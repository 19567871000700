import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS, CONTENT_WIDTH } from "@utils";
import { MAX_PAGE_WIDTH } from "@styles/themes";

const useStyle = makeStyles((theme) => ({
  
  descriptionEditorContainer: {
    borderWidth: "0 1px 0 0 !important",
    borderStyle: "solid",
  },
  descriptionEditor: {
    padding: "16px 8px",
    borderWidth: 0,
    borderRightWidth: "0 !important",
    borderStyle: "solid",
    maxWidth: (showPdfCover) => (
      showPdfCover
        ? CONTENT_WIDTH - 49
        : `min(calc(100vw - 64px), ${MAX_PAGE_WIDTH-2}px)`
    ),
    position: "sticky",
    left: 0,
    "&.d1": {
      paddingLeft: CELL_WIDTHS.EXPAND.DEPTH_1,
    },
    "&.d2": {
      paddingLeft: CELL_WIDTHS.EXPAND.DEPTH_2,
    },
    "&.d3": {
      paddingLeft: CELL_WIDTHS.EXPAND.DEPTH_3,
    },
  },
  descriptionEditorStatic: {
    borderTopWidth: 1,
    borderTopColor: `${theme.props.border} !important`,
    backgroundColor: `${theme.props.backgroundSecondary} !important`,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: `${theme.props.border} !important`,
  }
}));

export default useStyle;
