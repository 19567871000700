export const isProjectObserver = (userId, projectUsers, isAdmin = false) => {
  if (!userId) return false;

  return Boolean(
    isAdmin ||
    (projectUsers?.author && userId === projectUsers.author.id) ||
    (projectUsers?.owner && userId === projectUsers.owner.id) ||
    projectUsers?.observer.find((o) => o.id === userId)
  );
};
