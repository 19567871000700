import { useMemo, useEffect } from "react";
import { string, func, arrayOf } from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControlledTextField, InfoLabel } from "@components";
import { IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const shareSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t("errors.email.required")
      )
      .required(t("errors.email.required"))
      .max(64, t("errors.email.max")),
  });

export const ProjectShareForm = ({ onSubmit, onChange, forbiddenEmails }) => {
  const { t } = useTranslation();
  const schema = useMemo(() => shareSchema(t), []);

  const {
    watch,
    control,
    handleSubmit,
    setError,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    const subscription = watch(checkField);
    return () => subscription.unsubscribe();
  }, [watch]);

  const checkField = (value) => {
    if (schema.isValidSync(value)) onChange && onChange(value.email);
  };

  const submitHandler = async (data) => {
    if (forbiddenEmails.includes(data.email)) {
      setError("email", {
        type: "manual",
        message: t("errors.email.duplicate"),
      });
      return;
    }
    await onSubmit(data);
    resetForm();
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <InfoLabel label={t("forms.email")} />
      <ControlledTextField
        type="email"
        name="email"
        control={control}
        errors={errors}
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton aria-label="accept value" color="primary" type="submit">
              <Add />
            </IconButton>
          ),
        }}
      />
    </form>
  );
};

ProjectShareForm.propTypes = {
  onSubmit: func.isRequired,
  onChange: func,
  forbiddenEmails: arrayOf(string).isRequired,
};
