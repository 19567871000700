import { memo } from "react";
import { object, oneOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { useLibraryPusher, useStores } from "@hooks";
import { Alert, TooltipIcon } from "@components";
import { CircularProgress } from "@material-ui/core";
import { Add } from "@material-ui/icons";

export const LibraryPusher = memo((libraryPusherProps) => {
  const { userStore } = useStores();
  const { t } = useTranslation();

  const {
    isLoading,
    libraryPushAlertStatus,
    libraryPushSuccess,
    addToLibrary,
    closeAlert,
    closeSuccessAlert,
  } = useLibraryPusher(libraryPusherProps);
  
  const name = libraryPusherProps.element.name;
  
  return (
    <>
      <TooltipIcon
        icon={isLoading ? <CircularProgress size={18} /> : <Add />}
        onClick={addToLibrary}
        className="button-library-add noDrag hoverIcon opaque"
        size="small"
        color="primary"
        tabIndex={-1}
      >
        {t("views.editor.add_to_library")}
      </TooltipIcon>
      <Alert
        isOpen={!!libraryPushAlertStatus}
        title={t(libraryPushAlertStatus)}
        acceptText={t("common.close")}
        onAccept={closeAlert}
      />
      <Alert
        isOpen={libraryPushSuccess}
        title={t(
          `alerts.library.added_${
            userStore.isModerator ? "moderator" : "pending"
          }`,
          { name }
        )}
        acceptText={t("common.close")}
        onAccept={closeSuccessAlert}
      />
    </>
  );
});

LibraryPusher.propTypes = {
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]).isRequired,
};
