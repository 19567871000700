import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, ControlledTextField } from "@components";
import { CircularProgress } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailRegex } from "./emailRegex";

const loginFormSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, t("errors.email.required"))
      .required(t("errors.email.required")),
    password: yup.string().required(t("errors.password.required")),
    remember: yup.boolean().nullable(true),
  });

export const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(loginFormSchema(t)),
    defaultValues: {
      email: "",
      password: "",
      remember: false,
    },
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="login form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <ControlledTextField
        name="email"
        control={control}
        label={t("forms.email")}
        autoComplete="email"
        type="email"
        fullWidth
      />
      <ControlledTextField
        name="password"
        type="password"
        control={control}
        label={t("forms.password.generic")}
        autoComplete="current-password"
        fullWidth
      />
      <Controller
        name="remember"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <Checkbox
            name={name}
            checked={value || false}
            onChange={onChange}
            label={t("views.login.remember")}
          />
        )}
      />

      <Button
        isSubmit
        name="submit"
        variant="contained"
        fullWidth
        className="mt-4"
        icon={
          isSubmitting ? (
            <CircularProgress
              aria-label="progress indicator"
              size={24}
              color="inherit"
            />
          ) : undefined
        }
      >
        {!isSubmitting && t("routes.login")}
      </Button>
    </form>
  );
};

LoginForm.propTypes = {
  onSubmit: func.isRequired,
};
