import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useStores } from "@hooks";
import { getCouponQuery, getCurrentSubscriptionQuery, refreshUserDataQuery } from "@query";
import { parsePackageName } from "@utils";
import { PageTitle } from "@components";
import Grid from "@material-ui/core/Grid";
import { TeamTable } from "../Team/components/TeamTable/TeamTable";
import { SectionCards, SectionPayments, SectionPlan } from "./components";
import useStyle from "./Subscription.style";
import { Container } from "@material-ui/core";

export const Subscription = observer(() => {
  const classes = useStyle(isMobile);
  const { userStore } = useStores();
  const { t } = useTranslation();

  const [isLoading, setSubLoading] = useState(true);
  const [subscription, setSubscription] = useState(null);
  const [coupon, setCoupon] = useState(null);

  const loadSubscription = async () => {
    setSubLoading(true);
    const c = await getCouponQuery();
    setCoupon(c?.code);
    const s = await getCurrentSubscriptionQuery();
    if (s?.name) {
      userStore.updateSubscriptionData(
        parsePackageName(s.name),
        s.name,
        s.users,
        s.estimates,
        s.trialTo,
        s.isTrialing ? "try" : "buy"
      );
    } else if(s) {
      s.name = t("plan.default")
    }
    setSubscription(s);
    setSubLoading(false);
  };

  useEffect(() => {
    (async () => {
      const data = await refreshUserDataQuery();
      await userStore.saveUserData(data);
      await loadSubscription();
    })();
  }, []);

  const name = useMemo(
    () =>
      t(`plans.${parsePackageName(subscription?.name || userStore.subscriptionInfo.premium)}`),
    [subscription, userStore.data]
  );

  return (
    <>
      <PageTitle />
      <Container>
        <Grid
          item
          container
          className={classes.paper}
          style={{ minHeight: 260 }}
          spacing={0}
        >
          <SectionPlan
            name={name}
            isLoading={isLoading}
            hasSubscription={Boolean(subscription)}
            duration={subscription?.duration}
            price={subscription?.price}
            discountPrice={subscription?.discountPrice}
            estimates={subscription?.estimates}
            users={subscription?.users}
            coupon={coupon}
            setCoupon={setCoupon}
          />
          <SectionCards
            coupon={coupon}
            isSubscriptionLoading={isLoading}
            hasSubscription={Boolean(subscription)}
            subscriptionEnd={subscription?.to}
            subscriptionPrice={subscription?.discountPrice || subscription?.price}
            defaultPaymentMethod={subscription?.paymentMethod}
            lastPaymentPeriod={subscription?.lastPeriod}
          />
          <SectionPayments />
        </Grid>
        <Grid item container className={`${classes.paper} p-4`}>
          <TeamTable useTableTitle />
        </Grid>
      </Container>
    </>
  );
});