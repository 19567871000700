import { bool } from "prop-types";
import { usePresetFieldNames } from "@hooks";
import classnames from "classnames";

export const SummaryTitle = ({ isProposal }) => {
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  return (
    <p
      className={classnames(
        "text-sm",
        !isProposal && "transparent-3",
        isProposal && "preset-tableFooter pt-subtotal font-bold"
      )}
    >
      {getFieldName("pt-subtotal")}
    </p>
  );
}

SummaryTitle.propTypes = {
  isProposal: bool
}