import { SvgIcon } from "@material-ui/core";

export const Cursor = (props) => (
  <SvgIcon {...props} viewBox="-8 -8 48 64">
    <path
      fill="currentColor"
      d="M 3.499675710730185 1.3232432741736928 L 34.50032428926981 28.67675672582631 C 35.250162144634906 29.338378362913154 35 30 34 30 L 15.838 30 C 14.838 30 13.22851488858902 30.79279751448169 12.61902977717804 31.585595028963382 L 1.2189702228219588 46.41440497103662 C 0.6094851114109794 47.20720248551831 0.04163054471218133 47.00086692690765 0.08326108942436267 46.001733853815296 L 1.9167389105756374 1.998266146184704 C 1.9583694552878188 0.999133073092352 2.7498378553650924 0.6616216370868464 3.499675710730185 1.3232432741736928 Z "
      strokeWidth="6"
      stroke="currentColor"
    />
  </SvgIcon>
);