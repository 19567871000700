export const HORIZONTAL_PADDING = 32;
export const VERTICAL_PADDING = 8;

export const PAGE_VISIBLE_BOTTOM_OFFSET = 200;

export const PROPOSAL_THUMB_ID = "proposal";

export const SCALE_PRESETS = {
  CUSTOM: "custom",
  AUTO: "auto",
  WIDTH: "page-width",
  FIT: "page-fit",
}