export const getProfitColor = (profitability, theme) => {
  if(profitability === null)
    return;
  
  if(profitability < 5)
    return theme.palette.error.main
  if(profitability >= 5 && profitability < 15)
    return theme.palette.warning.main
  if(profitability >= 15 && profitability < 30)
    return theme.palette.primary.main
  
  return theme.palette.success.main
}