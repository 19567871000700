import { fetchData } from "@client";

export async function getWorkTypesQuery(all=false) {
  try {
    const res = await fetchData(`/tags?limit=1000${all ? "&all=1" : ""}`);
    return res.results;
  } catch (e) {
    return [];
  }
}
