import { APIMethod, fetchData } from "@client";

export async function createTemplateQuery(
  uuid,
  data, //{ name, description, category }
) {
  await fetchData(
    "/template",
    APIMethod.POST,
    {
      removeEmptyKeys: true,
      data: {
        uuid,
        ...data
      }
    }
  );
}
