import { ExpandMore } from "@material-ui/icons";
import { TagList, TextField } from "@components";
import {
  default as MuiAutocomplete,
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useEffect, useMemo, useState } from "react";
import {
  arrayOf,
  string,
  number,
  object,
  oneOfType,
  bool,
  func,
} from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { simplifyTextString } from "@utils";

export const Autocomplete = ({
  value: defaultValue,
  inputValue,
  name,
  options,
  labelKey,
  idKey,
  disableClearable,
  multiple,
  fieldClassName,
  onOptionCreate,
  onChange,
  onInputChange,
  getOptionDisabled,
  labelFormat,
  dataLoading,

  inputLabel,
  inputPlaceholder,
  InputProps,
  inputProps,
  error,
  helperText,
  ...muiAutocompleteProps
}) => {
  const { t } = useTranslation();
  const filter = createFilterOptions();

  const [objectValue, setObjectValue] = useState(multiple ? [] : null);

  const freeSolo = useMemo(() => !!onOptionCreate, []);

  useEffect(() => {
    if (!multiple)
      setObjectValue(
        options.find((option) => option[idKey] === defaultValue) || null
      );
    else
      setObjectValue(
        defaultValue.map(v => options.find((option) => v === option[idKey])) || []
      );
  }, [defaultValue]);

  const handleChange = async (_, value) => {
    if (value?.length) {
      let newValue = multiple ? value.slice(-1)[0] : value;

      if (typeof newValue === "string") {
        const existingOption = filter(
          options.filter(
            (o) =>
              !(multiple
                ? defaultValue.includes(o[idKey])
                : defaultValue === o[idKey])
          ),
          { inputValue: newValue, getOptionLabel }
        )[0];

        if (!existingOption && !onOptionCreate) return;

        if (
          !existingOption &&
          value.find(
            (o) =>
              typeof o !== "string" &&
              o[labelKey].toLowerCase() === newValue.toLowerCase()
          )
        )
          return;

        if (existingOption) value[value.length - 1] = existingOption;

        newValue = existingOption || {
          [idKey]: "input",
          [labelKey]: "",
          INPUT_TEXT: newValue,
        };
      }

      if (onOptionCreate && newValue.INPUT_TEXT) {
        try {
          const data = await onOptionCreate(newValue.INPUT_TEXT);
          newValue = { ...data, [labelKey]: newValue.INPUT_TEXT };
          if (multiple) value[value.length - 1] = newValue;
          else value = newValue;
        } catch (e) {
          return;
        }
      }
    }
    setObjectValue(value);
    onChange &&
      onChange(!multiple ? value?.[idKey] : value?.map((v) => v[idKey]), value);
  };

  const handleInputChange = (_, value) => {
    onInputChange && onInputChange(value);
  };
  
  const handleItemRemove = (item) => {
    if(!multiple) return;
    const value = objectValue.filter(i => i[idKey] !== item);
    setObjectValue(value)
    onChange &&
    onChange(value?.map((v) => v[idKey]), value, item);
  }

  const getOptionLabel = (option) => {
    const l = option[labelKey || idKey] || "";
    return labelFormat ? labelFormat(l, option) : l;
  };

  const checkAndShowTagCreationPrompt = (localOptions, params) => {
    const filtered = filter(localOptions, params);

    const { inputValue } = params;
    const isExisting = options.some(
      (option) => simplifyTextString(inputValue) === simplifyTextString(option[labelKey])
    );
    
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        [idKey]: "input",
        [labelKey]: `${t("common.add_new")} "${inputValue}"`,
        INPUT_TEXT: inputValue,
      });
    }

    return filtered;
  };
  return (
    <MuiAutocomplete
      {...muiAutocompleteProps}
      value={objectValue}
      inputValue={inputValue}
      options={options}
      autoHighlight
      disableClearable={freeSolo || disableClearable}
      filterSelectedOptions
      multiple={multiple}
      popupIcon={
        dataLoading ? (
          <CircularProgress aria-label="progress indicator" size={24} />
        ) : (
          <ExpandMore />
        )
      }
      forcePopupIcon={true}
      onChange={handleChange}
      onInputChange={handleInputChange}
      freeSolo={freeSolo}
      filterOptions={freeSolo ? checkAndShowTagCreationPrompt : undefined}
      selectOnFocus={freeSolo}
      clearOnBlur={freeSolo}
      handleHomeEndKeys={freeSolo}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => {
        return !!value?.[idKey] && option[idKey] === value[idKey];
      }}
      getOptionDisabled={getOptionDisabled}
      renderTags={() => (
        <TagList
          tags={objectValue}
          onRemove={handleItemRemove}
          ellipsisOnOverflow
          useMaxWidth
        />
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            name={name}
            error={error}
            helperText={helperText}
            className={fieldClassName}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              className: classnames(
                params.InputProps.className,
                InputProps?.className
              ),
            }}
            inputProps={{
              ...params.inputProps,
              ...inputProps,
              className: classnames(
                params.inputProps.className,
                inputProps?.className
              ),
            }}
            label={inputLabel}
            placeholder={!defaultValue?.length ? inputPlaceholder : undefined}
          />
        );
      }}
    />
  );
};

Autocomplete.propTypes = {
  id: string,
  value: oneOfType([string, number, arrayOf(oneOfType([string, number]))])
    .isRequired,
  options: arrayOf(object).isRequired,
  idKey: string.isRequired,
  name: string,
  labelKey: string,
  multiple: bool,
  disableClearable: bool,
  dataLoading: bool,

  onChange: func, // (id/idList, object/objectList, removedItemId) => void
  getOptionDisabled: func,
  labelFormat: func,
  onInputChange: func,
  onOptionCreate: func,
  inputValue: string,
  fieldClassName: string,
  inputLabel: string,
  inputPlaceholder: string,
  InputProps: object,
  inputProps: object,
  error: bool,
  helperText: string,
};
