import { makeStyles } from "@material-ui/core/styles";
import { MAX_PAGE_WIDTH } from "@styles/themes";

const useStyle = makeStyles(() => ({
  root: {
    margin: "16px 0",
    height: 48,
    maxWidth: MAX_PAGE_WIDTH,
  },
  expandButton: {
    width: 32,
    marginRight: 8,
  },
}));

export default useStyle;
