import { func, string } from "prop-types";
import { TextField } from "@components";
import { WysiwygInputField, WysiwygInputFieldProps } from "./WysiwygInputField";

export const WysiwygEditorInputWrapper = ({
  value,
  InputProps={},
  onChange,
  ...textFieldProps
}) => {
  
  return (
    <>
      <TextField
        {...textFieldProps}
        value={value}
        onChange={onChange}
        InputProps={{
          ...InputProps,
          inputComponent: WysiwygInputField
        }}
      />
    </>
  );
};

WysiwygEditorInputWrapper.propTypes = {
  ...WysiwygInputFieldProps,
  name: string.isRequired,
  onChange: func,
};