import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { SUBSCRIPTION_FEATURE_PREVIEWS } from "@utils";
import Grid from "@material-ui/core/Grid";
import useStyle from "./FeatureInfoDialog.style";

export const FeatureInfoDialog = ({ code, hasVideo, popup }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Grid item container direction="column">
      {popup && <h2 className={classes.title}>{t(`features.${code}`)}</h2>}
      <p>{t(`feature_desc.${code}`)}</p>
      {hasVideo && (
        <video autoPlay loop className={classes.video}>
          <source src={SUBSCRIPTION_FEATURE_PREVIEWS[code]} type="video/mp4" />
        </video>
      )}
    </Grid>
  );
};

FeatureInfoDialog.propTypes = {
  code: string.isRequired,
  hasVideo: bool.isRequired,
  popup: bool,
};
