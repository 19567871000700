import { APIMethod, fetchData } from "@client";

export async function getUniqueShareLinkQuery(projectUuid, email) {
  const res = await fetchData(
    `/project/uniquelink/${projectUuid}`,
    APIMethod.GET,
    {
      params: { email },
    }
  );

  return res.results.link;
}
