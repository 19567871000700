import {
  bool,
  string,
  object,
  node,
  any,
  func,
  shape,
  oneOf,
  oneOfType,
  arrayOf, number,
} from "prop-types";
import { Button } from "@components";
import { capitalize } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Check } from "@material-ui/icons";
import useStyle from "./ButtonSwitch.style";
import classnames from "classnames";

export const ButtonSwitch = ({
  values,
  value,
  setValue,
  size,
  fullWidth,
  markSelected,
  style,
  className,
  containerClassName,
  lighter,
  width=113,
  height,
}) => {
  /** @note: responsiveness? (maybe show them in the column instead of row */
  const classes = useStyle();

  return (
    <Grid
      item
      container
      justifyContent="center"
      wrap="nowrap"
      className={containerClassName}
      style={{ width: fullWidth ? "100%" : "max-content", ...(style || {}) }}
    >
      {values.map((val, i) => {
        const { value: v, label } = val;

        const selected = v === value;

        return (
          <Button
            key={`${v}_${i}`}
            className={classnames(
              classes.button,
              !selected && classes.empty,
              !selected && lighter && classes.emptyLight,
              className
            )}
            icon={markSelected && selected ? <Check /> : null}
            size={size}
            onClick={() => setValue(v)}
            variant={selected ? "contained" : "outlined"}
            color={selected ? "primary" : "secondary"}
            style={{ width: fullWidth ? "100%" : width, height }}
          >
            {!label ? v : typeof label === "string" ? capitalize(label) : label}
          </Button>
        );
      })}
    </Grid>
  );
};

ButtonSwitch.propTypes = {
  value: any.isRequired,
  values: arrayOf(
    shape({
      value: any.isRequired,
      label: oneOfType([string, node]),
    })
  ),
  setValue: func.isRequired,
  size: oneOf(["small", "medium", "large"]),
  fullWidth: bool,
  width: number,
  height: number,
  markSelected: bool,
  style: object,
  className: string,
  containerClassName: string,
  lighter: bool,
};
