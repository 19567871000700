import { arrayOf, object } from "prop-types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { FeatureName } from "../FeatureName/FeatureName";
import { Check, Clear } from "@material-ui/icons";
import useStyle from "./FeaturesTable.style";
import classnames from "classnames";
import { PACKAGE_BUSINESS, PACKAGE_FREE, SUBSCRIPTION_PLANS } from "@utils";

export const FeaturesTable = ({ features }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Grid item container className="overflow-auto">
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.featureTitle} />
            {SUBSCRIPTION_PLANS.map((plan) => (
              <th
                key={plan}
                className={classnames(
                  classes.featureCell,
                  classes.featureHeaderCell,
                  classes.featureValue
                )}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  className={classnames(
                    classes.planTitle,
                    plan === PACKAGE_FREE && classes.planTitleFree,
                    plan === PACKAGE_BUSINESS && classes.planTitleHighlight
                  )}
                >
                  {t(`plans.${plan}`)}
                </Grid>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(features).map((feature) => (
            <tr key={feature} className={classes.featureRow}>
              <td
                className={classnames(
                  classes.featureCell,
                  classes.featureTitle
                )}
              >
                <FeatureName code={feature} />
              </td>
              {SUBSCRIPTION_PLANS.map((plan, index) => {
                const v = features[feature][index];

                return (
                  <td
                    key={`${feature}${plan}`}
                    className={classnames(
                      classes.featureCell,
                      classes.featureValue
                    )}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      {typeof v === "boolean" ? (
                        v ? (
                          <Check className={classes.iconSuccess} />
                        ) : (
                          <Clear
                            color="error"
                            className={classes.iconError}
                          />
                        )
                      ) : (
                        <strong>{v}</strong>
                      )}
                    </Grid>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Grid>
  );
};

FeaturesTable.propTypes = {
  plans: arrayOf(object),
  features: arrayOf(object),
};
