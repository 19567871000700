import { bool, node, oneOf, string, number } from "prop-types";
import { capitalize, Grid } from "@material-ui/core";
import useCellStyle from "../commonStyles.style";
import classnames from "classnames";
import { useMemo } from "react";
import { CELL_WIDTHS } from "@utils";

export const CollapseValueCell = ({
  cell,
  children,
  isOpened,
  isHeader,
  alwaysDisplayMainCell,
  useSeparator,
  useBorder,
  className,
  innerClassName,
  column = false,
  isFirst = false,
  visible = true,
  size = "large",
  childrenWidth = CELL_WIDTHS.VALUE.TINY,
  mainCellWidth = CELL_WIDTHS.VALUE.SMALL,
}) => {
  const cellClasses = useCellStyle();

  const mainCellIn = useMemo(
    () => alwaysDisplayMainCell || !isOpened,
    [alwaysDisplayMainCell, isOpened]
  );

  const width = useMemo(() => {
    return isOpened
      ? childrenWidth * (children?.length || 0) +
          (alwaysDisplayMainCell ? mainCellWidth : 0) +
          2
      : mainCellWidth;
  }, [children, isOpened, alwaysDisplayMainCell]);

  return (
    <Grid
      item
      container
      role="rowitem"
      className={classnames(
        "cell collapseCell p-0",
        cellClasses.cell,
        useSeparator && cellClasses.withSeparator,
        isHeader && cellClasses.headCell,
        cellClasses[`size${capitalize(size)}`],
        !visible && cellClasses.cellOff,
        className
      )}
      style={{ width, minWidth: width }}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        direction={column ? "column" : "row"}
        className={classnames(
          !isHeader && cellClasses.collapseCellInner,
          isHeader && "h-full", "w-max min-w-full",
          useBorder && cellClasses.fullBorderRight,
          useBorder && isFirst && cellClasses.fullBorderLeft,
          innerClassName
        )}
      >
        {mainCellIn && cell}
        {isOpened && children}
        {isOpened && <span className="separator_bt" />}
      </Grid>
    </Grid>
  );
};

CollapseValueCell.propTypes = {
  cell: node,
  children: node,
  isOpened: bool,
  isHeader: bool,
  visible: bool,
  isFirst: bool,
  alwaysDisplayMainCell: bool,
  useSeparator: bool,
  useBorder: bool,
  column: bool,
  className: string,
  innerClassName: string,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
  childrenWidth: number,
  mainCellWidth: number,
};
