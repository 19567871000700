import { makeStyles, createStyles } from "@material-ui/core/styles";
import { DEFAULT_FONT_NAME, EDITOR_CONTAINER_ID } from "@utils";

const i = "!important";
export const usePresetStyles = (
  presetData,
  fontFamily,
  useStyles = true,
  useThemeBackground = false
) =>
  makeStyles((theme) => {
    if (useStyles && presetData)
      return createStyles({
        "@global": {
          "[class*=preset-]": {
            fontFamily: `'${fontFamily?.name || DEFAULT_FONT_NAME}' ${i}`,
          },
          ".preset-text": {
            color: presetData.fontColor.text + i,
            "& > hr": { backgroundColor: presetData.fontColor.text + i },
          },
          ".preset-summaryText": {
            color: presetData.fontColor.summaryText + i,
            "& > hr": { backgroundColor: presetData.fontColor.summaryText + i },
          },
          ".preset-timelineText": {
            color: presetData.fontColor.timelineText + i,
          },
          ".preset-summaryTitleText": {
            color: presetData.fontColor.summaryTitleText + i,
          },
          ".preset-timelineTitleText": {
            color: presetData.fontColor.timelineTitleText + i,
          },
          ".preset-titleText": {
            color: presetData.fontColor.titleText + i,
          },
          ".preset-btGreenText": {
            color: presetData.fontColor.btGreenText + i,
          },
          ".preset-btRedText": {
            color: presetData.fontColor.btRedText + i,
          },
          ".preset-titleTextSection": {
            color: presetData.fontColor.titleTextSection + i,
          },
          ".preset-title": {
            color: presetData.fontColor.title + i,
          },
          ".preset-breakdownHeader": {
            color: presetData.fontColor.breakdownHeader + i,
          },
          ".preset-tableBreakdownHeader": {
            color: presetData.fontColor.tableBreakdownHeader + i,
          },
          ".preset-tableSummaryHeader": {
            color: presetData.fontColor.tableSummaryHeader + i,
          },
          ".preset-tableTimelineHeader": {
            color: presetData.fontColor.tableTimelineHeader + i,
          },
          ".preset-tableTimelineBilling": {
            color: presetData.fontColor.tableTimelineBilling + i,
          },
          ".preset-tableFooter": {
            color: presetData.fontColor.tableFooter + i,
          },
          ".preset-summaryHeader": {
            color: presetData.fontColor.summaryHeader + i,
          },
          ".preset-timelineHeader": {
            color: presetData.fontColor.timelineHeader + i,
          },
          ".preset-timelineSubHeader": {
            color: presetData.fontColor.timelineSubHeader + i,
          },
          [`.preset-page, .preset-section, #${EDITOR_CONTAINER_ID}`]: {
            // backgroundColor: presetData.backgroundColor + i,
            background: useThemeBackground
              ? theme.props.backgroundSecondary
              : `linear-gradient(${presetData.backgroundColor}, ${presetData.backgroundColor}), linear-gradient(#fff, #fff)`,
          },
          ".preset-paper": {
            background: `linear-gradient(${presetData.paperColor}, ${presetData.paperColor}), linear-gradient(#fff, #fff)`,
          },
          ".preset-paperTimeline": {
            background: `linear-gradient(${presetData.paperTimelineColor}, ${presetData.paperTimelineColor}), linear-gradient(#fff, #fff)`,
          },
          ".preset-paper:not(.preset-noedit), .preset-paperTimeline:not(.preset-noedit)": {
            borderColor: presetData.paperDivider + i,
          },
          ".preset-section .sectionTitle": {
            color: presetData.fontColor.section + i,
            backgroundColor: presetData.sectionColor + i,
          },
          ".preset-sectionRow": {
            backgroundColor: presetData.sectionColor + i,
          },
          ".preset-sectionRow .cell": {
            color: presetData.fontColor.sectionRow + i,
          },
          ".preset-sectionRow .cell.overflowing": {
            borderRightColor: presetData.sectionDivider + i,
          },
          ".preset-section > hr": {
            backgroundColor: presetData.sectionDivider + i,
          },
          ".preset-sectionDesc": {
            color: presetData.fontColor.sectionDesc + i,
            backgroundColor: presetData.backgroundColor
          },
          ".preset-rowDesc": {
            color: presetData.fontColor.rowDesc + i,
            backgroundColor: presetData.rowDescColor + i,
          },
          ".preset-row": {
            backgroundColor: presetData.rowColor + i,
          },
          ".preset-row .cell": {
            color: presetData.fontColor.row + i,
          },
          ".preset-accent:hover .cell, .preset-accent.active .cell": {
            borderColor: presetData.rowBorderColor + i,
          },
          ".preset-accentSummary:hover .cell, .preset-accentSummary.active .cell":
            {
              borderColor: presetData.rowSummaryBorderColor + i,
            },
          ".preset-accentTimeline:hover .cell, .preset-accentTimeline.active .cell":
            {
              borderColor: presetData.rowTimelineBorderColor + i,
            },
          ".preset-rowSummary": {
            backgroundColor: presetData.rowSummaryColor + i,
          },
          ".preset-rowTimeline": {
            backgroundColor: presetData.rowTimelineColor + i,
          },
          ".preset-activeSummary": {
            backgroundColor: presetData.rowActiveSummaryColor + i,
          },
          ".preset-activeTimeline": {
            backgroundColor: presetData.rowActiveTimelineColor + i,
          },
          ".preset-moreDesc": {
            color: presetData.fontColor.moreDesc + i,
          },
          ".preset-moreDescSection": {
            color: presetData.fontColor.moreDescSection + i,
          },
          ".preset-export": {
            backgroundColor: presetData.exportButtonColor + i,
            color: presetData.fontColor.export + i,
          },
          '.preset-rowSummary:hover, .preset-rowSummary[aria-selected="true"], .preset-rowSummary .preset-accent:focus, .preset-rowSummary .preset-accent:hover':
            {
              borderColor: presetData.rowSummaryBorderColor + i,
            },
          '.preset-rowTimeline:hover, .preset-rowTimeline[aria-selected="true"], .preset-rowTimeline .preset-accent:focus, .preset-rowTimeline .preset-accent:hover':
            {
              borderColor: presetData.rowTimelineBorderColor + i,
            },
          ".preset-version, .preset-version-active": {
            borderColor: presetData.fontColor.version + i,
            color: presetData.fontColor.version + i,
          },
          ".preset-version": {
            backgroundColor: presetData.versionBackground + i,
          },
          ".preset-version-active": {
            backgroundColor: presetData.versionActiveBackground + i,
          },
          ".preset-timelineMode": {
            backgroundColor: presetData.timelineModeBackground + i,
            color: presetData.fontColor.timelineMode + i,
          },
          ".preset-timelineModeActive": {
            backgroundColor: presetData.timelineModeBackgroundActive + i,
            color: presetData.fontColor.timelineModeActive + i,
          },
          // ".preset-taskActionButton": {
          // 	color: presetData.rowBorderColor + i,
          // 	"& .Mui-checked+.MuiSwitch-track": {
          // 		backgroundColor: presetData.rowBorderColor + i,
          // 	},
          // "&+.MuiBadge-badge": {
          // 	backgroundColor: presetData.descriptionBadgeColor + i,
          // }
          // },
          ".preset-price": {
            color: presetData.fontColor.price + i,
            backgroundColor: presetData.priceColor + i,
            "& > span": { fontFamily: `'${fontFamily?.name || DEFAULT_FONT_NAME}' ${i}` },
            "& > hr": { backgroundColor: presetData.fontColor.price + i },
          },
          ".preset-totalTimeTitle": {
            color: presetData.fontColor.totalTimeTitle + i,
          },
          ".preset-totalHoursTitle": {
            color: presetData.fontColor.totalHoursTitle + i,
          },
          ".preset-totalCostTitle": {
            color: presetData.fontColor.totalCostTitle + i,
          },
          ".preset-totalTime": {
            color: presetData.fontColor.totalTime + i,
            "& > hr": { backgroundColor: presetData.fontColor.totalTime + i },
          },
          ".preset-totalHours": {
            color: presetData.fontColor.totalHours + i,
            "& > hr": { backgroundColor: presetData.fontColor.totalHours + i },
          },
          ".preset-totalCost": {
            color: presetData.fontColor.totalCost + i,
            "& > hr": { backgroundColor: presetData.fontColor.totalCost + i },
          },
          ".preset-bar": {
            backgroundColor: presetData.headerColor + i,
          },
          ".preset-active-1": {
            backgroundColor: presetData.activeColor1 + i,
          },
          ".preset-active-1 .cell": {
            color: presetData.fontColor.rowActive1 + i,
          },
          ".preset-active-2": {
            backgroundColor: presetData.activeColor2 + i,
          },
          ".preset-active-2 .cell": {
            color: presetData.fontColor.rowActive2 + i,
          },
          ".preset-active-3": {
            backgroundColor: presetData.activeColor3 + i,
          },
          ".preset-tableBreakdownExpandButton": {
            color: presetData.fontColor.tableBreakdownExpandButton + i,
            backgroundColor: presetData.tableBreakdownExpandButton + i,
          },
          ".preset-tableSummaryExpandButton": {
            color: presetData.fontColor.tableSummaryExpandButton + i,
            backgroundColor: presetData.tableSummaryExpandButton + i,
          },
          ".preset-tableTimelineExpandButton": {
            color: presetData.fontColor.tableTimelineExpandButton + i,
            backgroundColor: presetData.tableTimelineExpandButton + i,
          },
          
          // "#font-button-arial": { fontFamily: "Arial" },
          // "#font-button-calibri": { fontFamily: "Calibri" },
          // "#font-button-courier-new": { fontFamily: "Courier New" },
          // "#font-button-georgia": { fontFamily: "Georgia" },
          // "#font-button-garamond": { fontFamily: "Garamond" },
          // "#font-button-helvetica": { fontFamily: "Helvetica" },
          // "#font-button-lucida-console": { fontFamily: "Lucida Console" },
          // "#font-button-monaco": { fontFamily: "Monaco" },
          // "#font-button-papyrus": { fontFamily: "Papyrus" },
          // "#font-button-times-new-roman": { fontFamily: "Times New Roman" },
          // "#font-button-verdana": { fontFamily: "Verdana" },
        },
      });
  })();
