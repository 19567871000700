import { useCallback } from "react";
import { db } from "@client";
import axios from "axios";
import { refreshUserDataQuery } from "@query";
import { useStores } from "./stores";

export const useAutoLogin = () => {
  
  const { userStore } = useStores();
  
  return useCallback(async () => {
    
    const users = await db.user.toArray();
    
    // console.log("autoLogin user", users);
    if (!users?.[0]?.token)
      throw "No data";
    
    console.log("autoLogin continue");
    const { token } = users[0];
    axios.defaults.headers.common["Authorization"] = token;
    
    const userData = await refreshUserDataQuery();
    
    await userStore.checkUserTrial();
    userStore.saveUserData(userData);
    userStore.checkWorkspacesAndRedirect();
    userStore.setLoginProcessStatus(true);
  }, []);
}