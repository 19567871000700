import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    height: "max-content",
    maxWidth: 260
  },
}));

export default useStyle;
