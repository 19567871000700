import { isPlainObject } from "@utils/isPlainObject";

/**
 * maps object keys to snake_case
 */
export const mapKeysToSnakeCase = (data) => {
  const mapFn = (s) => {
    s.match(/[A-Z]/g)?.forEach(
      (m) => (s = s.replace(m, "_" + m.toLowerCase()))
    );
    return s;
  };
  return deepMapKeys(data, mapFn);
};

/**
 * maps object keys to camelCase
 */
export const mapKeysToCamelCase = (data) => {
  const mapFn = (s) => {
    s.match(/_\w/g)?.forEach(
      (m) => (s = s.replace(m, m.replace(/_/g, "").toUpperCase()))
    );
    return s;
  };
  return deepMapKeys(data, mapFn);
};

/**
 * Maps object key names based on passed function
 */
const deepMapKeys = (obj, mapFn) => {
  return Array.isArray(obj)
    ? obj.map((val) => deepMapKeys(val, mapFn))
    : isPlainObject(obj)
    ? Object.keys(obj).reduce((acc, current) => {
        const key = mapFn(current);
        const val = obj[current];
        acc[key] =
          val !== null && typeof val === "object"
            ? deepMapKeys(val, mapFn)
            : val;
        return acc;
      }, {})
    : obj;
};
