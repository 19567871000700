import { useState } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { APP_BAR_HEIGHTS } from "@styles/themes";
import { EditorContainer, PdfEditorContainer } from "@components";
import { EditorContent, ProposalCustomizationToolbar } from "../index";

export const EditorRoot = observer(({
  showStepper,
  showUserMovement,
  showPdfCover,
  hasEditorPrivileges,
  hasSellerPrivileges,
  readOnly,
  isProposal,
  useClientActions,
  useRecorderFriendlyPdf,
  forceAllowVersionSelect,
}) => {
  const [sectionsReordered, setSectionsReordered] = useState(false);

  const handleSectionReorder = () => {
    setSectionsReordered((state) => !state);
  };

  return (
    <>
      {readOnly && hasSellerPrivileges && hasEditorPrivileges && (
        <ProposalCustomizationToolbar />
      )}
      {
        showPdfCover
        ? (
          <PdfEditorContainer
            offsetTop={hasSellerPrivileges ? APP_BAR_HEIGHTS.LARGE : 0}
            noShareActions={!hasSellerPrivileges}
            showPageImages={!hasSellerPrivileges}
            noThumbnailListRescale
            proposalSharing
            noThumbButton
            noFileAppends
          >
            <EditorContent
              readOnly
              isProposal={isProposal}
              showPdfCover
              useRecorderFriendlyPdf={useRecorderFriendlyPdf}
              useClientActions={useClientActions}
            />
          </PdfEditorContainer>
        )
        : (
          <EditorContainer
            showStepper={showStepper}
            sectionReordered={sectionsReordered}
            showUserMovement={showUserMovement}
          >
            <EditorContent
              hasEditorPrivileges={hasEditorPrivileges}
              hasSellerPrivileges={hasSellerPrivileges}
              readOnly={readOnly}
              isProposal={isProposal}
              useClientActions={useClientActions}
              onSectionReorder={handleSectionReorder}
              forceAllowVersionSelect={forceAllowVersionSelect}
            />
          </EditorContainer>
        )
      }
    </>
  );
});

EditorRoot.propTypes = {
  showStepper: bool,
  showPdfCover: bool,
  showUserMovement: bool,

  hasEditorPrivileges: bool,
  hasSellerPrivileges: bool,
  readOnly: bool,
  isProposal: bool,
  useClientActions: bool,
  useRecorderFriendlyPdf: bool,
  forceAllowVersionSelect: bool,
};
