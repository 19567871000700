import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MS_SSO_CLIENT_ID,
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: process.env.REACT_APP_MS_SSO_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    asyncPopups: false,
    loggerOptions: {
      piiLoggingEnabled: true,
      loggerCallback(level, message) {
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            throw message;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const MSAL_PROGRESS = {
  LOGIN: "login",
  NONE: "none",
};

export const loginRequest = {
  scopes: ["User.Read"],
  prompt: "select_account",
};
