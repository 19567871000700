import { fetchData } from "@client";
import { APIMethod } from "../../client/api/fetchData";
import { ACCESS_DOMAINS } from "../../client/accessDomains";

export async function getUsersQuery(projectId) {
  return await fetchData(`api/get-jira-users`, APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.btApi,
    data: { project_id: projectId },
  });
}
