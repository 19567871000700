import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    zIndex: 1300,
    '&[data-popper-placement^="top"] > .arrow': {
      bottom: -4,
    },
    '&[data-popper-placement^="bottom"] > .arrow': {
      top: -4,
    },
    '&[data-popper-placement^="left"] > .arrow': {
      right: -4,
    },
    '&[data-popper-placement^="right"] > .arrow': {
      left: -4,
    },
  },

  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: theme.props.backgroundSecondary,
    boxShadow: "0 0 9px 0 #0e1d4833",
    minWidth: 160,
    minHeight: "max-content",
    borderRadius: 5,
    overflow: "hidden",
    maxWidth: "900vw",
  },

  arrow: {
    position: "absolute",
    width: 8,
    height: 8,
    borderColor: theme.props.backgroundSecondary,
    visibility: "hidden",
    "&::before": {
      position: "absolute",
      width: 8,
      height: 8,
      visibility: "visible",
      content: '""',
      transform: "rotate(45deg)",
      backgroundColor: theme.props.backgroundSecondary,
    },
  },
}));

export default useStyle;
