import { useEffect, useState, useRef, useMemo } from "react";
import { bool, object, oneOf, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useCheckProjectAccess, useProjectEditorStore } from "@hooks";
import {
  forceProjectSaveQuery,
  getShareLinkQuery,
  getUniqueShareLinkQuery,
  sendShareLinksToEmailsQuery,
} from "@query";
import { Grid, CircularProgress, Tooltip } from "@material-ui/core";
import { ClearButton, TextField, Button, Dialog, Tag, PresetPicker } from "@components";
import { Share as ShareIcon } from "@assets";
import { ProjectShareForm } from "@forms";
import useStyle from "./Share.style";
import { usePresetStore } from "@hooks";
import { useSnackbar } from "notistack";
import classnames from "classnames";
import { useParams } from "react-router-dom";

export const Share = observer(({
  usePresetModeButton,
  pdfShare,
  style,
  className,
  size
}) => {
    const presetStore = usePresetStore();
    const editorStore = useProjectEditorStore();
    const { projectUuid } = useParams();
    
    const classes = useStyle();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { projectUsers } = editorStore;
    const { isObserver } = useCheckProjectAccess(projectUsers);

    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setLoadingState] = useState(false);
    const [isGenerating, setGenerating] = useState(false);
    const [link, setLink] = useState("");
    // const [alertText, setAlertText] = useState("");
    // const [alertState, setAlertState] = useState(null);
    // const [alertVisible, showAlert] = useState(null);

    const [isCommitLoading, setLoading] = useState(false);
    const [shareEmail, setShareEmail] = useState("");
    const [shareInvites, setShareInvites] = useState([]);

    useEffect(() => {
      if (editorStore.shareModalForced) {
        setOpenModal(true);
        editorStore.forceShareModal(false);
      }
    }, [editorStore.shareModalForced]);

    // const formatLink = (hash) =>
    //   `${window.location.origin}/${
    //     pdfShare ? "proposalpdf" : "proposal"
    //   }/${hash}`;

    const inviteEmails = useMemo(
      () => shareInvites.map((x) => x.e),
      [shareInvites]
    );

    const noEmails = useMemo(() => !shareInvites.length, [shareInvites]);

    const sendShareLink = async () => {
      setLoading(true);
      try {
        const emails = [...inviteEmails, shareEmail].filter(
          (e, i, a) => e && !a.slice(0, i).includes(e)
        );

        await sendShareLinksToEmailsQuery(projectUuid, emails);
        setLoading(false);
        setOpenModal(false);
        setShareInvites([]);
        enqueueSnackbar(t("views.editor.dialogs.share.sent"), {
          variant: "success",
        });
        // setAlertText(t("views.editor.dialogs.share.sent"));
        // setAlertState("success");
        // showAlert(true);
      } catch (e) {
        enqueueSnackbar(t("errors.try_again"), { variant: "error" });
        // setAlertText(t("errors.try_again"));
        // setAlertState("error");
        // showAlert(true);
        setLoading(false);
      }
    };

    const addEmail = async ({ email }) => {
      const m = await getUniqueShareLinkQuery(projectUuid, email);
      setShareInvites([...shareInvites, { e: email, l: m }]);
    };

    const removeEmail = (o) => {
      setShareInvites(shareInvites.filter((x) => x.e !== o));
    };

    useEffect(() => {
      if (openModal && !link)
        (async () => {
          setGenerating(true);
          setLoadingState(true);
          const l = await getShareLinkQuery(projectUuid);
          // setLink(formatLink(l));
          setLink(l);
          setGenerating(false);

          await presetStore?.getPresets(t("views.editor.preset.apropo"));
          setLoadingState(false);
        })();
    }, [openModal]);

    const handleClose = () => {
      if (!isGenerating) setOpenModal(false);
    };

    const getLink = () => {
      linkInputRef.current.select();
      linkInputRef.current.setSelectionRange(0, 99999);
      document.execCommand("copy");
    };

    const getUniqueLink = (e) => {
      e.currentTarget.nextSibling.select();
      e.currentTarget.nextSibling.setSelectionRange(0, 99999);
      document.execCommand("copy");
    };

    const handleClick = async () => {
      setLoadingState(true);
      if (!isObserver) {
        enqueueSnackbar(t("views.editor.dialogs.share.no_privileges"), {
          variant: "warning",
        });
        // setAlertText(t("views.editor.dialogs.share.no_privileges"));
        // setAlertState("warning");
        // showAlert(true);
        return;
      }
      
      // socket?.requestRemoteSave();
      await forceProjectSaveQuery(projectUuid);
      if(!pdfShare && presetStore.presetChanged)
        await presetStore.updatePreset();
      if (!pdfShare && usePresetModeButton)
        await presetStore?.addPresetToProject(projectUuid);
      
      setLoadingState(false);
      setOpenModal(true);
    };

    const linkInputRef = useRef();

    return (
      <>
        <Button
          icon={!openModal && isLoading ? <CircularProgress size={24} className="text-alt" /> : <ShareIcon />}
          onClick={handleClick}
          className={classnames(classes.shareButton, className)}
          name={t("views.editor.dialogs.share.accept")}
          style={style}
          size={size || "large"}
          variant="contained"
        >
          {t("views.editor.share")}
        </Button>
        {/*<Snackbar*/}
        {/*  open={alertVisible}*/}
        {/*  severity={alertState || "info"}*/}
        {/*  autoHideDuration={4000}*/}
        {/*  onClose={() => showAlert(false)}*/}
        {/*>*/}
        {/*  {alertText}*/}
        {/*</Snackbar>*/}
        <Dialog
          open={openModal}
          onClose={handleClose}
          width={600}
          title={t("views.editor.dialogs.share.title")}
          actions={
            <>
              {
                !pdfShare &&
                <PresetPicker
                  isLoading={isLoading}
                  commitPresetSelection
                  noPresetCreate
                />
              }
              <Button
                variant="contained"
                icon={
                  isCommitLoading ? (
                    <CircularProgress
                      aria-label="progress indicator"
                      size={24}
                      color="inherit"
                    />
                  ) : null
                }
                onClick={sendShareLink}
                className="w-max"
                disabled={noEmails}
              >
                {t("views.editor.dialogs.share.accept")}
              </Button>
            </>
          }
        >
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            className="mt-2 mb-8"
          >
            <TextField
              id="shareLink"
              value={link}
              className="m-0"
              onClick={getLink}
              inputProps={{ ref: linkInputRef }}
            />
            <ClearButton
              className="ml-2"
              onClick={getLink}
            >
              {t("common.copy")}
            </ClearButton>
          </Grid>

          <ProjectShareForm
            fieldName="technical"
            onSubmit={addEmail}
            onChange={setShareEmail}
            useFieldSpecificName
            forbiddenEmails={inviteEmails}
          />
          {!!shareInvites.length && (
            <Grid
              item
              container
              spacing={1}
              role="listbox"
              alignItems="flex-start"
              wrap="nowrap"
            >
              {shareInvites.map(({ e, l }) => (
                <Tooltip
                  title={t("views.editor.dialogs.share.link_click")}
                  key={e}
                >
                  <Grid item>
                    <Tag
                      text={e}
                      onRemove={removeEmail}
                      onClick={getUniqueLink}
                    />
                    <input value={l} className={classes.uniqueLinkInput} />
                  </Grid>
                </Tooltip>
              ))}
            </Grid>
          )}
        </Dialog>
      </>
    );
  }
);

Share.propTypes = {
  usePresetModeButton: bool,
  pdfShare: bool,
  style: object,
  className: string,
  size: oneOf(["small", "medium", "large"]),
};
