import { memo } from "react";
import { string, bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { InfoLabel } from "@components";
import { Grid, Switch } from "@material-ui/core";
import classnames from "classnames";

export const TimelineContentSwitch = memo(({
  title,
  value,
  onChange,
  nameOn,
  nameOff,
  isLast,
}) => {
  
  const {t} = useTranslation();
  
  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classnames(!isLast && "mb-4")}
    >
      <InfoLabel label={t(title)} className="mb-0" />
      <Switch
        size="small"
        color="primary"
        checked={value}
        onChange={onChange}
        name={ value ? nameOn : nameOff }
      />
    </Grid>
  );
});

TimelineContentSwitch.propTypes = {
  title: string.isRequired,
  value: bool.isRequired,
  onChange: func.isRequired,
  nameOn: string,
  nameOff: string,
  isLast: bool,
};