import { APIMethod, fetchData } from "@client";

export async function sendShareLinksToEmailsQuery(projectUuid, emails) {
  return await fetchData(
    `/project/link/${projectUuid}`,
    APIMethod.GET,
    {
      params: {
        email: emails.join(";"),
      },
    }
  );
}
