import { useState } from "react";
import { bool } from "prop-types";
import { MicrosoftLogo } from "@assets";
import { Alert, Button } from "@components";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "@client";
import { useTranslation } from "react-i18next";

export const MicrosoftSSO = ({ register }) => {
  const { t, i18n } = useTranslation();
  const { instance } = useMsal();

  const [msalAlertTitle, setMsalAlertTitle] = useState();
  const [msalAlertMessage, setMsalAlertMessage] = useState();
  const [msalAlert, showMsalAlert] = useState(false);

  const handleSSOLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      if (e.errorCode === "invalid_client") {
        const mes = e.errorMessage.match(/((\w+:)(.*))/g)?.[0]?.split(":");
        if (mes) {
          setMsalAlertTitle(mes[0]);
          setMsalAlertMessage(mes[1]);
        }
      } else if (e.errorCode && i18n.exists(`errors.msal.${e.errorCode}`))
        setMsalAlertTitle(t(`errors.msal.${e.errorCode}`));
      else {
        setMsalAlertTitle(t("errors.msal.alert.title"));
        setMsalAlertMessage(t("errors.msal.alert.content"));
        console.error("MSAL Error:", e.errorCode);
        showMsalAlert(true);
        throw e;
      }

      showMsalAlert(true);
    });
  };

  const handleAlertClose = () => {
    showMsalAlert(false);
    setMsalAlertTitle(undefined);
    setMsalAlertMessage(undefined);
  };

  return (
    <>
      <Button
        onClick={handleSSOLogin}
        icon={<MicrosoftLogo />}
        variant="contained"
        color="secondary"
        fullWidth
      >
        {t(`views.auth.sso.microsoft.${register ? "up" : "in"}`)}
      </Button>
      <Alert
        isOpen={msalAlert}
        title={msalAlertTitle}
        acceptText={t("common.close")}
        onAccept={handleAlertClose}
      >
        {msalAlertMessage}
      </Alert>
    </>
  );
};

MicrosoftSSO.propTypes = {
  register: bool,
};
