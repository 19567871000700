import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  
  container: {
    height: 24,
    paddingLeft: 8,
    paddingRight: 8,
    position: "absolute",
    transition: "transform 250ms ease",
    zIndex: 2,
    pointerEvents: "none"
  },
  containerExceeds: {
    paddingRight: 0,
  },
  block: {
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 8,
    borderRadius: 10,
  },
  exceeds: {
    background: `linear-gradient(to left, transparent, ${theme.palette.primary.main} 24px)`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default useStyle;