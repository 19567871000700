import { forwardRef, useState, useCallback } from "react";
import {
  bool,
  number,
  string,
  func,
  object,
  oneOfType,
  arrayOf,
} from "prop-types";
import { ClickAwayListener } from "@material-ui/core";
import { Input } from "./components/Input";
import { Static } from "./components/Static";
import useStyle from "./TextClearInput.style";
import classnames from "classnames";

export const TextClearInput = forwardRef(({
  value,
  onChange,
  onFocus,
  onClick,
  placeholder,
  acceptOnClickAway,
  style,
  confirmOnEnter,
  onAccept,
  focused,
  error,
  className,
  maxLength,
  minimal,
  filterChars,
  multiline,
  preventClickAwayOnElement,
  lockSpecialCharacters,
  inputClassName,
}, ref ) => {
    const classes = useStyle();

    const [caretPos, setCaretPos] = useState(0);

    const focus = (e) => {
      e.stopPropagation();
      if (!focused && !onClick) {
        const selection = window.getSelection(e);
        setCaretPos(selection.focusOffset);
        if (onFocus) onFocus();
      }
    };
    
    const handleInputRef = useCallback((r) => {
      if(ref) ref.current = r;
      if(!caretPos || !r) return;
      r.selectionStart = caretPos;
      r.selectionEnd = caretPos;
      setCaretPos(0);
    }, [caretPos]);

    const handleClick = useCallback((e) => {
      e.stopPropagation();
      onClick && onClick(e);
    }, [onClick]);

    const handleClickAway = (e) => {
      if(preventClickAwayOnElement && e.target.closest(preventClickAwayOnElement))
        return;
      if (focused && onAccept) onAccept();
    };

    const handleChange = useCallback((e) => {
      if (!onChange) return;
      /** @notice: characters excluded from module & project names */
      let p = lockSpecialCharacters
        ? e.target.value.replace(/[\\^{}`]/g, "")
        : e.target.value;
      filterChars?.forEach((char) => {
        p = p.replace(char, "");
      });
      onChange(maxLength ? p.slice(0, maxLength) : p);
    }, [onChange, filterChars, maxLength]);

    const handleAccept = useCallback(() => {
      onAccept && onAccept();
    }, [onAccept]);
    
    const inputProps = {
      value,
      error,
      minimal,
      placeholder,
      confirmOnEnter,
      handleChange,
      handleAccept,
      handleClick,
      multiline,
    }

    return (
      <div
        className={classnames(
          "noDrag",
          classes.root,
          {
            [classes.focused]: focused,
            [classes.error]: error,
            [classes.minimal]: minimal,
          },
          className
        )}
        style={style}
        onClick={focus}
        ref={ref}
      >
        {!focused ? (
          <Static
            value={value}
            placeholder={placeholder}
            multiline={multiline}
            className={inputClassName}
          />
        ) : (
          acceptOnClickAway
           ? <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
              <Input ref={handleInputRef} {...inputProps} className={inputClassName} />
            </ClickAwayListener>
           : <Input ref={handleInputRef} {...inputProps} className={inputClassName} />
        )}
      </div>
    );
  }
);

TextClearInput.propTypes = {
  value: oneOfType([number, string]),
  onChange: func,
  onFocus: func,
  onClick: func,
  placeholder: string,
  confirmOnEnter: bool,
  error: bool,
  onAccept: func,
  focused: bool,
  acceptOnClickAway: bool,
  style: object,
  className: string,
  maxLength: number,
  minimal: bool,
  multiline: bool,
  lockSpecialCharacters: bool,
  filterChars: arrayOf(string),
  preventClickAwayOnElement: string,
  inputClassName: string,
};

TextClearInput.defaultProps = {
  value: "",
  lockSpecialCharacters: true
}
