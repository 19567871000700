import { APIMethod, fetchData } from "@client";

export async function createNewProjectQuery(
  workspaceUuid,
  name,
  description,
  status,
  type,
  template
) {
  const data = {
    workspace: workspaceUuid,
    name,
    description,
    template:
      type !== "standard" || !template
        ? undefined
        : template?.template?.MLP?.id ||
          template?.template?.MVP?.id ||
          template?.template?.MMP?.id ||
          template.id ||
          undefined,
    type: type,
    size: "MLP",
    status,
  };

  const res = await fetchData("/project", APIMethod.PUT, {
    data,
    removeEmptyKeys: true,
  });

  return res.results?.uuid;
}
