import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  
  actionButton: {
    color: theme.props.text.alt,
  },
}));

export default useStyle;
