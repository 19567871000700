import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { Alert } from "@components";
import { LinearProgress } from "@material-ui/core";

export const PdfUploadProgress = observer(() => {
  const { stateStore } = useStores();
  const { t } = useTranslation();

  const { filesUploadProgress, showFilesUploadProgress } = stateStore;
  

  const progress = useMemo(
    () =>
      filesUploadProgress.reduce((p, c) => p + c, 0) /
      filesUploadProgress.length,
    [filesUploadProgress, showFilesUploadProgress]
  );
  
  return (
    <Alert
      isOpen={showFilesUploadProgress}
      title={t("alerts.file_processing")}
      actions={<LinearProgress value={progress} variant="determinate" />}
    />
  );
});
