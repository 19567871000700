import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "@hooks";
import { useNavigate } from "react-router-dom";
import { IconButton, Divider, Grid, Hidden } from "@material-ui/core";
import { Avatar, PopMenu, MenuButton } from "@components";
import {
  ExpandMore,
  ExpandLess,
  FolderOpen,
  PowerSettingsNew,
  Event,
} from "@material-ui/icons";
import { Users, Settings, LibraryIcon, Info, File, Jira } from "@assets";
import {
  archived,
  login,
  settings,
  subscription,
  team,
  library,
  help,
  templates,
  jiraIntegration,
} from "@paths";
import useStyle from "./MenuUser.style";
import { UpgradeButton } from "../TrialInfo/components/UpgradeButton";
import { useMsal } from "@azure/msal-react";
import { logoutUserQuery } from "@query";

export const MenuUser = observer(() => {
  const { userStore } = useStores();
  const classes = useStyle(isMobile);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accounts } = useMsal();

  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, show] = useState(false);

  const { hasTrialLeft, nonPayingUser, isAdmin, data } = userStore;
  const { avatarColor, ...user } = data;
  const { name } = userStore.workspace;

  const clickAwayHandler = () => show(false);

  const handleLogout = async () => {
    show(false);
    await logoutUserQuery();
    await userStore.clearUserData();
    // instance?.getTokenCache()?.storage?.clear();
    accounts?.splice(0,1);
    navigate(login);
  };

  return (
    <>
      <Divider orientation="vertical" className={classes.separator} />
      <Avatar color={avatarColor} {...user} />
      <Hidden xsDown>
        <p className="ml-2">{user.firstname}</p>
      </Hidden>
      <IconButton
        className={"ml-2 p-1"}
        aria-label="Show main menu"
        ref={setAnchorEl}
        onClick={() => show(!visible)}
      >
        {visible ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <PopMenu
        key="top-menu"
        show={visible}
        anchor={anchorEl}
        showArrow
        onClickAway={clickAwayHandler}
        className="pb-2"
        style={{ width: 210 }}
        placement="bottom-end"
      >
        <Grid
          container
          className={classes.menuTop}
          direction="row"
          wrap="nowrap"
          onClick={() => {
            clickAwayHandler();
            navigate(settings);
          }}
        >
          <Avatar color={avatarColor} {...user} size={32} />
          <div className={classes.menuBox}>
            <p className={classes.menuName}>{user.fullname}</p>
            <p className={classes.menuCompany}>{name || ""}</p>
          </div>
        </Grid>

        {nonPayingUser && (
          <Hidden mdUp>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              className={classes.paymentContainer}
            >
              <UpgradeButton
                onClick={clickAwayHandler}
                className={classes.upgradeButton}
              />
            </Grid>
          </Hidden>
        )}
        <Hidden mdUp>
          <MenuButton
            icon={<LibraryIcon />}
            to={library}
            onClick={clickAwayHandler}
          >
            {t("routes.library")}
          </MenuButton>
          <MenuButton icon={<File />} to={templates} onClick={clickAwayHandler}>
            {t("routes.templates")}
          </MenuButton>
        </Hidden>
        <MenuButton
          icon={<FolderOpen />}
          to={archived}
          onClick={clickAwayHandler}
        >
          {t("bars.menu.archived")}
        </MenuButton>
        {isAdmin ? (
          <MenuButton
            icon={<Users />}
            name="team"
            to={subscription}
            onClick={clickAwayHandler}
          >
            {t("bars.menu.subscription")}
          </MenuButton>
        ) : (
          <MenuButton
            icon={<Users />}
            name="team"
            to={team}
            onClick={clickAwayHandler}
          >
            {t("bars.menu.team")}
          </MenuButton>
        )}
        <Hidden smUp>
          <MenuButton icon={<Info />} to={help} onClick={clickAwayHandler}>
            {t("routes.help")}
          </MenuButton>
        </Hidden>
        {/*<MenuButton*/}
        {/*	icon={<Star />}*/}
        {/*	onClick={() => cStore.setOpenFeedback(true)}*/}
        {/*>{t('bars.menu.feedback')}</MenuButton>*/}
        <MenuButton
          icon={<Jira />}
          to={jiraIntegration}
          onClick={clickAwayHandler}
        >
          {t("routes.jira")}
        </MenuButton>
        {hasTrialLeft ? (
          <Hidden mdUp>
            <MenuButton
              icon={<Event />}
              href={process.env.REACT_APP_DEMO_BOOK_LINK}
              onClick={clickAwayHandler}
            >
              {t("views.subscription.details.book_demo")}
            </MenuButton>
          </Hidden>
        ) : (
          <MenuButton
            icon={<Event />}
            href={process.env.REACT_APP_DEMO_BOOK_LINK}
            onClick={clickAwayHandler}
          >
            {t("views.subscription.details.book_demo")}
          </MenuButton>
        )}
        <MenuButton
          icon={<Settings />}
          to={settings}
          onClick={clickAwayHandler}
        >
          {t("bars.menu.settings")}
        </MenuButton>
        <MenuButton
          icon={<PowerSettingsNew color="error" />}
          onClick={handleLogout}
        >
          {t("bars.menu.logout")}
        </MenuButton>
      </PopMenu>
    </>
  );
});
