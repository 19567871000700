import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  memberItem: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      fontSize: "16px",
      marginLeft: 14,
    },
  },
  button: {
    position: "absolute",
    top: -5,
    right: -5,
    opacity: 0.5,
  },
}));

export default useStyle;
