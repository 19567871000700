import { bool } from "prop-types";
import { observer } from "mobx-react";
import { parsePrice } from "project-structure";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { TotalContainer } from "../components";
import { useMemo } from "react";

export const TotalCost = observer(({
  revenue,
  displayMax,
  ...containerProps
}) => {
  
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  
  const { settings, visibility, totalCost, showTotalMaxCost } = structure;
  
  const { currencyObj, useMinMax, roundPrice } = settings;
  const { apply, hideTotalCost } = visibility;
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideTotalCost && "transparent-2") || undefined
  ), [visibilityMode, apply, hideTotalCost])
  
  return (
    <TotalContainer
      totalName={ revenue ? "Revenue" : "Cost" }
      min={parsePrice(useMinMax ? totalCost.min : totalCost.avg)}
      max={parsePrice(totalCost.max)}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      displayMax={showTotalMaxCost && displayMax}
      textSmaller={revenue}
      showDecimals={!roundPrice}
      allowNegative
      className={className}
      visibilityHidden={hideTotalCost}
      setVisibility={visibility.setHideTotalCost}
      {...containerProps}
    />
  );
})

TotalCost.propTypes = {
  isProposal: bool,
  divider: bool,
  revenue: bool,
  displayMax: bool,
}