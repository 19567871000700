import { useEffect } from "react";
import { arrayOf, object, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { Button, InfoLabel } from "@components";
import { Grid } from "@material-ui/core";
import { Trash } from "@assets";
import { WorkTypeSelect } from "./WorkTypeSelect";
import { UsersSelect } from "./UsersSelect";

const fieldName = "users";
const fieldNameWorkType = "workType";
const fieldNameJiraId = "jiraId";
const fieldNameApropoId = "apropoId";

export const Users = ({
  control,
  jiraUsers,
  usedJiraUsers,
  apropoUsers,
  workTypes,
}) => {
  const { t } = useTranslation();
  
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: fieldName,
  });
  
  useEffect(() => {
    if(jiraUsers)
      addInput();
  }, [])
  
  const addInput = () => {
    append({ [fieldNameJiraId]: null, [fieldNameApropoId]: null, [fieldNameWorkType]: null })
  }
  
  return (
    <>
      <InfoLabel spacing={8} label="views.bt.user_with_apropo_users_and_work_types" />
      {fields.map((field, index) => (
        <Grid
          key={field.id}
          item
          container
          alignItems="center"
          spacing={1}
          className="m-0"
        >
          <Grid item xs={3}>
            <UsersSelect
              control={control}
              name={`${fieldName}.${index}.${fieldNameJiraId}`}
              label={t("views.bt.user.jira")}
              users={jiraUsers}
              selectedUsers={usedJiraUsers}
            />
          </Grid>
          <Grid
            xs={3}
            item container
            wrap="nowrap"
            justifyContent="flex-end"
            alignItems="center"
          >
            <InfoLabel spacing={0} label="as" className="mr-2 w-max" />
            <UsersSelect
              control={control}
              users={apropoUsers}
              name={`${fieldName}.${index}.${fieldNameApropoId}`}
              label={t("views.bt.user.apropo")}
            />
          </Grid>
          <Grid
            xs={6}
            item container
            wrap="nowrap"
            justifyContent="flex-end"
            alignItems="center"
          >
            <InfoLabel spacing={0} label="logging to" className="mr-2 w-max" />
            <WorkTypeSelect
              control={control}
              name={`${fieldName}.${index}.${fieldNameWorkType}`}
              workTypes={workTypes}
            />
            <Button type="button" onClick={() => remove(index)}>
              <Trash color="error" />
            </Button>
          </Grid>
        </Grid>
      ))}
      {
        fields.length < jiraUsers.length &&
        <Button type="button" onClick={addInput}>
          {t("views.bt.add_import_rule")}
        </Button>
      }
    </>
  );
};

Users.propTypes = {
  control: object,
  usedJiraUsers: arrayOf(string).isRequired,
  apropoUsers: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  jiraUsers: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  workTypes: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
};