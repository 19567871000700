import { Switch } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";

export const BreakdownPricesSwitch = observer(() => {
  
  const { t } = useTranslation();
  const structure = useStructureStore();
  const { showPrices } = structure.settings;
  
  const handleShowPrices = () => structure.settings.setShowPrices(!showPrices);
  
  return (
    <>
      <p className="mx-2">{t("views.editor.show_prices")}</p>
      <Switch
        color="primary"
        checked={showPrices}
        onChange={handleShowPrices}
        name={
          showPrices
            ? "Hide prices from breakdown"
            : "Show prices in breakdown"
        }
      />
    </>
  )
})