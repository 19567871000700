import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  tableTitle: {
    paddingBottom: 16,
    paddingTop: 16,
    marginTop: -16,
    position: "sticky",
    top: 0,
    backgroundColor: theme.props.backgroundSecondary,
    zIndex: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginRight: 8,
  },
  secondary: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    marginRight: 8,
    width: "max-content",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spinner: {
    position: "relative",
    left: "calc(50% - 20px)",
  },
}));

export default useStyle;
