import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Replayer } from "rrweb";
import { getSessionDataQuery } from "@query";
import ResizeObserver from "react-resize-observer";
import {
  CircularProgress,
  Grid,
  IconButton,
  Slider,
  Paper,
  Container,
} from "@material-ui/core";
import { PlayArrow, Pause, Replay } from "@material-ui/icons";
import { ProjectDetails } from "../components/ProjectDetails";
import useStyle from "./SessionReplayer.style";
import "./SessionReplayer.css";
import { parseSessionTime } from "@utils";

let timer = null;
let scale;

export const SessionReplayer = () => {
  const classes = useStyle();
  const { sessionId, projectUuid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [replayTime, setReplayTime] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [isRunning, setPlayerState] = useState(false);

  const replayerAnchorRef = useRef(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const { session, customerEmail, customerInfo, createdAt } =
        await getSessionDataQuery(sessionId);

      const scaleH = (window.innerWidth - 48) / session[0].data.width;
      const scaleV = (window.innerHeight - 48) / session[0].data.height;
      scale = scaleV > scaleH ? scaleH : scaleV;

      window.replayer = new Replayer(session, {
        root: document.getElementById("replayerAnchor"),
        skipInactive: false,
        // UNSAFE_replayCanvas: true,
        blockClass: "___"
      });
      setTotalTime(window.replayer.getMetaData().totalTime);
      window.replayer.on("start", () => {
        setPlayerState(true);
        loopTimer();
      });
      window.replayer.on("resume", () => {
        setPlayerState(true);
        loopTimer();
      });
      window.replayer.on("pause", () => {
        setPlayerState(false);
        stopTimer();
      });
      window.replayer.on("finish", () => {
        setPlayerState(false);
        stopTimer();
        setReplayTime(window.replayer.getMetaData().totalTime);
      });
      window.replayer.on("state-change", resizeIframe);
      window.replayer.wrapper.style.transform = `translateY(-50%) scale(${scale})`;

      setUserInfo({ ...customerInfo, email: customerEmail, createdAt });
      setIsLoading(false);
      window.replayer.play();
    })();

    return () => {
      if (window?.replayer) {
        pause();
        delete window.replayer;
      }
    };
  }, []);

  useEffect(() => {
    resizeIframe();
  }, [replayerAnchorRef.current]);

  const resizeIframe = () => {
    if (window.replayer && replayerAnchorRef.current) {
      const box = replayerAnchorRef.current.getBoundingClientRect();
      const scaleH = box.width / window.replayer.iframe.width;
      const scaleV = box.height / window.replayer.iframe.height;
      const newScale = scaleV > scaleH ? scaleH : scaleV;
      if (newScale !== scale) {
        scale = newScale;
        window.replayer.wrapper.style.transform = `translateY(-50%) scale(${scale})`;
      }
    }
  };

  const loopTimer = () => {
    stopTimer();
    const update = () => {
      const time = window.replayer.getCurrentTime();
      setReplayTime(time < 0 ? 0 : time);
      if (replayTime < window.replayer.getMetaData().totalTime) {
        timer = window.requestAnimationFrame(update);
      }
    };
    timer = window.requestAnimationFrame(update);
  };
  const stopTimer = () => {
    if (timer) {
      window.cancelAnimationFrame(timer);
      timer = null;
    }
  };

  const resume = () => {
    if (replayTime === 0) window.replayer.play();
    else if (replayTime === window.replayer.getMetaData().totalTime) {
      setReplayTime(0);
      window.replayer.play(0);
    } else window.replayer.resume(replayTime);
  };

  const pause = () => {
    window.replayer.pause();
    setPlayerState(false);
    stopTimer();
  };

  const handleSlider = (event, newValue) => {
    if (isRunning) {
      window.replayer.pause();
      setPlayerState(false);
      stopTimer();
    }
    setReplayTime(newValue);
  };

  const handleSliderCommit = () => {
    window.replayer.play(replayTime);
    setPlayerState(true);
    loopTimer();
  };

  const parsedTime = replayTime / 1000;
  const parsedTotalTime = totalTime / 1000;

  return (
    <Container className="h-full">
      <Grid
        item
        container
        justifyContent="center"
        alignContent="flex-start"
        className="h-full pt-4"
        spacing={3}
      >
        <Grid item container xs={9} className="h-full">
          <Paper className={classes.paper}>
            {isLoading && (
              <CircularProgress
                aria-label="progress indicator"
                className={classes.spinner}
              />
            )}
            <div
              id="replayerAnchor"
              className={classes.replayerAnchor}
              ref={replayerAnchorRef}
            />
            <ResizeObserver onResize={resizeIframe} />
            <Grid
              item
              container
              direction="column"
              className={classes.controlPanel}
            >
              <Slider
                color="primary"
                value={replayTime}
                onChange={handleSlider}
                onChangeCommitted={handleSliderCommit}
                className={classes.slider}
                max={totalTime}
              />
              <Grid
                item container
                justifyContent="center"
                alignItems="center"
                className={classes.controls}
              >
                <p>
                  {parseSessionTime(parsedTime)}
                  {" / "}
                  {parseSessionTime(parsedTotalTime)}
                </p>
                <IconButton
                  color="inherit"
                  onClick={isRunning ? pause : resume}
                  className="text-4xl"
                  style={{ justifySelf: "center" }}
                >
                  {isRunning ? (
                    <Pause />
                  ) : replayTime === totalTime ? (
                    <Replay />
                  ) : (
                    <PlayArrow />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <ProjectDetails projectUuid={projectUuid} userInfo={userInfo} />
      </Grid>
    </Container>
  );
};
