import { useState } from "react";
import { arrayOf, bool, func, object, string } from "prop-types";
import { observer } from "mobx-react";
import { Controller } from "react-hook-form";
import { Checkbox } from "@components";
import { Grid } from "@material-ui/core";
import classNames from "classnames";

export const ControlledCheckboxGroup = observer(
  ({
    control,
    name,
    options,
    optionIdentifier = "id",
    optionLabel = "name",
    multiple,
    parseLabel,
    checked,
  }) => {
    const [multipleValue, setMultipleValue] = useState(
      checked ? options.map((option) => option[optionIdentifier]) : []
    );

    const handleCheckboxChange = (onChange, value) => (e) => {
      if (!multiple) {
        onChange(value);
        return;
      }

      const newV = e.currentTarget.checked
        ? [...multipleValue, value]
        : multipleValue.filter((v) => v !== value);
      setMultipleValue(newV);
      onChange(newV);
    };

    return (
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => (
          <Grid item container direction="column" wrap="nowrap">
            {options?.map((option, index) => {
              const id = option[optionIdentifier];
              const checked = multiple
                ? multipleValue?.includes(id)
                : value === id;

              return (
                <Checkbox
                  key={id}
                  name={name}
                  error={!!error}
                  checked={checked}
                  onChange={handleCheckboxChange(onChange, id)}
                  label={
                    parseLabel
                      ? parseLabel(option[optionLabel], index)
                      : option[optionLabel]
                  }
                  className={classNames("my-1")}
                />
              );
            })}
            {!!error && (
              <span className="mt-2 color-error">{error?.message}</span>
            )}
          </Grid>
        )}
      />
    );
  }
);

ControlledCheckboxGroup.propTypes = {
  control: object.isRequired,
  name: string.isRequired,
  options: arrayOf(object).isRequired,
  optionIdentifier: string,
  multiple: bool,
  parseLabel: func,
};
