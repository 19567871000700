import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";
import { unzipStructure } from "project-structure";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { omit } from "lodash";
import {
  ActiveProjectStore,
  ActiveProjectStoreProvider,
  ProjectCreatorStoreProvider,
} from "@stores";
import {
  getActiveProjectsQuery,
  getCompanySettingsQuery,
  initDemoProjectQuery,
  setCompanySettingsQuery,
  getCompanyProjectsStatusQuery,
  getAnnouncementsQuery,
  setAnnouncementReadStatusQuery,
} from "@query";
import { COMPANY_SETTINGS } from "@client";
import { useStores } from "@hooks";
import { PROJECT_COLUMN_COLORS, PROJECT_COLUMNS } from "@utils";
import { BTImportModal, BTInfo, JiraExportModal, Announcements } from "@dialogs";
import { PageCircularProgress, UploadSnackBar } from "@components";
import { ActiveProjectsView } from "./ActiveProjectsView";

export const ActiveProjects = observer(() => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const [searchParams, setSearchParams] = useSearchParams();

  const [columns, setColumns] = useState(null);
  const [announcements, setAnnouncements] = useState();
  const [btProjectModalVisible, showBTProjectModal] = useState(
    searchParams.get("btCb")
  );
  const [btQuery, setBTQuery] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [openImportBT, setOpenImportBT] = useState(searchParams.get("btSu"));

  const activeProjectStore = useRef(new ActiveProjectStore());

  const jiraRedirectProjectId = useMemo(() => btQuery?.projectId, [btQuery]);
  const jiraRedirectProjectName = useMemo(
    () => btQuery?.jiraProjectName,
    [btQuery]
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const abortController = new AbortController();
    load();
    return () => abortController.abort();
  }, []);
  
  const checkAnnouncements = async () => {
    const an = await getAnnouncementsQuery(userStore.userUuid);
    setAnnouncements(an);
  }

  useEffect(() => {
    if (searchParams.get("btCb")) {
      const data = JSON.parse(unzipStructure(searchParams.get("btCb")));
      setBTQuery(data);
      setSearchParams();
    }
    if (searchParams.get("btSu")) {
      setBTQuery(JSON.parse(unzipStructure(searchParams.get("btSu"))));
      setSearchParams();
    }
  }, []);

  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
    enqueueSnackbar(
      <UploadSnackBar
        uuid={jiraRedirectProjectId}
      />,
      {
        variant: "info",
        key: jiraRedirectProjectId,
        persist: true,
        preventDuplicate: true,
      }
    );
  };

  const load = async () => {
    
    await checkAnnouncements();
    
    if (stateStore.firstLogin) {
      await initDemoProjectQuery();
    }

    const p = await getActiveProjectsQuery(userStore.workspaceUuid);
    activeProjectStore.current.setProjects(p);
    const projectName =
      jiraRedirectProjectName ||
      p.find((p) => p.uuid === jiraRedirectProjectId)?.name ||
      "";
    setProjectName(projectName);

    try {
      const j = await getCompanyProjectsStatusQuery(userStore.data.companyId);
      activeProjectStore.current.setBtProjectsStatus(j.projects);
    } catch (e) {
      console.error("Could not fetch BT projects");
    }

    const cols = Object.keys(PROJECT_COLUMNS).reduce(
      (o, col) => [
        ...o,
        {
          code: PROJECT_COLUMNS[col],
          color: PROJECT_COLUMN_COLORS[col],
        },
      ],
      []
    );

    const namedCols = await getCompanySettingsQuery(
      COMPANY_SETTINGS.HOME_COLUMNS
    );
    if (namedCols) {
      cols.forEach((col) => {
        col.name = namedCols[col.code];
      });
    }

    setColumns(cols);
  };

  const handleColumnNameChange = useCallback(async (code, newName) => {
    const c = [...columns];
    const column = c.find((col) => col.code === code);
    if (!column) return;

    column.name = newName;

    await setCompanySettingsQuery(
      COMPANY_SETTINGS.HOME_COLUMNS,
      c.reduce((o, { code, name }) => ({ ...o, [code]: name }), {})
    );
    setColumns(c);
  }, [columns]);
  
  const shownAnnouncements = useMemo(() => {
    if(!announcements) return undefined;
    const keys = Object.keys(announcements);
    if(!keys.length) return undefined;
    return {
      announcements: announcements[keys[0]],
      type: keys[0]
    };
  }, [announcements]);
  
  const handleAnnouncementModalClose = () => {
    shownAnnouncements.announcements.forEach(a => {
      setAnnouncementReadStatusQuery(a.id);
    })
    const u = omit(announcements, shownAnnouncements.type);
    setAnnouncements(Object.keys(u).length ? u : undefined)
  }

  return !columns ? (
    <PageCircularProgress />
  ) : (
    <ActiveProjectStoreProvider projectStore={activeProjectStore.current}>
      <ProjectCreatorStoreProvider>
        <ActiveProjectsView
          columns={columns}
          handleColumnNameChange={handleColumnNameChange}
        />
      </ProjectCreatorStoreProvider>
      {
        shownAnnouncements &&
        <Announcements
          updates={shownAnnouncements.announcements}
          variant={shownAnnouncements.type}
          onClose={handleAnnouncementModalClose}
        />
      }
      {btProjectModalVisible && (
        <JiraExportModal
          onClose={() => showBTProjectModal(false)}
          uuid={jiraRedirectProjectId}
          projectJiraName={jiraRedirectProjectName}
          onOpenInfo={handleOpenInfo}
        />
      )}
      {openImportBT && (
        <BTImportModal
          onClose={() => setOpenImportBT(false)}
          uuid={jiraRedirectProjectId}
          isOpen={openImportBT}
        />
      )}
      {openInfo && (
        <BTInfo
          hideNotClose
          openModal
          title={t("views.bt.info_create_project", {
            projectName: `${projectName}`,
          })}
          onClose={handleCloseInfo}
          width={600}
          actions={<UploadSnackBar
            uuid={jiraRedirectProjectId}
          />}
        />
      )}
    </ActiveProjectStoreProvider>
  );
});
