import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  sectionButton: {
    backgroundColor: theme.palette.primary.main + "1a",
    borderColor: theme.palette.primary.main + "4d",
    borderStyle: "dashed !important",
    height: 30,
  },
}));

export default useStyle;
