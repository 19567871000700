import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { PriceCell } from "@components";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";
import classnames from "classnames";

export const CostCell = observer(({
  element,
  allowEdition,
  displayCellContent,
  profitabilityMode
}) => {
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { usesTwoValues, settings, visibility } = structure;
  const { useMinMax, currencyObj, roundPrice } = settings;
  const { apply, hideSummaryCost, hideMinValues, hideMaxValues } = visibility;
  const { parsedPrice, showPriceMax, hasResources, inBreakdown } = element;
  
  const classes = useStyle();
  
  const handleCostChange = (price, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCustomPrice({ [key]: price });
  };
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryCost && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryCost]);
  
  return (
    <PriceCell
      widest={usesTwoValues}
      displayContent={displayCellContent}
      value={useMinMax ? parsedPrice.min : parsedPrice.avg}
      valueMax={parsedPrice.max}
      onValueChange={handleCostChange}
      editable={allowEdition && !inBreakdown && !hasResources && !profitabilityMode}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={(useMinMax && allowEdition && !inBreakdown) || (showPriceMax && (!apply || !hideMaxValues))}
      className={classnames(classes.cell, className)}
      allowNegative
      inputClassName="input-cost"
    />
    //   {
    //     allowEdition && roundType === 2 && !mixedHoursCounting && displayCellContent &&
    //     <DifferentWorkTypePriceTooltip
    //       workType={element}
    //       isInFirstCell={isFirstCell}
    //     />
    //   }
    // </PriceCell>
  );
});

CostCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
  profitabilityMode: bool,
  isFirstCell: bool,
};

const useStyle = makeStyles(() => ({
  cell: {
    zIndex: 2
  }
}));