import pdf from "pdfjs";

/**
 * @param {Blob[]} documents  - pdfjs documents
 * @param {{doc: number,page: number}[]} pageSets - array of document-page pairs
 * */
export const multiDocExport = async (documents, pageSets) => {
  const pdfDoc = new pdf.Document();

  const files = [];
  for (const document of documents) {
    let blob = document;
    if (document instanceof Uint8Array) {
      // const data = await document.getData();
      blob = new window.Blob([document], { type: "application/pdf" });
    }
    const buffer = await blob.arrayBuffer();
    const ext = new pdf.ExternalDocument(buffer);
    files.push(ext);
  }

  for (const { doc, page, all } of pageSets) {
    pdfDoc.setTemplate(files[doc]);
    if (all) await pdfDoc.addPagesOf(files[doc]);
    else await pdfDoc.addPageOf(page, files[doc]);
  }

  const buff = await pdfDoc.asBuffer();
  return new window.Blob([buff], { type: "application/pdf" });
};
