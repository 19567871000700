import { bool, func, string } from "prop-types";
import { Button, Checkbox, ControlledTextField } from "@components";
import { CircularProgress, Link } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { terms } from "@paths";
import * as yup from "yup";
import { /*emailFilter,*/ emailRegex } from "./emailRegex";
import { TRIAL_DAYS } from "@utils";
import classnames from "classnames";

const registerFormSchema = (t, defaultEmail, useCompanyField) => {
  return yup.object().shape({
    fullname: yup
      .string()
      .required(t("errors.name.required"))
      .max(64, t("errors.name.max")),
    email: yup
      .string()
      .matches(emailRegex, t("errors.email.required"))
      // .matches(emailFilter, t("errors.email.work"))
      .required(t("errors.email.required"))
      .default(defaultEmail),
    password: yup
      .string()
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$&*.\\/"'%()+,\-:;<>?[\]^_{}|~])(?=.*[0-9])(?=.*[a-z]).{8,128}$/,
        t("errors.password.pattern")
      )
      .required(t("errors.password.required"))
      .min(8, t("errors.password.min"))
      .max(128, t("errors.password.max")),
    policy: yup.boolean().default(false).isTrue(t("errors.policy.required")),
    terms: yup.boolean().default(false).isTrue(t("errors.terms.required")),
    company: useCompanyField
      ? yup
          .string()
          .required(t("errors.company.name.required"))
          .max(64, t("errors.company.name.max"))
      : yup.string().nullable(true),
  });
};

export const RegisterForm = ({
  onSubmit,
  defaultEmail,
  isAccountActivation = false,
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(
      registerFormSchema(t, defaultEmail || "", !isAccountActivation)
    ),
    defaultValues: {
      email: defaultEmail || "",
      fullname: "",
      password: "",
      company: "",
      policy: false,
      terms: false,
    },
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="register form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <ControlledTextField
        name="fullname"
        control={control}
        label={t("forms.name")}
        autoComplete="name"
        fullWidth
      />
      <ControlledTextField
        name="email"
        control={control}
        label={t("forms.email")}
        autoComplete="email"
        type="email"
        disabled={!!defaultEmail}
        fullWidth
      />
      {!isAccountActivation && (
        <ControlledTextField
          name="company"
          control={control}
          label={t("forms.company.name")}
          fullWidth
        />
      )}
      <ControlledTextField
        name="password"
        type="password"
        control={control}
        label={t("forms.password.generic")}
        autoComplete="current-password"
        fullWidth
      />
      <Controller
        name="terms"
        control={control}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => (
          <Checkbox
            name={name}
            checked={value}
            onChange={onChange}
            error={!!error}
            labelClassName="mb-4"
            label={
              <>
                {t("forms.legal.read")}{" "}
                <Link
                  className={classnames(!!error && "color-error")}
                  component={RouterLink}
                  to={terms}
                  target="_blank"
                  tabIndex={-1}
                >
                  <strong>{t("forms.legal.terms")}</strong>
                </Link>
                {"."}
              </>
            }
          />
        )}
      />

      <Controller
        name="policy"
        control={control}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => (
          <Checkbox
            name={name}
            checked={value}
            onChange={onChange}
            error={!!error}
            label={
              <>
                {t("forms.legal.read")}{" "}
                <Link
                  className={classnames(!!error && "color-error")}
                  component={RouterLink}
                  to={terms}
                  target="_blank"
                  tabIndex={-1}
                >
                  <strong>{t("forms.legal.policy")}</strong>
                </Link>
                {"."}
              </>
            }
          />
        )}
      />

      <Button
        isSubmit
        name="submit"
        variant="contained"
        fullWidth
        className="mt-6"
        icon={
          isSubmitting ? (
            <CircularProgress
              aria-label="progress indicator"
              size={24}
              color="inherit"
            />
          ) : undefined
        }
      >
        {!isSubmitting && t(`views.register.confirm${isAccountActivation ? "_invite" : ""}`, {trialDays: TRIAL_DAYS})}
      </Button>
    </form>
  );
};

RegisterForm.propTypes = {
  onSubmit: func.isRequired,
  defaultEmail: string,
  isAccountActivation: bool,
};

