import { ACCESS_DOMAINS } from "../../client/accessDomains";
import { APIMethod, fetchData } from "../../client/api/fetchData";

export async function storeSetUpQuery(data) {
  return await fetchData(`api/set-up`, APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.btApi,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
}
