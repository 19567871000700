import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    margin: 0,
    padding: "0 24px",
  },
  text: {
    fontWeight: "400 !important",
    marginBottom: 12,
  },
  button: {
    marginTop: 24,
    marginBottom: 36,
  },
  embed: {
    width: "80%",
    aspectRatio: "16 / 9",
    marginBottom: 24,
  },
}));

export default useStyle;
