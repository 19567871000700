// Material components
import { SvgIcon } from "@material-ui/core";

export default (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M21 19.7032L16.0776 14.7808C17.0639 13.5023 17.6119 11.9498 17.6119 10.3059C17.6119 8.3516 16.8539 6.51598 15.4749 5.13699C14.0959 3.75799 12.2603 3 10.3059 3C8.3516 3 6.51598 3.75799 5.13699 5.13699C3.75799 6.51598 3 8.3516 3 10.3059C3 12.2603 3.75799 14.0959 5.13699 15.4749C6.51598 16.8539 8.3516 17.6119 10.3059 17.6119C11.9498 17.6119 13.5023 17.0639 14.7808 16.0685L19.7032 20.9909L21 19.7032ZM10.3059 15.7854C8.84475 15.7854 7.46575 15.2192 6.43379 14.1781C5.39269 13.1461 4.82648 11.7671 4.82648 10.3059C4.82648 8.84475 5.39269 7.46575 6.43379 6.43379C7.46575 5.39269 8.84475 4.82648 10.3059 4.82648C11.7671 4.82648 13.1461 5.39269 14.1781 6.43379C15.2192 7.46575 15.7854 8.84475 15.7854 10.3059C15.7854 11.7671 15.2192 13.1461 14.1781 14.1781C13.1461 15.2192 11.7671 15.7854 10.3059 15.7854Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
