import { Controller } from "react-hook-form";
import { object, string } from "prop-types";
import { Checkbox } from "@components";

export const ControlledCheckbox = ({
  name,
  control,
  ...textFieldProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <Checkbox
        name={name}
        checked={!!value}
        onChange={onChange}
        error={!!error}
        helperText={error?.message}
        { ...textFieldProps }
      />
    )}
  />
);

ControlledCheckbox.propTypes = {
  name: string.isRequired,
  control: object.isRequired,
  errors: object,
  helperText: string,
};
