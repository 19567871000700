import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  text: {
    fontSize: 16,
    letterSpacing: 0.29,
    textAlign: "center",
    marginBottom: 32,
    maxWidth: 290,
  },
  title: {
    letterSpacing: 0.53,
    textTransform: "uppercase",
  },
}));

export default useStyle;
