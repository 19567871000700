import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import useStyle from "./Table.style";

const TableRow = (props) => {
  const classes = useStyle();
  const { className, children } = props;

  return (
    <Grid
      item
      container
      role="row"
      wrap="nowrap"
      className={`${classes.row} ${className}`}
    >
      {children}
    </Grid>
  );
};

TableRow.propTypes = {
  /** content of table cell */
  children: PropTypes.arrayOf(PropTypes.node),
  /** additional JSS classes */
  className: PropTypes.string,
};

TableRow.defaultProps = {
  children: null,
  className: "",
};

export default TableRow;
