import { bool, string, node, func, oneOfType } from "prop-types";
import { MenuButton } from "@components";
import { AddCircleOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

export const SearchableListItem = ({ disabled, onClick, children }) => {
  const classes = useStyle();

  return (
    <Grid item container justifyContent="space-between" alignItems="center">
      <MenuButton
        icon={<AddCircleOutline color={disabled ? "disabled" : "primary"} />}
        className={classes.item}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </MenuButton>
    </Grid>
  );
};

SearchableListItem.propTypes = {
  onClick: func.isRequired,
  children: oneOfType([node, string]).isRequired,
  disabled: bool,
};

const useStyle = makeStyles(() => ({
  item: {
    width: "100%",
    height: "max-content",
    padding: "6px 8px 6px 16px",
    "& > span": {
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
    "& > span > p": {
      width: "100%",
      textAlign: "left",
      fontSize: 16,
    },
    "& svg": {
      fontSize: 24,
    },
    "& .MuiTouchRipple-ripple": {
      width: "100% !important",
      left: "0 !important",
    },
  },
}));
