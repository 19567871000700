import { useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  setSelectionColor,
  identifierToCoords,
  coordsToIdentifier,
} from "@utils";
import { Button } from "@components";
import {
  Grid,
  IconButton,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import { Spreadsheet, WorkTypeSelector } from "../../index";
import { Clear, ListAlt } from "@material-ui/icons";
import useStyle from "./Steps.style";
import { useProjectCreatorStore } from "@hooks";
import classnames from "classnames";
import { ColumnSelector } from "@tools/XlsImporter/components/ColumnSelector/ColumnSelector";
import { compact, union } from "lodash";

const legendModules = ["section", "module", "feature", "task"];

export const Breakdown = observer(() => {
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const creator = useProjectCreatorStore();
  
  const [columnMode, setColumnMode] = useState(null);
  const [moduleMode, setModuleMode] = useState(null);
  
  const spreadsheet = useRef(null);

  const {
    currentBreakdownData,
    importModules,
    descColumn,
    importWorkTypes,
    useMinMax,
    titleColumnsLength,
  } = creator;
  const { sheet, selection } = currentBreakdownData;

  const mergeCells = Object.keys(sheet.mergeCells).map((x) => [
    ...identifierToCoords(x),
    ...sheet.mergeCells[x],
  ]);
  
  const setSpreadsheet = (s) => {
    spreadsheet.current = s;
  }
  
  const handleColumnSelectMode = (index) => () => {
    if (moduleMode !== null) setModuleMode(null);
    setColumnMode(index === columnMode ? null : index);
  };

  const handleModuleSelectMode = (index) => (e) => {
    e.stopPropagation();
    if (columnMode !== null) setColumnMode(null);
    setModuleMode(index === moduleMode ? null : index);
  };

  const handleModuleSelection = (instance, x1, y1, x2, y2) => {
    //x -> column, y -> row

    const type = legendModules[moduleMode];
    const mergedCell = sheet.mergeCells[coordsToIdentifier(x1, y1)];
    const singleModule =
      (y2 - y1 === 0 && x2 - x1 === 0) ||
      (mergedCell &&
        x2 - x1 + 1 === mergedCell[0] &&
        y2 - y1 + 1 === mergedCell[1])
        ? importModules.find((s) => s.x === x1 && s.y === y1)
        : null; // moduleMode === -1;

    const clear = Boolean(
      singleModule && (moduleMode === null || singleModule.type === type)
    );

    if (clear || moduleMode !== null)
      [...Array(y2 - y1 + 1).keys()].forEach((y) => {
        const row = y + y1;
        if (isInSystemColl(x1)) return;

        creator.changeImportModules(row, x1, type, clear);

        setSelectionColor(
          [row],
          [x1],
          clear ? null : theme.props.selections[`level${moduleMode}`],
          spreadsheet.current
        );
      });
  };

  const handleColumnSelectionClick = (instance, x1) =>
    handleColumnSelection(x1);
  
  const handleColumnSelectionSelect = (colIndex) => (e) =>
    handleColumnSelection(e.target.value, colIndex);
  
  const handleColumnMaxSelectionSelect = (colIndex) => (e) =>
    handleColumnSelection(e.target.value, colIndex, true);
  
  const handleDescriptionCellSelect = (e) => {
    if(descColumn)
      colorSystemColumn([descColumn], true, true);
    colorSystemColumn([e.target.value], true);
    creator.setImportDescColumn(e.target.value);
  }
  
  const handleColumnSelection = (col, colIndex = null, isMax = false) => {
    const taken = importWorkTypes.find(
      (s) => s.column === col || s.columnMax === col
    );
    if (taken || (col < titleColumnsLength && col >= 0)) return;

    const t = [...importWorkTypes];
    const i = colIndex !== null ? colIndex : columnMode;
    if (isMax && t[i].columnMax && t[i].columnMax !== -1)
      colorSystemColumn([t[i].columnMax], true, true);
    else if (!isMax) colorSystemColumn([t[i].column], true, true);
    if (isMax) t[i].columnMax = col;
    else t[i].column = col;
    creator.setImportWorkTypes(t);

    colorSystemColumn([col]);
  };

  const removeColumn = (index) => () => {
    setSelectionColor(
      [...Array(selection[3] - selection[1] + 1).keys()],
      [
        importWorkTypes[index].column,
        ...(importWorkTypes[index].columnMax >= 0
          ? [importWorkTypes[index].columnMax]
          : []),
      ],
      null,
      spreadsheet.current
    );

    creator.setImportWorkTypes([
      ...importWorkTypes.slice(0, index),
      ...importWorkTypes.slice(index + 1),
    ]);
  };

  const addWorkType = () => {
    let column =
      importWorkTypes
        .map((s) => s.column)
        .sort((a, b) => (a > b ? 1 : -1))
        .findIndex((c, i) => c !== titleColumnsLength + i) + 1;
    if (column <= 0) column = titleColumnsLength + importWorkTypes.length;
    creator.setImportWorkTypes([
      ...importWorkTypes,
      { name: "", column: column },
    ]);
    colorSystemColumn([column]);
  };

  const getInitialCellStyles = () => {
    let updates = {};

    if (importModules.length)
      updates = {
        ...updates,
        ...importModules.reduce((u, m) => {
          const n = setSelectionColor(
            [m.y],
            [m.x],
            theme.props.selections[`level${legendModules.indexOf(m.type)}`],
            spreadsheet.current
          );
          return { ...u, ...n };
        }, {}),
      };

    if (importWorkTypes.length)
      updates = {
        ...updates,
        ...colorSystemColumn(
          importWorkTypes.reduce(
            (a, { column, columnMax }) => [
              ...a,
              column,
              ...(columnMax >= 0 ? [columnMax] : []),
            ],
            []
          ),
          false
        ),
      };

    return updates;
  };

  const colorSystemColumn = (cols, apply = true, removeColor = false) => {
    let rows = [...Array(selection[3] - selection[1] + 1).keys()];
    rows = rows.filter((x) => !isInMergeCells(x, cols));

    return setSelectionColor(
      rows,
      cols,
      !removeColor ? theme.props.selections.alt : null,
      apply ? spreadsheet.current : null
    );
  };

  const isInMergeCells = (row, cols) => {
    return !!mergeCells.find(
      (c) =>
        c[2] > 1 &&
        row >= c[1] &&
        row < c[1] + c[3] &&
        cols.reduce((p, x1) => p || (x1 >= c[0] && x1 < c[0] + c[2]), false)
    );
  };

  const isInSystemColl = (col) =>
    importWorkTypes.reduce((p, c) => p || c.column === col, false);

  const availableColumns = useMemo(() => [
      ...Array(Math.max(selection[2] - titleColumnsLength + 1, 0)).keys(),
    ].map((c) => c + titleColumnsLength - selection[0]),
    [selection, titleColumnsLength]
  );
  
  const takenColumns = useMemo(() => union(
    compact(importWorkTypes.reduce((l, s) => [
      ...l,
      ...(s.column ? [s.column] : []),
      ...(s.columnMax !== -1 ? [s.columnMax] : []),
    ], [])),
    (descColumn ? [descColumn] : []),
    (importModules.map((m) => (m.x)))
  ), [importWorkTypes, descColumn, importModules]
  );
  
  const availableDescColumns = useMemo(() => [
      ...Array(Math.max(selection[2] - selection[0] + 1, 0)).keys()
    ], [descColumn, takenColumns, titleColumnsLength, selection]
  );

  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      className="overflow-hidden px-6"
    >
      <Grid item container alignItems="center" className="w-full">
        <p>{t("views.import.sub_titles.modules")}</p>
      </Grid>
      <Grid item container alignItems="center" className={classnames("mt-1 mb-4", classes.moduleContainer)}>
        {legendModules.map((m, i) => (
          <Grid
            item
            container
            key={m}
            className={classnames(
              "m-0-5 py-1 px-2",
              classes.moduleBlock,
              classes.systemBlock,
              moduleMode === i && classes.systemBlockActive,
              
            )}
            style={
              moduleMode === i
                ? { backgroundColor: theme.props.selections["level" + i] }
                : null
            }
            onClick={handleModuleSelectMode(i)}
          >
            <p className="capitalize">
              {t("proposal_parts.module_levels.level_" + i)}
            </p>
            <IconButton onClick={handleModuleSelectMode(i)} color="inherit">
              <Tooltip title={t("views.import.select_rows." + i)}>
                <ListAlt />
              </Tooltip>
            </IconButton>
          </Grid>
        ))}
      </Grid>
      <Grid
        item container
        direction="column"
        className="mb-4"
      >
        <p className="mb-1">{t("views.import.sub_titles.description")}</p>
        <ColumnSelector
          name="desc"
          selectedColumn={descColumn}
          availableColumns={availableDescColumns}
          takenColumns={takenColumns}
          onChange={handleDescriptionCellSelect}
          useMinMax={useMinMax}
          className={classes.descSelector}
        />
      </Grid>
      <Grid item container alignItems="center" className="w-full">
        <p>{t("views.import.sub_titles.systems")}</p>
      </Grid>
      <Grid
        item container
        alignItems="center"
        className={classnames("mt-1 mb-4", classes.moduleContainer)}
      >
        {importWorkTypes.map((s, i) => (
          <Grid
            item
            container
            className={classnames(
              "m-0-5 py-1 px-2",
              classes.block,
              classes.systemBlock
            )}
            key={s.name + i}
          >
            <WorkTypeSelector index={i} name={s.name} id={s.id} />
            <ColumnSelector
              name={s.name}
              selectedColumn={s.column}
              availableColumns={availableColumns}
              takenColumns={takenColumns}
              onChange={handleColumnSelectionSelect(i)}
              useMinMax={useMinMax}
              className="ml-2"
            />
            {useMinMax && (
              <ColumnSelector
                name={s.name}
                selectedColumn={s.columnMax}
                availableColumns={availableColumns}
                takenColumns={takenColumns}
                onChange={handleColumnMaxSelectionSelect(i)}
                useMinMax={useMinMax}
                className="ml-2"
              />
            )}
            <Grid
              item
              container
              wrap="nowrap"
              style={{ width: "max-content", marginLeft: 8 }}
            >
              <IconButton
                onClick={handleColumnSelectMode(i)}
                color={columnMode === i ? "primary" : "secondary"}
              >
                <Tooltip title={t("views.import.select_column")}>
                  <ListAlt />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={removeColumn(i)}
                color={columnMode === i ? "primary" : "secondary"}
              >
                <Tooltip title={t("views.import.remove_column")}>
                  <Clear color="error" />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
        ))}
        {importWorkTypes.length + titleColumnsLength - 1 <
          selection[2] - selection[0] && (
          <Grid item container xs={3} className={classnames(classes.block, "m-0-5")}>
            <Button
              style={{ height: 44 }}
              className="w-full"
              color="primary"
              onClick={addWorkType}
            >
              {t("views.import.add_column")}
            </Button>
          </Grid>
        )}
      </Grid>
      {!!sheet && (
        <Spreadsheet
          spreadsheet={spreadsheet.current}
          title={sheet.title}
          data={sheet.data.slice()}
          colWidths={sheet.colWidths.slice()}
          columns={sheet.columns.slice()}
          mergeCells={sheet.mergeCells}
          minDimensions={sheet.minDimensions.slice()}
          onRangeSelect={
            columnMode !== null
              ? handleColumnSelectionClick
              : handleModuleSelection
          }
          initialCellStyles={getInitialCellStyles()}
          setSpreadsheet={setSpreadsheet}
        />
      )}
    </Grid>
  );
});
