import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { ValueCell, ResetValueButton } from "@components";
import { useMemo } from "react";

export const TimeCell = observer(({
  element,
  allowEdition,
  displayCellContent,
  profitabilityMode,
}) => {
  const { t } = useTranslation();
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  
  const {
    useMinMax,
    isTimelineTableUsed,
  } = structure.settings;
  
  const {
    apply,
    hideSummaryTime,
    hideMinValues,
    hideMaxValues,
  } = structure.visibility;
  
  const {
    time,
    isTimeOverwritten,
    showTimeMax,
    hasOnlyCost,
    hasResources,
  } = element;

  const handleTimeChange = (time, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCustomTime({ [key]: time });
  };

  const handleReset = () => {
    element.resetTimeValues();
  };
  
  const editable = useMemo(() => (
    allowEdition && !hasResources && !profitabilityMode && !isTimelineTableUsed
  ), [allowEdition, hasResources, profitabilityMode, isTimelineTableUsed]);
  
  const messageTitle = useMemo(() => {
    if(isTimelineTableUsed && allowEdition)
      return t("views.editor.timeline_time_alert.title")
  }, [isTimelineTableUsed, allowEdition]);
  
  const messageContent = useMemo(() => {
    if(isTimelineTableUsed && allowEdition)
      return t("views.editor.timeline_time_alert.content")
  }, [isTimelineTableUsed, allowEdition]);
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryTime && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryTime]);

  return (
    <ValueCell
      widest
      showDecimals
      value={useMinMax ? time.min : time.avg}
      valueMax={time.max}
      onValueChange={handleTimeChange}
      displayContent={!hasOnlyCost && displayCellContent}
      warning={isTimeOverwritten}
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={(allowEdition && useMinMax && !hasResources) || (showTimeMax && (!apply || !hideMaxValues))}
      editable={editable}
      textPresetClass="preset-summaryText"
      messageTitle={messageTitle}
      message={messageContent}
      className={className}
      inputClassName="input-time"
    >
      {displayCellContent && allowEdition && isTimeOverwritten && (
        <ResetValueButton action={handleReset}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
    </ValueCell>
  );
});

TimeCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
  profitabilityMode: bool,
};
