import { useState } from "react";
import { bool, number, string, func, node, arrayOf, oneOfType } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, TextField, Dialog, Checkbox } from "@components";
import useStyle from "./Alert.style.js";
import Grid from "@material-ui/core/Grid";

export const Alert = ({
  title,
  children,
  cancelText,
  acceptText,
  isOpen,
  onAccept,
  onCancel,
  confirmationTitle,
  confirmationValue,
  actions: otherActions,
  onIntermediate,
  intermediateText,
  useLockCheckbox,
  flipActionOrder,
  width,
  ...dialogProps
}) => {
  
  
  const {t} = useTranslation();
  const classes = useStyle();
  
  const [value, setValue] = useState("");
  const [lock, setLock] = useState(false);
  const [disabled, setDisabled] = useState(true);
  
  const handleAccept = (e) => {
    onAccept && onAccept(e, useLockCheckbox ? lock : undefined);
  };
  const handleCancel = (e) => {
    onCancel && onCancel(e, useLockCheckbox ? lock : undefined);
  };
  const handleIntermediate = (e) => {
    onIntermediate && onIntermediate(e, useLockCheckbox ? lock : undefined);
  };
  
  const handleChange = e => {
    const val = e.currentTarget.value;
    setValue(val);
    setDisabled(val !== confirmationValue);
  };
  const handleLockChange = () => {
    setLock(!lock);
  };
  
  const useIntermediate = Boolean(onIntermediate && intermediateText);
  const useAccept = Boolean(onAccept);
  const useCancel = Boolean(onCancel);
  
  const actions = <>
    {
      useLockCheckbox &&
      <Checkbox
        name="importTemplate"
        checked={lock}
        className="mr-2"
        onChange={handleLockChange}
        label={t("common.not_again")}
      />
    }
    {otherActions}
    {
      (useCancel || useAccept || useIntermediate || otherActions) &&
      <Grid
        item container
        justifyContent="flex-end"
        direction={ flipActionOrder ? "row-reverse" : "row" }
        className="w-max"
      >
        {
          useCancel && <Button
            variant="outlined"
            className={classes.button}
            onClick={handleCancel}
          >
            {cancelText || t("common.cancel")}
          </Button>
        }
        {
          useIntermediate &&
          <Button
            variant="outlined"
            className={classes.button}
            disabled={confirmationTitle && confirmationValue && disabled}
            onClick={handleIntermediate}
          >
            {intermediateText}
          </Button>
        }
        {
          useAccept &&
          <Button
            variant="contained"
            className={classes.button}
            disabled={confirmationTitle && confirmationValue && disabled}
            onClick={handleAccept}
          >
            {acceptText || t("common.accept")}
          </Button>
        }
      </Grid>
    }
  </>;
  
  const content = children || (confirmationTitle && confirmationValue) && <>
    {
      children && (
        typeof children === "string" ?
          <p className={classes.subText}>
            {children}
          </p> :
          children
      )
    }
    {
      !!(confirmationTitle && confirmationValue) &&
      <>
        <p className={classes.confirmTitle}>
          {confirmationTitle}
        </p>
        <TextField
          value={value}
          id="confirm-action"
          onChange={handleChange}
        />
      </>
    }
  </>;
  
  return <Dialog
    open={isOpen}
    onClose={useCancel ? handleCancel : handleAccept}
    actions={actions}
    title={title}
    titleClass={classes.title}
    width={width || (useIntermediate ? 540 : 380)}
    useCloseButton={false}
    actionsAlignment={useLockCheckbox ? "spaceBetween" : "end"}
    {...dialogProps}
  >
    {content}
  </Dialog>;
};

Alert.propTypes = {
  title: string,
  children: oneOfType([string, node]),
  cancelText: oneOfType([string, node]),
  acceptText: oneOfType([string, node]),
  isOpen: bool,
  onAccept: func,
  onCancel: func,
  confirmationTitle: string,
  confirmationValue: string,
  actions: oneOfType([
    arrayOf(node),
    node
  ]),
  onIntermediate: func,
  intermediateText: string,
  useLockCheckbox: bool,
  flipActionOrder: bool,
  width: number,
};
