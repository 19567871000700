import { useStructureStore } from "@hooks";
import { Button } from "@material-ui/core";
import { DescriptionCell, DescriptionRow, Info } from "@assets";
import { useTranslation } from "react-i18next";

export const DescriptionVariantSelector = () => {
  
  const {t} = useTranslation();
  const {settings} = useStructureStore();
  
  return (
   <>
     <span className="text-sm transparent-3 mx-3">
       {t("views.editor.desc_mode")}
     </span>
     <Button
       color="primary"
       variant={settings.descriptionMode === 1 ? "contained" : "outlined"}
       className="p-0-5 m-1 text-xl"
       style={{width: 32, height: 32, minWidth: 32}}
       onClick={() => settings.setDescriptionMode(1)}
     >
       <Info />
     </Button>
     <Button
       color="primary"
       variant={settings.descriptionMode === 2 ? "contained" : "outlined"}
       className="p-0-5 m-1 text-2xl"
       style={{width: 32, height: 32, minWidth: 32}}
       onClick={() => settings.setDescriptionMode(2)}
     >
       <DescriptionRow />
     </Button>
     <Button
       color="primary"
       variant={settings.descriptionMode === 3 ? "contained" : "outlined"}
       className="p-0-5 m-1 text-2xl"
       style={{width: 32, height: 32, minWidth: 32}}
       onClick={() => settings.setDescriptionMode(3)}
     >
       <DescriptionCell />
     </Button>
   </>
  )
}