import { useCallback, useEffect, useRef, useState } from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { COMPANY_SETTINGS } from "@client";
import { MAX_WORKTYPE_LENGTH } from "project-structure";
import {
  createCustomWorkTypeQuery, editCustomWorkTypeQuery, getCompanySettingsQuery,
  getDefaultRatesQuery,
  getWorkTypesQuery,
  setCompanySettingsQuery,
} from "@query";
import { Grid, IconButton, LinearProgress } from "@material-ui/core";
import {
  AddButtonFull,
  InfoLabel,
  TextClearInput,
} from "@components";
import { Close } from "@material-ui/icons";
import useStyle from "../Subviews.style";
import { RateElement } from "@views/Utility/Settings/subviews/Project/components/Rates/RateElement";

export const Rates = ({ currency }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const anchor = useRef(null);

  const [isLoading, setLoading] = useState(true);
  const [hiddenWorkTypes, setHiddenWorkTypes] = useState([]);
  const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
  const [showNewWorkTypeInput, addNewWorkType] = useState(false);
  const [newWorkTypeName, setNewWorkTypeName] = useState("");

  useEffect(() => {
    (async () => {
      const tags = await getWorkTypesQuery(true);
      let defRates = await getDefaultRatesQuery();
      let hidden = await getCompanySettingsQuery(COMPANY_SETTINGS.HIDDEN_WT);
      if(hidden)
        setHiddenWorkTypes(hidden);
      setSelectedWorkTypes(
        tags.map((tag) => ({
          ...tag,
          rate: defRates[tag.id] || 0,
        }))
      );
      setLoading(false);
    })();
  }, []);

  const addWorkType = (workType) => {
    setSelectedWorkTypes((selectedWorkTypes) => [
      ...selectedWorkTypes,
      { ...workType, rate: 0 },
    ]);
  };

  const updateRates = async (workTypes) => {
    await setCompanySettingsQuery(
      COMPANY_SETTINGS.RATES,
      workTypes.reduce(
        (rates, wT) =>
          wT.rate && wT.rate !== "0" ? { ...rates, [wT.id]: wT.rate } : rates,
        {}
      )
    );
  };

  const cancelNewWorkTypeAdd = () => {
    addNewWorkType(false);
    setNewWorkTypeName("");
  };

  const createCustomWorkType = async () => {
    if (!newWorkTypeName.length) return;
    const t = await createCustomWorkTypeQuery(newWorkTypeName);
    cancelNewWorkTypeAdd();
    addWorkType(t);
  };

  const handleWorkTypeRateChange = useCallback(async (id, value) => {
    const match = selectedWorkTypes.find((wT) => wT.id === id);
    match.rate = value;
    setSelectedWorkTypes([...selectedWorkTypes]);
    await updateRates(selectedWorkTypes);
  }, [selectedWorkTypes]);
  
  const handleWorkTypeColorChange = useCallback(async (id, value) => {
    const match = selectedWorkTypes.find((wT) => wT.id === id);
    match.backgroundColor = value;
    setSelectedWorkTypes([...selectedWorkTypes]);
    await editCustomWorkTypeQuery(match.id, match.name, value);
  }, [selectedWorkTypes]);
  
  const handleWorkTypeRemove = useCallback(async (id) => {
    const newWT = selectedWorkTypes.filter((wT) => wT.id !== id);
    setSelectedWorkTypes(newWT);
    await updateRates(newWT);
  }, [selectedWorkTypes]);
  
  const handleWorkTypeHide = useCallback(async (id, hide) => {
    let l = [...hiddenWorkTypes];
    if(hide)
      l.push(id);
    else
      l.splice(l.indexOf(id), 1);
    
    setHiddenWorkTypes(l);
    await setCompanySettingsQuery(COMPANY_SETTINGS.HIDDEN_WT, l);
  }, [hiddenWorkTypes]);

  return (
    <>
      <InfoLabel
        label="views.settings.projects.rates"
        tooltipText="views.settings.projects.rates_info"
      />
      <Grid item container alignItems="center">
        {isLoading && <LinearProgress variant="indeterminate" />}
        {selectedWorkTypes.map((wT) => (
          <RateElement
            {...wT}
            key={wT.id}
            currency={currency}
            hiddenWorkTypes={hiddenWorkTypes}
            onRateChange={handleWorkTypeRateChange}
            onColorChange={handleWorkTypeColorChange}
            onRemove={handleWorkTypeRemove}
            onHide={handleWorkTypeHide}
          />
        ))}
        {showNewWorkTypeInput ? (
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
            className={classes.workTypeRow}
          >
            <Grid item container>
              <TextClearInput
                onChange={setNewWorkTypeName}
                maxLength={MAX_WORKTYPE_LENGTH}
                value={newWorkTypeName}
                focused={true}
                onAccept={createCustomWorkType}
                placeholder={t("views.settings.projects.new_work_type")}
                confirmOnEnter
                style={{ maxWidth: "80%" }}
              />
            </Grid>
            <IconButton onClick={cancelNewWorkTypeAdd} size="small">
              <Close color="error" />
            </IconButton>
          </Grid>
        ) : (
          <AddButtonFull
            onClick={() => addNewWorkType(true)}
            ref={anchor}
            name="Manage breakdown work types"
            className="mt-2"
            disabled={isLoading}
          >
            {t("views.settings.projects.select_work_type")}
          </AddButtonFull>
        )}
      </Grid>
      {/*<Snackbar*/}
      {/*  open={tagRemovalErrorAlert}*/}
      {/*  severity="error"*/}
      {/*  autoHideDuration={4000}*/}
      {/*  onClose={() => setTagRemovalErrorAlert(false)}*/}
      {/*>*/}
      {/*  {t("views.settings.projects.remove_error")}*/}
      {/*</Snackbar>*/}
    </>
  );
};

Rates.propTypes = {
  currency: object,
};
