import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  label: {
    fontWeight: 600,
    lineHeight: "1.5 !important",
  },
  "spacing-8": {
    marginBottom: 8,
    "&:not(:first-child)": {
      marginTop: 8,
    },
  },
  "spacing-16": {
    marginBottom: 8,
    "&:not(:first-child)": {
      marginTop: 16,
    },
  },
  "spacing-32": {
    marginBottom: 8,
    "&:not(:first-child)": {
      marginTop: 32,
    },
  },
}));

export default useStyle;
