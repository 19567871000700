import { isMobile } from "react-device-detect";
import classnames from "classnames";
import { func } from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Button } from "@components";
import { XlsFile } from "@assets";
import { useXlsBorderStyle } from "@styles";
import { useTranslation } from "react-i18next";

export const XlsTemplateCard = ({ onSelect }) => {
  const { t } = useTranslation();
  const { xlsBorder } = useXlsBorderStyle();

  return (
    <Grid
      item
      container
      xs={isMobile ? 12 : 6}
      md={isMobile ? 12 : 4}
      className="p-3"
      onClick={onSelect}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classnames(
          xlsBorder,
          "p-4 rounded-2 border-dashed-2 cursor-pointer"
        )}
      >
        <XlsFile style={{ fontSize: 64 }} />
        <p className="mb-4">{t("views.new_project.xls")}</p>
        <p className="mb-4 transparent-2">{t("views.new_project.xls_desc")}</p>
        <Button variant="contained" color="primary">
          {t("common.import")}
        </Button>
      </Grid>
    </Grid>
  );
};

XlsTemplateCard.propTypes = {
  onSelect: func.isRequired,
};
