import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  // heatmapContainer: {
  // 	zIndex: 2,
  // 	position: "absolute !important",
  // 	top: 0,
  // 	left: 0,
  // 	width: "100%",
  // 	height: "100%",
  // 	pointerEvents: "none",
  // },
  sortable: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    alignContent: "flex-start",
    height: "max-content"
  },
}));

export default useStyle;
