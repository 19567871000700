import { bool } from "prop-types";
import {
  TotalCompanyCost,
  TotalCost,
  TotalProfit,
  TotalProfitability
} from "./totalsTypes";
import { Grid } from "@material-ui/core";

export const ProfitabilityTotals = ({ allowEdition }) => {
  
  
  return (
    <Grid
      container
      className="w-max mt-4"
      justifyContent="flex-end"
      spacing={5}
    >
      <TotalCompanyCost />
      <TotalCost revenue />
      <TotalProfit allowEdition={allowEdition} />
      <TotalProfitability allowEdition={allowEdition} />
    </Grid>
  )
}

ProfitabilityTotals.propTypes = {
  allowEdition: bool
}