import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    bottom: 0,
    zIndex: 100,
    backgroundColor: theme.props.background,
    padding: "0 16px",
    height: 55,
  },
  total: {
    fontSize: 12,
    letterSpacing: 0.22,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "max-content",
    fontSize: 12,
    "& p": {
      fontSize: 12,
    },
    "& svg": {
      fontSize: 20,
    },
  },
  selectBox: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    outline: 0,
    marginLeft: 8,
    marginRight: 16,
    "& > div": {
      fontSize: 12,
      padding: "2px 24px 2px 8px !important",
      backgroundColor: "transparent !important",
    },
    "& fieldset": {
      display: "none",
    },
    "& svg": {
      right: 2,
      top: "calc(50% - 10px)",
    },
  },
  selectMenu: {
    "& .MuiMenu-paper": {
      width: 46,
      backgroundColor: theme.props.backgroundSecondary,
    },
  },
  pageBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "max-content",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    marginRight: 6,
  },
  pageNum: {
    lineHeight: "1.45rem !important",
    height: 22,
    width: 20,
    textAlign: "center",
  },
  pageButton: {
    padding: 0,
  },
  ofPages: {
    width: 30,
    textAlign: "right",
  },
}));

export default useStyle;
