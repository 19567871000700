import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  progress: {
    position: "relative",
    left: "50%",
    marginLeft: -20,
  },
  recommended: {
    fontSize: 11,
    marginLeft: 8,
    color: theme.palette.primary.main,
  },
  empty: {
    margin: "8px 16px",
    color: theme.palette.text.secondary,
  },
}));

export default useStyle;
