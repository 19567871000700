import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  name: {
    marginRight: 8,
    width: "max-content",
    fontSize: 16,
    fontWeight: "bold",
  },
  notFound: {
    margin: 16,
  },
  status0: { color: theme.props.inactive },
  status1: { color: theme.props.active },
  "status-1": { color: theme.palette.primary.main },
}));

export default useStyle;
