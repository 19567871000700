import { APIMethod, fetchData } from "@client";

export async function connectToJiraQuery(webTriggerUrl, domain = null) {
  const res = await fetchData("/v1/jira/authorize", APIMethod.POST, {
    data: domain ? { domain } : undefined,
  });

  const token = res.token;
  const wtRes = await fetch(`https://${webTriggerUrl}`, {
    method: APIMethod.POST,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token })
  });
  return wtRes.status === 200;
}