import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  avatar: {
    "&:last-child": {
      marginRight: 0,
    },
  },
  more: {
    textTransform: "uppercase",
    marginLeft: 10,
  },
  listItem: {
    width: "100%",
    justify: "flex-start",
    alignItems: "center",
    padding: "0 12px",
    margin: "4px 0",
    fontSize: 14,
  },
  listAvatar: {
    marginRight: 12,
  },
}));

export default useStyle;
