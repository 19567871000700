import { bool, node, string } from "prop-types";
import { Button } from "@components";
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Header1, Header2, Header3 } from "@assets";
import {
  Code,
  FormatBold,
  FormatItalic, FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatUnderlined,
  Link,
  StrikethroughS,
} from "@material-ui/icons";
import useStyle from "./QuillToolbar.style";
import classnames from "classnames";
import { forwardRef } from "react";

export const QuillToolbar = forwardRef(({
  id,
  children,
  visible,
  float
}, ref) => {
  
  const classes = useStyle();
  
  return (
    <Grid
      id={id}
      ref={ref}
      item container
      alignItems="center"
      className={classnames(
        classes.root,
        float ? "w-max" : "w-full",
        !visible && "hidden",
        float && "m-0"
      )}
    >
      {children}
      {Boolean(children) && (
        <Divider
          orientation="vertical"
          className={classes.divider}
        />
      )}
      {
        toolbarButtons.map((s, i) => {
          if(s === "|")
            return <Divider
              key={s+i}
              orientation="vertical"
              className={classes.divider}
            />
          
          const Icon = s[1];
          
          return (
            <Button
              key={s[0]+i}
              className={classnames(classes.button, `ql-${s[0]} p-0-5 text-lg`)}
              size="small"
              value={s[2]}
            >
              <Icon />
            </Button>
          )
        })
      }
    </Grid>
  )
});

QuillToolbar.propTypes = {
  id: string.isRequired,
  children: node,
  visible: bool,
  float: bool,
}

const toolbarButtons = [
  ["bold", FormatBold],
  ["italic", FormatItalic],
  ["underline", FormatUnderlined],
  ["strike", StrikethroughS],
  ["code", Code],
  ["link", Link],
  "|",
  ["header", Header1, "1"],
  ["header", Header2, "2"],
  ["header", Header3, "3"],
  "|",
  ["list", FormatListNumbered,  "ordered"],
  ["list", FormatListBulleted, "bullet"],
  "|",
  ["blockquote", FormatQuote],
  ["code-block", Code],
]