import { useRef } from "react";
import { bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@material-ui/core";
import { ClearButton, TextClearInput } from "@components";
import useStyle from "../Subviews.style";
import classnames from "classnames";

export const ActiveDomain = ({
  domain,
  cnameName,
  cnameValue,
  useDomain,
  isLoading,
  domainFocused,
  actionBlocked,
  saveDomain,
  handleDomainChange,
  handleDomainFocus,
}) => {
  
  const { t } = useTranslation();
  const classes = useStyle();
  
  const domainNameInputRef = useRef(null);
  const cnameNameInputRef = useRef(null);
  const cnameValueInputRef = useRef(null);
  
  const getDomainName = () => {
    const input = domainNameInputRef.current?.querySelector("input");
    if(!input) return;
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  const getCnameName = () => {
    const input = cnameNameInputRef.current?.querySelector("input");
    if(!input) return;
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  const getCnameValue = () => {
    const input = cnameValueInputRef.current?.querySelector("input");
    if(!input) return;
    input.select();
    input.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  
  return <Grid
    item
    container
    alignItems="center"
    className="text-md"
  >
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className={classes.workTypeHeaderRow}
    >
      <Grid
        xs={12} sm={2} md={4}
      >
        {t("views.settings.projects.cname_record")}
      </Grid>
      <Grid
        item container
        alignItems="center"
        wrap="nowrap"
        xs={6} sm={5} md={4}
      >
        {t("views.settings.projects.cname_name")}
      </Grid>
      <Grid
        item container
        alignItems="center"
        wrap="nowrap"
        xs={6} sm={5} md={4}
      >
        {t("views.settings.projects.cname_value")}
      </Grid>
    </Grid>
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className={classes.workTypeRow}
    >
      <Grid
        xs={12} sm={2} md={4}
        className={classnames(!useDomain && classes.unused)}
      >
        {t("views.settings.projects.domain")}
      </Grid>
      <Grid
        item container
        alignItems="center"
        wrap="nowrap"
        xs={6} sm={5} md={4}
        className="relative"
      >
        <TextClearInput
          value={domain}
          onChange={handleDomainChange}
          onAccept={saveDomain}
          confirmOnEnter
          acceptOnClickAway
          focused={domainFocused}
          onFocus={handleDomainFocus}
          disabled={isLoading}
          className={classnames("mr-4",!useDomain && classes.unused)}
          placeholder={t("views.settings.projects.domain_placeholder")}
          filterChars={["/", ":"]}
        />
        { isLoading && <CircularProgress size={18} className="absolute r-0" /> }
      </Grid>
      <Grid
        item container
        alignItems="center"
        wrap="nowrap"
        xs={6} sm={5} md={4}
      >
        <TextClearInput
          ref={domainNameInputRef}
          value="domain.apropo.io"
          focused
          minimal
          onClick={getDomainName}
          className={classnames( !useDomain && classes.unused)}
        />
        <ClearButton
          disabled={!cnameName}
          className={classes.copyButton}
          onClick={getDomainName}
          color={useDomain ? "primary" : "secondary"}
        >
          {t("common.copy")}
        </ClearButton>
      </Grid>
    </Grid>
    {!actionBlocked && (
      <>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.workTypeRow}
        >
          <Grid
            item container
            xs={12} sm={2} md={4}
            alignItems="center"
            className={classnames(classes.cell, !useDomain && classes.unused)}
          >
            {t("views.settings.projects.ssl")}
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            wrap="nowrap"
            xs={6} sm={5} md={4}
          >
            {cnameName ? <>
              <TextClearInput
                ref={cnameNameInputRef}
                value={cnameName}
                alignContent="center"
                focused
                minimal
                onClick={getCnameName}
                className={classnames(!useDomain && classes.unused)}
              />
              <ClearButton
                disabled={!cnameName}
                className={classes.copyButton}
                onClick={getCnameName}
                color={useDomain ? "primary" : "secondary"}
              >
                {t("common.copy")}
              </ClearButton>
            </> : (
              <p className={classes.notReady}>
                {t("views.settings.projects.not_ready")}
              </p>
            )}
          </Grid>
          {/*<Grid*/}
          {/*  item xs={2} sm={5} md={6} lg={7}*/}
          {/*  className={classnames(!useDomain && classes.unused)}*/}
          {/*>*/}
          {/*  {t("views.settings.projects.cname_value")}*/}
          {/*</Grid>*/}
          <Grid
            item
            container
            alignItems="center"
            wrap="nowrap"
            xs={6} sm={5} md={4}
          >
            {cnameValue ? <>
              <TextClearInput
                ref={cnameValueInputRef}
                value={cnameValue}
                alignContent="center"
                focused
                minimal
                onClick={getCnameValue}
                className={classnames(!useDomain && classes.unused)}
              />
              <ClearButton
                disabled={!cnameValue}
                className={classes.copyButton}
                onClick={getCnameValue}
                color={useDomain ? "primary" : "secondary"}
              >
                {t("common.copy")}
              </ClearButton>
            </> : (
              <p className={classes.notReady}>
                {t("views.settings.projects.not_ready")}
              </p>
            )}
          </Grid>
        </Grid>
      </>
    )}
  </Grid>;
}


ActiveDomain.propTypes = {
  domain: string,
  cnameName: string,
  cnameValue: string,
  actionBlocked: bool,
  isLoading: bool,
  useDomain: bool,
  domainFocused: bool,
  handleDomainChange: func,
  saveDomain: func,
  handleDomainFocus: func,
}