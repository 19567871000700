import { useMemo, useRef } from "react";
import { bool, number, object, oneOf } from "prop-types";
import { observer } from "mobx-react";
import { roundFloat } from "project-structure";
import { useBreakdownValueAlert, useStructureStore } from "@hooks";
import { DifferentValueAlert } from "@dialogs";
import { PriceCell } from "@components";
import classnames from "classnames";

export const TaskPrice = observer(({
  element,
  allowEdition,
  actualDepthLevel,
  isLibrary,
  isSectionRow,
  numOfVisibleWorkTypes,
  opened,
  tableDepth,
}) => {
  
  const structure = useStructureStore();
  
  const changeValueData = useRef(null);
  
  const { estimateValueLevel, settings, visibility, usesTwoValues } = structure;
  const { apply, hideMinValues, hideMaxValues} = visibility;
  
  const {
    currencyObj,
    roundPrice,
    useMinMax,
    showBreakdownParentValues,
  } = settings;
  
  const {
    price,
    priceMax,
    showMaxPrice,
    hasChildren,
    parentHoldsPrice,
  } = element;
  
  const {
    valueAlertVisible,
    handleValueAlertClose: onValueAlertClose,
    handleValueAlertOpen,
  } = useBreakdownValueAlert();
  
  const holdsSomeValues = Boolean(Object.entries(element.holdsViewedHours).length);
  
  const displayCurrentCellContent = useMemo(() => (
    !opened || !hasChildren || tableDepth + 1 > estimateValueLevel
  ), [actualDepthLevel, estimateValueLevel, opened, hasChildren, tableDepth]);
  
  const displayCellContent = useMemo(() => (
    actualDepthLevel <= estimateValueLevel &&
    (showBreakdownParentValues || displayCurrentCellContent)
  ), [actualDepthLevel, estimateValueLevel, showBreakdownParentValues, displayCurrentCellContent]);
  
  const blurDisplayedValue = useMemo(() => (
    displayCellContent && !displayCurrentCellContent
  ), [displayCellContent, displayCurrentCellContent]);
  
  const parsedPrice = useMemo(() => (
    parentHoldsPrice
      ? undefined
      : roundFloat(price, roundPrice ? 3 : 0, !roundPrice)
  ), [price, roundPrice, parentHoldsPrice]);
  
  const parsedPriceMax = useMemo(() => (
    parentHoldsPrice
      ? undefined
      : roundFloat(priceMax, roundPrice ? 3 : 0, !roundPrice)
  ), [priceMax, roundPrice, parentHoldsPrice]);
  
  const displayPriceMax = useMemo(() => (
    useMinMax && (showMaxPrice || (allowEdition && !isLibrary))
  ), [useMinMax, showMaxPrice, allowEdition, isLibrary])
  
  const parentHoldsAllValues = parentHoldsPrice && !allowEdition || Boolean(numOfVisibleWorkTypes && Object.entries(element.parentHoldsHours).length === numOfVisibleWorkTypes);
  // const holdsSelfOrChildValues = Boolean(Object.entries(element.holdsViewedHours).length || parsedPrice || (useMinMax && parsedPriceMax));
  
  
  const handlePriceChange = (price, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    
    if(!element.hasCustomPrice && (element.hasFixedPrice || element.hasChildren)) {
      changeValueData.current = { key, price };
      handleValueAlertOpen();
      return true;
    }
    else
      element.setCustomPrice({ [key]: price }, hasChildren, hasChildren);
  };
  
  const handleValueAlertClose = (clearChildren) => {
    onValueAlertClose();
    if(typeof clearChildren === "undefined") return;
    if(changeValueData.current) {
      const {key, price} = changeValueData.current;
      element.setCustomPrice({ [key]: price }, clearChildren, clearChildren);
    }
  }

  return (
    <PriceCell
      wider={usesTwoValues}
      value={parsedPrice}
      valueMax={parsedPriceMax}
      editable={allowEdition && !isLibrary}
      onValueChange={handlePriceChange}
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={displayPriceMax && (!apply || !hideMaxValues)}
      noContainerBackground={isSectionRow}
      displayContent={element.hasCustomPrice || (((displayCellContent && !parentHoldsAllValues) || holdsSomeValues) && actualDepthLevel <= estimateValueLevel)}
      showDecimals={!roundPrice}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      usePresetClass={!isSectionRow}
      useSectionClass={isSectionRow}
      allowNegative
      inputClassName={classnames(
        "input-price",
        blurDisplayedValue && "transparent-2",
      )}
    >
      {allowEdition && valueAlertVisible && (
        <DifferentValueAlert
          moduleLevel={actualDepthLevel}
          handleValueAlertClose={handleValueAlertClose}
        />
      )}
    </PriceCell>
  )
});

TaskPrice.propTypes = {
  element: object.isRequired,
  numOfVisibleWorkTypes: number.isRequired,
  actualDepthLevel: oneOf([0, 1, 2, 3]),
  allowEdition: bool,
  isLibrary: bool,
  displayCellContent: bool,
  isSectionRow: bool,
  opened: bool,
  tableDepth: bool,
};