import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Switch } from "@material-ui/core";
import { Percent } from "@assets";

export const EstimateTitle = ({
  expanded,
  onExpand
}) => {
  
  const { t } = useTranslation();
  
  return (
    <Grid item container justifyContent="space-between" alignItems="center">
      <Grid item container wrap="nowrap" alignItems="center" className="w-max">
        <Percent className="color-primary text-2xl mr-2" />
        {t("views.editor.dialogs.estimate.subtitle")}
        {": "}
      </Grid>
      <Switch
        color="primary"
        checked={expanded}
        onChange={onExpand}
        name={
          expanded
            ? "Disable automatic estimation"
            : "Enable automatic estimation"
        }
      />
    </Grid>
  );
};

EstimateTitle.propTypes = {
  expanded: bool.isRequired,
  onExpand: func.isRequired,
}