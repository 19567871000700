import { fetchData } from "@client";
import { APIMethod } from "../../client/api/fetchData";
import { ACCESS_DOMAINS } from "../../client/accessDomains";

export async function syncIssuesQuery(apropo_project_id) {
  return await fetchData(`api/import/sync`, APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.btApi,
    data: {
      apropo_project_id: apropo_project_id,
    },
  });
}