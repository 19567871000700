import { useMemo, useRef } from "react";
import { bool, number, string, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  useBreakdownValueAlert,
  useProjectEditorStore,
  useRiskBufferAlert,
  useStructureStore,
  useWorkTypePermitChecker
} from "@hooks";
import { intersectionBy } from "lodash";
import { getSectionWtClass } from "@utils";
import { DifferentValueAlert } from "@dialogs";
import { ValueCell, ResetValueButton, Alert } from "@components";
import {TaskWorkTypeOverheadTooltip} from "./components/TaskWorkTypeOverheadTooltip"
import classnames from "classnames";

export const TaskWorkType = observer(({
  element,
  workType,
  editable,
  emptyValue,
  actualDepthLevel,
  tableDepth,
  opened,
  className,
  ...valueCellProps
}) => {

  const { editionGrant } = useWorkTypePermitChecker(workType.id);

  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { t } = useTranslation();
  
  const { estimateValueLevel, settings, visibility, usesTwoValues } = structure;
  const { apply, hideMinValues, hideMaxValues } = visibility;
  const {
    useMinMax,
    riskBuffer,
    roundType,
    roundHours,
    roundLevel,
    usedRoundDecimals,
    modifier,
    showBreakdownParentValues,
  } = settings;
  const { hasChildren } = element;
  
  const changeValueData = useRef(null);
  
  const {
    riskAlertVisible,
    handleRiskAlertClose,
    handleRiskAlertOpen,
  } = useRiskBufferAlert();
  
  const {
    valueAlertVisible,
    handleValueAlertClose: onValueAlertClose,
    handleValueAlertOpen,
  } = useBreakdownValueAlert();
  
  const isDifferentPercent = element.isValueOverridden?.(workType.id);
  const values = element.getTotalTimePerWorkType(workType.id);
  const cleanValues = element.unmodifiedTaskValues()?.get(workType.id.toString());
  const visible = !structure.isWorkTypeOff(workType.id);
  
  const handleValueChange = (value, isMax = false) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    const v = parseFloat((value * modifier).toPrecision(12));
    const oldValue = element.getTotalTimePerWorkType(workType.id)?.[key];
    const oldUnmodifiedValue = element.values?.get(workType.id.toString())?.[key];
    
    if(!element.holdsViewedHours[workType.id] && oldValue && element.hasChildren) {
      changeValueData.current = { key, value: v };
      handleValueAlertOpen();
      return true;
    }
    else if(oldUnmodifiedValue === v)
      return true;
    else
      element.changeValue(workType.id, { [key]: v }, false, element.hasChildren, element.hasChildren);

    handleRiskAlertOpen();
  };
  
  const handleValueAlertClose = (clearChildren) => {
    onValueAlertClose();
    if(typeof clearChildren === "undefined") return;
    if(changeValueData.current) {
      const {key, value} = changeValueData.current;
      element.changeValue(workType.id, { [key]: value }, false, clearChildren, clearChildren);
    }
  }
  
  const recoverAutoCountData = () => {
    element.resetPercentValues(workType.id, true);
  };
  
  const allowEdition = useMemo(
    () => editable && editionGrant,
    [editable, editionGrant]
  );
  
  const valueVisible = useMemo(
    () => visible && editionGrant,
    [visible, editionGrant]
  );
  
  const holdsHours = element.holdsViewedHours[workType.id];
  
  const showHours = Boolean(!element.parentHoldsHours[workType.id] || (
    element.parentHoldsNonOverriddenPercentHours[workType.id]
    && (
      workType.hasPercent
        ? intersectionBy(workType.of || [], Object.keys(element.holdsViewedHours), (v) => v.toString())?.length
        : Object.entries(element.holdsViewedHours).length
    )
  ));
  
  const displayCurrentCellContent = useMemo(() => (
    !opened || !hasChildren || holdsHours || tableDepth + 1 > estimateValueLevel
  ), [actualDepthLevel, estimateValueLevel, opened, hasChildren, tableDepth, holdsHours]);
  
  const displayCellContent = useMemo(() => (
    actualDepthLevel <= estimateValueLevel &&
    (showBreakdownParentValues || displayCurrentCellContent)
  ), [actualDepthLevel, estimateValueLevel, showBreakdownParentValues, displayCurrentCellContent]);
  
  const blurDisplayedValue = useMemo(() => (
    displayCellContent && !displayCurrentCellContent
  ), [displayCellContent, displayCurrentCellContent]);
  
  const value = useMemo(() =>
      (emptyValue || !showHours)
        ? undefined
        : Number(
          ((useMinMax ? values.min || 0 : values.avg || 0) / modifier).toPrecision(12)
        ),
    [values, useMinMax, emptyValue, showHours, modifier]
  );
  
  const valueMax = useMemo(() =>
      (emptyValue || !showHours)
        ? undefined
        : Number(
          ((values.max || 0) / modifier).toPrecision(12)
        ),
    [values, emptyValue, showHours, modifier]
  );
  
  const originalValue = useMemo(() =>
      (emptyValue || !showHours)
        ? undefined
        : Number(
          ((useMinMax ? cleanValues?.min || 0 : cleanValues?.avg || 0) / modifier).toPrecision(12)
        ),
    [values, useMinMax, emptyValue, showHours, modifier]
  );
  
  const originalValueMax = useMemo(() =>
      (emptyValue || !showHours)
        ? undefined
        : Number(
          ((cleanValues?.max || 0) / modifier).toPrecision(12)
        ),
    [values, emptyValue, showHours, modifier]
  );
  
  const showValueMax = useMemo(
    () => useMinMax && (editable || value !== valueMax),
    [values, useMinMax, editable, modifier]
  );
  
  const displayMinMaxSeparator = useMemo(() => (
    !emptyValue && (showHours || allowEdition)
  ), [emptyValue, showHours]);
  
  const showDecimals = useMemo(() => (
    (roundType !== 2 || !roundHours)
      ? true
      : (
        typeof roundLevel === "number"
        && typeof element.lvl === "number"
        && element.lvl > roundLevel
      )
  ), [element.children?.length, roundType, roundHours, roundLevel, element.lvl]);
  
  const sectionWtClassName = useMemo(() => (
    "."+getSectionWtClass(workType.parent.id, workType.id)
  ), [workType.id, workType.parent.id])
  
  const handleMouseEnter = () => {
    document.querySelector(sectionWtClassName)?.classList.add("opaque");
  }
  const handleMouseLeave = () => {
    document.querySelector(sectionWtClassName)?.classList.remove("opaque");
  }
  
  const workTypeName = useMemo(() => (
    structure.getWorkTypeById(workType.id)?.name
  ), [workType.id]);
  
  const usedWorkTypeOverheads = element.usedOverheads
    .filter(ov => ov.workTypes.includes(Number(workType.id)));
    // .map(({ percent }) => safeRoundHours(h * percent, modifier));
  
  const displayUsedOverheads = usedWorkTypeOverheads?.length > 0 && displayCellContent && editable;
  
  return (
    <ValueCell
      {...valueCellProps}
      displayContent={displayCellContent}
      displayMinMaxSeparator={displayMinMaxSeparator}
      value={value}
      valueMax={valueMax}
      wider={usesTwoValues}
      onValueChange={handleValueChange}
      warning={isDifferentPercent}
      showDecimals={showDecimals}
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={showValueMax && (!apply || !hideMaxValues)}
      placeholderMin={originalValue}
      placeholderMax={originalValueMax}
      editable={allowEdition}
      visible={valueVisible}
      decimals={usedRoundDecimals}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classnames(
        className,
        visibilityMode && workType.isHidden && "transparent-2"
      )}
      inputClassName={classnames(
        `input-${workTypeName.toLowerCase().replace(/\//g, "-")}`,
        blurDisplayedValue && "transparent-2",
      )}
      inputMinTooltipMessage={displayUsedOverheads &&
        <TaskWorkTypeOverheadTooltip element={element} workTypeId={workType.id} usedWorkTypeOverheads={usedWorkTypeOverheads} />}
      inputMaxTooltipMessage={displayUsedOverheads &&
        <TaskWorkTypeOverheadTooltip element={element} workTypeId={workType.id} usedWorkTypeOverheads={usedWorkTypeOverheads} isMax />}
    >
      {displayCellContent && isDifferentPercent && allowEdition && !emptyValue && showHours && (
        <ResetValueButton action={recoverAutoCountData}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
      {allowEdition && riskAlertVisible && (
        <Alert
          isOpen={riskAlertVisible}
          title={t("views.editor.dialogs.settings.risk_buffer")}
          acceptText={t("common.close")}
          onAccept={handleRiskAlertClose}
          useLockCheckbox
        >
          {t("views.editor.risk_alert", {percent: riskBuffer})}
        </Alert>
      )}
      {allowEdition && valueAlertVisible && (
        <DifferentValueAlert
          moduleLevel={actualDepthLevel}
          handleValueAlertClose={handleValueAlertClose}
        />
      )}
    </ValueCell>
  );
});

TaskWorkType.propTypes = {
  element: object.isRequired,
  textPresetClass: string,
  tableDepth: number,
  workType: object.isRequired,
  emptyValue: bool,
  editable: bool,
  opened: bool,
  actualDepthLevel: number,
};
