import { useMemo } from "react";
import { bool, string, object, number, shape } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, usePresetFieldNames } from "@hooks";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {
  HeaderRow,
  TitleCell,
  DescriptionCell,
  ScrollButton,
} from "@components";
import { HeaderWorkType } from "./components/HeaderWorkType";
import { TABLE_DRAGGABLE_CONTAINER, CELL_WIDTHS } from "@utils";
import { isMobile } from "react-device-detect";
import { MAX_PAGE_WIDTH } from "@styles/themes";
import classnames from "classnames";

export const TableHeader = observer(
  ({
    section,
    tableIdentifier,
    allowEdition,
    blockExpansions,
    isSellerOrClient,
    useClientActions,
    isProposal,
    isLibrary,
    useExpandCell,
    useDescriptionCell,
    maxDescriptionCellWidth,
    isTableContentOverflowing,
    scrollButtonProps,
  }) => {
    const structure = useStructureStore();
    const { settings } = structure;

    const {
      viewLevel,
      showBreakdownColumns,
      sectionFormat,
      descriptionMode,
      descriptionCellWidth,
    } = settings;

    const {
      btTaskWorkTypes,
      taskWorkTypes,
      allChildrenExpanded,
      sectionModulesHaveChildren,
      isOpened,
    } = section;

    const { t } = useTranslation();
    const { getFieldName } = usePresetFieldNames(isProposal);

    const expanded = useMemo(
      () => (sectionFormat !== 1 || isOpened) && allChildrenExpanded,
      [allChildrenExpanded, isOpened, sectionFormat]
    );

    const maxTitleCellWidth = useMemo(() => {
      const width = taskWorkTypes?.map((workType) =>
        workType.btOpened ? CELL_WIDTHS.VALUE.TINY * 4 : CELL_WIDTHS.VALUE.LARGE
      );
      MAX_PAGE_WIDTH - width - CELL_WIDTHS.TITLE_BT_SUMMARY;
    });

    const showDescriptionCell = useMemo(
      () => descriptionMode === 3 && useDescriptionCell,
      [descriptionMode, useDescriptionCell]
    );

    const expandAllChildren = () => section.expandAllChildren(!expanded);

    const handleTitleCellRescale = (w) => {
      structure.settings.setTitleCellWidth(w);
    };
    const handleDescriptionCellRescale = (w) => {
      structure.settings.setDescriptionCellWidth(w);
    };
    return (
      <HeaderRow
        id={tableIdentifier}
        useExpandCell={useExpandCell}
        hideVisibilitySwitch
        useVisibilityCell={useClientActions}
        expandable={!blockExpansions && viewLevel > 1}
        expanded={expanded}
        onExpand={expandAllChildren}
        endRowChildren={
          isTableContentOverflowing &&
          !isMobile && (
            <ScrollButton direction="right" {...scrollButtonProps} />
          )
        }
      >
        <TitleCell
          allowOverflowDisplay={descriptionMode < 3}
          minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
          maxWidth={CELL_WIDTHS.DESCRIPTION}
          pinnedLeft={!isProposal || sectionModulesHaveChildren ? 1 : 0}
          actions={isTableContentOverflowing && !isMobile && !showDescriptionCell &&
            <ScrollButton direction="left" {...scrollButtonProps} />
          }
          allowEdition={showDescriptionCell && allowEdition}
          setWidth={handleTitleCellRescale}
          setNextCellWidth={handleDescriptionCellRescale}
          nextCellWidth={descriptionCellWidth}
          nextCellMaxWidth={maxDescriptionCellWidth}
        >
          <span className="preset-tableHeader pt-module">
            {getFieldName("pt-module", 0)}
          </span>
        </TitleCell>

        <DescriptionCell
          minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
          maxWidth={maxTitleCellWidth}
          isHeader
          size="xSmall"
          useRescale={allowEdition}
          setWidth={handleDescriptionCellRescale}
        >
          <span className="preset-tableHeader ml-0-5">{t("common.desc")}</span>
        </DescriptionCell>

        <Grid
          className={classnames(TABLE_DRAGGABLE_CONTAINER, "wt-container")}
          item
          container
          wrap="nowrap"
          alignItems="center"
          style={{ width: "initial" }}
        >
          {showBreakdownColumns &&
            btTaskWorkTypes?.map((workType) => (
              <HeaderWorkType
                key={workType.id}
                workType={workType}
                section={section}
                isLibrary={isLibrary}
                isSellerOrClient={isSellerOrClient}
              />
            ))}
        </Grid>
      </HeaderRow>
    );
  }
);

TableHeader.propTypes = {
  section: object.isRequired,
  tableIdentifier: string.isRequired,
  isSellerOrClient: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  blockExpansions: bool,
  useExpandCell: bool,
  useDescriptionCell: bool,
  pricesVisible: bool,
  maxTitleCellWidth: number,
  maxDescriptionCellWidth: number,
  isTableContentOverflowing: bool,
  scrollButtonProps: shape({
    containerRef: object.isRequired,
    scrollReachedStart: bool,
    scrollReachedEnd: bool,
  }).isRequired,
};
