import { APIMethod, fetchData } from "@client";

export async function setFirstSubscriptionQuery(
  paymentMethodId,
  priceId,
  quantity
) {
  return await fetchData("/order", APIMethod.POST, {
    data: { paymentMethodId, priceId, quantity },
    // mapRequestKeysToSnakeCase: false,
  });
}
