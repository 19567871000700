import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function generateUserStoryQuery(taskName) {
  const res = await fetchData(
    `/api/ai/story?taskName=${taskName}`,
    APIMethod.GET,
    {
      noAuth: true,
      baseURL: ACCESS_DOMAINS.editor,
    }
  );
  return res.results;
}
