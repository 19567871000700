import { APIMethod, fetchData } from "@client";

export async function createCustomWorkTypeQuery(name, backgroundColor="#4098F4") {
  const data = {
    name,
    backgroundColor,
    type: "development",
    textColor: "#000000",
    description: "",
  };
  const res = await fetchData("/tags", APIMethod.PUT, { data, mapRequestKeysToSnakeCase:false });
  return { ...data, removable: true, id: res.results.id };
}
