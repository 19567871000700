import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { ValueCell } from "@components";
import { parsePrice } from "project-structure";

export const CompanyCostCell = observer(({
  element,
  allowEdition,
  displayCellContent,
}) => {
  const structure = useStructureStore();
  const { useMinMax, currencyObj, roundPrice } = structure.settings;
  const { parsedCompanyCost, showCompanyCostMax, hasOnlyCost } = element;

  const handleCompanyCostChange = (cost, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCompanyCost(cost, key);
  };

  return (
    <ValueCell
      wider
      widest={useMinMax}
      displayContent={displayCellContent}
      value={useMinMax ? parsedCompanyCost.min : parsedCompanyCost.avg}
      valueMax={parsedCompanyCost.max}
      onValueChange={handleCompanyCostChange}
      editable={allowEdition && hasOnlyCost}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      displayMax={showCompanyCostMax}
      max={10000000}
      parser={parsePrice}
      inputClassName="input-company-cost"
    />
  );
});

CompanyCostCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
  profitabilityMode: bool,
};
