import { useEffect } from "react";
import { observer } from "mobx-react";
import { useEditorWebsocket } from "@hooks";
import { hashCode } from "project-structure";
import { Cursor } from "@assets";
import { PROPOSAL_STEPPER_ID } from "@utils";
import useStyle from "./UserMoveBoard.style";
import { MAX_PAGE_WIDTH } from "@styles/themes";

let lastEmit = new Date().getTime();
const THROTTLE = 50;

const now = () => new Date().getTime();

export const UserMoveBoard = observer(({ containerRef }) => {
  const classes = useStyle();
  const socket = useEditorWebsocket();

  // const offsetTop = useMemo(
  //   () =>
  //     (containerRef?.parentNode?.getBoundingClientRect().y || 0) +
  //     (document.getElementById(PROPOSAL_STEPPER_ID)?.getBoundingClientRect()
  //       .height || 0),
  //   [containerRef?.parentNode]
  // );

  useEffect(() => {
    containerRef?.addEventListener("mousemove", captureMouseMovement, true);
    return () => {
      containerRef?.removeEventListener(
        "mousemove",
        captureMouseMovement,
        true
      );
    };
  }, [containerRef]);

  const captureMouseMovement = (e) => {
    if (!containerRef) return;
    if (lastEmit < now() - THROTTLE) {
      lastEmit = now();
      const offsetLeft =
        containerRef?.clientWidth < MAX_PAGE_WIDTH
          ? 24
          : (containerRef?.clientWidth - MAX_PAGE_WIDTH) / 2;
      const tableElement = e.target.closest("[data-id]");
      const offsetTop = (containerRef?.parentNode?.getBoundingClientRect().y || 0) +
        (document.getElementById(PROPOSAL_STEPPER_ID)?.getBoundingClientRect()
          .height || 0);
      socket?.sendMoveData(
        e.clientX - offsetLeft,
        e.clientY - offsetTop + (containerRef?.parentNode?.scrollTop || 0),
        tableElement?.dataset?.t,
        tableElement?.dataset?.p,
        tableElement?.dataset?.id
      );
    }
  };

  return (
    <div id="cursors" className={classes.root}>
      {socket?.users?.map(({ id, name, color, email }) => (
        <div
          key={email}
          id={hashCode(id)}
          className={classes.cursor}
          style={{ color: color }}
        >
          <Cursor className={classes.icon} />
          <span className={classes.name}>{name}</span>
        </div>
      ))}
    </div>
  );
});
