import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { PriceCell } from "@components";
import { useMemo } from "react";
import { roundFloat } from "project-structure";

export const CostCell = observer(() => {
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { fixedBreakdownPrice, usesTwoValues } = structure;
  const { useMinMax, currencyObj, roundPrice } = structure.settings;
  const { apply, hideSummaryCost, hideMinValues, hideMaxValues } = structure.visibility;
  
  const value = useMemo(
    () =>
      roundFloat(
        useMinMax ? fixedBreakdownPrice.min : fixedBreakdownPrice.avg,
        roundPrice ? 3 : 0
      ),
    [roundPrice, fixedBreakdownPrice, useMinMax]
  );
  
  const valueMax = useMemo(
    () => roundFloat(fixedBreakdownPrice.max, roundPrice ? 3 : 0),
    [roundPrice, fixedBreakdownPrice]
  );
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryCost && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryCost]);
  
  return (
    <PriceCell
      displayContent
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={useMinMax && value !== valueMax && (!apply || !hideMaxValues)}
      value={value}
      valueMax={valueMax}
      widest={usesTwoValues}
      showDecimals={!roundPrice}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      allowNegative
      className={className}
    />
  );
});
