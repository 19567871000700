import { string, func, bool } from "prop-types";
import { Cached } from "@material-ui/icons";
import { TooltipIcon } from "@components";
import useStyle from "./ResetValueButton.style";
import classnames from "classnames";

export const ResetValueButton = ({
  action,
  children,
  className,
  disabled
}) => {
  const classes = useStyle();

  return (
    <TooltipIcon
      icon={<Cached className={classnames(!disabled && "color-warning")} />}
      onClick={action}
      className={classnames("noDrag", classes.reloadValue, className)}
      disabled={disabled}
    >
      {children}
    </TooltipIcon>
  );
};

ResetValueButton.propTypes = {
  action: func.isRequired,
  children: string.isRequired,
  className: string,
  disabled: bool,
};
