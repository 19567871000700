import { bool, number } from "prop-types";
import { Row } from "../Row/Row";
import { ValueCell, PriceCell } from "../../cells";

export const SummaryRow = ({
  children,
  displayMinMax,
  emptyValueCells,
  emptyPriceCell,
  className,
  ...rowProps
}) => {

  return (
    <Row
      {...rowProps}
      noArrow
      hideVisibilitySwitch
      className={className}
    >
      {children}
      {emptyValueCells >= 0 &&
        [...new Array(emptyValueCells)].map((_, i) => (
          <ValueCell {...rowProps} wider={displayMinMax} key={i} />
        ))}
      {emptyPriceCell && (
        <PriceCell {...rowProps} wider={displayMinMax} />
      )}
    </Row>
  );
};

SummaryRow.propTypes = {
  ...Row.props,
  displayMinMax: bool,
  emptyValueCells: number,
  emptyPriceCell: bool,
};
