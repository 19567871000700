import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    height: 24,
    padding: "0 8px",
    backgroundColor: `${theme.palette.text.primary} !important`,
    color: theme.props.text.alt,
    borderRadius: 5,
    flexDirection: "row !important",
    fontWeight: 600,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15) !important",
  },
}));

export default useStyle;
