import { makeStyles } from "@material-ui/core/styles";

const font = {
  fontSize: "24px !important",
  fontWeight: 600,
};

const useStyle = makeStyles(() => ({
  form: {
    height: 42,
    margin: "-6px -8px",
    width: "100%",
  },
  input: {
    "& input": {
      padding: "4px 8px",
      height: 42,
      ...font,
    },
  },
  text: {
    cursor: "text",
    overflow: "hidden",
    textOverflow: "ellipsis",
    ...font,
  },
}));

export default useStyle;
