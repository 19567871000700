import parse from "html-react-parser";
export const HtmlParser = (htmlString="") => (
  parse(
    htmlString,
    {
      transform: ( reactNode, domNode ) => {
        if(domNode.type === "text")
          return <span>{reactNode}</span>
        return reactNode;
      }
    }
  )
)