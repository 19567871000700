import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  item: {
    padding: 4,
  },
  checkbox: {
    marginRight: 8,
  },
}));

export default useStyle;
