import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ControlledTextField } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const passwordChangeFormSchema = (t) =>
  yup.object().shape({
    password: yup
      .string()
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$&*.\\/"'%()+,\-:;<>?[\]^_{}|~])(?=.*[0-9])(?=.*[a-z]).{8,128}$/,
        t("errors.password.pattern")
      )
      .required(t("errors.password.required"))
      .min(8, t("errors.password.min"))
      .max(128, t("errors.password.max")),
    currentPassword: yup
      .string()
      .required(t("errors.password.required"))
      .notOneOf([yup.ref("password"), null], t("errors.password.unique"))
      .min(8, t("errors.password.min"))
      .max(128, t("errors.password.max")),
    passwordConfirm: yup
      .string()
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$&*.\\/"'%()+,\-:;<>?[\]^_{}|~])(?=.*[0-9])(?=.*[a-z]).{8,128}$/,
        t("errors.password.pattern")
      )
      .oneOf([yup.ref("password"), null], t("errors.password.match"))
      .min(8, t("errors.password.min"))
      .max(128, t("errors.password.max")),
  });

const defaultValues = {
  password: "",
  currentPassword: "",
  passwordConfirm: "",
};
export const PasswordChangeForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    reset: resetForm,
    formState: { isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(passwordChangeFormSchema(t)),
    defaultValues,
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors, reset } = result;

      if (reset) resetValues();

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  const resetValues = () => {
    resetForm(defaultValues);
  };

  return (
    <form
      autoComplete="on"
      aria-label="password reset form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container spacing={1}>
        <Grid item container xs={12}>
          <ControlledTextField
            name="currentPassword"
            control={control}
            type="password"
            label={t("forms.password.current")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12}>
          <ControlledTextField
            name="password"
            type="password"
            control={control}
            label={t("forms.password.new")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12}>
          <ControlledTextField
            name="passwordConfirm"
            type="password"
            control={control}
            label={t("forms.password.repeat")}
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider className="mt-2 -mx-6 mb-6" />

      <Grid item container justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={resetValues}
          className="mr-2"
          disabled={!isDirty}
        >
          {t("common.cancel")}
        </Button>
        <Button
          isSubmit
          name="submit"
          variant="contained"
          icon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {!isSubmitting && t("common.save")}
        </Button>
      </Grid>
    </form>
  );
};

PasswordChangeForm.propTypes = {
  onSubmit: func.isRequired,
};
