import { useMemo } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { checkBuildLock, HARD_ROUNDING_LOCK_BUILD } from "project-structure";
import { useStructureStore } from "@hooks";
import { ButtonSwitch, ClearNumberInput, InfoLabel, TextField, TooltipIcon } from "@components";
import { Collapse, Divider, Grid, Switch } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const Rounding = observer(() => {
  
  const structure = useStructureStore();
  const { t } = useTranslation();
  
  const {
    timeModifier,
    roundHours,
    roundType,
    roundPrice,
    roundDecimals,
    roundLevel,
  } = structure.settings;
  
  const showHardRounding = useMemo(() => Boolean(
    !structure.sOriginBuild || checkBuildLock(HARD_ROUNDING_LOCK_BUILD, structure.sOriginBuild)
  ), [structure.sOriginBuild]);
  
  const options = useMemo(() => [
    { value: 1, label: t("views.editor.dialogs.settings.round_soft") },
    { value: 2, label: t("views.editor.dialogs.settings.round_hard") },
  ], []);
  
  const handleRoundTypeChange = (checkbox) => () => {
    structure.settings.setRoundType(checkbox ? (roundType ? 0 : 1) : (roundType === 1 ? 2 : 1))
  }
  
  const handlePriceRoundingChange = () => {
    structure.settings.setPriceRounding(!roundPrice);
  };
  
  const handleHoursRoundingChange = (e, n) => {
    structure.historyManager.startGroup();
    structure.settings.setHoursRounding(n >= 0 ? n : 3);
    structure.generateTimeline();
    structure.historyManager.stopGroup();
  };
  
  const handleHoursRoundingLevelChange = (e,n) => {
    structure.settings.setRoundLevel(n >= 0 ? n : 0);
  }
  const handleRoundDecimalsChange = (v) => {
    structure.settings.setRoundDecimals(v);
  }
  
  return <>
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      className="mb-4"
    >
      <InfoLabel
        className="my-0"
        label="views.editor.dialogs.settings.rounding_price"
        tooltipText={`views.editor.dialogs.settings.tooltips.round_price${
          timeModifier === 1 ? "" : "_day"
        }`}
      />
      <Switch
        size="medium"
        color="primary"
        checked={roundPrice}
        onChange={handlePriceRoundingChange}
        name={t(
          `views.editor.dialogs.settings.${
            roundPrice ? "no_price_rounding" : "round_price"
          }`
        )}
      />
    </Grid>
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      className="mb-4"
    >
      <InfoLabel
        className="my-0"
        label={`views.editor.dialogs.settings.rounding_hours`}
        tooltipText={t(
          `views.editor.dialogs.settings.tooltips.round_${
            timeModifier === 1 ? "hour" : "day"
          }`
        )}
        translate
      />
      <Switch
        size="medium"
        color="primary"
        checked={Boolean(roundType)}
        onChange={handleRoundTypeChange(true)}
        name={t(
          `views.editor.dialogs.settings.${
            roundType ? "round_hours" : "no_hours_rounding"
          }`
        )}
      />
    </Grid>
    <Collapse
      in={Boolean(roundType)}
      mountOnEnter
      unmountOnExit
    >
      <Grid item container>
        {
          showHardRounding
            ? <>
              <Grid item container wrap="nowrap" className="mb-4">
                <ButtonSwitch
                  size="small"
                  value={roundType}
                  setValue={handleRoundTypeChange()}
                  values={options}
                />
                <TooltipIcon color="primary" className="ml-2">
                  {t("views.editor.dialogs.settings.tooltips.round_type_1")}
                  <br />
                  {t("views.editor.dialogs.settings.tooltips.round_type_2")}
                </TooltipIcon>
              </Grid>
              {
                roundType === 1 &&
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <InfoLabel
                    className="my-0"
                    label="views.editor.dialogs.settings.round_decimal"
                  />
                  <ClearNumberInput
                    min={0}
                    max={2}
                    value={roundDecimals}
                    addInitialBorder
                    fullWidth
                    onChange={handleRoundDecimalsChange}
                  />
                </Grid>
              }
              {
                roundType === 2 &&
                <Grid item container spacing={1} wrap="nowrap">
                  <Grid item container xs={12} direction="column">
                    <InfoLabel
                      className="mt-0"
                      label={`views.editor.dialogs.settings.round_type`}
                      translate
                    />
                    <Autocomplete
                      id="roundingModeSelect"
                      value={roundHours}
                      fullWidth
                      size="small"
                      onChange={handleHoursRoundingChange}
                      getOptionSelected={(o, v) => o === v}
                      getOptionLabel={(o) => t("rounding." + o)}
                      disableClearable
                      options={[1, 2, 3, 0]}
                      renderInput={(params) => (
                        <TextField {...params} className="mb-0" label="" />
                      )}
                    />
                  </Grid>
                  <Grid item container xs={12} direction="column">
                    <InfoLabel
                      className="mt-0"
                      label={`views.editor.dialogs.settings.round_apply`}
                      translate
                    />
                    <Autocomplete
                      id="sectionFormatSettingsSelect"
                      value={roundLevel}
                      fullWidth
                      size="small"
                      className="mb-0"
                      onChange={handleHoursRoundingLevelChange}
                      getOptionSelected={(o, v) => o === v}
                      getOptionLabel={(o) =>
                        t(`proposal_parts.modules_levels.${o}`)
                      }
                      disableClearable
                      options={[0, 1, 2, 3]}
                      renderInput={(params) => (
                        <TextField {...params} className="mb-0" label="" />
                      )}
                    />
                  </Grid>
                </Grid>
              }
            </>
            : <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <InfoLabel
                className="my-0"
                label="views.editor.dialogs.settings.round_decimal"
              />
              <ClearNumberInput
                min={0}
                max={2}
                value={roundDecimals}
                addInitialBorder
                fullWidth
                onChange={handleRoundDecimalsChange}
              />
            </Grid>
        }
      </Grid>
      <Divider className="mt-2 mb-4" />
    </Collapse>
  </>
})