import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  header: {
    margin: "16px 24px 8px 24px",
    paddingBottom: 8,
    width: "initial",
    borderBottom: `1px solid ${theme.props.border}`,
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxSizing: "border-box",
  },
}));

export default useStyle;
