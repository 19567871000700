import { bool, node, number, oneOf, oneOfType, shape, string } from "prop-types";
import Grid from "@material-ui/core/Grid";
import { ValueCellReadOnlyContent } from "../../components/ValueCellReadOnlyContent";
import { ValueCellEditableContent } from "../../components/ValueCellEditableContent";
import useStyle from "./CollapseValueInnerCell.style";
import useCellStyle from "../../commonStyles.style";
import classnames from "classnames";
import { InputCellProps } from "../../types";

export const CollapsePriceInnerCell = ({
  displayContent,
  editable,
  children,
  displayMax,
  onValueChange,
  textPresetClass,
  isHeader,
  useBorder,
  useSeparator,
  narrow,
  noContainerBackground,
  usePresetClass,
  ...valueProps
}) => {
  const classes = useStyle();
  const cellClasses = useCellStyle();
  
  if(!displayContent)
    return <></>
  
  return <Grid
    item container
    justifyContent="center"
    alignItems="center"
    wrap="nowrap"
    className={classnames(
      classes.cell,
      cellClasses.bolder,
      usePresetClass &&
      (isHeader ? "preset-tableHeader" : "preset-price"),
      isHeader && "h-full",
      !isHeader && cellClasses.collapseCellInner,
      narrow && classes.narrow,
    )}
  >
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      wrap="nowrap"
      className={classnames(
        classes.priceContainer,
        !noContainerBackground && classes.priceContainerBackground,
        usePresetClass &&
        (isHeader ? "preset-tableHeader" : "preset-price"),
        useBorder && cellClasses.fullDashBorderRight,
        useSeparator && cellClasses.withSeparator,
      )}
    >
      {(editable ? (
        <ValueCellEditableContent
          {...valueProps}
          displayMax={displayMax}
          onValueChange={onValueChange}
          className={textPresetClass}
        />
        ) : (
        <ValueCellReadOnlyContent
          displayMax={displayMax}
          {...valueProps}
        />
      ))}
    </Grid>
    {children}
    {useSeparator && <span className="separatorInner" />}
  </Grid>
  
}

CollapsePriceInnerCell.propTypes = {
  ...InputCellProps,
  min: oneOfType([number, shape({ min: number, max: number })]),
  max: oneOfType([number, shape({ min: number, max: number })]),
  children: oneOfType([node, string]), //reset button
  useBorder: bool,
  useSeparator: bool,
  showDecimals: bool,
  isHeader: bool,
  narrow: bool,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
}