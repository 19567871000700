import { parseRegex } from "@utils";

export const highlightSearchText = (text, val="") => {
  val = parseRegex(val);

  const reg = new RegExp(val, "i");
  const i = text.search(reg);
  const n =
    val && i >= 0 ? [text.slice(0, i), text.slice(i + val.length)] : [text];
  const b = text.match(reg);

  return [n[0], b ? b[0] : "", n[1] || ""];
};
