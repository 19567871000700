export const TRIAL_DAYS = 30;

export const PLAN_DURATION = {
  YEAR: "year",
  MONTH: "month",
};

export const COMPANY_SIZE_TIERS = [
  { index: 0, amount: 5, title: "1 - 10" },
  { index: 1, amount: 10, title: "11 - 20" },
  { index: 2, amount: 20, title: "21 - 50" },
  { index: 3, amount: 50, title: "51 - 250" },
  { index: 4, amount: 100, title: "251 - 1000" },
];

export const ESTIMATE_TIERS = [
  { index: 0, amount: 3, title: "1" },
  { index: 1, amount: 5, title: "2 - 5" },
  { index: 2, amount: 10, title: "6 - 10" },
  { index: 3, amount: 20, title: "11 - 20" },
  { index: 4, amount: 30, title: "21 - 30" },
];

export const PACKAGE_FREE = "package_free";
export const PACKAGE_STARTER = "package_starter";
export const PACKAGE_BUSINESS = "package_business";
export const PACKAGE_PRO = "package_pro";

export const SUBSCRIPTION_PLANS = [
  PACKAGE_FREE,
  PACKAGE_STARTER,
  PACKAGE_BUSINESS,
  PACKAGE_PRO,
];

export const SUBSCRIPTION_FEATURES = {
  estimation: {
    library: [true, true, true, true],
    templates: [true, true, true, true],
    table: [true, true, true, true],
    import: [true, true, true, true],
    allocation: [false, false, true, true],
    ai_story: [false, false, true, true],
    timeline: [false, false, false, true],
  },
  sales: {
    sharing: [true, true, true, true],
    branding: [true, true, true, true],
    pdf: [false, true, true, true],
    recordings: [false, true, true, true],
    visibility: [false, false, true, true],
    comments: [false, false, true, true],
    domain: [false, false, false, true],
  },
  budget: {
    jira: [false, false, true, true],
    comparison: [false, false, false, true],
  },
  consulting: {
    success_man: [false, "+ $49", "+ $49", "+ $49"],
    sales_man: [false, "+ $99", "+ $99", "+ $99"],
  },
};

const SUBSCRIPTION_FEATURE_BASE_PATH =
  "https://apropo-public.s3.eu-central-1.amazonaws.com/website/files/movies";

export const SUBSCRIPTION_FEATURE_PREVIEWS = {
  // estimation
  library: `${SUBSCRIPTION_FEATURE_BASE_PATH}/LibraryApropo.mp4`,
  templates: `${SUBSCRIPTION_FEATURE_BASE_PATH}/TemplatesApropo.mp4`,
  table: `${SUBSCRIPTION_FEATURE_BASE_PATH}/EstimationTable.mp4`,
  versions: `${SUBSCRIPTION_FEATURE_BASE_PATH}/VersionsApropo.mp4`,
  import: `${SUBSCRIPTION_FEATURE_BASE_PATH}/ApropoExcelImport.mp4`,
  allocation: `${SUBSCRIPTION_FEATURE_BASE_PATH}/TeamAllocation.mp4`,
  timeline: `${SUBSCRIPTION_FEATURE_BASE_PATH}/ProjectTimeline.mp4`,

  // sales
  sharing: `${SUBSCRIPTION_FEATURE_BASE_PATH}/SharelinkApropo.mp4`,
  branding: `${SUBSCRIPTION_FEATURE_BASE_PATH}/BrandingApropo.mp4`,
  pdf: `${SUBSCRIPTION_FEATURE_BASE_PATH}/PDFApropo.mp4`,
  recordings: `${SUBSCRIPTION_FEATURE_BASE_PATH}/ApropoRecordings.mp4`,
  visibility: undefined,
  comments: `${SUBSCRIPTION_FEATURE_BASE_PATH}/CommentsApropo.mp4`,
  domain: undefined,

  // budget
  jira: `${SUBSCRIPTION_FEATURE_BASE_PATH}/JiraApropo.mp4`,
  comparison: undefined,
};
