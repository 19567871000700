import { observer } from "mobx-react";
import { parsePrice } from "project-structure";
import { useStructureStore } from "@hooks";
import { TotalContainer } from "../components";

export const TotalCompanyCost = observer(() => {
  
  const structure = useStructureStore();
  
  const { settings, totalCompanyCost, showTotalMaxCompanyCost } = structure;
  
  const { currencyObj, useMinMax, roundHours } = settings;
  
  return (
    <TotalContainer
      totalName="Cost"
      min={parsePrice(useMinMax ? totalCompanyCost.min : totalCompanyCost.avg)}
      max={parsePrice(totalCompanyCost.max)}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      displayMax={showTotalMaxCompanyCost}
      textSmaller
      showDecimals={!roundHours}
    />
  );
})