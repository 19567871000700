import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles((theme) => ({
  cell: {
    width: CELL_WIDTHS.VALUE.SMALL,
    minWidth: CELL_WIDTHS.VALUE.SMALL,
    paddingLeft: 4,
    paddingRight: 4,
    "&:not(:last-child) .separatorInner": {
      position: "absolute",
      width: 1,
      right: 0,
      height: "50%",
      backgroundColor: theme.props.border,
      opacity: 0.8,
    },
  },
  narrow: {
    width: CELL_WIDTHS.VALUE.TINY,
    minWidth: CELL_WIDTHS.VALUE.TINY
  },
  priceContainer: {
    height: 24,
  },
  priceContainerBackground: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main + "0d",
    color: theme.palette.text.primary,
  },
}));

export default useStyle;
