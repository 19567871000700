import { useMemo } from "react";
import { object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, useBudgetTrackingStore } from "@hooks";
import { ValueCell, CollapseValueCell } from "@components";
import { CELL_WIDTHS } from "@utils";
import { makeStyles } from "@material-ui/core";
import { PriceCell } from "@components";
import classnames from "classnames";
import { roundFloat } from "project-structure";

export const HourCell = observer(({ workType }) => {
  const structure = useStructureStore();
  const { hours } = useBudgetTrackingStore();
  const classes = useStyle();

  const {
    roundHours,
    useMinMax,
    modifier,
    usedRounding,
    usedDecimals,
    roundType,
    roundLevel,
  } = structure.settings;
  const {
    parsedHours,
    parsedBTHours,
    // hasCustomHours,
    btHourDiff,
    btHourDiffPercent,
  } = workType;

  const colorText = useMemo(() => {
    if (parsedHours.avg !== 0) {
      return btHourDiff.avg > 0 ? classes.rowAccepted : classes.rowDanger;
    }
    return null;
  }, [parsedHours.avg, btHourDiff.avg]);

  const value = useMemo(
    () =>
      roundFloat(
        useMinMax ? parsedHours.max || 0 : parsedHours.avg || 0,
        usedRounding,
        false,
        usedDecimals
      ),
    [parsedHours, useMinMax, usedRounding, usedDecimals, roundType, roundLevel]
  );

  const btHourDiffPercents = useMemo(
    () =>
      roundFloat(
        useMinMax ? btHourDiffPercent.max || 0 : btHourDiffPercent.avg || 0,
        usedRounding,
        false,
        usedDecimals
      ),
    [useMinMax, roundHours, useMinMax]
  );

  const btHourDiffs = useMemo(
    () =>
      roundFloat(
        (useMinMax ? btHourDiff.max || 0 : btHourDiff.avg || 0) / modifier,
        usedRounding,
        false,
        usedDecimals
      ),
    [useMinMax, roundHours, useMinMax]
  );

  return (
    <CollapseValueCell
      childrenWidth={CELL_WIDTHS.VALUE.TINY}
      mainCellWidth={CELL_WIDTHS.VALUE.LARGE}
      isOpened={hours}
      cell={
        <ValueCell
          widest
          value={btHourDiffPercents}
          symbolEnd="%"
          displayContent
          showDecimals={!roundHours}
          // warning={hasCustomHours}
          textPresetClass={classnames("preset-summaryText", colorText)}
          useSeparator
        />
      }
    >
      <PriceCell mini displayContent value={value} />
      <ValueCell
        mini
        value={parsedBTHours || 0}
        displayContent
        showDecimals={!roundHours}
        // warning={hasCustomHours}
        textPresetClass={colorText}
      />
      <ValueCell
        mini
        value={btHourDiffs}
        displayContent
        showDecimals={!roundHours}
        // warning={hasCustomHours}
        textPresetClass={colorText}
        showValueSign
      />
      <ValueCell
        mini
        value={btHourDiffPercents}
        displayContent
        showDecimals={!roundHours}
        // warning={hasCustomHours}
        symbolEnd="%"
        textPresetClass={colorText}
        showValueSign
      />
    </CollapseValueCell>
  );
});

HourCell.propTypes = {
  workType: object.isRequired,
};

const useStyle = makeStyles(() => ({
  cell: {
    zIndex: 2,
  },
  rowDanger: {
    color: "#41BD56",
  },
  rowAccepted: {
    color: "#DE0909",
  },
}));
