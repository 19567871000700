import { bool, string, object, oneOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { AddButtonFull } from "@components";
import { generateEntryId } from "project-structure";
import classnames from "classnames";

export const AddChildTask = ({
  parent,
  tableDepth,
  style,
  className,
  showId,
}) => {
  const { t } = useTranslation();

  const addModule = () => {
    parent.addChild(true, { id: generateEntryId() }, !parent.hasChildren);
  };

  return (
    <>
      <AddButtonFull
        id={showId ? "addModuleFirst" : undefined}
        onClick={addModule}
        name={`Add new ${t(
          `proposal_parts.module_levels.level_${tableDepth}`
        )} `}
        style={style}
        className={classnames(className, `addElement-${tableDepth}`)}
      >
        {t("common.add")}{" "}
        {t(`proposal_parts.module_levels.level_${tableDepth}`)}
      </AddButtonFull>
    </>
  );
};

AddChildTask.propTypes = {
  parent: object,
  tableDepth: oneOf([1, 2, 3]).isRequired,
  style: object,
  className: string,
  showId: bool,
};
