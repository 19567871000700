import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  rowGroup: {
    width: "initial",
    border: "none !important",
    "&.timeline": {
      width: "max-content"
    },
    "&:not(.static)": {
      cursor: "pointer",
    },
    "&.d0": { margin: "4px 0" },
    "&.d1": { margin: "2px 0" },
    "&.d0, &.d1": { borderRadius: 5 },
    "&:not(.d0):hover": {
      boxShadow: " 0px 1px 2px rgba(0, 0, 0, 0.15)",
    },
    // border colors
    "& .d0 > .cell": { borderColor: "transparent" },
    "&:not(.timeline) .d1 > .cell": { borderColor: theme.props.border },
    "&.active:not(.d0) .cell, &:not(.d0):hover .cell": {
      borderColor: theme.palette.primary.main,
    },

    // border widths
    "& .top > .cell": { borderTopWidth: 1 },
    "& .bottom > .cell": { borderBottomWidth: 1 },
    "& div[role='row'] > .cell:first-child": { borderLeftWidth: 1 },
    "& div[role='row'] > .cell:last-child": { borderRightWidth: 1 },
    "&.active:not(.d0) div > .cell:last-child, &:not(.d0):hover .cell:last-child": { borderRightColor: theme.palette.primary.main },

    "& .top > .cell:first-child": { borderTopLeftRadius: 5 },
    "& .bottom > .cell:first-child": { borderBottomLeftRadius: 5 },
    "&:not(.timeline) .top > .cell:last-child": { borderTopRightRadius: 5 },
    "&:not(.timeline) .bottom > .cell:last-child": { borderBottomRightRadius: 5 },
    "&.active.timeline .top > .cell:last-child, &.timeline:hover .top > .cell:last-child": { borderTopRightRadius: 5 },
    "&.active.timeline .bottom > .cell:last-child, &.timeline:hover .bottom > .cell:last-child": { borderBottomRightRadius: 5 },
  },
  // noBottomBorders: {
  //   "& .bottom > .cell": { borderBottomWidth: 1 },
  // }
}));

export default useStyle;
