import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  filter: {
    overflowX: "auto",
    width: "100%",
    margin: 0,
    marginBottom: 8,
    "& > div": {
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
  },
  clearFilters: {
    padding: 6,
  },
}));

export default useStyle;
