import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  headerRow: {
    zIndex: 14,
    cursor: "auto",
    backgroundColor: theme.props.background,
  },
  altBackground: {
    backgroundColor: theme.props.backgroundSecondary,
  },
}));

export default useStyle;
