import { observer } from "mobx-react";
import { useStructureStore, useTableExpandHandler} from "@hooks";
import { Tables, hashCode } from "project-structure";

import { SortableList, TableTitle } from "@components";
import { Collapse } from "@material-ui/core";
import useStyle from "../commonStyles.style";
import { Section } from "./components/Section/Section";
import { useTranslation } from "react-i18next";


export const BudgetTrackingTableBreakdown = observer(() => {
  const structure = useStructureStore();
  const classes = useStyle();
  const { t } = useTranslation();

  const { sections, settings } = structure;
  const { showBreakdown } = settings;

  const handleSectionReorder = (list) => structure.reorderSections(list);
  const { ...collapseProps } = useTableExpandHandler(
    true,
    showBreakdown,
    settings.setShowBreakdown
  );

  return (
    <>
      <TableTitle
        presetClass="breakdown"
        id={hashCode(Tables.BREAKDOWN)}
        title={t("views.bt.title")}
        className="preset-page preset-noedit"
      />
      <Collapse
        in={showBreakdown}
        timeout="auto"
        className={classes.collapse}
        mountOnEnter
        unmountOnExit
        data-id={hashCode("cont")}
        data-t="breakdown"
        data-p={hashCode(Tables.BREAKDOWN)}
        {...collapseProps}
      >
        <SortableList
          list={sections}
          group="sections"
          onListReorder={handleSectionReorder}
          disabled
          className={classes.tableContainerWrapper}
        >
          {sections
          .filter((ob) => !ob.isTurnedOff )
          .map((section, index) => {
            return (
              <Section
                key={section.id}
                section={section}
                index={index}
                isProposal
              />
            );
          })}
        </SortableList>
      </Collapse>
    </>
  );
});

BudgetTrackingTableBreakdown.propTypes = {};
