import { fetchData } from "@client";

export async function checkProjectAccessQuery(uuid) {
  try {
    await fetchData(`/v1/permit/project/access/${uuid}`);
    return true;
  } catch (e) {
    return false;
  }
}
