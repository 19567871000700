import { APIMethod, fetchData } from "@client";

export async function setAssignedUserWorkTypeVisibility(
  projectUuid,
  userUuid,
  visibility=0, // 0 | 1
) {
  const res = await fetchData(
    `/v1/project/user/${projectUuid}`,
    APIMethod.PUT,
    {
      data: {
        user: userUuid,
        workTypeVisibility: visibility ? 1 : 0,
      }
    }
  );
  return res.results;
}