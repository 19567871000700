import { makeStyles } from "@material-ui/core/styles";
import { CELL_HEIGHTS, CELL_PADDING } from "@utils";

const useStyle = makeStyles((theme) => ({
  cell: {
    padding: CELL_PADDING,
    // minHeight: CELL_HEIGHTS.LARGE-2*CELL_PADDING,
    alignItems: "flex-start",
    flexWrap: "nowrap",
    position: "relative",
    background: "inherit",
    fontSize: 14,
    borderStyle: "solid",
    // boxSizing: "content-box",
    borderWidth: 0,
    borderColor: "transparent",
    color: theme.palette.text.secondary,
    "&:not(:hover) .resizeHandle": {
      opacity: 0,
    },
    "&:not(:hover) .cellHoverIcon": {
      zIndex: -1,
      opacity: 0,
    },
    "&:not(:nth-last-child(2)) .separator": {
      position: "absolute",
      right: -5,
      width: 1,
      height: "75%",
      backgroundColor: theme.props.border,
      opacity: 0.8,
    },
    "&:not(:nth-last-child(2)) .separator.small": {
      right: 0,
      height: "50%",
    },
    "&:not(:nth-last-child(1)) .separator_bt": {
      position: "absolute",
      right: 0,
      width: 1,
      height: "75%",
      backgroundColor: theme.props.border,
      opacity: 0.8,
    },
  },

  cellInner: {
    position: "relative",
    minHeight: CELL_HEIGHTS.LARGE - 2 * CELL_PADDING,
  },
  collapseCellInner: {
    position: "relative",
    minHeight: CELL_HEIGHTS.LARGE,
  },
  cellOff: {
    "& > *:not(.opaque):not(.opaque-tContent)": { opacity: 0.3 },
    "& > *.opaque-tContent .tm-bar": { opacity: 0.3 },
  },
  bolder: { fontWeight: 600 },
  headCell: {
    lineHeight: "normal",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    padding: "0 4px",
    margin: "0 !important",
    textTransform: "uppercase",
    border: "none !important",
    alignItems: "center",
    textAlign: "center",
    background: "inherit",
    "& > span": {
      fontSize: "inherit",
      fontWeight: "inherit",
    },
  },
  sizeXSmall: {
    // height: CELL_HEIGHTS.XSMALL,
    minHeight: CELL_HEIGHTS.XSMALL,
  },
  sizeSmall: {
    // height: CELL_HEIGHTS.SMALL,
    minHeight: CELL_HEIGHTS.SMALL,
  },
  sizeMedium: {
    // height: CELL_HEIGHTS.MEDIUM,
    minHeight: CELL_HEIGHTS.MEDIUM,
  },
  sizeLarge: {
    // height: CELL_HEIGHTS.LARGE,
    minHeight: CELL_HEIGHTS.LARGE,
  },
  sizeXLarge: {
    // height: CELL_HEIGHTS.XLARGE,
    minHeight: CELL_HEIGHTS.XLARGE,
  },

  titleCell: {
    width: "100%",
    color: theme.palette.text.primary,
    "& > span": {
      marginLeft: 6,
    },
  },
  isSticky: {
    left: 0,
    zIndex: 4,
    position: "sticky",
  },
  isSticky2: {
    left: 0,
    zIndex: 3,
    position: "sticky",
  },
  actionWrapper: {
    minHeight: CELL_HEIGHTS.LARGE - 2 * CELL_PADDING,
    alignSelf: "flex-start",
  },
  withSeparator: {
    paddingRight: 5,
  },
  fullBorderRight: {
    borderRightStyle: "solid !important",
    borderRightWidth: "1px !important",
    borderRightColor: theme.props.border + " !important",
  },
  fullDashBorderRight: {
    "&:not(:last-child)": {
      borderRightStyle: "dashed !important",
      borderRightWidth: "1px !important",
      borderRightColor: theme.props.border + " !important",
    },
  },
  fullBorderLeft: {
    borderLeftStyle: "solid !important",
    borderLeftWidth: "1px !important",
    borderLeftColor: theme.props.border + " !important",
  },
}));

export default useStyle;
