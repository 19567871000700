import { memo, useState } from "react";
import { object, oneOf } from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Check } from "@material-ui/icons";
import { Trash } from "@assets";
import { Alert } from "@components";
import { acceptLibraryElementQuery, removeLibraryElementQuery } from "@query";

export const LibraryAccept = memo(({ element, tableDepth }) => {
  const { t } = useTranslation();

  const [declineAlertVisible, setDeclineAlertStatus] = useState(false);
  // const [successAlertStatus, setSuccessAlertStatus] = useState(false);

  // const hasParent = useMemo(() => element.parent.lvl > 0, []);

  const acceptElement = async () => {
    await acceptLibraryElementQuery(element.componentId || element.id);
    element.removeSelfWithUpStreamStatusCheck();
  };

  const declineElement = async () => {
    await removeLibraryElementQuery(element.componentId || element.id);
    element.removeSelfWithUpStreamStatusCheck();
  };

  const openDeclineAlert = () => setDeclineAlertStatus(true);
  const closeDeclineAlert = () => setDeclineAlertStatus(false);
  // const closeSuccessAlert = () => setSuccessAlertStatus(0);

  return (
    <>
      <Tooltip title={t("alerts.library.accept.title")}>
        <IconButton
          onClick={acceptElement}
          className="button-library-accept noDrag hoverIcon opaque"
          size="small"
          color="primary"
        >
          <Check />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("alerts.library.discard.title")}>
        <IconButton
          onClick={openDeclineAlert}
          className="hoverIcon opaque"
          size="small"
          color="secondary"
        >
          <Trash color="error" />
        </IconButton>
      </Tooltip>
      <Alert
        isOpen={declineAlertVisible}
        title={t("alerts.library.discard.title")}
        onAccept={declineElement}
        onCancel={closeDeclineAlert}
      >
        {t("alerts.library.discard.text", {
          level: t(`proposal_parts.module_levels.level_${tableDepth}`),
        })}
      </Alert>
      {/*<Alert*/}
      {/*	isOpen={!!successAlertStatus}*/}
      {/*	title={t("views.library.accept_alert.title")}*/}
      {/*	onAccept={closeSuccessAlert}*/}
      {/*>*/}
      {/*	{*/}
      {/*		t(*/}
      {/*			`views.library.accept_alert.text${hasParent ? "_nested" : ""}`, {*/}
      {/*				level: t(`proposal_parts.module_levels.level_${level}`),*/}
      {/*				parentName: element.parent?.name,*/}
      {/*				parentLevel: t(`proposal_parts.module_levels.level_${level-1}`),*/}
      {/*			}*/}
      {/*		)*/}
      {/*	}*/}
      {/*</Alert>*/}
    </>
  );
});

LibraryAccept.propTypes = {
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]).isRequired,
};
