import { APIMethod, fetchData } from "@client";

export async function createCompanyPreset(name, params, font) {
  const res = await fetchData("/preset", APIMethod.POST, {
    data: {
      name,
      fonts: font,
      params: JSON.stringify(params),
    },
  });
  
  return res.results?.[0];
}
