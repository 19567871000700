import { bool, number, object, oneOf, string } from "prop-types";
import { Grid } from "@material-ui/core";
import { ScrollButton } from "@components";

export const ScrollButtonContainer = (scrollButtonProps) => {
  
  return (
    <Grid
      item container
      alignItems="center"
      justifyContent="flex-end"
      className="relative w-full"
      style={ { zIndex: 20, height: 24 } }
    >
      <ScrollButton
        direction="left"
        { ...scrollButtonProps }
      />
      <ScrollButton
        direction="right"
        { ...scrollButtonProps }
      />
    </Grid>
  )
}

ScrollButtonContainer.propTypes = {
  containerRef: object.isRequired,
  className: string,
  style: object,
  direction: oneOf(["left", "right"]),
  scrollSpeed: number,
  scrollReachedStart: bool,
  scrollReachedEnd: bool,
};