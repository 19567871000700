import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkSSOLinkQuery } from "@query";
import { login } from "@paths";
import { Alert } from "@components";
import { CircularProgress } from "@material-ui/core";

export const SSOLink = () => {
  
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { ssoLink } = useParams();
  
  const [alertVisible, showAlert] = useState(false);
  
  useEffect(() => {
    handleLink();
  });
  
  const handleLink = async () => {
    try {
      await checkSSOLinkQuery(ssoLink);
      navigate(login, { replace: true });
    } catch (e) {
      showAlert(true);
    }
  };
  
  const handleAlertClose = () => {
    navigate(login, { replace: true });
    showAlert(false);
  }
  
  return (
    <>
      <CircularProgress
        aria-label="progress indicator"
        className="absolute"
        style={{ left: "50%", top: "50%", marginLeft: -24, marginTop: -24 }}
      />
      <Alert
        title={t("alerts.link_expired")}
        isOpen={alertVisible}
        acceptText={t("common.close")}
        onAccept={handleAlertClose}
      />
    </>
  );
};
