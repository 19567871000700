import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@components";
import { object, string } from "prop-types";

export const ControlledTextField = forwardRef(({
  name,
  control,
  ...textFieldProps
}, ref) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState: { error } }) => (
      <TextField
        name={name}
        value={value}
        onChange={onChange}
        ref={ref}
        error={!!error}
        helperText={error?.message}
        {...textFieldProps}
      />
    )}
  />
));

ControlledTextField.propTypes = {
  name: string.isRequired,
  control: object.isRequired,
  errors: object,
  helperText: string,
};
