import { func, object, string } from "prop-types";
import { Columns, parsePrice, roundFloat } from "project-structure";

export const ExportTotals = ({
  name,
  currentProjectStructure,
  translate,
}) => {
  
  const {
    settings,
    visibility,
    totalCost,
    totalHours,
    totalTime,
    showTotalMaxCost,
    showTotalMaxHours,
    showTotalMaxTime,
  } = currentProjectStructure;
  
  const {
    useMinMax,
    currencyObj,
    timeModifier,
    roundPrice,
    roundType,
    roundHours,
    usedRoundDecimals,
  } = settings;
  
  
  const {
    apply,
    hideMinValues,
    hideMaxValues,
    hideTotalTime,
    hideTotalCost,
    hideTotalHours
  } = visibility;
  
  const showDecimals = roundType !== 2 || !roundHours;
  
  switch(name) {
    case Columns.COST:
      if(apply && hideTotalCost) return <></>
      return (
        <strong className="total-cost">
          {
            (!apply || !hideMinValues) &&
            <>
              {currencyObj.symbolStart || ""}
              {parsePrice(
                roundFloat(
                  useMinMax ? totalCost.min : totalCost.avg,
                  roundPrice ? 3 : 0,
                  !roundPrice,
                )
              )}
              {currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : ""}
            </>
          }
          {showTotalMaxCost && (!apply || !(hideMinValues || hideMaxValues)) && "  -  "}
          {showTotalMaxCost && (!apply || !hideMaxValues) && (
            <>
              {currencyObj.symbolStart || ""}
              {parsePrice(
                roundFloat(
                  totalCost.max,
                  roundPrice ? 3 : 0,
                  !roundPrice,
                )
              )}
              {currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : ""}
            </>
          )}
        </strong>
      )
    case Columns.HOURS:
      if(apply && hideTotalHours) return <></>
      return (
        <strong className="total-hours">
          {
            (!apply || !hideMinValues) &&
            <>
              {roundFloat(
                useMinMax ? totalHours.min : totalHours.avg,
                showDecimals ? roundHours : 0,
                false, usedRoundDecimals
              )}
              {" "}
              {translate(`times.${timeModifier === 1 ? "h" : "day"}`)}
            </>
          }
          {showTotalMaxHours && (!apply || !(hideMinValues || hideMaxValues)) && "  -  "}
          {showTotalMaxHours && (!apply || !hideMaxValues) && (
            <>
              {roundFloat(
                totalHours.max,
                showDecimals ? roundHours : 0,
                false, usedRoundDecimals
              )}
              {" "}
              {translate(`times.${timeModifier === 1 ? "h" : "day"}`)}
            </>
          )}
        </strong>
      )
    case Columns.TIME:
      if(apply && hideTotalTime) return <></>
      return (
        <strong className="total-time">
          {
            (!apply || !hideMinValues) &&
            <>
              {useMinMax ? totalTime.min : totalTime.avg}
              {" "}
              {translate("times.month")}
            </>
          }
          {showTotalMaxTime && (!apply || !(hideMinValues || hideMaxValues)) &&  "  -  "}
          {showTotalMaxTime && (!apply || !hideMaxValues) && (
            <>
              {totalTime.max}
              {" "}
              {translate("times.month")}
            </>
          )}
        </strong>
      )
    default:
      return <></>
  }
}

ExportTotals.propTypes = {
  translate: func,
  name: string,
  currentProjectStructure: object
}