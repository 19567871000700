export const downloadFile = async (fileName, fileBlob, url) => {
  let blob = fileBlob;
  if(url) {
    const res = await window.fetch(url);
    blob = await res.blob();
  }
  

  const a = window.document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  window.document.body.appendChild(a);
  a.click();
  a.remove();
};
