// Material components
import { SvgIcon } from "@material-ui/core";

export default (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2V4H21V6H3V4H9V2H15ZM5 20C5 21.103 5.897 22 7 22H17C18.103 22 19 21.103 19 20V7H5V20ZM7 9H17L17.001 20H7V9ZM9 11H11V18H9V11ZM15 11H13V18H15V11Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
