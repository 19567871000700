import { bool, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { useEditorCommentStore, useEditorWebsocket } from "@hooks";
import { TooltipIcon } from "@components";
import { ChatThread, TimelineSettings } from "@dialogs";
import { Trash } from "@assets";
import { observer } from "mobx-react";

export const SummaryNestedRowActions = observer(({
  resource,
  // onRemove,
  allowEdition,
  // commentPath,
  showComments
}) => {
  const commentStore = useEditorCommentStore();
  const socket = useEditorWebsocket();
  const { t } = useTranslation();
  
  // const commentPath = resource.treePath.join("/");
  const path = resource.treePath.join("/");
  const timelinePath = "t"+path;
  
  const handleRemoval = async () => {
    resource.removeSelf();
    socket?.requestCommentPathRemove(path);
    socket?.requestCommentPathRemove(timelinePath);
  };
  
  return (
    <>
      {allowEdition && (
        <TooltipIcon
          icon={<Trash color="error" />}
          onClick={handleRemoval}
          className="button-remove hoverIcon"
        >
          {t("views.editor.remove_resource")}
        </TooltipIcon>
      )}
      {
        allowEdition &&
        <TimelineSettings
          selectedResource={resource.id}
          className="button-wta-settings hoverIcon opaque"
        />
      }
      {showComments && commentStore?.commentsVisible && (
        <ChatThread rowThread path={path} />
      )}
    </>
  );
});

SummaryNestedRowActions.propTypes = {
  allowEdition: bool,
  resource: object,
  // commentPath: string,
  showComments: bool,
};
