import { forwardRef } from "react";
import { string, func, object } from "prop-types";
import { Add } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import useStyle from "./AddButton.style";
import classnames from "classnames";

export const AddButton = forwardRef(
  ({ onClick, style, name, className, ...buttonProps }, ref) => {
    const classes = useStyle();

    return (
      <IconButton
        ref={ref}
        color="primary"
        onClick={onClick}
        className={classnames(classes.root, className)}
        style={style}
        name={name}
        aria-label={name}
        {...buttonProps}
      >
        <Add />
      </IconButton>
    );
  }
);

AddButton.propTypes = {
  onClick: func.isRequired,
  style: object,
  name: string,
  className: string,
};
