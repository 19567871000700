import { useEffect, useMemo, useState } from "react";
import { bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { quillBlocksRegex, WYSIWYG_FORMATS, HtmlParser } from "@utils";
import ReactQuill from "react-quill";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import classnames from "classnames";
import useStyle from "@components/WysiwygEditor/WysiwygEditor.style";

export const ReadOnlyContent = ({
  onFocus,
  onShowMore,
  value,
  shorten,
  showFull,
  readOnly,
  showPlaceholder,
  useBorder,
  placeholder,
  overflows,
  blockContentButton,
  moreButtonClassName,
}) => {
  
  const classes = useStyle();
  const {t} = useTranslation();
  
  const [ loaded, setLoaded] = useState(false);
  
  const contentLines = useMemo(() => (
    !value
      ? []
      : value.match(quillBlocksRegex) || [value]
  ), [value]);
  
  const displayValue = useMemo(() => {
    if(!shorten || showFull) return value;
    if(!contentLines?.[0]) return "";
    return contentLines[0]
      .replace(/<li>/g, "<p>")
      .replace(/<\/li>/g, "</p>");
  }, [shorten, showFull, value, contentLines]);
  
  const showMoreButton = useMemo(() => (
    shorten &&
    (contentLines?.length > 1 || overflows)
  ), [shorten, contentLines, overflows]);
  
  useEffect(() => {
    if(!loaded)
      setLoaded(true);
  }, [displayValue, showFull])
  
  const handleShowMore = () => {
    if(blockContentButton) return;
    setLoaded(false);
    onShowMore && onShowMore()
  }
  
  const handleFocus = (selection) => {
    if(readOnly) return;
    onFocus && onFocus(selection);
  }
  
  const noPropagationClick = (e) => {
    if(readOnly) return;
    e.stopPropagation();
  }
  
  return (
    <>
      <Grid
        item container
        translate={ readOnly || !loaded ? undefined : "no" }
        onClick={noPropagationClick}
        className={classnames(
          useBorder && !readOnly && classes.bordered,
          "ql-root ql-editor", !showFull && "ql-ellipsis"
        )}
      >
        {
          readOnly || !loaded
            ? HtmlParser(displayValue)
            : <ReactQuill
              readOnly
              placeholder={showPlaceholder ? placeholder : undefined}
              value={displayValue}
              onFocus={handleFocus}
              className={classnames("w-full", classes.editor)}
              theme={null}
              formats={WYSIWYG_FORMATS}
              modules={{ toolbar: false, clipboard: { matchVisual: false } }}
            />
        }
      </Grid>
      {
        showMoreButton &&
        <Link
          className={classnames(
            "noDrag text-xs cursor-pointer p-0-5 px-0 ",
            useBorder && "ml-2",
            classes.moreButton,
            moreButtonClassName
          )}
          onClick={handleShowMore}
        >
          { showFull ? t("common.see_less") : t("common.see_more") }
        </Link>
      }
    </>
  )
}

ReadOnlyContent.propTypes = {
  onFocus: func,
  onShowMore: func,
  value: string,
  shorten: bool,
  showFull: bool,
  readOnly: bool,
  overflows: bool,
  showPlaceholder: bool,
  useBorder: bool,
  placeholder: string,
  moreButtonClassName: string,
  blockContentButton: bool,
}