import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { getFAQsQuery } from "@query";
import { useStores } from "@hooks";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Paper,
  CircularProgress,
  Hidden, Container,
} from "@material-ui/core";
import { HtmlParser } from "@utils";
import { Accordion, MenuButton, PageTitle } from "@components";
import { User } from "@assets";
import useStyle from "./Help.style";
import classnames from "classnames";

export const Help = () => {
  const { stateStore } = useStores();
  const classes = useStyle(isMobile);
  const location = useLocation();

  const [faqs, setFaqs] = useState([]);
  const [expanded, expand] = useState(null);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const parseGroupHash = (groupName) =>
    groupName.toLowerCase().replace(/ /g, "");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const f = await getFAQsQuery(stateStore.lang);
      setFaqs(f);
      const grps = f.reduce(
        (p, c) => (!p.includes(c.name) ? [...p, c.name] : p),
        []
      );
      setGroups(grps);
      setLoading(false);
      if (location.hash) {
        let q = location.hash.match(/^#([a-zA-Z]+)?_?(\d*)?$/);
        if (q?.[1]) setGroup(grps.find((g) => parseGroupHash(g) === q[1]));
        if (q?.[2]) expand(parseInt(q[2]));
      }
    })();
  }, []);

  const faqList = (
    <Grid
      item
      container
      className="p-0 h-full"
      justifyContent="center"
    >
      {faqs.map((f) => {
        const { id, name, question, answer } = f;
        if (!group || group === name) {
          const isExpanded = expanded === id;
          const identifier = `${group ? `${parseGroupHash(group)}_${id}` : id}`;
          const expandAction = () => expand(isExpanded ? null : id);

          return (
            <Accordion
              key={identifier}
              id={identifier}
              title={HtmlParser(question)}
              expanded={isExpanded}
              onExpand={expandAction}
              onEntered={(node) => {
                stateStore.scrollRef?.scrollTo({
                  top: node?.parentElement.offsetTop - 8,
                  behavior: "smooth",
                })
              }}
              titleClassName="h-64 pl-8"
              contentClassName="p-8"
              colored
              bordered
              reverseTitleRow
            >
              {HtmlParser(answer)}
            </Accordion>
          );
        }
        return null;
      })}
    </Grid>
  );

  return (
    <>
      <PageTitle />
      <Container>
        <Grid item container spacing={4} className={classes.root}>
          {isMobile ? (
            faqList
          ) : (
            <Paper className={classes.paper} elevation={0}>
              <Grid container wrap="nowrap" justifyContent="center">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Hidden xsDown>
                      <Grid
                        item
                        container
                        className={classes.menuContainer}
                        direction="column"
                      >
                        {groups.map((name) => {
                          return (
                            <MenuButton
                              key={name}
                              icon={<User />}
                              onClick={() =>
                                setGroup(group === name ? null : name)
                              }
                              className={classnames({
                                [classes.menuButton]: true,
                                selected: group === name,
                              })}
                            >
                              {name}
                            </MenuButton>
                          );
                        })}
                      </Grid>
                    </Hidden>
                    {faqList}
                  </>
                )}
              </Grid>
            </Paper>
          )}
        </Grid>
      </Container>
    </>
  );
};
