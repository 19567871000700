import { APIMethod, fetchData } from "@client";

export async function generateExportPdfQuery(
  onUploadProgress,
  uuidOrShareKey,
  body,
  font,
  tablePosition,
  noAuth = false
) {
  const res = await fetchData(
    `/project/export${noAuth ? "-no-authorization" : "pdf"}`,
    APIMethod.POST,
    {
      onUploadProgress,
      data: {
        uuid: uuidOrShareKey,
        html: body,
        font,
        tablePosition,
      },
    }
  );

  return res.results?.url;
}
