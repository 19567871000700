import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 24,
    width: "100%",
    height: "max-content",
    transition:
      "transform 300ms ease, box-shadow 300ms ease, -webkit-transform 300ms ease",
    "&:hover": {
      boxShadow: "0 4px 0.75rem 0 rgba(113, 115, 128, 0.5)",
      transform: "translate(0px, -0.25rem)",
    },
  },
  wrapperAlt1: {
    backgroundColor: theme.props.text.alt1,
    color: theme.props.text.alt2,
  },
  wrapperAlt2: {
    backgroundColor: theme.props.text.alt2,
    color: theme.props.text.neutral,
  },
  container: {
    height: "max-content",
    padding: 24,
    position: "relative",
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
    textTransform: "capitalize",
  },
  nameHighlight: {
    fontSize: 26,
    marginTop: 12,
  },
  price: {
    fontWeight: 900,
    fontSize: 36,
  },
  priceHighlight: {
    fontSize: 46,
  },
  priceDivider: {
    backgroundColor: "currentColor",
    width: "100%",
    margin: "12px 0",
  },
  featuresLink: {
    marginTop: 24,
    fontSize: 10,
    color: "inherit",
    cursor: "pointer",
    textAlign: "center",
  },
  amount: {
    fontSize: 12,
    marginBottom: 8,
    "& strong": {
      margin: "0 4px",
      fontSize: 16,
    },
  },
  buttonDivider: {
    height: 32,
  },
  topper: {
    color: theme.props.text.alt,
    justifyContent: "center",
    alignItems: "center",
    height: 24,
    width: "max-content",
    position: "absolute",
    top: 8,
    left: -10,
    "& span": {
      position: "absolute",
    },
  },
  topperTag: {
    fontSize: 103,
    height: "max-content",
    color: theme.palette.text.primary,
  },
  best: {
    color: theme.palette.primary.main,
  },
  duration: {
    fontSize: 10,
    marginLeft: 4,
  },
  durationHighlight: {
    fontSize: 14,
  },
  button: {
    borderRadius: 12,
  },
  checkmark: {
    fontSize: 16,
  },
}));

export default useStyle;
