export const basicFonts = [
  "Arial",
  "Calibri",
  "Courier New",
  "Georgia",
  "Garamond",
  "Helvetica",
  "Lucida Console",
  "Monaco",
  "Papyrus",
  "Times New Roman",
  "Verdana",
];
