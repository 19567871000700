import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionPlan } from "../SubscriptionPlan/SubscriptionPlan";
import { Contact } from "@dialogs";
import useStyle from "./CustomPlan.style";

export const CustomPlan = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [openContact, setContactModalState] = useState(false);

  return (
    <SubscriptionPlan
      code="custom"
      name={t("plans.custom")}
      onSelect={() => setContactModalState(true)}
      selectButtonText={t("views.subscription.plan.custom.talk")}
    >
      <div>
        <p className={classes.point}>
          {t("views.subscription.plan.custom.point1")}
        </p>
        <p className={classes.point}>
          {t("views.subscription.plan.custom.point2")}
        </p>
        <p className={classes.point}>
          {t("views.subscription.plan.custom.point3")}
        </p>
      </div>

      <span className={classes.offer}>
        {t("views.subscription.plan.custom.get")}
      </span>
      <Contact open={openContact} onClose={() => setContactModalState(false)} />
    </SubscriptionPlan>
  );
};
