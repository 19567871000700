import {
  bool,
  number,
  string,
  object,
  func,
  node,
  oneOf,
  oneOfType,
  arrayOf,
} from "prop-types";
import {
  Dialog as MuiDialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import useStyle from "./Dialog.style";
import classnames from "classnames";
import { isArray } from "lodash";
import { useMemo } from "react";

export const Dialog = ({
  id,
  open=false,
  children,
  actions,
  title,
  onClose,
  useCloseButton,
  useBackdropClick,
  actionsAlignment,
  width,
  minWidth,
  height,
  className,
  containerClass,
  actionsClass,
  titleClass,
  rootClass,
  containerId,
  style,
  variant,
  ...rest
}) => {
  const classes = useStyle();
  
  const parsedChildren = useMemo(() => {
    if(isArray(children)) {
      const filtered = children.filter(child => child);
      return filtered?.length ? filtered : undefined;
    }
    return children;
  }, [children]);
  
  return (
    <MuiDialog
      {...rest}
      open={open}
      onClose={useBackdropClick ? onClose : null}
      className={rootClass}
      PaperProps={{
        id,
        className: classnames(classes.root, variant && classes[`variant-${variant}`], className),
        style: width
          ? {
              width,
              minWidth,
              maxWidth: "90vw",
              ...(height ? { height } : {}),
              ...(style || {}),
            }
          : style,
      }}
    >
      {title && (
        <DialogTitle
          disableTypography
          className={classnames(
            isMobile && classes.mobileTitleContent,
            variant && classes[`variant-${variant}`]
          )}
        >
          {typeof title === "string" ? (
            <h2 className={titleClass}>{title}</h2>
          ) : (
            title
          )}
          {useCloseButton && onClose && (
            <IconButton
              color="primary"
              aria-label="close modal"
              onClick={onClose}
            >
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {Boolean(parsedChildren) && (
        <DialogContent
          className={classnames(
            isMobile && classes.mobileContent,
            !actions && "border-none",
            containerClass
          )}
          id={containerId}
        >
          {parsedChildren}
        </DialogContent>
      )}
      {Boolean(actions) && (
        <DialogActions
          className={classnames(
            classes.actions,
            classes[actionsAlignment + "Alignment"],
            isMobile && classes.mobileActionsContent,
            actionsClass
          )}
        >
          {actions}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  id: string,
  open: bool,
  onClose: func,
  title: oneOfType([string, node]),
  children: oneOfType([arrayOf(node), node]),
  actions: oneOfType([arrayOf(node), node]),
  className: string,
  style: object,
  containerId: string,
  rootClass: string,
  containerClass: string,
  actionsClass: string,
  titleClass: string,
  width: number,
  minWidth: number,
  height: number,
  useCloseButton: bool,
  useBackdropClick: bool,
  actionsAlignment: oneOf(["start", "end", "spaceBetween"]),
  variant: oneOf(["info", "warning", "danger"]),
};

Dialog.defaultProps = {
  useCloseButton: true,
  useBackdropClick: true,
  actionsAlignment: "end",
};
