import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: (isMobile) => (isMobile ? 16 : 24),
    height: theme.props.contentHeight,
    margin: "0 -12px",
    padding: "0 12px",
    alignContent: "flex-start",
    paddingBottom: 24,
  },
  paper: {
    width: "100%",
    backgroundColor: theme.props.backgroundSecondary,
    borderRadius: 10,
    overflow: "hidden",
    border: `1px solid ${theme.props.border}`,
    padding: 24,
    boxSizing: "border-box",
    height: "max-content",
  },
  menuButton: {
    border: "none !important",
    width: "100%",
    height: 48,
    fontSize: 16,
    justifyContent: "flex-start",
    fontWeight: "normal",
    "& svg": { fontSize: "24px !important" },
    "& p": { width: "100%" },
  },
  menuButtonSelected: {
    backgroundColor: `${theme.palette.primary.main}25`,
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  divider: {
    margin: "4px -24px",
  },
  contentContainer: {
    padding: (isMobile) => (isMobile ? 0 : 32),
    // backgroundColor: theme.props.backgroundSecondary,
    height: "100%",
  },
  dialog: {
    width: 510,
  },
  button: {
    width: "max-content",
    "&:first-child": { marginRight: 24 },
  },
  mobileDrawerButton: {
    margin: "5px 3px -5px 3px",
  },
}));

export default useStyle;
