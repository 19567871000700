export const isPlainObject = (value) => {
  if (!(value !== null && typeof value === "object")) return false;
  const proto = Object.getPrototypeOf(value);
  if (proto == null) return true;
  const protoConstructor =
    Object.hasOwnProperty.call(proto, "constructor") && proto.constructor;
  return (
    typeof protoConstructor === "function" &&
    protoConstructor.toString() === Object.toString()
  );
}; // https://stackoverflow.com/a/37865170
