import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  actionButton: {
    width: 40,
    borderRadius: "0 !important",
    "& svg": {
      fontSize: 18,
    },
  },
  actionButtonLarge: {
    width: 45,
    "& svg": {
      fontSize: 24,
    },
  },
  actionButtonSmall: {
    width: 32,
    "& svg": {
      fontSize: 16,
    },
  },
  actionMenu: {
    margin: 4,
    minWidth: "max-content !important",
    flexDirection: "row !important",
  },
}));

export default useStyle;
