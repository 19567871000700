import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { ValueCell } from "@components";
import { useMemo } from "react";

export const HourCell = observer(({
  element,
  allowEdition,
  profitabilityMode
}) => {
  
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  
  const {
    useMinMax,
    modifier,
    roundType,
    roundHours,
    usedRoundDecimals,
  } = structure.settings;
  
  const {
    apply,
    hideSummaryHours,
    hideMinValues,
    hideMaxValues,
  } = structure.visibility;
  
  const {
    parsedHours,
    availableHours,
    isOnlyResource,
    showHoursMax
  } = element;
  
  const handleHoursChange = (hour, isMax) => {
    structure.historyManager.startGroup();
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    hour = parseFloat((hour * modifier).toPrecision(12));
    const diff = hour - element.cHours?.[key];
    element.parent.updateResourceHours(element.id, diff, key);
    element.setCustomHours({ [key]: hour });
    element.setHoursOverwrite(true, useMinMax ? "min" : "avg");
    element.parent.generateTimeline();
    structure.historyManager.stopGroup();
  };
  
  const editable = useMemo(() => (
    allowEdition && !isOnlyResource && !profitabilityMode// && !isTimelineTableUsed
  ), [allowEdition, profitabilityMode, isOnlyResource]);
  
  // const messageTitle = useMemo(() => {
  //   if(isTimelineTableUsed)
  //     return t("views.editor.timeline_hours_alert.title")
  // }, [isTimelineTableUsed]);
  //
  // const messageContent = useMemo(() => {
  //   if(isTimelineTableUsed)
  //     return t("views.editor.timeline_hours_alert.content")
  // }, [isTimelineTableUsed]);
  
  const showDecimals = useMemo(() => (
    roundType !== 2 || !roundHours
  ), [roundType, roundHours]);
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryHours && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryHours]);

  return (
    <ValueCell
      displayContent
      widest
      value={useMinMax ? parsedHours.min : parsedHours.avg}
      valueMax={parsedHours.max}
      onValueChange={handleHoursChange}
      max={availableHours}
      editable={editable}
      showDecimals={showDecimals}
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={
        (allowEdition && useMinMax && !isOnlyResource) || (showHoursMax && (!apply || !hideMaxValues))
      }
      textPresetClass="preset-summaryText"
      useSeparator={profitabilityMode}
      decimals={usedRoundDecimals}
      className={className}
      inputClassName="input-hours"
    />
  );
});

HourCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  profitabilityMode: bool,
};
