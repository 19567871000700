import { APIMethod, fetchData } from "@client";

export async function updateSubscriptionQuery(
  paymentMethodId,
  priceId,
  quantity
) {
  return await fetchData("/order/subscription", APIMethod.PUT, {
    data: { priceId, quantity, paymentMethodId },
    // mapRequestKeysToSnakeCase: false,
  });
}
