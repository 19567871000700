import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { resetPasswordQuery } from "@query";
import { useStores } from "@hooks";
import { Alert, ClearButton } from "@components";
import { ArrowLeft } from "@assets";
import { login } from "@paths";
import { PasswordResetForm } from "@forms";

export const PasswordReset = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stateStore } = useStores();

  const [alertText, setAlertText] = useState("");

  const handleReset = async (data) => {
    try {
      const status = await resetPasswordQuery(data);
      if (status) navigate(login, { replace: true });
    } catch (e) {
      if (e.response) setAlertText(e.response.data.error);
      else setAlertText(t("errors.network"));
    }
  };

  return (
    <>
      <PasswordResetForm
        onSubmit={handleReset}
        defaultEmail={stateStore.resetEmail}
      />

      <ClearButton
        fullWidth
        size="medium"
        className="mt-2"
        icon={<ArrowLeft />}
        to={login}
      >
        {t("views.retrieve_password.back")}
      </ClearButton>
      <Alert
        isOpen={Boolean(alertText)}
        title={alertText}
        acceptText={t("common.close")}
        onAccept={() => setAlertText("")}
      />
    </>
  );
};
