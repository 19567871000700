import { APIMethod, fetchData } from "@client";

export async function changeProjectStatusQuery(uuid, status, order = null) {
  const data = { uuid, status, order };
  const res = await fetchData(
    "/project/status",
    APIMethod.POST,
    { data, removeEmptyKeys: true }
  );
  return res.results?.uuid;
}
