export const isEmptyHtml = (testString) => {
  return !!(
    !testString ||
    testString === "<p><br></p>" ||
    testString === "<p><br><p>"
  );
};

export const trimEmptyRows = (string) => {
  return string
    .replace(/^(<p><br><\/?p>)*/g, "")
    .replace(/(<p><br><\/?p>)*$/g, "");
};
