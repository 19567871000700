import { Chip } from "@material-ui/core";
import { node, oneOf, oneOfType, string } from "prop-types";
import useStyle from "./TagLabel.style";
import classnames from "classnames";

export const TagLabel = ({
  icon,
  children,
  color="primary",
  className,
  ...muiChipProps
}) => {
  const classes = useStyle();
  
  return (
    <Chip
      {...muiChipProps}
      size="small"
      icon={icon}
      label={ children }
      variant="outlined"
      classes={{
        root: classnames(
          muiChipProps.clases?.root,
          ["info", "warning", "danger"].includes(color) ? classes[`color-${color}`] : color,
          className
        ),
        icon: classnames(
          muiChipProps.clases?.icon,
          ["info", "warning", "danger"].includes(color) ? classes[`color-${color}`] : color,
        ),
        ...muiChipProps.classes
      }}
    />
  )
}

TagLabel.propTypes = {
  children: node.isRequired,
  icon: node,
  className: string,
  color: oneOfType([string, oneOf(["info", "warning", "danger"])])
}