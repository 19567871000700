import { makeStyles } from "@material-ui/core/styles";

const useStyle = (useStyles = true) =>
  makeStyles((theme) => {
    if (useStyles)
      return {
        "@global": {
          ".preset-title:hover, .preset-breakdownHeader:hover, .preset-tableBreakdownHeader:hover, .preset-tableSummaryHeader:hover, .preset-tableTimelineHeader:hover, .preset-tableTimelineBilling:hover, .preset-tableFooter:hover, .preset-summaryHeader:hover, .preset-timelineSubHeader:hover, .preset-timelineHeader:hover, .preset-totalTime:hover, .preset-totalCost:hover, .preset-totalHours:hover":
            {
              outline: "1px dashed " + theme.palette.primary.main + "cc",
              outlineOffset: 2,
              cursor: "pointer",
            },
          ".preset-text:hover, .preset-summaryText:hover, .preset-timelineText:hover, .preset-titleText:hover, .preset-titleTextSection:hover, .preset-summaryTitleText:hover, .preset-timelineTitleText:hover":
            {
              outline: "1px dashed " + theme.palette.primary.main + "cc",
              outlineOffset: 3,
              cursor: "pointer",
            },
          ".preset-price:hover": {
            outline: "1px dashed " + theme.palette.primary.main + "cc",
            cursor: "pointer",
          },
          ".preset-logo:hover": {
            outline: "1px dashed " + theme.props.backgroundSecondary + "cc",
            outlineOffset: 2,
            cursor: "pointer",
          },
        },
      };
  })();

export default useStyle;
