import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    width: "40%",
    margin: "16px 8px",
  },
}));

export default useStyle;
