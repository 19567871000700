import { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import {
  TextField as Field,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  ExpandMore,
} from "@material-ui/icons";
import useStyle from "./TextField.style";
import classnames from "classnames";

export const TextField = forwardRef(({
  label,
  id,
  name,
  className,
  type,
  InputProps,
  select,
  endAdornment,
  startAdornment,
  fullWidth,
  ...rest
}, ref) => {
  const classes = useStyle();

  const [showPassword, setShowPassword] = useState(false);

  let inputType = type;
  if (type === "password" && showPassword) inputType = "text";

  return (
    <Field
      ref={ref}
      className={classnames(
        {
          [classes.root]: true,
          [classes.noLabel]: !label,
        },
        className
      )}
      fullWidth={fullWidth}
      label={label}
      name={name || id}
      type={inputType}
      data-private={type === "password" ? "redacted" : undefined}
      select={select}
      variant="outlined"
      SelectProps={select ? { IconComponent: ExpandMore } : undefined}
      InputProps={{
        endAdornment:
          !endAdornment && type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
              >
                {showPassword ? (
                  <VisibilityOffOutlined />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            </InputAdornment>
          ) : (
            endAdornment
          ),
        startAdornment,
        ...InputProps,
      }}
      {...rest}
    />
  );
});

TextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.any,
  helperText: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["text", "password", "email", "number"]),
  InputProps: PropTypes.object,
  select: PropTypes.bool,
  endAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  startAdornment: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export const TextFieldProps = TextField.propTypes;

TextField.defaultProps = {
  className: "",
  type: "text",
  InputProps: {},
  fullWidth: true
};
