import { bool } from "prop-types";
import { usePresetFieldNames } from "@hooks";
import { ValueCell } from "@components";

export const ProfitabilityCell = ({
  allowEdition,
  isProposal,
}) => {
  const { getFieldName } = usePresetFieldNames(isProposal);
  
  return (
    <ValueCell
      wider
      isHeader
      size="xSmall"
      style={{ cursor: allowEdition && "grab" }}
    >
      <span>
        {getFieldName("pt-profitability")}
      </span>
    </ValueCell>
  )
}

ProfitabilityCell.propTypes = {
  allowEdition: bool,
  isProposal: bool,
}