import { useEffect, useState } from "react";
import { useStores, useAutoLogin } from "@hooks";
import { useLocation } from "react-router-dom";
import { login, help } from "@paths";
import { AppContainer, AppToolBar } from "@components";
import { Help } from "@views";

export const SharedHelp = () => {
  const { stateStore } = useStores();
  const location = useLocation();

  const autoLogin = useAutoLogin();
  
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        stateStore.setMemorizedAction(help + location.hash);
        await autoLogin();
        setLoading(false);
      } catch(e) {
        setLoading(false);
      }
    })();
  }, []);

  if (isLoading) return null;

  return (
    <>
      <AppToolBar noActions iconLink={login} />
      <AppContainer>
        <Help />
      </AppContainer>
    </>
  );
};
