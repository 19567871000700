import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { StructureStoreProvider } from "@stores";
import { useTranslation } from "react-i18next";
import {
  useFonts,
  useProjectEditorStore,
  useEditorWebsocket,
  useCheckProjectAccess,
  useStructureTransactionListener,
  useEditorCommentStore,
  useStores,
} from "@hooks";
import { useParams, useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { fetchProposalCommentsQuery } from "@query";
import { PageCircularProgress, PdfUploadProgress, EditorRoot } from "@components";

export const ProjectEditorView = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const editorStore = useProjectEditorStore();
  const { projectUuid } = useParams();
  const socket = useEditorWebsocket();
  const commentStore = useEditorCommentStore()
  const [searchParams] = useSearchParams();
  const { unloadFonts } = useFonts();

  const [isLoading, setLoading] = useState(false);

  const {
    projectLoaded,
    isArchived,
    currentProjectStructure,
    proposalStep,
    projectUsers,
  } = editorStore;

  const {
    hasEditorPrivileges,
    hasSellerPrivileges,
    isObserver
  } = useCheckProjectAccess(projectUsers);

  useStructureTransactionListener(currentProjectStructure, false, isObserver);

  useEffect(() => {
    if (isArchived)
      enqueueSnackbar(t("alerts.editor.archive_readonly"));
  }, [isArchived]);

  const init = async () => {
    setLoading(true);
    
    if(hasEditorPrivileges && commentStore)
      commentStore.setCommentsVisible(hasEditorPrivileges);
    socket?.init(true, hasEditorPrivileges);

    if (searchParams.get("share")) editorStore.forceShareModal(true);

    if (!isObserver)
      enqueueSnackbar(t("alerts.editor.readonly_info"), {
        variant: "info",
        autoHideDuration: 6000,
      });

    setLoading(false);
  };
  
  useEffect(() => {
    if(
      hasEditorPrivileges
      && !commentStore
        .fetchedVersions
        .includes(editorStore.currentVersionKey)
    )
      fetchProjectComments();
    
    return () => {
      unloadFonts();
    }
  }, [hasSellerPrivileges, editorStore.currentVersionKey])

  useEffect(() => {
    if (projectLoaded) init();
  }, [projectLoaded]);
  
  const fetchProjectComments = async () => {
    if(!commentStore) return;
    // const res = await checkPrivilegesQuery(LOCKED_FEATURES.INTERNAL_COMMENTS.serverCode);
    commentStore.setUseInternalComments(true);
    commentStore.setUseExternalComments(hasSellerPrivileges);
    const c = await fetchProposalCommentsQuery(projectUuid, editorStore.currentVersionKey, userStore.userUuid);
    commentStore.setComments(c, editorStore.currentVersionKey);
  }
  

  if (isLoading || !currentProjectStructure) return <PageCircularProgress />;

  return (
    <StructureStoreProvider value={currentProjectStructure}>
      <EditorRoot
        hasEditorPrivileges={
          socket?.connectionOnline && !isArchived && hasEditorPrivileges
        }
        hasSellerPrivileges={hasSellerPrivileges}
        readOnly={!socket?.connectionOnline || isArchived || !!proposalStep}
        isProposal={!!proposalStep}
        useClientActions={
          !isArchived && hasEditorPrivileges && proposalStep !== 1
        }
        forceAllowVersionSelect={isArchived}
        showStepper={
          !isArchived &&
          !proposalStep &&
          hasSellerPrivileges &&
          hasEditorPrivileges &&
          socket?.connectionOnline
        }
        showPdfCover={proposalStep === 2}
        projectStructure={currentProjectStructure}
        showUserMovement={proposalStep === 0}
      />
      <PdfUploadProgress />
    </StructureStoreProvider>
  );
});
