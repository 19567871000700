import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  removeButton: {
    position: "absolute",
    right: 4,
    padding: 3,
    backgroundColor: theme.props.background,
  },
  descContainer: {
    zIndex: 11,
    position: "relative",
    backgroundColor: theme.props.background,
    padding: "16px 8px",
  },
}));

export default useStyle;
