import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  container: {
    marginTop: 16,
    marginBottom: 32,
    backgroundColor: theme.props.text.alt2,
    border: "1px solid " + theme.props.text.alt2,
    borderRadius: "50px !important",
    width: "max-content",
    position: "relative",
  },
  switchButton: {
    borderRadius: "50px !important",
    backgroundColor: theme.props.text.alt2,
    borderColor: theme.props.text.alt2,
    color: theme.props.text.alt,
    width: 146,
    fontWeight: 900,
    height: 56,
  },
  switchButtonSelected: {
    backgroundColor: theme.props.backgroundSecondary,
    color: theme.props.text.alt2,
    textDecoration: "underline",
  },
  discount: {
    backgroundColor: theme.props.success,
    color: theme.props.text.alt,
    position: "absolute",
    left: -130,
    width: 103,
    height: 28,
    borderRadius: 5,
    fontSize: 16,
    fontWeight: 600,
    "@media (max-width: 520px)": {
      display: "none",
    },
  },
  discountLine: {
    position: "absolute",
    left: 106,
    top: -8,
  },
}));

export default useStyle;
