import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  commentsContainer: {
    marginTop: 8,
    paddingTop: 8,
    borderTop: "1px solid " + theme.props.border,
  },
  progress: {
    position: "relative",
    marginTop: 10,
    marginLeft: -20,
    left: "50%",
  },
}));

export default useStyle;
