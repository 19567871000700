import { func, oneOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert } from "@components";

export const DifferentValueAlert = ({
  moduleLevel,
  handleValueAlertClose
}) => {
  const { t } = useTranslation();
  
  const onAccept = () => handleValueAlertClose(true);
  const onAcceptSplit = () => handleValueAlertClose(false);
  const onCancel = () => handleValueAlertClose();
  
  return (
    <Alert
      isOpen
      title={t(`views.editor.children_value_alert.title`, { level: t(`proposal_parts.module_levels.level_${moduleLevel}`) })}
      acceptText={t("views.editor.children_value_alert.reset")}
      intermediateText={t("views.editor.children_value_alert.split")}
      cancel={t("common.cancel")}
      onAccept={onAccept}
      onIntermediate={onAcceptSplit}
      onCancel={onCancel}
      width={600}
    />
  )
}

DifferentValueAlert.propTypes = {
  moduleLevel: oneOf([0, 1, 2, 3]).isRequired,
  handleValueAlertClose: func.isRequired
}