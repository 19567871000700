import { APIMethod, fetchData } from "@client";
import {libraryChildMap} from "@utils";

export async function fetchLibraryQuery(pending = false) {
  const res = await fetchData(
    `/v1/library${pending ? "/pending" : "/section"}`,
    APIMethod.GET
  );

  const sections = res.results[0];
  Object.keys(sections).forEach((key) => {
    sections[key] = Object.values(sections[key]).map((e) =>
      libraryChildMap(e, "id")
    );
  });

  return sections;
}
