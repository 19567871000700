import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    width: 416,
  },
  threadContainer: {
    maxHeight: 400,
    overflowY: "auto"
  },
  responseContainer: {
    backgroundColor: theme.props.background + "a0",
  },
  messageBordered: {
    borderBottom: `1px solid ${theme.props.border}`,
  },
  editorContainer: {
    width: "100%",
    padding: 8,
    backgroundColor: theme.props.backgroundSecondary,
  },
  bordered: {
    borderTop: `2px solid ${theme.props.border}`,
  },
  navi: {
    fontSize: "16px !important",
    letterSpacing: "normal !important",
    fontWeight: "normal !important"
  }
}));

export default useStyle;
