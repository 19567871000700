const CENTER = {
  vertical: "middle",
  horizontal: "center",
};

const RIGHT = {
  vertical: "middle",
  horizontal: "right",
};

const CENTER_VERTICAL = {
  vertical: "middle",
};

const COLOR = (color) => ({ argb: "FF" + color.slice(1) });

const BORDER_LEFT = (color) => ({
  left: {
    style: "thin",
    color: COLOR(color),
  },
});

const BORDER_RIGHT = (color) => ({
  right: {
    style: "thin",
    color: COLOR(color),
  },
});

const BORDER_HORIZONTAL = (color) => ({
  ...BORDER_LEFT(color),
  ...BORDER_RIGHT(color),
});

const FONT_TABLE = {
  size: 12,
  color: COLOR("#FFFFFF"),
  bold: true,
};

const FONT_HEADER = {
  color: COLOR("#FFFFFF"),
};

const FILL = (color) => ({
  type: "pattern",
  pattern: "solid",
  fgColor: COLOR(color),
});

export const TITLE_ROW_HEIGHT = 30;

export const sectionStyle = {
  fill: FILL("#A0CBF8"),
  font: {
    size: 11,
    bold: true,
  },
};

export const sectionTotalStyle = {
  ...sectionStyle,
  alignment: RIGHT,
};

export const taskFill = (level) => {
  if (level <= 3) return level === 1 ? FILL("#CDE6FF") : FILL("#E2F1FF");
};

export const taskFont = (level, isTurnedOff = false) => {
  const style = {};
  if (level === 1) style.bold = true;
  if (isTurnedOff) style.color = COLOR("#888888");
  
  return style;
};

export const dividerStyle = {
  fill: FILL("#4098F4"),
};

export const proposalHeaderStyle = {
  alignment: CENTER,
  fill: FILL("#1255CC"),
  font: FONT_TABLE,
};

export const tableHeaderStyle = {
  fill: FILL("#4098F4"),
  font: FONT_HEADER,
  alignment: { ...CENTER_VERTICAL, wrapText: true },
};

export const tableHeaderStyleCenter = {
  ...tableHeaderStyle,
  alignment: { ...CENTER, wrapText: true },
};

export const turnedOffValueCellStyle = {
  font: {
    color: COLOR("#dddddd"),
  },
};

export const valueCellStyle = (
  isSection = false,
  align = undefined,
  turnedOff = false
) => ({
  alignment: CENTER,
  border:
    align === "left"
      ? BORDER_LEFT("#4098F4")
      : align === "right"
        ? BORDER_RIGHT("#4098F4")
        : BORDER_HORIZONTAL("#4098F4"),
  font: {
    color: turnedOff ? COLOR("#dddddd") : undefined,
    bold: isSection,
  },
});

export const totalCellStyle = (right = false, left = false) => ({
  alignment: left ? undefined : right ? RIGHT : CENTER,
  fill: FILL("#CDE6FF"),
});