import { bool, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { PopMenu } from "@components";
import { Pencil } from "@assets";
import useStyle from "./Tooltip.style";

export const Tooltip = ({ anchor, show }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const elClass = anchor ? anchor.elClass : "";

  return elClass ? (
    <PopMenu
      show={show}
      anchor={anchor}
      placement="right"
      className={classes.root}
    >
      <Pencil className="mr-1" />
      {t(`views.editor.preset.edit_tags.${elClass}`)}
    </PopMenu>
  ) : (
    <></>
  );
};

Tooltip.propTypes = {
  show: bool,
  anchor: object,
};
