import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    height: "max-content",
    width: "100%",
    minWidth: "100%",
    position: "relative",
    overflowX: "auto",
    overflowY: "hidden",
    // paddingBottom: 8,
    marginBottom: 16,
  },
}));

export default useStyle;
