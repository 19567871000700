import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { ValueCell } from "@components";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useProjectEditorStore, useStructureStore } from "@hooks";

export const TeamMembersCell = observer(({
  element,
  allowEdition,
  displayCellContent,
  profitabilityMode,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { totalTeamMembers, hasOnlyCost, hasResources } = element;
  const { isTimelineTableUsed } = structure.settings;
  const { apply, hideSummaryTeam } = structure.visibility;

  const handleTeamMembersChange = (members) => {
    element.setTeamMembers(members);
  };
  
  const messageTitle = useMemo(() => {
    if(isTimelineTableUsed && allowEdition)
      return t("views.editor.timeline_team_alert.title")
  }, [isTimelineTableUsed, allowEdition]);
  
  const messageContent = useMemo(() => {
    if(isTimelineTableUsed && allowEdition)
      return t("views.editor.timeline_team_alert.content")
  }, [isTimelineTableUsed, allowEdition]);
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryTeam && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryTeam]);

  return (
    <ValueCell
      wider
      value={totalTeamMembers}
      min={1}
      onValueChange={handleTeamMembersChange}
      displayContent={!hasOnlyCost && displayCellContent}
      editable={allowEdition && !hasResources && !profitabilityMode}
      textPresetClass={ classnames("preset-summaryText", classes.input) }
      messageTitle={messageTitle}
      message={messageContent}
      className={className}
      inputClassName="input-team-members"
    />
  );
});

TeamMembersCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
  profitabilityMode: bool,
};

const useStyle = makeStyles(() => ({
  input: {
    maxWidth: "62px !important",
  }
}));