import { bool, string, oneOf } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ActionButton} from "@components";
import { CircularProgress } from "@material-ui/core";
import { Export } from "@assets";
import { usePdfExporter } from "./usePdfExporter";
import classnames from "classnames";

export const PdfExport = observer(({
  shareKey,
  useSharedStyle,
  titleBarButton,
  attachCover,
  size,
  simpleExport,
  disableActions,
}) => {
  const { t } = useTranslation();

  const { isLoading, exportProposal } = usePdfExporter(
    shareKey,
    attachCover,
    simpleExport,
  );

  const handleExport = async () => {
    if(disableActions) return;
    await exportProposal();
  };

  return (
    <ActionButton
      square
      icon={
        isLoading ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          <Export />
        )
      }
      onClick={handleExport}
      color="secondary"
      size={size}
      className={classnames(
        useSharedStyle && "preset-export",
        !titleBarButton && "p-0"
      )}
      name={t("views.editor.export_name.document")}
      tooltip
    />
  );
});

PdfExport.propTypes = {
  shareKey: string,
  useSharedStyle: bool,
  titleBarButton: bool,
  attachCover: bool,
  simpleExport: bool,
  disableActions: bool,
  size: oneOf(["small", "medium", "large"]),
};
