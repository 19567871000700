import { makeStyles } from "@material-ui/core/styles";
import { MAX_PAGE_WIDTH } from "@styles/themes";

const useStyle = makeStyles((theme) => ({
  collapse: {
    width: "100%",
    maxWidth: MAX_PAGE_WIDTH,
  },
  tableContainerWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: 0,
    boxSizing: "border-box",
    flexDirection: "column",
    width: "100%",
  },
  tableContainer: {
    position: "relative",
    overflowX: "auto",
    overflowY: "hidden",
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    minWidth: "100%",
  },
  buttonSwitch: {
    marginLeft: 16,
  },
  overflows: {
    "& .display-overflow": {
      borderRightStyle: "solid !important",
      borderRightWidth: "2px !important",
      borderRightColor: `${ theme.props.border } !important`,
    }
  },
  whiteContainer: {
    width: "100%",
    padding: "24px 24px 0",
    margin: "0 -24px",
    backgroundColor: theme.props.backgroundSecondary,
    boxSizing: "content-box",
    "& > div": {
      maxWidth: MAX_PAGE_WIDTH,
    },
  },
  whiteContainerFirst: {
    paddingBottom: 24,
    borderTop: "1px solid " + theme.props.border,
  },
  whiteContainerLast: {
    paddingBottom: 24,
    borderBottom: "1px solid " + theme.props.border,
  },
}));

export default useStyle;
