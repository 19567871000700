import { makeStyles } from "@material-ui/core/styles";
import { DEFAULT_FONT_NAME } from "@utils";

const useStyle = makeStyles((theme) => ({
  actionContainer: {
    color: theme.props.background,
    margin: -4,
    width: "max-content",
    "& > button": {
      margin: 4,
    },
  },
  info: {
    color: theme.palette.text.primary + "80",
  },
  infoText: {
    color: theme.palette.text.primary + "80",
    fontSize: 13,
    fontWeight: 600,
  },
  dot: {
    backgroundColor: theme.props.dot,
    width: 4,
    height: 4,
    borderRadius: "50%",
    margin: "0 8px",
  },
  snackbar: {
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    fontFamily: `'${DEFAULT_FONT_NAME}', sans-serif !important`,
  },
  projectTitleEditor: {
    marginLeft: -6,
  },
  titleProposal: {
    display: "flex",
    alignItems: "center",
  }
}));

export default useStyle;
