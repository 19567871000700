import { APIMethod, fetchData } from "@client";

export async function setEstimationStatusQuery(projectUuid, workTypeId) {
  await fetchData("/estimate/project/estimator/status", APIMethod.POST, {
    data: {
      project: projectUuid,
      worktype: workTypeId,
    },
  });
}
