import { Children, memo, isValidElement, cloneElement } from "react";
import {
  bool,
  number,
  string,
  object,
  node,
  oneOfType,
  arrayOf,
} from "prop-types";
import Grid from "@material-ui/core/Grid";
import { TABLE_DRAGGABLE_CONTAINER } from "@utils";
import { ExpandCell, TitleCell, VisibilityCell } from "../../cells";
import useStyle from "./Row.style";
import classnames from "classnames";

export const Row = memo(({
  id,
  children,
  endRowChildren,
  useExpandCell,
  useVisibilityCell,
  hideVisibilitySwitch,
  isFirstGroupRow,
  isLastGroupRow,
  className,
  style,
  presetClass,

  expandable,
  expanded,
  noArrow,
  onExpand,
  expandOnClick,
  visible = true,
  onVisibilityToggle,
  actionTargetName,
  tableDepth,
  isHeader,
  useFiller,
  fillerAction,
  expandCellPresetClass,
  ...commonProps
}) => {
  const classes = useStyle();

  const childrenWithProps = Children.map(children, (child) => {
    if (
      isValidElement(child)
      && child?.props?.className !== TABLE_DRAGGABLE_CONTAINER
      && !(child?.props?.item || child?.props?.container)
    ) {
      return cloneElement(child, {
        ...commonProps,
        isHeader,
        ...child.props,
      });
    }
    return child;
  });

  return (
    <Grid
      item
      container
      id={id}
      // key={`${fullIdPath.slice(-1)[0]}-main`}
      role="row"
      wrap="nowrap"
      // alignItems="flex-start"
      className={classnames(
        presetClass,
        tableDepth >= 0 && `d${tableDepth}`,
        classes.row,
        {
          top: isFirstGroupRow,
          bottom: isLastGroupRow, //lastRow && (!hasChildren || !expanded),
          [classes.rowOff]: !visible, //useClientActions &&
          [classes.rowSelected]: expanded,
          [`preset-active-${tableDepth}`]:
            !presetClass && expanded && tableDepth > 0,
          "preset-row": !presetClass && !expanded && tableDepth > 0,
          "preset-sectionRow": !presetClass && tableDepth === 0,
        },
        className
      )}
      style={style}
      aria-selected={expanded}
      onClick={expandOnClick ? onExpand : undefined}
    >
      {useExpandCell && (
        <ExpandCell
          {...commonProps}
          isHeader={isHeader}
          tableDepth={tableDepth}
          expandable={expandable}
          expanded={expanded}
          onExpand={!expandOnClick ? onExpand : undefined}
          noArrow={noArrow}
          textPresetClass={expandCellPresetClass}
        />
      )}
      {childrenWithProps}
      {useVisibilityCell && (
        <VisibilityCell
          {...commonProps}
          isHeader={isHeader}
          tableDepth={tableDepth}
          visible={visible}
          hideVisibilitySwitch={hideVisibilitySwitch}
          onVisibilityToggle={onVisibilityToggle}
          actionTargetName={actionTargetName}
        />
      )}
      {useFiller && (
        <TitleCell minWidth={0} isHeader={isHeader}>
          {fillerAction}
        </TitleCell>
      )}
      {endRowChildren}
    </Grid>
  );
});

Row.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  endRowChildren: node,
  id: string,
  useExpandCell: bool,
  useVisibilityCell: bool,
  isLastGroupRow: bool,
  isFirstGroupRow: bool,
  expandOnClick: bool,
  style: object,
  className: string,
  expandCellPresetClass: string,
  presetClass: string,
  isHeader: bool,
  useFiller: bool,
  fillerAction: node,
  ...ExpandCell.props,
  ...VisibilityCell.props,
  tableDepth: number,
  highlightCellText: bool,
};
