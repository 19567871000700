import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { getProfitColor } from "@utils";
import { ValueCell } from "@components";
import { useTheme } from "@material-ui/core/styles";

export const ProfitCell = observer(({
  element,
  allowEdition,
  displayCellContent
}) => {
  const theme = useTheme();
  const structure = useStructureStore();
  const { useMinMax, currencyObj, roundPrice } = structure.settings;
  const { parsedCompanyProfit, companyProfitability, showCompanyProfitMax } = element;
  
  const color = useMemo(() => (
    getProfitColor(companyProfitability, theme)
  ), [companyProfitability, theme]);

  const handleCompanyProfitChange = (profit, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCompanyProfit(profit, key);
  };

  return (
    <ValueCell
      wider
      widest={useMinMax}
      displayContent={displayCellContent}
      value={useMinMax ? parsedCompanyProfit.min : parsedCompanyProfit.avg}
      valueMax={parsedCompanyProfit.max}
      onValueChange={handleCompanyProfitChange}
      editable={allowEdition}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      displayMax={showCompanyProfitMax}
      allowNegative
      max={10000000}
      style={{color}}
      inputClassName="input-profit"
    />
  );
});

ProfitCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
};
