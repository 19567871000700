import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { ActionButton, Button, Dialog } from "@components";
import { XlsFileDark } from "@assets";
import { useXlsExporter } from "./useXlsExporter";
import useStyle from "./XlsExport.style";
import { useState } from "react";

export const XlsExport = observer(({
  disableActions
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  
  const { isLoading, exportProposal } = useXlsExporter();
  
  const [dialogVisible, showDialog] = useState(false);
  
  const handleExport = (unstyled=false) => () => {
    exportProposal(unstyled);
    showDialog(false);
  }
  
  return (
    <>
      <ActionButton
        square
        icon={
          isLoading ? (
            <CircularProgress color="primary" size={20} />
          ) : (
            <XlsFileDark />
          )
        }
        onClick={() => showDialog(true)}
        color="secondary"
        name={t("views.editor.export_name.spreadsheet")}
        tooltip
      />
      {!disableActions && (
        <>
          <Dialog
            width={320}
            open={dialogVisible}
            onClose={() => showDialog(false)}
            className={classes.dialog}
            containerClass={classes.dialogContent}
          >
            <Button
              color="secondary"
              onClick={handleExport()}
              className={classes.exportButton}
              size="large"
            >
              <XlsFileDark color="primary" />
              <span className={classes.label}>
                { t("views.editor.export_name.styled") }
              </span>
            </Button>
            <Button
              color="secondary"
              onClick={handleExport(true)}
              className={classes.exportButton}
              size="large"
            >
              <XlsFileDark color="secondary" />
              <span className={classes.label}>
                {t("views.editor.export_name.unstyled")}
              </span>
            </Button>
          </Dialog>
        </>
      )}
    </>
  );
});