import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  noTextIcon: { "& .MuiButton-startIcon, &.MuiButton-endIcon": { margin: 0 } },

  clearButton: {
    border: "none !important",
    padding: "6px 12px",
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: "transparent",
  },
  menuButton: {
    width: "100%",
    height: 36,
    fontSize: 12,
    fontWeight: 600,
    justifyContent: "flex-start",
    padding: "0 16px",
    borderRadius: "0 !important",
    "&:not(:last-child)": {
      borderBottom: "1px solid " + theme.props.background,
    },
  },
  addButtonFull: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textTransform: "none",
    backgroundColor: 'transparent'
  },
  circle: {
    backgroundColor: theme.props.memberAddButton,
    border: "1px dashed",
    width: 24,
    height: 24,
    borderRadius: "50%",
    boxSizing: "border-box",
  },
  icon: {
    width: 16,
    height: 16,
  },
  "buttonSquare-large": {
    width: 45,
  },
  "buttonSquare-medium": {
    width: 36,
  },
  "buttonSquare-small": {
    width: 32,
  }
}));

export default useStyle;
