import { useEffect, useRef, useState } from "react";

export const useTooltipHoverActions = (disableHover) => {
  const [hover, setHover] = useState(false);
  const timeout = useRef();

  const handleHoverIn = () => {
    if (!disableHover) setHover(true);
  };

  const handleHoverOut = () => setHover(false);

  const handlePopupClose = () => {
    if (hover) timeout.current = setTimeout(() => setHover(false), 200);
  };

  const handleTimeoutClear = () => {
    if (timeout.current) clearTimeout(timeout.current);
  };

  useEffect(() => {
    handleTimeoutClear();
  }, [hover]);

  useEffect(() => {
    if (disableHover) setHover(false);
  }, [disableHover]);

  return {
    hover,

    handleHoverIn,
    handleHoverOut,
    handlePopupClose,
    handleTimeoutClear,
  };
};
