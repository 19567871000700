import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  sampleColorsContainer: {
    margin: "-2px -2px -2px 14px",
    width: 64,
  },
  input: {
    flexGrow: 1,
    flexBasis: 0,
  },
  block: {
    width: "calc(50% - 4px)", //32,
    height: 25,
    border: "1px solid " + theme.palette.text.primary + "26",
    borderRadius: 5,
    boxSizing: "border-box",
    margin: 2,
    cursor: "pointer",
  },
  saturation: {
    position: "relative",
    width: 228,
    height: 228,
    borderRadius: 5,
    "& .saturation-white > div:last-child > div": {
      border: "2px solid #fff",
      boxShadow: "none !important",
    },
  },
  pointerHue: {
    marginLeft: 16,
    borderRadius: 5,
    "& .hue-vertical > div": {
      left: "auto !important",
      right: 0,
      "& > div": {
        border: "2px solid #fff",
        boxShadow: "none !important",
      },
    },
  },
}));

export default useStyle;
