import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles(() => ({
  cellContainer: {
    width: CELL_WIDTHS.VALUE.SMALL+8,
    minWidth: CELL_WIDTHS.VALUE.SMALL+8,
  },
  cellExpanded: {
    width: CELL_WIDTHS.VALUE.TINY * 4,// + 8,
    minWidth: CELL_WIDTHS.VALUE.TINY * 4,// + 8
  },
  weekCell: {
    width: CELL_WIDTHS.VALUE.TINY,
    minWidth: CELL_WIDTHS.VALUE.TINY
  },
  button: {
    right: 0,
    zIndex: 10
  },
  buttonOpened: {
    right: -8,
  },
}));

export default useStyle;
