import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router-dom";
import { connectToJiraQuery } from "@query";
import { useFeatureGuardian } from "@hooks";
import { home } from "@paths";
import { Button } from "@components";
import { CircularProgress } from "@material-ui/core";
import { LOCKED_FEATURES } from "@utils";
import useStyle from "./JiraAuth.style";

export const JiraAuth = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [searchParams] = useSearchParams();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.JIRA
  );

  const [isLoading, setLoading] = useState();
  // const [error, setError] = useState();

  const domain = atob(searchParams.get("dm") || "");
  const cb = atob(searchParams.get("cb") || "");

  const handleAuth = async () => {
    setLoading(true);
    const allowed = await checkAccess();
    if (allowed) {
      try {
        await connectToJiraQuery(cb, domain);
        // if (!result) {
        //   setError("Something went wrong");
        // }
      } catch (e) {
        console.error(e);
        // setError("Something went wrong");
      }
      window.history.back();
    }
    setLoading(false);
  };

  if (!cb) return <Navigate to={home} />;

  return (
    <>
      <h1 className={classes.title}>{t("views.auth.jira.title")}</h1>

      <p className={classes.desc}>{t("views.auth.jira.desc")}</p>

      <p className={classes.desc}>{t("views.auth.jira.tech_desc")}</p>

      <p className="text-center w-full mt-2">
        <strong>{domain}</strong>
      </p>

      <Button
        onClick={handleAuth}
        name="submit"
        variant="contained"
        className={classes.authorize}
        icon={
          isLoading ? (
            <CircularProgress
              aria-label="progress indicator"
              size={24}
              color="inherit"
            />
          ) : null
        }
      >
        {!isLoading ? t("views.auth.jira.button") : null}
      </Button>
      <FeatureAlert />
    </>
  );
};
