import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  flag: {
    border: `1px solid ${theme.props.border}`,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none",
    height: 132,
    width: "100%",
    padding: 16,
    border: `1px solid ${theme.props.border}`,
    borderRadius: 5,
  },
  spinner: {
    left: "50%",
    position: "relative",
    marginLeft: -32
  },
}));

export default useStyle;
