import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  autocomplete: {
    margin: 8,
    width: 208,
  },
}));

export default useStyle;
