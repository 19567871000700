export const jspreadsheetDefaultSettings = {
  tableOverflow: "hidden",
  tableHeight: "100%",
  tableWidth: "100%",
  defaultColAlign: "left",
  contextMenu: () => null,
  editable: false,
  allowExport: false,
  columnDrag: false,
  rowDrag: false,
  columnResize: true,
  rowResize: true,
  columnSorting: false,
  allowDeleteRow: false,
  allowDeletingAllRows: false,
  allowDeleteColumn: false,
  allowRenameColumn: false,
  allowInsertRow: false,
  allowManualInsertRow: false,
  allowInsertColumn: false,
  allowManualInsertColumn: false,
};
