import { APIMethod, fetchData } from "@client";

export async function getCurrentSubscriptionQuery() {
  try {
    const res = await fetchData("/company/subscription", APIMethod.GET);

    if (res && !res.length && res.code) {
      const {
        code,
        name,
        duration,
        estimates,
        users,
        price,
        discount,
        trialTo,
        isTrialing,
        paymentMethod,
        currentPeriodStart,
        currentPeriodEnd,
      } = res;

      let discountPrice;
      if(discount?.valid) {
        if(discount.amountOff)
          discountPrice = price - discount.amountOff/100;
        if(discount.percentOff)
          discountPrice = price - (price * discount.percentOff / 100);
      }
      
      return {
        code,
        name: name?.match(/^\d+ Estimates - (.*) [YM]$/)?.[1] || name,
        price,
        discountPrice,
        duration,
        estimates,
        users,
        isTrialing,
        paymentMethod,
        trialTo: trialTo ? trialTo * 1000 : undefined,
        from: currentPeriodStart * 1000,
        to: currentPeriodEnd * 1000,
      };
    }
    return null;
  } catch (e) {
    return null;
  }
}
