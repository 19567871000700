import { fetchData } from "@client";

export async function getProjectDetailsQuery(uuid) {
  const { results } = await fetchData(`/project/${uuid}`);
  return results;
  // if(results) {
  //   const selected = this.projects.find(project => project.uuid === uuid);
  //   if(selected)
  //     selected.attachments = results.attachments;
  //   return results;
  // }
}
