import { string, oneOf } from "prop-types";
import useStyle from "./StatusBullet.style";
import classnames from "classnames";

export const StatusBullet = ({ className, size, color, ...rest }) => {
  const classes = useStyle();

  return (
    <span
      {...rest}
      className={classnames(
        classes.root,
        classes[size],
        classes[color],
        className
      )}
    />
  );
};

StatusBullet.propTypes = {
  className: string,
  color: oneOf(["text", "primary", "info", "success", "warning", "danger"]),
  size: oneOf(["xs", "sm", "md", "lg"]),
};

StatusBullet.defaultProps = {
  size: "md",
  color: "text",
};
