import { func, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ControlledTextField, TextField } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const userInfoFormSchema = (t, defaultValues) =>
  yup.object().shape({
    fullname: yup
      .string()
      .required(t("errors.name.required"))
      .max(64, t("errors.name.max"))
      .default(defaultValues?.fullname),
    // email: yup.string()
    //   .email(t("errors.email.required"))
    //   .required(t("errors.email.required"))
    //   .default(defaultValues?.email),
  });

export const UserInfoForm = ({ onSubmit, defaultValues }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    reset: resetForm,
    formState: { isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(userInfoFormSchema(t, defaultValues)),
    defaultValues: defaultValues || {
      fullname: "",
      email: "",
    },
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors, reset } = result;

      if (reset) {
        resetForm(defaultValues);
        return;
      }

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="userInfo form"
      onSubmit={handleSubmit(submitHandler)}
      className="w-full"
    >
      <Grid item container spacing={1}>
        <Grid item container xs={12}>
          <ControlledTextField
            name="fullname"
            control={control}
            label={t("forms.name")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12}>
          <TextField
            value={defaultValues?.email}
            disabled
            label={t("forms.email")}
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider className="mt-2 -mx-6 mb-6" />

      <Grid item container justifyContent="flex-end">
        <Button
          isSubmit
          name="submit"
          variant="contained"
          disabled={!isDirty}
          icon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {!isSubmitting && t("common.save")}
        </Button>
      </Grid>
    </form>
  );
};

UserInfoForm.propTypes = {
  onSubmit: func.isRequired,
  defaultValues: object,
};
