import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useSharedProjectKey = () => {
  const { company, project, projectHash } = useParams();
  return useMemo(
    () => (company && project ? `${company}/${project}/${projectHash}` : projectHash),
    [projectHash, company, project]
  );
};
