import { makeStyles, darken } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  shareButton: {
    backgroundColor: theme.props.success,
    color: theme.props.text.alt,
    "&:hover": {
      backgroundColor: darken(theme.props.active, 0.3),
    },
  },
  uniqueLinkInput: {
    height: 0,
    padding: 0,
    border: "none",
    position: "absolute",
  },
}));

export default useStyle;
