import { bool, string } from "prop-types";
import { DragHandle as DragHandleIcon } from "@material-ui/icons";
import classnames from "classnames";
import useStyle from "./DragHandle.style";

export const DragHandle = ({ absolute, backgroundPrimary, className }) => {
  const classes = useStyle();

  return (
    <DragHandleIcon
      className={classnames(
        "dragHandle",
        classes.handle,
        absolute && classes.absolute,
        backgroundPrimary && classes.backgroundPrimary,
        className
      )}
    />
  );
};

DragHandle.propTypes = {
  className: string,
  backgroundPrimary: bool,
  absolute: bool,
};
