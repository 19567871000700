import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    border: "1px solid",
    backgroundColor: theme.props.backgroundSecondary,
    borderColor: theme.props.border,
    width: "100%",
    height: "max-content",
    boxShadow: "none",
    marginBottom: 16,
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover a": {
      textDecoration: "underline",
    },
  },
  actionButtonContainer: {
    marginRight: -8,
    width: "max-content"
  },
  actionButton: {
    height: 32,
    border: [[1, "solid", theme.props.border], "!important"],
  },
  deadline: {
    marginTop: 6,
    marginBottom: 12,
  },
}));

export default useStyle;
