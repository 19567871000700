import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import {
  CircularProgress, Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Table,
  TableCell,
  TableRow,
  PageTitle,
  ActionButton, TablePagination,
} from "@components";
import { PlayArrow } from "@material-ui/icons";
import { format } from "date-fns";
import useStyle from "./ProjectSessions.style";
import {
  sessionReplay,
  editor
} from "@paths";
import ReactCountryFlag from "react-country-flag";
import { parseSessionTime, universalDateParser } from "@utils";
import { getSessionsQuery } from "@query";
import { isCancel } from "axios";

let queryController;

export const ProjectSessions = observer(() => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { projectUuid } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [firstView, setFirstView] = useState(null);
  const [lastView, setLastView] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const loadSessions = useCallback(async () => {
    if(isLoading) queryController?.abort();
    if (!isLoading) {
      try {
        setIsLoading(true);
        queryController = new AbortController();
        const { results, total: newTotal, views } = await getSessionsQuery(
          queryController,
          projectUuid,
          page+1,
          rowsPerPage
        );
        setSessions(results);
        setTotal(newTotal);
        if (views) {
          setFirstView(views.first);
          setLastView(views.last);
        }
        setIsLoading(false);
      } catch (e) {
        if (!isCancel(e))
          throw e;
      }
    }
  }, [isLoading, page, total, rowsPerPage]);

  useEffect(() => {
    loadSessions();
  }, [page, rowsPerPage]);

  const replaySession = (id) => () => {
    navigate(sessionReplay(projectUuid, id));
  };

  return (
    <>
      <PageTitle title={"sessions"} translate pinned>
        <ActionButton to={editor(projectUuid)}>
          {t("views.session.estimation")}
        </ActionButton>
      </PageTitle>
      <Container>
        {(!isLoading || sessions.length > 0) && (
          <Grid
            item container
            spacing={4}
            className="mb-0 -mx-4"
          >
            <Grid
              item container
              xs={12} sm={4}
            >
              <Paper className={classes.paper}>
                <p className="text-4xl mb-2 font-bold">
                  {lastView
                    ? format(universalDateParser(lastView), "dd.MM.yyyy ")
                    : "-"}
                </p>
                <p>{t("views.session.first")}</p>
              </Paper>
            </Grid>
            <Grid
              item container
              xs={12} sm={4}
            >
              <Paper className={classes.paper}>
                <p className="text-4xl mb-2 font-bold">
                  {firstView
                    ? format(universalDateParser(firstView), "dd.MM.yyyy ")
                    : "-"}
                </p>
                <p>{t("views.session.last")}</p>
              </Paper>
            </Grid>
            <Grid
              item container
              xs={12} sm={4}
            >
              <Paper className={classes.paper}>
                <p className="text-4xl mb-2 font-bold">{total}</p>
                <p>{t("views.session.total")}</p>
              </Paper>
            </Grid>
          </Grid>
        )}
        {
          isLoading
            ? <CircularProgress size={ 64 } className={classes.spinner} />
            : <Table
              id="recordingsTable"
              headerOffset={96}
              containerHeight
              headers={[
                { label: t("views.session.customer"), width: 220, flexible: true },
                { label: t("views.session.date"), width: 160 },
                {
                  label: t("views.session.duration"),
                  width: 60,
                  justify: "center",
                },
                { label: t("views.session.ip"), width: 110, justify: "center" },
                { label: t("views.session.location"), width: 240, flexible: true },
                { label: t("views.session.status"), width: 80 },
                { width: 70 },
              ]}
            >
              {sessions.map((m) => {
                const {
                  id,
                  customerEmail,
                  customerInfo,
                  createdAt,
                  status,
                  duration
                } = m;
                
                return (
                  <TableRow key={id}>
                    <TableCell width={220} flexible>
                      <strong>
                        {customerEmail || t("views.session.anonymous")}
                      </strong>
                    </TableCell>
                    <TableCell width={160}>
                      {format(
                        universalDateParser(createdAt),
                        "dd.MM.yyyy HH:mm:ss"
                      )}
                    </TableCell>
                    <TableCell width={60} justifyContent="center">
                      <strong>
                        {parseSessionTime(duration)}
                      </strong>
                    </TableCell>
                    <TableCell width={110} justifyContent="center">
                      {customerInfo?.ip || ""}
                    </TableCell>
                    <TableCell width={240} flexible wrap="nowrap">
                      {Boolean(customerInfo?.city || customerInfo?.region) && (
                        <p className="mr-2">
                          {customerInfo.city || ""}
                          {customerInfo.city && customerInfo.region ? ", " : ""}
                          {customerInfo.region || ""}
                        </p>
                      )}
                      {Boolean(customerInfo?.country) && (
                        <ReactCountryFlag
                          countryCode={customerInfo.country.toUpperCase()}
                          svg
                          className={classes.flag}
                          style={{
                            width: "1.5em",
                            height: "auto",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell width={80}>
                      {t(`views.session.${status ? "done" : "progress"}`)}
                    </TableCell>
                    <TableCell width={70} justifyContent="flex-end">
                      <Tooltip title={t("views.session.play")}>
                        <IconButton color="primary" onClick={replaySession(id)}>
                          <PlayArrow />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
        }
        <TablePagination
          page={page}
          total={total}
          rowsPerPage={rowsPerPage}
          onPageChange={setPage}
          onRowsPerPageChange={setRowsPerPage}
          useSmallerPageSize
        />
      </Container>
    </>
  );
});