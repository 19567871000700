import { APIMethod, fetchData } from "@client";

export async function setProjectPresetQuery(projectUuid, presetId) {
  await fetchData("/preset/project", APIMethod.PUT, {
    data: {
      id: presetId,
      uuid: projectUuid,
    },
  });
}
