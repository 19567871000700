import { useState } from "react";
import { bool, func, object, oneOf, string } from "prop-types";
import { Button, Dialog } from "@components";
import { useTranslation } from "react-i18next";
import { ColorPickerInner } from "@components/ColorPicker/components/ColorPickerInner";

export const ColorPickerDialog = ({
  show,
  onClose,
  title,
  type,
  initialColor,
  onChange,
}) => {
  
  const { t } = useTranslation();
  const [color, setColor] = useState(initialColor);
  
  const handleSave = () => {
    onChange(color);
    onClose();
  }
  
  return (
    <Dialog
      open={show}
      onClose={onClose}
      containerClass="p-0"
      width={376}
      title={
        type
          ? `${title} - ${t(`color_picker_types.${type}`)}`
          : title
      }
      actions={
        <>
          <Button
            variant="outlined"
            onClick={onClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
          >
            {t("common.save_changes")}
          </Button>
        </>
      }
    >
      <ColorPickerInner
        onChange={setColor}
        initialColor={initialColor}
      />
    </Dialog>
  );
};

ColorPickerDialog.propTypes = {
  show: bool,
  anchor: object,
  onClose: func.isRequired,
  initialColor: string,
  title: string.isRequired,
  onChange: func,
  type: oneOf(["font","background","border"])
};
