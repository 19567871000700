import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.primary.main + "30",
    borderColor: theme.palette.primary.main,
  },
  hidden: {
    // opacity: .5,
    color: theme.props.border,
    "&$selected": {
      backgroundColor: theme.props.border + "30",
      borderColor: theme.palette.text.primary + "80",
      color: theme.palette.text.primary + "80",
    }
  },
  nameInput: {
    color: "inherit",
  },
  badgeContainer: {
    position: "absolute",
    bottom: -10,
    right: 2,
    width: "max-content",
    zIndex: 10,
  },
  offBadge: {
    width: 16,
    height: 16,
    // borderRadius: 5,
    margin: 2,
    color: theme.palette.primary.main,
    // backgroundColor: theme.props.backgroundSecondary,
    // border: "1px solid "+theme.palette.primary.main,
  },
}));

export default useStyle;
