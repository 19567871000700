import { useRef } from "react";
import { scrollReachedBottom } from "@utils";
import { useStores } from "./stores";

export const useContainerScrollHandler = () => {
  const { stateStore } = useStores();
  
  const reachedBottom = useRef(false);
  const lastPosition = useRef(0);
  const timer = useRef(null);
  
  return (e) => {
    if(stateStore.scrollHandler)
      stateStore.scrollHandler(e);
    
    const currentPosition = e.currentTarget.scrollTop;
    const onBottom = scrollReachedBottom(e.currentTarget);
    
    if(stateStore.scrollRefPadding && lastPosition.current > currentPosition) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => stateStore.updateScrollRefPadding(), 50);
    }
    
    if(stateStore.scrollEndHandler && onBottom && !reachedBottom.current)
      stateStore.scrollEndHandler();
    reachedBottom.current = onBottom;
    lastPosition.current = currentPosition;
  }
}