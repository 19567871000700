import { observable, action, computed, makeObservable } from "mobx";
import {
  COMPANY_SETTINGS,
  db,
  runLogrocket, runSmartlook,
} from "@client";
import { loadStripe } from "@stripe/stripe-js";
import { differenceInCalendarDays } from "date-fns";
import { ROLE_ADMIN, TEAM_ROLE_BUSINESS, TEAM_ROLE_MODERATOR } from "@utils";
import { activate, createWorkspace, home } from "@paths";
import axios from "axios";
import { getCompanySettingsQuery } from "@query";

export class UserStore {
  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  refreshTimeout;

  @observable data = {};
  @observable subscriptionInfo = {};
  @observable workspace = {};
  @observable availableWorkspaces = [];
  @observable loginProcessEnded = false;
  
  // =============== COMPUTED-S ===============

  @computed get workspaceUuid() {
    return this.workspace.uuid;
  }

  @computed get isLoggedIn() {
    return this.loginProcessEnded && Boolean(this.data?.email);
  }

  @computed get userId() {
    return this.data?.id;
  }
  @computed get userUuid() {
    return this.data?.uuid;
  }

  @computed get isAdmin() {
    return this.data?.role === ROLE_ADMIN || this.data?.team?.admin;
  }

  @computed get isModerator() {
    if (this.isAdmin) return true;
    return this.data?.team?.teamRoleId === TEAM_ROLE_MODERATOR || false;
  }

  @computed get isDeveloper() {
    if (this.isModerator) return false;
    if (this.data?.team)
      return this.data.team.teamRoleId !== TEAM_ROLE_BUSINESS;
    return true;
  }

  @computed get isBusinessUser() {
    return this.isAdmin || !this.isDeveloper;
  }

  @computed get hasTrialLeft() {
    return this.trialLeftDays > 0 && this.subscriptionInfo?.trialStatus === "try";
  }

  @computed get isMiniUser() {
    return this.isLoggedIn && this.subscriptionInfo?.premium?.toLowerCase().includes("mini");
  }

  @computed get nonPayingUser() {
    return (
      this.subscriptionInfo &&
      this.subscriptionInfo.trialStatus &&
      this.subscriptionInfo.trialStatus !== "try" &&
      (["package_trialEnded", "package_trial", "package_free"].includes(
        this.subscriptionInfo.premium
      ) ||
        !this.subscriptionInfo.premium)
    );
  }

  @computed get trialLeftDays() {
    if (!this.subscriptionInfo?.trialEnd) return 0;
    return differenceInCalendarDays(this.subscriptionInfo?.trialEnd, new Date());
  }
  
  // =============== SETTERS ===============
  
  @action setAvailableWorkspaces(availableWorkspaces) {
    this.availableWorkspaces = availableWorkspaces || [];
    if(availableWorkspaces?.length && availableWorkspaces[0])
      this.workspace = availableWorkspaces[0];
  }
  
  @action setLoginProcessStatus(status) {
    this.loginProcessEnded = status;
  }
  
  @action
  updateSubscriptionData(
    premium,
    premiumName,
    premiumUsers,
    premiumEstimate,
    trialEnd,
    trialStatus
  ) {
    this.subscriptionInfo = {
      premium,
      premiumName,
      premiumUsers,
      premiumEstimate,
      trialEnd,
      trialStatus
    }
  }
  
  @action
  async clearUserData() {
    clearTimeout(this.refreshTimeout);
    this.data = {};
    this.workspace = {};
    this.setLoginProcessStatus(false);
    delete axios.defaults.headers.common["Authorization"];
    db.user.clear();
  }
  
  @action updateUserData(data) {
    if(!this.data) this.data = {};
    this.data = {...this.data, ...data }
  }
  
  @action saveUserData(data) {
    const {
      workspaces,
      premium,
      premiumName,
      premiumUsers,
      premiumEstimate,
      trialEnd,
      trialStatus,
      ...userData
    } = data;
    
    this.updateUserData(userData);
    this.updateSubscriptionData(
      premium,
      premiumName,
      premiumUsers,
      premiumEstimate,
      trialEnd*1000,
      trialStatus
    );
    this.setAvailableWorkspaces(workspaces);
  }
  
  @action async checkUserTrial() {
    const shownTrialAlert = await getCompanySettingsQuery(
      COMPANY_SETTINGS.TRIAL_ALERT
    );
    this.rootStore.stateStore.setTrialAlertStatus(
      (this.nonPayingUser && shownTrialAlert !== "end") ||
      (this.trialLeftDays === 7 && shownTrialAlert !== "7") ||
      (this.trialLeftDays === 3 && shownTrialAlert !== "3")
    );
  }
  
  @action checkWorkspacesAndRedirect() {
    if(this.data.status === 0) {
      this.rootStore.stateStore.setActivationEmail(this.data.email);
      this.rootStore.stateStore.setMemorizedAction(activate);
      return;
    }
    
    this.rootStore.stateStore.setMemorizedAction(
      !this.availableWorkspaces?.length || !this.workspaceUuid
        ? createWorkspace
        : this.rootStore.stateStore.memorizedAction || home
    );
  }
  
  // =============== ACTIONS ===============

  @action userSessionStart() {
    if (window.gtag) window.gtag("set", { user_id: this.data.uuid });
    runLogrocket(this.data);
    runSmartlook(this.data);
  }
}
