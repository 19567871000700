import { useEffect, useState } from "react";
import { bool, object, func } from "prop-types";
import { usePresetFieldNames, usePresetStore, useS3Uploader, useStores } from "@hooks";
import { observer } from "mobx-react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ColorPicker, PopMenu, Button, TextField } from "@components";
import Grid from "@material-ui/core/Grid";
import useStyle from "./ToolPopup.style";
import { deleteFromS3 } from "@client";

export const ToolPopup = observer(({
  show,
  onClose,
  anchor,
  isDefaultPreset
}) => {
  const presetStore = usePresetStore();
  const { stateStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyle();
  
  const { uploadPresetLogo } = useS3Uploader();
  const { getFieldName } = usePresetFieldNames(true);

  const [fontPickerAnchor, setFontPickerAnchor] = useState(null);
  const [backgroundPickerAnchor, setBackgroundPickerAnchor] = useState(null);
  const [borderPickerAnchor, setBorderPickerAnchor] = useState(null);
  const [showFontPicker, setShowFontPicker] = useState(false);
  const [showBackgroundPicker, setShowBackgroundPicker] = useState(false);
  const [showBorderPicker, setShowBorderPicker] = useState(false);
  const [isFileLoading, setFileLoading] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [focused, setFocus] = useState(false);

  const { selectedPresetData } = presetStore;

  const elClass = anchor
    ? anchor.isVirtual
      ? anchor.elClass
      : anchor.className.match(/preset-\w+(-\w+)?/)?.[0] || ""
    : "";
  const textClass = anchor
    ? anchor.isVirtual
      ? anchor.textClass
      : anchor.className.match(/pt-\w+/)?.[0] || ""
    : "";
  const tableDepth =
    anchor && !anchor.isVirtual
      ? parseInt(anchor.className.match(/(d)(\d)/)?.[2] || 1)
      : 1;

  useEffect(() => {
    if (textClass) {
      setFieldName(getFieldName(textClass));
    }
  }, [textClass]);

  const fileGetter = async (event) => {
    const files = [];
    if (
      !isFileLoading &&
      (event.target.files || event.dataTransfer.files.length)
    ) {
      const fileList = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);
        files.push(file);
      }

      setFileLoading(true);
      stateStore.resetFilesUploadProgress(1);
      const { Location } = await uploadPresetLogo(presetStore.selectedPresetName, files[0]);
      presetStore.setLogo(`${Location}?t=${new Date().getTime()}`);
      await presetStore.updatePreset();
      stateStore.resetFilesUploadProgress();
      setFileLoading(false);
    }
    return [];
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    getFilesFromEvent: fileGetter,
    accept: [".png", ".jpg", ".jpeg"],
  });

  let editFontColor, editBackground, editBorder, editLogo;
  switch (elClass) {
    case "preset-bar":
      editBackground = "headerColor";
      break;
    case "preset-section":
    case "preset-sectionRow":
      editBackground = "sectionColor";
      editBorder = "sectionDivider";
      editFontColor = true;
      break;
    case "preset-active-1":
      editBackground = "activeColor1";
      editBorder = "rowBorderColor";
      editFontColor = "rowActive1";
      break;
    case "preset-active-2":
      editBackground = "activeColor2";
      editFontColor = "rowActive2";
      break;
    case "preset-row":
      editFontColor = true;
      editBackground = "rowColor";
      if (tableDepth === 1) editBorder = "rowBorderColor";
      break;
    case "preset-rowDesc":
      editBackground = "rowDescColor";
      editFontColor = true;
      break;
    case "preset-rowSummary":
      editBackground = "rowSummaryColor";
      if (tableDepth === 1) editBorder = "rowSummaryBorderColor";
      break;
    case "preset-rowTimeline":
      editBackground = "rowTimelineColor";
      if (tableDepth === 1) editBorder = "rowTimelineBorderColor";
      break;
    case "preset-activeSummary":
      editBackground = "rowActiveSummaryColor";
      editBorder = "rowSummaryBorderColor";
      break;
    case "preset-activeTimeline":
      editBackground = "rowActiveTimelineColor";
      editBorder = "rowTimelineBorderColor";
      break;
    case "preset-logo":
      editLogo = true;
      break;
    case "preset-paper":
      editBackground = "paperColor";
      editBorder = "paperDivider";
      break;
    case "preset-paperTimeline":
      editBackground = "paperTimelineColor";
      editBorder = "paperDivider";
      break;
    case "preset-version-active":
      editBackground = "versionActiveBackground";
      editFontColor = true;
      break;
    case "preset-version":
      editBackground = "versionBackground";
      editFontColor = true;
      break;
    case "preset-timelineMode":
      editBackground = "timelineModeBackground";
      editFontColor = true;
      break;
    case "preset-timelineModeActive":
      editBackground = "timelineModeBackgroundActive";
      editFontColor = true;
      break;
    case "preset-export":
      editBackground = "exportButtonColor";
      editFontColor = true;
      break;
    case "preset-page":
      editBackground = "backgroundColor";
      break;
    case "preset-price":
      editBackground = "priceColor";
      editFontColor = true;
      break;
    case "preset-tableBreakdownExpandButton":
      editBackground = "tableBreakdownExpandButton";
      editFontColor = true;
      break;
    case "preset-tableSummaryExpandButton":
      editBackground = "tableSummaryExpandButton";
      editFontColor = true;
      break;
    case "preset-tableTimelineExpandButton":
      editBackground = "tableTimelineExpandButton";
      editFontColor = true;
      break;
    default:
      editFontColor = true;
  }

  const elFontClass = typeof editFontColor === "string"
    ? editFontColor
    : (elClass?.length ? elClass.split("-")[1] : "");

  const handleClose = () => {
    if(focused) return;
    setShowBackgroundPicker(false);
    setShowFontPicker(false);
    setShowBorderPicker(false);
    onClose();
  };

  const onBackgroundChange = (s) => {
    presetStore.changePresetData(editBackground, s);
  };
  
  const handleBackgroundColorPickerClickAway = () => {
    if(focused) return;
    setShowBackgroundPicker(false);
  };

  const onBorderChange = (s) => {
    presetStore.changePresetData(editBorder, s);
  };
  
  const handleBorderColorPickerClickAway = () => {
    if(focused) return;
    setShowBorderPicker(false);
  };

  const onFontChange = (s) => {
    presetStore.changePresetFontColor(elFontClass, s);
  };
  
  const handleFontColorPickerClickAway = () => {
    if(focused) return;
    setShowFontPicker(false);
  };
  
  const handleLogoRemove = async () => {
    if(!selectedPresetData.logo) return;
    
    await deleteFromS3(selectedPresetData.logo)
    presetStore.removeLogo();
    await presetStore.updatePreset();
  }

  const onTextChange = (e) => {
    setFieldName(e.target.value);
    if (textClass) presetStore.changePresetText(textClass, e.target.value);
  };

  const handleOpen = () => {
    if (isDefaultPreset) presetStore.setPresetChangedFlag(true);
    else open();
  };
  
  const handleFocus = (e) => {
    e.stopPropagation();
    setFocus(true);
  }
  
  const handleBlur = (e) => {
    e.stopPropagation();
    setImmediate(() => {
      setFocus(false);
    })
  }

  return (
    <PopMenu
      show={show}
      onClickAway={handleClose}
      anchor={anchor}
      placement="bottom-start"
    >
      <Grid item container wrap="nowrap" className={classes.container}>
        {editFontColor && (
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              ref={setFontPickerAnchor}
              onClick={() => setShowFontPicker(!showFontPicker)}
              icon={
                <div
                  className={classes.block}
                  style={{
                    backgroundColor:
                      selectedPresetData.fontColor[elFontClass],
                  }}
                />
              }
            >
              {selectedPresetData.fontColor[elFontClass]}
            </Button>
            <ColorPicker
              show={showFontPicker}
              anchor={fontPickerAnchor}
              onClose={handleFontColorPickerClickAway}
              initialColor={selectedPresetData?.fontColor[elFontClass]}
              title={t("views.editor.preset.tags." + elClass)}
              type="font"
              onChange={onFontChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Grid>
        )}
        {editBackground && (
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              ref={setBackgroundPickerAnchor}
              onClick={() => setShowBackgroundPicker(!showBackgroundPicker)}
              icon={
                <div
                  className={classes.block}
                  style={{
                    backgroundColor: selectedPresetData[editBackground],
                  }}
                />
              }
            >
              {selectedPresetData[editBackground]}
            </Button>
            <ColorPicker
              show={showBackgroundPicker}
              anchor={backgroundPickerAnchor}
              onClose={handleBackgroundColorPickerClickAway}
              initialColor={selectedPresetData?.[editBackground]}
              title={t("views.editor.preset.tags." + elClass)}
              type="background"
              onChange={onBackgroundChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Grid>
        )}
        {editBorder && (
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              ref={setBorderPickerAnchor}
              onClick={() => setShowBorderPicker(!showBorderPicker)}
              icon={
                <div
                  className={classes.block}
                  style={{ backgroundColor: selectedPresetData[editBorder] }}
                />
              }
            >
              {selectedPresetData[editBorder]}
            </Button>
            <ColorPicker
              show={showBorderPicker}
              anchor={borderPickerAnchor}
              onClose={handleBorderColorPickerClickAway}
              initialColor={selectedPresetData?.[editBorder]}
              title={t("views.editor.preset.tags." + elClass)}
              type="border"
              onChange={onBorderChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </Grid>
        )}
        {editLogo && (
          <Grid item {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="mr-4"
              onClick={handleLogoRemove}
              disabled={!selectedPresetData?.logo}
            >
              {t("views.editor.preset.edit.delete")}
            </Button>
            <Button variant="contained" size="large" onClick={handleOpen}>
              {t("views.editor.preset.edit.new_logo")}
            </Button>
          </Grid>
        )}
        {!!textClass && (
          <Grid item>
            <TextField
              value={fieldName}
              onChange={onTextChange}
              className="mb-0"
            />
          </Grid>
        )}
      </Grid>
    </PopMenu>
  );
});

ToolPopup.propTypes = {
  show: bool,
  anchor: object,
  onClose: func.isRequired,
  isDefaultPreset: bool,
};
