import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { generateSectionId } from "project-structure";
import { Button, SectionTitle } from "@components";
import { Add } from "@material-ui/icons";
import useStyle from "./NewSectionButton.style";

export const NewSectionButton = () => {
  const structure = useStructureStore();
  const { t } = useTranslation();
  const classes = useStyle();
  
  const handleNewSection = () => {
    structure.addSection(generateSectionId());
  }

  return (
    <SectionTitle spacing>
      <Button
        variant="outlined"
        color="primary"
        className={classes.sectionButton}
        onClick={handleNewSection}
        icon={<Add />}
      >
        {t("views.editor.add_section")}
      </Button>
    </SectionTitle>
  );
};