import { bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Checkbox, DragHandle } from "@components";
import useStyle from "./TableCheckbox.style";

export const TableCheckbox = ({ name, visible, setVisibility }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const handleVisibility = () => setVisibility(!visible);

  return (
    <Grid key={name} item container xs={12} className={classes.item}>
      <DragHandle />
      <Checkbox
        name={name}
        aria-label={name}
        checked={visible}
        onChange={handleVisibility}
        className={classes.checkbox}
        label={t(`proposal_parts.editor_sections.${name}`)}
      />
    </Grid>
  );
};

TableCheckbox.propTypes = {
  name: string,
  visible: bool,
  setVisibility: func,
};
