import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeatureGuardian } from "@hooks";
import { Switch, Tooltip } from "@material-ui/core";
import { getCompanySettingsQuery, setCompanySettingsQuery } from "@query";
import { InfoLabel } from "@components";
import { LOCKED_FEATURES } from "@utils";
import { NewDomain } from "./NewDomain";
import { ActiveDomain } from "./ActiveDomain";
import { DomainInfoDialog } from "../DomainInfoDialog";
import { COMPANY_SETTINGS} from "@client";

export const Domain = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [oldDomain, setOldDomain] = useState("");
  const [useDomain, setUseDomain] = useState(false);
  const [domain, setDomain] = useState("");
  const [domainSaved, setDomainSaved] = useState(false);
  const [domainFocused, setDomainFocus] = useState(false);
  const [cnameName, setCnameName] = useState("");
  const [cnameValue, setCnameValue] = useState("");
  const [domainDialogVisible, openDomainDialog] = useState(false);

  const { checkAccess, actionBlocked, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.DOMAIN
  );

  useEffect(() => {
    (async () => {
      await checkAccess(true);
      let d = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN);
      let u = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_USE);
      let cN = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_CNAME);
      let cV = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_VALUE);
      setDomain(d || "");
      setOldDomain(d || "");
      setUseDomain(u === 1);
      if (d) setDomainSaved(true);
      setCnameName(cN || "");
      setCnameValue(cV || "");
      setLoading(false);
    })();
  }, []);

  const handleDomainChange = (v) => setDomain(v);
  const handleDomainFocus = () => setDomainFocus(!domainFocused);

  const handleUseDomain = async () => {
    await setCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_USE, useDomain ? 0 : 1);
    setUseDomain(!useDomain);
  };

  const saveDomain = async () => {
    setDomainFocus(false);
    if (domain !== oldDomain) {
      if (isLoading) return;
      setLoading(true);
      const allowed = await checkAccess();
      if (!allowed) return;

      await setCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN, domain);

      setOldDomain(domain);
      setDomainSaved(!!domain);
      domain && openDomainDialog(true);
      setLoading(false);
    }
  };

  return (
    <>
      <InfoLabel
        label="views.settings.projects.domain_company"
        tooltipText="views.settings.projects.domain_company_info"
        titleAction={domainSaved && !actionBlocked && (
          <Tooltip
            title={t(
              `views.settings.projects.${useDomain ? "no_" : ""}use_domain`
            )}
          >
            <Switch
              size="small"
              color="primary"
              checked={useDomain}
              onChange={handleUseDomain}
              disabled={!cnameName || !cnameValue}
              className="ml-2 mr-5"
            />
          </Tooltip>
        )}
      />
      {
        domainSaved
          ? <ActiveDomain
            domain={domain}
            cnameName={cnameName}
            cnameValue={cnameValue}
            domainFocused={domainFocused}
            isLoading={isLoading}
            useDomain={useDomain}
            actionBlocked={actionBlocked}
            saveDomain={saveDomain}
            handleDomainChange={handleDomainChange}
            handleDomainFocus={handleDomainFocus}
          />
          : <NewDomain
            domain={domain}
            domainFocused={domainFocused}
            isLoading={isLoading}
            saveDomain={saveDomain}
            handleDomainChange={handleDomainChange}
            handleDomainFocus={handleDomainFocus}
          />
      }
      {domainDialogVisible && (
        <DomainInfoDialog
          domainName={domain}
          onClose={() => openDomainDialog(false)}
        />
      )}
      <FeatureAlert />
    </>
  );
};
