import { fetchData } from "@client";
import { APIMethod } from "../../client/api/fetchData";
import { ACCESS_DOMAINS } from "../../client/accessDomains";

export async function storeProjectBudgetTrackingQuery(uuid, version, key) {
  return await fetchData(`api/export/project`, APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.btApi,
    data: {
      projectJiraId: key,
      projectId: uuid,
      version: version,
    },
  });
}
