import { Fragment } from "react";
import { oneOfType, bool, string, func, object, node } from "prop-types";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@material-ui/core";
import { Check, Remove } from "@material-ui/icons";
import classnames from "classnames";

/**
 * Custom checkbox button
 */
export const Checkbox = ({
  checked,
  onChange,
  name,
  error,
  style,
  label,
  labelStyle,
  labelClassName,
  className,
  colorSecondary,
  disabled = false,
  indeterminate = false,
  ...props
}) => {
  const checkbox = (
    <MuiCheckbox
      color="primary"
      icon={<Fragment />}
      checked={checked}
      indeterminate={indeterminate}
      name={name}
      className={classnames(!!error && "border-error", className)}
      onChange={onChange}
      inputProps={{ "aria-label": props["aria-label"] }}
      checkedIcon={<Check />}
      indeterminateIcon={<Remove />}
      style={style}
      disabled={disabled}
    />
  );

  return label ? (
    <FormControlLabel
      control={checkbox}
      style={labelStyle}
      className={classnames("m-0", labelClassName)}
      label={
        <p
          className={classnames(
            "ml-2 text-md",
            !!error && "color-error",
            colorSecondary && "text-secondary"
          )}
        >
          {label}
        </p>
      }
    />
  ) : (
    checkbox
  );
};

Checkbox.propTypes = {
  checked: bool.isRequired,
  name: string.isRequired,
  error: bool,
  onChange: func,
  indeterminate: bool,
  "aria-label": string,
  style: object,
  labelStyle: object,
  disabled: bool,
  colorSecondary: bool,
  className: string,
  labelClassName: string,
  label: oneOfType([string, node]),
};

Checkbox.defaultProps = {
  error: false,
  indeterminate: false,
  "aria-label": "",
};
