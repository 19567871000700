import { useMemo, useState } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useEditorWebsocket, useProjectEditorStore } from "@hooks";
import { MAX_VERSIONS } from "@utils";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { Button, SortableList } from "@components";
import { Add } from "@material-ui/icons";
import { VersionButton } from "./components/VersionButton";
import classnames from "classnames";

export const Versioning = observer(({
  noEditing,
  useClientActions,
  hasEditorPrivileges,
  isProposal,
  version = null
}) => {
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();

  const [isDragged, setDragged] = useState(false);

  const {
    projectVersions,
    visibleProjectVersions,
    newVersionLoading,
    currentVersionKey,
    sortedVersions,
    currentVersion,
    visibilityMode,
  } = editorStore;

  const handleVersionReorder = async (list, key) => {
    const keyList = list.map(({ id }) => id);
    setDragged(false);
    socket?.requestVersionReorder(key, keyList);
    editorStore.reorderVersions(keyList);
  };

  const handleNewVersionAdd = () => {
    if (!newVersionLoading) {
      editorStore.setNewVersionLoading();
      socket?.requestNewVersion(currentVersionKey);
    }
  };

  const allowAdding = !noEditing && projectVersions?.length < MAX_VERSIONS;
  const removable = !noEditing && projectVersions?.length > 1;
  const hideable = visibleProjectVersions > 1;

  const versions = useMemo(
    () =>
      noEditing && !visibilityMode
        ? sortedVersions.filter(
            ({ structure }) => structure?.visibility?.version
          )
        : sortedVersions,
    [noEditing, sortedVersions, visibilityMode]
  );

  return (
    <>
      {!noEditing || projectVersions?.length > 1 ? (
        <Grid item container alignItems="center" className="my-4">
          <SortableList
            list={sortedVersions}
            group="versions"
            onListReorderStart={() => setDragged(true)}
            onListReorder={handleVersionReorder}
            disabled={noEditing}
            itemIdKey="key"
          >
            {versions.map(
              ({ key, name, structure }, index) => (
                version == key  || version == null) && (
                  <VersionButton
                    key={key}
                    identifier={key}
                    name={name}
                    index={index}
                    selected={key === currentVersion}
                    removable={removable}
                    editable={!noEditing || visibilityMode}
                    hideable={hideable}
                    useClientActions={useClientActions}
                    isDragged={isDragged}
                    used={structure?.visibility?.version}
                    locked={structure?.locked && !isProposal}
                    hasEditorPrivileges={hasEditorPrivileges}
                    editVisibility={editorStore.visibilityMode}
                  />
                )
            )}
            <Tooltip title="Add new version" arrow placement="bottom">
              <Button
                icon={
                  newVersionLoading ? <CircularProgress size={24} /> : <Add />
                }
                onClick={handleNewVersionAdd}
                className={classnames(
                  "noDrag m-1 p-1 border-dashed",
                  !allowAdding && "hidden invisible"
                )}
                variant="outlined"
                size="large"
              />
            </Tooltip>
          </SortableList>
        </Grid>
      ) : undefined}
    </>
  );
});

Versioning.propTypes = {
  noEditing: bool,
  useClientActions: bool,
  hasEditorPrivileges: bool,
  isProposal: bool,
};
