import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 15,
    width: "100%",
    height: "max-content",
    borderBottom: `1px solid ${theme.props.border}`,
  },
  message: {
    fontSize: 12,
    letterSpacing: 0.4,
  },
  date: {
    fontSize: 10,
    letterSpacing: 0.33,
    marginTop: 4,
  },
}));

export default useStyle;
