import { APIMethod, fetchData } from "@client";

export async function assignUserToWorkTypeQuery(projectUuid, workTypeId, userId) {
  await fetchData("/estimate/project/user/assigne", APIMethod.POST, {
    data: {
      user: userId,
      project: projectUuid,
      worktype: workTypeId,
    },
  });
}
