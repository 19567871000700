import { useEffect, useState } from "react";
import { func, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  Button,
  ControlledTextField,
  Autocomplete,
  InfoLabel,
} from "@components";
import { Divider, Grid, CircularProgress } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getCategoriesQuery } from "@query";

const newTemplateFormSchema = (t, defaultValues) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("errors.project.name.template"))
      .default(defaultValues?.name),
    description: yup
      .string()
      .nullable(true)
      .default(defaultValues?.description),
    category: yup.number().default(defaultValues?.category),
  });

export const NewTemplateForm = observer(({ onSubmit, defaultValues }) => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(newTemplateFormSchema(t)),
    defaultValues: defaultValues || {
      description: "",
      name: "",
      category: undefined,
    },
  });

  useEffect(() => {
    (async () => {
      const res = await getCategoriesQuery(true);
      setCategories(res);
    })();
  }, []);

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="newTemplate form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container spacing={1} direction="column" wrap="nowrap">
        <Grid item container xs={12}>
          <InfoLabel label="forms.template.name" />
          <ControlledTextField
            name="name"
            control={control}
            placeholder={t("forms.template.name_enter")}
          />
        </Grid>
        <Grid item container xs={12}>
          <InfoLabel label="forms.template.description" />
          <ControlledTextField
            name="description"
            control={control}
            placeholder={t("forms.template.description_enter")}
            multiline
            size="small"
          />
        </Grid>
        <Grid item container xs={12}>
          <InfoLabel label="forms.template.category" />
          <Controller
            name="category"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Autocomplete
                id="categorySelect"
                value={value}
                options={categories}
                inputPlaceholder={t("forms.template.category_enter")}
                fullWidth
                idKey="id"
                labelKey="name"
                name={name}
                onChange={onChange}
                groupBy={(option) => option.parent.toString()}
              />
            )}
          />
        </Grid>
      </Grid>

      <Divider className="mt-2 -mx-6 mb-6" />

      <Grid item container justifyContent="flex-end">
        <Button
          isSubmit
          name="submit"
          variant="contained"
          endIcon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {t("views.project.project_template.title")}
        </Button>
      </Grid>
    </form>
  );
});

NewTemplateForm.propTypes = {
  onSubmit: func.isRequired,
  defaultValues: object,
};
