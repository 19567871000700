import { bool, string, func, object, oneOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { PopMenu } from "@components";
import { DialogTitle, IconButton } from "@material-ui/core";
import { ColorPickerInner } from "@components/ColorPicker/components/ColorPickerInner";
import { Close } from "@material-ui/icons";

export const ColorPicker = ({
  show,
  onClose,
  anchor,
  title,
  type,
  ...innerProps
}) => {
  
  const { t } = useTranslation();
  
  return (
    <PopMenu
      show={show}
      anchor={anchor}
      onClickAway={onClose}
      style={{ width: 376 }}
      placement="right-start"
    >
      <DialogTitle disableTypography className="w-full p-4">
        <h2 className="text-xl font-bold">
          {
            type
              ? `${title} - ${t(`color_picker_types.${type}`)}`
              : title
          }
        </h2>
        <IconButton
          className="p-0"
          color="primary"
          aria-label="close modal"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <ColorPickerInner
        {...innerProps}
      />
    </PopMenu>
  );
};

ColorPicker.propTypes = {
  show: bool,
  anchor: object,
  onClose: func.isRequired,
  initialColor: string,
  title: string.isRequired,
  onChange: func,
  onFocus: func,
  onBlur: func,
  type: oneOf(["font","background","border"])
};
