import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useSection } from "./useSection";
import { isEmptyHtml } from "project-structure";
import { Grid, IconButton } from "@material-ui/core";
import { Trash } from "@assets";
import { Alert, SectionTitle, SectionName, WysiwygEditor } from "@components";
import useStyle from "./SectionRow.style";

export const SectionRow = observer(({
  index,
  section,
  allowEdition,
  isLibrary
}) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const { id, name, content, hasChildren } = section;

  const {
    removable,
    alertVisible,
    toggleAlert,
    handleSectionRemoval,
    handleNameChange,
    handleDescriptionChange,
  } = useSection(section, allowEdition);

  return (
    <>
      <SectionTitle
        id={"sectionTitle" + index}
        useDragHandle={allowEdition}
        actions={
          removable && (
            <IconButton
              onClick={
                hasChildren ? toggleAlert : handleSectionRemoval(false)
              }
              size="small"
              tabIndex={-1}
              className={`removeButton preset-page preset-noedit ${classes.removeButton}`}
            >
              <Trash color="error" />
            </IconButton>
          )
        }
      >
        <SectionName
          id={id}
          defaultName={
            isLibrary ? t(`proposal_parts.section_titles.${name}`) : name
          }
          editable={allowEdition}
          onChange={handleNameChange}
          colored
        />
      </SectionTitle>
      <Alert
        isOpen={alertVisible}
        title={t("views.editor.dialogs.remove_alert.title_level0")}
        onCancel={toggleAlert}
        onIntermediate={handleSectionRemoval(true)}
        onAccept={handleSectionRemoval(false)}
        acceptText={t("common.yes")}
        intermediateText={t("views.editor.dialogs.remove_alert.intermediate")}
        cancelText={t("common.no")}
      />
      {(allowEdition || !isEmptyHtml(content)) && (
        <Grid
          item
          container
          wrap="nowrap"
          alignItems="center"
          className={`preset-sectionDesc ${classes.descContainer}`}
          id={"sectionDesc" + index}
        >
          <WysiwygEditor
            readOnly={!allowEdition}
            noEmptyHtmlStrings
            changeOnClickAway
            placeholder={allowEdition && t("views.editor.desc_change")}
            onChange={handleDescriptionChange}
            name="description-section"
            value={content}
          />
        </Grid>
      )}
    </>
  );
});

SectionRow.propTypes = {
  index: number.isRequired,
  section: object.isRequired,
  allowEdition: bool,
  isLibrary: bool,
};
