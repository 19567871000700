import { useMemo } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { getUserVisibleWorkTypes, useProjectEditorStore, useStructureStore } from "@hooks";
import { TimelineSettings } from "@dialogs";
import { ButtonSwitch, VisibilityButton } from "@components";
import { Collapse, Grid } from "@material-ui/core";
import { TimelineRowGroupList, TimelineSectionTitleRow } from "../components";
import { BarChartSide, TableChart } from "@assets";

export const TimelineTeamAllocation = observer(({
  useClientActions,
  isSellerOrClient,
  isEditorOrClient,
  isProposal,
  allowEdition,
  blockExpansions,
}) => {
  
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  
  const { settings, visibility, timelineWorkTypes } = structure;
  const { displayTimelineAllocation, useTimelineChart } = settings;
  const { apply, hideTimelineAllocation } = visibility;
  
  const visibleWorkTypes = getUserVisibleWorkTypes(timelineWorkTypes, isProposal, true);
  
  const handleTimelineAllocationVisibility = () => {
    if(!useClientActions) return;
    settings.setDisplayTimelineAllocation(!displayTimelineAllocation);
  }
  
  const handleWorkTypeReorder = (list) => {
    structure.reorderWorkTypes(list);
  }
  const handleTimelineChartSelect = (v) => {
    if(!useClientActions) return;
    structure.settings.setUseTimelineChart(v);
  }
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideTimelineAllocation && "transparent-2") || undefined
  ), [visibilityMode, apply, hideTimelineAllocation]);
  
  return (
    <Grid item container className="vCon">
      <TimelineSectionTitleRow
        isProposal={isProposal}
        code="team"
        expandable
        expanded={displayTimelineAllocation}
        onExpand={handleTimelineAllocationVisibility}
        className={className}
        action={<>
          { allowEdition && <TimelineSettings fullSettings/> }
          {
            visibilityMode &&
              <VisibilityButton hidden={hideTimelineAllocation} onChange={visibility.setHideTimelineAllocation} />
          }
        </>}
      >
        {
          !visibilityMode &&
          <ButtonSwitch
            value={useTimelineChart}
            setValue={handleTimelineChartSelect}
            width={32}
            height={24}
            lighter
            values={[
              { value: true, label: <BarChartSide className="text-lg" /> },
              { value: false, label: <TableChart className="text-lg" /> },
            ]}
            size="small"
          />
        }
      </TimelineSectionTitleRow>
      <Collapse
        in={displayTimelineAllocation}
        timeout="auto"
        mountOnEnter
        unmountOnExit
        className={className}
      >
        <TimelineRowGroupList
          parentIds={["timeline"]}
          listElements={visibleWorkTypes}
          onReorder={handleWorkTypeReorder}
          useClientActions={useClientActions}
          isSellerOrClient={isSellerOrClient}
          isEditorOrClient={isEditorOrClient}
          isProposal={isProposal}
          isLastGroupRow
          allowEdition={allowEdition}
          blockExpansions={blockExpansions}
        />
      </Collapse>
    </Grid>
  );
});

TimelineTeamAllocation.propTypes = {
  useClientActions: bool,
  isSellerOrClient: bool,
  isEditorOrClient: bool,
  isProposal: bool,
  allowEdition: bool,
  blockExpansions: bool,
};