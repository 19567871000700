import { useMemo } from "react";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { ValueCell } from "@components";

export const HourCell = observer(() => {
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { apply, hideSummaryHours } = structure.visibility;
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryHours && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryHours]);
  
  return (
    <ValueCell
      widest
      className={className}
    />
  )
})