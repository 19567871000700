import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";
import axios from "axios";

export async function forceProjectSaveQuery(
  projectUuid,
) {
  const res = await fetchData("/api/session/save", APIMethod.GET, {
    noAuth: true,
    baseURL: ACCESS_DOMAINS.editor,
    mapRequestKeysToSnakeCase: false,
    params: {
      uuid: projectUuid,
      token: axios.defaults.headers.common["Authorization"],
    },
  });

  return res.results;
}
