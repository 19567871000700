import { bool } from "prop-types";
import { formatDistanceToNow } from "date-fns";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { dateLocales } from "@locale";
import { Button } from "@components";
import { Grid, Divider, Hidden } from "@material-ui/core";
import useStyle from "./TrialInfo.style";
import { UpgradeButton } from "./components/UpgradeButton";

export const TrialInfo = () => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const classes = useStyle();
  const { lang } = stateStore;
  const { hasTrialLeft, nonPayingUser } = userStore;

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      className="w-max"
    >
      {hasTrialLeft && (
        <span className="font-bold m-1">
          {t("views.subscription.details.trial_expires")}{" "}
          {formatDistanceToNow(userStore.subscriptionInfo.trialEnd, {
            locale: dateLocales[lang],
            addSuffix: true,
          })}
        </span>
      )}
      {hasTrialLeft && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className={classes.demoButton}
          href={process.env.REACT_APP_DEMO_BOOK_LINK}
          target="_blank"
        >
          {t("views.subscription.details.book_demo")}
        </Button>
      )}
      {nonPayingUser && (
        <Hidden smDown>
          <UpgradeButton color="secondary" className={classes.demoButton} />
        </Hidden>
      )}
      {(nonPayingUser || hasTrialLeft) && (
        <Divider orientation="vertical" className={classes.separator} />
      )}
    </Grid>
  );
};

TrialInfo.propTypes = {
  inMenu: bool,
};
