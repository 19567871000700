import { fetchData } from "@client";

export async function getTeamMembersQuery() {
  try {
    const res = await fetchData("/team");
    return res.results;
  } catch (e) {
    return [];
  }
}
