import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { observer } from "mobx-react";
import { InfoLabel, TextField } from "@components";
import { Autocomplete } from "@material-ui/lab";
import { getCurrencies, getCurrency } from "project-structure";
import { TimeEquiv } from "./components/TimeEquiv";
import { Rates } from "./components/Rates/Rates";
import { Domain } from "./components/Domain/Domain";
import { getCompanySettingsQuery, setCompanySettingsQuery } from "@query";
import { COMPANY_SETTINGS } from "@client";

export const Project = observer(() => {
  const { t } = useTranslation();

  const currencies = useMemo(getCurrencies, []);

  const [isLoading, setLoading] = useState(true);
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    (async () => {
      const c = await getCompanySettingsQuery(COMPANY_SETTINGS.CURRENCY_DEF);
      setCurrency(getCurrency(c || "USD"));
      setLoading(false);
    })();
  }, []);

  const handleCurrencyChange = (_, v) => {
    setCurrency(v);
    setCompanySettingsQuery(COMPANY_SETTINGS.CURRENCY_DEF, v.code);
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
    >
      <h2 className="mb-4">{t("views.settings.menu.project_full")}</h2>

      <InfoLabel
        label="views.settings.projects.currency"
        tooltipText="views.settings.projects.currency_info"
      />
      <Autocomplete
        id="currencySelect"
        options={currencies}
        value={currency}
        disabled={isLoading}
        onChange={handleCurrencyChange}
        getOptionLabel={(o) => `${o.name} (${o.symbolStart || o.symbolEnd})`}
        fullWidth
        autoHighlight
        forcePopupIcon={true}
        disableClearable
        renderInput={(params) => <TextField {...params} />}
      />
      <Domain />
      <TimeEquiv />
      <Rates currency={currency} />
    </Grid>
  );
});
