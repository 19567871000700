import { useEffect, useState } from "react";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { deleteAccountQuery } from "@query";
import { Grid, Paper, Divider, CircularProgress, Container } from "@material-ui/core";
import { Dialog, Button, TextField, Alert } from "@components";
import { LibraryIcon, User, Trash } from "@assets";
import { LockOutlined, Business } from "@material-ui/icons";
import {
  settings,
  settingsPassword,
  settingsCompany,
  login,
  settingsProject,
} from "@paths";
import useStyle from "./Settings.style";

export const Settings = observer(() => {
  const { userStore } = useStores();
  const classes = useStyle(isMobile);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openPopup, setOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [deletionAlert, showDeletionAlert] = useState(false);
  const [password, setPassword] = useState("");

  const location = useLocation();
  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.settings")}`;
  }, [])

  const selected = (path) =>
    location.pathname === path ? classes.menuButtonSelected : "";

  const deleteAccount = async () => {
    setDeleting(true);
    try {
      await deleteAccountQuery(password);
      await userStore.clearUserData();
      navigate(login);
    } catch (e) {
      showDeletionAlert(true);
    }
    setDeleting(false);
  };

  const { isAdmin } = userStore;

  return (
    <Container>
      <Grid
        item
        container
        spacing={3}
        justifyContent="center"
        className={classes.root}
      >
        <Grid item container sm={12} md={isMobile ? 12 : 4}>
          <Paper className={classes.paper} elevation={0}>
            <h2 className="mb-4">{t("routes.settings")}</h2>
            <Button
              variant="outlined"
              icon={<User />}
              to={settings}
              color="secondary"
              className={`${classes.menuButton} ${selected(settings)}`}
            >
              {t("views.settings.menu.profile")}
            </Button>
            {isAdmin && (
              <Button
                variant="outlined"
                icon={<Business />}
                to={settingsCompany}
                color="secondary"
                className={`${classes.menuButton} ${selected(settingsCompany)}`}
              >
                {t("views.settings.menu.company")}
              </Button>
            )}
            <Button
              variant="outlined"
              icon={<LockOutlined />}
              to={settingsPassword}
              color="secondary"
              className={`${classes.menuButton} ${selected(settingsPassword)}`}
            >
              {t("views.settings.menu.passwd")}
            </Button>
            
            {isAdmin && (
              <Button
                variant="outlined"
                icon={<LibraryIcon />}
                to={settingsProject}
                color="secondary"
                className={`${classes.menuButton} ${selected(settingsProject)}`}
              >
                {t("views.settings.menu.project")}
              </Button>
            )}
            <Divider className={classes.divider} />
            <Button
              variant="outlined"
              icon={<Trash color="error" />}
              onClick={() => setOpen(!openPopup)}
              color="secondary"
              className={classes.menuButton}
            >
              {t("views.settings.menu.delete")}
            </Button>
          </Paper>
        </Grid>
        <Grid item container sm={12} md={isMobile ? 12 : 8}>
          <Paper className={classes.paper} elevation={0}>
            <Outlet />
          </Paper>
        </Grid>
        
        <Dialog
          open={openPopup}
          onClose={() => setOpen(false)}
          title={t("views.settings.popup.title")}
          className={classes.dialog}
          actions={
            <>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => setOpen(false)}
              >
                {t("common.cancel")}
              </Button>
              <Button
                variant="contained"
                icon={
                  isDeleting ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                className={classes.button}
                onClick={deleteAccount}
                disabled={!password}
              >
                {t("views.settings.popup.delete")}
              </Button>
            </>
          }
        >
          <p>{t("views.settings.popup.text")}</p>
          <TextField
            id="deletePassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t("forms.password.current")}
            type="password"
            className="my-6"
          />
        </Dialog>
        <Alert
          isOpen={deletionAlert}
          title={t("errors.password.not_valid")}
          onAccept={() => showDeletionAlert(false)}
          acceptText={t("common.close")}
        />
      </Grid>
    </Container>
  );
});
