import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  getProfessionalLevelsQuery,
  getStackTypeQuery,
  getTeamMembersQuery,
  getTeamRoleQuery, refreshUserDataQuery,
} from "@query";
import { useStores } from "@hooks";
import { CircularProgress, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Table, TableCell, TableRow, Button, Tag } from "@components";
import { WorkspaceInvite } from "@dialogs";
import { useFeatureGuardian } from "@hooks";
import { LOCKED_FEATURES } from "@utils";
import { UserRow } from "./components";
import useStyle from "./TeamTable.style";

export const TeamTable = observer(({ useTableTitle }) => {
  const classes = useStyle();
  const { userStore } = useStores();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [inviteModalVisible, openInviteModal] = useState(false);
  const [professionalLevels, setLevels] = useState([]);
  const [teamRoles, setRoles] = useState([]);
  const [stackTypes, setStackTypes] = useState([]);

  const { premiumUsers } = userStore.subscriptionInfo;

  const totalAdmins = useMemo(
    () => users.reduce((p, c) => (c.admin ? p + 1 : p), 0),
    [users]
  );

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.USER
  );

  const loadData = async (email = null) => {
    if (!isLoading) setIsLoading(true);
    if (email && email === userStore.data.email) {
      const userData = await refreshUserDataQuery();
      userStore.saveUserData(userData);
    }
    
    if (!email) await loadInputData();
    const res = await getTeamMembersQuery();
    setUsers(res);
    setIsLoading(false);
  };

  const loadInputData = async () => {
    const r = await Promise.all([
      getProfessionalLevelsQuery(),
      getStackTypeQuery(),
      getTeamRoleQuery(),
    ]);

    setLevels(r[0]);
    setStackTypes(r[1]);
    setRoles(r[2]);
  };

  const handleInviteModalOpen = async () => {
    const allowed = await checkAccess();
    if (!allowed) return;
    openInviteModal(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      {useTableTitle && (
        <Grid
          item
          container
          justifyContent="space-between"
          wrap="nowrap"
          className={classes.tableTitle}
        >
          <Grid item container alignItems="center">
            <h3 className={classes.title}>{t("views.team.title")}</h3>
            {!isLoading && (
              <Tag
                text={`${users.length}/${
                  premiumUsers < 0 ? "∞" : premiumUsers
                }`}
              />
            )}
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="center">
            {!isLoading && premiumUsers >= 0 && (
              <p className={classes.secondary}>
                {t("views.team.seats", {
                  seats:
                    premiumUsers - users.length < 0
                      ? 0
                      : premiumUsers - users.length,
                })}
              </p>
            )}
            <Button
              variant="outlined"
              size="small"
              icon={<Add />}
              disabled={isLoading} // || (premium_users >= 0 && premium_users-users.length <= 0)}
              onClick={handleInviteModalOpen}
            >
              {t("views.team.add")}
            </Button>
          </Grid>
        </Grid>
      )}
      {isLoading ? (
        <CircularProgress
          aria-label="progress indicator"
          className={classes.spinner}
        />
      ) : (
        <Table
          id="teamMembers"
          headerOffset={useTableTitle ? 64 : 96}
          headers={[
            { label: t("views.team.name"), width: 300, flexible: true },
            { label: t("views.team.role"), width: 160 },
            { label: t("views.team.level"), width: 100 },
            { label: t("views.team.stack"), width: 280 },
            { label: t("views.team.status"), width: 80 },
            { width: 34 },
          ]}
        >
          {users.map((u) => (
            <UserRow
              key={u.uuid}
              user={u}
              totalAdmins={totalAdmins}
              reloadData={loadData}
              professionalLevels={professionalLevels}
              teamRoles={teamRoles}
              stackTypes={stackTypes}
            />
          ))}
          {!isLoading && !users.length && (
            <TableRow>
              <TableCell colSpan={7}>
                <h3>{t("views.team.not_found")}</h3>
              </TableCell>
            </TableRow>
          )}
        </Table>
      )}
      <FeatureAlert />
      <WorkspaceInvite
        onClickAway={() => openInviteModal(false)}
        isOpen={inviteModalVisible}
        onInvite={loadData}
        useTechnicalInvites
      />
    </>
  );
});

TeamTable.propTypes = {
  useTableTitle: PropTypes.bool,
};
