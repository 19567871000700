import { APIMethod, fetchData } from "@client";

export async function saveTemplateQuery(uuid, data) {
  await fetchData(`/template/${uuid}`, APIMethod.POST, {
    data: {
      uuid,
      ...data,
    },
  });
}
