import { useState } from "react";
import { string } from "prop-types";
import { useTranslation } from "react-i18next";
import { getDuplicateName } from "project-structure";
import { duplicateProjectQuery, forceProjectSaveQuery } from "@query";
import { LOCKED_FEATURES } from "@utils";
import { useActiveProjectStore, useFeatureGuardian } from "@hooks";
import { MenuButton } from "@components";
import { FileCopy } from "@assets";
import { CircularProgress } from "@material-ui/core";

export const DuplicateProject = ({ uuid, name }) => {
  
  const activeProjects = useActiveProjectStore();
  const { t } = useTranslation();
  
  const [isDuplicating, setDuplicate] = useState(false);
  
  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ESTIMATION,
  );
  
  const duplicateProject = async () => {
    setDuplicate(true);
    const allowed = await checkAccess();
    if (!allowed) {
      setDuplicate(false);
      return;
    }
    
    await forceProjectSaveQuery(uuid);
    const p = await duplicateProjectQuery(uuid, getDuplicateName(name));
    activeProjects.addProject(p);
    
    setDuplicate(false);
  }
  
  return <>
    <MenuButton
      icon={isDuplicating ? <CircularProgress size={18} /> : <FileCopy />}
      onClick={duplicateProject}
    >
      {t("common.duplicate")}
    </MenuButton>
    <FeatureAlert />
  </>
}

DuplicateProject.propTypes = {
  uuid: string.isRequired,
  name: string.isRequired
}