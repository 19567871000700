import { useState, memo, useMemo } from "react";
import { bool, string, func, arrayOf, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { isEmptyHtml } from "project-structure";
import { Button, TextField } from "@components";
import {
  IconButton,
  CircularProgress,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { WysiwygEditorInputWrapper } from "./components/WysiwygEditorInputWrapper";
import { Send } from "@assets";
import useStyle from "./ChatMessageEditor.style";
import classnames from "classnames";

export const ChatMessageEditor = memo(({
  onCommit,
  placeholder,
  requireAuth,
  defaultValue,
  projectTeamMembers
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [message, setMessage] = useState(defaultValue || "");
  const [login, setLogin] = useState("");
  const [mentions, setMentions] = useState("");
  const [isSending, setSending] = useState(false);

  const handleChange = (value, isDifferent, mentions) => {
    setMessage(value);
    setMentions(mentions);
  };

  const handleSend = async () => {
    if (!isSending && message) {
      setSending(true);
      await onCommit(message, login, mentions);
      setMessage("");
      setMentions([]);
      setSending(false);
    }
  };

  const allowCommit = useMemo(
    () =>
      !isEmptyHtml(message) &&
      (!requireAuth || login.length) &&
      message !== defaultValue,
    [message, login]
  );

  return (
    <Grid item container role="listitem">
      {requireAuth && (
        <Grid item container wrap="nowrap">
          <TextField
            placeholder={t("errors.name.required")}
            onChange={(e) => setLogin(e.target.value)}
            className="m-1 input-name"
            size="small"
          />
          <Button
            size="medium"
            variant="contained"
            onClick={handleSend}
            disabled={!allowCommit}
            className={classnames(classes.messageButton, "button-send")}
          >
            {isSending ? (
              <CircularProgress size={24} />
            ) : (
              t("views.editor.comments.placeholder")
            )}
          </Button>
        </Grid>
      )}
      <WysiwygEditorInputWrapper
        value={message}
        placeholder={placeholder}
        onChange={handleChange}
        name="message-editor"
        size="small"
        className="m-1 input-comment"
        InputProps={{
          inputProps: {
            editorMentionUsers: projectTeamMembers,
            editorMentionSearchKeys: ["fullname", "email"],
          },
          endAdornment: !requireAuth && (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                onClick={handleSend}
                size="small"
                disabled={!allowCommit}
                className="button-send"
              >
                {isSending ? <CircularProgress size={16} /> : <Send />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
});

ChatMessageEditor.propTypes = {
  onCommit: func,
  placeholder: string,
  requireAuth: bool,
  projectTeamMembers: arrayOf(object),
  defaultValue: string
};
