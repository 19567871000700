import { node, number, string } from "prop-types";
import { useDroppable } from "@dnd-kit/core";
import { Grid } from "@material-ui/core";
import useStyle from "./TimelineSectionDroppable.style";
import useCellStyle from "@components/ProjectEditor/Tables/cells/commonStyles.style";
import classnames from "classnames";

export const SectionTimelineDroppable = ({
  sectionId,
  week,
  children,
  className,
}) => {
  const classes = useStyle();
  const cellClasses = useCellStyle();
  const { setNodeRef, isOver } = useDroppable({
    id: `${sectionId}-${week}`,
    data: { week }
  })
  
  return (
    <Grid
      item container
      alignItems="center"
      className={classnames(
        "h-full",
        cellClasses.fullDashBorderRight,
        className,
      )}
    >
      <Grid
        item container
        ref={setNodeRef}
        alignItems="center"
        className={classnames(
          "h-full relative rounded-2",
          isOver && classes.isOver
          )}
      >
        {children}
      </Grid>
    </Grid>
  );
};

SectionTimelineDroppable.propTypes = {
  sectionId: string.isRequired,
  week: number.isRequired,
  children: node,
  className: string,
};