import { useEffect } from "react";

export const ApropoRedirector = () => {
  useEffect(() => {
    if (process.env.REACT_APP_APP_ORIGIN_DOMAIN)
      window.location.replace(process.env.REACT_APP_APP_ORIGIN_DOMAIN);
  }, []);

  return <></>;
};
