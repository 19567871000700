import { bool, func, string } from "prop-types";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { Button } from "@components";
import { Dialog, LinearProgress } from "@material-ui/core";
import classnames from "classnames";
import useStyle from "./CreationProgress.style";

export const CreationProgress = ({
  open,
  cancelText,
  onCancel,
  errorMessage
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { stateStore } = useStores();

  const { filesUploadProgress } = stateStore;

  return (
    <Dialog open={open} PaperProps={{ className: classes.progressDialog }}>
      <h2 className="w-full mb-6 text-lg">{t("views.new_project.wait")}</h2>
      <p className="mb-4 w-full">
        {t("views.new_project.saving.project")}
        {"..."}
      </p>
      <LinearProgress
        className={classnames(
          classes.progress,
          !!errorMessage && classes.progressErrorColor
        )}
        variant={
          errorMessage || filesUploadProgress?.length > 1
            ? "determinate"
            : "indeterminate"
        }
        value={
          filesUploadProgress?.length > 1
            ? filesUploadProgress.reduce((p, c) => p + c, 0) /
              filesUploadProgress.length
            : 50
        }
      />
      {errorMessage ? (
        <>
          <p className="w-full my-4">{errorMessage}</p>
          <Button onClick={onCancel}>{cancelText || t("common.close")}</Button>
        </>
      ) : null}
    </Dialog>
  );
};

CreationProgress.propTypes = {
  open: bool.isRequired,
  onCancel: func.isRequired,
  cancelText: string,
  errorMessage: string,
};
