import { memo, useMemo } from "react";
import { bool, number, string, func, oneOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { capitalize, Grid, Switch, Tooltip } from "@material-ui/core";
import useStyle from "./VisibilityCell.style";
import useCellStyle from "../commonStyles.style";
import classnames from "classnames";

export const VisibilityCell = memo(({
  isHeader,
  hideVisibilitySwitch,
  onVisibilityToggle,
  actionTargetName,
  tableDepth = 0,
  visible = true,
  className,
  size="large"
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const cellClasses = useCellStyle();

  const text = useMemo(
    () =>
      `${t(`common.${visible ? "disable" : "enable"}`)} ${(
        actionTargetName ||
        t(`proposal_parts.module_levels.level_${tableDepth}`)
      ).toLowerCase()}`,
    [visible, tableDepth, actionTargetName]
  );

  return (
    <Grid
      item
      container
      role="rowitem"
      className={classnames(
        "cell",
        `d${tableDepth}`,
        cellClasses.cell,
        classes.visibilityCell,
        isHeader && cellClasses.headCell,
        cellClasses[`size${capitalize(size)}`],
        className
      )}
    >
      <Grid
        item container
        alignItems="center"
        wrap="nowrap"
        className={classnames(isHeader && "h-full", !isHeader && cellClasses.cellInner)}
      >
        {!hideVisibilitySwitch && (
          <Tooltip arrow title={text} placement="bottom">
            <Switch
              size="small"
              color="default"
              checked={visible}
              onChange={onVisibilityToggle}
              className="noDrag hoverIcon opaque preset-taskActionButton"
              name={text}
              tabIndex={-1}
            />
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
});

VisibilityCell.propTypes = {
  visible: bool,
  onVisibilityToggle: func,
  actionTargetName: string,
  hideVisibilitySwitch: bool,
  // common
  tableDepth: number,
  isHeader: bool,
  className: string,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
};
