import { arrayOf, bool, number, shape, string } from "prop-types";
import { observer } from "mobx-react";
import { useEditorCommentStore, useStructureStore } from "@hooks";
import { CELL_WIDTHS } from "@utils";
import { Tables } from "project-structure";
import { TitleCell, RowGroup, Row } from "@components";
import { ColumnFactory } from "./components/ColumnFactory";
import { ChatThread } from "@dialogs";
import { TitleInput } from "./components/TitleInput";

export const FixedPriceRow = observer(({
  useClientActions,
  isSellerOrClient,
  isProposal,
  maxTitleCellWidth,
  useExpandCell,
  columns,
  useProfitability,
  isEditorOrClient,
  allowEdition,
}) => {
  
  const commentStore = useEditorCommentStore();
  const structure = useStructureStore();
  const { turnOffFixedPrice } = structure.settings;

  const handleFixedPriceVisibility = () =>
    structure.settings.setTurnOffFixedPrice(!turnOffFixedPrice);
  
  return (
    <RowGroup
      originTableId={Tables.SUMMARY}
      tableDepth={1}
      isStatic
      presetClass="preset-accentSummary"
      elementId="fixedPrice"
      elementType="fix"
    >
      <Row
        tableDepth={1}
        isLastGroupRow
        isFirstGroupRow
        useVisibilityCell={useClientActions}
        useExpandCell={useExpandCell}
        presetClass="preset-rowSummary"
        visible={!turnOffFixedPrice}
        onVisibilityToggle={handleFixedPriceVisibility}
        noArrow
      >
        <TitleCell
          minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
          maxWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : maxTitleCellWidth}
          pinnedLeft={useExpandCell ? 1 : 0}
          actions={isEditorOrClient && useClientActions && commentStore?.commentsVisible && (
            <ChatThread
              rowThread
              path="fixedCosts"
            />
          )}
        >
          <TitleInput
            allowEdition={allowEdition}
            useExpandCell={useExpandCell}
          />
        </TitleCell>
        {
          columns.map(({ name }) => (
            <ColumnFactory
              key={name}
              name={name}
              isProposal={isProposal}
              isSellerOrClient={isSellerOrClient}
            />
          ))
        }
      </Row>
    </RowGroup>
  );
});

FixedPriceRow.propTypes = {
  useClientActions: bool,
  isSellerOrClient: bool,
  isProposal: bool,
  useExpandCell: bool,
  isEditorOrClient: bool,
  allowEdition: bool,
  columns: arrayOf(shape({
    name: string.isRequired
  })).isRequired,
  maxTitleCellWidth: number
};
