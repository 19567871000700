import { string } from "prop-types";
import { Link } from "@material-ui/core";
import useStyle from "../TextClearInput.style";
import classnames from "classnames";

export const Static = ({
  value,
  placeholder,
  className
}) => {
  
  const classes = useStyle();
  
  return (
    <Link
      onFocus={focus}
      color="textPrimary"
      className={classnames(classes.linkWrapper, className)}
    >
      <p
        className={classnames(value ? classes.inheritFont : classes.placeholder, "w-full")}
      >
        {value ? value.replace(/<(.|\n)*?>/g, "") : placeholder}
      </p>
    </Link>
  )
}

Static.propTypes = {
  value: string,
  placeholder: string,
  className: string,
}