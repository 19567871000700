import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  new: {
    backgroundColor: `${theme.palette.primary.main}80 !important`,
  },
  text: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.25,
  },
  avatar: {
    marginRight: 16,
  },
  check: {
    fontSize: 18,
    margin: "0 16px",
  },
  spinner: {
    position: "relative",
    left: "calc(50% - 20px)",
    margin: 10,
    alignSelf: "center",
  },
}));

export default useStyle;
