import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles(() => ({
  expandCell: {
    fontSize: 20,
    width: CELL_WIDTHS.EXPAND.DEPTH_1,
    minWidth: CELL_WIDTHS.EXPAND.DEPTH_1,
    position: "sticky !important",
    left: 0,
    zIndex: 3,
    "& > button": { padding: 0 },
    "&.d2": {
      width: CELL_WIDTHS.EXPAND.DEPTH_2,
      minWidth: CELL_WIDTHS.EXPAND.DEPTH_2,
    },
    "&.d3": {
      width: CELL_WIDTHS.EXPAND.DEPTH_3,
      minWidth: CELL_WIDTHS.EXPAND.DEPTH_3,
    },
  },
  arrow: {
    width: 24,
    height: 24,
  },
}));

export default useStyle;
