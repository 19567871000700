import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    width: 520,
  },
  cardElementContainer: {
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 5,
    padding: "14px 16px 13px",
    marginBottom: 16,
  },
  cardElementContainerFocused: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: "13px 15px 12px",
    borderWidth: 2,
    boxSizing: "border-box",
  },
  accordion: {
    borderRadius: 10,
    boxShadow: "none",
    padding: "0 12px",
    margin: "8px -12px !important",
    border: `1px solid ${theme.props.border}`,
    "&.expanded": {
      border: "none",
    },
    "&::before": {
      display: "none",
    },
  },
  title: {
    cursor: "auto !important",
    minHeight: "56px !important",
    padding: 0,
    "& > div": {
      margin: "0 !important",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  details: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  companyInfo: {
    margin: "2px 0",
  },
}));

export default useStyle;
