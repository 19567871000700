// Material components
import { SvgIcon } from "@material-ui/core";

export default (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 22 22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 16.9635 7.0374 21 12 21C16.9626 21 21 16.9635 21 12C21 7.0374 16.9626 3 12 3C7.0374 3 3 7.0383 3 12ZM4.8 12C4.8 8.0301 8.0301 4.8 12 4.8C15.9699 4.8 19.2 8.0301 19.2 12C19.2 15.9699 15.9699 19.2 12 19.2C8.0301 19.2 4.8 15.9699 4.8 12Z"
        fill="currentColor"
      />
      <path
        d="M12.9 14.7V11.1C12.9 10.6032 12.4977 10.2 12 10.2H10.2V12H11.1L11.1 14.7H9.3V16.5H14.7L14.7 14.7H12.9Z"
        fill="currentColor"
      />
      <path
        d="M12 9.52502C12.6213 9.52502 13.125 9.02134 13.125 8.40002C13.125 7.7787 12.6213 7.27502 12 7.27502C11.3787 7.27502 10.875 7.7787 10.875 8.40002C10.875 9.02134 11.3787 9.52502 12 9.52502Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
