import { bool } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useStructureStore } from "@hooks";
import { ValueCell } from "@components";

export const ProfitCell = observer(({
  allowEdition,
  isProposal,
}) => {
  const { getFieldName } = usePresetFieldNames(isProposal);
  const structure = useStructureStore();
  const { useMinMax } = structure.settings;
  
  return (
    <ValueCell
      wider widest={useMinMax}
      isHeader
      size="xSmall"
      style={{ cursor: allowEdition && "grab" }}
    >
      <span>
        {getFieldName("pt-profit")}
      </span>
    </ValueCell>
  )
})

ProfitCell.propTypes = {
  allowEdition: bool,
  isProposal: bool,
}