export default {
  primary: {
    main: "#4098F4",
    light: "#b4e0fd",
    lighter: "#D9EFFE",
    lightest: "#F5FBFF",
  },
  primaryLighter: "#79B6F7",
  secondary: "#3A3C4F",
  background: "#f5f6fa",
  backgroundSecondary: "#ffffff",
  backgroundLevel1: "#ecf4fe",
  backgroundLevel2: "#f5fafe",

  border: "#E5E5E5",
  backdrop: "#3A3C4F99",
  paperShadow: "0px 16px 64px rgba(0, 0, 0, 0.1)",

  text: {
    primary: "#3A3C4F",
    secondary: "#757784",
    special: "#020a48",
    lighter: "#b9bac1",
    alt: "#ffffff",
    alt1: "#7be5ee",
    alt2: "#062c61",
    neutral: "#F0F0F0",
  },

  buttonSecondaryHoverColor: "#cbccd0",
  tagBorder: "#E6E7ED",
  avatarColor: "#4a90e2",
  adminColor: "#C83BF5",

  contentHeight: "100%",
  templateCard: {
    standard: "#f1f1f1",
    selected: "#0089e2",
    premium: "#ffecb9",
    premiumBg: "#fff3d3",
    lock: "#fcb700",
  },
  stars: {
    gold: "#EAA844",
    silver: "#c0c0c0",
    bronze: "#cd7f32",
  },
  accordion: {
    expandedTitle: "#b4e0fd80",
    expandedContent: "#b4e0fd21",
    divider: "#d5e2fa",
  },
  prices: {
    package_free: "#393c51",
    package_pro: "#435fce",
    package_business: "#198fe7",
    package_enterprise: "#435fce",
  },

  selections: {
    level0: "#4098F4b3",
    level1: "#4098F480",
    level2: "#4098F44d",
    level3: "#4098F426",
    alt: "#3A3C4F26",
  },

  dot: "#D8D8DC",
  memberAddButton: "#ECF5FE",
  sectionColor: "#717381",
  billingColor: "#2590FF",

  active: "#5BAF4D",
  inactive: "#E64646",

  success: "#42BE57",
  warning: "#FB8B40",
  error: {
    main: "#E64646",
    light: "#ed7d7d",
  },
};
