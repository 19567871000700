import { Fragment } from "react";
import { number, string, object, arrayOf, oneOfType } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextClearInput, Link, SearchableListContent, SearchableListItem } from "@components";
import { CircularProgress } from "@material-ui/core";
import { useTaskSelect } from "./useTaskSelect";
import useStyle from "./TaskSelect.style";

export const TaskSelect = observer(({
  element,
  tableDepth,
  fullIdPath,
  parentName
}) => {
  const {
    isLoading,
    elements,
    focused,
    anchorEl,
    searchValue,
    taskNameAlreadyUsed,
    handleFocus,
    handleInputChange,
    handleScrollEnd,
    handleNameChange,
    handleModuleSelect,
  } = useTaskSelect({ element, tableDepth, fullIdPath });

  const classes = useStyle();
  const { t } = useTranslation();

  let usedHeader = null;

  return (
    <>
      <TextClearInput
        multiline
        onChange={handleInputChange}
        value={searchValue}
        focused={focused}
        error={taskNameAlreadyUsed}
        onFocus={handleFocus}
        onAccept={handleModuleSelect}
        acceptOnClickAway
        lockSpecialCharacters={false}
        placeholder={
          focused && tableDepth > 0
            ? t(`views.editor.dialogs.features.search`)
            : t("views.editor.dialogs.features.new_name_" + tableDepth)
        }
        ref={anchorEl}
        confirmOnEnter
        containerClass="name"
        className="-m-0-5"
        preventClickAwayOnElement="#libraryElementSelector"
        inputClassName="input-name"
      />
      {tableDepth > 0 && (
        <SearchableListContent
          scrollEndHandler={handleScrollEnd}
          show={focused}
          anchor={anchorEl.current}
          showEmptyMessage={!isLoading && !elements?.length}
          id="libraryElementSelector"
        >
          {elements.map((childElement) => {
            const {
              id: listedId,
              componentId,
              name: listedName,
              // category,
              parent,
            } = childElement;

            const isUsed = element.hasSibling(
              listedName,
              listedId,
              componentId
            );

            const parentId = element.parentComponentId;
            let header;
            //
            if (!header && parentId && parentId === parent) {
              header = "parent";
            } else header = "other";
            //
            if (header === usedHeader) {
              header = undefined;
            } else {
              usedHeader = header;
            }

            return (
              <Fragment key={`${listedName}_${listedId || componentId}`}>
                {header && (
                  <span className={classes.recommended}>
                    {header === "parent" ? (
                      t("views.editor.dialogs.features.headers.parent", {
                        item: parentName,
                      })
                    ) : (
                      <>
                        {t("views.editor.dialogs.features.headers.library")}
                        <Link to="/home/library">
                          {t(
                            "views.editor.dialogs.features.headers.library_link"
                          )}
                        </Link>
                      </>
                    )}
                  </span>
                )}
                <SearchableListItem
                  onClick={() => handleNameChange(childElement)}
                  disabled={isUsed}
                >
                  {listedName?.replace(/<(.|\n)*?>/g, "")}
                </SearchableListItem>
              </Fragment>
            );
          })}
          {isLoading && (
            <CircularProgress
              aria-label="progress indicator"
              className={classes.progress}
            />
          )}
        </SearchableListContent>
      )}
    </>
  );
});

TaskSelect.propTypes = {
  element: object.isRequired,
  tableDepth: number,
  fullIdPath: arrayOf(oneOfType([number, string])),
  parentName: string,
};
