import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { StructureStoreProvider } from "@stores";
import {useLibraryStore, useProjectEditorStore, useStructureTransactionListener} from "@hooks";
import { PageCircularProgress, LibraryContainer } from "@components";

export const LibraryView = observer(() => {
  const libraryStore = useLibraryStore();
  const editorStore = useProjectEditorStore();

  const [isLoading, setLoading] = useState(true);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    onLoad();
  }, [libraryStore.pending]);

  const onLoad = async () => {
    setLoading(true);
    const s = await libraryStore.initStructure(editorStore.workTypes);
    setStructure(s);
    setLoading(false);
  };

  useStructureTransactionListener(structure, true);

  return isLoading || !structure ? (
    <PageCircularProgress />
  ) : (
    <StructureStoreProvider value={structure}>
      <LibraryContainer />
    </StructureStoreProvider>
  );
});
