import { arrayOf, bool, number, shape, string } from "prop-types";
import { observer } from "mobx-react";
import {
  usePresetFieldNames,
  useBudgetTrackingStore,
  useStructureStore,
} from "@hooks";
import { HeaderRow, TitleCell, ScrollButton } from "@components";
import { isMobile } from "react-device-detect";
import { ValueCell } from "../../../cells";
import { useTranslation } from "react-i18next";
import { ExpandTitleHourCell } from "./ExpandTitleHourCell.js/ExpandTitleHourCell";
import { ExpandTitleCostCell } from "./ExpandTitleCostCell.js/ExpandTitleCostCell";
import { CELL_WIDTHS } from "../../../../../../utils/settings";

export const TableHeader = observer(
  ({
    tableIdentifier,
    isProposal,
    isTableContentOverflowing,
    scrollTargetId,
  }) => {
    const { getFieldName } = usePresetFieldNames(isProposal);
    const { t } = useTranslation();

    const budgetTracking = useBudgetTrackingStore();
    const structure = useStructureStore();
    const { timeModifier } = structure.settings;

    return (
      <HeaderRow
        expandable
        id={tableIdentifier}
        className="preset-paper"
        altBackground
        endRowChildren={
          isTableContentOverflowing && !isMobile &&
          <ScrollButton direction="right" scrollTargetId={scrollTargetId} />
        }
      >
        <TitleCell
          maxWidth={budgetTracking.calculateMaxWithcellSummary}
          minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
          pinnedLeft={0}
          actions={
            isTableContentOverflowing && !isMobile &&
            <ScrollButton direction="left" scrollTargetId={scrollTargetId} />
          }
        >
          <span className="preset-summaryHeader pt-work">
            {getFieldName("pt-work")}
          </span>
        </TitleCell>
        <ValueCell widest>
          {t(
            `views.editor.summary_table.rate_${
              timeModifier === 1 ? "hours" : "days"
            }`
          )}
        </ValueCell>
        <ExpandTitleHourCell />
        <ExpandTitleCostCell />
      </HeaderRow>
    );
  }
);

TableHeader.propTypes = {
  tableIdentifier: string,
  showPrices: bool,
  isProposal: bool,
  readOnly: bool,
  useClientActions: bool,
  allowEdition: bool,
  useProfitability: bool,
  columns: arrayOf(
    shape({
      name: string.isRequired,
    })
  ).isRequired,
  maxTitleCellWidth: number,
  isTableContentOverflowing: bool,
  scrollTargetId: string,
};
