import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderColor: "transparent",
    boxSizing:"content-box"
  },
  startAlignment: { justifyContent: "flex-start" },
  endAlignment: { justifyContent: "flex-end" },
  spaceBetweenAlignment: { justifyContent: "space-between" },
  actions: {
    padding: 12,
    "& > button, & > div > button": {
      margin: 4,
    },
  },
  mobileTitleContent: {
    padding: 12,
    paddingRight: 4,
  },
  mobileContent: {
    padding: 12,
  },
  mobileActionsContent: {
    padding: 4,
  },
  "variant-info": {
    color: theme.palette.primary.main + " !important",
    borderColor: theme.palette.primary.main + " !important",
    borderStyle: "solid",
  },
  "variant-warning": {
    color: theme.palette.warning.main + " !important",
    borderColor: theme.palette.warning.main + " !important",
    borderStyle: "solid",
  },
  "variant-danger": {
    color: theme.palette.error.main + " !important",
    borderColor: theme.palette.error.main + " !important",
    borderStyle: "solid",
  },
}));

export default useStyle;
