import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, capitalize } from "@material-ui/core";
import { ClearInput, InfoLabel } from "@components";
import { getCompanySettingsQuery, setCompanySettingsQuery } from "@query";
import { COMPANY_SETTINGS } from "@client";
import useStyle from "./Subviews.style";

export const TimeEquiv = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isLoading, setLoading] = useState(true);
  const [dayTime, setDayTime] = useState(0);
  const [weekTime, setWeekTime] = useState(0);
  const [monthTime, setMonthTime] = useState(0);

  useEffect(() => {
    (async () => {
      let t = await getCompanySettingsQuery(COMPANY_SETTINGS.TIME_EQUIV);
      if (!t) {
        t = { day: 8, week: 5, month: 20 };
        await setCompanySettingsQuery(COMPANY_SETTINGS.TIME_EQUIV, t);
      }
      setDayTime(t.day);
      setWeekTime(t.week);
      setMonthTime(t.month);
      setLoading(false);
    })();
  }, []);

  const handleDayChange = (v) => setDayTime(v);
  const handleWeekChange = (v) => setWeekTime(v);
  const handleMonthChange = (v) => setMonthTime(v);

  useEffect(() => {
    if (!isLoading)
      setCompanySettingsQuery(COMPANY_SETTINGS.TIME_EQUIV, {
        day: dayTime,
        week: weekTime,
        month: monthTime,
      });
  }, [dayTime, weekTime, monthTime]);

  return (
    <>
      <InfoLabel
        label="views.settings.projects.timeEquiv"
        tooltipText="views.settings.projects.timeEquiv_info"
      />
      <Grid
        item
        container
        alignItems="center"
        className="text-md"
      >
        <Grid
          item
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          className={classes.workTypeRow}
        >
          <Grid item xs={2} sm={5} md={6} lg={7}>
            {capitalize(t("time.day"))}
          </Grid>
          <Grid item container alignItems="center" xs={10} sm={7} md={6} lg={5}>
            <ClearInput
              value={dayTime}
              onChange={handleDayChange}
              alignContent="center"
              max={24}
              min={1}
              confirmOnEnter={false}
              disabled={isLoading}
              className={classes.timeEquivInput}
              addInitialBorder
              decimals={2}
              fixedDecimalScale
            />
            <span>{t("times.hour")}</span>
          </Grid>
        </Grid>
        <Grid
          item
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          className={classes.workTypeRow}
        >
          <Grid item xs={2} sm={5} md={6} lg={7}>
            {capitalize(t("time.week"))}
          </Grid>
          <Grid item container alignItems="center" xs={10} sm={7} md={6} lg={5}>
            <ClearInput
              value={weekTime}
              onChange={handleWeekChange}
              alignContent="center"
              max={7}
              min={1}
              confirmOnEnter={false}
              disabled={isLoading}
              className={classes.timeEquivInput}
              addInitialBorder
              decimals={2}
              fixedDecimalScale
            />
            <span>
              {t("times.day") + " "}
              <span className="opaque">{`(${dayTime * weekTime} h)`}</span>
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
          className={classes.workTypeRow}
        >
          <Grid item xs={2} sm={5} md={6} lg={7}>
            {capitalize(t("time.month"))}
          </Grid>
          <Grid item container alignItems="center" xs={10} sm={7} md={6} lg={5}>
            <ClearInput
              value={monthTime}
              onChange={handleMonthChange}
              alignContent="center"
              max={31}
              min={1}
              confirmOnEnter={false}
              disabled={isLoading}
              className={classes.timeEquivInput}
              addInitialBorder
              decimals={2}
              fixedDecimalScale
            />
            <span>
              {t("times.day") + " "}
              <span className="opaque">{`(${dayTime * monthTime} h)`}</span>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
