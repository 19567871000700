import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { ValueCell } from "@components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { parsePrice } from "project-structure";
import { MAX_VALUES } from "@utils";

export const CompanyUnitCostCell = observer(({
  element,
  displayCellContent,
  allowEdition,
  useCompanyCurrency,
  profitabilityMode
}) => {
  
  const { t } = useTranslation();
  const { currencyExchangeRate } = useProjectEditorStore();
  const structure = useStructureStore();
  const { currencyObj, companyCurrencyObj, modifier, timeModifier } = structure.settings;
  const { hasResources, hasOnlyCost, parsedCompanyUnitCost, parsedCompanyUnitCostWithModifier } = element;
  
  const handleCompanyUnitCostChange = (unitCost) => {
    element.setCompanyUnitCost(unitCost / modifier / (useCompanyCurrency ? currencyExchangeRate : 1));
  };
  
  const usedCurrency = useMemo(() => (
    useCompanyCurrency
      ? companyCurrencyObj
      : currencyObj
  ), [useCompanyCurrency, companyCurrencyObj, currencyObj]);
  
  const messageTitle = useMemo(() => {
    if(profitabilityMode && hasOnlyCost)
      return t("views.editor.profitability_fixed_alert.title")
  }, [profitabilityMode, hasOnlyCost]);
  
  const messageContent = useMemo(() => {
    if(profitabilityMode && hasOnlyCost)
      return t("views.editor.profitability_fixed_alert.content")
  }, [profitabilityMode, hasOnlyCost]);
  
  const value = useMemo( () => (
    useCompanyCurrency && currencyExchangeRate !== 1
      ? parsedCompanyUnitCostWithModifier(currencyExchangeRate)
      : parsedCompanyUnitCost
), [parsedCompanyUnitCost, useCompanyCurrency, currencyExchangeRate]);
  
  return (
    <ValueCell
      widest
      displayContent={displayCellContent}
      value={hasOnlyCost ? "" : value}
      onValueChange={handleCompanyUnitCostChange}
      editable={!hasResources && allowEdition /*&& typeof value !== "string"*/}
      max={timeModifier === 1 ? MAX_VALUES.RATE_HOUR : MAX_VALUES.RATE_DAY}
      symbolStart={usedCurrency.symbolStart}
      symbolEnd={usedCurrency.symbolEnd}
      messageTitle={messageTitle}
      message={messageContent}
      showDecimals
      parser={parsePrice}
      inputClassName="input-unit-cost"
    />
  );
});

CompanyUnitCostCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  allowEdition: bool,
  useCompanyCurrency: bool,
  profitabilityMode: bool,
};
