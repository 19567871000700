import { PageTitle } from "@components";
import { TeamTable } from "@views/Utility/Team/components/TeamTable/TeamTable";
import { Container } from "@material-ui/core";

export const Team = () => {

  return (
    <>
      <PageTitle pinned />
      <Container>
        <TeamTable />
      </Container>
    </>
  );
};
