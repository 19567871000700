import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  container: {
    "&:not(:last-child)": {
      marginBottom: "8px !important",
    },
  },
  accordion: {
    width: "100%",
    overflow: "hidden",
    boxShadow: "none",
    border: "none",
    marginTop: "0 !important",
  },
  title: {
    minHeight: "36px !important",
    borderRadius: 10,
    border: `1px solid ${theme.props.border}`,
    padding: "0 8px",
    "&.expanded": {
      borderColor: theme.palette.primary.main,
    },
    "& > div": {
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0 !important",
    },
  },
  content: {
    paddingTop: 24,
    paddingBottom: 24,
    flexDirection: "column",
  },
}));

export default useStyle;