import { useEffect, useState } from "react";
import { func, string } from "prop-types";
import { HuePicker } from "react-color";
import { sampleColors } from "@utils";
import { Saturation } from "react-color/lib/components/common";
import ChromePointerCircle from "react-color/lib/components/chrome/ChromePointerCircle";
import {
  hsv2hsl,
  hsl2rgb,
  hsl2hsv,
  rgb2hsl,
  rgba2string,
  parseRgbString,
} from "@utils/colorParsers";
import { TextField } from "@components";
import { Grid } from "@material-ui/core";
import classnames from "classnames";
import useStyle from "../ColorPicker.style";

export const ColorPickerInner = ({
  onChange,
  initialColor,
  onFocus,
  onBlur,
}) => {
  const classes = useStyle();
  
  const [color, setColor] = useState({ h: 0, s: 0, l: 0 });
  const [inputColor, setInputColor] = useState({ r: 0, g: 0, b: 0, a: 1 });
  const [colorString, setColorString] = useState("");
  
  useEffect(() => {
    if (initialColor) {
      const { h, s, l } = rgb2hsl(initialColor);
      setColor({ h, s, l });
      setInputColor(parseRgbString(initialColor));
      setColorString(initialColor);
    }
  }, []);
  
  const handleColorChange = ({ h, s, v }) => {
    const hsl = hsv2hsl({ h, s, v });
    setColor(hsl);
    updateColorInputs(hsl); //, alpha);
  };
  const handleHueChange = ({ hsl }) => {
    setColor({ ...color, h: hsl.h });
    updateColorInputs({ ...color, h: hsl.h }); //, alpha);
  };
  
  const updateColorInputs = (hsl) => {
    const c = hsl2rgb(hsl);
    const str = rgba2string(c);
    if (onChange) onChange(str);
    setColorString(str);
    setInputColor({
      r: parseInt(c.r * 255),
      g: parseInt(c.g * 255),
      b: parseInt(c.b * 255),
    }); //,a:c.a});
  };
  
  const handleColorStringChange = (e) => {
    setColorString(e.target.value);
    if (e.target.value.match(/^(#[a-fA-F0-9]{6})$/)) {
      const { h, s, l } = rgb2hsl(e.target.value);
      setColor({ h, s, l });
      setInputColor(parseRgbString(e.target.value));
      if (onChange) onChange(e.target.value);
    }
  };
  
  const setColorPart = (t) => (e) => {
    if (!e.target.value || Number.isNaN(parseInt(e.target.value))) {
      setInputColor({ ...inputColor, [t]: e.target.value });
      return;
    }
    let rgbColor = hsl2rgb(color);
    let num = parseInt(e.target.value);
    if (num > 255) num = 255;
    setInputColor({ ...inputColor, [t]: num.toString() });
    rgbColor = { ...rgbColor, [t]: num / 255 };
    setColor(rgb2hsl(rgbColor));
    const str = rgba2string(rgbColor);
    setColorString(str);
    if (onChange) onChange(str);
  };
  
  const setSampleColor = (c) => () => {
    const hsl = rgb2hsl(c);
    setColor(hsl);
    updateColorInputs(hsl, 1);
  };
  
  return (
    <Grid item container>
      <Grid item container className="p-4 pb-0">
        <div className={classes.saturation}>
          <Saturation
            hsv={hsl2hsv(color)}
            hsl={color}
            pointer={ChromePointerCircle}
            onChange={handleColorChange}
          />
        </div>
        <HuePicker
          color={color}
          direction="vertical"
          width={24}
          height={228}
          pointer={ChromePointerCircle}
          onChange={handleHueChange}
          className={classes.pointerHue}
        />
        <Grid item container className={classes.sampleColorsContainer}>
          {sampleColors.map((backgroundColor) => (
            <Grid
              item
              key={backgroundColor}
              className={classes.block}
              style={{ backgroundColor }}
              onClick={setSampleColor(backgroundColor)}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item container className="p-3" wrap="nowrap">
        <TextField
          value={colorString}
          onChange={handleColorStringChange}
          variant="outlined"
          size="small"
          className={classnames(classes.input, "m-1")}
          style={{ flexGrow: 2.5 }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <TextField
          value={inputColor.r}
          onChange={setColorPart("r")}
          variant="outlined"
          size="small"
          className={classnames(classes.input, "m-1")}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <TextField
          value={inputColor.g}
          onChange={setColorPart("g")}
          variant="outlined"
          size="small"
          className={classnames(classes.input, "m-1")}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <TextField
          value={inputColor.b}
          onChange={setColorPart("b")}
          variant="outlined"
          size="small"
          className={classnames(classes.input, "m-1")}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Grid>
    </Grid>
  );
};

ColorPickerInner.propTypes = {
  initialColor: string,
  onChange: func,
  onFocus: func,
  onBlur: func,
};