import { useMemo } from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { TotalContainer } from "../components";
import { getProfitColor } from "@utils";
import { useTheme } from "@material-ui/core/styles";

export const TotalProfitability = observer(({
  allowEdition
}) => {
  
  const theme = useTheme();
  const { t } = useTranslation();
  const structure = useStructureStore();
  
  const { totalCompanyProfitability } = structure;
  
  const notApplicable = useMemo(() => (
    totalCompanyProfitability === null
  ), [totalCompanyProfitability]);
  
  const color = useMemo(() => (
    getProfitColor(totalCompanyProfitability, theme)
  ), [totalCompanyProfitability, theme]);
  
  const handleProfitabilityChange = (profitability) => {
    structure.setTotalCompanyProfitability(profitability);
  };
  
  return (
    <TotalContainer
      totalName="Profitability"
      min={notApplicable ? t("common.na") : totalCompanyProfitability}
      symbolEnd={notApplicable ? undefined : "%"}
      style={{color}}
      onValueChange={handleProfitabilityChange}
      editable={allowEdition}
      showDecimals
      maxValue={99.99}
    />
  );
})

TotalProfitability.propTypes = {
  allowEdition: bool,
}