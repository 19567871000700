import {
  bool,
  string,
  number,
  node,
  object,
  func,
  arrayOf,
  oneOf,
  oneOfType,
} from "prop-types";
import { observer } from "mobx-react";
import { SortableList } from "@components";
import { TimelineNestedRow } from "../TimelineNestedRow/TimelineNestedRow";
import { TimelineRowGroup } from "../TimelineRowGroup/TimelineRowGroup";

export const TimelineRowGroupList = observer(({
  allowEdition,
  isLastGroupRow,
  listElements,
  onReorder,
  children,
  parentIds,
  tableDepth = 0,
  ...elementProps
}) => (
  <>
    <SortableList
      list={listElements}
      path={parentIds.join("/")}
      group={`timeline_${parentIds.join("/")}`}
      // group={`timeline_d${tableDepth}`}
      onListReorder={onReorder}
      displayAsColumnFlexbox
      disabled={!allowEdition}
    >
      {listElements?.map((child, i) =>
        tableDepth ? (
          <TimelineNestedRow
            key={`element${child.id}`}
            resource={child}
            allowEdition={allowEdition}
            isLastGroupRow={isLastGroupRow && i === listElements.length - 1}
            parentIds={parentIds}
            tableDepth={tableDepth + 1}
            {...elementProps}
          />
        ) : (
          <TimelineRowGroup
            key={`element${child.id}`}
            workType={child}
            allowEdition={allowEdition}
            parentIds={parentIds}
            tableDepth={tableDepth + 1}
            {...elementProps}
          />
        )
      )}
    </SortableList>
    {children}
  </>
));

TimelineRowGroupList.propTypes = {
  tableDepth: oneOf([0, 1, 2, 3]),
  listElements: arrayOf(object),
  onReorder: func,

  blockExpansions: bool,
  isLastGroupRow: bool,

  parentIds: arrayOf(oneOfType([number, string])),
  children: node,

  //common
  allowEdition: bool,
  isSellerOrClient: bool,
  isProposal: bool,
  useClientActions: bool,
};
