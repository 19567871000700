import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { useTranslation } from "react-i18next";
import { ClearInput, InfoLabel } from "@components";
import { Grid, Switch } from "@material-ui/core";

export const Risk = observer(({ noLabel }) => {
  
  const structure = useStructureStore();
  const { t } = useTranslation();
  
  const { riskBuffer, useRiskBuffer } = structure.settings;
  
  const handleRiskBufferChange = (v) => {
    structure.settings.setRiskBuffer(v);
  };
  
  const handleUseRiskBufferChange = () => {
    structure.settings.setUseRiskBuffer(!useRiskBuffer);
  };
  
  return <>
    {
      !noLabel &&
      <InfoLabel
        className="mt-0"
        label="views.editor.dialogs.settings.risk_buffer"
        tooltipText="views.editor.dialogs.settings.tooltips.risk_buffer"
      />
    }
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <ClearInput
        confirmOnEnter
        name="risk buffer"
        value={riskBuffer || 0}
        alignContent="left"
        onChange={handleRiskBufferChange}
        fullWidth
        addInitialBorder
        className="px-3 text-md"
        style={{ height: 36 }}
        suffix="%"
      />
      <Switch
        size="medium"
        color="primary"
        checked={useRiskBuffer || false}
        onChange={handleUseRiskBufferChange}
        name={t("views.editor.dialogs.settings.risk_buffer")}
      />
    </Grid>
  </>
})

Risk.propTypes = {
  noLabel: bool
}