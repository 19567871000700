export const COMPANY_SETTINGS = {
  LIBRARY_SETTINGS: "librarySettings",
  LIBRARY_WT: "libraryWorkTypes",
  TRIAL_ALERT: "trialAlert",
  HOME_COLUMNS: "columns",
  RATES: "rates",
  PRESET: "defaultPresetId",
  TEMPLATE: "defaultTemplateId",
  TIME_EQUIV: "timeEquiv",
  CURRENCY_DEF: "defaultCurrency",
  COMPANY_CURRENCY: "companyCurrency",
  HIDDEN_WT: "hiddenWorkTypes",

  DOMAIN: "Domain",
  DOMAIN_USE: "useDomain",
  DOMAIN_CNAME: "CnameName",
  DOMAIN_VALUE: "CnameValue",
  
  INVOICE_EMAIL: "invoiceEmail",
  INVOICE_SEND: "sendInvoiceEmail"
};

export const USER_COMPANY_SETTINGS = {
  AVERAGE_ALERT: (userUuid) => `projectAverageAlert${userUuid}`,
  RISK_ALERT: (userUuid) => `projectRiskBufferAlert${userUuid}`,
  // LATEST_UPDATES_SEEN: (userUuid) => `seenLatestUpdates${userUuid}`,
};

export const PROJECT_COMPANY_SETTINGS = {
  VALUE_ALERT: (projectUuid) => `projectValueAlert${projectUuid}`,
  VALUE_PROJECT: (projectUuid) => `projectDifferentValue-${projectUuid}`,
}