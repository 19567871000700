import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  workTypeRow: {
    padding: "8px 16px",
    border: `1px solid ${theme.props.border}`,
    borderRadius: 5,
    marginTop: 2,
    marginBottom: 2,
    minHeight: 52,
    height: "max-content",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  workTypeHeaderRow: {
    padding: "4px 17px",
    marginBottom: 2,
    fontSize: 12,
    alignItems: "center",
    fontWeight: "400 !important",
    lineHeight: "normal",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
  workTypeRate: {
    maxWidth: 256,
  },
  timeEquivInput: {
    height: 36,
    marginRight: 8,
    maxWidth: 96,
    "@media (max-width: 520px)": {
      maxWidth: 64,
    },
  },
  notReady: {
    color: theme.props.warning,
  },
  unused: {
    color: theme.props.text.lighter,
  },
  uniqueLinkInput: {
    height: 0,
    padding: 0,
    border: "none",
    position: "absolute",
  },
  cell: {
    height: 36,
  },
  copyButton: {
    maxWidth: 40,
    marginLeft: 8,
    marginRight: 12
  }
}));

export default useStyle;
