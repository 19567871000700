import { bool, func, string } from "prop-types";
import { Grid } from "@material-ui/core";
import { Checkbox } from "@components";

export const SettingEntry = ({
  name,
  label,
  visible,
  setVisibility
}) => (
  <Grid item xs={12} className="no-basis">
    <Checkbox
      name={name}
      aria-label={name}
      checked={visible}
      onChange={() => setVisibility(!visible)}
      className="mr-2"
      labelClassName="m-0"
      label={label}
    />
  </Grid>
)

SettingEntry.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  setVisibility: func.isRequired,
  visible: bool,
}