import { APIMethod, fetchData } from "@client";

export async function createCustomFontQuery(
  name,
  target,
) {
  const res = await fetchData("/fonts", APIMethod.POST, {
    data: { name, target, custom: 1 },
  });
  return res.results.id;
}
