import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: 8,
    fontSize: 14,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "transparent",
    borderRadius: 5,
    "&:hover": {
      borderColor: (allowEdition) =>
        allowEdition ? theme.palette.primary.light : undefined,
    },
  },
  editable: {
    border: `1px solid ${theme.palette.primary.light} !important`,
    marginBottom: 8,
  },
  button: {
    justifyContent: "center",
    "&:last-child": {
      marginLeft: 8,
    },
  },
}));

export default useStyle;
