import { mapKeysToSnakeCase } from "@client/api/utils/fetchApiUtils";

export const parseFormData = (
  data,
  removeEmpty = false,
  mapRequestKeysToSnakeCase = false
) => {
  const parsed = mapRequestKeysToSnakeCase ? mapKeysToSnakeCase(data) : data;
  const body = new URLSearchParams();
  Object.keys(parsed).forEach((k) => {
    if ((removeEmpty && ![null, undefined].includes(parsed[k])) || !removeEmpty)
      body.append(k, parsed[k]);
  });

  return body;
};
