import { arrayOf, bool, number, object, shape } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useProjectEditorStore, useStructureStore, useWorkTypePermitChecker } from "@hooks";
import { ValueCell, VisibilityButton } from "@components";
import { HeaderWorkTypeActions } from "./HeaderWorkTypeActions";
import { Tooltip, useTheme } from "@material-ui/core";
import classnames from "classnames";
import { useMemo } from "react";

export const HeaderWorkType = observer(({
  workType,
  allowEdition,
  section,
  isLibrary,
  isSellerOrClient,
  visibleWorkTypes,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { usesTwoValues } = structure;

  const { editionGrant, isPending, hasPermit, userSeesOnlyPermittedWorkTypes } = useWorkTypePermitChecker(
    workType.id
  );

  const editable = allowEdition;
  const visible = !structure.isWorkTypeOff(workType.id);
  
  const structureWorkType = useMemo(() => (
    structure.getWorkTypeById(workType.id)
  ), [workType.id]);

  return (
    <ValueCell
      key={workType.id}
      isHeader
      size="xSmall"
      wider={usesTwoValues}
      style={{
        cursor: editable && "grab",
        color:
          editable &&
          hasPermit &&
          (isPending ? theme.props.warning : theme.props.success),
      }}
      visible={visible && editionGrant}
      className={classnames(visibilityMode && workType.isHidden && "transparent-2")}
    >
      <Tooltip
        title={t(`views.editor.status_${isPending ? "pending" : "done"}`)}
        disableHoverListener={!editable || !hasPermit}
      >
        <span className="preset-tableBreakdownHeader">
          {structureWorkType?.name}
        </span>
      </Tooltip>
      {editable && Boolean(structureWorkType) && (
        <HeaderWorkTypeActions
          workType={structureWorkType}
          sectionWorkType={workType}
          section={section}
          useLibraryManagementStyle={isLibrary}
          isSellerOrClient={isSellerOrClient}
          hasPermit={hasPermit && editionGrant}
          isPending={isPending}
          visibleWorkTypes={visibleWorkTypes}
          userSeesOnlyPermittedWorkTypes={userSeesOnlyPermittedWorkTypes}
        />
      )}
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={2}
          hidden={workType.isHidden}
          onChange={workType.setHideWorkType}
        />
      }
    </ValueCell>
  );
});

HeaderWorkType.propTypes = {
  workType: object.isRequired,
  section: object.isRequired,
  allowEdition: bool,
  isLibrary: bool,
  isSellerOrClient: bool,
  visibleWorkTypes: arrayOf(shape({
    id: number,
    invisible: bool,
  })),
};
