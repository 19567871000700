import { useCallback, useEffect, useState } from "react";
import { bool, string, node, arrayOf, oneOfType } from "prop-types";
import { Slide, Grid, Paper } from "@material-ui/core";
import { useProjectEditorStore } from "@hooks";
import { observer } from "mobx-react";
import useStyle from "./Sidebar.style";

const minDrawerWidth = 200;

export const Sidebar = observer(({
  children,
  noRescale,
  containerId,
  visible
}) => {

  const editorStore = useProjectEditorStore();
  const classes = useStyle();
  
  const [sidebarWidth, setSidebarWidth] = useState(200);

  const { containerRef } = editorStore;

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    editorStore.setScale();
  };
  
  useEffect(() => {
    return () => {
      document.removeEventListener("mouseup", handleMouseUp, true);
      document.removeEventListener("mousemove", handleMouseMove, true);
    }
  })

  const handleMouseMove = useCallback((e) => {
    const container = containerId
      ? document.querySelector("#" + containerId)
      : containerRef;
    if (container) {
      const newWidth = e.clientX - container.getBoundingClientRect().x;
      setSidebarWidth(
        newWidth > minDrawerWidth
          ? newWidth < container.offsetWidth / 2
            ? newWidth
            : container.offsetWidth / 2
          : 200
      );
    }
  }, [containerId]);

  return (
    <Slide direction="right" in={visible} className={classes.root}>
      <Paper
        style={{
          width: visible ? sidebarWidth : 0,
          minWidth: visible ? sidebarWidth : 0,
        }}
      >
        {!noRescale && (
          <div
            onMouseDown={(e) => handleMouseDown(e)}
            className={classes.dragger}
          />
        )}
        <Grid item container wrap="nowrap" className={classes.paper}>
          {visible && children}
        </Grid>
      </Paper>
    </Slide>
  );
});

Sidebar.propTypes = {
  visible: bool,
  noRescale: bool,
  children: oneOfType([arrayOf(node), node]).isRequired,
  // actions: oneOfType([arrayOf(node), node]),
  containerId: string,
};
