import { arrayOf, bool, number, string, func } from "prop-types";
import { Accordion, Button } from "@components";
import { OverheadTitle } from "./components/OverheadTitle";
import { SettingContents } from "../SettingContents/SettingContents";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const NewOverhead = ({
  used,
  name,
  percent,
  workTypes,
  availableWorkTypes,
  onAccept,
  onChange,
  onCancel,
}) => {
  const { t } = useTranslation();
  
  const handleSetUsed = (newUsed) => {
    onChange({ used: newUsed })
  }
  
  const handleSetName = (newName) => {
    onChange({ name: newName })
  }
  
  const handleSetOf = (workTypeId) => {
    onChange({
      workTypes: workTypes.includes(workTypeId) ? workTypes.filter((s) => s !== workTypeId) : [...workTypes, workTypeId]
    })
  }
  
  const handleSetAllOf = () => {
    onChange({ workTypes: workTypes.length === availableWorkTypes.length ? [] : [...availableWorkTypes] })
  }
  
  const handleSetPercent = (newPercent) => {
    onChange({ percent: newPercent })
  }
  
  return (
    <Accordion
      expanded
      title={<OverheadTitle
        name={name}
        used={used}
        useActions={false}
        hasParent={Boolean(parent)}
        setUsed={handleSetUsed}
        setName={handleSetName}
      />}
      titleClassName="pl-2"
      variant="info"
      bordered={false}
      titleColored
    >
      <SettingContents
        percent={percent}
        usedWorkTypes={workTypes}
        availableWorkTypes={availableWorkTypes}
        onPercentChange={handleSetPercent}
        onWorkTypeSelect={handleSetOf}
        hideList
        onSelectAll={handleSetAllOf}
      />
      <Grid item container justifyContent="flex-end" className="mt-4">
        <Button
          variant="contained"
          color="secondary"
          onClick={onCancel}
          className="mr-2"
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onAccept}
        >
          {t("common.apply")}
        </Button>
      </Grid>
    </Accordion>
  );
};

NewOverhead.propTypes = {
  used: bool.isRequired,
  name: string.isRequired,
  percent: number.isRequired,
  workTypes: arrayOf(number).isRequired,
  availableWorkTypes: arrayOf(number).isRequired,
  
  onChange: func.isRequired,
  onAccept: func.isRequired,
  onCancel: func.isRequired,
};