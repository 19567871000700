import { memo, useMemo } from "react";
import { arrayOf, bool, func, number, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@components";
import { Grid, Tooltip } from "@material-ui/core";

export const WorkTypeCheckbox = memo(({
  id,
  name,
  checked,
  usedInSections,
  onChange,
  editedWorkTypeName,
}) => {
  const { t } = useTranslation();
  
  const isDisabled = useMemo(() => (
    Boolean(usedInSections?.length)
  ), [usedInSections?.length])
  
  return (
    <Tooltip
      placement="bottom-start"
      title={
      isDisabled && editedWorkTypeName
        ? <>
          <span>
            {t("views.editor.dialogs.estimate.used_in_sections", { wTName: editedWorkTypeName, targetWTName: name })}
          </span>
          <br />
          <strong>
            {usedInSections.join(", ")}
          </strong>
        </>
        : ""
      }
    >
      <Grid item container className="my-1">
        <Checkbox
          name={`count${id}`}
          aria-label={name}
          checked={checked}
          disabled={isDisabled}
          onChange={onChange}
          className="m-0"
          label={name}
        />
      </Grid>
    </Tooltip>
  );
});

WorkTypeCheckbox.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  editedWorkTypeName: string,
  onChange: func.isRequired,
  usedInSections: arrayOf(string),
  checked: bool,
};