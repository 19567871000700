export function setSelectionColor(
  rows,
  cols,
  color = null,
  spreadsheet = null
) {
  const updates = {};
  rows.forEach((row) => {
    cols.forEach((cell) => {
      const id = coordsToIdentifier(cell, row);
      const oldStyle = spreadsheet ? spreadsheet.getStyle(id) : "";
      if (
        oldStyle.match(
          /background-color: ((\w+|#.{3}|#.{6}|#.{8})|(rgba?\(.*\)));/g
        )
      )
        updates[id] = oldStyle.replace(
          /background-color: ((\w+|#.{3}|#.{6}|#.{8})|(rgba?\(.*\)));/g,
          `background-color: ${color || "initial"};`
        );
      else if (color) updates[id] = oldStyle + ` background-color: ${color};`;
    });
  });
  if (spreadsheet) spreadsheet.setStyle(updates);
  else return updates;
}

export function coordsToIdentifier(colIndex, rowIndex) {
  if (!colIndex && colIndex !== 0) return (rowIndex || 0) + 1;
  colIndex = Math.floor(Math.abs(colIndex)) + 1;
  let col = "",
    rest;
  while (colIndex > 0) {
    rest = (colIndex - 1) % 26;
    col = String.fromCharCode(65 + rest) + col;
    colIndex = parseInt((colIndex - rest) / 26);
  }
  return rowIndex || rowIndex === 0 ? col + (rowIndex + 1) : col;
}

export function identifierToCoords(id) {
  const parsed = id.toUpperCase().split("");
  let col = 0,
    row = 0;

  parsed.forEach((p, i) => {
    if (!isNaN(parseInt(p)))
      row += parseInt(p) * Math.pow(10, parsed.length - i - 1);
    else col = col * 26 + (p.charCodeAt(0) - 65);
  });
  return [col, row - 1];
}
