import { coordsToIdentifier, identifierToCoords } from "./spreadsheetTools";

export const cutSheetArea = (sheet, selection) => {
  return {
    title: sheet.title,
    data: sheet.data
      .filter((r, i) => i >= selection[1] && i <= selection[3])
      .map((r) => r.filter((c, i) => i >= selection[0] && i <= selection[2])),
    columns: sheet.columns.filter(
      (c, i) => i >= selection[0] && i <= selection[2]
    ),
    colWidths: sheet.colWidths.filter(
      (c, i) => i >= selection[0] && i <= selection[2]
    ),
    mergeCells: Object.keys(sheet.mergeCells).reduce((o, k) => {
      const c = identifierToCoords(k);
      if (
        c[0] >= selection[0] &&
        c[0] <= selection[2] &&
        c[1] >= selection[1] &&
        c[1] <= selection[3]
      )
        o[coordsToIdentifier(c[0] - selection[0], c[1] - selection[1])] = [
          sheet.mergeCells[k][0] + c[0] <= selection[2] + 1
            ? sheet.mergeCells[k][0]
            : selection[2] - c[0] + 1,
          sheet.mergeCells[k][1] + c[1] <= selection[3] + 1
            ? sheet.mergeCells[k][1]
            : selection[3] - c[1] + 1,
          sheet.mergeCells[k][2],
        ];
      return o;
    }, {}),
    minDimensions: [
      selection[2] - selection[0] + 1,
      selection[3] - selection[1] + 1,
    ],
  };
};
