export const suggestMentionPeople = (data, searchKeys, searchTerm) => {
  let list = data;
  
  if(searchKeys && searchTerm) {
    list = data
      .filter(el => (
        searchKeys.find(key => (
          el[key]?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        ))
      ));
  }
  
  return list
    .map(el => ({ id: el.uuid, value: el.fullname }))
}