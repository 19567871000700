import { makeStyles } from "@material-ui/core/styles";
import { CELL_HEIGHTS, CELL_WIDTHS } from "@utils";

const useStyle = makeStyles((theme) => ({
  
  cell: {
    width: CELL_WIDTHS.VALUE.SMALL,
    minWidth: CELL_WIDTHS.VALUE.SMALL,
    paddingTop: 24,
  },
  cellChartBlock: {
    width: "100%",
    height: CELL_HEIGHTS.XXLARGE,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.props.border,
    "&:first-child": {
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: theme.props.border,
    }
  },
  cellNarrow: {
    width: CELL_WIDTHS.VALUE.TINY,
    minWidth: CELL_WIDTHS.VALUE.TINY,
  }
}));

export default useStyle;