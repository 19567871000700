import { bool, number, node } from "prop-types";
import { observer } from "mobx-react";
import { useCheckProjectAccess, useProjectEditorStore } from "@hooks";
import { PDF_CONTAINER_ID } from "@utils";
import Grid from "@material-ui/core/Grid";
import { PDF_TOOLBAR_HEIGHT } from "@styles/themes";
import { ToolBar, ThumbnailList, ViewBox } from "./components";
import { useCallback, useState } from "react";
export const PdfEditorContainer = observer(({ 
  children, 
  offsetTop,
  noThumbnailListRescale,
  showPageImages,
  ...toolbarProps
}) => {
  
  const editorStore = useProjectEditorStore();
  
  const [ sidebarVisible, showSidebar ] = useState(true);
  
  const { isSharedVersion, projectUsers } = editorStore;
  
  const { isObserver, hasSharePrivileges } = useCheckProjectAccess(projectUsers);
  
  const handleShowSidebar = useCallback(() => showSidebar(v => !v), []);
  
  return (
    <>
      <ToolBar
        sidebarVisible={sidebarVisible}
        handleShowSidebar={handleShowSidebar}
        hasSharePrivileges={hasSharePrivileges}
        isObserver={isObserver}
        {...toolbarProps}
      />
      <Grid
        item
        container
        wrap="nowrap"
        style={{
          height: `calc(100% - ${
            PDF_TOOLBAR_HEIGHT + (offsetTop || 0)
          }px)`,
        }}
        id={PDF_CONTAINER_ID}
      >
        <ThumbnailList
          visible={sidebarVisible}
          noRescale={noThumbnailListRescale}
          readOnly={isSharedVersion || !isObserver}
          containerId={PDF_CONTAINER_ID}
          showPageImages={showPageImages}
        />
        <ViewBox showPageImages={showPageImages}>
          {children}
        </ViewBox>
      </Grid>
    </>
  );
});

PdfEditorContainer.propTypes = {
  children: node,
  offsetTop: number,
  noThumbnailListRescale: bool,
  proposalSharing: bool,
  noShareActions: bool,
  noThumbButton: bool,
  noFileAppends: bool,
};
