import { bool, number, string, object, func } from "prop-types";
import { usePresetFieldNames } from "@hooks";
import { HtmlParser } from "@utils";
import { isEmptyHtml, parsePrice, roundFloat } from "project-structure";
import classnames from "classnames";

/**
 * Simplified BreakdownRowGroup for PDF export
 * Specific for [ExportView](/#exportview) Component.
 */
export const ExportTask = ({
  translate,
  editorStore,
  settings,
  task,
  tableDepth,
  index,
  allowShowingPrices,
  noContent,
  isSubtotal,
  estimateViewLevel,
  estimateValueLevel,
  visibility,
}) => {
  const {
    useMinMax,
    showPrices,
    currencyObj,
    modifier,
    showBreakdownColumns,
    roundPrice,
    roundHours,
    roundType,
    roundLevel,
    usedRoundDecimals,
  } = settings;
  
  const {
    apply,
    hideMinValues,
    hideMaxValues,
  } = visibility;

  const {
    name,
    content,
    isOpened,
    taskWorkTypes,
    children,
    price,
    priceMax,
    showMaxPrice,
    isTurnedOff,
    hasCustomPrice,
    hasChildrenHours,
    parentHoldsPrice,
  } = task;
  
  const { getFieldName } = usePresetFieldNames(true, editorStore);

  const opened =
    tableDepth > 0 && estimateViewLevel > tableDepth && children?.length && isOpened;
  
  const parsedPrice = roundFloat(price, roundPrice ? 3 : 0, !roundPrice);
  const parsedPriceMax = roundFloat(priceMax, roundPrice ? 3 : 0, !roundPrice);
  
  const showDecimals = (
    (roundType !== 2 || !roundHours)
      ? true
      : (
        typeof roundLevel === "number"
        && typeof task.lvl === "number"
        && task.lvl > roundLevel
      )
  );
  const usedRounding = showDecimals ? undefined : roundHours;
  
  const visibleTaskWorkTypes = taskWorkTypes
    .filter(workType => !apply || !workType.isHidden);
  
  const noValues = (hasCustomPrice && !hasChildrenHours) || parentHoldsPrice;
  
  return (
    <>
      <tr
        className={classnames(`d${tableDepth}`, {
          hover: opened,
          off: isTurnedOff,
        })}
      >
        <td
          className={classnames(
            `d${tableDepth}`,
            isTurnedOff && "off",
            !isSubtotal && tableDepth > 0 && "name"
          )}
          style={{
            textAlign: isSubtotal ? "right" : "left",
            paddingLeft: 16 * (tableDepth || 1),
          }}
        >
          {isSubtotal ? (
            getFieldName("pt-subtotal")
          ) : (
            <>
              {name?.replace(/<(.|\n)*?>/g, "") || translate("common.unnamed")}
            </>
          )}
        </td>
        {showBreakdownColumns &&
          visibleTaskWorkTypes
            ?.map(({ id }) => {
              const values = task.getTotalTimePerWorkType(id);
              const valueMin = roundFloat(
                ((useMinMax ? values.min : values.avg) || 0) / modifier,
                usedRounding, showDecimals, usedRoundDecimals
              );
              const valueMax = roundFloat(
                (values.max || 0) / modifier,
                usedRounding, showDecimals, usedRoundDecimals
              );
              const workTypeIsOff = task.isWorkTypeOff(id);
  
              return (
                <td
                  key={id}
                  className={classnames(
                    `d${tableDepth}`,
                    (isTurnedOff || workTypeIsOff) && "off"
                  )}
                >
                  {!noValues && tableDepth <= estimateValueLevel && (
                    <>
                      {
                        (!apply || !hideMinValues) &&
                        valueMin
                      }
                      {
                        Boolean(
                          useMinMax && valueMax && valueMin !== valueMax &&
                          (!apply || !(hideMinValues || hideMaxValues))
                        ) && "  -  "
                      }
                      {
                        Boolean(
                          useMinMax && valueMax && valueMin !== valueMax && (!apply || !hideMaxValues)
                        ) && valueMax
                      }
                    </>
                  )}
                </td>
              );
            })
        }
        {showBreakdownColumns && allowShowingPrices && showPrices && (
          <td className={classnames(`d${tableDepth}`/*, tableDepth > 0 && "cell-price"*/)}>
            {!parentHoldsPrice && (isSubtotal || tableDepth <= estimateValueLevel) && (
              <p
                className={classnames(
                  `d${tableDepth}`,
                  "price",
                  isTurnedOff && "off",
                )}
              >
                {
                  (!apply || !hideMinValues) &&
                  <>
                    {currencyObj.symbolStart || ""}
                    {parsePrice(parsedPrice)}
                    {currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : ""}
                  </>
                }
                {Boolean(showMaxPrice && (!apply || !(hideMinValues || hideMaxValues))) && "  -  "}
                {
                  showMaxPrice && (!apply || !hideMaxValues) &&
                  <>
                    {currencyObj.symbolStart || ""}
                    {parsePrice(parsedPriceMax)}
                    {currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : ""}
                  </>
                }
              </p>
            )}
          </td>
        )}
      </tr>
      {!isEmptyHtml(content) && !noContent && (
        <tr className={`d${tableDepth}${opened ? " hover" : ""}`}>
          <td
            className={classnames(isTurnedOff ? "off" : false, "desc")}
            colSpan={
              1 +
              visibleTaskWorkTypes.length +
              (allowShowingPrices && showPrices ? 1 : 0)
            }
            style={{ paddingLeft: 16 * tableDepth + 20 }}
          >
            {HtmlParser(content)}
          </td>
        </tr>
      )}

      {opened &&
        children?.map((s, i) => (
          <ExportTask
            translate={translate}
            key={s.id || s.newId}
            index={`${index}.${i + 1}`}
            tableDepth={tableDepth + 1}
            task={s}
            editorStore={editorStore}
            settings={settings}
            visibility={visibility}
            allowShowingPrices={allowShowingPrices}
            estimateViewLevel={estimateViewLevel}
            estimateValueLevel={estimateValueLevel}
          />
        ))}
    </>
  );
};

ExportTask.propTypes = {
  translate: func.isRequired,
  editorStore: object.isRequired,
  task: object.isRequired,
  visibility: object.isRequired,
  settings: object.isRequired,
  index: string,
  tableDepth: number.isRequired,
  allowShowingPrices: bool,
  noContent: bool,
  isSubtotal: bool,
  estimateViewLevel: number,
  estimateValueLevel: number,
};
