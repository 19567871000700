import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  settingsPaper: {
    overflow: "hidden",
    border: `1px solid ${theme.props.border}`,
    borderRadius: 10,
    width: "100%",
    padding: 8,
    marginBottom: 24,
  },
  settingsGrid: {
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
}));

export default useStyle;
