import { fetchData } from "@client";
import { initCompanyPresetsQuery } from "@query";

export async function fetchCompanyPresetsQuery() {
  let res = await fetchData("/preset?limit=1000");

  if (!res.results?.length) {
    await initCompanyPresetsQuery();
    res = await fetchData("/preset?limit=1000");
  }

  return res.results;
}
