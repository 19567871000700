import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  desc: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 8,
    textAlign: "center",
    marginTop: 16,
    width: "100%",
  },
  title: {
    fontSize: 28,
    fontWeight: 900,
    textAlign: "center",
    marginBottom: 32,
    marginTop: 16,
    width: "100%",
  },
  authorize: {
    width: "100%",
    justifyContent: "center",
    marginTop: 32,
  },
  progress: {
    position: "absolute",
    top: "50%",
    marginTop: -20,
  },
}));

export default useStyle;
