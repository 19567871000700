import { bool, string } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { Columns } from "project-structure";
import {
  CostCell,
  HoursCell,
  RateCell,
  TeamCell,
  TimeCell,
  ProfitabilityCell,
  ProfitCell,
  UnitCostCell,
  CompanyCostCell,
} from "./cells";

export const HeaderColumnFactory = observer(({
  columnName,
  isProposal,
  allowEdition,
  showPrices,
  profitabilityMode
}) => {
  const structure = useStructureStore();
  const { settings, visibility } = structure;
  const { currency, companyCurrency, hasTeamMembersCol, hasTimeCol } = settings;
  const {
    apply,
    hideSummaryTime,
    hideSummaryTeam,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryCost,
  } = visibility;
  
  const cellProps = { isProposal, allowEdition };

  switch (columnName) {
    case Columns.TEAM:
      return (isProposal && apply && hideSummaryTeam) || !hasTeamMembersCol
        ? <></>
        : <TeamCell {...cellProps} />
    
    case Columns.TIME:
      return (isProposal && apply && hideSummaryTime) || !hasTimeCol
        ? <></>
        : <TimeCell {...cellProps} />
    
    case Columns.RATE:
      return (isProposal && apply && hideSummaryRate) || !showPrices
        ? <></>
        : <RateCell {...cellProps} />
    
    case Columns.HOURS:
      return isProposal && apply && hideSummaryHours
        ? <></>
        : <HoursCell {...cellProps} />
    case Columns.COST:
      return (isProposal && apply && hideSummaryCost) || !showPrices
        ? <></>
        : <CostCell {...cellProps} profitabilityMode={profitabilityMode} />
      
    case Columns.C_COST:
      return showPrices
        && <CompanyCostCell {...cellProps} fieldName="pt-profit" />
    
    case Columns.C_UNIT_COST_INNER:
      return <UnitCostCell {...cellProps} company />
      
    case Columns.C_UNIT_COST:
      return currency === companyCurrency
        ? <></>
        : <UnitCostCell {...cellProps} />
    
    case Columns.C_PROFIT:
      return <ProfitCell {...cellProps} />
    
    case Columns.C_PROFITABILITY:
      return <ProfitabilityCell {...cellProps} />
  }
});

HeaderColumnFactory.propTypes = {
  columnName: string,
  isProposal: bool,
  allowEdition: bool,
  showPrices: bool,
  profitabilityMode: bool,
};
