import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Dialog } from "@components";
import { useTranslation } from "react-i18next";
import { ContactForm } from "@forms";
import { sendContactMessageQuery } from "@query";

export const Contact = observer(({ open, onClose, defaultMessage, onSend }) => {
  const { t } = useTranslation();

  const sendFeedback = async ({ name, company, email, phone, message }) => {
    await sendContactMessageQuery(
      `Name: ${name}\n Company: ${company}\n Email: ${email}\n ${
        phone ? `Phone: ${phone}\n` : ""
      }\n\n\n${message}`
    );
    if (onSend) onSend();
    onClose();
  };

  return (
    <Dialog
      open={open}
      // width="80vw"
      title={t("forms.contact.title")}
      onClose={onClose}
    >
      <ContactForm onSubmit={sendFeedback} defaultMessage={defaultMessage} />
    </Dialog>
  );
});

Contact.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultMessage: PropTypes.string,
  onSend: PropTypes.func,
};
