// Material components
import { SvgIcon } from "@material-ui/core";

export function Header1(props) {
  return (
    <SvgIcon {...props} viewBox="-2 0 20 12">
      <path xmlns="http://www.w3.org/2000/svg" d="M0.519531 11.3107V0.703125H2.49998V5.00965H6.51953V0.703125H8.49998V11.3107H6.51953V6.9901H2.49998V11.3107H0.519531ZM13.5 11.3107V2.68358H11.5V0.703125H15.4946V11.3107H13.5Z" fill="currentColor"/>
    </SvgIcon>
  );
}
