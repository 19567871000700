export const parsePackageName = (c) => {
  if (c.toLowerCase() === "package_pro") return "package_business";
  if (c.toLowerCase() === "package_business") return "package_pro";
  
  if (c.toLowerCase().indexOf("free") >= 0) return "package_free";
  if (c.toLowerCase().indexOf("pro") >= 0) return "package_pro";
  if (c.toLowerCase().indexOf("starter") >= 0) return "package_starter";
  if (c.toLowerCase().indexOf("mini") >= 0) return "package_mini";
  if (c.toLowerCase().indexOf("business") >= 0) return "package_business";
  if (c.toLowerCase().indexOf("enterprise") >= 0) return "package_enterprise";
  return "default";
};
