// Material components
import { SvgIcon } from "@material-ui/core";

export function Header3(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 12">
      <path xmlns="http://www.w3.org/2000/svg" d="M0.648438 11.3112V0.689453H2.62889V5.0101H6.52126V0.689453H8.50171V11.3112H6.52126V6.99055H2.62889V11.3112H0.648438ZM11.5017 11.3112V9.31663H17.3745V6.99055H13.5017V5.0101H17.3745V2.68403H11.5017V0.689453H17.3745C17.9369 0.689453 18.4099 0.882336 18.7936 1.2681C19.1773 1.65389 19.3691 2.12586 19.3691 2.68403V9.31663C19.3691 9.87479 19.1762 10.3468 18.7905 10.7326C18.4047 11.1183 17.9327 11.3112 17.3745 11.3112H11.5017Z" fill="#888888"/>
    </SvgIcon>
  );
}
