export const sampleColors = [
  "#3A3C4F",
  "#4098F4",
  "#F5F6FA",
  "#ECF5FE",
  "#F5FAFE",
  "#FFFFFF",
  "#FF6F6F",
  "#C6FB31",
  "#3FC865",
  "#F60A0A",
  "#57DFF1",
  "#F59158",
  "#4EF0A2",
  "#F937A0",
  "#7949DF",
  "#49E9CC",
];