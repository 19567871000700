import { useMemo, useState } from "react";
import { bool, number, object, string } from "prop-types";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToHorizontalAxis, restrictToFirstScrollableAncestor, snapCenterToCursor } from "@dnd-kit/modifiers";
import { CELL_WIDTHS, MIN_FILLER_MONTHS } from "@utils";
import { Grid } from "@material-ui/core";
import { SectionCell } from "./components/SectionCell";
import { TimelineSectionBar } from "./components/TimelineSectionBar/TimelineSectionBar";
import { HeaderRow, TitleCell, VisibilityButton } from "@components";
import classnames from "classnames";

export const TimelineSectionsRow =  observer(({
  index,
  section,
  disabled,
  tableContainerIdentifier,
}) => {
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { totalDevelopmentTime } = structure;
  const { apply } = structure.visibility;
  const {
    id,
    timelineStartWeek,
    timelineStartMonth,
    hideOnTimeline,
  } = section;
  
  const [isDragging, setIsDragging] = useState();
  
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideOnTimeline && "transparent-2") || undefined
  ), [visibilityMode, apply, hideOnTimeline]);
  
  const handleDragStart = () => setIsDragging(true);
  const handleDragCancel = () => setIsDragging(false);
  const handleDragEnd = ({ over }) => {
    section.setTimelineStartWeek(over?.data.current.week);
    setIsDragging(false);
  };
  
  const scrollHandler = (el) => {
    return el?.id === tableContainerIdentifier;
  }
  
  return (
    <HeaderRow
      useExpandCell
      // useFiller
      altBackground
      size="medium"
      className={classnames("preset-paperTimeline", className)}
      // expandCellPresetClass="preset-timelineSubHeader"
    >
      <TitleCell
        pinnedLeft={1}
        maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
        minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
        size="medium"
        allowOverflowDisplay
        actions={
          visibilityMode &&
          <VisibilityButton hidden={hideOnTimeline} onChange={section.setHideOnTimeline} />
        }
      />
      <Grid item container wrap="nowrap">
        <DndContext
          collisionDetection={rectIntersection}
          sensors={sensors}
          autoScroll={{ layoutShiftCompensation: { x: false, y: true }, canScroll: scrollHandler }}
          modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor, snapCenterToCursor]}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          {
            [...new Array(Math.max(totalDevelopmentTime, MIN_FILLER_MONTHS)).keys()]
              .map((_, i) => (
                <SectionCell
                  key={i}
                  sectionId={id}
                  monthIndex={i}
                  startWeek={timelineStartWeek}
                >
                  {
                    timelineStartMonth === i &&
                    <TimelineSectionBar index={index} section={section} disabled={disabled} isPlaceholder={isDragging} />
                  }
                </SectionCell>
              ))
          }
          <DragOverlay>
            {
              isDragging &&
              <TimelineSectionBar index={index} section={section} isDraggable />
            }
          </DragOverlay>
        </DndContext>
      </Grid>
    </HeaderRow>
  );
});

TimelineSectionsRow.propTypes = {
  index: number,
  section: object.isRequired,
  disabled: bool,
  tableContainerIdentifier: string,
};