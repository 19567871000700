import { arrayOf, bool, func, number, object, shape } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Autocomplete } from "@components";
import { capitalize, CircularProgress, Divider, Grid } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const teamUserInfoFormSchema = (t, defaultValues) =>
  yup.object().shape({
    teamRole: yup.number().nullable(true).default(defaultValues?.teamRole),
    technicalStack: yup
      .array()
      .of(yup.number())
      .default(defaultValues?.technicalStack || []),
    professionalLevel: yup
      .number()
      .nullable(true)
      .default(defaultValues?.professionalLevel),
  });

export const TeamUserInfoForm = ({
  onSubmit,
  onCancel,
  defaultValues,
  allowRoleChange,
  dataLoading,
  teamRoles,
  professionalLevels,
  stackTypes,
}) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    reset: resetForm,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(teamUserInfoFormSchema(t, defaultValues)),
    defaultValues: defaultValues,
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors, reset } = result;

      if (reset) {
        resetForm(defaultValues);
        return;
      }

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="teamUserInfo form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container>
        <Controller
          name="teamRole"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Autocomplete
              id="teamRoleSelect"
              value={value}
              options={teamRoles}
              name={name}
              idKey="id"
              labelKey="name"
              inputLabel={t("views.team.role")}
              fullWidth
              disabled={!allowRoleChange}
              onChange={onChange}
              size="small"
              dataLoading={dataLoading}
            />
          )}
        />
        <Controller
          name="professionalLevel"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Autocomplete
              id="professionalLevelSelect"
              value={value}
              options={professionalLevels}
              name={name}
              fullWidth
              idKey="id"
              labelKey="name"
              inputLabel={t("views.team.level")}
              labelFormat={(l) => capitalize(l)}
              onChange={onChange}
              size="small"
              dataLoading={dataLoading}
            />
          )}
        />
        <Controller
          name="technicalStack"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Autocomplete
              id="technicalStackSelect"
              value={value}
              options={stackTypes}
              name={name}
              fullWidth
              multiple
              className="h-max"
              idKey="id"
              labelKey="name"
              inputLabel={t("views.team.stack")}
              onChange={onChange}
              size="small"
              dataLoading={dataLoading}
            />
          )}
        />
      </Grid>

      <Divider className="mt-2 -mx-6 mb-6" />

      <Grid item container justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          onClick={onCancel}
          className="mr-2"
        >
          {t("common.cancel")}
        </Button>
        <Button
          isSubmit
          name="submit"
          variant="contained"
          endIcon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {t("common.save")}
        </Button>
      </Grid>
    </form>
  );
};

TeamUserInfoForm.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  defaultValues: shape({
    teamRole: number,
    technicalStack: arrayOf(number),
    professionalLevel: number,
  }),
  allowRoleChange: bool,
  dataLoading: bool,
  teamRoles: arrayOf(object),
  professionalLevels: arrayOf(object),
  stackTypes: arrayOf(object),
};
