import { useState } from "react";
import {
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from "prop-types";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@material-ui/core";
import { Button } from "@components";
import { pricing } from "@paths";
import { CouponDialog } from "../CouponDialog";
import useStyle from "./SubscriptionSections.style";
import classnames from "classnames";
import { roundFloat } from "project-structure";

export const SectionPlan = observer(({
  name,
  isLoading,
  hasSubscription,
  duration,
  estimates,
  users,
  price,
  discountPrice,
  coupon,
  setCoupon,
}) => {

    const { t } = useTranslation();
    const classes = useStyle();
    const { userStore } = useStores();

    const [couponDialogOpened, openCouponDialog] = useState(false);

    return (
      <Grid
        item
        container
        xs={12}
        md={4}
        className={`${classes.box} ${classes.bordered}`}
      >
        <p className="font-bold text-xl">
          {t("views.subscription.titles.current")}
        </p>
        {isLoading ? (
          <CircularProgress
            aria-label="progress indicator"
            size={40}
            color="primary"
            className={classes.spinner}
          />
        ) : (
          <Grid item container direction="column">
            <p className="color-primary text-3xl">{name}</p>
            {!userStore.hasTrialLeft && (
              <p className="transparent-2">
                {!hasSubscription
                  ? t("billing.forever")
                  : t(`billing.per_${duration}_fancy`)}
              </p>
            )}
            <p className="transparent-2">
              {hasSubscription &&
                `${users} ${t("common.users")}, ${estimates} ${t(
                  "common.estimates"
                )}`}
            </p>
            <p className="mt-2">
              <span className={classnames("font-bold relative", discountPrice ? "text-xl" : "text-2xl")}>
                {"$"}
                {roundFloat(price || 0, 0, true)}
                {
                  (typeof discountPrice === "number") &&
                  <div className={classes.discountLine} />
                }
              </span>
              {
                (typeof discountPrice === "number") &&
                <span className="font-bold text-2xl ml-1">
                  {"$"}
                  {roundFloat(discountPrice || 0, 0, true)}
                </span>
              }
              {Boolean(duration) && (
                <span className="font-bold text-lg">
                  {" / "}
                  {t(`time.${duration}`)}
                </span>
              )}
            </p>
          </Grid>
        )}
        <Grid item container justifyContent="space-between" spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              to={pricing}
              disabled={isLoading}
            >
              {t("views.subscription.actions.change_plan")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={() => openCouponDialog(true)}
              disabled={isLoading}
            >
              {t("views.subscription.actions.add_coupon")}
            </Button>
          </Grid>
        </Grid>
        <CouponDialog
          open={couponDialogOpened}
          onClose={() => openCouponDialog(false)}
          coupon={coupon}
          setCoupon={(c) => setCoupon(c)}
        />
      </Grid>
    );
  }
);

SectionPlan.propTypes = {
  name: string.isRequired,
  isLoading: bool,
  hasSubscription: bool,
  duration: oneOf(["month", "year"]),
  estimates: number,
  users: number,
  price: number,
  discountPrice: number,
  coupon: oneOfType([string, object]),
  setCoupon: func,
};
