import { arrayOf, func, number, oneOf, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { HtmlParser } from "@utils";
import { Accordion, Button, Dialog } from "@components";
import { Grid } from "@material-ui/core";
import { Rocket } from "@assets";
import { ErrorOutlined, WarningOutlined } from "@material-ui/icons";

export const Announcements = ({
  updates,
  onClose,
  variant,
}) => {
  
  const {t} = useTranslation();
  
  return (
    <Dialog
      open
      onClose={onClose}
      title={(
        <Grid item container alignItems="center" justifyContent="center">
          {
            variant === "info" || (
              variant === "warning"
                ? <WarningOutlined className="text-2xl mr-2" />
                : <ErrorOutlined className="text-2xl mr-2" />
            )
          }
          <h2>
            {
              variant === "info"
                ? t("alerts.announcements.title")
                : t(`common.${ variant }`)
            }
          </h2>
        </Grid>
      )}
      width={680}
      variant={variant === "info" || variant}
    >
      {
        variant === "info" &&
        <Grid item container alignItems="center" justifyContent="center" wrap="nowrap" className="mb-6">
          <Rocket className="text-2xl rotate-30 color-primary mr-2" />
          <span className="text-md font-bold">
          {t(`alerts.announcements.subtitle${updates.length === 1 ? "_singular" : ""}`)}
        </span>
        </Grid>
      }
    
      <Grid item container>
        {
          updates.map((u) => (
            <Accordion
              key={u.id}
              defaultExpanded={updates.length === 1}
              title={u.title}
              titleClassName="pl-2"
              variant={variant}
              noSummary={updates.length === 1}
            >
              {/*{*/}
              {/*  u.thumb &&*/}
              {/*  <img alt="" src={u.thumb} className="w-full mt-2 mb-4 rounded-2" />*/}
              {/*}*/}
              {HtmlParser(u.content)}
            </Accordion>
          ))
        }
      </Grid>
      
      {
        variant === "info" &&
        <Grid item container direction="column" alignItems="center" className="mt-8">
          <h3>
            {t("alerts.announcements.demo")}
          </h3>
          <Button
            variant="contained"
            color="primary"
            className="mt-4"
            href={process.env.REACT_APP_DEMO_BOOK_LINK}
            onClick={onClose}
          >
            {t("views.subscription.details.book_demo")}
          </Button>
        </Grid>
      }
    </Dialog>
  )
}

Announcements.propTypes = {
  timestamp: number,
  updates: arrayOf(shape({
    id: number.isRequired,
    title: string.isRequired,
    content: string,
    createdAt: string,
    updatedAt: string,
  })),
  onClose: func,
  variant: oneOf(["info", "warning", "danger"]),
}