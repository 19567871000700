import { useMemo, useEffect } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import {
  Button,
  InfoLabel,
  ControlledCheckboxGroup,
  ControlledCheckbox,
} from "@components";
import { IssueTypeSelect } from "./components/IssueTypeSelect";
import { CircularProgress, Divider, Grid, Tooltip } from "@material-ui/core";

const newProjectFormSchema = (t) =>
  yup
    .object()
    .shape({
      workTypes: yup
        .array()
        .of(yup.string().required(t("errors.project.workType.required")))
        .min(1, t("errors.project.workType.required")),
      mappingSections: yup
        .array()
        .of(yup.string().required(t("errors.bt.mappingSections.required")))
        .min(1, t("errors.bt.mappingSections.required")),
      mergeWorkTypes: yup.bool(),
      level0Used: yup.bool(),
      level0Map: yup.string().when("level0Used", {
        is: true,
        then: (f) => f.required(t("errors.jira.issueType.required")),
      }),
      level1Used: yup.bool(),
      level1Map: yup.string().when("level1Used", {
        is: true,
        then: (f) => f.required(t("errors.jira.issueType.required")),
      }),
      level2Used: yup.bool(),
      level2Map: yup.string().when("level2Used", {
        is: true,
        then: (f) => f.required(t("errors.jira.issueType.required")),
      }),
      level3Used: yup.bool(),
      level3Map: yup.string().when("level3Used", {
        is: true,
        then: (f) => f.required(t("errors.jira.issueType.required")),
      }),
    })
    .test("at-least-one-level", function (value) {
      return (
        value.level0Used ||
        value.level1Used ||
        value.level2Used ||
        value.level3Used
      );
    });

export const IssueMappingForm = observer(({
  onSubmit,
  issueTypes,
  selectedVersion,
  projectViewLevel = 3
}) => {
  const { t } = useTranslation();
  const defaultValues = useMemo(
    () => ({
      mergeWorkTypes: true,
      level0Used: true,
      workTypes: selectedVersion?.workTypes?.map((wT) => wT.id) || [],
      mappingSections:
        selectedVersion?.sections?.filter((ob) => !ob.isTurnedOff )
          .map((wT) => wT.id) || [],
    }),
    []
  );

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset: resetForm,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(newProjectFormSchema(t)),
    defaultValues,
  });

  const levelsUsed = [
    watch("level0Used"),
    watch("level1Used"),
    watch("level2Used"),
    watch("level3Used"),
  ];

  const levelMaps = [
    watch("level0Map"),
    watch("level1Map"),
    watch("level2Map"),
    watch("level3Map"),
  ];

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors, reset } = result;

      if (reset) {
        resetForm(defaultValues);
        return;
      }

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  useEffect(() => {
    const isAnyCheckboxChecked = levelsUsed.some((checkbox) => checkbox);

    if (!isAnyCheckboxChecked) {
      setError("checkboxGroup", {
        type: "custom",
        message: t("errors.bt.issueType.required"),
      });
    } else {
      clearErrors("checkboxGroup");
    }
  }, [levelsUsed, setError, clearErrors]);

  return (
    <form
      autoComplete="on"
      aria-label="companyDetails form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container direction="column" wrap="nowrap" className="p-6">
        <InfoLabel spacing={8} label="views.bt.select_sections" />
        {Boolean(selectedVersion.sections) && (
          <ControlledCheckboxGroup
            control={control}
            name="mappingSections"
            options={selectedVersion?.sections?.filter((ob) => !ob.isTurnedOff )}
            multiple
            checked
          />
        )}
        <InfoLabel spacing={8} label="views.bt.levels_of_estimate" />
        {[0, 1, 2, 3].map((level) => (
          <Grid
            key={level}
            item
            container
            justifyContent="space-between"
            alignItems="center"
            className="mb-2"
          >
            <Tooltip
              title={t("views.bt.no_view_level", {
                levelName: t(
                  `proposal_parts.modules_levels.${level}`
                ).toLowerCase(),
              })}
              disableHoverListener={level <= projectViewLevel}
            >
              <Grid item>
                <ControlledCheckbox
                  name={`level${level}Used`}
                  control={control}
                  label={t(`proposal_parts.modules_levels.${level}`)}
                  disabled={level > projectViewLevel}
                />
              </Grid>
            </Tooltip>

            {levelsUsed[level] && (
              <Grid item>
                <IssueTypeSelect
                  control={control}
                  level={level}
                  issueTypes={issueTypes}
                  parentIssueLevels={levelMaps.filter((_, i) => i < level)}
                />
              </Grid>
            )}
          </Grid>
        ))}
        {errors.checkboxGroup && (
          <span className="mt-2 color-error">
            {errors.checkboxGroup.message}
          </span>
        )}{" "}
        <InfoLabel spacing={8} label="views.bt.work_type" />
        {Boolean(selectedVersion?.workTypes) && (
          <ControlledCheckboxGroup
            control={control}
            name="workTypes"
            options={selectedVersion.workTypes}
            multiple
            checked
          />
        )}
        <InfoLabel spacing={8} label="views.bt.multiple_work_types" />
        <ControlledCheckboxGroup
          control={control}
          name="mergeWorkTypes"
          options={[
            { id: true, name: t("views.bt.single_ticket_work_type") },
            { id: false, name: t("views.bt.new_task_work_type") },
          ]}
        />
        <Divider className="mt-2 -mx-6 mb-6" />
        <Grid item container justifyContent="flex-end">
          <Button
            isSubmit
            name="submit"
            variant="contained"
            endIcon={
              isSubmitting ? (
                <CircularProgress
                  aria-label="progress indicator"
                  size={24}
                  color="inherit"
                />
              ) : undefined
            }
          >
            {t("common.export")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

IssueMappingForm.propTypes = {
  onSubmit: func,
  projectViewLevel: number,
  selectedVersion: Object,
  issueTypes: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      lvl: number.isRequired,
      subtask: bool,
    })
  ).isRequired,
};
