import { useEffect, useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTableOverflowChecker, useStructureStore } from "@hooks";
import ResizeObserver from "react-resize-observer";
import { Grid, TableContainer } from "@material-ui/core";
import { TableHeader } from "../TableHeader/TableHeader";
import useStyle from "./Section.style";
import useEditorStyle from "../../../commonStyles.style";
import { calculateMaxBreakdownTitleCellWidth } from "@utils";
import classnames from "classnames";
import { BreakdownRowGroup } from "../BudgetTrackingBreakdownRowGroup/BudgetTrackingBreakdownRowGroup";

export const Section = observer(({
  section,
  index,
  allowEdition,
  isProposal,
  isLibrary,
  isSellerOrClient,
  isEditorOrClient,
  useClientActions,
  ...otherProps
}) => {
  const structure = useStructureStore();
  const classes = useStyle();
  const commonClasses = useEditorStyle();

  const {
    useMinMax,
    titleCellWidth,
    descriptionCellWidth,
    showPrices,
    showBreakdownColumns,
    descriptionMode,
    valueLevel,
  } = structure.settings;

  const { taskWorkTypes, sectionModulesHaveChildren, childrenWithContent } =
    section;

  const tableContainerIdentifier = useMemo(
    () => `breakdownContainer${index}`,
    []
  );
  const tableIdentifier = useMemo(() => `tableHeader${index}`, []);
  
  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);
  
  useEffect(() => {
    onResize();
  }, [useMinMax, titleCellWidth, descriptionCellWidth]);

  const pricesVisible = useMemo(
    () => showBreakdownColumns && showPrices && isSellerOrClient,
    [showPrices, isSellerOrClient, showBreakdownColumns]
  );

  const useExpandCell = useMemo(
    () => !isProposal || sectionModulesHaveChildren,
    [isProposal, sectionModulesHaveChildren]
  );

  const useDescriptionCell = useMemo(
    () => !isProposal || Boolean(section.content) || childrenWithContent > 0,
    [isProposal, childrenWithContent]
  );

  const maxTitleCellWidth = useMemo(
    () =>
      descriptionMode === 3 && useDescriptionCell
        ? titleCellWidth
        : calculateMaxBreakdownTitleCellWidth(
            valueLevel === -1 ? 0 : taskWorkTypes.length,
            pricesVisible,
            useClientActions,
            useMinMax
          ),
    [
      descriptionMode,
      titleCellWidth,
      taskWorkTypes,
      pricesVisible,
      useClientActions,
      useMinMax,
      valueLevel,
    ]
  );

  const maxDescriptionCellWidth = useMemo(
    () =>
      descriptionMode < 3 || !useDescriptionCell
        ? 0
        : calculateMaxBreakdownTitleCellWidth(
            valueLevel === -1 ? 0 : taskWorkTypes.length,
            pricesVisible,
            useClientActions,
            useMinMax,
            titleCellWidth
          ),
    [
      descriptionMode,
      taskWorkTypes,
      pricesVisible,
      useClientActions,
      useMinMax,
      titleCellWidth,
      valueLevel,
    ]
  );

  const childProps = {
    isSellerOrClient,
    isEditorOrClient,
    useClientActions,
    allowEdition,
    isProposal,
    isLibrary,
    useExpandCell,
    useDescriptionCell,
    maxTitleCellWidth,
    maxDescriptionCellWidth,
    pricesVisible,
    ...otherProps,
  };

  return (
    <Grid item container>
      <TableContainer
        ref={ref}
        onScroll={onScroll}
        className={classnames(
          "pb-3",
          classes.root,
          isTableContentOverflowing && commonClasses.overflows
        )}
        id={tableContainerIdentifier}
      >
        <Grid container role="rowgroup" direction="column">
          <ResizeObserver onResize={onResize} />
          <TableHeader
            {...childProps}
            section={section}
            tableIdentifier={tableIdentifier}
            isTableContentOverflowing={isTableContentOverflowing}
            scrollButtonProps={scrollButtonProps}
          />
          {
            <BreakdownRowGroup
              {...childProps}
              sectionIndex={index}
              element={section}
              isLastGroupRow
              tableDepth={0}
              isFirst={index === 0}
            />
          }
        </Grid>
      </TableContainer>
    </Grid>
  );
});

Section.props = {
  section: object.isRequired,
  index: number.isRequired,
  hasEditorPrivileges: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  isSellerOrClient: bool,
  blockExpansions: bool,
  displayStatus: bool,
  allowStatusChange: bool,
  allowEdition: bool,
};
