import { arrayOf, bool, func, object, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useCheckProjectAccess } from "@hooks";
import {
  AvatarList,
  Checkbox,
  TableCell,
  TableRow,
  TagList,
} from "@components";
import { flattenProjectUsers, universalDateParser } from "@utils";
import { ArchivedMenu } from "./ArchivedMenu";

export const ArchivedRow = ({
  uuid,
  name,
  tags,
  createdAt,
  dueOn,
  users,
  onRestore,
  onSelect,
  onRemove,
  selected = false,
}) => {
  const { t } = useTranslation();
  const { isOwner } = useCheckProjectAccess(users);

  const handleSelect = () => {
    if (isOwner) onSelect(uuid);
  };

  return (
    <TableRow
      hover
      key={uuid}
      onClick={handleSelect}
      selected={selected}
      className="cursor-pointer"
    >
      <TableCell justifyContent="center" width={26} sticky={0}>
        {isOwner && (
          <Checkbox
            name={uuid}
            aria-label="select row"
            checked={selected}
            color="primary"
            onChange={handleSelect}
            value="true"
          />
        )}
      </TableCell>
      <TableCell width={431} /*sticky={26 + 24 + 1}*/ noTextOverflow>
        {name.replace(/<(.|\n)*?>/g, "")}
      </TableCell>
      {/*<TableCell width={130}>{category ? category.title : ""}</TableCell>*/}
      <TableCell width={320}>
        <TagList tags={tags.slice(0, 3)} />
        {tags?.length > 3 && (
          <p className="color-primary text-xs">
            {t("common.more_items", { items: tags.length - 3 })}
          </p>
        )}
      </TableCell>
      <TableCell width={80}>
        {format(universalDateParser(createdAt), "dd.MM.yyyy")}
      </TableCell>
      <TableCell width={80}>
        {format(universalDateParser(dueOn), "dd.MM.yyyy")}
      </TableCell>
      <TableCell width={130}>
        <AvatarList users={flattenProjectUsers(users)} />
      </TableCell>
      <TableCell justifyContent="center" width={34}>
        <ArchivedMenu
          uuid={uuid}
          onRestore={onRestore}
          onRemove={onRemove}
          isOwner={isOwner}
        />
      </TableCell>
    </TableRow>
  );
};

ArchivedRow.propTypes = {
  uuid: string.isRequired,
  name: string.isRequired,
  category: object,
  tags: arrayOf(object),
  createdAt: string,
  dueOn: string,
  users: shape({
    author: object,
    owner: object,
    observer: arrayOf(object),
  }),

  selected: bool,
  onRestore: func.isRequired,
  onSelect: func.isRequired,
  onRemove: func.isRequired,
};
