import { useCallback, useState } from "react";
import { string, arrayOf, shape, func } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import {
  useActiveProjectStore,
  useProjectCreatorStore,
  useStores,
  useFeatureGuardian,
} from "@hooks";
import { LOCKED_FEATURES, PROJECT_STATUS } from "@utils";
import Grid from "@material-ui/core/Grid";
import { AddOutlined } from "@material-ui/icons";
import { PageTitle, ActionButton, Alert, Tag } from "@components";
import { Column } from "./components";
import { NewProjectDialog } from "@dialogs";
import useStyle from "./Active.style";
import { Container } from "@material-ui/core";

export const ActiveProjectsView = observer(
  ({ columns, handleColumnNameChange }) => {
    const { userStore } = useStores();
    const activeProjects = useActiveProjectStore();
    const creator = useProjectCreatorStore();
    const { t } = useTranslation();
    const classes = useStyle(isMobile);

    const [alertText, setAlertText] = useState("");
    const [showNewProjectModal, openNewProjectModal] = useState(false);

    const { premiumEstimate } = userStore.subscriptionInfo;

    const { checkAccess, FeatureAlert } = useFeatureGuardian(
      LOCKED_FEATURES.ESTIMATION
    );

    const toNewProject = useCallback(async (name) => {
      const allowed = await checkAccess();
      if (!allowed) return;

      creator.setStatus(name);
      openNewProjectModal(true);
    }, []);

    const handleNewProjectButtonClick = () => toNewProject(PROJECT_STATUS.TODO);

    return (
      <>
        <PageTitle
          translate
          title="active"
          titleTag={
            <Tag
              text={`${activeProjects?.projects.length}/${
                premiumEstimate < 0 ? "∞" : premiumEstimate
              }`}
              className="ml-3 mt-1"
            />
          }
        >
          <ActionButton
            icon={<AddOutlined />}
            onClick={handleNewProjectButtonClick}
          >
            {t("views.active.new_project")}
          </ActionButton>
        </PageTitle>
        <Container>
          <Grid
            item
            container
            spacing={4}
            justifyContent="center"
            alignItems="flex-start"
            className={isMobile ? classes.mobileColumnContainer : classes.root}
          >
            {columns.map(({ code, color, name }) => (
              <Column
                key={code}
                code={code}
                name={name}
                color={color}
                onNewProjectButtonClick={toNewProject}
                onNameChange={handleColumnNameChange}
              />
            ))}
          </Grid>
          {showNewProjectModal && (
            <NewProjectDialog
              open={showNewProjectModal}
              onClose={() => openNewProjectModal(false)}
              useTemplatesDialog
            />
          )}
          <FeatureAlert />
          <Alert
            title={alertText}
            isOpen={!!alertText}
            onAccept={() => setAlertText("")}
            acceptText={t("common.close")}
          />
        </Container>
      </>
    );
  }
);

ActiveProjectsView.propTypes = {
  handleColumnNameChange: func.isRequired,
  columns: arrayOf(
    shape({
      code: string.isRequired,
      color: string.isRequired,
      name: string,
    })
  ).isRequired,
};
