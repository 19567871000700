import { arrayOf, object, string } from "prop-types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import useStyle from "./FeaturesList.style";
import { SUBSCRIPTION_FEATURES } from "@utils";
import { FeaturesTable } from "@views/Utility/Pricing/components/FeaturesTable/FeaturesTable";

export const FeaturesList = ({ hashLink }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Grid item container direction="column" id={hashLink}>
      <h1 className={classes.title}>
        {t("views.subscription.plan.features.comparison")}
      </h1>

      <h1 className={classes.sectionTitleFirst}>
        {t("views.subscription.plan.features.estimation")}
      </h1>
      <FeaturesTable features={SUBSCRIPTION_FEATURES.estimation} />

      <h1 className={classes.sectionTitle}>
        {t("views.subscription.plan.features.sales")}
      </h1>
      <FeaturesTable features={SUBSCRIPTION_FEATURES.sales} />

      <h1 className={classes.sectionTitle}>
        {t("views.subscription.plan.features.budget")}
      </h1>
      <FeaturesTable features={SUBSCRIPTION_FEATURES.budget} />

      <h1 className={classes.sectionTitle}>
        {t("views.subscription.plan.features.consulting")}
      </h1>
      <FeaturesTable features={SUBSCRIPTION_FEATURES.consulting} />
    </Grid>
  );
};

FeaturesList.propTypes = {
  hashLink: string,
  plans: arrayOf(object),
  features: arrayOf(object),
};
