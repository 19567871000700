import { useState } from "react";
import { bool, string, number, func, arrayOf, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  demoteUserQuery,
  editTeamMemberQuery,
  promoteUserQuery,
  removeTeamMemberQuery,
} from "@query";
import { useStores } from "@hooks";
import { TeamUserInfoForm } from "@forms";
import { MoreVert } from "@material-ui/icons";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Alert, Dialog, MenuButton, PopMenu } from "@components";
import { User, Trash, Pencil} from "@assets";

export const MemberMenu = observer(({
  userId,
  memberId,
  name,
  email,
  professionalLevels,
  stackTypes,
  teamRoles,
  professionalLevel,
  technicalStack,
  teamRole,
  isAdmin: isUserAdmin,
  reloadData,
  totalAdmins,
}) => {
  const { t } = useTranslation();
  const { userStore } = useStores();

  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, show] = useState(false);
  const [editionModalVisible, openEditionModal] = useState(false);
  const [removeAlertVisible, openRemoveAlert] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);

  const { isAdmin, isModerator, data } = userStore;

  const saveMemberData = async ({
    teamRole,
    professionalLevel,
    technicalStack,
  }) => {
    await editTeamMemberQuery(
      memberId,
      teamRole,
      professionalLevel,
      technicalStack
    );
    openEditionModal(false);
    show(false);
    reloadData(email);
  };

  const removeMember = async () => {
    setAlertLoading(true);
    await removeTeamMemberQuery(userId);
    openRemoveAlert(false);
    setAlertLoading(false);
    reloadData();
  };

  const handlePromoteUser = async () => {
    await promoteUserQuery(memberId);
    reloadData(email);
  };

  const handleDemoteUser = async () => {
    await demoteUserQuery(memberId);
    reloadData(email);
  };

  const handleRemove = () => {
    openRemoveAlert(true);
  };

  const handleEdit = async () => {
    openEditionModal(true);
  };

  const closeEditionModal = () => {
    openEditionModal(false);
  };

  const handleDialog = () => (isModerator ? show(!visible) : handleEdit());

  return (
    <>
      <IconButton
        color="primary"
        ref={setAnchorEl}
        onClick={handleDialog}
        aria-label="menu"
      >
        {isModerator ? <MoreVert /> : <Pencil />}
      </IconButton>
      <PopMenu
        key={`${memberId}popmenu`}
        show={visible}
        anchor={anchorEl}
        onClickAway={() => show(false)}
        className="min-h-max"
        style={{ width: 230 }}
        placement="bottom-end"
      >
        <MenuButton icon={<Pencil />} onClick={handleEdit}>
          {t("common.edit")}
        </MenuButton>
        {isAdmin && (!isUserAdmin || totalAdmins > 1) && (
          <MenuButton
            icon={<User />}
            onClick={isUserAdmin ? handleDemoteUser : handlePromoteUser}
          >
            {t(`views.team.${isUserAdmin ? "change_user" : "change_admin"}`)}
          </MenuButton>
        )}
        {email !== data.email && (isAdmin || !isUserAdmin) && (
          <MenuButton icon={<Trash color="error" />} onClick={handleRemove}>
            {t("common.delete")}
          </MenuButton>
        )}
      </PopMenu>
      {editionModalVisible && (
        <Dialog
          open={editionModalVisible}
          width={395}
          onClose={closeEditionModal}
          title={t("views.team.modal_title")}
        >
          <TeamUserInfoForm
            onSubmit={saveMemberData}
            onCancel={closeEditionModal}
            allowRoleChange={isModerator}
            professionalLevels={professionalLevels}
            stackTypes={stackTypes}
            teamRoles={teamRoles}
            defaultValues={{ professionalLevel, teamRole, technicalStack }}
          />
        </Dialog>
      )}
      {removeAlertVisible && (
        <Alert
          isOpen={removeAlertVisible}
          onAccept={removeMember}
          onCancel={() => openRemoveAlert(false)}
          title={t("views.team.remove_member", { name })}
          acceptText={
            alertLoading ? (
              <CircularProgress
                aria-label="progress indicator"
                size={24}
                color="inherit"
              />
            ) : (
              t("common.delete")
            )
          }
        />
      )}
    </>
  );
});

MemberMenu.propTypes = {
  userId: number.isRequired,
  memberId: number.isRequired,
  email: string.isRequired,
  name: string.isRequired,
  isAdmin: bool.isRequired,
  allowRoleChange: bool,
  reloadData: func.isRequired,
  totalAdmins: number,
  professionalLevel: number,
  technicalStack: arrayOf(number),
  teamRole: number,
  professionalLevels: arrayOf(object),
  stackTypes: arrayOf(object),
  teamRoles: arrayOf(object),
};

MemberMenu.defaultProps = {
  totalAdmins: 1,
};
