import { useState } from "react";
import { bool, string } from "prop-types";
import { changeProjectDescriptionQuery } from "@query";
import { useActiveProjectStore } from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Button, WysiwygEditor } from "@components";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import useStyle from "./DescriptionEditor.style";

export const DescriptionEditor = observer(
  ({ uuid, description, allowEdition }) => {
    const activeProjects = useActiveProjectStore();
    const { t } = useTranslation();
    const classes = useStyle(allowEdition);

    const [focused, setFocus] = useState(false);
    const [value, setValue] = useState(description);

    const handleDescriptionFocus = (e) => {
      if (!focused && e?.target?.nodeName !== "A") setFocus(true);
    };

    const handleChange = e => {
      setValue(e);
    }
    
    const handleEditEnd = (save) => async (e) => {
      e.preventDefault();
      if (save) {
        await changeProjectDescriptionQuery(uuid, value);
        activeProjects.editLocalProjectData(uuid, "content", value);
      } else setValue(description);
      setFocus(false);
    };

    return (
      <Grid item container>
        <Grid
          item
          container
          className={classnames(classes.container, {
            [classes.editable]: focused,
          })}
        >
          <WysiwygEditor
            noEmptyHtmlStrings
            placeholder={t("views.active.popup.no_desc")}
            onChange={handleChange}
            name="description"
            value={value}
            showPlaceholderOnBlurred
            forcedFocus={focused}
            readOnly={!allowEdition}
            onFocus={handleDescriptionFocus}
            // changeOnLooseForcedFocus
          />
        </Grid>
        {focused && (
          <Grid item container justifyContent="flex-end">
            <Button
              className={classes.button}
              variant="outlined"
              size="small"
              onClick={handleEditEnd(false)}
            >
              {t("common.cancel")}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              size="small"
              onClick={handleEditEnd(true)}
            >
              {t("common.save")}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
);

DescriptionEditor.propTypes = {
  uuid: string,
  description: string,
  allowEdition: bool,
};

DescriptionEditor.defaultProps = {
  description: "",
  allowEdition: true,
};
