import { APIMethod, fetchData } from "@client";


export async function getSessionsQuery(controller, project, page, limit=5) {
  const { results, paginate } = await fetchData(
    `/session?project=${project}&page=${page}&limit=${limit}`,
    APIMethod.GET,
    {
      signal: controller.signal,
    }
  );
  return {
    results,
    ...paginate,
  };
}