import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { retrievePasswordQuery } from "@query";
import { useStores } from "@hooks";
import { Alert, ClearButton } from "@components";
import { ArrowLeft } from "@assets";
import { login, resetPassword } from "@paths";
import { PasswordRetrieveForm } from "@forms";
import useStyle from "./PasswordRetrieve.style";

export const PasswordRetrieve = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyle();
  const { stateStore } = useStores();

  const [alertText, setAlertText] = useState("");

  const retrievePassword = async (data) => {
    try {
      const status = await retrievePasswordQuery(data);
      stateStore.setResetEmail(data.email);
      if (status) navigate(resetPassword, { replace: true });
    } catch (e) {
      if (e.response) setAlertText(e.response.data.error);
      else setAlertText(t("errors.network"));
    }
  };

  return (
    <>
      <p className={`${classes.text} ${classes.title}`}>
        {t("views.retrieve_password.forgotten")}
      </p>
      <p className={classes.text}>{t("views.retrieve_password.desc")}</p>

      <PasswordRetrieveForm onSubmit={retrievePassword} />

      <ClearButton
        fullWidth
        size="medium"
        className="mt-2"
        icon={<ArrowLeft />}
        to={login}
      >
        {t("views.retrieve_password.back")}
      </ClearButton>
      <Alert
        isOpen={Boolean(alertText)}
        title={alertText}
        acceptText={t("common.close")}
        onAccept={() => setAlertText("")}
      />
    </>
  );
};
