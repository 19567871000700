import { bool, func, number } from "prop-types";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@components";
import { capitalize, Grid } from "@material-ui/core";
import useStyle from "./LevelCheckbox.style"

export const LevelCheckbox = ({
  level,
  checked,
  onCheck,
  disabled,
}) => {
  const {t} = useTranslation();
  const classes = useStyle(level);
  
  const handleChange = () => onCheck(level);
  
  return (
    <Grid item container className={classes.container}>
      {
        level > 0 &&
        <div className={classes.line}/>
      }
      <Checkbox
        name={`level${level}`}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        className={classes.checkbox}
        label={capitalize(t(`proposal_parts.modules_levels.${level}`))}
      />
    </Grid>
  );
};

LevelCheckbox.propTypes = {
  level: number.isRequired,
  checked: bool.isRequired,
  onCheck: func.isRequired,
  disabled: bool,
};