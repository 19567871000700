import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  badge: {
    "& > .MuiBadge-badge": {
      border: "1px solid",
      width: 14,
      minWidth: 14,
      height: 14,
      fontSize: ".5rem",
    },
  },
  seeAllButton: {
    width: "100%",
    borderRadius: 0,
    justifyContent: "center",
  },
  noMessages: {
    padding: "16px 30px",
    borderBottom: `1px solid ${theme.props.border}`,
  },
  messageContainer: {
    overflowY: "auto",
    minHeight: "max-content",
    maxHeight: 5 * 63, //315,
  },
  wrapper: {
    position: "relative !important",
    "& > div": { position: "relative !important" },
  },
}));

export default useStyle;
