import { arrayOf, func, number, shape, string } from "prop-types";
import { TextField } from "@components";
import { Autocomplete } from "@material-ui/lab";
import useStyle from "./PricingAutocomplete.style";
import { ExpandMore } from "@material-ui/icons";

export const PricingAutocomplete = ({ id, label, value, onChange, options }) => {
  const classes = useStyle();

  return (
    <Autocomplete
      id={id}
      value={value}
      autoHighlight
      fullWidth
      disableClearable
      options={options}
      getOptionSelected={(o, v) => o.amount === v.amount}
      getOptionLabel={(o) => o.amount.toString()}
      onChange={onChange}
      className={classes.autocomplete}
      popupIcon={<ExpandMore />}
      renderInput={(params) => (
        <TextField {...params} style={{ margin: 0 }} label={label} />
      )}
    />
  );
};

PricingAutocomplete.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  value: shape({
    amount: number.isRequired,
    title: string.isRequired,
  }),
  onChange: func.isRequired,
  options: arrayOf(
    shape({
      amount: number.isRequired,
      title: string.isRequired,
    })
  ),
};
