import { bool, func, object } from "prop-types";
import { capitalize } from "@material-ui/core";
import { ExportTimelineRow } from "./ExportTimelineRow";
import { usePresetFieldNames } from "@hooks";
import { ExportTimelineBilling } from "./ExportTimelineBilling";

export const ExportTableTimeline = ({ translate, editorStore, usePreset }) => {
  const { currentProjectStructure } = editorStore;
  const {
    timelineWorkTypes,
    settings,
    visibility,
    totalDevelopmentTime,
    timelineBilling
  } = currentProjectStructure;
  const {
    roundHours,
    modifier,
    roundPrice,
    useTimelineBilling,
    useTimelineAllocation,
    currencyObj,
    roundType,
    usedRoundDecimals,
  } = settings;
  const {
    apply,
    hideTimelineBilling,
    hideTimelineAllocation,
  } = visibility;
  
  const { getFieldName } = usePresetFieldNames(usePreset, editorStore);
  
  const visibleWorkTypes = timelineWorkTypes.filter(wT => (
    !wT.isHidden && !wT.hideOnTimeline
  ));

  return (
    <div className="table-timeline">
      <h3 className="timeline">{getFieldName("pt-timeline")}</h3>
      <table>
        <thead>
          <tr>
            <th className="timeline" style={{ textAlign: "left" }}>
              {getFieldName("pt-role")}
            </th>
            {totalDevelopmentTime &&
              [...new Array(totalDevelopmentTime).keys()].map((i) => (
                <th key={`period${i}`} className="timeline">
                  {`${capitalize(translate("time.month"))} ${i + 1}`}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {
            useTimelineAllocation &&
            (!apply || !hideTimelineAllocation) &&
            visibleWorkTypes.map((workType) => (
              <ExportTimelineRow
                key={`period${workType.id}`}
                translate={translate}
                element={workType}
                totalDevelopmentTime={totalDevelopmentTime}
                roundHours={roundHours}
                modifier={modifier}
                roundType={roundType}
                usedRoundDecimals={usedRoundDecimals}
              />
            ))
          }
          {
            useTimelineBilling && (!apply || !hideTimelineBilling) &&
            <ExportTimelineBilling
              editorStore={editorStore}
              timelineBilling={timelineBilling}
              roundPrice={roundPrice}
              currencyObj={currencyObj}
              roundType={roundType}
              roundHours={roundHours}
            />
          }
        </tbody>
      </table>
    </div>
  );
};

ExportTableTimeline.propTypes = {
  editorStore: object,
  usePreset: bool,
  translate: func,
};
