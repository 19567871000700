import { APIMethod, fetchData } from "@client";

export async function sendRecordingPartQuery(
  recordingSessionId,
  structure,
  timestamp,
  order=1
) {
  await fetchData(`/session/body/${recordingSessionId}`, APIMethod.POST, {
    data: { structure, timestamp, order },
  });
}
