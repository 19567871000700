import { APIMethod, fetchData } from "@client";

export async function initDemoProjectQuery() {
  try {
    await fetchData(
      `/v1/project/structure/demo`,
      APIMethod.POST,
    );
  } catch (e) {
    console.error("Failed to create demo project")
  }
}
