import { APIMethod, fetchData } from "@client";
import { createCustomWorkTypeQuery } from "@query";

export async function setProjectWorkTypesQuery(projectId, tags, customTags) {
  const tagIds = tags.filter((t) => typeof t === "number");

  await Promise.all(
    customTags
      .map(async ({ name }) => {
        const res = await createCustomWorkTypeQuery(name);
        tagIds.push(res.id);
      })
  );

  const data = {
    uuid: projectId,
    tag: JSON.stringify(tagIds),
  };

  return await fetchData("/project/addtag", APIMethod.PUT, { data });
}
