import { observable, action, makeObservable } from "mobx";
import { format } from "date-fns";
import { getScrollBottom } from "@utils";

export class AppStateStore {
  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;

    // this.handleGlobalScroll = this.handleGlobalScroll.bind(this);
    // this.handleGlobalScrollEnd = this.handleGlobalScrollEnd.bind(this);
  }
  // =============== PROPERTIES ===============

  @observable lang = "en";
  @observable theme = "def";

  @observable firstLogin = false;
  @observable trialAlertStatus = false;

  @observable memorizedWorkspaceId = null;
  @observable memorizedProjectId = null;
  @observable memorizedAction = null;
  memorizedActionReplace = false;

  @observable newProjectCreated = false;

  @observable resetEmail = "";

  @observable openFeedback = false;

  @observable showFilesUploadProgress = false;
  @observable filesUploadProgress = [];

  @observable activationEmail = null;

  @observable scrollRef = null;
  @observable auxiliaryScrollRef = null;
  @observable scrollRefPadding = 0;
  @observable scrollEndHandler = null;
  @observable scrollHandler = null;

  // ================ ACTIONS =================

  @action setTrialAlertStatus(status) {
    this.trialAlertStatus = status;
  };

  @action setMemorizedAction(
    action = null,
    projectId = null,
  ) {
    if (projectId) this.memorizedProjectId = projectId;
    this.memorizedAction = action;
  };

  @action setProjectCreated = (v) => {
    this.newProjectCreated = v;
  };

  @action setResetEmail(email) {
    this.resetEmail = email;
  }

  /** @note: unused ~21.03.2023 */
  // @computed get themeSettings() {
  //   return themes[this.theme];
  // }

  @action setOpenFeedback = (s) => (this.openFeedback = s);

  @action resetFilesUploadProgress(length = 0) {
    this.showFilesUploadProgress = Boolean(length);
    this.filesUploadProgress = [...new Array(length)].map(() => 0);
  }
  @action setFilesUploadProgress(index, progress) {
    const p = [...this.filesUploadProgress];
    p[index] = progress;
    this.filesUploadProgress = p;
  }

  getAttachmentPath(uuid, fileName, version = "v1") {
    if (!this.rootStore) return "";
    return `${this.rootStore.userStore.workspaceUuid}/${uuid}/${version}/${fileName}`;
  }
  
  getFontFilePath(fontName, format) {
    if (!this.rootStore) return "";
    return `company_fonts/${this.rootStore.userStore.data.companyId}/${fontName}.${format}`;
  }

  getPresetPath(presetName) {
    if (!this.rootStore) return "";
    return `${this.rootStore.userStore.data.companyId}/presets/${presetName}/logo`;
  }

  getBrokenStructurePath(uuid) {
    if (!this.rootStore) return "";
    return `${
      this.rootStore.userStore.workspaceUuid
    }/${uuid}/_broken_structures/${format(
      new Date(),
      "yyyy_MM_dd_HH_mm_ss"
    )}.json`;
  }

  // ---------- global scrollbar actions -----------------

  @action setScrollRef = (ref) => (this.scrollRef = ref|| null);
  @action setUseAuxiliaryScrollRef = (useAuxiliaryScrollRef) => this.useAuxiliaryScrollRef = useAuxiliaryScrollRef;
  @action setAuxiliaryScrollRef = (ref) => (this.auxiliaryScrollRef = ref|| null);
  @action setScrollRefPadding = (padding=this.scrollRefPadding) => {
    const ref = this.useAuxiliaryScrollRef ? this.auxiliaryScrollRef : this.scrollRef;
    if(!ref) return;
    this.scrollRefPadding = this.scrollRefPadding + Math.max(
      padding - getScrollBottom(ref), 0
    );
    ref.style.paddingBottom = this.scrollRefPadding ? `${this.scrollRefPadding}px` : "";
  }
  @action updateScrollRefPadding = () => {
    const ref = this.useAuxiliaryScrollRef ? this.auxiliaryScrollRef : this.scrollRef;
    if(!ref) return;
    this.scrollRefPadding = Math.max(
      this.scrollRefPadding - getScrollBottom(ref), 0
    );
    ref.style.paddingBottom = this.scrollRefPadding ? `${this.scrollRefPadding}px` : "";
  }
  @action resetScrollRefPadding = () => {
    const ref = this.auxiliaryScrollRef || this.scrollRef;
    if(!ref || !this.scrollRefPadding) return;
    ref.style.paddingBottom = "";
    this.scrollRefPadding = 0;
  }

  // @action handleGlobalScroll(e) {
  //   this.scrollHandler && this.scrollHandler(e);
  //   if(e.currentTarget.scrollTop + 100 >= e.currentTarget.scrollHeight)
  //     this.scrollEndHandler && this.scrollEndHandler(e);
  // }

  @action setScrollEndHandler = (callback) =>
    (this.scrollEndHandler = callback);

  @action resetScrollEndHandler = () => this;

  // @action handleGlobalScrollEnd(e) {
  //   this.scrollEndHandler && this.scrollEndHandler(e);
  // }

  @action setScrollHandler = (p) => (this.scrollHandler = p);

  @action resetScrollHandler = (p) => (this.scrollHandler = p);

  @action setActivationEmail = (e) => (this.activationEmail = e);
}
