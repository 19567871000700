import { useMemo } from "react";
import { observer } from "mobx-react";
import { useEditorWebsocket } from "@hooks";
import Grid from "@material-ui/core/Grid";
import { AvatarList } from "@components";

export const EditorUsers = observer(() => {
  const socket = useEditorWebsocket();

  const userAvatars = useMemo(
    () =>
      socket?.users.map(({ name, color, ...other }) => ({
        ...other,
        avatarColor: color,
        fullname: name,
      })) || [],
    [socket?.users.length]
  );

  return (
    <>
      {userAvatars?.length ? (
        <Grid
          item
          container
          alignItems="center"
          className="my-2"
          style={{ minHeight: 32 }}
        >
          <AvatarList users={userAvatars} size={32} borderWidth={2} />
        </Grid>
      ) : undefined}
    </>
  );
});
