// Material other
import { SvgIcon } from "@material-ui/core";

export const Stacks = (props) => {
  return (
    <SvgIcon { ...props } viewBox="0 0 24 24" fill="none">
      <path
        d="M19.7281 16.8714L12.2716 20.6917C12.1835 20.7307 12.0886 20.7508 11.9927 20.7508C11.8968 20.7508 11.8019 20.7307 11.7138 20.6917L4.2611 16.8714C4.10932 16.7932 4.10932 16.6643 4.2611 16.5862L6.04459 15.6722C6.13274 15.6334 6.22762 15.6134 6.32349 15.6134C6.41937 15.6134 6.51424 15.6334 6.6024 15.6722L11.7176 18.2893C11.8056 18.3286 11.9005 18.3488 11.9965 18.3488C12.0924 18.3488 12.1873 18.3286 12.2754 18.2893L17.3906 15.6722C17.4787 15.6331 17.5736 15.613 17.6695 15.613C17.7654 15.613 17.8603 15.6331 17.9484 15.6722L19.7357 16.5862C19.8912 16.6643 19.8912 16.7893 19.7357 16.8675L19.7281 16.8714ZM19.7281 11.5393L17.9408 10.6253C17.8527 10.5863 17.7578 10.5661 17.6619 10.5661C17.566 10.5661 17.4711 10.5863 17.383 10.6253L12.2678 13.2464C12.1796 13.2852 12.0848 13.3052 11.9889 13.3052C11.893 13.3052 11.7981 13.2852 11.71 13.2464L6.6024 10.6214C6.51424 10.5826 6.41937 10.5626 6.32349 10.5626C6.22762 10.5626 6.13274 10.5826 6.04459 10.6214L4.25731 11.5354C4.10552 11.6136 4.10552 11.7425 4.25731 11.8206L11.7138 15.6409C11.8019 15.6799 11.8968 15.7 11.9927 15.7C12.0886 15.7 12.1835 15.6799 12.2716 15.6409L19.7281 11.8206C19.8837 11.7425 19.8837 11.6136 19.7281 11.5354V11.5393ZM4.2611 7.09403L11.7176 10.6214C11.8968 10.6939 12.0961 10.6939 12.2754 10.6214L19.7319 7.09403C19.8837 7.01981 19.8837 6.90262 19.7319 6.83231L12.2716 3.30496C12.0925 3.23168 11.8929 3.23168 11.7138 3.30496L4.25731 6.83231C4.10173 6.90653 4.10173 7.02371 4.25731 7.09403H4.2611Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
