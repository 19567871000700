import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: "100%",
    borderRadius: 5,
    boxShadow: "none",
    border: "1px solid " + theme.props.border,
    padding: "24px 16px 16px",
    overflow: "hidden",
    position: "relative",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-20px 0 0 -20px",
  },
  flag: {
    border: `1px solid ${theme.palette.primary.main}80`,
  },
  divider: {
    width: "calc(100% + 32px)",
    margin: "24px -16px",
  },
}));

export default useStyle;
