import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&:not(:hover) $removeSection": {
      opacity: 0,
    },
  },
  image: {
    color: "transparent",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    textAlign: "center",
    textIndent: "10000px",
  },
  removeSection: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#000000ad",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // borderRadius: '50%',
    color: "white",
    cursor: "pointer",
  },
  "star-1": { color: theme.props.stars.bronze },
  "star-2": { color: theme.props.stars.silver },
  "star-3": { color: theme.props.stars.gold },
}));

export default useStyle;
