import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  container: {
    "& > p": {
      margin: "4px 0",
    },
  },
  dialog: {
    zIndex: "2000 !important",
  },
}));

export default useStyle;
