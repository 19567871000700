import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    backgroundColor: theme.props.background,
    width: "100%",
    borderBottom: "1px solid " + theme.props.border,
    overflowX: "hidden",
  },
  backgroundAlt: {
    backgroundColor: theme.props.backgroundSecondary,
  },
  stepperNaviButtonWrapper: {
    flexShrink: 2,
    minWidth: 65,
  },
  stepperWrapper: {
    flexShrink: 1,
    padding: "0 8px",
  },
}));

export default useStyle;
