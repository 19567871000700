import { useEffect } from "react";
import { PdfEditorContainer, PdfUploadProgress } from "@components";
import { useEditorWebsocket } from "@hooks";

export const PdfEditorView = () => {
  
  const socket = useEditorWebsocket();
  
  useEffect(() => {
    socket?.init(true);
  }, []);
  
  return (
    <>
      <PdfEditorContainer />
      <PdfUploadProgress />
    </>
  )
}