import { arrayOf, bool, func, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { Avatar, TableCell, TableRow, Tag, TagList } from "@components";
import { Grid, useTheme } from "@material-ui/core";
import { getFullName } from "@utils";
import { MemberMenu } from "../index";
import useStyle from "./UserRow.style";

export const UserRow = observer(({
  user,
  reloadData,
  totalAdmins,
  ...memberMenuProps
}) => {
  
  const {
    id,
    memberId,
    fullname,
    avatar,
    avatarColor,
    admin,
    teamRole,
    teamRoleId,
    email,
    professionalLevel,
    professionalLevelId,
    userTechnicalStack,
    status,
  } = user;

  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();
  const { userStore } = useStores();

  const { isModerator, data } = userStore;

  return (
    <TableRow>
      <TableCell big width={300} flexible>
        <Grid item container wrap="nowrap" alignItems="center">
          {
            <Avatar
              fullname={fullname}
              avatar={avatar}
              color={avatarColor}
              size={40}
            />
          }
          <Grid
            item
            container
            direction="column"
            className="ml-2"
            style={{ width: "calc(100% - 48px)" }}
          >
            <Grid item container>
              <p className={classes.name}>
                {fullname}
              </p>
              {admin && (
                <Tag
                  text={t("views.team.admin")}
                  color={theme.props.adminColor}
                />
              )}
            </Grid>
            <p className={classes.secondary}>{email}</p>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell big width={160}>
        {teamRole && <Tag text={teamRole} />}
      </TableCell>
      <TableCell big width={100}>
        {professionalLevel || ""}
      </TableCell>
      <TableCell big width={280}>
        <TagList
          tags={userTechnicalStack.slice(0, 2)}
          idKey="stackTypeId"
          nameKey="stackType"
          forceDot
        />
        {userTechnicalStack?.length > 2 && (
          <p className="text-xs color-primary">
            {t("common.more_items", { items: userTechnicalStack.length - 2 })}
          </p>
        )}
      </TableCell>
      <TableCell className={classes[`status${status}`]} big width={80}>
        {t(`views.team.status${status}`)}
      </TableCell>
      <TableCell className={classes.menuCell} big width={34}>
        {(isModerator || email === data.email) && (
          <MemberMenu
            userId={id}
            memberId={memberId}
            email={email}
            name={fullname}
            professionalLevel={professionalLevelId}
            technicalStack={userTechnicalStack?.map((s) => s.stackTypeId)}
            teamRole={teamRoleId}
            isAdmin={admin}
            reloadData={reloadData}
            totalAdmins={totalAdmins}
            {...memberMenuProps}
          />
        )}
      </TableCell>
    </TableRow>
  );
});

UserRow.propTypes = {
  user: object.isRequired,
  allowRoleChange: bool,
  reloadData: func.isRequired,
  totalAdmins: number.isRequired,
  professionalLevels: arrayOf(object),
  stackTypes: arrayOf(object),
  teamRoles: arrayOf(object),
};
