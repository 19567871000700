import { bool, number, string, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Collapse, Grid } from "@material-ui/core";
import { WysiwygEditor } from "@components";
import useStyle from "./DescriptionRow.style";
import classnames from "classnames";
import {
  DescriptionVariantSelector
} from "@components/ProjectEditor/DescriptionVariantSelector/DescriptionVariantSelector";

export const DescriptionRow = ({
  show,
  value,
  onValueChange,
  isLastGroupRow,
  tableDepth = 0,
  staticDescription,
  allowEdition,
  isSectionRow,
  isLibrary,
  showBorder,
  showPdfCover=false,
}) => {
  const { t } = useTranslation();
  const classes = useStyle(showPdfCover);

  return (
    <Collapse
      in={show}
      mountOnEnter
      unmountOnExit
      classes={{
        root: "noDrag w-full",
        wrapperInner: classnames(
          "cell",
          `d${tableDepth}`,
          isLastGroupRow && "bottom",
        )
      }}
    >
      <Grid
        item
        container
        role="row"
        className={classnames(
          "cell",
          `d${tableDepth}`,
          isLastGroupRow && "bottom",
          classes.descriptionEditorContainer,
          
        )}
      >
        <WysiwygEditor
          readOnly={!allowEdition}
          autoFocus={!staticDescription || !value?.length}
          noEmptyHtmlStrings
          changeOnClickAway
          placeholder={t("views.editor.desc_change")}
          onChange={onValueChange}
          name="description-row"
          value={value}
          className={classnames(
            "cell",
            `d${tableDepth}`,
            classes.descriptionEditor,
            staticDescription && !isSectionRow && classes.descriptionEditorStatic,
            showBorder && classes.border,
            isSectionRow && "preset-sectionDesc",
            !isSectionRow && "preset-rowDesc"
          )}
        >
          {
            !isLibrary &&
            <DescriptionVariantSelector/>
          }
        </WysiwygEditor>
      </Grid>
    </Collapse>
  );
};

DescriptionRow.propTypes = {
  onValueChange: func.isRequired,
  show: bool,
  value: string,
  isLastGroupRow: bool,
  //common
  tableDepth: number,
  staticDescription: bool,
  allowEdition: bool,
  isSectionRow: bool,
  isLibrary: bool,
  showBorder: bool,
  showPdfCover: bool,
};
