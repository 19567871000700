import { useState, memo, useMemo } from "react";
import { bool, number, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { Trash } from "@assets";
import { Alert, TooltipIcon } from "@components";
import classnames from "classnames";
import { useEditorWebsocket } from "@hooks";

export const TaskRemove = memo(({
  element,
  tableDepth,
  isLibrary
}) => {
  const { t } = useTranslation();
  const socket = useEditorWebsocket();
  
  // const [clearAlertVisible, showClearAlert] = useState(false);
  const [removeAlertVisible, showRemoveAlert] = useState(false);
  
  const isEmptyLibraryElement = useMemo(
    () => isLibrary && typeof element.id !== "number",
    [element.id]
  );
  
  const handleRemove = () => {
    if(isEmptyLibraryElement) {
      element.removeSelf();
      return;
    }
    if (!element.hasValues && !element.hasChildren) confirmRemove();
    else showRemoveAlert(true);
  };

  const confirmRemove = async (moveModules = false) => {
    const path = element.treePath.join("/");
    const newSection = element.removeSelf(moveModules);
    showRemoveAlert(false);
    if(!isLibrary) {
      if(moveModules && newSection)
        socket?.requestCommentMove(path, newSection.treePath.join("/"));
      else
        socket?.requestCommentPathRemove(path);
    }
  };

  // const confirmClear = () => {
  //   element.resetTaskValues();
  //   showClearAlert(false);
  // };

  return (
    <>
      <TooltipIcon
        icon={<Trash />}
        onClick={handleRemove}
        className={classnames(
          "button-remove noDrag hoverIcon opaque",
          tableDepth === 0 ? "color-error-lighter" : "color-error"
        )}
      >
        {t(`views.editor.remove_rows.${tableDepth}`)}
      </TooltipIcon>
      {/*<Alert*/}
      {/*  isOpen={clearAlertVisible}*/}
      {/*  title={t(`views.editor.dialogs.clear_alert.title_level_${tableDepth}`)}*/}
      {/*  onAccept={confirmClear}*/}
      {/*  onCancel={() => showClearAlert(false)}*/}
      {/*  acceptText={t("views.editor.dialogs.clear_alert.confirm")}*/}
      {/*/>*/}
      <Alert
        isOpen={removeAlertVisible}
        title={t(`views.editor.dialogs.remove_alert.title_level${tableDepth}`)}
        onAccept={() => confirmRemove(false)}
        onIntermediate={
          tableDepth === 0 ? () => confirmRemove(true) : undefined
        }
        onCancel={() => showRemoveAlert(false)}
        acceptText={t("common.yes")}
        cancelText={t("common.no")}
        intermediateText={
          tableDepth === 0 && element.hasChildren
            ? t("views.editor.dialogs.remove_alert.intermediate")
            : undefined
        }
      />
    </>
  );
});

TaskRemove.propTypes = {
  element: object.isRequired,
  tableDepth: number,
  isLibrary: bool,
};
