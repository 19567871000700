import { arrayOf, object, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@components";

export const WorkTypeSelect = ({
  control,
  name,
  workTypes,
}) => {
  const { t } = useTranslation();
  
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => {
        return <Autocomplete
          value={value}
          options={workTypes}
          name={name}
          idKey="id"
          labelKey="name"
          inputLabel={t("common.work_types")}
          fullWidth
          onChange={onChange}
          size="small"
          style={{width: 192}}
          fieldClassName="m-0"
        />
      }}
    />
  )
}

WorkTypeSelect.propTypes = {
  control: object.isRequired,
  name: string.isRequired,
  workTypes: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired
}