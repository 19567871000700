import { memo, forwardRef } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link as MuiLink } from "@material-ui/core";

export const Link = memo(
  forwardRef((props, ref) => {
    const { children, to, ...linkProps } = props;

    const location = useLocation();

    return (
      <MuiLink
        ref={ref}
        component={RouterLink}
        to={to}
        state={{ from: location.pathname }}
        {...linkProps}
      >
        {children}
      </MuiLink>
    );
  })
);

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
