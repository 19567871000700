
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  
  container: {
    width: "100%",
    padding: "4px 16px"
  },
  block: {
    height: "100%",
    width: "100%",
    borderRadius: 10,
    backgroundColor: theme.props.billingColor,
    "& .MuiButton-label": {
      alignItems: "flex-end"
    }
  },
  blockOpened: {
    backgroundColor: theme.props.text.main,
  },
  blockPart: {
    boxSizing: "border-box",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      opacity: .75
    },
    "& > span": {
      lineHeight: "normal"
    }
  },
  name: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    padding: "0 4px"
  }
}));

export default useStyle;