export function parseUsedPages(usedPages, documentTotalPages=0) {
  const def = [...new Array(documentTotalPages)].map((a, i) => [i + 1, i]);
  
  if(usedPages === "[]")
    return def;
  
  if(typeof usedPages === "string") {
    if (usedPages.match(/^(\d+,)+\d$/g)) {
      return usedPages.split(",").reduce((t, p, i) => {
        if (i % 2)
          t[t.length - 1].push(Number(p));
        else
          t.push([Number(p)]);
        return t;
      }, []);
    }
    
    try {
      return JSON.parse(usedPages);
    } catch (e) {
      return def;
    }
  }
  
  return usedPages;
}