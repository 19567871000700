import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useEditorCommentStore, useProjectEditorStore, useStructureStore } from "@hooks";
import { getPercentWorkTypes } from "@utils";
import { ChatThread, TimelineSettings } from "@dialogs";
import { TooltipIcon, VisibilityButton } from "@components";
import { Percent } from "@assets";

export const TimelineRowActions = observer(({
  allowEdition,
  workType,
  // commentPath,
  opened
}) => {
  const { visibilityMode } = useProjectEditorStore();
  const commentStore = useEditorCommentStore();
  const structure = useStructureStore();
  
  const { id, hasPercent, usesTimelinePercent, hideOnTimeline, percentageWorkTypes } = workType;
  
  const commentPath = "t"+workType.treePath.join("/");

  const percentWorkTypes = useMemo(() => (
    getPercentWorkTypes(percentageWorkTypes)
  ), [structure.percentageWorkTypes, percentageWorkTypes]);

  return (
    <>
      {allowEdition && hasPercent && usesTimelinePercent && (
        <TooltipIcon
          icon={<Percent />}
          color="primary"
          className="noDrag"
        >
          {percentWorkTypes}
        </TooltipIcon>
      )}
      {allowEdition && (
        <TimelineSettings
          fullSettings
          className="button-wta-settings hoverIcon opaque"
          selectedWorkType={id}
        />
      )}
      {
        visibilityMode &&
        <VisibilityButton
          hidden={hideOnTimeline}
          onChange={workType.setHideOnTimeline}
          inRow
        />
      }
      {commentStore?.commentsVisible && (
        <ChatThread rowThread path={commentPath} hideBadge={opened} />
      )}
    </>
  );
});

TimelineRowActions.propTypes = {
  allowEdition: bool,
  workType: object.isRequired,
  // commentPath: string,
  opened: bool,
};
