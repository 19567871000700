import { useState } from "react";
import { bool, string, func } from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Refresh, MoreHoriz } from "@material-ui/icons";
import { ArrowRight, Trash } from "@assets";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Alert, MenuButton, PopMenu } from "@components";
import { editor } from "@paths";
import { removeProjectQuery } from "@query";

export const ArchivedMenu = ({ uuid, onRestore, onRemove, isOwner }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuVisible, showMenu] = useState(false);
  const [alertLoading, setAlertLoading] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleMenuOpen = (e) => {
    e.stopPropagation();
    showMenu(!menuVisible);
  };

  const removeProject = (uuid) => async () => {
    setAlertLoading(true);
    await removeProjectQuery(uuid);
    setAlertLoading(false);
    setDeleteDialog(false);
    onRemove();
  };

  const openEditor = () => {
    navigate(editor(uuid));
  };

  return (
    <>
      <IconButton
        color="primary"
        ref={setMenuAnchorEl}
        onClick={isOwner ? handleMenuOpen : openEditor}
        aria-label="menu"
      >
        {isOwner ? <MoreHoriz /> : <ArrowRight />}
      </IconButton>
      {isOwner && (
        <PopMenu
          key={uuid}
          show={menuVisible}
          anchor={menuAnchorEl}
          onClickAway={() => showMenu(false)}
          className="py-2 min-h-max"
          style={{ width: 210 }}
          placement="bottom-end"
        >
          <MenuButton icon={<ArrowRight />} onClick={openEditor}>
            {t("common.open")}
          </MenuButton>
          <MenuButton
            icon={<Refresh style={{ transform: "scaleX(-1)" }} />}
            onClick={() => onRestore(uuid)}
          >
            {t("common.restore")}
          </MenuButton>
          <MenuButton
            icon={<Trash color="error" />}
            onClick={() => setDeleteDialog(true)}
          >
            {t("common.delete")}
          </MenuButton>
          <Alert
            isOpen={deleteDialog}
            onAccept={removeProject(uuid)}
            onCancel={() => setDeleteDialog(false)}
            title={t("common.delete_project_question")}
            acceptText={
              alertLoading ? (
                <CircularProgress
                  aria-label="progress indicator"
                  size={24}
                  color="inherit"
                />
              ) : (
                t("common.delete_accept")
              )
            }
          />
        </PopMenu>
      )}
    </>
  );
};

ArchivedMenu.propTypes = {
  uuid: string.isRequired,
  onRestore: func,
  onRemove: func,
  isOwner: bool.isRequired,
};
