import { string, func } from "prop-types";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Button } from "@components";
import useStyle from "./PlanDurationSwitch.style";
import classnames from "classnames";
import { PLAN_DURATION } from "@utils";

export const PlanDurationSwitch = ({ value, onChange }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Grid
      item
      container
      justifyContent="center"
      wrap="nowrap"
      className={classes.container}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classes.discount}
      >
        <svg
          width="31"
          height="7"
          viewBox="0 0 31 7"
          fill="none"
          className={classes.discountLine}
        >
          <path
            d="M1.61269 3.99148C14.2598 -3.80019 26.9901 4.76615 29.586 6.05115"
            stroke="#42BE57"
            strokeWidth="1.36285"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>
          {t("common.save")}
          {" 16%"}
        </span>
      </Grid>
      {Object.values(PLAN_DURATION).map((v) => (
        <Button
          key={v}
          className={classnames(
            classes.switchButton,
            value === v && classes.switchButtonSelected
          )}
          size="large"
          variant="contained"
          color="primary"
          onClick={() => onChange(v)}
        >
          {t(`billing.per_${v}`)}
        </Button>
      ))}
    </Grid>
  );
};

PlanDurationSwitch.propTypes = {
  value: string,
  onChange: func,
};
