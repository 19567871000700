import { useEffect, useState } from "react";
import { bool, string, array } from "prop-types";
import { ProjectEditorStoreProvider, StructureStoreProvider } from "@stores";
import { StructureModel, structureParser, unzipStructure } from "project-structure";
import { PageCircularProgress, EditorContainer, TableBreakdown } from "@components";
import { PROJECT_TYPE } from "@utils";
import { Grid } from "@material-ui/core";

export const TemplatePreview = ({
  projectStructure,
  workTypes,
  useNewWorkTypes,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    (async () => {
      let structureData;
      if(typeof projectStructure === "string" && projectStructure[0] !== "{") {// may be blank
        try {
          structureData = JSON.parse(unzipStructure(projectStructure));
        } catch(e) {
          try {
            structureData = JSON.parse(unzipStructure(unzipStructure(projectStructure)));
          } catch(e) {
            console.log("TEMPLATE PARSE FAIL:", JSON.stringify(e));
          }
        }
      } else if(projectStructure)
        structureData = projectStructure;

      if(structureData) {
        const parsedStructure = await structureParser({
          structureData,
          workTypes,
          createNewTags: useNewWorkTypes
        });
        setStructure(StructureModel.create(parsedStructure));
        setLoading(false);
      }
    })();
  }, []);

  if (isLoading || !structure) return <PageCircularProgress />;

  return (
    <ProjectEditorStoreProvider projectType={PROJECT_TYPE.STANDARD} workTypes={workTypes}>
      <StructureStoreProvider value={structure}>
        <EditorContainer>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
          >
            <TableBreakdown isProposal />
          </Grid>
        </EditorContainer>
      </StructureStoreProvider>
    </ProjectEditorStoreProvider>
  );
};

TemplatePreview.propTypes = {
  projectStructure: string.isRequired,
  workTypes: array,
  useNewWorkTypes: bool,
};
