import { makeStyles } from "@material-ui/core/styles";
import { MAX_PAGE_WIDTH } from "@styles/themes";

const useStyle = makeStyles(() => ({
  root: {
    marginBottom: 36,
  },
  // sectionButton: {
  //   backgroundColor: theme.props.primary+"1a",
  //   borderColor: theme.props.primary+"4d",
  //   borderStyle: "dashed !important",
  //   height: 30,
  // },
  // divider: {
  //   width: "100%",
  //   marginTop: 24,
  //   marginBottom: 24,
  // },
  container: {
    width: "100%",
    maxWidth: MAX_PAGE_WIDTH,
    marginTop: 24,
  },
  // empty: {
  //   width: "100%",
  //   textAlign: "center",
  //   fontSize: 24
  // }
}));

export default useStyle;
