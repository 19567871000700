import { useState } from "react";
import { object, string } from "prop-types";
import { useFeatureGuardian } from "@hooks";
import { LOCKED_FEATURES } from "@utils";
import { generateUserStoryQuery } from "@query";
import { TooltipIcon } from "@components";
import { useTranslation } from "react-i18next";
import { Rocket } from "@assets";
import { CircularProgress } from "@material-ui/core";
import classnames from "classnames";

export const UserStoryGenerator = ({ element, lighterColor }) => {
  const { t } = useTranslation();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.AI_STORY
  );

  const [generatingStory, setGeneratingStory] = useState();

  const handleGenerateUserStory = async () => {
    const allowed = await checkAccess();
    if (!allowed || generatingStory || !element.canGenerateUserStory) return;

    setGeneratingStory(true);
    const story = await generateUserStoryQuery(element.name);
    if (story?.length) {
      element.setContent(story, true);
      setGeneratingStory(false);
    }
  };

  return (
    <>
      <TooltipIcon
        icon={generatingStory ? <CircularProgress size={18} /> : <Rocket />}
        onClick={handleGenerateUserStory}
        className={classnames(
          "button-ai-story",
          lighterColor,
          "noDrag hoverIcon",
          element.canGenerateUserStory && "opaque"
        )}
        color={element.canGenerateUserStory ? "primary" : "secondary"}
      >
        {t(
          `views.editor.generate${element.canGenerateUserStory ? "" : "d"}_us`,
          { name: element.name }
        )}
      </TooltipIcon>
      <FeatureAlert />
    </>
  );
};

UserStoryGenerator.propTypes = {
  element: object.isRequired,
  lighterColor: string,
};
