import { useMemo, useRef, useState } from "react";
import { string } from "prop-types";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { IconButton } from "@material-ui/core";
import useStyle from "./FeatureName.style";
import { Info } from "@assets";
import { Dialog } from "@components";
import { FeatureInfoDialog } from "@views/Utility/Pricing/components/FeatureInfoDialog/FeatureInfoDialog";
import { SUBSCRIPTION_FEATURE_PREVIEWS } from "@utils";

export const FeatureName = ({ code }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [visible, open] = useState(false);

  const buttonRef = useRef(null);

  const hasVideo = useMemo(
    () => Boolean(SUBSCRIPTION_FEATURE_PREVIEWS[code]),
    [code]
  );

  const width = useMemo(() => (hasVideo ? 720 : 320), [hasVideo]);

  // title={t(`feature_desc.${code}`)}
  // placement="right"

  const handleOpen = () => {
    open(true);
    // const isSmall = window.innerWidth < 720;
    // if(
    //   ((isSmall || isMobile) && trigger === "click")
    //   || (trigger === "move" && !isSmall)
    // )
    //   open(trigger);
  };
  const handleClose = (trigger) => () => {
    const isSmall = window.innerWidth < 720;
    if (trigger === "click" || (!isSmall && !isMobile)) open(null);
  };

  return (
    <>
      <a onClick={handleOpen} className={classes.name}>
        <span>{t(`features.${code}`)}</span>
        <IconButton
          ref={buttonRef}
          color="secondary"
          className="ml-1"
          size="small"
        >
          <Info />
        </IconButton>
      </a>
      <Dialog
        open={visible}
        onClose={handleClose("click")}
        useCloseButton={false}
        title={t(`features.${code}`)}
        width={width}
      >
        <FeatureInfoDialog code={code} hasVideo={hasVideo} />
      </Dialog>
      {/*{*/}
      {/*  visible === "click"*/}
      {/*    ? <Dialog*/}
      {/*      open={Boolean(visible)}*/}
      {/*      onClose={handleClose("click")}*/}
      {/*      useCloseButton={false}*/}
      {/*      title={t(`features.${code}`)}*/}
      {/*      width={width}*/}
      {/*    >*/}
      {/*      <FeatureInfoDialog code={code} hasVideo={hasVideo} />*/}
      {/*    </Dialog>*/}
      {/*    : <PopMenu*/}
      {/*      show={Boolean(visible)}*/}
      {/*      anchor={buttonRef.current}*/}
      {/*      placement="right"*/}
      {/*      onClose={handleClose("click")}*/}
      {/*      style={{width, maxWidth: "90vh", padding: 24}}*/}
      {/*    >*/}
      {/*      <FeatureInfoDialog code={code} hasVideo={hasVideo} popup />*/}
      {/*    </PopMenu>*/}
      {/*}*/}
    </>
  );
};

FeatureName.propTypes = {
  code: string,
};
