import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles(() => ({
  cellContainer: {
    height: 38,
    width: CELL_WIDTHS.VALUE.LARGE,
    minWidth: CELL_WIDTHS.VALUE.LARGE,
  },
  cellExpanded: {
    width: CELL_WIDTHS.VALUE.TINY * 4,// + 10,
    minWidth: CELL_WIDTHS.VALUE.TINY * 4,// + 10
  },
  weekCell: {
    width: CELL_WIDTHS.VALUE.MINI,
    minWidth: CELL_WIDTHS.VALUE.MINI,
  },
  button: {
    right: 0,
    zIndex: 3
  },
  buttonOpened: {
    right: -8,
  },
}));

export default useStyle;