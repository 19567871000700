import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  container: {
    textTransform: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 6,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  iconContainer: {
    width: 24,
    height: 24,
  }
}));

export default useStyle;
