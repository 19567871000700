import { useEffect, useState } from "react";
import { func, number } from "prop-types";
import { observer } from "mobx-react";
import { useStores, useTemplates } from "@hooks";
import { NewProjectDialog } from "@dialogs";
import { PageTitle, TemplatesFilter, TemplatesList } from "@components";
import { Container } from "@material-ui/core";

export const TemplatesView = observer(({
  defaultTemplateId,
  setDefaultTemplateId,
}) => {

  const { stateStore } = useStores();

  const [showNewProjectModal, openNewProjectModal] = useState(false);

  const {
    templates,
    isLoading,
    getTemplates,
    // updateTemplate,
    removeTemplate,
    ...filterProps
  } = useTemplates();

  useEffect(() => {
    stateStore.setScrollEndHandler(onScrollEnd);
    return () => {
      stateStore.resetScrollEndHandler();
    };
  }, [isLoading, templates, templates?.length]);

  const onScrollEnd = async () => {
    await getTemplates();
  }

  return (
    <>
      <PageTitle pinned>
        <TemplatesFilter {...filterProps} />
      </PageTitle>
      <Container>
        <TemplatesList
          defaultTemplateId={defaultTemplateId}
          templates={templates}
          loadingTemplates={isLoading}
          onSelect={() => openNewProjectModal(true)}
          onSetDefault={setDefaultTemplateId}
          onRemove={removeTemplate}
          // onEdit={updateTemplate}
          allowEdition
          companyTemplates={filterProps.areCompany}
        />
        {
          showNewProjectModal &&
          <NewProjectDialog
            open={showNewProjectModal}
            defaultTemplateId={defaultTemplateId}
            onClose={() => openNewProjectModal(false)}
          />
        }
      </Container>
    </>
  );
});

TemplatesView.propTypes = {
  defaultTemplateId: number,
  setDefaultTemplateId: func.isRequired,
}