import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  sectionSummaryRow: {
    background: theme.props.background,
    // "& .cell:last-child": { borderRightWidth: 1 },
    // "& .cell:first-child": { borderLeftWidth: 1 },
  },
}));

export default useStyle;
