import { useState } from "react";
import { bool, number, object, func } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MAX_WORKTYPE_LENGTH } from "project-structure";
import { createCustomWorkTypeQuery } from "@query";
import { useStructureStore, useProjectEditorStore, useEditorWebsocket } from "@hooks";
import { Alert, AsyncPicker, Tag } from "@components";

export const WorkTypeManager = observer(({
  open,
  section,
  onClose,
  anchorEl,
  sectionWorkTypeId,
  // hasSellerPrivileges,
  useLibraryManagementStyle,
  visibleWorkTypes,
}) => {

  const structure = useStructureStore();
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();
  const { t } = useTranslation();

  const [workTypeId, setWorkType] = useState(null);

  const confirmRemove = async () => {
    if (useLibraryManagementStyle) {
      structure.removeWorkType(workTypeId, false);
    } else {
      structure.historyManager.startGroup();
      const path = structure.getWorkTypeById(workTypeId).treePath.join("/");
      section.removeWorkType(workTypeId);
      const removed = structure.checkAndRemoveSectionWorkType(workTypeId);
      if(removed) {
        socket?.requestCommentPathRemove(path);
        socket?.requestCommentPathRemove("t"+path);
      }
      structure.historyManager.stopGroup();
    }

    setWorkType(null);
  };

  const handleWorkTypeRemove = (workTypeId) => {
    if (useLibraryManagementStyle) {
      const wT = structure.workTypes.find((wT) => wT.id === workTypeId);
      wT?.setBreakdownVisibility(!wT?.inBreakdown);
      return;
    }
    setWorkType(workTypeId);
  };

  const addWorkType = (workType) => {
    structure.historyManager.startGroup();
    checkStructureWorkType(workType);
    section.addWorkType(workType.id, false);
    structure.historyManager.stopGroup();
  };

  const editWorkType = (workType) => {
    structure.historyManager.startGroup();
    section.changeWorkType(sectionWorkTypeId, workType.id, true);
    checkStructureWorkType(workType);
    if (useLibraryManagementStyle) {
      structure.removeWorkType(sectionWorkTypeId);
    }
    onClose();
    structure.historyManager.stopGroup();
  };

  const checkStructureWorkType = (workType) => {
    if ( !structure.hasWorkType(workType.id) ) {
      structure.addWorkType(
        false,
        workType,
        editorStore?.getWorkTypeDefaultRate(workType.id),
        true
      );
    
      editorStore?.addWorkTypeWithStatus(workType.id);
    } else {
      const wT = structure.getWorkTypeById(workType.id);
      if (!wT.inBreakdown) wT.setBreakdownVisibility(true);
    }
  };

  const createCustomWorkType = async (v) => {
    const t = await createCustomWorkTypeQuery(v);
    addWorkType(t);
    editorStore.addCustomTag(t.id, t.name, t.backgroundColor);
  };

  const disableOption = (option) =>
    sectionWorkTypeId && section.usedWorkTypes.includes(option.id);

  return (
    <>
      <AsyncPicker
        isOpen={open}
        inputName="tag-search"
        selected={visibleWorkTypes}
        anchorEl={anchorEl}
        inputLabel={t("views.active.search_tag")}
        ContentItem={({ children, backgroundColor}) => (
          <Tag text={children} color={backgroundColor} />
        )}
        title={t(
          `views.editor.tags_edition${
            sectionWorkTypeId
              ? "_change"
              : useLibraryManagementStyle
              ? "_library"
              : ""
          }`
        )}
        options={editorStore?.workTypes.slice()}
        onClickAway={onClose}
        onAdd={sectionWorkTypeId ? editWorkType : addWorkType}
        onRemove={handleWorkTypeRemove}
        limit={10}
        disableOptions={disableOption}
        newItemText={(value) => t("views.editor.custom_workType", { value })}
        onNewItemAdd={createCustomWorkType}
        maxLength={MAX_WORKTYPE_LENGTH}
      />
      <Alert
        isOpen={Boolean(workTypeId)}
        title={t(
          `alerts.editor.${
            useLibraryManagementStyle
              ? "tag_remove_library"
              : "tag_remove_section"
          }`
        )}
        onAccept={confirmRemove}
        onCancel={() => setWorkType(null)}
        acceptText={t("common.remove")}
        cancelText={t("common.cancel")}
      />
    </>
  );
});

WorkTypeManager.propTypes = {
  show: bool,
  anchorEl: object,
  section: object.isRequired,
  onClose: func.isRequired,
  sectionWorkTypeId: number,
};
