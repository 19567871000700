import { makeStyles } from "@material-ui/core/styles";

const removableStyles = {
  "& .remove": {
    width: "max-content",
    marginLeft: 4,
    // padding: "2px 8px 2px 5px",
    opacity: 1,
    transition: "opacity .3s ease-out, width .5s ease-out",
  },
  // "& p:nth-child(1)": {
  //   margin: "3px 5px 3px 8px",
  // },
};

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    width: "max-content",
    transition: "width .5s ease-out",
    height: 24,
    padding: "0 8px",
    border: `1px solid ${theme.props.tagBorder}`,
    backgroundColor: theme.props.backgroundSecondary,
    color: theme.palette.text.primary,
  },
  colorDot: {
    borderRadius: "50%",
    height: 8,
    width: 8,
    minWidth: 8,
    marginRight: 4,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: 12,
    letterSpacing: 0.22,
  },
  titleHover: {
    "&:hover": {
      textDecoration: "underline"
    },
  },
  removableShown: removableStyles,
  removeSection: {
    cursor: "pointer",
    borderLeft: `1px solid ${theme.props.text.alt}`,
    //display: 'none',
    width: 0,
    opacity: 0,
    margin: 0,
    height: "1em",
    boxSizing: "content-box",
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  maxWidth: {
    maxWidth: 312,
  }
}));

export default useStyle;
