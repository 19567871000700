import { memo, useState } from "react";
import { bool, func, string, arrayOf, shape } from "prop-types";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { updatePaymentMethodQuery } from "@query";
import {
  ClearButton,
  Dialog,
  StaticImg,
  Table,
  TableCell,
  TableRow,
  Button,
} from "@components";
import { PaymentDialog } from "@dialogs";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import { CARD_BRAND_SRC } from "@assets";

export const PaymentMethodsDialog = memo(({
  open,
  onClose,
  onReload,
  onCardSelect,
  savedCards = [],
  defaultCard
}) => {
    const { t } = useTranslation();
    const { userStore } = useStores();
    const classes = useStyle();

    const [isLoading, setLoading] = useState(false);
    const [modalIsOpened, showModal] = useState(false);

    const updatePaymentMethod = (id) => async () => {
      try {
        setLoading(true);
        await updatePaymentMethodQuery(id);
        onCardSelect(id);
        setLoading(false);
      } catch (e) {
        console.log(e); // @todo: proper error handling
      }
    };

    const handlePaymentDialogClose = (success) => {
      if (success) onReload();
      showModal(false);
    };

    return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        width={720}
        title={t("views.subscription.dialogs.methods")}
        actions={
          <Button variant="outlined" onClick={() => showModal(true)}>
            {t("views.subscription.actions.edit_method")}
          </Button>
        }
      >
        <Elements stripe={userStore.stripePromise}>
          <Table
            id="paymentMethodsDialog"
            overflowContent
            headers={[
              {
                label: t("views.subscription.methods.manufacturer"),
                width: 150,
              },
              {
                label: t("views.subscription.methods.number"),
                width: 110,
                flexible: true,
              },
              { label: t("views.subscription.methods.valid_to"), width: 70 },
              { width: 56 },
            ]}
          >
            {savedCards.length ? (
              savedCards.map((m) => {
                const { id, brand, digits, valid } = m;

                const matchedBrand = CARD_BRAND_SRC.find(
                  (b) => b.name === brand.toLowerCase().replace(" ", "")
                );

                return (
                  <TableRow key={id}>
                    <TableCell width={150}>
                      {Boolean(matchedBrand) && (
                        <StaticImg
                          src={matchedBrand.src}
                          srcSet={matchedBrand.srcSet}
                          style={{ height: 32 }}
                        />
                      )}
                    </TableCell>
                    <TableCell width={110} flexible>
                      &#8226;&#8226;&#8226;&#8226;{" "}
                      &#8226;&#8226;&#8226;&#8226;{" "}
                      &#8226;&#8226;&#8226;&#8226; {digits}
                    </TableCell>
                    <TableCell width={70}>{valid}</TableCell>
                    <TableCell width={56}>
                      {defaultCard !== id && (
                        <Tooltip title={t("views.subscription.default_set")}>
                          <ClearButton size="medium" onClick={updatePaymentMethod(id)}>
                            {isLoading
                              ? <CircularProgress size={24} />
                              : t("common.default_set")
                            }
                          </ClearButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <p className={classes.noMethods}>
                {t("views.subscription.no_methods")}
              </p>
            )}
          </Table>
          <PaymentDialog
            open={modalIsOpened}
            onClose={handlePaymentDialogClose}
            title={t("views.subscription.actions.edit_method")}
            onlyCreateCard
          />
        </Elements>
      </Dialog>
    );
  }
);

const useStyle = makeStyles(() => ({
  noMethods: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    padding: "24px 0",
  },
}));

PaymentMethodsDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onReload: func.isRequired,
  onCardSelect: func.isRequired,
  savedCards: arrayOf(
    shape({
      id: string.isRequired,
      brand: string.isRequired,
      digits: string.isRequired,
      valid: string.isRequired,
    })
  ),
  defaultCard: string,
};
