import { APIMethod, fetchData, persistUserData } from "@client";

export async function activateUserAccountQuery(body) {
  const { data, headers } = await fetchData(
    "/user/activation",
    APIMethod.POST,
    { data: body, noAuth: true, returnFullResponse: true }
  );
  
  await persistUserData(data.user.id, headers.token, data.remember);
  return data.user;
}