import { useEffect, useState } from "react";
import { bool, func } from "prop-types";
import { getCompanySettingsQuery, setCompanySettingsQuery } from "@query";
import { COMPANY_SETTINGS } from "@client";
import { EmailInvoicesForm } from "@forms";
import { Alert, Dialog } from "@components";
import { useTranslation } from "react-i18next";


export const EmailInvoicesDialog = ({ open, onClose }) => {
  
  const { t } = useTranslation();
  
  const [defaults, setDefaults] = useState();
  const [alertVisible, setAlertVisible] = useState(false);
  
  useEffect(() => {
    loadData();
  }, []);
  
  const loadData = async () => {
    const email = await getCompanySettingsQuery(COMPANY_SETTINGS.INVOICE_EMAIL);
    const send = await getCompanySettingsQuery(COMPANY_SETTINGS.INVOICE_SEND);
    setDefaults({ email, send })
  }
  
  const handleInvoiceEmail = async ({ invoiceEmail }) => {
    await setCompanySettingsQuery(COMPANY_SETTINGS.INVOICE_EMAIL, invoiceEmail);
    setDefaults({ ...defaults, email: invoiceEmail })
    setAlertVisible(true);
  };
  
  const handleSaveInvoice = async (sendInvoiceEmail) => {
    await setCompanySettingsQuery(COMPANY_SETTINGS.INVOICE_SEND, sendInvoiceEmail);
    setDefaults({ ...defaults, send: sendInvoiceEmail })
  }
  
  const handleClose = () => {
    setAlertVisible(false);
    onClose();
  }
  
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      width={800}
      title={t("views.subscription.actions.email_invoices")}
    >
      {
        defaults &&
        <EmailInvoicesForm
          onSubmit={handleInvoiceEmail}
          onSendInvoices={handleSaveInvoice}
          email={defaults.email}
          sendInvoices={defaults.send}
        />
      }
      <Alert
        title={t("views.subscription.actions.email_invoices_success", {email: defaults?.email})}
        isOpen={alertVisible}
        onAccept={handleClose}
        acceptText={t("common.close")}
      />
    </Dialog>
  )
}

EmailInvoicesDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onSend: func,
};
