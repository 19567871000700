import { useMemo } from "react";
import { bool, func, node, oneOf, string } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useStructureStore } from "@hooks";
import { CELL_WIDTHS, MIN_FILLER_MONTHS } from "@utils";
import { HeaderRow, TitleCell } from "@components";
import { capitalize, Grid } from "@material-ui/core";
import { TimelineSectionTitleCell } from "./components/TimelineSectionTitleCell";
import classnames from "classnames";

export const TimelineSectionTitleRow = observer(({
  isProposal,
  code,
  action,
  children,
  className,
  ...rowProps
}) => {
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const structure = useStructureStore();
  const { totalDevelopmentTime } = structure;
  
  const ptClass = useMemo(() => `pt-timeline${capitalize(code)}`, [code]);
  
  return (
    <HeaderRow
      useExpandCell
      // useFiller
      altBackground
      size="medium"
      className={classnames("preset-paperTimeline min-w-max", className)}
      expandCellPresetClass="preset-timelineSubHeader"
      {...rowProps}
    >
      <TitleCell
        pinnedLeft={1}
        maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
        minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
        size="medium"
        allowOverflowDisplay
      >
        <Grid item container alignItems="center">
          <h3 className={classnames("capitalize preset-timelineSubHeader", ptClass, children && "mr-2")}>
            { getFieldName(ptClass)}
          </h3>
          { action }
        </Grid>
        { children }
      </TitleCell>
      <Grid item container wrap="nowrap">
        {
          [...new Array(Math.max(totalDevelopmentTime, MIN_FILLER_MONTHS)).keys()]
            .map((_, i) => (
              <TimelineSectionTitleCell key={`month${i}`} monthIndex={i} />
            ))
        }
      </Grid>
    </HeaderRow>
  );
});

TimelineSectionTitleRow.propTypes = {
  code: oneOf(["sections", "team", "billing"]).isRequired,
  isProposal: bool,
  action: node,
  children: node,
  expandable: bool,
  expanded: bool,
  onExpand: func,
  className: string,
};