import { bool, object, func } from "prop-types";
import { DatePicker as Picker } from "@material-ui/pickers";
import { PopMenu } from "@components";
import { useTranslation } from "react-i18next";
import { IconButton, Grid } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import useStyle from "./DatePicker.style";

export const DatePicker = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const {
    isOpen,
    anchorEl,
    value,
    changeHandler,
    cancelHandler,
    disableFuture,
    disablePast,
  } = props;

  return (
    <PopMenu
      key="top-menu"
      show={isOpen}
      anchor={anchorEl}
      onClickAway={cancelHandler}
      placement="bottom"
      arrowStyle={{ display: "none" }}
    >
      <Grid container direction="column">
        <Grid item container className={classes.header}>
          <h2>{t("common.deadline")}</h2>
          <IconButton
            color="primary"
            aria-label="close modal"
            onClick={cancelHandler}
          >
            <Close />
          </IconButton>
        </Grid>
        <Grid item container>
          <Picker
            initialFocusedDate={new Date().setHours(0, 0, 0)}
            disableToolbar
            disablePast={disablePast}
            disableFuture={disableFuture}
            orientation="landscape"
            variant="static"
            format="dd MMM yyyy"
            openTo="date"
            leftArrowButtonProps={{ color: "primary", style: { order: 1 } }}
            rightArrowButtonProps={{
              color: "primary",
              style: { order: 2, marginRight: 8 },
            }}
            value={value}
            onChange={changeHandler}
          />
        </Grid>
      </Grid>
    </PopMenu>
  );
};

DatePicker.propTypes = {
  changeHandler: func.isRequired,
  value: object.isRequired,
  isOpen: bool,
  anchorEl: object,
  cancelHandler: func.isRequired,
  disablePast: bool,
  disableFuture: bool,
};
