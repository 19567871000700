import { useMemo } from "react";
import { number } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { CollapseValueCell } from "@components";
import { Grid } from "@material-ui/core";
import useStyle from "../../TimelineSectionsRow/components/SectionCell.style";
import useCellStyle from "@components/ProjectEditor/Tables/cells/commonStyles.style";
import classnames from "classnames";

export const TimelineSectionTitleCell = observer(({ monthIndex }) => {
  
  const classes = useStyle();
  const cellClasses = useCellStyle();
  const structure = useStructureStore();
  
  const isOpened = useMemo(() => (
    structure.settings.isTimelineMonthExpanded(monthIndex)
  ), [structure.settings.expandedTimelineMonths?.size]);
  
  return (
    <CollapseValueCell
      isOpened={isOpened}
      narrowChildren
      useBorder
      isHeader
      visible
      isFirst={monthIndex === 0}
    >
      {
        isOpened &&
        [...new Array(4).keys()]
          .map((week) => (
            <Grid
              key={`week${week}`}
              item container
              alignItems="center"
              className={classnames(
                "h-full relative",
                cellClasses.fullDashBorderRight,
                classes.weekCell
              )}
            />
          ))
      }
    </CollapseValueCell>
  );
});

TimelineSectionTitleCell.propTypes = {
  monthIndex: number
};