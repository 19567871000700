import { APIMethod, fetchData } from "@client";

export async function editTeamMemberQuery(
  member,
  teamRole,
  professionalLevel,
  technicalStack
) {
  const data = {
    member,
    teamRole,
    professionalLevel,
    stackType: technicalStack.length ? `[${technicalStack.join(",")}]` : "[]",
  };
  try {
    const res = await fetchData("/team", APIMethod.PUT, {
      data,
      removeEmptyKeys: true,
      mapRequestKeysToSnakeCase: false,
    });
    return res.results;
  } catch (e) {
    return [];
  }
}
