import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EditorSocketProvider, ProjectEditorStoreProvider } from "@stores";
import { useStores } from "@hooks";
import {
  checkProjectAccessQuery,
  fetchProjectStructureQuery,
  getCompanyLogoQuery,
} from "@query";
import { PROJECT_TYPE } from "@utils";
import { home } from "@paths";
import { PageCircularProgress } from "@components";
import { ProjectLoadingAlert } from "@dialogs";
import { PdfEditorView } from "./PdfEditorView";

export const PdfEditor = () => {
  const navigate = useNavigate();
  const { userStore, stateStore } = useStores();
  const { projectUuid } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [hasError, setError] = useState(0);

  useEffect(() => {
    // const abortController = new AbortController();
    onLoad();
    return () => {
      stateStore.resetFilesUploadProgress();
      // abortController.abort();
    }
  }, []);

  const onLoad = async () => {
    const hasAccess = await checkProjectAccessQuery(projectUuid);
    if (!hasAccess) {
      navigate(home, { replace: true });
      return;
    }

    try {
      const logo = await getCompanyLogoQuery();

      const projectData = await fetchProjectStructureQuery(
        userStore.userId,
        projectUuid,
        PROJECT_TYPE.MINI
      );

      if(searchParams.get("new"))
        setSearchParams(undefined, {replace: true});

      setData({
        logo,
        projectData
      });
    } catch (e) {
      setError(2);
    }
  };

  if (hasError) return <ProjectLoadingAlert structureError={hasError === 1} />;

  return !data ? (
    <PageCircularProgress />
  ) : (
    <ProjectEditorStoreProvider projectType={PROJECT_TYPE.MINI} { ...data }>
      <EditorSocketProvider projectUuid={projectUuid}>
        <PdfEditorView />
      </EditorSocketProvider>
    </ProjectEditorStoreProvider>
  );
};
