import { useCallback, useState } from "react";
import { checkIfLibraryElementExistsQuery, moveLibraryElementQuery } from "@query";
import { useTranslation } from "react-i18next";

export const useLibraryPusher = ({
  element,
  tableDepth,
}) => {
  
  const { t } = useTranslation();
  
  const [isLoading, setLoading] = useState(false);
  const [libraryPushAlertStatus, setLibraryPushAlertStatus] = useState(null);
  const [libraryPushSuccess, setLibraryPushSuccess] = useState(false);
  
  const addToLibrary = async () => {
    if (isLoading) return;
    
    setLoading(true);
    
    if (!element.name?.length) {
      setLibraryPushAlertStatus("alerts.library.library_name_required");
      setLoading(false);
      return;
    }
    
    if (element.componentId) {
      const exists = await checkIfLibraryElementExistsQuery(
        element.componentId
      );
      if (exists) {
        setLibraryPushAlertStatus("alerts.library.library_is_already");
        setLoading(false);
        return;
      } else element.setComponentId();
    }
    
    let newTask = element;
    const updateComponentIds = (element, result) => {
      element.setComponentId(result.componentId);
      if (element.children?.length && result.children?.length)
        element.children.forEach((child, index) =>
          updateComponentIds(child, result.children[index])
        );
    };
    
    try {
      let parentComponentId =
        tableDepth > 1 ? element.parent?.componentId : undefined; // getParentLibraryStatus(element.parent);
      const updatedTask = await moveLibraryElementQuery(
        newTask.serialize(true, t("common.unnamed")),
        tableDepth,
        parentComponentId
      );
      
      updateComponentIds(newTask, updatedTask);
      setLibraryPushSuccess(true);
    } catch (e) {
      setLibraryPushAlertStatus("alerts.library.library_name_error");
    }
    
    setLoading(false);
  };
  
  const closeAlert = useCallback(() => setLibraryPushAlertStatus(false), []);
  const closeSuccessAlert = useCallback(() => setLibraryPushSuccess(false), []);
  
  return {
    isLoading,
    libraryPushAlertStatus,
    libraryPushSuccess,
    addToLibrary,
    closeAlert,
    closeSuccessAlert,
  }
}