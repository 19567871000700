import pako from "pako";
import { record } from "rrweb";
import { sendRecordingPartQuery, startRecordingSessionQuery } from "@query";

export class SessionRecorder {
  ws = null;
  sessionData = {};
  events = [];

  useCompression = false;

  shareKey;
  clientEmail;
  defaultIdleDuration = 30 * 60;
  idleDuration;
  recorderStarted = false;
  idleChecker = null;
  recordConsumer = null;
  recordEmiter = null;
  pingTimeout = 60;

  sendChecker = 0;
  maxSendDelay = 20; // for an interval that triggers every 100ms -> 20 * 100ms -> 2000ms -> 2sec
  maxPartLength = 64 * 1024;

  constructor({ shareKey, clientEmail, useCompression }) {
    this.shareKey = shareKey;
    this.clientEmail = clientEmail;
    this.idleDuration = this.defaultIdleDuration;
    this.useCompression = useCompression;

    this.sendData = this.sendData.bind(this);
  }

  init = async () => {
    window.document.addEventListener("mousemove", this.wakeUp);
    window.document.addEventListener("keyup", this.wakeUp);
    window.document.addEventListener("touchstart", this.wakeUp);
    window.addEventListener("scroll", this.wakeUp);
    window.document.addEventListener("mouseleave", this.prepareDataDispatch); // send last data before the user leaves the page
    window.document.addEventListener("keydown", this.keydownCheck);

    await this.startRecording();

    this.idleChecker = setInterval(() => {
      if (this.idleDuration <= 0 && this.recorderStarted) {
        this.idleDuration--;
        this.endRecording();
      } else if (this.idleDuration > 0) this.idleDuration--;

      if (this.ws && --this.pingTimeout < 0) {
        this.ws.send(JSON.stringify({ action: "ping" }));
        this.pingTimeout = 60;
      }
    }, 1000);
  };

  initWsConnection() {
    if (this.ws) {
      this.ws.close(1000);
      delete this.ws;
    }

    this.ws = new window.WebSocket(process.env.REACT_APP_SESSION_WEBSOCKET);

    this.ws.addEventListener("open", () => {
      this.ws.send(
        JSON.stringify({ action: "sessionstart", ...this.sessionData })
      );
    });

    this.ws.addEventListener("close", (event) => {
      // eslint-disable-next-line no-console
      console.log("Error occurred: ", JSON.stringify(event));
    });
  }

  startRecording = async () => {
    if (!this.recorderStarted) {
      this.sessionData = await startRecordingSessionQuery(
        this.shareKey,
        this.clientEmail,
        this.useCompression
      );
      this.initWsConnection();

      const that = this;
      this.recordEmiter = record({
        collectFonts: true,
        blockClass: /react-pdf__Page__((textContent)|(Thumb))/,
        inlineImages: false,
        inlineStylesheet: false,
        slimDOMOptions: {
          script: false,
          comment: false,
          headFavicon: false,
          headWhitespace: false,
          headMetaDescKeywords: false,
          headMetaSocial: false,
          headMetaRobots: false,
          headMetaHttpEquiv: false,
          headMetaAuthorship: false,
          headMetaVerification: false,
        },
        sampling: {
          mouseInteraction: {
            MouseUp: false,
            MouseDown: false,
            TouchStart: false,
            TouchEnd: false,
            ContextMenu: false,
          },
          scroll: 300,
        },
        emit(event) {
          that.events.push(event);
        },
      });
      this.recordConsumer = setInterval(() => this.prepareDataDispatch(), 100);
      this.recorderStarted = true;
      // eslint-disable-next-line no-console
      console.log("new session started!");
    }
  };

  endRecording = () => {
    if (this.recorderStarted) {
      (async () => {
        // eslint-disable-next-line no-console
        console.log("stopping current session!");
        await this.prepareDataDispatch();
        this.recorderStarted = false;
        if (this.ws) {
          this.ws.close(1000);
          delete this.ws;
        }
        if (this.recordEmiter) {
          this.recordEmiter();
          delete this.recordEmiter;
        }
        clearInterval(this.recordConsumer);
        delete this.recordConsumer;
      })();
    }
    return undefined;
  };

  prepareDataDispatch = async () => {
    if (this.events.length && this.recorderStarted) {
      this.sendChecker++;
      const body = JSON.stringify(this.events);
      if (this.sendChecker === this.maxSendDelay) {
        const timestamp = this.events[0].timestamp;
        this.sendData(body, timestamp);
        this.events = [];
        this.sendChecker = 0;
      }
    }
  };

  sendData = async (body, timestamp) => {
    if (this.useCompression) body = pako.deflate(body);
    if (body.length > this.maxPartLength) {
      for (let i = 0; i < Math.ceil(body.length / this.maxPartLength); i++) {
        const part = body.slice(
          i * this.maxPartLength,
          i * this.maxPartLength + this.maxPartLength
        );
        sendRecordingPartQuery(
          this.sessionData.sessionId,
          part,
          timestamp,
          i + 1
        );
      }
      return;
    }

    await sendRecordingPartQuery(this.sessionData.sessionId, body, timestamp);
  };

  keydownCheck = (e) => {
    if (e.key === "F5" || e.ctrlKey || e.metaKey) this.prepareDataDispatch();
  };

  wakeUp = () => {
    if (this.idleDuration < 0) this.startRecording();
    this.idleDuration = this.defaultIdleDuration;
  };

  destroy() {
    this.endRecording();
    clearInterval(this.idleChecker);

    window.document.removeEventListener("mousemove", this.wakeUp);
    window.document.removeEventListener("keyup", this.wakeUp);
    window.document.removeEventListener("touchstart", this.wakeUp);
    window.removeEventListener("scroll", this.wakeUp);
    window.document.removeEventListener("mouseleave", this.prepareDataDispatch);
    window.document.removeEventListener("keydown", this.keydownCheck);

    delete this;
  }
}
