import { useMemo } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useProjectEditorStore, useStructureStore } from "@hooks";
import { PriceCell, VisibilityButton } from "@components";

export const CostCell = observer(({
  allowEdition,
  isProposal,
  profitabilityMode,
}) => {
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { usesTwoValues, visibility } = structure;
  const { apply, hideSummaryCost } = visibility;
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryCost && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryCost]);
  
  return (
    <PriceCell
      widest={usesTwoValues}
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab" }}
    >
      <span className="preset-tableSummaryHeader pt-cost">
        {getFieldName(profitabilityMode ? "pt-revenue" : "pt-cost")}
      </span>
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={20}
          hidden={hideSummaryCost}
          onChange={structure.visibility.setHideSummaryCost}
        />
      }
    </PriceCell>
  )
})

CostCell.propTypes = {
  isProposal: bool,
  allowEdition: bool,
  profitabilityMode: bool,
}