const baseUrl = process.env.REACT_APP_ASSET_BUCKET;
const cardBaseUrl = `${process.env.REACT_APP_ASSET_BUCKET}/cards`;

export const REGISTER_BG_SRC = `${baseUrl}/registerbg.png`;
export const PATTERN_SRC = `${baseUrl}/pattern.png`;
export const PROPOSAL_THUMB_SRC = `${baseUrl}/proposal_thumb.png`;
export const CARD_BRAND_SRC = [
  { name: "visa", src: `${cardBaseUrl}/visa.png` },
  { name: "mastercard", src: `${cardBaseUrl}/mastercard.png` },
  { name: "americanexpress", src: `${cardBaseUrl}/americanexpress.png` },
  { name: "dinerscclub", src: `${cardBaseUrl}/dinerscclub.png` },
  { name: "discover", src: `${cardBaseUrl}/discover.png` },
  { name: "jcb", src: `${cardBaseUrl}/jcb.png` },
];
