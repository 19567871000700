import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  
  dialog: {
    margin: 0,
  },
  dialogContent: {
    padding: "0 !important",
    flexDirection: "row !important",
  },
  exportButton: {
    width: 160,
    height: 160,
    "& > span": {
      flexDirection: "column",
    },
    "& svg": {
      fontSize: 48,
      marginBottom: 8,
    },
  },
  label: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default useStyle;