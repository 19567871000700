import { parseCellType } from "@utils";

const maxClusterSpacing = 3;

const rowPattern = [
  ["string"],
  ["string", "empty", "number"],
  ["string", "number", "empty"],
  ["string", "number", "empty"],
  ["empty", "number"],
];

const blockedTypesForPrevOnes = [
  {},
  { empty: "string", number: "string" },
  { empty: "string", number: "string" },
  { empty: "string", number: "string" },
];

const forbiddenWords = [
  "hour",
  "workload",
  "summary",
  "price",
  "rate",
  "works",
  "duration",
]; // this words should not occur in breakdown
const questionableWords = ["sum", "total", "pm", "ux", "qa"]; // this words are usually used in summary, but may occur in breakdown

export const areaAutoSearch = (sheet) => {
  // let forbiddenWordsRows = [];
  // let questionableWordsRows = [];
  let allWrongWordRows = [];
  let differentColumnLengths = {};

  // map & search for breakdown table
  const mapping = sheet.data.map((row, i) => {
    let colStart = -1;
    let length = 0;
    let minimumMatch = [false, false]; // at least one string & number (or empty) match
    let stringColLength = 0; //sum all strings
    let offset = 0;
    let lastType = null;
    let lastNonEmpty = -1;
    let forbiddenWordColumn = -1;
    let questionableWordColumn = -1;

    const cMatch = row.map((col, k) => {
      const type = parseCellType(col);
      if (colStart < 0 && ["empty", "undefined"].includes(type)) offset = k + 1;
      else if (
        rowPattern[
          k - offset >= rowPattern.length ? rowPattern.length - 1 : k - offset
        ].includes(type) &&
        !(blockedTypesForPrevOnes[k - offset]?.[lastType] === type)
      ) {
        if (colStart < 0) colStart = k;
        if (type === "string") {
          stringColLength++;
          minimumMatch[0] = true;
        } else if (["empty", "number"].includes(type)) minimumMatch[1] = true;
        length++;
        if (type !== "empty") lastNonEmpty = k;
      } // else if(length >= 2 && type === 'string') // might be a comment
      // length++;
      // else
      // 	offset = k+1;
      else if (lastType === "string" && type === "string") stringColLength++;
      else if (offset === k) offset++;

      if (type === "string") {
        if (
          forbiddenWords.find((w) => col.toLowerCase().match(w)) &&
          k > forbiddenWordColumn
        )
          forbiddenWordColumn = k;
        if (
          questionableWords.find((w) => col.toLowerCase().match(w)) &&
          k > questionableWordColumn
        )
          questionableWordColumn = k;
      }

      lastType = type;
      return type;
    });

    minimumMatch = minimumMatch[0] && minimumMatch[1];
    if (length - 1 > lastNonEmpty) length = lastNonEmpty + 1;

    if (forbiddenWordColumn >= 0) {
      // forbiddenWordsRows.push(i);
      if (length > forbiddenWordColumn) length = forbiddenWordColumn + 1;
    }
    if (questionableWordColumn >= 0) {
      // questionableWordsRows.push(i);
      if (length > questionableWordColumn) length = questionableWordColumn + 1;
    }

    if (questionableWordColumn >= 0 || forbiddenWordColumn >= 0)
      allWrongWordRows.push(i);

    if (differentColumnLengths[length]) differentColumnLengths[length]++;
    else differentColumnLengths[length] = 1;

    return {
      start: colStart,
      columns: length,
      minimumMatch,
      stringColumns: stringColLength,
      mayBeTitle: stringColLength > 1 && !minimumMatch,
      data: cMatch,
      forbiddenWordColumn,
      questionableWordColumn,
    };
  });

  const clusters = [];
  let nonClusterStart = 0;
  let clusterStart = -100;
  let lastPossibleClusterEnd = -100;
  allWrongWordRows.forEach((rowIndex) => {
    if (rowIndex > lastPossibleClusterEnd + maxClusterSpacing) {
      if (clusterStart > 0 && clusterStart !== lastPossibleClusterEnd) {
        clusters.push(nonClusterStart, clusterStart - 1);
        nonClusterStart = lastPossibleClusterEnd + 1;
      }
      clusterStart = rowIndex;
    }
    lastPossibleClusterEnd = rowIndex;
  });
  if (
    allWrongWordRows.length &&
    allWrongWordRows.slice(-1)[0] !== clusterStart
  ) {
    clusters.push(nonClusterStart, clusterStart - 1);
    nonClusterStart = lastPossibleClusterEnd + 1;
  }
  if (nonClusterStart < mapping.length)
    clusters.push(nonClusterStart, mapping.length - 1);

  let bigestCluster = [0, 0];
  for (let i = 0; i < clusters.length; i += 2)
    if (clusters[i + 1] - clusters[i] > bigestCluster[1] - bigestCluster[0])
      bigestCluster = [clusters[i], clusters[i + 1]];

  let cStart = Infinity,
    cEnd,
    rStart,
    rEnd;
  let firstNumberCol = Infinity;
  let lastNonQuestionableMatchingRow = -1;

  mapping.forEach((row, i) => {
    const isInBiggestCluster = i >= bigestCluster[0] && i <= bigestCluster[1];
    if (
      row.forbiddenWordColumn < 0 &&
      isInBiggestCluster &&
      (row.minimumMatch || row.mayBeTitle)
    ) {
      if (row.start < cStart) cStart = row.start;
      if (rStart === undefined) rStart = i;
      if (row.columns - 1 > cEnd || cEnd === undefined) cEnd = row.columns - 1;

      const numberCol = row.data.indexOf("number");
      if (numberCol > 0 && numberCol < firstNumberCol)
        firstNumberCol = numberCol;

      if (row.questionableWordColumn < 0) lastNonQuestionableMatchingRow = i;
    }
  });

  rEnd = lastNonQuestionableMatchingRow;

  if (firstNumberCol === Infinity) firstNumberCol = 1;
  if (cStart === Infinity) cStart = 0;

  return {
    breakdownSelection:
      cStart >= 0 && rStart >= 0 && cEnd >= 0 && rEnd
        ? [cStart, rStart, cEnd, rEnd]
        : [],
    mapping,
    titleColumnsLength: firstNumberCol,
  };
};
