import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "80vw",
  },
  textarea: {
    "& input, & .MuiSelect-root": {
      height: 48,
      boxSizing: "border-box",
      padding: "14px 16px",
    },
    "& label": {
      transform: "translate(16px, 14px) scale(1)",
    },
    "& fieldset": {
      borderRadius: 5,
      borderColor: theme.palette.text.primary,
    },
    height: "max-content",
    marginBottom: 16,
    "& > div": {
      minHeight: 98,
      display: "block",
    },
    "& textarea": {
      overflowY: "auto !important",
      maxHeight: 164,
    },
  },
  button: {
    width: "max-content",
    "&:first-child": { marginRight: 24 },
  },
}));

export default useStyle;
