import { useState } from "react";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { authorizeUserQuery } from "@query";
import { register, retrievePassword } from "@paths";
import { LoginForm } from "@forms";
import { Alert, ClearButton } from "@components";
import { OrDivider } from "@views/Auth/components/OrDivider/OrDivider";
import { MicrosoftSSO } from "@views/Auth/components/MicrosoftSSO/MicrosoftSSO";
import useStyle from "./Login.style";

export const Login = () => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const classes = useStyle();

  const [alertText, setAlertText] = useState("");

  const signIn = async (data) => {
    try {
      const userData = await authorizeUserQuery(data);
      userStore.saveUserData(userData);
      if(!stateStore.memorizedAction)
        userStore.checkWorkspacesAndRedirect();
      
      await userStore.checkUserTrial();
      userStore.setLoginProcessStatus(true);
    } catch (e) {
      if (e.response?.data?.errors) {
        const errors = e?.response.data.errors;
        if (errors) return { errors };
      } else {
        console.error(e);
        setAlertText(t("errors.network"));
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      wrap="nowrap"
      className="h-full pt-3"
    >
      <Grid item container>
        <LoginForm onSubmit={signIn} />

        <OrDivider />
        <MicrosoftSSO />

        <ClearButton to={retrievePassword} className={classes.forgot}>
          {t("views.login.no_password")}
        </ClearButton>
      </Grid>
      <div className={classes.bottomText}>
        <p className={classes.askText}>{t("views.login.no_account")}</p>
        <ClearButton to={register}>{t("views.login.sign_here")}</ClearButton>
      </div>
      <Alert
        isOpen={Boolean(alertText)}
        title={alertText}
        acceptText={t("common.close")}
        onAccept={() => setAlertText("")}
      />
    </Grid>
  );
};
