import { AppStateStore } from "./AppStateStore";
import { MessageStore } from "./MessageStore";
import { UserStore } from "./UserStore";

export class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.messageStore = new MessageStore();
    this.stateStore = new AppStateStore(this);
  }
}
