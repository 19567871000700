import { makeStyles } from "@material-ui/core/styles";
import { DEFAULT_FONT_NAME } from "@utils";

const useStyle = makeStyles((theme) => ({
  separator: {
    width: 2,
    marginLeft: 8,
    marginRight: 8,
    height: 24,
    opacity: 0.5,
    backgroundColor: theme.props.border,
  },
  menuBox: {
    paddingLeft: 13,
    width: "100%",
    color: theme.props.text.special,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: "100%",
    overflow: "hidden",
  },
  menuName: {
    fontFamily: `${DEFAULT_FONT_NAME}, sans-serif !important`,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.47,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  menuCompany: {
    fontSize: 10,
    letterSpacing: 0.33,
    fontFamily: "${DEFAULT_FONT_NAME}, sans-serif !important",
  },
  menuTop: {
    borderBottom: `1px solid ${theme.props.border}`,
    width: "100%",
    padding: 12,
    marginBottom: 8,
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
  },
  paymentContainer: {
    borderBottom: `1px solid ${theme.props.border}`,
    paddingBottom: 8,
  },
  upgradeButton: {
    width: "75%",
  },
}));

export default useStyle;
