import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  title: {
    marginTop: 0,
    marginBottom: 24,
  },
  video: {
    marginTop: 24,
    borderRadius: 5,
    border: `1px solid ${theme.props.border}`,
    width: "100%",
    height: "",
  },
}));

export default useStyle;
