import { useEffect, useState } from "react";
import { bool, func, string } from "prop-types";
import { Dialog, TextField, Button } from "@components";
import { CircularProgress } from "@material-ui/core";

export const NameEditorModal = (props) => {
  const {
    open,
    onClose,
    confirmButtonTitle,
    title,
    defaultName,
    useBackdropClick,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    setName(defaultName || "");
  }, [open]);

  const handleClick = async () => {
    setLoading(true);
    await onClose(name.trim());
    setLoading(false);
  };

  const handleChange = (e) => {
    setName(e.target.value.replace(/[$\\+[\]^{}`]/g, "").slice(0, 30));
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      width={320}
      useBackdropClick={useBackdropClick}
      containerClass="py-3"
      actions={
        <Button
          disabled={!name || defaultName === name}
          variant="contained"
          onClick={handleClick}
          style={{width: 160}}
        >
          {
            isLoading
              ? <CircularProgress size={16} className="text-alt" />
              : confirmButtonTitle
          }
        </Button>
      }
    >
      <TextField
        value={name}
        label={title}
        onChange={handleChange}
        className="my-4"
      />
    </Dialog>
  );
};

NameEditorModal.propTypes = {
  title: string,
  confirmButtonTitle: string,
  open: bool.isRequired,
  onClose: func.isRequired,
  defaultName: string,
  useBackdropClick: bool,
};
