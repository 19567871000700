import { number, func, string, bool } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { Select, MenuItem, IconButton } from "@material-ui/core";
import useStyle from "./TablePagination.style";
import { useMemo } from "react";

export const TablePagination = ({
  page,
  total,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  className,
  useSmallerPageSize,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const handleOnRowsPerPageChange = (e) => {
    onRowsPerPageChange(e.target.value);
    onPageChange(0);
  };
  
  const rowOptions = useMemo(() => (
    useSmallerPageSize ? [5,10] : [5,10,20]
  ), [useSmallerPageSize])

  const minPage = 0;
  const maxPage = Math.ceil(total / rowsPerPage);

  return (
    <div className={`${classes.root} ${className}`}>
      <p className={classes.total}>
        {total} {t("views.archived.projects")}
      </p>
      <div className={classes.actions}>
        <p>
          {t("views.archived.show")} {" : "}
        </p>
        <Select
          variant="outlined"
          className={classes.selectBox}
          id="pagination-rows"
          aria-label={"number of rows per page selection"}
          value={rowsPerPage}
          onChange={handleOnRowsPerPageChange}
          IconComponent={KeyboardArrowDown}
          MenuProps={{ className: classes.selectMenu }}
        >
          {rowOptions.map((r) => (
            <MenuItem key={r} value={r}>
              {r}
            </MenuItem>
          ))}
        </Select>
        <div className={classes.pageBox}>
          <IconButton
            className={classes.pageButton}
            disabled={page <= minPage}
            title={t("common.prev_page")}
            aria-label={"previous page button"}
            onClick={() => onPageChange(page - 1)}
          >
            <ChevronLeft />
          </IconButton>
          <p className={classes.pageNum}>{page + 1}</p>
          <IconButton
            className={classes.pageButton}
            disabled={page >= maxPage - 1}
            title={t("common.next_page")}
            aria-label={"next page button"}
            onClick={() => onPageChange(page + 1)}
          >
            <ChevronRight />
          </IconButton>
        </div>
        <p className={classes.ofPages}>
          {t("common.of")} {maxPage}
        </p>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  page: number.isRequired,
  total: number.isRequired,
  rowsPerPage: number.isRequired,
  onPageChange: func.isRequired,
  onRowsPerPageChange: func.isRequired,
  className: string,
  useSmallerPageSize: bool,
};
