import { useEffect, useMemo, useState } from "react";
import { bool, func, number } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { structureParser } from "project-structure";
import {
  createTemplateQuery,
  setProjectWorkTypesQuery,
  createNewProjectQuery,
  getWorkTypesQuery,
  pushProjectStructureQuery,
  setProjectPresetQuery,
  getCompanySettingsQuery,
  getTemplateQuery,
} from "@query";
import { COMPANY_SETTINGS } from "@client";
import { useProjectCreatorStore, useS3Uploader, useStores } from "@hooks";
import { editorNewTemplate, editorNew, pdfEditorNew } from "@paths";
import { DEFAULT_WORK_TYPES, PROJECT_TYPE, TEMPLATE_TYPE } from "@utils";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  ControlledTextField,
  InfoLabel,
  FileUploader,
} from "@components";
import { TemplatesDialog, CreationProgress } from "@dialogs";
import { XlsImporter } from "@tools";
import { TemplateSelector } from "./components";
import { CircularProgress, Grid, useTheme } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const newProjectFormSchema = (t, isStandard) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("errors.project.name.required"))
      .min(4, t("errors.project.name.min"))
      .max(64, t("errors.project.name.max")),
    description: yup.string(),
    workTypes: isStandard
      ? yup
          .array()
          .of(yup.mixed())
          .min(1, t("errors.project.workType.required"))
          .required(t("errors.project.workType.required"))
          .default([])
      : yup.array().of(yup.number()).default([]),
  });

export const NewProjectDialog = observer(({
  open,
  onClose,
  useTemplatesDialog,
  defaultTemplateId: remoteDefaultTemplateId
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userStore, stateStore } = useStores();
    const creator = useProjectCreatorStore();

    const theme = useTheme();

    const [projectType, setProjectType] = useState("standard");

    const {
      control,
      handleSubmit,
      setValue,
      formState: { isSubmitting },
    } = useForm({
      resolver: yupResolver(
        newProjectFormSchema(t, projectType === "standard")
      ),
      defaultValues: {
        name: "",
        description: "",
        workTypes: DEFAULT_WORK_TYPES,
      },
    });

    const { uploadProposal } = useS3Uploader();

    const [defaultTemplateId, setDefaultTemplateId] = useState(remoteDefaultTemplateId);
    const [workTypesLoading, setWorkTypesLoading] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [templatesDialogOpened, openTemplatesDialog] = useState(false);
    const [importDialogOpened, openImportDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [importTemplate, setImportTemplate] = useState(false);

    const { isMiniUser, workspaceUuid } = userStore;
    const {
      hasXlsFile,
      files,
      status,
      workTypes: availableWorkTypes,
      xlsProjectStructure,
      template,
      projectWorkTypes,
    } = creator;

    const isMini = useMemo(
      () => projectType === PROJECT_TYPE.MINI,
      [projectType]
    );

    useEffect(() => {
      if (isMiniUser) setProjectType(PROJECT_TYPE.MINI);
      else {
        setWorkTypesLoading(true);
        (async () => {
          const defaultTemplateId = useTemplatesDialog
            ? await getCompanySettingsQuery(COMPANY_SETTINGS.TEMPLATE)
            : remoteDefaultTemplateId;
          
          let workTypes = creator.workTypes;
          if (!workTypes.length) {
            workTypes = await getWorkTypesQuery();
            creator.setWorkTypes(workTypes);
          }
          
          let wTSet = Boolean(projectWorkTypes?.length);
          if(defaultTemplateId && !creator.template) {
            setDefaultTemplateId(defaultTemplateId);
            const template = await getTemplateQuery(defaultTemplateId);
            if(template)
              creator.setTemplate({
                id: defaultTemplateId,
                ...template
              }, template.company);
            if(template.metadata) {
              const wT = JSON.parse(template.metadata)?.workTypes
                ?.filter(mWT => workTypes.find(wT => wT.id === mWT.id))
                ?.map(mWT => mWT.id);
              if(wT?.length) {
                creator.setProjectWorkTypes(wT);
                wTSet = true;
              }
            }
          }
          
          if(!wTSet)
            creator.setProjectWorkTypes(DEFAULT_WORK_TYPES.filter(wId => workTypes.find(wT => wT.id === wId)))
          
          setWorkTypesLoading(false);
        })();
      }

      return () => {
        creator.clearProjectData();
      };
    }, []);
    
    useEffect(() => {
      setValue("workTypes", projectWorkTypes);
    }, [projectWorkTypes])

    const handleWorkTypesChange = (formChange) => (workTypes, newWorkType, removedWorkType) => {
      formChange(workTypes);
      creator.setProjectWorkTypes(workTypes);
      if(removedWorkType && typeof removedWorkType === "string") {
        creator.removeWorkType(removedWorkType);
      }
    };

    const addCustomWorkType = (name) => {
      const newWT = {
        id: new Date().getTime().toString(),
        name,
        custom: true,
        backgroundColor: theme.palette.primary.main,
      };
      creator.addWorkType(newWT);
      return newWT;
    };

    const saveProject = async ({ name, description, workTypes }) => {
      setShowProgress(true);
      try {
        const uuid = await createNewProjectQuery(
          workspaceUuid,
          name,
          description,
          status,
          projectType,
          template
        );
        if (!isMini) {
          await setProjectWorkTypesQuery(
            uuid,
            workTypes,
            creator.workTypes.filter(w => w.custom && workTypes.includes(w.id))
          );

          if (hasXlsFile)
            await pushProjectStructureQuery(uuid, xlsProjectStructure);
          // else if (!template) {
          //   const data = structureParser({
          //     workTypes: availableWorkTypes.filter(wT => workTypes.includes(wT.id)),
          //     createNewTags: true,
          //   })
          //   await pushProjectStructureQuery(uuid, data);
          // }

          if (importTemplate) {
            await createTemplateQuery(uuid, { name, description });
            setImportTemplate(false);
          }
          
          const defaultPresetId = await getCompanySettingsQuery(COMPANY_SETTINGS.PRESET);
          if(defaultPresetId && defaultPresetId !== -1) {
            await setProjectPresetQuery(uuid, defaultPresetId);
          }
        } else if (isMini && files?.length) {
          let index = 0;
          stateStore.resetFilesUploadProgress(files.length);
          for (let file of files) {
            await uploadProposal(uuid, file, index);
            index++;
          }
          stateStore.resetFilesUploadProgress();
        }

        // if(!projectStore.projects.length)
        //   pushGTagEvent("created_first_project");

        // stateStore.setProjectCreated(true);
        setShowProgress(false);
        navigate(isMini ? pdfEditorNew(uuid) : (template?.company ? editorNewTemplate(uuid) : editorNew(uuid)));
        creator.clearProjectData();
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error)
          setErrorMessage(err.response.data.error);
        else if (err.message) setErrorMessage(err.message);
      }
    };

    const handleButtonClick = () => {
      useTemplatesDialog ? handleTemplatesDialogOpen() : onClose();
    };

    const cancelSaving = () => {
      setErrorMessage("");
      setShowProgress(false);
    };

    const handleTemplatesDialogOpen = () => {
      openTemplatesDialog(!templatesDialogOpened);
      window.userGuiding?.previewGuide(65707);
    };

    const handleTemplatesClose = (type) => {
      if (type === TEMPLATE_TYPE.XLS) openImportDialog(true);
      openTemplatesDialog(false);
    };

    return (
      <Dialog
        id="newProject"
        open={open}
        onClose={onClose}
        actionsClass="newProjectForm"
        containerId="newProjectForm"
        containerClass="newProjectForm py-3"
        actionsAlignment={hasXlsFile ? "spaceBetween" : "end"}
        width={600}
        title={t("views.new_project.title")}
        actions={
          <>
            {hasXlsFile && (
              <Checkbox
                name="importTemplate"
                checked={importTemplate}
                onChange={setImportTemplate}
                label={t("views.new_project.import_template")}
              />
            )}
            <Button
              variant="contained"
              onClick={handleSubmit(saveProject)}
              endIcon={
                isSubmitting ? (
                  <CircularProgress
                    aria-label="progress indicator"
                    size={24}
                    color="inherit"
                  />
                ) : undefined
              }
            >
              {t("views.new_project.title_short")}
            </Button>
          </>
        }
      >
        {/*<ScrollBar className={classnames(classes.scrollbar, isMobile ? "px-3" : "px-6")}>*/}
          <Grid
            item
            container
            direction="column"
            wrap="nowrap"
            // className={classnames("h-max", isMobile ? "py-3" : "py-6")}
          >
            <InfoLabel spacing={8} label="views.new_project.name" />
            <ControlledTextField
              control={control}
              name="name"
              placeholder={t("views.new_project.enter_name")}
            />

            <InfoLabel spacing={8} label="views.new_project.desc" />
            <ControlledTextField
              control={control}
              name="description"
              multiline
              placeholder={t("views.new_project.enter_desc")}
              minRows={2}
            />

            {isMini ? (
              <>
                <InfoLabel spacing={8} label="views.new_project.proposal_pdf_upload" />
                <FileUploader
                  onAdd={creator.setFiles}
                  onRemove={creator.setFiles}
                  files={files}
                  maxNameLength={120}
                  style={{ flexShrink: 0 }}
                  // multiple
                  acceptedFormats=".pdf"
                  // text={t(`views.new_project.${multiple ? "pdfs_drag" : "pdf_drag"}`)}
                  // textActive={t(`views.new_project.${multiple ? "pdfs_drop" : "pdf_drop"}`)}
                  text={t("views.new_project.pdf_drag")}
                  textActive={t("views.new_project.pdf_drop")}
                  showBrowseLink
                />
              </>
            ) : (
              <>
                <InfoLabel spacing={8} label="views.new_project.template" />
                <TemplateSelector onClick={handleButtonClick} />
              </>
            )}
            
            
            {!isMini && (
              <>
                <InfoLabel
                  label="common.work_type"
                  tooltipText="views.new_project.work_type_desc"
                />
                <Controller
                  name="workTypes"
                  control={control}
                  render={({
                    field: { onChange, name },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      id="workTypeSelect"
                      value={projectWorkTypes}
                      options={availableWorkTypes}
                      fullWidth
                      multiple
                      idKey="id"
                      labelKey="name"
                      onChange={handleWorkTypesChange(onChange)}
                      onOptionCreate={addCustomWorkType}
                      name={name}
                      dataLoading={workTypesLoading}
                      error={!!error}
                      helperText={error?.message}
                      // InputProps={{ className: "px-4 py-1-5" }}
                      inputProps={{ className: "mb-0" }}
                      inputPlaceholder={t("views.new_project.enter_system")}
                      getOptionDisabled={() => projectWorkTypes.length >= 10}
                    />
                  )}
                />
              </>
            )}
          </Grid>
        {/*</ScrollBar>*/}
        <Alert
          isOpen={showModal}
          title={t("views.new_project.finish")}
          acceptText={t("views.project.create")}
          onCancel={() => setShowModal(false)}
          onAccept={saveProject}
        />
        <CreationProgress
          open={showProgress}
          onCancel={cancelSaving}
          errorMessage={errorMessage}
        />

        {useTemplatesDialog && !isMiniUser && (
          <TemplatesDialog
            open={templatesDialogOpened}
            onClose={handleTemplatesClose}
            defaultTemplateId={defaultTemplateId}
          />
        )}
        {importDialogOpened && (
          <XlsImporter
            modalOpened={importDialogOpened}
            onClose={() => openImportDialog(false)}
          />
        )}
      </Dialog>
    );
  }
);

NewProjectDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  useTemplatesDialog: bool,
  defaultTemplateId: number,
};
