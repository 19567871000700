import { memo } from "react";
import { arrayOf, number, shape, string } from "prop-types";
import { Grid } from "@material-ui/core";

export const TimelineSectionBarTooltip = memo(({
  name,
  values,
}) => {
  const nonZeroValues = values.filter(v => v.hours);
  
  return (
    <Grid item container direction="column" className="font-bold">
      <span>
        {name}
        {!!nonZeroValues?.length && ": "}
      </span>
      {
        nonZeroValues
          .map(({name, hours}) => (
            <span key={name}>
            {name}{": "}{hours}
          </span>
          ))
      }
    </Grid>
  )
});

TimelineSectionBarTooltip.propTypes = {
  name: string.isRequired,
  values: arrayOf(shape({
    hours: number.isRequired,
    name: string.isRequired,
  })).isRequired,
};