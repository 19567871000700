import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ControlledTextField } from "@components";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailRegex } from "./emailRegex";

const passwordRetrieveFormSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, t("errors.email.required"))
      .required(t("errors.email.required")),
  });

export const PasswordRetrieveForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(passwordRetrieveFormSchema(t)),
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="password retrieve form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <ControlledTextField
        name="email"
        control={control}
        label={t("forms.email")}
        autoComplete="email"
        type="email"
        fullWidth
      />

      <Button
        isSubmit
        name="submit"
        variant="contained"
        fullWidth
        className="mt-6"
        endIcon={
          isSubmitting ? (
            <CircularProgress
              aria-label="progress indicator"
              size={24}
              color="inherit"
            />
          ) : undefined
        }
      >
        {t("views.retrieve_password.send")}
      </Button>
    </form>
  );
};

PasswordRetrieveForm.propTypes = {
  onSubmit: func.isRequired,
};
