// Material components
import { SvgIcon } from "@material-ui/core";

export default (props) => (
  <SvgIcon {...props} viewBox="0 0 103 35">
    <filter id="darken">
      <feComponentTransfer>
        <feFuncR type="linear" slope="0.7" />
        <feFuncG type="linear" slope="0.7" />
        <feFuncB type="linear" slope="0.7" />
      </feComponentTransfer>
    </filter>
    <path
      d="M0 5.5L25 9.58065L10 25V34.5L0 25V5.5Z"
      fill="currentColor"
      filter="url(#darken)"
    />
    <path
      d="M0 4.84211C0 2.16788 2.16788 0 4.84211 0H98.1579C100.832 0 103 2.16788 103 4.84211V20.1579C103 22.8321 100.832 25 98.1579 25H0V4.84211Z"
      fill="currentColor"
    />
  </SvgIcon>
);
