export const vatTypes = [
  "au_abn",
  "eu_vat",
  "br_cnpj",
  "ca_bn",
  "cl_tin",
  "hk_br",
  "in_gst",
  "id_npwp",
  "il_vat",
  "jp_cn",
  //'jp_rn',
  "kr_brn",
  "li_uid",
  //'my_frp',
  //'my_itn',
  //'my_sst',
  "mx_rfc",
  "nz_gst",
  "no_vat",
  "ru_inn",
  //'ru_kpp',
  "sa_vat",
  "sg_gst",
  //'sg_uen',
  "za_vat",
  //'es_cif',
  "ch_vat",
  "tw_vat",
  "th_vat",
  "ae_trn",
  "gb_vat",
  "us_ein",
];

export const vatFormatReplacement = {
  "#": /[0-9]/,
  "@": /[a-zA-Z]/,
  "%": /[a-zA-Z0-9]/,
}
export const vatFormats = [
  { sCode: "au_abn", code: "AU", format: "##-#######-##" },
  { sCode: "eu_vat", code: "AT", format: "ATU########" },
  { sCode: "eu_vat", code: "BE", format: "BE##########" },
  { sCode: "eu_vat", code: "BG", format: "BG##########" },
  { sCode: "eu_vat", code: "HR", format: "HR###########" },
  { sCode: "eu_vat", code: "CY", format: "CY#########" },
  { sCode: "eu_vat", code: "CZ", format: "CZ##########" },
  { sCode: "eu_vat", code: "DK", format: "DK########" },
  { sCode: "eu_vat", code: "EE", format: "EE#########" },
  { sCode: "eu_vat", code: "FI", format: "FI########" },
  { sCode: "eu_vat", code: "FR", format: "FR@@#########" },
  { sCode: "eu_vat", code: "DE", format: "DE#########" },
  { sCode: "eu_vat", code: "EL", format: "EL#########" },
  { sCode: "eu_vat", code: "HU", format: "HU########" },
  { sCode: "eu_vat", code: "IE", format: "IE#######@@" },
  { sCode: "eu_vat", code: "IT", format: "IT##########" },
  { sCode: "eu_vat", code: "LV", format: "LV###########" },
  { sCode: "eu_vat", code: "LT", format: "LT############" },
  { sCode: "eu_vat", code: "LU", format: "LU########" },
  { sCode: "eu_vat", code: "MT", format: "MT########" },
  { sCode: "eu_vat", code: "NL", format: "NL#########B##" },
  { sCode: "eu_vat", code: "PL", format: "PL##########" },
  { sCode: "eu_vat", code: "PT", format: "PT#########" },
  { sCode: "eu_vat", code: "RO", format: "RO##########" },
  { sCode: "eu_vat", code: "SK", format: "SK##########" },
  { sCode: "eu_vat", code: "SI", format: "SI########" },
  { sCode: "eu_vat", code: "ES", format: "ES@#######@" },
  { sCode: "eu_vat", code: "SE", format: "SE############" },
  { sCode: "eu_vat", code: "SE", format: "SE############" },
  // { sCode: "eu_vat", code: "GB", format: "XI#########" },
  
  { sCode: "br_cnpj", code: "BR", format: "##.###.###/####-##" },
  { sCode: "ca_bn", code: "CA", format: "#########" },
  { sCode: "cl_tin", code: "CL", format: "##.###.###-K" },
  { sCode: "hk_br", code: "HK", format: "########" },
  { sCode: "in_gst", code: "IN", format: "%%%%%%%%%%%%%%%" },
  { sCode: "id_npwp", code: "ID", format: "##.###.###.#-###.###" },
  { sCode: "il_vat", code: "IL", format: "#########" },
  { sCode: "jp_cn", code: "JP", format: "#############" },
  { sCode: "li_uid", code: "LV", format: "LV###########" },
  { sCode: "kr_brn", code: "KR", format: "###-##-#####" },
  { sCode: "mx_rfc", code: "MX", format: "@@@######@@@" },
  { sCode: "nz_gst", code: "NZ", format: "#########" },
  { sCode: "no_vat", code: "NO", format: "#########MVA" },
  { sCode: "ru_inn", code: "RU", format: "##########" },
  { sCode: "sa_vat", code: "SA", format: "###############" },
  { sCode: "sg_gst", code: "SG", format: "M########%" },
  { sCode: "za_vat", code: "ZA", format: "4#########" },
  { sCode: "ch_vat", code: "CH", format: "CHE-###.###.### MWST" },
  { sCode: "tw_vat", code: "TW", format: "########" },
  { sCode: "th_vat", code: "TH", format: "#############" },
  { sCode: "ae_trn", code: "AE", format: "###############" },
  { sCode: "gb_vat", code: "GB", format: "GB#########" },
  { sCode: "us_ein", code: "US", format: "##-#######" },
]