import { bool, string } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { CELL_WIDTHS, MIN_FILLER_MONTHS } from "@utils";
import { TitleCell, HeaderRow } from "@components";
import { TableHeaderCell } from "./components/TableHeaderCell";

export const TableHeader = observer(({
  isProposal,
  tableIdentifier,
  useClientActions,
}) => {

  const structure = useStructureStore();
  const { totalDevelopmentTime, } = structure;

  return (
    <HeaderRow
      id={tableIdentifier}
      useExpandCell
      altBackground
      className="preset-paperTimeline"
      expandCellPresetClass="preset-tableTimelineHeader"
    >
      <TitleCell
        pinnedLeft={1}
        maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
        minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
        allowOverflowDisplay
      />
      {
        [...new Array(Math.max(totalDevelopmentTime, MIN_FILLER_MONTHS)).keys()]
          .map((i) => <TableHeaderCell
            key={`month${i}`}
            monthIndex={i}
            isProposal={isProposal}
            useClientActions={useClientActions}
          />)
      }
    </HeaderRow>
  );
});

TableHeader.propTypes = {
  tableIdentifier: string.isRequired,
  blockExpansions: bool,
  allowEdition: bool,
  useClientActions: bool,
};
