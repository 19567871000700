import { APIMethod, fetchData } from "@client";

export async function removeTeamMemberQuery(member) {
  try {
    const res = await fetchData("/team", APIMethod.DELETE, {
      data: { member },
    });
    return res.results;
  } catch (e) {
    return [];
  }
}
