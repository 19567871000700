import { fetchData } from "@client";

export async function getSubscriptionPlansQuery() {
  try {
    const res = await fetchData("/premium/package");
    if (res)
      return res.filter(
        (x) => !x.code.includes("package_enterprise", "package_trial")
      );
  } catch (e) {
    console.error(e);
  }

  return [];
}
