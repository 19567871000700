import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  
  "color-info": {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  "color-warning": {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
  "color-danger": {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

export default useStyle;