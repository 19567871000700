import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  spinner: {
    left: "50%",
    position: "relative",
    marginLeft: -32
  },
  noArchived: {
    margin: 16,
  },
}));

export default useStyle;
