import { ValueCell } from "@components";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { useMemo } from "react";

export const RateCell = observer(() => {
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { apply, hideSummaryRate } = structure.visibility;
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryRate && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryRate]);
  
  return (
    <ValueCell
      widest
      className={className}
    />
  )
})