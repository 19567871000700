import axios from "axios";
import { parseFormData } from "@utils/dataParsers/parseFormData";
import { ACCESS_DOMAINS } from "@client";
import { mapKeysToCamelCase, mapKeysToSnakeCase } from "./utils/fetchApiUtils";
import { fetchDataLogoutHandler } from "./middleware/fetchDataLogoutHandler";
import { fetchDataResponseInterceptor } from "./middleware/fetchDataResponseInterceptor";

axios.defaults.baseURL = ACCESS_DOMAINS.api;
axios.defaults.headers.put["Content-Type"] =
  "application/x-www-form-urlencoded";

axios.interceptors.response.use(
  fetchDataResponseInterceptor,
  fetchDataLogoutHandler
);

export const APIMethod = {
  GET: "GET",
  PATCH: "PATCH",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const fetchData = async (url, method = APIMethod.GET, options) => {
  const {
    data,
    params,
    headers: customHeaders,
    baseURL,
    removeEmptyKeys,
    noAuth,
    returnFullResponse,
    onUploadProgress,
    onDownloadProgress,
    mapResponseKeysToCamelCase = true,
    mapRequestKeysToSnakeCase = true,
    ...axiosOptions
  } = options || {};

  let headers = {};

  if (customHeaders) headers = { ...customHeaders };

  if (noAuth && axios.defaults.headers.common["Authorization"]) headers["Authorization"] = "";

  const response = await axios({
    ...axiosOptions,
    onUploadProgress,
    onDownloadProgress,
    method: method || (data ? "POST" : "GET"),
    url,
    baseURL,
    headers,
    params:
      params &&
      (params instanceof URLSearchParams || !mapRequestKeysToSnakeCase
        ? params
        : mapKeysToSnakeCase(params)),
    data:
      data &&
      (typeof data === "string"
        ? data
        : parseFormData(data, removeEmptyKeys, mapRequestKeysToSnakeCase)),
  });

  const d = mapResponseKeysToCamelCase
    ? mapKeysToCamelCase(response.data)
    : response.data;
  return returnFullResponse
    ? {...response, data: d}
    : d
};
