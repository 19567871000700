import { number } from "prop-types";
import useStyle from "../RowTitle.style";
import Grid from "@material-ui/core/Grid";

export const TeamMembersTag = ({ teamMembers }) => {
  const classes = useStyle();

  if (!teamMembers || teamMembers === 1) return <></>;

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      className={classes.teamMembers}
    >
      {teamMembers}
    </Grid>
  );
};

TeamMembersTag.propTypes = {
  teamMembers: number,
};
