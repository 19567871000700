import { APIMethod, fetchData } from "@client";

export async function inviteToWorkspaceQuery(emails = []) {
  const invites = emails.map(
    ({ email, role }) =>
      new Promise(async (resolve) => {
        try {
          const res = await fetchData("/workspace/invite", APIMethod.PUT, {
            data: { email, role },
          });
          resolve({ email, hasError: !!res?.error, hasDomainError: false });
        } catch (error) {
          console.log(error);
          const hasDomainError = error.response.data.message.includes("generic");
          resolve({ email, hasError: !hasDomainError, hasDomainError });
          // if any error pops up here, it's probably because email is already in workspace
        }
      })
  );

  return Promise.all(invites);
}
