import { APIMethod, fetchData } from "@client";

export async function registerUserQuery(data, inviteCode) {
  const res = await fetchData(
    `/user/${inviteCode ? "invited-activation" : "register"}`,
    APIMethod.POST,
    {
      data: inviteCode ? { ...data, code: inviteCode }: data,
      removeEmptyKeys: true, noAuth: true
    }
  );
  
  return res.user;
}