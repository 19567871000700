import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  dragContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyle;
