import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Button, PageTitle } from "@components";
import { Container } from "@material-ui/core";
import { Rocket } from "@assets";
import useStyle from "./JiraIntegration.style";

export const JiraIntegration = () => {
  const classes = useStyle(isMobile);
  const { t } = useTranslation();

  return (
    <>
      <PageTitle center />
      <Container className="items-center">
        <h3 className={classes.text}>{t("views.jira.text1")}</h3>
        <h3 className={classes.text}>{t("views.jira.text2")}</h3>
        <Button
          variant="contained"
          icon={<Rocket />}
          className={classes.button}
          href={process.env.REACT_APP_ATLASSIAN_PAGE}
        >
          {t("views.jira.button")}
        </Button>
        <iframe
          className={classes.embed}
          src={process.env.REACT_APP_INTEGRATION_VIDEO}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Container>
    </>
  );
};
