import { APIMethod, fetchData, persistUserData } from "@client";

export async function authorizeUserQuery({ email, password, remember }) {
  const body = { email, password, remember: remember ? 1 : 0 };
  const { data, headers } = await fetchData(
    "/user/authorize",
    APIMethod.POST,
    { data: body, removeEmptyKeys: true, noAuth: true, returnFullResponse: true }
  );
  
  await persistUserData(data.user.id, headers.token, data.remember);
  
  return data.user;
}