import { object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { ValueCell } from "@components";

export const RateCell = observer(({ workType }) => {
  const structure = useStructureStore();
  const { currencyObj } = structure.settings;
  const { parsedRate } = workType;

  return (
    <ValueCell
      widest
      displayContent
      value={parsedRate}
      max={9999}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={`${currencyObj.symbolEnd || ""}/h`}
      textPresetClass="preset-summaryText"
      showDecimals
      useSeparator
    ></ValueCell>
  );
});

RateCell.propTypes = {
  workType: object.isRequired,
};
