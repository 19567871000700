import { useEffect, useRef } from "react";
import { func, number, object } from "prop-types";
import useStyle from "./CellRescale.style";
import classnames from "classnames";

export const CellRescale = ({
  cellRef,
  maxWidth,
  minWidth,
  currentCellWidth,
  offset=0,
  setWidth,
  maxNextCellWidth=0,
  minNextCellWidth=0,
  currentNextCellWidth=0,
  currentNextCellMaxWidth=0,
  setNextCellWidth
}) => {
  
  const classes = useStyle();
  
  const widthRef = useRef();
  const handleRef = useRef();
  const isDragged = useRef(false);
  
  const handleMouseMove = (e) => {
    if (!cellRef?.current || !isDragged.current)
      return;
    
    const newWidth = Math.round(
      Math.max(
        Math.min(
          e.clientX - cellRef?.current.getBoundingClientRect().x,
          maxWidth-offset
        ),
        minWidth-offset
      )
    );
    let nextSiblingWidth = 0;
    if(setNextCellWidth) {
      nextSiblingWidth = Math.round(
        Math.max(
          Math.min(
            currentNextCellWidth + currentCellWidth - newWidth, maxNextCellWidth
          ), minNextCellWidth
        )
      );
      const nextSiblingMaxWidth = Math.round(
        Math.max(
          Math.min(
            currentNextCellMaxWidth + currentCellWidth - newWidth, maxNextCellWidth
          ), minNextCellWidth
        )
      );
      cellRef.current.nextSibling.style.minWidth = `${nextSiblingWidth}px`;
      cellRef.current.nextSibling.style.maxWidth = `${nextSiblingMaxWidth}px`;
      cellRef.current.nextSibling.style.left = `${newWidth+offset}px`;
      
      cellRef.current.style.minWidth = `${newWidth}px`;
      cellRef.current.style.maxWidth = `${newWidth}px`;
    } else {
      cellRef.current.firstChild.style.minWidth = `${newWidth-8}px`;
      cellRef.current.firstChild.style.maxWidth = `${newWidth-8}px`;
    }
    widthRef.current = { newWidth: newWidth, nextSiblingWidth };
  }
  
  const handleMouseDown = (e) => {
    if(e.button !== 0) return;
    styleHandle(true);
    
    // if(!setNextCellWidth && cellRef.current.style.maxWidth)
    //   cellRef.current.style.maxWidth = "";
    
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };
  
  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    
    if(!widthRef.current || !isDragged.current) return;
    if(widthRef.current.nextSiblingWidth) {
      cellRef.current.nextSibling.style.maxWidth = "";
      setNextCellWidth(widthRef.current.nextSiblingWidth);
    }
  
    cellRef.current.firstChild.style.minWidth = "";
    cellRef.current.firstChild.style.maxWidth = "";
    // cellRef.current.style.maxWidth = `${ maxWidth }px`;
    
    setWidth(widthRef.current.newWidth + offset);
    widthRef.current = undefined;
    styleHandle(false);
  };
  
  const styleHandle = (active=false) => {
    isDragged.current = active;
    if(!handleRef.current)
      return;
    
    handleRef.current.style.opacity = active ? "1" : "";
    // handleRef.current.lastChild.style.display = active ? "block" : "none";
  }
  
  useEffect(() => {
    return () => {
      document.removeEventListener("mouseup", handleMouseUp, true);
      document.removeEventListener("mousemove", handleMouseMove, true);
    }
  })
  
  return <div
    ref={handleRef}
    className={classnames(
      "noDrag resizeHandle py-1 absolute",
      classes.handle
    )}
    onMouseDown={handleMouseDown}
  />;
}

CellRescale.propTypes = {
  cellRef: object.isRequired,
  maxWidth: number.isRequired,
  minWidth: number.isRequired,
  currentCellWidth: number.isRequired,
  setWidth: func.isRequired,
  offset: number,
  maxNextCellWidth: number,
  minNextCellWidth: number,
  currentNextCellWidth: number,
  currentNextCellMaxWidth: number,
  setNextCellWidth: func
}