import { bool, node } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { APP_BAR_HEIGHTS } from "@styles/themes";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { useContainerScrollHandler } from "@hooks";


export const AppContainer = observer(({ children }) => {
  
  const classes = useStyle();
  const { stateStore } = useStores();
  
  const handleScroll = useContainerScrollHandler()
  
  return (
    <div
      ref={stateStore.setScrollRef}
      className={classes.root}
      onScroll={handleScroll}
    >
      {children}
    </div>
  )
});

AppContainer.propTypes = {
  children: node,
  noScroll: bool
}

const useStyle = makeStyles(() => ({
  root: {
    width: "100%",
    height: `calc(100% - ${APP_BAR_HEIGHTS.SMALL}px)`,
    display: "block",
    overflow: "auto",
    overflowAnchor: "none",
    "@media (min-width: 600px)": {
      height: `calc(100% - ${APP_BAR_HEIGHTS.LARGE}px)`,
    }
  }
}));