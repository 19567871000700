import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  table: {
    width: "100%",
    borderSpacing: 0,
    fontSize: 16,
  },
  featureRow: {
    "&:nth-child(even) td": {
      backgroundColor: "#e2f0fe",
    },
  },
  planTitle: {
    borderRadius: 10,
    fontSize: 20,
    height: "100%",
    backgroundColor: theme.props.text.alt1,
    color: theme.props.text.alt2,
  },
  planTitleFree: {
    backgroundColor: theme.props.backgroundSecondary,
    color: theme.palette.text.primary,
  },
  planTitleHighlight: {
    backgroundColor: theme.props.text.alt2,
    color: theme.props.text.neutral,
  },

  featureCell: {
    width: "18%",
    minWidth: 156,
    height: 96,
    padding: "4px 24px",
  },
  featureHeaderCell: {
    height: 56,
  },
  featureValue: {
    "&:last-child": {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
  },
  featureTitle: {
    width: "28%",
    minWidth: 128,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  iconSuccess: {
    fontSize: 26,
    color: theme.props.success,
  },
  iconError: {
    fontSize: 26,
  },
}));

export default useStyle;
