import { initLibraryQuery } from "@query";
import { APIMethod, fetchData } from "@client";
import { libraryChildMap } from "@utils";

export async function searchLibraryComponentsQuery(
  lvl,
  page,
  parentId = undefined,
  query = undefined
) {
  try {
    const search = async () => {
      return await fetchData(
        // `/v1/library/search?lvl=${this.levels[level-1]}&page=${page}${query ? "&query="+query : ""}${parentId ? "&parent="+parentId : ""}`,
        "/v1/library/search",
        APIMethod.GET,
        {
          params: {
            lvl,
            page,
            query,
            parent: parentId,
          },
        }
      );
    }

    let res = await search();

    if (!query && !res?.results?.length) {
      await initLibraryQuery();
      res = await search();
    }

    return {
      ...res.paginate,
      elements: res.results.map((e) => libraryChildMap(e, "componentId")),
    }

  } catch (e) {
    return { elements: [], page: 0, pages: 0 };
  }
}
