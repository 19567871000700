import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  list: {
    maxHeight: 240,
  },
  currencyCode: {
    opacity: 0.4,
    marginRight: 8,
    width: 30,
  },
}));

export default useStyle;
