import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    position: "relative",
    pointerEvents: "none",
    left: 0,
    top: 0,
    zIndex: 100,
    width: "100%",
    overflowX: "clip",
  },
  cursor: {
    top: -100,
    left: -100,
    position: "absolute",
  },
  name: {
    position: "absolute",
    left: 8,
    top: 12,
    fontSize: 14,
    fontWeight: 900,
  },
  icon: {
    left: -5,
    top: -2,
    position: "absolute",
    fontSize: 20,
  },
}));

export default useStyle;
