import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    maxWidth: "50%",
    height: "100%", //({offsetTop}) => offsetTop ? `calc(100% ${offsetTop > 0 ? "-" : "+"} ${Math.abs(offsetTop)}px)` : "100%",
    position: "relative",
    left: 0,
    // visibility: "visible !important",
    zIndex: 21,
    // transform: ({offsetTop}) => `translateY(-${offsetTop}px)`,
    ["@media (max-width: 599.95px)"]: {
      display: "none",
    },
  },
  paper: {
    // height: 'calc(100% - 50px)',
    height: "100%",
    width: "100%",
    borderColor: theme.props.border,
    borderStyle: "solid",
    // alignItems: "center",
    borderWidth: 0,
    borderTopWidth: 1,
  },
  dragger: {
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: 2,
    borderRight: "1px solid " + theme.props.border,
    cursor: "ew-resize",
    zIndex: 2,
  },
  // heatmapContainer: {
  // 	zIndex: 2,
  // 	position: "absolute !important",
  // 	top: 0,
  // 	left: 0,
  // 	width: "100%",
  // 	height: "100%",
  // 	pointerEvents: "none",
  // },
  // actionBar: {
  // 	width: "100%",
  // 	borderColor: theme.props.border,
  // 	borderStyle: "solid",
  // 	alignItems: "center",
  // 	borderWidth: 0,
  // 	borderBottomWidth: 1,
  // 	padding: 8,
  // 	backgroundColor: theme.props.backgroundSecondary
  // },
  // actionBarSticker: {
  // 	width: `calc(100% + ${24 + 16}px)`,
  // 	borderTopRightRadius: 5,
  // 	borderBottomRightRadius: 5,
  // 	borderRightWidth: ({noBorder}) => noBorder ? 0 : 1,
  // 	borderTopWidth: ({noBorder}) => noBorder ? 0 : 1,
  // 	borderBottomWidth: ({noBorder}) => noBorder ? 0 : 1,
  // 	// marginTop: 8,
  // },
}));

export default useStyle;
