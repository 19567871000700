import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { syncIssuesQuery, fetchProjectBudgetTrackingQuery } from "@query";
import { useProjectEditorStore } from "@hooks";
import { BTInfo } from "@dialogs";
import { EditorContainer, TableToolbar, Versioning } from "@components";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { BudgetTrackingSummary, BudgetTrackingTableBreakdown } from "../Tables";
import useStyle from "../EditorContent/EditorContent.style";
import className from "classnames";

export const BudgetTrackingContainer = observer(() => {
  const { t } = useTranslation();
  const classes = useStyle();

  const editorStore = useProjectEditorStore();
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [jiraProject, setJiraProject] = useState(null);

  const refreshProject = async () => {
    setLoading(true);
    setOpenModal(true);
    await syncIssuesQuery(editorStore.projectId);
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      const jira = await fetchProjectBudgetTrackingQuery(editorStore.projectId);
      setJiraProject(jira);
    })();
  }, []);

  return (
    <EditorContainer>
      <Grid item container className={className(classes.scrollableContainer)}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          name="table_title"
          className="mb-9"
          direction="column"
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            id="titleBar"
            className={classes.titleContainer}
          >
            <Grid container justifyContent="flex-end">
              <Grid item container spacing={2} alignItems="center">
                <Grid item>
                  <TableToolbar isSharedVersion />
                </Grid>
                <Grid item>
                  <Button
                    onClick={refreshProject}
                    className="mb-6"
                    variant="contained"
                    color="secondary"
                    endIcon={
                      isLoading && (
                        <CircularProgress color="primary" size={20} />
                      )
                    }
                  >
                    {t("common.refresh")}
                  </Button>
                </Grid>
              </Grid>
              <Versioning
                noEditing
                useClientActions
                version={jiraProject?.version}
              />
            </Grid>
          </Grid>
          <BudgetTrackingSummary />
          <BudgetTrackingTableBreakdown />
        </Grid>
      </Grid>
      {openModal && (
        <BTInfo
          openModal={openModal}
          title={t("views.bt.refresh_notice")}
          onClose={() => setOpenModal(false)}
          projectId={editorStore?.projectId}
        />
      )}
    </EditorContainer>
  );
});
