import { makeStyles } from "@material-ui/core/styles";
import { MAX_PAGE_WIDTH } from "@styles/themes";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // minWidth: "100vw",
    backgroundColor: theme.props.backgroundSecondary,
    borderBottom: `1px solid ${theme.props.border}`,
    "@media (max-width: 1280px)": {
      justifyContent: "flex-start !important",
    },
    height: 96,
    minHeight: 96,
    marginBottom: 24,
    boxSizing: "border-box",
    position: "sticky",
    zIndex: 200,
    left: 0
  },
  container: {
    width: "100vw",
    maxWidth: MAX_PAGE_WIDTH,
    padding: "0 24px",
    position: "sticky",
    left: 0,
    overflow: "hidden"
  },
  containerSmall: {
    maxWidth: 920,
  },
  titleContainer: {
    width: "max-content",
    marginRight: 18,
  },
  boardName: {
    opacity: 0.5,
    marginTop: 3,
    fontWeight: 600,
  },
}));

export default useStyle;
