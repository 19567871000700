import Color from "color";

const parseRgbString = (str) => {
  if (!str || str[0] !== "#") return str;
  const r = parseInt(str.slice(1, 3), 16),
    g = parseInt(str.slice(3, 5), 16),
    b = parseInt(str.slice(5, 7), 16),
    a = str.length > 7 ? parseInt(str.slice(7), 16) / 255 : 1;

  return a === 1
    ? `rgb(${r}, ${g}, ${b})`
    : `rgba(${r}, ${g}, ${b}, ${a.toFixed(2)})`;
};

const lightenOffColor = (color) => (
  Color(color).mix(Color("#ffffff"), 0.8).hex()
)

export const ExportStyles = (presetData) => {
  const pr = Object.keys(presetData).reduce(
    (p, c) =>
      c === "fontColor"
        ? {
            ...p,
            fontColor: Object.keys(presetData.fontColor).reduce((pp, cc) => ({
              ...pp,
              [cc]: parseRgbString(presetData.fontColor[cc]),
            })),
          }
        : { ...p, [c]: parseRgbString(presetData[c]) },
    {}
  );

  return `body {
    width: 1100px;
    background: ${pr?.backgroundColor};
}

a {
	color: ${pr?.fontColor?.text};
}

header {
    background: ${pr?.headerColor};
    border-radius: 10px 10px 0px 0px;
}

header button {
    background: ${pr?.headerColor};
    border: none;
    margin: 10px;
}

h3 {
    font-size: 18px;
    font-weight: 600;
    font-family: "${pr?.fontFamily}", sans-serif !important;
}

h3.summary {
    color: ${pr?.fontColor?.summaryHeader}
}

h3.breakdown {
    color: ${pr?.fontColor?.breakdownHeader}
}

h3.timeline {
    color: ${pr?.fontColor?.timelineHeader}
}

h1 {
    font-size: 26px;
    font-weight: 600;
    font-family: "${pr?.fontFamily}", sans-serif !important;
    color: ${pr?.fontColor?.title};
    float: right;
    margin: 0;
}

h3.section {
    margin: 24px 16px -4px;
}

.sectionDesc {
	padding: 16px;
	color: ${pr?.fontColor?.sectionDesc};
	text-align: left;
}

.table-summary {
    background: ${pr?.paperColor};
    padding: 24px 16px;
}

.table-timeline {
    background: ${pr?.paperTimelineColor};
    padding: 24px 16px;
}

.timeline, .breakdown {
	margin-bottom: 24px;
}

.teamMembers {
	font-size: 10px;
	line-height: 20px;
	color: #FB8B40;
}

table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
}

thead {
    display: table-header-group;
}

th {
    color: ${pr?.fontColor?.tableBreakdownHeader};
    border: none;
    height: 40px;
    padding: 0 16px;
    font-size: 12px;
    text-align: center;
    font-family: "${pr?.fontFamily}", sans-serif !important;
    font-weight: 600;
    text-transform: uppercase;
}

th.summary {
    color: ${pr?.fontColor?.tableSummaryHeader};
}

th.timeline {
    color: ${pr?.fontColor?.tableTimelineHeader};
}

tbody {
    background: none;
    display: table-row-group;
}

header table tr {
    border-radius: 10px 10px 0px 0px;
}

tr {
    color: inherit;
    display: table-row;
    outline: 0;
    vertical-align: middle;
}

tr.system {
    background: ${pr?.rowSummaryColor};
}
tr.system.hover {
    background: ${pr?.rowActiveSummaryColor};
}

tr.timeline {
    background: ${pr?.rowTimelineColor};
}
tr.timeline.hover {
    background: ${pr?.rowActiveTimelineColor};
}

td {
    font-size: 14px;
    text-align: center;
    color: inherit;
    display: table-cell;
    padding: 16px;
    font-family: "${pr?.fontFamily}", sans-serif !important;
    font-weight: 400;
    line-height: 1.43;
    vertical-align: inherit;
		color: ${pr?.fontColor?.text};
}

td.name {
	font-weight: 600;
	color: ${pr?.fontColor?.titleText};
}

td.cell-price {
	background-color: ${pr?.priceColor};
}

td.d0 {
	font-weight: 600;
	color: ${pr?.fontColor?.section};
}

h3.section, .d0 {
    font-weight: 400;
    box-sizing: border-box;
    font-family: "${pr?.fontFamily}", sans-serif !important;
    height: 30px;
    width: calc(100% - 32px);
    line-height: 30px;
    color:  ${pr?.fontColor?.section} !important;
    border-radius: 4px;
    background-color: ${pr?.sectionColor};
    min-width: 100px;
    font-size: 16px;
    text-align: center;
}

.d0 {
	padding: 8px
}


td.summary {
	color: ${pr?.fontColor?.summaryText};
}

td.timeline {
	color: ${pr?.fontColor?.timelineText};
}

td.summary-name {
	font-weight: 600;
	color: ${pr?.fontColor?.summaryTitleText};
}

td.timeline-name {
	font-weight: 600;
	color: ${pr?.fontColor?.timelineTitleText};
}

td.off {
    color: ${lightenOffColor(pr?.fontColor?.text)} !important;
}

td.summary-name.off {
    color: ${lightenOffColor(pr?.fontColor?.summaryText)} !important;
}

td.summary.off {
    color: ${lightenOffColor(pr?.fontColor?.summaryText)} !important;
}

tr.d1, tr.d2, tr.d3 {
    background: ${pr?.rowColor};
}

td.desc {
	text-align: left;
    padding-top: 0;
    font-style: italic;
    font-size: 12px;
}

tr.d1.hover {
    background: ${pr?.activeColor1};
}

tr.d1.off.hover {
	background: ${lightenOffColor(pr?.activeColor1)} !important;
}

tr.d2.hover {
    background: ${pr?.activeColor2};
}

tr.d2.off.hover {
	background: ${lightenOffColor(pr?.activeColor2)} !important;
}

tr.d3.hover {
    background: ${pr?.activeColor3};
}

tr.d3.off.hover {
	background: ${lightenOffColor(pr?.activeColor3)} !important;
}

.price {
	color: ${pr?.fontColor?.price};
	text-align: center;
	margin: 0;
	width: 100%;
	padding: 4px;
}

.price.off {
    color: ${lightenOffColor(pr?.fontColor?.text)} !important;
}

td.d0, .price.d0 {
	color:  ${pr?.fontColor?.section};
}

td.d-1, .price.d-1 {
	color:  ${pr?.fontColor?.tableFooter} !important;
}

.total-container {
    margin-top: 20px;
    height: 70px;
    text-align: right;
}

.total {
    font-family: "${pr?.fontFamily}", sans-serif !important;
    text-align: right;
    margin: 4px 16px;
    display: inline-block;
}

.total-cost-title {
    color: ${pr?.fontColor?.totalCostTitle} !important;
    font-size: 10px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
}

.total-time-title {
    color: ${pr?.fontColor?.totalTimeTitle} !important;
    font-size: 10px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
}

.total-hours-title {
    color: ${pr?.fontColor?.totalHoursTitle} !important;
    font-size: 10px !important;
    text-transform: uppercase !important;
    font-weight: 400 !important;
}

.total-cost {
	font-size: 32px !important;
	margin-left: 16px !important;
	font-weight: 600 !important;
  color: ${pr?.fontColor?.totalCost} !important;
}

.total-hours {
	font-size: 24px !important;
	margin-left: 16px !important;
	font-weight: 600 !important;
  color: ${pr?.fontColor?.totalHours} !important;
}

.total-time {
	font-size: 24px !important;
	margin-left: 16px !important;
	font-weight: 600 !important;
  color: ${pr?.fontColor?.totalTime} !important;
}`;
};
