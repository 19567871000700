import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  container: {
    position: "relative",
    marginTop: 20,
    paddingLeft: (level) => (level-1) * 28,
  },
  checkbox: {
    marginLeft: (level) => level > 0 ? 28 : undefined,
  },
  line: {
    borderColor: theme.props.border,
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 6,
    height: 30,
    width: 12,
    marginLeft: 12,
    marginTop: 4,
    marginRight: 4,
    position: "absolute",
    bottom: 11
  }
}));

export default useStyle;