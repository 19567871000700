import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.props.memberAddButton,
    border: "1px dashed",
    padding: 3,
    width: 16,
    height: 16,
    fontSize: 12,
    boxSizing: "border-box",
  },
}));

export default useStyle;
