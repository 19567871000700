import { useMemo, useState } from "react";
import {
  bool,
  number,
  string,
  object,
  arrayOf,
  oneOf,
  oneOfType,
} from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStructureStore, useTableExpandHandler } from "@hooks";
import { Tables, hashCode } from "project-structure";
import { CELL_WIDTHS } from "@utils";
import { Collapse } from "@material-ui/core";
import { TitleCell, Row, RowGroup } from "@components";
import { TaskWorkType } from "../TaskWorkType/TaskWorkType";
import { BreakdownRowGroupList } from "../BreakdownRowGroupList/BreakdownRowGroupList";
import classnames from "classnames";
import { MAX_PAGE_WIDTH } from "@styles/themes";
import { DescriptionCell } from "../../../cells";
import { WysiwygEditor } from "@components";

export const BreakdownRowGroup = observer(
  ({
    element,
    allowEdition,
    isSellerOrClient,
    isEditorOrClient,
    isProposal,
    isLibrary,
    useClientActions,
    isLastGroupRow,
    blockExpansions,
    displayStatus,
    allowStatusChange,
    parentIds = [],
    tableDepth = 0,
    initialDepth = 0,
    useExpandCell,
    useDescriptionCell,
    pricesVisible,
    maxDescriptionCellWidth,
  }) => {
    const structure = useStructureStore();
    const { t } = useTranslation();

    const [descEdition] = useState(undefined);

    const { valueLevel, viewLevel, descriptionMode } = structure.settings;

    const {
      id: elementId,
      name,
      content,
      isTurnedOff,
      hasChildren,
      hasFixedPrice,
      isOpened,
      status,
      taskWorkTypes,
      showMoreContent,
    } = element;

    const actualDepthLevel = useMemo(
      () => tableDepth + initialDepth,
      [tableDepth, initialDepth]
    );

    const fullIdPath = useMemo(
      () => [...parentIds, elementId],
      [parentIds, elementId]
    );

    const openable = useMemo(
      () => actualDepthLevel < viewLevel && (allowEdition || hasChildren),
      [actualDepthLevel, viewLevel, allowEdition, hasChildren]
    );

    const hasEditedDescription = useMemo(
      () =>
        descriptionMode === 2 ? Boolean(content) || descEdition : descEdition,
      [descEdition, descriptionMode, content]
    );

    const isAcceptedComponent = useMemo(
      () => status === 1 && displayStatus,
      [status, displayStatus]
    );

    const opened = useMemo(
      () => isAcceptedComponent || (openable && (isOpened || blockExpansions)),
      [isAcceptedComponent, openable, isOpened, blockExpansions]
    );

    const isSectionRow = useMemo(
      () => actualDepthLevel === 0,
      [actualDepthLevel]
    );

    const displayCellContent = useMemo(
      () =>
        actualDepthLevel <= valueLevel &&
        (!opened || !hasChildren || tableDepth + 1 > valueLevel),
      [actualDepthLevel, valueLevel, opened, hasChildren, tableDepth]
    );

    const hashedPath = useMemo(
      () =>
        [Tables.BREAKDOWN, ...(parentIds || []), elementId]
          .map((id) => hashCode(id))
          .join("/"),
      [parentIds, elementId]
    );

    const handleTurnOff = () => {
      element.setTurnOffState(!isTurnedOff);
    };

    const { handleOpen, ...collapseProps } = useTableExpandHandler(
      openable,
      isOpened,
      element.setOpenState
    );

    const maxTitleCellWidth = useMemo(() => {
      const width = taskWorkTypes?.map((workType) =>
        workType.btOpened
          ? CELL_WIDTHS.VALUE.SMALL * 4
          : CELL_WIDTHS.VALUE.LARGE
      );
      MAX_PAGE_WIDTH - width - CELL_WIDTHS.TITLE_BT_SUMMARY;
    });

    return (
      <RowGroup
        originTableId={Tables.BREAKDOWN}
        tableDepth={tableDepth}
        isActive={opened}
        isStatic={!allowEdition}
        elementId={elementId}
        parentIds={parentIds}
        elementType={["s", "m", "f", "t"][actualDepthLevel]}
      >
        <Row
          useExpandCell={useExpandCell}
          expandable={openable}
          expanded={opened}
          onExpand={handleOpen}
          noArrow={actualDepthLevel === 1}
          expandOnClick
          useVisibilityCell={useClientActions}
          visible={!isTurnedOff}
          onVisibilityToggle={handleTurnOff}
          isFirstGroupRow={tableDepth <= 1}
          isLastGroupRow={
            isSectionRow || (isLastGroupRow && !opened && !hasEditedDescription)
          }
          highlightCellText={isAcceptedComponent}
          tableDepth={tableDepth}
          style={!tableDepth ? { marginBottom: 2 } : undefined}
        >
          <TitleCell
            allowOverflowDisplay={descriptionMode < 3}
            minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
            maxWidth={CELL_WIDTHS.DESCRIPTION}
            pinnedLeft={useExpandCell ? tableDepth || 1 : 0}
          >
            {
              <span
                className={classnames(
                  "name wrap-text my-3",
                  !isSectionRow && "preset-titleText",
                  isSectionRow && "preset-titleTextSection",
                  !name?.length && "semi-transparent",
                  !useExpandCell && "ml-3"
                )}
              >
                {name?.replace(/<(.|\n)*?>/g, "") || t("common.unnamed")}
              </span>
            }
          </TitleCell>
          <DescriptionCell
            allowOverflowDisplay
            isSectionRow={isSectionRow}
            minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
            maxWidth={maxTitleCellWidth}
          >
            <WysiwygEditor
              readOnly={!allowEdition}
              noEmptyHtmlStrings
              changeOnClickAway
              placeholder={t("views.editor.desc_change")}
              name={`desc${hashCode(elementId)}`}
              value={content}
              toolbarVariant="popup"
              shorten
              useBorder
              showMore={showMoreContent}
              onShowMore={element.showMoreContent}
              moreButtonClassName={classnames(
                !isSectionRow && "preset-moreDesc",
                isSectionRow && "preset-moreDescSection color-primary-lighter"
              )}
              className={allowEdition ? "my-1-5" : "my-3 ml-0-5"}
            ></WysiwygEditor>
          </DescriptionCell>
          {element.btTaskWorkTypes?.map((workType) => (
            <TaskWorkType
              key={workType.id}
              tableDepth={tableDepth}
              element={element}
              workType={workType}
              editable={
                allowEdition && (!isLibrary || typeof elementId === "number")
              }
              displayContent={displayCellContent}
              fullIdPath={fullIdPath}
              emptyValue={hasFixedPrice}
            />
          ))}
        </Row>
        {openable && (
          <Collapse
            in={opened}
            className="w-full"
            mountOnEnter
            unmountOnExit
            data-id={hashCode(`${elementId}_cont`)}
            data-p={hashedPath}
            data-t={["s", "m", "f", "t"][actualDepthLevel + 1]}
            {...collapseProps}
          >
            <BreakdownRowGroupList
              element={element}
              allowEdition={allowEdition}
              tableDepth={tableDepth}
              initialDepth={initialDepth}
              isLastGroupRow={
                tableDepth === 0 || (isLastGroupRow && !allowEdition)
              }
              parentIds={fullIdPath}
              parentName={name}
              useClientActions={useClientActions}
              isLibrary={isLibrary}
              isProposal={isProposal}
              className="w-full"
              blockExpansions={blockExpansions}
              isSellerOrClient={isSellerOrClient}
              isEditorOrClient={isEditorOrClient}
              displayStatus={displayStatus}
              allowStatusChange={allowStatusChange}
              useExpandCell={useExpandCell}
              useDescriptionCell={useDescriptionCell}
              pricesVisible={pricesVisible}
              maxDescriptionCellWidth={maxDescriptionCellWidth}
            />
          </Collapse>
        )}
      </RowGroup>
    );
  }
);

BreakdownRowGroup.propTypes = {
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]),
  initialDepth: oneOf([0, 1, 2, 3]),

  parentIds: arrayOf(oneOfType([number, string])),
  parentName: string,

  isLastGroupRow: bool,
  displayStatus: bool,
  allowStatusChange: bool,
  isFirst: bool,

  //common
  allowEdition: bool,
  isSellerOrClient: bool,
  isEditorOrClient: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  useExpandCell: bool,
  useDescriptionCell: bool,
  pricesVisible: bool,
  maxDescriptionCellWidth: number,
};
