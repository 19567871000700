import { memo, useMemo, useState } from "react";
import { arrayOf, bool, func, object, string } from "prop-types";
import { highlightSearchText } from "@utils";
import { AddCircleOutlineOutlined, RemoveCircle } from "@material-ui/icons";
import { Button, CircularProgress } from "@material-ui/core";
import classnames from "classnames";
import useStyle from "./AsyncPickerItem.style";


export const AsyncPickerItem = memo(({
  data,
  idKey,
  labelKey,
  disabled,
  inputValue,
  selectedItems,
  maxSelected,
  onAdd,
  onRemove,
  ContentItem
}) => {
  
  const classes = useStyle();
  
  const [isLoading, setLoading] = useState(false);
  
  const id = data[idKey];
  const label = highlightSearchText(data[labelKey], inputValue);
  
  const isSelected = useMemo(() => (
    selectedItems?.findIndex((s) => s[idKey] === id) >= 0
  ), [selectedItems]);
  
  const limited =
    !isSelected && maxSelected;
  
  const handleAdd = async () => {
    if(!onAdd) return;
    setLoading(true);
    await onAdd(data);
    setLoading(false);
  };
  
  const handleRemove = async () => {
    if(!onRemove) return;
    setLoading(true);
    await onRemove(id);
    setLoading(false);
  };
  
  return (
    <Button
      disableRipple
      color="secondary"
      disabled={(limited && !isSelected) || disabled}
      className={classnames(classes.container, "px-6")}
      role="listitem"
      onClick={isSelected ? handleRemove : handleAdd}
      aria-label={isSelected ? "remove item" : "add item"}
    >
      <ContentItem {...data} disabled={disabled}>
        { label[0] && <span>{label[0]}</span>}
        {label[1] && (
          <strong>
            {label[1]}
          </strong>
        )}
        { label[2] && <span>{label[2]}</span>}
      </ContentItem>
      <span className={classes.iconContainer}>
        { !disabled &&
          (
            isLoading
              ? <CircularProgress size={24} />
              : (
                isSelected && onRemove ? (
                  <RemoveCircle color="primary" className="text-2xl" />
                ) : (
                  <AddCircleOutlineOutlined color="primary" className="text-2xl"/>
                )
              )
          )
        }
      </span>
    </Button>
  )
});

AsyncPickerItem.propTypes = {
  data: object.isRequired,
  idKey: string.isRequired,
  labelKey: string.isRequired,
  inputValue: string,
  selectedItems: arrayOf(object),
  onAdd: func,
  onRemove: func,
  ContentItem: func,
  maxSelected: bool,
  disabled: bool,
}