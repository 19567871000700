import { useEffect, useState } from "react";
import { arrayOf, func, number, shape, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Button } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { Users, Rules } from "./components";

export const SetUpBtForm = observer(({
  onSubmit,
  workTypes,
  jiraUsers,
  apropoUsers,
  labels,
}) => {
  const { t } = useTranslation();
  
  const [usedJiraUsers, setUsedJiraUsers] = useState([]);
  
  const {
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  
  useEffect(() => {
    const subscription = watch(checkField);
    return () => subscription.unsubscribe();
  }, [watch]);
  
  const checkField = (value) => {
    setUsedJiraUsers(value.users.filter(u => u.jiraId).map(u => u.jiraId))
  };

  const submitHandler = ({ users, rules }) => {
    const workTypes = users
      .reduce((o, u) => {
        if(u.jiraId && u.workType)
          o[u.jiraId] = u.workType;
        return o;
      }, {});
    
    const usersMap = users
      .reduce((o, u) => {
        if(u.jiraId && u.apropoId)
          o[u.jiraId] = u.apropoId;
        return o;
      }, {});
    
    const importTickets = rules
      .reduce((o, r) => {
        if(r.name && r.selection) {
          o[ labels.find((obj) => obj["id"] === r.selection).name ] = r.name;
        }
        return o;
      }, {})
    
    onSubmit({
      users: usersMap,
      workTypes,
      importTickets,
    });
  };

  return (
    <form
      autoComplete="on"
      aria-label="companyDetails form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container direction="column" wrap="nowrap">
        <Users
          control={control}
          apropoUsers={apropoUsers}
          jiraUsers={jiraUsers}
          usedJiraUsers={usedJiraUsers}
          workTypes={workTypes}
        />
        
        <Rules
          control={control}
          labels={labels}
        />
        
        <Divider className="mt-2 -mx-6 mb-6" />

        <Grid item container justifyContent="flex-end" className="mb-3">
          <Button
            isSubmit
            name="submit"
            variant="contained"
            endIcon={
              isSubmitting ? (
                <CircularProgress
                  aria-label="progress indicator"
                  size={24}
                  color="inherit"
                />
              ) : undefined
            }
          >
            {t("common.save")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

SetUpBtForm.propTypes = {
  onSubmit: func,
  projectViewLevel: number,
  apropoUsers: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired
};
