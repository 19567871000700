import { useMemo } from "react";
import { bool, func, number } from "prop-types";
import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { CollapseValueInnerCell, ResetValueButton } from "@components";
import { observer } from "mobx-react";

export const MonthValueCell = observer(({
  value: monthlyValue=0,
  month,
  maxHours,
  hasOverride,
  allowEdition,
  displayContent,
  displayCellContent,
  handleReset,
  handleValueChange,
  isOpened,
  showDecimals
}) => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const { usedRoundDecimals } = structure.settings;
  
  const displayResetButton = useMemo(() => (
    hasOverride && displayContent && allowEdition && !isOpened && handleReset
  ), [hasOverride, displayContent, allowEdition, isOpened, handleReset]);
  
  const displayedValue = useMemo(() => (
    displayCellContent && (hasOverride || monthlyValue)
      ? monthlyValue
      : ""
  ), [displayCellContent, hasOverride, monthlyValue]);
  
  const localMaxHours = useMemo(() => (
    maxHours + (hasOverride ? monthlyValue : 0)
  ), [maxHours, hasOverride]);
  
  return (
    <CollapseValueInnerCell
      value={displayedValue}
      max={localMaxHours}
      displayContent={displayContent}
      displayCellContent={displayCellContent}
      showDecimals={showDecimals}
      editable={allowEdition}
      textPresetClass="preset-timelineText"
      onValueChange={handleValueChange}
      decimals={usedRoundDecimals}
      inputClassName={ `input-month${month+1}` }
    >
      {displayResetButton && (
        <ResetValueButton action={handleReset}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
    </CollapseValueInnerCell>
  )
})

MonthValueCell.propTypes = {
  month: number.isRequired,
  value: number,
  maxHours: number,
  hasOverride: bool,
  allowEdition: bool,
  displayContent: bool,
  displayCellContent: bool,
  handleReset: func,
  handleValueChange: func,
  isOpened: bool,
  showDecimals: bool,
}