import { useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { ClearInput, ResetValueButton } from "@components";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";

export const MtaInput = observer(({
  element,
  disabled,
  hidden,
  autoFocus,
  gridSize,
  // isSectionElement,
}) => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const { maxWta, timeModifier, useMinMax, timelineHoursKey } = structure.settings;
  
  const { timeModifiedMta } = element;
  
  const value = useMemo(() => (
    timeModifiedMta[timelineHoursKey] / 4
  ), [timeModifiedMta, useMinMax]);
  
  const handleChange = (value) => {
    element.setMta({ [timelineHoursKey]: value * 4 });
  }
  
  const handleReset = () => {
    element.resetMta();
  }
  
  return (
    <Grid
      item container
      wrap="nowrap"
      alignItems="center"
      xs={gridSize}
      className={classnames(hidden && "invisible")}
    >
      <ClearInput
        min={1}
        // max={maxMta}
        max={maxWta}
        value={value}
        addInitialBorder
        fullWidth
        onChange={handleChange}
        autoFocus={autoFocus}
        disabled={disabled}
        decimals={2}
        fixedDecimalScale
      />
      <span className={classnames("ml-2", disabled && "semi-transparent")}>
        {t(
          `times.${timeModifier === 1 ? "hour" : "day"}`
        ).toLowerCase()}
      </span>
      <ResetValueButton
        className="ml-2"
        action={handleReset}
        disabled={disabled}
      >
        {t("views.editor.dialogs.timeline_settings.wta_restore")}
      </ResetValueButton>
    </Grid>
  )
})

MtaInput.propTypes = {
  element: object.isRequired,
  disabled: bool,
  autoFocus: bool,
  gridSize: number,
  isSectionElement: bool,
};
