import { useMemo, useState } from "react";
import {
  bool, number, string, func, any,
  arrayOf, oneOfType, instanceOf, object,
} from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  addProjectCommentQuery,
  changeProjectCommentQuery,
  removeProjectCommentQuery,
} from "@query";
import { useStores } from "@hooks";
import { format } from "date-fns";
import { Avatar, StatusBullet, WysiwygEditor } from "@components";
import { IconButton, CircularProgress, Grid } from "@material-ui/core";
import { Pencil, Trash } from "@assets";
import { Clear, Send } from "@material-ui/icons";
import useStyle from "./ChatMessage.style";
import classnames from "classnames";

export const ChatMessage = observer(({
  commentId,
  projectUuid,
  date,
  authorId,
  message,
  onChange,
  creationMode,
  teamMembers,
}) => {

  const { userStore } = useStores();
  const classes = useStyle();
  const { t } = useTranslation();

  const [isDeleting, setDeleting] = useState(false);
  const [isSending, setSending] = useState(false);
  const [newMessage, setNewMessage] = useState(message || "");
  const [messageEditorOpened, openMessageEditor] = useState(creationMode);
  const [mentions, setMentions] = useState([]);

  const { id, fullname, avatar, avatarColor } = userStore.data;

  const author = useMemo(() => (
    teamMembers?.find((x) => x.id === authorId)
  ), [teamMembers, authorId]);

  const fName = author ? author.fullname : fullname;
  const av = author ? author.avatar : avatar;
  const color = author ? author.avatarColor : avatarColor;

  const userIsAuthor = !author || author.id === id;
  const parsedDate = date
    ? new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60)
    : new Date();

  const handleChange = (value, isDifferent, mentions) => {
    setNewMessage(value);
    setMentions(mentions);
  }

  const removeMessage = async () => {
    if (!isSending && !isDeleting) {
      setDeleting(true);
      await removeProjectCommentQuery(projectUuid, commentId);
      postCommit(commentId);
      setDeleting(false);
    }
  };

  const handleSend = async () => {
    openMessageEditor(false);
    if(isSending || isDeleting || !newMessage || newMessage === message)
      return;
   
    setSending(true);
    if (creationMode) await addProjectCommentQuery(projectUuid, newMessage, mentions);
    else await changeProjectCommentQuery(projectUuid, commentId, newMessage, mentions);
    postCommit(commentId || 1);
    setSending(false);
  };

  const cancelSending = () => {
    setNewMessage(message || "");
    if (!isSending && !isDeleting) postCommit();
  };

  const postCommit = (id = null) => {
    if (!creationMode) openMessageEditor(false);
    onChange && onChange(id);
  };

  return (
    <Grid
      item
      container
      role="listitem"
      className={classnames({
        [classes.root]: true,
        [classes.creationMode]: messageEditorOpened,
        [classes.sentMessage]: !messageEditorOpened,
        [classes.ownMessage]: userIsAuthor,
      })}
    >
      <Grid item container justifyContent="space-between">
        <Grid
          item
          container
          alignItems="center"
          spacing={1}
          className={classes.infoRowContent}
        >
          <Grid item>
            <Avatar
              fullname={fName}
              avatar={av}
              color={color}
              size={16}
            />
          </Grid>
          <Grid item>
            <p className={classes.text}>
              {!userIsAuthor ? fName : t("common.you")}
            </p>
          </Grid>
          <Grid item container alignItems="center">
            <StatusBullet className={classes.opaque} size="xs" />
          </Grid>
          <Grid item>
            <p className={classnames(classes.opaque, classes.text)}>
              {format(parsedDate, "dd MMM yyyy")}
            </p>
          </Grid>
        </Grid>
        {userIsAuthor && !messageEditorOpened && (
          <Grid
            item
            container
            alignItems="center"
            spacing={1}
            className={classes.infoRowContent}
          >
            <Grid item>
              <IconButton size="small" onClick={() => openMessageEditor(true)}>
                {isSending ? (
                  <CircularProgress size={14} />
                ) : (
                  <Pencil className="text-sm" />
                )}
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={removeMessage}>
                {isDeleting ? (
                  <CircularProgress size={14} />
                ) : (
                  <Trash className="text-sm" color="error" />
                )}
              </IconButton>
            </Grid>
          </Grid>
        )}
        {messageEditorOpened && (
          <Grid
            item
            container
            alignItems="center"
            spacing={1}
            className={classes.infoRowContent}
          >
            <Grid item>
              <IconButton size="small" onClick={cancelSending}>
                <Clear className="text-sm" color="error" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                color="primary"
                onClick={handleSend}
              >
                {isSending ? (
                  <CircularProgress size={14} />
                ) : (
                  <Send className="text-sm" />
                )}
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      
      <WysiwygEditor
        name="message"
        noEmptyHtmlStrings
        onChange={handleChange}
        value={newMessage}
        readOnly={!messageEditorOpened}
        forcedFocus={messageEditorOpened}
        className="mt-2 text-sm"
        editorMentionUsers={teamMembers}
        editorMentionSearchKeys={[ "fullname", "email" ]}
      />
    </Grid>
  );
});

ChatMessage.propTypes = {
  projectUuid: string.isRequired,
  authorId: oneOfType([string, number]),
  commentId: number,
  date: instanceOf(window.Date),
  message: oneOfType([
    string,
    arrayOf(any),
  ]),
  onChange: func,
  creationMode: bool,
  teamMembers: arrayOf(object)
};
