import { memo, useMemo, useRef } from "react";
import { bool, string, func, node, number, oneOf } from "prop-types";
import Grid from "@material-ui/core/Grid";
import useCellStyle from "../commonStyles.style";
import classnames from "classnames";
import { CellRescale } from "@components/ProjectEditor/Tables/other/CellRescale/CellRescale";
import { CELL_WIDTHS } from "@utils";
import { capitalize } from "@material-ui/core";

export const DescriptionCell = memo(({
  children,
  actions,
  isHeader,
  className,
  allowOverflowDisplay,
  useRescale,
  minWidth,
  maxWidth,
  left,
  setWidth,
  size="large"
}) => {
  
  const cellClasses = useCellStyle();
  
  const cellRef = useRef(null);
  
  const allowRescale = useMemo(() => (
    isHeader && useRescale && Boolean(setWidth)
  ), [isHeader, useRescale, setWidth]);
  
  return (
    <Grid
      item
      container
      role="rowitem"
      ref={cellRef}
      className={classnames(
        "cell",
        !isHeader && "title",
        cellClasses.cell,
        cellClasses.titleCell,
        cellClasses.isSticky2,
        isHeader && cellClasses.headCell,
        cellClasses[`size${capitalize(size)}`],
        allowOverflowDisplay && "display-overflow",
        className
      )}
      style={{ minWidth, maxWidth: Math.max(minWidth, maxWidth), left }}
    >
      <Grid
        item container
        justifyContent={children ? "space-between" : "flex-end"}
        alignItems="center"
        wrap="nowrap"
        className={classnames(isHeader && "h-full", !isHeader && cellClasses.cellInner)}
      >
        {children}
        {(Boolean(actions) || allowRescale) && (
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            className={classnames("opaque relative w-max h-full ml-2", !isHeader && cellClasses.actionWrapper)}
          >
            {actions}
            {
              allowRescale &&
              <CellRescale
                cellRef={cellRef}
                minWidth={CELL_WIDTHS.DESC_MIN}
                maxWidth={CELL_WIDTHS.DESC_MAX}
                currentCellWidth={minWidth}
                setWidth={setWidth}
              />
            }
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

DescriptionCell.propTypes = {
  children: node,
  actions: node,
  className: string,
  allowOverflowDisplay: bool,
  
  isHeader: bool,
  useRescale: bool,
  minWidth: number,
  maxWidth: number,
  left: number,
  setWidth: func,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
};