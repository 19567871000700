export const CELL_PADDING = 4;

export const CELL_HEIGHTS = {
  XXSMALL: 24,
  XSMALL: 34,
  SMALL: 38,
  MEDIUM: 48,
  LARGE: 52,
  XLARGE: 56,
  XXLARGE: 62,
};

const EXPAND_CELL_WIDTH = 42;
const CELL_WIDTH_DEPTH_MODIFIER = 28;

export const CELL_WIDTHS = {
  VISIBILITY: 46,
  TITLE: 420 + EXPAND_CELL_WIDTH,
  TITLE_MIN: 274 + 41,
  TITLE_MAX: 840 + EXPAND_CELL_WIDTH - 160,
  DESC_MIN: 160,
  DESC_MAX: 840 + EXPAND_CELL_WIDTH - 290,
  TITLE_SUMMARY: 332,
  TITLE_BT_SUMMARY: 180,
  TITLE_SUMMARY_MIN: 332,
  TITLE_SUMMARY_EMPTY: 258,
  EXPAND: {
    DEPTH_1: EXPAND_CELL_WIDTH,
    DEPTH_2: EXPAND_CELL_WIDTH + CELL_WIDTH_DEPTH_MODIFIER,
    DEPTH_3: EXPAND_CELL_WIDTH + 2*CELL_WIDTH_DEPTH_MODIFIER,
  },
  VALUE: {
    MINI: 82,
    TINY: 90,
    SMALL: 104,
    MEDIUM: 124,
    LARGE: 160,
  },
  PRICE: {
    SMALL: 124,
    MEDIUM: 172,
    LARGE: 228,
  },
  DESCRIPTION: 300
};

export const BAR_CHART_LINE_HEIGHTS = {
  BOUNDARIES: [ 40, 80, 120 ],
  WEIGHTS: [ .25, .5, .75 ],
}