import { useMemo, useState } from "react";
import { FeatureAlert } from "@components";
import { useTranslation } from "react-i18next";
import { checkPrivilegesQuery } from "@query";

export const useFeatureGuardian = (feature, optionalCheckFunc) => {
  const { i18n } = useTranslation();

  const [visible, showAlert] = useState(false);
  const [actionBlocked, setActionBlocked] = useState(false);
  const [isChecking, setChecking] = useState(false);
  const [resMessage, setResMessage] = useState();

  const featureHasSystemDescription = useMemo(
    () => i18n.exists(`feature_desc.${feature.code}`),
    []
  );

  const checkAccess = async (noAlert=false) => {
    try {
      setChecking(true);
      const res = await checkPrivilegesQuery(feature.serverCode);
      let allowed = res.isAllowed;

      !featureHasSystemDescription && setResMessage(res.message);

      if (optionalCheckFunc) {
        allowed = !(await optionalCheckFunc({
          allowed: res.isAllowed,
          info: res.feature,
        }));
      }

      !noAlert && showAlert(!allowed);
      setActionBlocked(!allowed);
      setChecking(false);
      return allowed;
    } catch (e) {
      !noAlert && showAlert(true);
      setActionBlocked(true);
      return false;
    }
  };

  return {
    isChecking,
    checkAccess,
    actionBlocked,
    FeatureAlert: () => (
      <FeatureAlert
        visible={visible}
        feature={feature}
        onClose={() => showAlert(false)}
        altText={resMessage}
      />
    ),
  };
};
