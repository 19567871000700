import { useState } from "react";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert } from "@components";
import { IconButton, Link } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";

export const SoftRoundingAlert = ({
  showBreakdownSettingsDialog
}) => {
  const {t} = useTranslation();
  
  const [dialogVisible, showDialog] = useState();
  
  const handleDialogOpen = () => showDialog(!dialogVisible);
  
  return <>
    <IconButton
      size="small"
      onClick={handleDialogOpen}
    >
      <ReportProblemOutlined className="color-warning" />
    </IconButton>
    <Alert
      title={t("common.warning")}
      isOpen={dialogVisible}
      onAccept={handleDialogOpen}
      acceptText={t("common.close")}
    >
      <p className="mb-4 text-sm">
        {t("views.editor.soft_rounding_alert.text")}
      </p>
      <p className="text-sm">
        {t("views.editor.soft_rounding_alert.text2")}
        <Link className="cursor-pointer" onClick={showBreakdownSettingsDialog}>
          {t("common.click_here")}</Link>
        {"."}
      </p>
    </Alert>
  </>
}

SoftRoundingAlert.propTypes = {
  showBreakdownSettingsDialog: func
}