import { useEffect, useRef, useState } from "react";
import { LibraryStore, LibraryStoreProvider, ProjectEditorStoreProvider } from "@stores";
import { useTranslation } from "react-i18next";
import { getWorkTypesQuery } from "@query";
import { PROJECT_TYPE } from "@utils";
import { PageCircularProgress } from "@components";
import { Container } from "@material-ui/core";
import { LibraryView } from "./LibraryView";

export const Library = () => {
  
  const {t} = useTranslation();
  
  const [workTypes, setWorkTypes] = useState(null);
  const libraryStore = useRef(new LibraryStore());
  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.library")}`;
  }, [])

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const workTypes = await getWorkTypesQuery();
    setWorkTypes(workTypes);
  };

  return !workTypes ? (
    <PageCircularProgress />
  ) : (
    <ProjectEditorStoreProvider
      projectType={PROJECT_TYPE.LIBRARY}
      workTypes={workTypes}
    >
      <LibraryStoreProvider libraryStore={libraryStore.current}>
        <Container>
          <LibraryView />
        </Container>
      </LibraryStoreProvider>
    </ProjectEditorStoreProvider>
  );
};
