import {
  bool,
  string,
  number,
  object,
  node,
  func,
  arrayOf,
  oneOfType,
} from "prop-types";
import { Close } from "@material-ui/icons";
import classnames from "classnames";
import useStyle from "./Tag.style";
import { useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const Tag = ({
  text,
  id,
  color,
  className,
  style,
  onRemove,
  onClick,
  forceDot = false,
  ellipsisOnOverflow = false,
  useMaxWidth = false,
}) => {
  const classes = useStyle();
  
  const [isRemoving, setRemovalState] = useState(false);
  
  const useRemove = useMemo(() => Boolean(onRemove), [onRemove]);
  const useClick = useMemo(() => Boolean(onClick), [onClick]);

  const handleRemove = async (e) => {
    e.stopPropagation();
    if(!onRemove) return;
    
    setRemovalState(true);
    await onRemove(id || text);
    setRemovalState(false);
  };

  const handleClick = (e) => {
    onClick && onClick(e, id || text);
  };

  return (
    <div
      className={classnames(
        classes.root,
        useRemove && classes.removableShown,
        ellipsisOnOverflow && "max-w-full",
        className
      )}
      style={{ ...style }}
      aria-label="tag"
      onClick={handleClick}
    >
      {(color || forceDot) && (
        <span style={{ backgroundColor: color }} className={classes.colorDot} />
      )}
      <p className={classnames(
        classes.title,
        useClick && "cursor-pointer",
        useClick && classes.titleHover,
        ellipsisOnOverflow && classes.overflow,
        useMaxWidth && classes.maxWidth,
      )}>
        {text}
      </p>
      {useRemove && (
        <span
          className={`${classes.removeSection} remove`}
          onClick={handleRemove}
          aria-label="remove tag"
        >
          {
            isRemoving
              ? <CircularProgress size="1em" />
              : <Close />
          }
        </span>
      )}
    </div>
  );
};

Tag.propTypes = {
  text: oneOfType([string, node, arrayOf(node)]).isRequired,
  color: string,
  className: string,
  style: object,
  onRemove: func,
  onClick: func,
  forceDot: bool,
  ellipsisOnOverflow: bool,
  useMaxWidth: bool,
  id: oneOfType([string, number]),
};
