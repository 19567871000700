import { useState } from "react";
import { bool, number, string, func, node, oneOfType } from "prop-types";
import { useTranslation } from "react-i18next";
import { unzipStructure } from "project-structure";
import { getTemplatePreviewQuery, removeTemplateQuery } from "@query";
import { useProjectCreatorStore, useStores } from "@hooks";
import { isMobile } from "react-device-detect";
import { HtmlParser } from "@utils";
import { Grid, Link, CircularProgress, IconButton } from "@material-ui/core";
import { Pencil, TemplatePlaceholder, Trash } from "@assets";
import { Button, PopMenu, Dialog, Alert, Tag } from "@components";
import { TemplatePreview } from "@tools";
import { CreateTemplateDialog } from "@dialogs";
import classnames from "classnames";
import useStyle from "./TemplateCard.style";
import { MAX_PAGE_WIDTH } from "@styles/themes";

export const TemplateCard = ({
  id,
  previewId,
  allowEdition,
  name,
  metadata,
  content,
  category,
  maxDescLength,
  thumb,
  isDefault,
  onSelect,
  onRemove,
  onEdit,
  onSetDefault,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const creator = useProjectCreatorStore();
  const { userStore } = useStores();
  
  const { isAdmin } = userStore;

  const [popupAnchor, setPopupAnchor] = useState(null);
  const [detailsVisible, showDetails] = useState(false);
  const [isLoadingPreview, loadingPreview] = useState(false);
  const [previewModalOpened, openPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [alertLoading, setAlertLoading] = useState(false);
  const [showEditionModal, setShowEditionModal] = useState(false);

  const handleClick = () => {
    if (!detailsVisible && !previewModalOpened)
      onSelect(id, metadata && JSON.parse(metadata)?.workTypes?.map(wT => wT.id));
  };
  const handlePreview = async (e) => {
    e.stopPropagation();
    loadingPreview(true);
    try {
      const d = await getTemplatePreviewQuery(previewId || id);
      let structureData;
      if(typeof d === "string") {// may be blank
        try {
          structureData = JSON.parse(d);
        } catch(e) {
          console.log("NO PARSE:", JSON.stringify(e));
          try {
            structureData = JSON.parse(unzipStructure(d));
          } catch(e) {
            console.log("FIRST PARSE:", JSON.stringify(e));
            try {
              structureData = JSON.parse(unzipStructure(unzipStructure(d)));
            } catch(e) {
              console.log("DOUBLE PARSE:", JSON.stringify(e));
            }
          }
        }
      } else if(d)
        structureData = d;
      setPreviewData(structureData);
    } catch (e) {
      setPreviewData(null);
    }
    loadingPreview(false);
    openPreviewModal(true);
  };
  const handleDetails = (e) => {
    e.stopPropagation();
    showDetails(!detailsVisible);
  };

  const closeModal = () => {
    openPreviewModal(false);
    setPreviewData(null);
  };
  
  const handleSetAsDefault = () => {
    onSetDefault?.(isDefault ? undefined : id);
  }

  const handleRemove = async () => {
    setAlertLoading(true);
    if(isDefault)
      onSetDefault?.(undefined);
    await removeTemplateQuery(id);
    onRemove && onRemove(id);
    setAlertLoading(false);
    setDeleteDialog(false);
  };

  return (
    <Grid item xs={isMobile ? 12 : 6} md={isMobile ? 12 : 4}>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        wrap="nowrap"
        className={classnames(
          "rounded-2 border-solid border-light box-border overflow-hidden bg-secondary relative",
          classes.deleteHover
        )}
        role="listitem"
      >
        <Grid item container className={classes.gfx}>
          {thumb ? (
            <img src={thumb} alt={"template graphic"} />
          ) : (
            <TemplatePlaceholder />
          )}
        </Grid>
        <Grid
          item
          container
          wrap="nowrap"
          direction="column"
          justifyContent="space-between"
          className={classes.info}
          style={{ height: 190 + 3 * Math.floor(Math.sqrt(maxDescLength)) }}
        >
          <h3 className={classes.title}>{name}</h3>
          {content ? (
            <p style={{ margin: 0, fontSize: 14 }}>
              {(() => {
                const p = content.replace(/<[^>]*>/g, "");
                if (p.length > maxDescLength)
                  return p.slice(0, maxDescLength).replace(/ \w+,?$/, "");
                return p;
              })()}
              {content.length > maxDescLength && (
                <Link ref={setPopupAnchor} onClick={handleDetails}>
                  {" ("}
                  {t("views.new_project.read_more")}
                  {"...)"}
                </Link>
              )}
            </p>
          ) : null}
          <Grid
            item
            container
            style={{ width: "calc(100% + 24px)", margin: "0 -12px" }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              icon={
                isLoadingPreview && (
                  <CircularProgress color="inherit" size={20} />
                )
              }
              className={classes.button}
              aria-label={"preview template"}
              onClick={handlePreview}
            >
              {t("views.new_project.preview")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.button}
              aria-label={"select template"}
              onClick={handleClick}
            >
              {t("common.choose")}
            </Button>
          </Grid>
        </Grid>
        {
          isDefault &&
          <Grid item container className={classes.actionButtonContainer}>
            <Tag forceDot text={t("views.new_project.default_template")} />
          </Grid>
        }
        {allowEdition && (
          <Grid item container className={classnames(allowEdition && "hoverIcon", classes.actionButtonContainer)}>
            {
              isAdmin &&
              <Button
                onClick={handleSetAsDefault}
                size="small"
                className={classes.setDefaultButton}
              >
                {t(isDefault ? "common.default_unset" : "common.default_set")}
              </Button>
            }
            {!!onEdit && (
              <IconButton size="small" onClick={() => setShowEditionModal(true)}>
                <Pencil />
              </IconButton>
            )}
            {!!onRemove && (
              <>
                <IconButton size="small" onClick={() => setDeleteDialog(true)}>
                  <Trash color="error" />
                </IconButton>
              </>
            )}
          </Grid>
        )}
      </Grid>
      {deleteDialog && (
        <Alert
          isOpen={deleteDialog}
          onAccept={handleRemove}
          onCancel={() => setDeleteDialog(false)}
          title={t("common.delete_template_question", {template: name})}
          acceptText={
            alertLoading ? (
              <CircularProgress
                aria-label="progress indicator"
                size={24}
                color="inherit"
              />
            ) : (
              t("common.delete_accept")
            )
          }
        />
      )}
      {content && (
        <PopMenu
          show={detailsVisible}
          anchor={popupAnchor}
          onClickAway={() => showDetails(false)}
          style={{ width: 560, padding: 24 }}
        >
          <p>{HtmlParser(content)}</p>
        </PopMenu>
      )}
      {showEditionModal && (
        <CreateTemplateDialog
          open={showEditionModal}
          onChange={onEdit}
          onClose={() => setShowEditionModal(false)}
          defaultValues={{ id, name, content, category }}
        />
      )}
      {previewData && (
        <Dialog
          className={classes.dialogPaper}
          open={previewModalOpened}
          title={t("views.new_project.preview_title", { name })}
          onClose={closeModal}
          width={MAX_PAGE_WIDTH + 48}
        >
          <TemplatePreview
            projectStructure={previewData}
            workTypes={creator.workTypes}
          />
        </Dialog>
      )}
    </Grid>
  );
};

TemplateCard.propTypes = {
  id: oneOfType([string, number]).isRequired,
  previewId: oneOfType([string, number]),
  category: number,
  name: string.isRequired,
  content: string,
  onSelect: func.isRequired,
  showGraphic: bool,
  thumb: string,
  icon: node,
  maxDescLength: number,
  isDefault: bool,
  onSetDefault: func,
  onRemove: func,
  onEdit: func,
  allowEdition: bool,
  metadata: string, //JSON string
};

TemplateCard.defaultProps = {
  maxDescLength: 130,
};
