import { makeStyles } from "@material-ui/core/styles";
import { MAX_PAGE_WIDTH } from "@styles/themes";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    boxSizing: "border-box",
    width: MAX_PAGE_WIDTH,
    // padding: "0 8px",
  },
  mobileColumnContainer: {
    height: theme.props.contentHeight,
    boxSizing: "border-box",
    "@media (max-width: 520px)": {
      width: "300vw !important",
    },
  },
}));

export default useStyle;
