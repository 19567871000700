import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.props.background,
    width: "100vw",
    height: "100vh",
    borderRadius: 0,
    position: "relative",
    overflow: "hidden",
    "&:focus": {
      outline: "none !important",
    },
  },
}));

export default useStyle;
