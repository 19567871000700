import { APIMethod, fetchData } from "@client";

export async function editCustomWorkTypeQuery(id, name, backgroundColor="#4098F4") {
  const data = {
    id,
    name,
    backgroundColor,
  };
  const res = await fetchData(`/tags/${id}`, APIMethod.PUT, { data, mapRequestKeysToSnakeCase:false });
  return { ...data, removable: true, id: res.results.id };
}
