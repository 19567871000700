import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  dropzone: {
    padding: 48,
    minHeight: 56,
    borderRadius: 5,
    border: `2px dashed ${theme.palette.primary.main}4d`,
    cursor: "pointer",
  },
  iconBackground: {
    backgroundColor: "rgba(38, 218, 164, 0.1)",
    width: 64,
    height: 64,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    marginBottom: 24,
  },
  textField: {
    height: 32,
    maxWidth: 128,
    marginBottom: "0 !important",
    borderRadius: 5,
    "& input": {
      height: 32,
      padding: "0 4px",
      textAlign: "center",
    },
  },
  block: {
    maxWidth: "calc(33.333333% - 4px)",
    flexBasis: "calc(33.333333% - 4px)",
  },
  moduleContainer: {
    width: "calc(100% + 4px)",
    margin: "0 -2px"
  },
  moduleBlock: {
    maxWidth: "calc(25% - 4px)",
    flexBasis: "calc(25% - 4px)",
  },
  systemBlock: {
    border: "1px solid " + theme.palette.text.primary,
    borderRadius: 5,
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
    cursor: "pointer",
  },
  systemBlockActive: {
    border: "1px solid " + theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  columnSelect: {
    width: "30%",
    minWidth: 60,
    "& > div": {
      height: 32,
    },
    "& input, & .MuiSelect-root": {
      padding: "4px 12px",
    }
  },
  workTypeAutocompleteTextField: {
    height: 32,
    "& > div": {
      padding: "0 !important",
    },
    "& input": {
      height: 32,
    },
  },
  endAdornment: {
    right: "3px !important"
  },
  previewRoot: {
    minHeight: 320
  },
  descSelector: {
    maxWidth: 132,
  }
}));

export default useStyle;
