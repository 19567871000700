import { useMemo } from "react";
import { useCheckProjectAccess, useProjectEditorStore, useStructureStore } from "@hooks";

export const getUserVisibleWorkTypes = (taskWorkTypes, isProposal, isTimeline) => {
  
  const structure = useStructureStore();
  const editorStore = useProjectEditorStore();
  
  const { apply } = structure.visibility;
  const {
    workTypesWithStatus,
    projectUserWorkTypeVisibility
  } = editorStore;
  
  const { userId, userUuid, isObserver } = useCheckProjectAccess(editorStore.projectUsers);
  
  return useMemo(() => {
    let wt = taskWorkTypes;
    
    if(isProposal && apply)
      wt = taskWorkTypes.filter(wT => (
        !wT.isHidden && !(isTimeline && wT.hideOnTimeline)
      ));
    
    return (
      isObserver && editorStore.hasAssignedWorkTypes(userUuid) && projectUserWorkTypeVisibility[userId] === 0
        ? wt.filter(({id}) => workTypesWithStatus?.find(w => w.id === id)?.estimatorUuid === userUuid)
        : wt
    );
  }, [userId, editorStore, isObserver, taskWorkTypes, workTypesWithStatus, projectUserWorkTypeVisibility, apply, isProposal]);
}