import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  stepper: {
    // width: "calc(100% + 48px) !important",
    // margin: "0px -24px",
    "& .MuiStepLabel-labelContainer": {
      height: 18,
    },
    "& .MuiStepLabel-label": {
      fontSize: "12px !important",
    },
    "& .MuiStepIcon-root": {
      fontSize: 28,
    },
  },
}));

export default useStyle;
