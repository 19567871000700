import def from "./themes/default";
//import light from "./themes/light";

// list of theme configs
export default { def /* ... */ };

export const APP_BAR_HEIGHTS = {
  SMALL: 56,
  LARGE: 64,
};
export const PDF_TOOLBAR_HEIGHT = 52;

export const MAX_PAGE_WIDTH = 1280