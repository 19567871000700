import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    "& .MuiStep-horizontal": {
      maxWidth: 216,
    },
    "& .MuiStepConnector-alternativeLabel": {
      top: 15,
      left: "calc(-50% + 32px)",
      right: "calc(50% + 32px)",
    },
    width: "100%",
    // maxWidth: 920,
    padding: 0,
  },
  stepLabel: {
    "& .MuiStepLabel-labelContainer": {
      height: 66,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .MuiStepLabel-label": {
      margin: 0,
      fontSize: 12,
      // maxWidth: 92
    },
  },
  currentStep: {
    "& .MuiStepLabel-label": {
      fontSize: (isMobile) => (isMobile ? 14 : 20),
      fontWeight: "bold",
      maxWidth: 160,
    },
  },
  isMini: {
    "& .MuiStepLabel-labelContainer": {
      height: 28,
    },
    "& .MuiStepLabel-label": {
      fontSize: "12px !important",
    },
  },
  stepIcon: {
    width: 32,
    height: 32,
    border: "1px solid black",
    borderRadius: "50%",
    lineHeight: "31px !important",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    boxSizing: "border-box",
  },
  stepIconMini: {
    width: 24,
    height: 24,
    fontSize: 12,
    minHeight: 24,
  },
  previousStep: {
    fontSize: 24,
    width: 32,
    height: 32,
    minHeight: 32,
    boxShadow: "none",
  },
  previousStepMini: {
    fontSize: 16,
    width: 24,
    height: 24,
    minHeight: 24,
    boxShadow: "none",
  },
  disabledStep: {
    backgroundColor: theme.palette.text.primary + "33",
  },
  stepIconActive: {
    border: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.alt,
  },
}));

export default useStyle;
