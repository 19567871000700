import { makeStyles } from "@material-ui/core/styles";
import { DEFAULT_FONT_NAME } from "@utils";

const useStyle = makeStyles((theme) => ({
  
  root: {
    boxSizing: "border-box",
    cursor: "text",
    background: "transparent",
  },
  editor: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit",
    fontFamily: `'${DEFAULT_FONT_NAME}', sans-serif !important`,
  },
  moreButton: {
    whiteSpace: "nowrap",
  },
  bordered: {
    padding: 2,
    marginTop: 4,
    marginBottom: 4,
    // borderWidth: 2,
    // borderStyle: "solid",
    // borderColor: "transparent",
    borderRadius: 1,
    // minHeight: 32,
    alignItems: "center",
    outlineOffset: 2,
    "&:hover": {
      outlineWidth: 2,
      outlineStyle: "solid",
      outlineColor: theme.palette.primary.main,
    }
  },
  borderedFocused: {
    outlineWidth: 2,
    outlineStyle: "solid",
    outlineColor: theme.palette.primary.main,
  },
  mentionList: {
    width: 416,
  }
}));

export default useStyle;