import { useEffect, useRef, memo, useState } from "react";
import { bool, string, number, func, object, oneOf } from "prop-types";
import { capitalize, InputBase } from "@material-ui/core";
import classnames from "classnames";
import useStyle from "./ClearNumberInput.style";

export const ClearNumberInput = memo(({
  value,
  onChange,
  onFocus,
  onBlur,
  alignContent,
  placeholder,
  style,
  color,
  addInitialBorder,
  confirmOnEnter,
  disabled,
  className,
  autoFocus,
  max,
  min,
  size,
  fullWidth,
  maxWidth,
  tabIndex,
}) => {

  const classes = useStyle();
  const [localValue, setValue] = useState(value?.toString());
  const [focused, setFocused] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    setValue(value);
  }, [value]);

  const checkKey = e => {
    if(confirmOnEnter && e.keyCode === 13 ) {
      e.stopPropagation();
      blur(e, true);
      inputRef.current.blur();
    }
  };

  const focus = () => {
    if(!focused) {
      onFocus && onFocus();
      setFocused(true);
    }
  };

  const blur = (e, fromEnterKey=false) => {
    if(focused || fromEnterKey) {
      onBlur && onBlur();
      setFocused(false);
    }
  };

  const onValueChange = (e) => {
    const v = parseInt(e.currentTarget.value);
    if((v > max) || (v < min ))
      return;
    setValue(v);
    setFocused(true);
    if(onChange) {
      const cancelled = onChange(v);
      if(cancelled)
        setValue(value?.toString());
    }
  };
  
  return (
    <div className="display-block h-full relative">
      <InputBase
        type="number"
        ref={inputRef}
        value={localValue}
        className={classnames(
          "noDrag",
          classes.root,
          classes[`color${capitalize(color)}`],
          classes.minWidth,
          {
            [classes.disabled]: disabled,
            [classes.fullWidth]: fullWidth && !maxWidth,
            [classes.maxWidth]: maxWidth,
            [classes.addInitialBorder]: addInitialBorder,
            [ classes.sizeSmall ]: size === "small"
          }, className
        )}
        inputProps={{
          className: classnames(
            classes.input,
            classes[alignContent],
          )
        }}
        onChange={onValueChange}
        onFocus={focus}
        onBlur={blur}
        onKeyDown={checkKey}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        style={style}
        tabIndex={tabIndex}
        min={min}
        max={max}
      />
    </div>
  );
});

ClearNumberInput.propTypes = {
  value: number,
  onChange: func.isRequired,
  onFocus: func,
  onBlur: func,
  placeholder: string,
  alignContent: oneOf(["left", "center", "right"]),
  style: object,
  max: number,
  min: number,
  confirmOnEnter: bool,
  disabled: bool,
  className: string,
  autoFocus: bool,
  color: oneOf(["primary","text", "warning", "error"]),
  addInitialBorder: bool,
  fullWidth: bool,
  maxWidth: bool,
  size: oneOf(["small","large"]),
  tabIndex: number,
};

ClearNumberInput.defaultProps = {
  confirmOnEnter: true,
  alignContent: "center",
  max: 999,
  min: 0,
  color: "primary",
  size: "large",
};
