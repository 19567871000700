import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  EditorCommentsStoreProvider,
  EditorSocketProvider,
  PresetStoreProvider,
  ProjectEditorStoreProvider,
} from "@stores";
import {
  checkProjectAccessQuery,
  fetchProjectStructureQuery,
  getProjectPresetQuery,
  getTeamMembersQuery,
  sendBrokenStructureFeedbackFileQuery,
} from "@query";
import { useStores } from "@hooks";
import { home } from "@paths";
import { PageCircularProgress } from "@components";
import { ProjectLoadingAlert } from "@dialogs";
import { ProjectEditorView } from "./ProjectEditorView";
import { PROJECT_TYPE } from "@utils";
import { useTranslation } from "react-i18next";

export const ProjectEditor = () => {
  const {t} = useTranslation();
  const { userStore, stateStore } = useStores();
  const navigate = useNavigate();

  const { projectUuid } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState(null);
  const [hasError, setError] = useState(0);
  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.editor")}`;
  }, [])
  
  const commenterData = useMemo(() => ({
    uuid: userStore.data.uuid,
    fullname: userStore.data.fullname,
    color: userStore.data.avatarColor
  }), [userStore.data])

  useEffect(() => {
    // const abortController = new AbortController();
    onLoad();
    return () => {
      stateStore.resetFilesUploadProgress();
      // abortController.abort();
    }
  }, []);

  const onLoad = async () => {
    const hasAccess = await checkProjectAccessQuery(projectUuid);
    if (!hasAccess) {
      navigate(home, { replace: true });
      return;
    }

    try {
      // const workTypes = await getWorkTypesQuery();
      // const logo = await getCompanyLogoQuery();
      // const blockedAverageAlert = await getCompanySettingsQuery(
      //   USER_COMPANY_SETTINGS.AVERAGE_ALERT(userStore.userUuid)
      // );
      // const blockedRiskAlert = await getCompanySettingsQuery(
      //   USER_COMPANY_SETTINGS.RISK_ALERT(userStore.userUuid)
      // );
      // const blockedValueAlert = await getCompanySettingsQuery(
      //   PROJECT_COMPANY_SETTINGS.VALUE_ALERT(projectUuid)
      // );
      // const hadDifferentValues = await getCompanySettingsQuery(
      //   PROJECT_COMPANY_SETTINGS.VALUE_PROJECT(projectUuid)
      // );
      
      const teamMembers = await getTeamMembersQuery(userStore.workspaceUuid);
      let projectPreset = await getProjectPresetQuery(projectUuid);
      
      const isNew = Boolean(searchParams.get("new"));
      const isFromTemplate = Boolean(searchParams.get("template"));
      
      const {
        blockedAverageAlert,
        blockedRiskAlert,
        blockedValueAlert,
        hadDifferentValues,
        availableWorkTypes,
        defaultPresetId,
        logo,
        ...projectData
      } = await fetchProjectStructureQuery(
        userStore.userId,
        projectUuid,
        PROJECT_TYPE.STANDARD,
        isNew,
        isFromTemplate,
      );

      if(isNew || isFromTemplate)
        setSearchParams(undefined, {replace: true});

      setData({
        workTypes: availableWorkTypes,
        logo,
        projectData,
        projectPreset,
        defaultPresetId,
        blockedAverageAlert,
        blockedRiskAlert: Boolean(blockedRiskAlert),
        blockedValueAlert,
        hadDifferentValues,
        teamMembers
      });
    } catch (e) {
      // send structure to s3 and add feedback
      if (e.json) {
        if (process.env.REACT_APP_NODE_ENV === "production") // @todo: check if needed
          await sendBrokenStructureFeedbackFileQuery(e.json);
        setError(1);
        return;
      }

      setError(2);
    }
  };

  if (hasError) return <ProjectLoadingAlert structureError={hasError === 1} />;

  return !data ? (
    <PageCircularProgress />
  ) : (
    <ProjectEditorStoreProvider projectType={PROJECT_TYPE.STANDARD} {...data} useHistoryManager>
      <PresetStoreProvider projectPreset={data.projectPreset} defaultPresetId={data.defaultPresetId}>
        <EditorCommentsStoreProvider commenterData={commenterData}>
          <EditorSocketProvider
            projectUuid={projectUuid}
            commentHash={data.projectData.hash}
          >
            <ProjectEditorView />
          </EditorSocketProvider>
        </EditorCommentsStoreProvider>
      </PresetStoreProvider>
    </ProjectEditorStoreProvider>
  );
};
