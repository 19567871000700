import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  progress: {
    height: 8,
    width: "100%",
    borderRadius: 10,
  },
  progressDialog: {
    padding: 24,
    backgroundColor: theme.props.backgroundSecondary,
    borderRadius: 10,
    overflow: "hidden",
    boxShadow: "0 0 9px 0 #0e1d4833 !important",
    border: `1px solid ${theme.props.border}`,
    maxWidth: 324,
    width: 324,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexFlow: "wrap",
  },
  progressErrorColor: {
    backgroundColor: theme.palette.error.light + "66",
    "& > div": {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

export default useStyle;
