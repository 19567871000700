import { useMemo, useState } from "react";
import { arrayOf, bool, func, number, shape, string, object, oneOfType } from "prop-types";
import { ErrorCode, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid, IconButton, Link } from "@material-ui/core";
import { Alert } from "@components";
import { PdfFile } from "@assets";
import { Close } from "@material-ui/icons";
import useStyle from "./FileUploader.style";
import classnames from "classnames";

export const FileUploader = ({
  files,
  onAdd,
  onRemove,
  style,
  mini,
  fileLoading,
  maxNameLength,
  multiple,
  acceptedFormats,
  useClick= false,
  maxSize= 10 * 1024 * 1024,
  text,
  textActive,
  showBrowseLink=false,
  errorText,
  setError,
  inputName,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [uploadAlertTitle, setUploadAlertTitle] = useState();
  
  const disabled = useMemo(() => !onAdd, [onAdd]);

  const fileGetter = async (fileList) => {
    if (fileList?.length) {
      onAdd(fileList);
    }
    return [];
  };

  const handleFileRemove =
    (name, id = null) =>
    (e) => {
      e.stopPropagation();
      onRemove(
        files.filter((f) => f.name !== name || (id ? f.id === id : false)),
        name,
        id
      );
    };
  
  const handleFileReject = (reason) => {
    if(reason?.[0].errors) { //@todo: handle reject on multiple files ??
      const errorCodes = reason[0].errors.map(err => err.code);
      const errorMessage = errorCodes.includes(ErrorCode.FileInvalidType)
        ? (
          typeof acceptedFormats === "string"
            ? t(`errors.file.wrong_format.single`, {type: acceptedFormats})
            : t(`errors.file.wrong_format.multiple`, {types: acceptedFormats.join(", ")})
        )
        : t("errors.file.too_large", { mb: maxSize });
      
      if(setError) {
        setError(errorMessage);
        return;
      }
      
      setUploadAlertTitle(errorMessage);
    }
  }

  const {
    getRootProps,
    getInputProps,
    open: openFile,
    isDragActive,
  } = useDropzone({
    accept: acceptedFormats,
    noClick: !useClick,
    noKeyboard: true,
    multiple,
    maxFiles: 4,
    maxSize,
    disabled,
    onDropAccepted: fileGetter,
    onDropRejected: handleFileReject,
  });

  return (
    <Grid
      item
      container
      justifyContent={mini ? "flex-start" : "center"}
      alignItems="center"
      direction={mini ? "row" : "column"}
      wrap="nowrap"
      {...getRootProps({ className: "dropzone" })}
      className={classnames(
        classes.root,
        "rounded overflow-hidden cursor-pointer",
        mini ? "p-4" : "p-8",
      )}
      onClick={openFile}
      style={style}
    >
      {fileLoading ? (
        <CircularProgress color="primary" size={32} />
      ) : (
        <PdfFile className="text-4xl" color="primary" />
      )}
      {files?.length ? (
        files.map((file) => (
          <p
            key={file.name}
            className="ellipsis font-bold text-md mt-2"
          >
            {maxNameLength && file.name.length > maxNameLength
              ? `${file.name.slice(
                0,
                maxNameLength - 10
              )} ... ${file.name.slice(-10)}`
              : file.name}
            {Boolean(onRemove) && (
              <IconButton
                color="primary"
                aria-label="remove attachment"
                className="ml-2 text-md"
                onClick={handleFileRemove(file.name, file.id)}
              >
                <Close title="remove" />
              </IconButton>
            )}
          </p>
        ))
      ) : (
        <p className={classnames("ellipsis font-bold text-md mt-2", mini ? "ml-6" : "mt-6")}>
          {
            isDragActive
              ? textActive
              : disabled
                ? t("views.new_project.pdf_no_file")
                : <>
                  {text}
                  {
                    showBrowseLink &&
                    <Link>{t("views.new_project.pdf_browse")}</Link>
                  }
                </>
          }
        </p>
      )}
      {
        Boolean(errorText) &&
        <p className={ classnames("ellipsis font-bold text-md mt-2 color-error mt-6") }>
          {errorText}
        </p>
      }
      <input
        { ...getInputProps() }
        className="display-block h-0 w-full"
        tabIndex="0"
        aria-label={t("views.new_project.proposal_pdf_upload")}
        name={inputName}
      />
      <Alert
        title={uploadAlertTitle}
        isOpen={!!uploadAlertTitle}
        acceptText={t("common.close")}
        onAccept={() => setUploadAlertTitle(null)}
      />
    </Grid>
  );
};

FileUploader.propTypes = {
  text: string.isRequired,
  textActive: string.isRequired,
  acceptedFormats: oneOfType([string, arrayOf(string)]).isRequired,
  files: arrayOf(
    shape({
      name: string.isRequired,
      id: number,
    })
  ).isRequired,
  onAdd: func,
  onRemove: func,
  mini: bool,
  style: object,
  fileLoading: bool,
  maxNameLength: number,
  multiple: bool,
  useClick: bool,
  maxSize: number,
  showBrowseLink: bool,
  inputName: string,
  errorText: string,
  setError: func,
};
