import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { matchLang } from "@utils";

// translations
import en from "./translations/en.json";
// import pl from './translations/pl.json';

const resources = {
  en: {
    translation: en,
  },
  // pl: {
  // 	translation: mergeDeep(lang.pl, pl),
  // },
  // import & add more
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: matchLang(Object.keys(resources), "en"),
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
