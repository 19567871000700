import { useEffect, useMemo, useState } from "react";
import { func, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useActiveProjectStore } from "@hooks";
import {
  getProjectVersionStructureQuery,
  fetchProjectBudgetTrackingQuery,
  getUsersQuery,
  getApropoUsersQuery,
  getProjectsLabelsQuery,
  storeSetUpQuery,
} from "@query";
import { budgetTracking } from "@paths";
import { CreationProgress } from "@dialogs";
import { SetUpBtForm } from "@forms";
import { Alert, Dialog } from "@components";
import { CircularProgress, Grid } from "@material-ui/core";

export const BTImportModal = observer(({ onClose, uuid, projectJiraName }) => {
  const { t } = useTranslation();
  const activeProjects = useActiveProjectStore();

  const [showProgress, setShowProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const [workTypes, setWorkTypes] = useState(null);
  const [jiraUsers, setJiraUsers] = useState(null);
  const [apropoUsers, setApropoUsers] = useState(null);
  const [labels, setLabels] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const projectName = useMemo(
    () =>
      projectJiraName ||
      activeProjects.projects.find((p) => p.uuid === uuid)?.name ||
      "",
    []
  );
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const proj = await getProjectVersionStructureQuery(uuid);
      setProjectId(proj.results.id);
      const jiraProj = await fetchProjectBudgetTrackingQuery(proj.results.id);
      setWorkTypes(jiraProj.workTypes);
      // setWorkTypes([{id: 6, name: "Backend"}, {id: 5, name: "Frontend"}]);
      const jiraUsers = await getUsersQuery(proj.results.id);
      setJiraUsers(jiraUsers.data);
      // setJiraUsers([{id: 6, name: "John"}, {id: 5, name: "Doe"}]);
      const apropoUsers = await getApropoUsersQuery(proj.results.id);
      setApropoUsers(apropoUsers.data);
      // setApropoUsers([{id: 6, name: "John2"}, {id: 5, name: "Doe2"}]);
      const labels = await getProjectsLabelsQuery(proj.results.id);
      setLabels(labels);
      // setLabels([{id: 6, name: "L1"}, {id: 5, name: "L2"}]);
      setLoading(false);
    })();
  }, []);

  const saveImportTickets = async ({ workTypes, importTickets, users}) => {
    const data = {
      project_id: projectId,
      users,
      workTypes,
      importTickets,
    };
    await storeSetUpQuery(data);
    navigate(budgetTracking(uuid));
  };

  const cancelSaving = () => {
    setErrorMessage("");
    setShowProgress(false);
  };

  return (
    <Dialog
      id="newProject"
      open
      onClose={onClose}
      actionsClass="btImportForm"
      containerId="btImportForm"
      containerClass="btImportForm"
      width={800}
      title={`${t("views.bt.set_up")} - ${projectName}`}
    >
      {
        isLoading
        ? <Grid
            item container
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: 200 }}
            className="w-full"
          >
            <CircularProgress  />
          </Grid>
        : <SetUpBtForm
          onSubmit={saveImportTickets}
          workTypes={workTypes}
          apropoUsers={apropoUsers}
          jiraUsers={jiraUsers}
          labels={labels}
        />
      }
      <Alert
        isOpen={showModal}
        title={t("views.new_project.finish")}
        acceptText={t("views.project.create")}
        onCancel={() => setShowModal(false)}
      />
      <CreationProgress
        open={showProgress}
        onCancel={cancelSaving}
        cancelText={t("common.hide")}
        errorMessage={errorMessage}
      />
    </Dialog>
  );
});

BTImportModal.propTypes = {
  onClose: func.isRequired,
  uuid: string.isRequired,
  projectName: string,
};
