import { useState, useMemo } from "react";
import { bool, func, array } from "prop-types";
import { useStores, useFeatureGuardian } from "@hooks";
import { useTranslation } from "react-i18next";
import { inviteToWorkspaceQuery } from "@query";
import { WorkspaceInviteForm } from "@forms";
import {
  HtmlParser,
  LOCKED_FEATURES,
  TEAM_ROLE_BUSINESS,
  TEAM_ROLE_TECHNICAL,
} from "@utils";
import { Alert, Button, Dialog, TagList } from "@components";
import { useTheme, CircularProgress, Grid } from "@material-ui/core";

export const WorkspaceInvite = ({
  isOpen,
  onClickAway,
  onInvite,
  useTechnicalInvites
}) => {

  const { t } = useTranslation();
  const { userStore } = useStores();
  const theme = useTheme();

  const [businessEmail, setBusinessEmail] = useState("");
  const [technicalEmail, setTechnicalEmail] = useState("");
  const [businessInvites, setBusinessInvites] = useState([]);
  const [technicalInvites, setTechnicalInvites] = useState([]);
  const [showAlert, setAlertState] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [erroneousEmails, setErroneousEmails] = useState([]);
  const [domainErroneousEmails, setDomainErroneousEmails] = useState([]);

  const emailErrors = !!erroneousEmails?.length;
  const domainEmailErrors = !!domainErroneousEmails?.length;

  const invitedUsers = useMemo(
    () => userStore?.workspace?.users?.map((u) => u.email) || [],
    [userStore?.workspace?.users]
  );

  const forbiddenEmails = useMemo(
    () =>
      [...technicalInvites, ...businessInvites, ...invitedUsers].filter(
        (e) => !!e
      ),
    [technicalInvites, businessInvites, invitedUsers]
  );

  const noEmails = useMemo(
    () =>
      !businessInvites.length &&
      (!useTechnicalInvites || !technicalInvites.length),
    [businessInvites, technicalInvites]
  );

  const checkAvailableUsers = async ({ allowed, info }) => {
    if (!allowed) return true;

    let available = info.value - info.used;
    const users = getAllUsers();

    if (available >= 0 && users.length > available) {
      await inviteUsers(users.slice(0, available)); // show alert & restore limited number of projects
      return true;
    }
  };

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.USER,
    checkAvailableUsers
  );

  const handleInviteUsers = async () => {
    setLoading(true);
    const allowed = await checkAccess();
    if (allowed) await inviteUsers(getAllUsers());

    setLoading(false);
    setAlertState(true);
  };

  const getAllUsers = () => {
    const b = [...businessInvites, businessEmail].filter(
      (e, i, a) => e && !a.slice(0, i).includes(e)
    );
    const t = [...technicalInvites, technicalEmail].filter(
      (e, i, a) => e && !a.slice(0, i).includes(e) && !b.includes(e)
    );
    return [
      ...b.map((email) => ({ email, role: TEAM_ROLE_BUSINESS })),
      ...t.map((email) => ({ email, role: TEAM_ROLE_TECHNICAL })),
    ];
  };

  const inviteUsers = async (emails) => {
    const results = await inviteToWorkspaceQuery(emails);
    setErroneousEmails(results.filter((r) => r.hasError).map((r) => r.email));
    setDomainErroneousEmails(results.filter((r) => r.hasDomainError).map((r) => r.email));

    if (results.filter((r) => !r.hasError).length) {
      onInvite && onInvite();
    }
  };

  const closeAlert = () => {
    if (!emailErrors && !domainEmailErrors) {
      setBusinessInvites([]);
      setTechnicalInvites([]);
      onClickAway();
    }
    setAlertState(false);
    setErroneousEmails([]);
    setDomainErroneousEmails([]);
  };

  const addTechnicalEmail = ({ email }) => {
    setTechnicalInvites([...technicalInvites, email]);
  };

  const addBusinessEmail = ({ email }) => {
    setBusinessInvites([...businessInvites, email]);
  };

  const removeTechnicalEmail = (o) => {
    const a = [...technicalInvites];
    a.splice(a.indexOf(o), 1);
    setTechnicalInvites(a);
  };
  const removeBusinessEmail = (o) => {
    const a = [...businessInvites];
    a.splice(a.indexOf(o), 1);
    setBusinessInvites(a);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClickAway}
      title={t("forms.workspace_invite.title")}
      actions={
        <>
          <Button variant="outlined" onClick={onClickAway}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            icon={
              isLoading ? (
                <CircularProgress
                  aria-label="progress indicator"
                  size={24}
                  color="inherit"
                />
              ) : null
            }
            disabled={noEmails}
            onClick={handleInviteUsers}
          >
            {!isLoading ? t("common.send") : null}
          </Button>
        </>
      }
    >
      <p className="mb-4">
        {t("forms.workspace_invite.subtitle")}</p>

      <WorkspaceInviteForm
        fieldName="business"
        onSubmit={addBusinessEmail}
        onChange={setBusinessEmail}
        forbiddenEmails={forbiddenEmails}
        useFieldSpecificName={useTechnicalInvites}
      />
      {!!businessInvites.length && (
        <Grid
          item container
          role="listbox"
          className="-mt-2 mb-4"
        >
          <TagList
            tags={businessInvites}
            color={theme.palette.text.primary}
            onRemove={removeBusinessEmail}
          />
        </Grid>
      )}
      {useTechnicalInvites && (
        <>
          <WorkspaceInviteForm
            fieldName="technical"
            onSubmit={addTechnicalEmail}
            onChange={setTechnicalEmail}
            forbiddenEmails={forbiddenEmails}
            useFieldSpecificName
          />
          {!!technicalInvites.length && (
            <Grid
              item container
              role="listbox"
              className="-mt-2 mb-4"
            >
              <TagList
                tags={technicalInvites}
                color={theme.palette.text.primary}
                onRemove={removeTechnicalEmail}
              />
            </Grid>
          )}
        </>
      )}
      <Alert
        title={
          emailErrors || domainEmailErrors
            ? t("forms.workspace_invite.fail")
            : `${t("common.done")}!`
        }
        isOpen={showAlert}
        onAccept={closeAlert}
        acceptText={t("common.close")}
      >
        {
          <>
            {!emailErrors && !domainEmailErrors && (
              <p>{HtmlParser(t("forms.workspace_invite.success"))}</p>
            )}
            {(emailErrors || domainEmailErrors) && (
              <>
                {
                  (
                    domainEmailErrors
                      ? domainErroneousEmails
                      : erroneousEmails
                  ).map((e, i) => (
                    <strong key={e + i}>{e}</strong>
                  ))
                }
                <p className="mt-4">
                  {
                    domainEmailErrors
                      ? t("forms.workspace_invite.fail_domain")
                      : t("forms.workspace_invite.fail_desc")
                  }
                </p>
              </>
            )}
          </>
        }
      </Alert>
      <FeatureAlert />
    </Dialog>
  );
};

WorkspaceInvite.propTypes = {
  isOpen: bool,
  onClickAway: func.isRequired,
  onInvite: func,
  invitedMembers: array,
  useTechnicalInvites: bool,
};

WorkspaceInvite.defaultProps = {
  type: "popup",
  invitedMembers: [],
};
