import { useMemo } from "react";
import { bool, func, number } from "prop-types";
import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { CollapseValueInnerCell, ResetValueButton } from "@components";
import { observer } from "mobx-react";

export const WeekValueCell = observer(({
  value: weeklyValue=0,
  week,
  maxHours,
  hasOverride,
  allowEdition,
  displayContent,
  displayCellContent,
  handleReset,
  handleValueChange,
  showDecimals
}) => {
  const { t } = useTranslation();
  const structure = useStructureStore();
  const { modifier, usedRoundDecimals } = structure.settings;
  
  const value = useMemo(() => (
    weeklyValue ? (weeklyValue / modifier) : 0
  ), [weeklyValue, modifier]);
  
  const displayResetButton = useMemo(() => (
    hasOverride && displayContent && allowEdition && handleReset
  ), [hasOverride, displayContent, allowEdition, handleReset])
  
  const displayedValue = useMemo(() => (
    displayCellContent && (hasOverride || value)
      ? value
      : ""
  ), [displayCellContent, hasOverride, value]);
  
  return (
    <CollapseValueInnerCell
      narrow
      value={displayedValue}
      useBorder
      max={maxHours}
      displayContent={displayContent}
      displayCellContent={displayCellContent}
      showDecimals={showDecimals}
      editable={allowEdition}
      textPresetClass="preset-timelineText"
      onValueChange={handleValueChange}
      decimals={usedRoundDecimals}
      inputClassName={ `input-week${week+1}` }
    >
      {displayResetButton && (
        <ResetValueButton action={handleReset}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
    </CollapseValueInnerCell>
  )
})

WeekValueCell.propTypes = {
  week: number.isRequired,
  value: number,
  maxHours: number,
  hasOverride: bool,
  allowEdition: bool,
  displayContent: bool,
  displayCellContent: bool,
  showDecimals: bool,
  handleReset: func,
  handleValueChange: func,
}