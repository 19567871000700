import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { TotalContainer } from "../components";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";


export const TotalHours = observer(({
  displayMax,
  ...containerProps
}) => {
  
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const {t} = useTranslation();
  
  const { settings, visibility, totalHours, showTotalMaxHours } = structure;
  const { useMinMax, timeModifier, usedRoundDecimals, roundType, roundHours } = settings;
  const { apply, hideTotalHours } = visibility;
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideTotalHours && "transparent-2") || undefined
  ), [visibilityMode, apply, hideTotalHours])
  
  const showDecimals = useMemo(() => (
    roundType !== 2 || !roundHours
  ), [roundType, roundHours]);
  
  return (
    <TotalContainer
      totalName="Hours"
      min={useMinMax ? totalHours.min : totalHours.avg}
      max={totalHours.max}
      displayMax={showTotalMaxHours && displayMax}
      symbolEnd={timeModifier === 1 ? t("times.h") : " "+t("times.day")}
      textSmaller
      decimals={usedRoundDecimals}
      showDecimals={showDecimals}
      className={className}
      visibilityHidden={hideTotalHours}
      setVisibility={visibility.setHideTotalHours}
      {...containerProps}
    />
  );
})

TotalHours.propTypes = {
  isProposal: bool,
  divider: bool,
  displayMax: bool,
}