import { fetchData } from "@client";

export async function getCategoriesQuery(group = false) {
  try {
    const res = await fetchData("/category");
    const data = res.results;
    if (group)
      return data.filter((a) => {
        if (a.parent > 0) {
          const parent = data.find((b) => a.parent === b.id);
          if (parent) {
            a.parent = parent.name;
            return a;
          }
        }
        return false;
      });

    return data;
  } catch (e) {
    return [];
  }
}
