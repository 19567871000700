import { bool, string, object, node, arrayOf, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { PopMenu } from "@components";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { scrollReachedBottom } from "@utils";
import { useRef } from "react";
import classnames from "classnames";

export const SearchableListContent = ({
  id,
  children,
  emptyMessage,
  showEmptyMessage,
  scrollEndHandler,
  className,
  ...popMenuProps
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  
  const reachedBottom = useRef(false);
  
  const handleScroll = (e) => {
    const onBottom = scrollReachedBottom(e.currentTarget);
    if(onBottom && !reachedBottom.current && scrollEndHandler)
      scrollEndHandler();
    reachedBottom.current = onBottom;
  }

  return (
    <PopMenu
      placement="bottom-start"
      {...popMenuProps}
      className={classnames(classes.root, className)}
    >
      <Grid
        id={id}
        item container
        className={classnames(classes.list, "w-full overflow-auto py-2")}
        onScroll={handleScroll}
      >
        {children}
        {(!children.length || showEmptyMessage) && (
          <p className="text-secondary my-2 mx-4">
            {emptyMessage || t("views.editor.dialogs.features.not_found")}
          </p>
        )}
      </Grid>
    </PopMenu>
  );
};

SearchableListContent.propTypes = {
  id: string,
  show: bool,
  anchor: object,
  children: arrayOf(node),
  scrollEndHandler: func,
  emptyMessage: string,
  showEmptyMessage: bool,
  className: string,
};

const useStyle = makeStyles(() => ({
  root: {
    width: "70vw",
    maxWidth: 670,
    // height: 324,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
  },
  list: {
    maxHeight: 264,
  }
}));
