import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { ValueCell, ResetValueButton } from "@components";
import { useMemo } from "react";
import { MAX_VALUES } from "@utils";

export const RateCell = observer(({
  element,
  useClientActions,
  isProposal,
  displayClientCellContent,
  displayCellContent,
  editorOrClient,
  profitabilityMode,
}) => {
  const { t } = useTranslation();
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  const { currencyObj, modifier, timeModifier } = structure.settings;
  const { apply, hideSummaryRate } = structure.visibility;
  const { parsedRate, hasClientRate, hasResources, hasOnlyCost } = element;

  const handleRateChange = (rate) => {
    element.setRate(rate / modifier, useClientActions && isProposal);
  };
  
  const messageTitle = useMemo(() => {
    if(profitabilityMode && hasOnlyCost)
      return t("views.editor.profitability_fixed_alert.title")
  }, [profitabilityMode, hasOnlyCost]);
  
  const messageContent = useMemo(() => {
    if(profitabilityMode && hasOnlyCost)
      return t("views.editor.profitability_fixed_alert.content")
  }, [profitabilityMode, hasOnlyCost]);
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryRate && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryRate]);

  return (
    <ValueCell
      widest
      displayContent={profitabilityMode ? displayCellContent : displayClientCellContent}
      value={hasOnlyCost ? "" : parsedRate}
      onValueChange={handleRateChange}
      editable={!hasResources && editorOrClient}
      max={timeModifier === 1 ? MAX_VALUES.RATE_HOUR : MAX_VALUES.RATE_DAY}
      // placeholder={t("views.editor.summary_table.price_placeholder")}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      textPresetClass="preset-summaryText"
      useSeparator={profitabilityMode}
      messageTitle={messageTitle}
      message={messageContent}
      showDecimals
      className={className}
      inputClassName="input-rate"
    >
      {displayClientCellContent &&
        useClientActions &&
        isProposal &&
        hasClientRate && (
          <ResetValueButton action={element.resetClientRate}>
            {t("views.editor.alert")}
          </ResetValueButton>
        )}
    </ValueCell>
  );
});

RateCell.propTypes = {
  element: object.isRequired,
  displayClientCellContent: bool,
  editorOrClient: bool,
  useClientActions: bool,
  profitabilityMode: bool,
};
