import { ACCESS_DOMAINS } from "@client";

export function checkSetUpQuery(
  projectId,
  pathName,
  companyId
) {
  const response = window.location.replace(
    ACCESS_DOMAINS.btApi +
      "check-set-up?project_id=" +
      projectId +
      "&pathName=" +
      pathName +
      "&company_id=" +
      companyId
  );
  return response;
}