import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { TooltipIcon, ValueCell } from "@components";
import { ExpandMore } from "@material-ui/icons";
import classnames from "classnames";
import { useBudgetTrackingStore, useStructureStore } from "@hooks";
import useStyle from "./ExpandTitleCostCell.style";

export const ExpandTitleCostCell = observer(() => {
  const { cost } = useBudgetTrackingStore();
  const budgetTracking = useBudgetTrackingStore();
  const structure = useStructureStore();
  const { t } = useTranslation();
  const classes = useStyle();

  const { currencyObj } = structure.settings;

  const open_cost = () => {
    return budgetTracking.setCost(!cost);
  };

  return (
    <ValueCell
      isHeader
      className={classnames(
        "p-0",
        classes.cellContainer,
        cost && classes.cellExpanded
      )}
    >
      <Grid item container direction="column">
        <span className="preset-timelineHeader">
          {t("views.editor.summary_table.cost_currency", {currency: currencyObj.symbolStart})}
        </span>
        {cost && (
          <Grid
            item
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
          >
            {[
              "views.bt.estimate",
              "views.bt.spent",
              "views.bt.diffh",
              "views.bt.diff",
            ].map((week) => (
              <span
                key={week}
                className={classnames(
                  "preset-timelineHeader my-1",
                  classes.weekCell
                )}
              >
                {t(week)}
              </span>
            ))}
          </Grid>
        )}
      </Grid>
      <TooltipIcon
        icon={
          <ExpandMore
            className={classnames(
              "expand transition -rotate-90",
              cost && "rotate-90"
            )}
          />
        }
        onClick={open_cost}
        className={classnames(
          "noDrag opaque absolute left",
          classes.button,
          !cost && "cellHoverIcon"
        )}
        color="primary"
      >
        {t("times.hour")}
      </TooltipIcon>
    </ValueCell>
  );
});

ExpandTitleCostCell.propTypes = {};
