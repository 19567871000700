import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: 8,
    display: "flex",
    position: "relative",
    cursor: "pointer",
    borderRadius: 5,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15) !important",
    border: "1px solid transparent",
    "&:not(:hover) > button": {
      display: "none",
    },
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-khtml-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    userSelect: "none",
    width: 200,
  },
  canvasThumb: {
    borderRadius: 5,
    width: "100%",
    height: "auto",
    maxHeight: "100%"
  },
  imageThumb: {
    borderRadius: 5,
    position: "absolute",
    top: 0,
    zIndex: -1,
    width: "100%",
    height: "auto",
    maxHeight: "100%",
  },
  loadingPlaceholder: {
    width: "100%",
    aspectRatio: "4 / 3"
  },
  highlight: {
    border: "1px solid " + theme.palette.primary.main,
  },
  removeButton: {
    position: "absolute",
    right: -8,
    top: -8,
    zIndex: 100,
    backgroundColor: theme.props.backgroundSecondary + " !important",
  },
  proposal: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: theme.props.backgroundSecondary,
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  proposalHighlight: {
    background: theme.palette.primary.main,
    color: theme.props.text.alt,
  },
}));

export default useStyle;
