import axios from "axios";
import { ACCESS_DOMAINS, updateUserToken } from "@client";

export const fetchDataResponseInterceptor = (response) => {
  if(!response.config.baseURL === ACCESS_DOMAINS.api) return response;
  
  if(response.headers.token) {
    axios.defaults.headers.common["Authorization"] = response.headers.token;
    updateUserToken(response.headers.token);
  }
  
  return response;
}