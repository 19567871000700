import { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Button as MuiButton, Link, Tooltip } from "@material-ui/core";
import useStyle from "./CommonButtons.style";
import classnames from "classnames";

export const Button = forwardRef(( {
  icon,
  children,
  to,
  href,
  className,
  tooltip,
  isSubmit,
  tooltipText,
  name,
  endIcon,
  size,
  square,
  disabled,
  ...muiButtonProps
}, ref) => {

  const classes = useStyle();
  const location = useLocation();

  const component = useMemo(
    () => (href ? Link : to ? RouterLink : undefined),
    [href, to]
  );

  const buttonComponent = (
    <MuiButton
      ref={ref}
      type={isSubmit ? "submit" : "button"}
      className={classnames(
        !children && classes.noTextIcon,
        square && classes["buttonSquare-"+size],
        className
      )}
      component={component}
      to={to}
      state={to ? { from: location.pathname } : undefined}
      href={href}
      name={name || (typeof children === "string" ? children : "")}
      aria-label={name || (typeof children === "string" ? children : "")}
      endIcon={endIcon ? endIcon : null}
      startIcon={!endIcon ? icon : null}
      size={size}
      disabled={disabled}
      {...muiButtonProps}
    >
      {children}
    </MuiButton>
  );

  return ((tooltip && name) || tooltipText) && !disabled ? (
    <Tooltip title={tooltipText || name}>{buttonComponent}</Tooltip>
  ) : (
    buttonComponent
  );
});

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  icon: PropTypes.node,
  endIcon: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  square: PropTypes.bool,
  name: PropTypes.string,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  tooltip: PropTypes.bool,
  tooltipText: PropTypes.string,
};

Button.defaultProps = {
  color: "primary",
  size: "medium",
};
