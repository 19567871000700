export const handleFixedTableHeader = (el, id, scrollTop, offsetTop) => {
  const parentTable = document.getElementById(id);
  if(!parentTable) return;
  const anchor_top = parentTable.offsetTop - offsetTop - 64; // @todo: check app bar height
  const anchor_bottom = parentTable.offsetTop + parentTable.offsetHeight;
  if (scrollTop > anchor_top && scrollTop < anchor_bottom) {
    el.style.transform = `translateY(${scrollTop - anchor_top}px)`;
    el.style.boxShadow = "rgba(0, 0, 0, 0.14) 0px 4px 5px 0px";
  } else if (el.style.transform) {
    el.style.transform = null;
    el.style.boxShadow = null;
  }
};
