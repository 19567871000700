import { getCompanySettingsQuery, setCompanySettingsQuery } from "@query";
import { DEFAULT_RATES } from "project-structure";
import { COMPANY_SETTINGS } from "@client";

export async function getDefaultRatesQuery() {
  let defRates = await getCompanySettingsQuery(COMPANY_SETTINGS.RATES);
  if (!defRates || !Object.keys(defRates).length) {
    defRates = { ...DEFAULT_RATES };
    await setCompanySettingsQuery(COMPANY_SETTINGS.RATES, defRates);
  }

  return defRates;
}
