import { bool, func } from "prop-types";
import { WysiwygEditor } from "@components";

export const WysiwygInputField = ({
  disabled,
  ...rest
}) => (
  <WysiwygEditor
    readOnly={disabled}
    toolbarVariant="popup"
    noEmptyHtmlStrings
    {...rest}
  />
);

export const WysiwygInputFieldProps = {
  onChange: func,
  onFocus: func,
  onBlur: func,
  disabled: bool,
};

WysiwygInputField.propTypes = WysiwygInputFieldProps;
