import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { Alert } from "@components";

export const MobileExperienceAlert = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Alert
      title={t("alerts.mobile_experience.title")}
      isOpen={isOpen}
      onAccept={onClose}
      acceptText={t("common.close")}
    >
      <p className="mb-4">{t("alerts.mobile_experience.text")}</p>
      <p>{t("alerts.mobile_experience.text2")}</p>
    </Alert>
  );
};

MobileExperienceAlert.propTypes = {
  onClose: func,
  isOpen: bool,
};
