import { forwardRef } from "react";
import {
  bool,
  number,
  string,
  node,
  func,
  oneOfType,
  arrayOf,
} from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Button } from "@components";
import { ExpandMore } from "@material-ui/icons";
import useStyle from "./TableTitle.style";
import classnames from "classnames";

export const TableTitle = forwardRef(({
  id,
  title,
  showContent,
  expandable,
  onContentAction,
  children,
  action,
  presetClass,
  buttonPresetClass,
  disabled,
  presetEditionMode,
}, ref ) => {
  const classes = useStyle();

  return (
    <Grid
      data-id={id}
      item
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
      ref={ref}
      name={`table_${title.toLowerCase().replace(/ /g, "_")}`}
    >
      <Grid container alignItems="center" className={classnames("w-max", disabled && "transparent-2")}>
        {(expandable || presetEditionMode) && (
          <Button
            color="secondary"
            size="small"
            variant="contained"
            className={classnames("expand", classes.expandButton, buttonPresetClass)}
            onClick={onContentAction}
            name={showContent ? "Collapse table" : "Expand table"}
            icon={
              <ExpandMore
                className={classnames(" transition", showContent ? "-rotate-90" : "rotate-0")}
              />
            }
          />
        )}
        <h3 className={`preset-${presetClass}Header pt-${presetClass}`}>
          {title}
        </h3>
        {action}
      </Grid>
      {children}
    </Grid>
  );
});

TableTitle.propTypes = {
  id: oneOfType([string, number]).isRequired,
  presetClass: string.isRequired,
  buttonPresetClass: string,
  title: string.isRequired,
  children: oneOfType([arrayOf(node), node]),
  showContent: bool,
  onContentAction: func,
  action: node,
  expandable: bool,
  disabled: bool,
  presetEditionMode: bool,
};
