import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  teamMembers: {
    marginLeft: 8,
    fontSize: 10,
    fontWeight: 600,
    width: 16,
    height: 16,
    borderRadius: "50%",
    background: theme.props.warning,
    color: theme.props.text.alt,
  },
}));

export default useStyle;
