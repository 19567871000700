import { SvgIcon } from "@material-ui/core";

export default function XlsFileDark(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M13.9995 10.9995C11.2395 10.9995 9.00001 8.76001 9.00001 6.00001C9.00001 4.02 10.17 2.2605 11.91 1.4595C12.1905 0.9195 12.5295 0.429 12.9405 0H2.7495C1.23 0 0 1.23 0 2.7495V21.249C0 22.77 1.23 24 2.7495 24H17.25C18.7695 24 19.9995 22.77 19.9995 21.2505V11.001H13.9995V10.9995ZM6.90601 20.886C7.10701 21.249 6.97801 21.705 6.61501 21.906C6.49951 21.9705 6.37501 22.0005 6.25201 22.0005C5.98801 22.0005 5.733 21.861 5.595 21.615L5.001 20.544L4.407 21.615C4.2705 21.861 4.014 22.0005 3.75 22.0005C3.627 22.0005 3.5025 21.9705 3.387 21.906C3.024 21.705 2.895 21.249 3.096 20.886L4.143 19.0005L3.096 17.115C2.895 16.752 3.024 16.296 3.387 16.095C3.75 15.894 4.206 16.0245 4.407 16.386L5.001 17.457L5.595 16.386C5.7945 16.0245 6.25201 15.894 6.61501 16.095C6.97801 16.296 7.10701 16.752 6.90601 17.115L5.85901 19.0005L6.90601 20.886ZM11.25 22.0005H8.74951C8.33551 22.0005 7.99951 21.6645 7.99951 21.2505V16.7505C7.99951 16.3365 8.33551 16.0005 8.74951 16.0005C9.16351 16.0005 9.49951 16.3365 9.49951 16.7505V20.5005H11.25C11.664 20.5005 12 20.8365 12 21.2505C12 21.6645 11.664 22.0005 11.25 22.0005ZM14.7915 18.2505H15.207C16.1955 18.2505 16.9995 19.092 16.9995 20.1255C16.9995 21.159 16.1955 22.0005 15.207 22.0005H13.749C13.335 22.0005 12.999 21.6645 12.999 21.2505C12.999 20.8365 13.335 20.5005 13.749 20.5005H15.207C15.3645 20.5005 15.4995 20.328 15.4995 20.1255C15.4995 19.923 15.366 19.7505 15.207 19.7505H14.7915C13.803 19.7505 12.999 18.909 12.999 17.8755C12.999 16.842 13.803 16.0005 14.7915 16.0005H16.2495C16.6635 16.0005 16.9995 16.3365 16.9995 16.7505C16.9995 17.1645 16.6635 17.5005 16.2495 17.5005H14.7915C14.634 17.5005 14.499 17.673 14.499 17.8755C14.5005 18.078 14.634 18.2505 14.7915 18.2505Z"
        fill="currentColor"
      />
      <path
        d="M20.4995 9.00001H14C12.3455 9.00001 11 7.65451 11 6.00001C11 4.542 12.044 3.324 13.424 3.0555C13.943 1.278 15.6005 0 17.4995 0C18.998 0 20.3855 0.798001 21.143 2.0625C22.775 2.361 23.999 3.795 23.999 5.5005C23.999 7.42951 22.4285 9.00001 20.4995 9.00001Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
    </SvgIcon>
  );
}
