export const MAX_WYSIWYG_LENGTH = 3000;

export const quillBlocksRegex = /(<pre.*?>[^~]*?<\/pre>)|(<p.*?>[^~]*?<\/p>)|(<h[123].*?>[^~]*?<\/h[123]>)|(<blockquote.*?>[^~]*?<\/blockquote>)|(<li.*?>[^~]*?<\/li>)/g

export const WYSIWYG_FORMATS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "code",
  "link",
  "header",
  "list",
  "blockquote",
  "code-block",
  "indent",
];
export const WYSIWYG_FORMATS_WITH_MENTIONS = [
  "bold",
  "italic",
  "underline",
  "strike",
  "code",
  "link",
  "header",
  "list",
  "blockquote",
  "code-block",
  "indent",
  "mention",
];