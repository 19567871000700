import { bool, node, number, oneOf, oneOfType, shape, string } from "prop-types";
import Grid from "@material-ui/core/Grid";
import { ValueCellReadOnlyContent } from "../../components/ValueCellReadOnlyContent";
import { ValueCellEditableContent } from "../../components/ValueCellEditableContent";
import useStyle from "./CollapseValueInnerCell.style";
import useCellStyle from "../../commonStyles.style";
import classnames from "classnames";
import { InputCellProps } from "../../types";

export const CollapseValueInnerCell = ({
  displayContent,
  displayCellContent,
  editable,
  children,
  displayMax,
  onValueChange,
  textPresetClass,
  isHeader,
  useBorder,
  useSeparator,
  narrow,
  ...valueProps
}) => {
  const classes = useStyle();
  const cellClasses = useCellStyle();
  
  if(!displayContent)
    return <></>
  
  return <Grid
    item container
    justifyContent="center"
    alignItems="center"
    wrap="nowrap"
    className={classnames(
      isHeader && "h-full",
      !isHeader && cellClasses.collapseCellInner,
      classes.cell,
      useSeparator && cellClasses.withSeparator,
      narrow && classes.narrow,
      useBorder && cellClasses.fullDashBorderRight,
    )}
  >
    {displayCellContent && (editable ? (
      <ValueCellEditableContent
        {...valueProps}
        displayMax={displayMax}
        onValueChange={onValueChange}
        className={textPresetClass}
      />
      ) : (
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        className={classnames(
          textPresetClass,
          isHeader && "preset-tableHeader"
        )}
      >
        <ValueCellReadOnlyContent
          {...valueProps}
          displayMax={displayMax}
        />
      </Grid>
    ))}
    {children}
    {useSeparator && <span className="separatorInner" />}
  </Grid>
  
}

CollapseValueInnerCell.propTypes = {
  ...InputCellProps,
  min: oneOfType([number, shape({ min: number, max: number })]),
  max: oneOfType([number, shape({ min: number, max: number })]),
  children: oneOfType([node, string]), //reset button
  useBorder: bool,
  useSeparator: bool,
  showDecimals: bool,
  isHeader: bool,
  narrow: bool,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"]),
  inputClassName: string,
}