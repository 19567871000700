import { observer } from "mobx-react";
import { isMobile } from "react-device-detect";
import ResizeObserver from "react-resize-observer";
import { CONTENT_WIDTH } from "@utils";
import { AppContainer, PdfEditorContainer, PdfUploadProgress, ProposalAppBar } from "@components";
import { PolicyDialog } from "@dialogs";
import { useMemo } from "react";
import { SessionRecorder } from "@tools";
import { useSharedProjectKey } from "@hooks";
import { useLocation } from "react-router-dom";

const PdfProposalView = observer(() => {
  const shareKey = useSharedProjectKey();
  const { search } = useLocation();
  
  const clientEmail = new URLSearchParams(search).get("c") || null
  const sessionRecorder = useMemo(
    () =>
      new SessionRecorder({
        shareKey,
        clientEmail,
        type: "proposal", //"pdf",
        useCompression: true,
      }),
    []
  );
  
  const handleViewPortChange = () => {
    document.querySelector(
      'meta[name="viewport"]'
    ).content = `width=device-width, initial-scale=${Math.min(
      window.screen.width / CONTENT_WIDTH,
      1
    )}`;
  };
  
  const initSession = () => {
    sessionRecorder?.init();
  };

  return (
    <>
      {isMobile && <ResizeObserver onResize={handleViewPortChange} />}
      <ProposalAppBar proposalShareKey={shareKey} isPdfProject />
      <AppContainer>
        <PdfEditorContainer
          noShareActions
          showPageImages
        />
        <PdfUploadProgress />
        <PolicyDialog onDisclaimerClose={initSession} />
      </AppContainer>
    </>
  );
});

export default PdfProposalView;
