import { APIMethod, fetchData } from "@client";

export async function downloadFileFromUrlQuery(
  fileUrl,
  onDownloadProgress = undefined,
  responseType = "blob"
) {
  return await fetchData(fileUrl, APIMethod.GET, {
    noAuth: true,
    onDownloadProgress,
    responseType,
  });
}
