import { fetchData } from "@client";
import pako from "pako";

export async function getSessionDataQuery(id) {
  const res = await fetchData(`/session/${id}`);
  return {
    ...res.results,
    session: Object.values(res.results.session).reduce((p, c) => {
      try {
        let parsed;
        if (res.results.compressed) {
          const unzipped = pako.inflate(new Uint8Array(c.split(",")), {
            to: "string",
          });
          parsed = JSON.parse(unzipped);
        } else parsed = JSON.parse(c);
        return [...p, ...(parsed.events || parsed)];
      } catch (e) {
        return p;
      }
    }, []),
  };
}
