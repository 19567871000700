import { observable, action, makeObservable } from "mobx";
import { APIMethod, fetchData } from "@client";

export class MessageStore {
  constructor() {
    makeObservable(this);
  }
  // =============== PROPERTIES ===============

  @observable newMessages = [];

  // ================ ACTIONS =================

  @action async getAll(page) {
    const { paginate, results } = await fetchData(
      `/user/notifications?type=all&page=${page}&limit=15`
    );
    results.forEach((x) => {
      if (x.status === 0 && !this.newMessages.find((y) => y.id === x.id))
        x.status = 1;
    });
    return { paginate, results };
  }

  @action async getNew() {
    const res = await fetchData(
      "/user/notifications?type=unread&page=1&limit=100"
    );
    
    if (!res?.results || res?.results?.length === this.newMessages.length)
      return;
    
    const results = res.results.map((x) => x.id);
    const current = this.newMessages.map((x) => x.id);
    const diff = current
      .filter((x) => !results.includes(x))
      .concat(results.filter((x) => !current.includes(x)));
    if (!diff.length) return;
    
    this.newMessages = res.results;
  }

  @action async readMessage(id) {
    try {
      await fetchData("/user/notifications", APIMethod.PUT, { data: { id } });
      this.newMessages = this.newMessages.filter((x) => x.id !== id);
    } catch (e) {
      return e;
    }
  }
}
