import { ActionButton } from "@components";
import { useTranslation } from "react-i18next";
import { getCurrencyExchangeRatesQuery } from "@query";
import { useFeatureGuardian, useProjectEditorStore, useStructureStore } from "@hooks";
import { observer } from "mobx-react";
import { DollarSign } from "@assets";
import { LOCKED_FEATURES } from "@utils";
import { CircularProgress } from "@material-ui/core";

export const ProfitabilityMode = observer(() => {
  
  const { t } = useTranslation();
  const editorStore = useProjectEditorStore();
  const structure = useStructureStore();
  
  const { checkAccess, isChecking, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.PROFITABILITY
  );
  
  const { profitabilityMode } = editorStore;
  const { currency, companyCurrency } = structure.settings;
  
  const handleProfitabilityMode = async () => {
    if(!profitabilityMode) {
      if(!editorStore.allowProfitability) {
        const allowed = await checkAccess();
        if(!allowed)
          return;
        editorStore.setAllowProfitability(allowed);
      }
      
      const [
        companyCurrencyRate,
        projectCurrencyRate
      ] = await getCurrencyExchangeRatesQuery(companyCurrency, currency);
      editorStore.setCurrencyExchangeRate(companyCurrencyRate.value, projectCurrencyRate.value);
    }
    editorStore.switchProfitabilityMode();
  };
  
  return (
    <>
      <ActionButton
        square
        icon={isChecking ? <CircularProgress size={20} /> : <DollarSign />}
        color={profitabilityMode ? "primary" : "secondary"}
        onClick={handleProfitabilityMode}
        name={t(`views.editor.dialogs.project_settings.profitability.${profitabilityMode ? "off" : "on"}`)}
        tooltip
      />
      <FeatureAlert />
    </>
  )
})