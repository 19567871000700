import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  // dialog: {
  //   "& .MuiBackdrop-root": {
  //     opacity: "0 !important",
  //   },
  // },
  list: {
    maxHeight: 240,
  },
  addButton: {
    width: "100% !important",
    justifyContent: "center !important",
  },
}));

export default useStyle;
