import { SvgIcon } from "@material-ui/core";

export const CommentAdd = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0868 18L6 21.8042V18H4C2.89543 18 2 17.1046 2 16V4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V16C22 17.1046 21.1046 18 20 18H12.0868ZM8 18.1958L11.5132 16H20V4H4V16H8V18.1958ZM11 6V9H8V11H11V14H13V11H16V9H13V6H11Z"
      fill="currentColor"
    />
  </SvgIcon>
);