import { useMemo } from "react";
import { arrayOf, func, number, shape, string } from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { Button, ControlledCheckboxGroup, InfoLabel, Autocomplete } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";

const newProjectFormSchema = (t) =>
  yup.object().shape({
    project: yup.object()
      .shape({
        name: yup.string(),
        id: yup
          .string()
          .required(t("views.bt.select_project")),
      })
      .required(t("views.bt.select_project")),
    version: yup
      .number()
      .required(t("errors.version_key.required")),
  });

export const JiraExportForm = ({
  onSubmit,
  projects,
  projectVersions,
  defaultProject,
  defaultVersion
}) => {
  const { t } = useTranslation();
  
  const defaultValues = useMemo(() => ({
    project: defaultProject || {
      name: null,
      id: null
    },
    version: defaultVersion,
  }), []);
  
  const {
    control,
    handleSubmit,
    reset: resetForm,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(newProjectFormSchema(t)),
    defaultValues,
  });
  
  const submitHandler = async (data) => {
    const result = await onSubmit(data);
    
    if (result) {
      const { errors, reset } = result;
      
      if (reset) {
        resetForm(defaultValues);
        return;
      }
      
      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };
  
  return (
    <form
      autoComplete="on"
      aria-label="companyDetails form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container direction="column" wrap="nowrap" className="p-6">
        <InfoLabel spacing={8} label="views.bt.select_project_full" />
        <Controller
          name="project"
          control={control}
          render={({ field: { onChange, value, name }, fieldState: { error } }) => (
            <Autocomplete
              id="teamRoleSelect"
              value={value?.id}
              options={projects}
              name={name}
              idKey="id"
              labelKey="name"
              inputLabel={t("common.project")}
              fullWidth
              onChange={(_, v) => onChange(v)}
              error={!!error?.id}
              helperText={error?.id?.message}
              size="small"
            />
          )}
        />
        
        <InfoLabel spacing={8} label="views.bt.following_version" />
        <ControlledCheckboxGroup
          control={control}
          name="version"
          options={projectVersions}
          optionIdentifier="key"
          parseLabel={(name, index) => `${t("views.bt.version")} ${index + 1}: ${name}`}
        />
        
        <Divider className="mt-2 -mx-6 mb-3" />
        
        <Grid item container justifyContent="flex-end">
          <Button
            isSubmit
            name="submit"
            variant="contained"
            endIcon={
              isSubmitting ? (
                <CircularProgress
                  aria-label="progress indicator"
                  size={24}
                  color="inherit"
                />
              ) : undefined
            }
          >
            {t("common.next")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

JiraExportForm.propTypes = {
  onSubmit: func,
  projectVersions: arrayOf(shape({
    key: number,
    name: string
  })),
  projects: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    // disabled: bool,
  })),
  defaultVersion: number.isRequired,
  defaultProject: shape({
    name: string.isRequired,
    key: string.isRequired,
  })
};