import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  autocomplete: {
    height: "max-content",
    maxWidth: 260,
    marginRight: 8,
  },
}));

export default useStyle;
