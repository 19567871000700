import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  input: {
    height: "30px !important",
    margin: "0 !important",
    padding: 0,
    borderRadius: "4px !important",
    minWidth: 100,
    maxWidth: "initial !important",
    borderWidth: 2,
    borderStyle: "solid ",
    borderColor: "transparent",
    color: "inherit",
    "& > input": {
      width: "max-content",
      minWidth: "100%",
      height: "30px !important",
      padding: "0 !important",
      textAlign: "center",
    },
    "&:hover": {
      borderColor: theme.palette.text.primary,
    },
  },
  colored: {
    backgroundColor: theme.props.sectionColor,
    color: theme.props.text.alt,
  },
  inputFocused: {
    border: "2px solid " + theme.palette.text.primary,
  },
  text: {
    minHeight: 30,
    margin: "0 !important",
    borderRadius: "4px !important",
    minWidth: 100,
    fontSize: 16,
    justifyContent: "center",
    alignItems: "center",
    color: "inherit",
  },
}));

export default useStyle;
