import { observer } from "mobx-react";
import { Grid, Switch } from "@material-ui/core";
import { bool, number, object } from "prop-types";

export const TeamTogetherSwitch = observer(({
  workType,
  disabled,
  gridSize
}) => {
  
  const { teamTogether, hasResources } = workType;
  
  const handleTeamTogether = () => {
    workType.setTeamTogether(!teamTogether);
  }
  
  return (
    <Grid
      item container
      xs={gridSize}
      justifyContent="center"
      className="pl-4"
    >
      {
        hasResources &&
        <Switch
          size="small"
          color="primary"
          checked={teamTogether}
          onChange={handleTeamTogether}
          disabled={disabled}
        />
      }
    </Grid>
  )
})

TeamTogetherSwitch.propTypes = {
  workType: object.isRequired,
  disabled: bool,
  gridSize: number,
}