import { useState } from "react";
import { func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { ColorPickerDialog, TooltipIcon } from "@components";
import { Palette } from "@material-ui/icons";

export const RateElementColorEditor = ({
  elementName,
  color,
  onColorChange
}) => {
  
  const { t } = useTranslation();
  
  const [isOpened, show] = useState();
  
  const handleVisibility = () => {
    show(!isOpened);
  }
  
  return (
    <>
      <TooltipIcon
        onClick={handleVisibility}
        size="small"
        className="ml-2"
        icon={<Palette style={{ color }} />}
      >
        {t("views.settings.projects.color_select")}
      </TooltipIcon>
      <ColorPickerDialog
        show={isOpened}
        onClose={handleVisibility}
        initialColor={color}
        title={t("views.settings.projects.color_select_dialog_title", {name: elementName})}
        onChange={onColorChange}
      />
    </>
  );
};

RateElementColorEditor.propTypes = {
  elementName: string,
  color: string,
  onColorChange: func
};