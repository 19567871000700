import { APIMethod, fetchData } from "@client";
import { zipStructure } from "project-structure";
import { libraryChildMap } from "@utils";

export async function moveLibraryElementQuery(
  serializedElement,
  level,
  parentComponentId = undefined
) {
  const res = await fetchData(
    "/v1/library/pack",
    APIMethod.POST,
    {
      removeEmptyKeys: true,
      data: {
        json: zipStructure(JSON.stringify(serializedElement)),
        parent: parentComponentId,
        lvl: 4 - level, // yeah, this method "reverts" level notation
      }
    }
  );
  return libraryChildMap(res.results, "componentId");
}
