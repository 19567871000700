import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  deadline: {
    fontSize: 12,
    opacity: 0.75,
    lineHeight: "14px",
    display: "block",
  },
  hasDeadline: {
    textTransform: "uppercase",
  },
  hasError: {
    color: theme.palette.error.main,
    opacity: 1,
  },
  hasWarning: {
    color: theme.palette.warning.main,
    opacity: 1,
  },
}));

export default useStyle;
