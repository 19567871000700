import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  title: {
    fontSize: 32,
    textAlign: "center",
    marginTop: 96,
    marginBottom: 16
  },
  sectionTitle: {
    fontSize: 32,
    marginTop: 48,
  },
  sectionTitleFirst: {
    fontSize: 32,
  },
}));

export default useStyle;
