import { useEffect, useMemo } from "react";
import { onAction, onPatch } from "mobx-state-tree";
import { useEditorWebsocket, useLibraryStore, useStores } from "@hooks";
import { EditorCommands } from "@client";
import {
  addLibraryElementQuery,
  changeLibraryWorkTypeQuery,
  removeLibraryElementQuery,
  setCompanySettingsQuery,
  updateLibraryElementNameQuery,
  updateLibraryElementParentQuery,
  updateLibraryElementHoursQuery,
} from "@query";
import { COMPANY_SETTINGS } from "@client";

let updatedIds = {};
let blockValueUpdates = false;
let remoteChange = false;

export const useStructureTransactionListener = (
  structure,
  isLibrary = false,
  hasEditorAccess = false
) => {

  const { userStore } = useStores();
  const socket = useEditorWebsocket();
  const libraryStore = useLibraryStore();

  const patchListenerCb = ({ path, op }) => {
    if (isLibrary) {
      if (
        path.includes("useMinMax") ||
        path.includes("timeModifier") ||
        path.includes("showPrices")
      ) {
        const { useMinMax, timeModifier, showPrices } = structure.settings;
        setCompanySettingsQuery(COMPANY_SETTINGS.LIBRARY_SETTINGS, {
          useMinMax,
          timeModifier,
          showPrices,
        });
      } else if ( path.includes("values") ) {
        /** @note: use same code to send data to other when they're in project **/
        
        const pathMatch = path.match(/\/values\/(\d+)\/?(\w+)?/);
        if ( op === "remove" ) return;
        
        if ( pathMatch?.[ 1 ] && !blockValueUpdates ) {
          
          let childPath = path
            .slice(1, path.indexOf("/values"))
            .split("/");
          
          for ( let i = childPath.length; i > 1; i -= 2 ) {
            const child = childPath
              .slice(0, i)
              .reduce(( node, key ) => node[ key ], structure);
            if ( !child.id ) return;
            
            if (
              !updatedIds[ child.id ]?.includes(pathMatch[ 2 ]) &&
              typeof child.id !== "string"
            ) {
              // id is unique in library & there's no need to update parent several times
              const values = child.taskValues.get(pathMatch[ 1 ]);
              updateLibraryElementHoursQuery(
                userStore.data.id,
                child.id,
                pathMatch[ 1 ],
                values?.min || 0,
                values?.avg || 0,
                values?.max || 0
              );
              updatedIds[ child.id ] = [
                ...(updatedIds[ child.id ] || []),
                pathMatch[ 2 ],
              ];
            }
          }
        }
      }
    }
  };

  const actionListenerCb = ({ name, path, args }) => {
    if (path.includes("historyManager") || localActions.includes(name) || name.includes("_internal")) {
      if(name === "withoutUndo")
        remoteChange = false;
      return;
    }

    if (args?.[0] !== EditorCommands.CHANGE && !remoteChange) {
      if (
        ["addResource", "addChild"].includes(name) ||
        (name === "addWorkType" && !path.match(/\/sections\/\d+/))
      )
        args[0] = false;
      
      const actionData = {
        name,
        args,
        path
      }

      socket?.requestStructureChange(actionData);
    }
    remoteChange = false;
  };

  const libraryActionListenerCb = ({ name, path, args }) => {
    if (name === "removeWorkType") {
      blockValueUpdates = true;
      libraryStore.setLibraryWorkTypes(
        structure.usedWorkTypes.filter((id) => id !== args[0])
      );
    }

    if (!path.length) return;
    path = path.slice(1).split("/");
    const child = path.reduce((node, key) => node[key], structure);

    const hasId = child.id && typeof child.id === "number";

    switch (name) {
      case "changeValue":
        blockValueUpdates = false;
        updatedIds = {};
        break;
      case "reorderChildren":
        // Send only id of reordered element
        if (args[1] && args[0]?.length) {
          const movedElementIndex = args[0].findIndex(
            (arg) => arg.id === args[1]
          );
          if (movedElementIndex >= 0)
            updateLibraryElementParentQuery(
              args[1],
              typeof child.id === "string" ? 0 : child.id,
              movedElementIndex
            );
        }
        break;
      case "pushChild":
        updateLibraryElementParentQuery(
          args[0],
          typeof child.id === "string" ? 0 : child.id,
          args[2] || 0
        ); // @note: there should be no problems, as all library elements are unique (ids, names)
        break;
      case "setContent":
        if (hasId) updateLibraryElementNameQuery(child.id, child.name, args[0]);
        break;
      case "setName":
        if (!hasId) {
          (async () => {
            const level =
              path.slice(4).length / 2 +
              structure.sections[path[1]].startingLevel;
            const id = await addLibraryElementQuery(
              level,
              args[0],
              child.content,
              child.parentOrder,
              level > 1 ? child.parentId : undefined
            );
            if (id) {
              child.setId(id);
              if (child.hasValues) {
                const iterator = child.taskValues[Symbol.iterator]();
                const wTUpdates = [];
                for (const item of iterator) {
                  if (
                    item[1]?.min > 0 ||
                    item[1]?.avg > 0 ||
                    item[1]?.max ||
                    0 > 0
                  )
                    wTUpdates.push(
                      updateLibraryElementHoursQuery(
                        userStore.data.id,
                        id,
                        item[0],
                        item[1]?.min || 0,
                        item[1]?.avg || 0,
                        item[1]?.max || 0
                      )
                    );
                }
                Promise.all(wTUpdates);
              }
            }
          })();
        } else updateLibraryElementNameQuery(child.id, args[0], child.content);
        break;
      case "removeSelf":
        if (hasId) removeLibraryElementQuery(child.id);
        break;
      case "addWorkType":
        libraryStore.setLibraryWorkTypes(structure.usedBreakdownWorkTypes);
        break;
      case "setBreakdownVisibility":
        libraryStore.setLibraryWorkTypes(
          child.inBreakdown
            ? structure.usedBreakdownWorkTypes.filter((wT) => wT !== child.id)
            : [...structure.usedBreakdownWorkTypes, child.id]
        );
        // work type is added to structure first; this is a listener setup on section
        break;
      case "changeWorkType":
        blockValueUpdates = true;
        changeLibraryWorkTypeQuery(args[0], args[1]);
        libraryStore.setLibraryWorkTypes([
          ...structure.usedWorkTypes.filter((id) => id !== args[0]),
          args[1],
        ]);
        break;
      case "reorderWorkTypes":
        libraryStore.setLibraryWorkTypes(args[0].map((arg) => arg.id));
        break;
    }
  };

  const actionListener = useMemo(
    () =>
      structure &&
      (isLibrary || hasEditorAccess) &&
      onAction(
        structure,
        isLibrary && libraryStore ? libraryActionListenerCb : actionListenerCb,
        !isLibrary
      ),
    [structure]
  );

  const patchListener = useMemo(
    () =>
      structure &&
      !!(isLibrary && libraryStore) &&
      onPatch(structure, patchListenerCb),
    [structure]
  );


  useEffect(() => {
    // disposes listeners on unmount
    return () => {
      actionListener && actionListener();
      patchListener && patchListener();
    };
  }, [structure]);
};

const localActions = [
  "afterCreate",
  "deserialize",
  "@APPLY_SNAPSHOT",
  "removeAutofocus",
  "switchParentBlockChildReorder",
];
