// Material components
import { SvgIcon } from "@material-ui/core";

export default function Users(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path d="M8.667 8.333c1.84 0 3.333 1.493 3.333 3.334V13c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667v-1.333c0-1.105-.896-2-2-2H3.333c-1.104 0-2 .895-2 2V13c0 .368-.298.667-.666.667C.298 13.667 0 13.368 0 13v-1.333c0-1.841 1.492-3.334 3.333-3.334zm4.02.587c.093-.356.457-.57.813-.479 1.47.38 2.499 1.706 2.5 3.226V13c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667v-1.333c-.001-.911-.618-1.707-1.5-1.935-.357-.092-.571-.456-.48-.812zM6 .333c1.84 0 3.333 1.493 3.333 3.334C9.333 5.507 7.841 7 6 7 4.16 7 2.667 5.508 2.667 3.667 2.667 1.826 4.159.333 6 .333zm4.02.588c.092-.356.455-.571.812-.48 1.475.377 2.507 1.706 2.507 3.229 0 1.523-1.032 2.852-2.507 3.23-.357.09-.72-.125-.811-.481-.091-.357.124-.72.48-.811.885-.227 1.504-1.024 1.504-1.938 0-.914-.619-1.71-1.504-1.938-.356-.09-.571-.454-.48-.81zM6 1.667c-1.105 0-2 .895-2 2 0 1.104.895 2 2 2s2-.896 2-2c0-1.105-.895-2-2-2z" />
    </SvgIcon>
  );
}
