import { bool, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStores } from "@hooks";
import Grid from "@material-ui/core/Grid";
import { Button, ProjectActionMenu } from "@components";
import { VideoLabel } from "@material-ui/icons";
import { ArrowRight } from "@assets";
import { editor, pdfEditor, sessions } from "@paths";
import { useFeatureGuardian } from "@hooks";
import { LOCKED_FEATURES } from "@utils";

export const Footer = ({
  uuid,
  isMini,
  isOwner,
  isBusiness,
  name,
  description,
}) => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.RECORDINGS
  );

  const { isDeveloper } = userStore;

  const openEditor = () => {
    navigate(editor(uuid));
  };

  const openPdfEditor = () => {
    navigate(pdfEditor(uuid));
  };

  const openSessionList = async () => {
    const allowed = await checkAccess();
    if (!allowed) return;
    navigate(sessions(uuid));
  };

  return (
    <Grid item container justifyContent="space-between" spacing={2}>
      <Grid item container spacing={1} className="w-max">
        {!isDeveloper && (
          <Grid item>
            <Button
              icon={<VideoLabel />}
              onClick={openSessionList}
              size="large"
              variant="contained"
              color="secondary"
            >
              {t("routes.recordings")}
            </Button>
          </Grid>
        )}
        <Grid item>
          <ProjectActionMenu
            isOwner={isOwner}
            isMini={isMini}
            isBusiness={isBusiness}
            uuid={uuid}
            name={name}
            description={description}
            onlyDangerousActions
            useCreateTemplate
            fullButton
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          size="large"
          icon={<ArrowRight />}
          onClick={isMini ? openPdfEditor : openEditor}
        >
          {t("common.open")}
        </Button>
        <FeatureAlert />
      </Grid>
    </Grid>
  );
};

Footer.propTypes = {
  uuid: string.isRequired,
  isOwner: bool.isRequired,
  isMini: bool.isRequired,
  isBusiness: bool.isRequired,
  description: string,
  name: string,
};
