import { useStores } from "./stores";
import { isNonExpandActionClick } from "@utils";
import { useCallback, useRef } from "react";

export const useTableExpandHandler = (
  expandable,
  isOpened,
  onExpand
) => {
  const { stateStore } = useStores();
  
  const isExpanding = useRef(false);
  
  const handleOpen = useCallback((event) => {
    if (isExpanding.current || !expandable || isNonExpandActionClick(event)) {
      return;
    }
    
    onExpand(!isOpened);
  }, [expandable, isOpened, onExpand]);
  
  const onExit = useCallback((targetCollapse) => {
    isExpanding.current = true
    const container = targetCollapse.closest("div[role='rowgroup']");
    const row = container?.firstChild;
    if(container && row)
      stateStore.setScrollRefPadding(container.clientHeight - row.clientHeight);
  }, []);
  
  const onExited = useCallback(() => {
    isExpanding.current = false;
  }, []);
  
  const onEnter = useCallback(() => {
    isExpanding.current = true;
  }, []);
  
  const onEntered = useCallback((targetCollapse) => {
    const container = targetCollapse.closest("div[role='rowgroup']");
    const row = container?.firstChild;
    if(container && row)
      stateStore.updateScrollRefPadding();
    
    isExpanding.current = false;
  }, []);
  
  return {
    handleOpen,
    onExit,
    onExited,
    onEnter,
    onEntered,
  };
}