import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  demoButton: {
    fontWeight: 600,
    margin: 4,
    color: theme.palette.primary.main,
    "& > span": {
      opacity: "1 !important",
    },
  },
  separator: {
    width: 2,
    marginLeft: 8,
    height: 24,
    opacity: 0.5,
    backgroundColor: theme.props.border,
  },
}));

export default useStyle;
