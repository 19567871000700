import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    overflow: "auto"
  },
  scrollbar: {
    flex: "1 1 auto",
    minHeight: 0,
    height: "auto !important",
    maxHeight: 600,
    marginTop: 16,
    width: "100%",
    "& .simplebar-scrollbar:before": {
      backgroundColor: "#aaa",
    },
  },
}));

export default useStyle;
