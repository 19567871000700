import { useState } from "react";
import { bool, string, object, arrayOf } from "prop-types";
import { useActiveProjectStore, useS3Uploader, useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { Alert, ClearButton, PdfUploadProgress } from "@components";
import { CircularProgress } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import useStyle from "./AttachmentsEditor.style";
import { checkPrivilegesQuery } from "@query";
import { LOCKED_FEATURES } from "@utils";

export const AttachmentsEditor = ({ uuid, attachments, multiple }) => {
  const { t } = useTranslation();
  const { stateStore, userStore } = useStores();
  const activeProjects = useActiveProjectStore();
  const classes = useStyle();

  const [isFileLoading, setFileLoading] = useState(false);
  const [alertText, setAlertText] = useState();

  const { userId } = userStore;
  const { uploadAttachment } = useS3Uploader();

  const fileGetter = async (event) => {
    if (
      !isFileLoading &&
      (event.target.files || event.dataTransfer.files.length)
    ) {
      try {
        setFileLoading(true);
        const res = await checkPrivilegesQuery(LOCKED_FEATURES.STORAGE.serverCode);
        if (!res.isAllowed) {
          setAlertText(res.message || "Not allowed");
          return;
        }

        const fileList = event.dataTransfer
          ? event.dataTransfer.files
          : event.target.files;
        let index = 0;

        stateStore.resetFilesUploadProgress(fileList.length);
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList.item(i);
          if (!attachments.find((f) => f.name === file.name)) {
            const fileRes = await uploadAttachment(uuid, file, index);
            activeProjects.editLocalProjectData(uuid, "attachmentAdd", {
              ...fileRes,
              userId,
            });
            index++;
          }
        }
      } catch (e) {
        setAlertText(e.response.data.error);
      }
      stateStore.resetFilesUploadProgress();
      setFileLoading(false);
    }
    return [];
  };

  const { getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple,
    getFilesFromEvent: fileGetter,
  });

  return (
    <>
      <ClearButton
        size="small"
        icon={
          isFileLoading ? (
            <CircularProgress
              aria-label="progress indicator"
              className={classes.progress}
            />
          ) : (
            <Add />
          )
        }
        onClick={open}
      >
        {t("forms.upload_files")}
      </ClearButton>
      <PdfUploadProgress />
      <input
        {...getInputProps()}
        tabIndex="0"
        aria-label="Upload project attachment"
      />
      <Alert
        title={alertText}
        isOpen={!!alertText}
        onAccept={() => setAlertText("")}
        acceptText={t("common.close")}
      />
    </>
  );
};

AttachmentsEditor.propTypes = {
  uuid: string.isRequired,
  attachments: arrayOf(object).isRequired,
  multiple: bool,
};
