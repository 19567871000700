import { useState } from "react";
import { useStores } from "@hooks";
import { sendFeedbackQuery } from "@query";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Dialog, Button, TextField } from "@components";
import { CircularProgress } from "@material-ui/core";
import useStyle from "./Feedback.style";

export const Feedback = observer(() => {
  const { stateStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyle();

  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);

  const { openFeedback } = stateStore;

  const sendFeedback = async () => {
    setFeedbackLoading(true);
    await sendFeedbackQuery(feedbackText);
    setFeedbackLoading(false);
    stateStore.setOpenFeedback(false);
  };

  const closeFeedbackModal = () => stateStore.setOpenFeedback(false);

  const actions = (
    <>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={closeFeedbackModal}
      >
        {t("common.cancel")}
      </Button>
      <Button
        variant="contained"
        icon={
          feedbackLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : null
        }
        onClick={sendFeedback}
        disabled={!feedbackText.length}
        className={classes.button}
      >
        {t("common.send")}
      </Button>
    </>
  );

  return (
    <Dialog
      open={openFeedback}
      className={classes.root}
      title={t("forms.feedback.title")}
      onClose={closeFeedbackModal}
      actions={actions}
    >
      <TextField
        id="feedback"
        value={feedbackText}
        multiline
        className={classes.textarea}
        onChange={(e) => setFeedbackText(e.target.value)}
      />
    </Dialog>
  );
});
