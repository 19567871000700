import { makeStyles } from "@material-ui/core/styles";
import { CELL_HEIGHTS } from "@utils";

const useStyle = makeStyles(() => ({
  
  labelContainer: {
    marginLeft: 32,
    height: CELL_HEIGHTS.XXLARGE * 4 + 8,
  },
  labelCell: {
    position: "relative",
    top: -(32-8),
    minHeight: CELL_HEIGHTS.XXLARGE,
    height: CELL_HEIGHTS.XXLARGE,
  }
}));

export default useStyle;