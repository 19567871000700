import { fetchData } from "@client";

export async function getPaymentHistoryQuery(id = null) {
  try {
    return await fetchData(
      `/order?limit=8${id ? "&starting_after=" + id : ""}`
    );
  } catch (e) {
    return null;
  }
}
