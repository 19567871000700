import { useEffect, useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTableOverflowChecker, useStructureStore, getUserVisibleWorkTypes } from "@hooks";
import { calculateMaxBreakdownTitleCellWidth } from "@utils";
import ResizeObserver from "react-resize-observer";
import { Grid, TableContainer } from "@material-ui/core";
import { ScrollButtonContainer, SectionSummaryRow } from "@components";
import { TableHeader } from "../TableHeader/TableHeader";
import { SectionRow } from "../../../rows/SectionRow/SectionRow";
import { BreakdownRowGroup } from "../BreakdownRowGroup/BreakdownRowGroup";
import { BreakdownRowGroupList } from "../BreakdownRowGroupList/BreakdownRowGroupList";
import useStyle from "./Section.style";
import useEditorStyle from "../../../commonStyles.style";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

export const Section = observer(({
  section,
  index,
  allowEdition,
  isProposal,
  isLibrary,
  isSellerOrClient,
  isEditorOrClient,
  useClientActions,
  ...otherProps
}) => {
  const structure = useStructureStore();
  const classes = useStyle();
  const commonClasses = useEditorStyle();
  
  const { estimateValueLevel, settings, usesTwoValues } = structure;
  const {
    useMinMax,
    titleCellWidth,
    descriptionCellWidth,
    showPrices,
    showBreakdownColumns,
    descriptionMode,
  } = settings;
  
  const {
    taskWorkTypes,
    sectionModulesHaveChildren,
    childrenWithContent
  } = section;

  const visibleWorkTypes = getUserVisibleWorkTypes(taskWorkTypes, isProposal);
  
  const inTableSection = useMemo(
    () => structure.settings.sectionFormat === 1,
    [structure.settings.sectionFormat]
  );

  const showSectionSummary = useMemo(
    () => structure.settings.sectionFormat === 2,
    [structure.settings.sectionFormat]
  );

  const tableContainerIdentifier = useMemo(() => `breakdownContainer${index}`, [index]);
  const tableIdentifier = useMemo(() => `tableHeader${index}`, [index]);

  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);
  
  useEffect(() => { onResize() }, [useMinMax, titleCellWidth, descriptionCellWidth]);
  
  const pricesVisible = useMemo(
    () => showBreakdownColumns && showPrices && isSellerOrClient,
    [showPrices, isSellerOrClient, showBreakdownColumns]
  );
  
  const useExpandCell = useMemo(() => (
    !isProposal || sectionModulesHaveChildren
  ), [isProposal, sectionModulesHaveChildren]);
  
  const useDescriptionCell = useMemo(() => (
    !isProposal || Boolean(section.content) || childrenWithContent > 0
  ), [isProposal, childrenWithContent]);
  
  const maxTitleCellWidth = useMemo(() => (
    descriptionMode === 3 && useDescriptionCell
      ? titleCellWidth
      : calculateMaxBreakdownTitleCellWidth(estimateValueLevel === -1 ? 0 : visibleWorkTypes.length, pricesVisible, useClientActions, usesTwoValues)
  ), [descriptionMode, titleCellWidth, visibleWorkTypes, pricesVisible, useClientActions, usesTwoValues, estimateValueLevel])
  
  const maxDescriptionCellWidth = useMemo(() => (
    descriptionMode < 3 || !useDescriptionCell
      ? 0
      : calculateMaxBreakdownTitleCellWidth(estimateValueLevel === -1 ? 0 : visibleWorkTypes.length, pricesVisible, useClientActions, usesTwoValues, titleCellWidth)
  ), [descriptionMode, visibleWorkTypes, pricesVisible, useClientActions, usesTwoValues, titleCellWidth, estimateValueLevel]);
  
  const childProps = {
    isSellerOrClient,
    isEditorOrClient,
    useClientActions,
    allowEdition,
    isProposal,
    isLibrary,
    useExpandCell,
    useDescriptionCell,
    maxTitleCellWidth,
    maxDescriptionCellWidth,
    pricesVisible,
    ...otherProps
  }
  
  return (
    <Grid item container>
      {
        isTableContentOverflowing && !isMobile &&
        <ScrollButtonContainer {...scrollButtonProps} />
      }
      { !inTableSection && (
        <SectionRow
          index={ index }
          section={ section }
          allowEdition={ allowEdition && !isLibrary }
          isLibrary={ isLibrary }
        />
      ) }
      <TableContainer
        ref={ ref }
        id={ tableContainerIdentifier }
        onScroll={ onScroll }
        className={ classnames(
          "pb-3",
          classes.root,
          isTableContentOverflowing && commonClasses.overflows
        ) }
      >
        <Grid container role="rowgroup" direction="column">
          <ResizeObserver onResize={ onResize }/>
          <TableHeader
            { ...childProps }
            section={ section }
            tableIdentifier={ tableIdentifier }
            visibleWorkTypes={ visibleWorkTypes }
          />
          { inTableSection ? (
            <BreakdownRowGroup
              { ...childProps }
              sectionIndex={ index }
              element={ section }
              isLastGroupRow
              tableDepth={ 0 }
              isFirst={ index === 0 }
              visibleWorkTypes={ visibleWorkTypes }
            />
          ) : (
            <BreakdownRowGroupList
              { ...childProps }
              initialDepth={ isLibrary ? section.startingLevel - 1 : 0 }
              element={ section }
              sectionIndex={ index }
              isLastGroupRow
              parentIds={ [ section.id ] }
              visibleWorkTypes={ visibleWorkTypes }
            />
          ) }
          { !inTableSection && (showSectionSummary || allowEdition) && (
            <SectionSummaryRow
              { ...childProps }
              section={ section }
              isFirst={ index === 0 }
              visibleWorkTypes={ visibleWorkTypes }
            />
          ) }
        </Grid>
      </TableContainer>
    </Grid>
  );
});

Section.props = {
  section: object.isRequired,
  index: number.isRequired,
  hasEditorPrivileges: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
  isSellerOrClient: bool,
  blockExpansions: bool,
  showPdfCover: bool,
  displayStatus: bool,
  allowStatusChange: bool,
  allowEdition: bool,
};
