import { bool } from "prop-types";
import { Divider } from "@material-ui/core";
import useStyle from "./ValueDivider.style";

export const ValueDivider = ({ display }) => {
  
  const classes = useStyle()
  
  return display
    ? <Divider className={classes.root} />
    : <></>
}

ValueDivider.propTypes = {
  display: bool
}