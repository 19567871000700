import {
  bool,
  number,
  string,
  object,
  node,
  arrayOf,
  oneOfType,
  oneOf,
} from "prop-types";
import { observer } from "mobx-react";
import { BreakdownRowGroup } from "../BudgetTrackingBreakdownRowGroup/BudgetTrackingBreakdownRowGroup";

export const BreakdownRowGroupList = observer(
  ({
    element,
    allowEdition,
    isLastGroupRow,
    parentIds,
    tableDepth = 0,
    initialDepth = 0,
    ...elementProps
  }) => {
    const { children: elementChildren } = element;

    return (
      <>
        {elementChildren?.map((child, i) => (
          <BreakdownRowGroup
            key={`element${child.id}`}
            element={child}
            allowEdition={allowEdition}
            isLastGroupRow={
              isLastGroupRow &&
              (tableDepth === 0 || i === elementChildren.length - 1)
            }
            parentIds={parentIds}
            tableDepth={tableDepth + 1}
            initialDepth={initialDepth}
            {...elementProps}
          />
        ))}
      </>
    );
  }
);

BreakdownRowGroupList.propTypes = {
  /** TaskModel | SectionModel */
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]),
  initialDepth: oneOf([0, 1, 2, 3]),

  blockExpansions: bool,
  displayStatus: bool,
  allowStatusChange: bool,
  isLastGroupRow: bool,

  parentIds: arrayOf(oneOfType([number, string])),
  parentName: string,
  children: node,

  //common
  allowEdition: bool,
  isSellerOrClient: bool,
  isProposal: bool,
  isLibrary: bool,
  useClientActions: bool,
};
