import { useEffect, useState } from "react";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { HtmlParser, universalDateParser } from "@utils";
import { formatDistance } from "date-fns";
import { dateLocales } from "@locale";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Table, TableRow, TableCell } from "@components";
import { Done } from "@material-ui/icons";
import useStyle from "./NotificationsTable.style";

export const NotificationsTable = observer(() => {
  const { messageStore, stateStore } = useStores();
  const classes = useStyle();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [messages, setMessages] = useState([]);
  const { t } = useTranslation();
  
  const loadMessages = async (resetList = false) => {
    if (resetList || (!isLoading && page < total)) {
      setIsLoading(true);
      const res = await messageStore.getAll(resetList ? 1 : page + 1);
      setMessages(resetList ? res.results : [...messages, ...res.results]);
      setPage(res.paginate.page);
      setTotal(res.paginate.pages);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadMessages(true);
  }, []);

  const readMessage = (id) => async () => {
    await messageStore.readMessage(id);
    // const found = messages.find(x => x.id);
    // found.status = 1;
    // setMessages(messages);
  };

  const now = new Date();

  const { lang } = stateStore;
  const { newMessages } = messageStore;

  useEffect(() => {
    loadMessages(true);
  }, [newMessages]);

  return (
    <Table
      id="notificationsTable"
      headerOffset={96}
      loadNextPage={loadMessages}
      headers={[
        { label: t("views.notifications.message"), width: 420, flexible: true },
        { label: t("views.notifications.date"), width: 240, flexible: true },
        { width: 34 },
      ]}
    >
      {messages.map((m) => {
        const { id, content, createdAt, status } = m;

        const parsedDate = new Date(
          universalDateParser(createdAt).getTime() -
            new Date().getTimezoneOffset() * 1000 * 60
        );

        return (
          <TableRow
            key={m.id}
            className={m.status === 0 ? classes.selected : ""}
          >
            <TableCell justifyContent="flex-start" width={420} flexible>
              <p>{HtmlParser(content)}</p>
            </TableCell>
            <TableCell width={240} flexible>
              {formatDistance(parsedDate, now, {
                locale: dateLocales[lang],
                addSuffix: true,
              })}
            </TableCell>
            <TableCell width={34}>
              {status === 0 ? (
                <IconButton
                  color="primary"
                  onClick={readMessage(id)}
                  className={classes.check}
                >
                  <Done />
                </IconButton>
              ) : (
                <Done color="primary" className={classes.check} />
              )}
            </TableCell>
          </TableRow>
        );
      })}
      {isLoading && (
        <CircularProgress
          aria-label="progress indicator"
          className={classes.spinner}
        />
      )}
    </Table>
  );
});
