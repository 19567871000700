 import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    "-webkit-transition": "opacity 0.4s ease",
    "-moz-transition": "opacity 0.4s ease",
    transition: "opacity 0.4s ease",
  }
}));

export default useStyle;