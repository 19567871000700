import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  handle: {
    // width: 16,
    // background: theme.palette.secondary.main,
    cursor: "col-resize",
    // right: -11,
    fontSize: 16,
    
    right: -4,
    width: 2,
    zIndex: 9999,
    background: theme.palette.text.primary+"9A",
    top: "calc(50% - 12px)",
    height: 24,
  },
  line: {
    position: "absolute",
    height: "100vh",
    zIndex: 9999,
    width: 2,
    background: theme.props.border,
    left: 7,
    top: 17,
    pointerEvents: "none",
    display: "none"
  }
}));

export default useStyle;