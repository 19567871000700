import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  text: {
    fontSize: 14,
  },
  opaque: {
    opacity: 0.5,
  },
  headerContainer: {
    maxWidth: "calc(100% - 32px)",
  },
}));

export default useStyle;
