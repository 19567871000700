import { useEffect, useState } from "react";
import { string, func, instanceOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { Grid, Link } from "@material-ui/core";
import { Timer } from "@assets";
import { format, add, sub, isAfter } from "date-fns";
import useStyle from "./Deadline.style";
import classnames from "classnames";
import { DatePicker } from "../index";

export const Deadline = ({ status, date, onEdit, className }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [datepickerAnchor, setDatePickerAnchor] = useState(null);
  const [datepickerVisible, showDatepicker] = useState(null);
  const [localDate, setLocalDate] = useState(date);

  useEffect(() => {
    setLocalDate(date);
  }, [date]);

  const allowEdition = !!onEdit;

  const isEndangered =
    status === "done" || !date
      ? false
      : isAfter(new Date(), add(date, { days: 1 }))
      ? 2
      : isAfter(new Date(), sub(date, { days: 1 }))
      ? 1
      : false;
  
  const handleClick = () => {
    if(allowEdition)
      showDatepicker(!datepickerVisible)
  }

  const handleDateCancel = () => {
    setLocalDate(date);
    showDatepicker(false);
  };

  const handleDateChange = (newDate) => {
    setLocalDate(newDate);
    onEdit && onEdit(newDate);
    showDatepicker(false);
  };

  return (
    <>
      <Link
        ref={setDatePickerAnchor}
        underline={allowEdition ? "hover" : "none"}
        onClick={handleClick}
        className={classnames(
          {
            [classes.deadline]: true,
            [classes.hasDeadline]: !!date,
            [classes.hasWarning]: isEndangered === 1,
            [classes.hasError]: isEndangered === 2,
          },
          className
        )}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Timer className="text-sm mr-1" />
          {date ? format(date, "dd MMM yyyy") : t("views.active.no_deadline")}
        </Grid>
      </Link>
      {allowEdition && (
        <DatePicker
          isOpen={datepickerVisible}
          anchorEl={datepickerAnchor}
          arrowStyle={{ display: "none" }}
          cancelHandler={handleDateCancel}
          value={localDate}
          changeHandler={handleDateChange}
        />
      )}
    </>
  );
};

Deadline.propTypes = {
  date: instanceOf(Date),
  status: string,
  onEdit: func,
  className: string,
};
