import { matchStringToPrice } from "project-structure";

export const parseCellType = (val) => {
  if (!val || val === "" || (typeof val === "string" && val.match(/^\s*$/g)))
    return "empty"; // empty string or contains only whitespace characters
  if (
    typeof val === "string" &&
    matchStringToPrice.find((matcher) => matcher(val))
  )
    return "price"; //contains currency start or end symbols
  if (!isNaN(parseFloat(val))) return "number"; // plain int or float number
  return typeof val; //
};
