import { ValueCell } from "@components";
import { useStructureStore } from "@hooks";
import { observer } from "mobx-react";

export const CompanyCostCell = observer(() => {
  const structure = useStructureStore();
  const { settings, usesTwoValues } = structure;
  const { currencyObj, companyFixedBreakdownCost } = settings;
  
  const handleCompanyFixedBreakdownCostChange = (v) =>
    settings.setCompanyFixedBreakdownCost(v);
  
  return <ValueCell
    wider
    displayContent
    widest={usesTwoValues}
    editable
    value={companyFixedBreakdownCost}
    onValueChange={handleCompanyFixedBreakdownCostChange}
    symbolStart={currencyObj.symbolStart}
    symbolEnd={currencyObj.symbolEnd}
    max={10000000}
  />
})