import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function getCurrencyExchangeRatesQuery(
  ...currencyCodes
) {
  const res = await fetchData("/api/rates/batch", APIMethod.GET, {
    noAuth: true,
    baseURL: ACCESS_DOMAINS.editor,
    mapRequestKeysToSnakeCase: false,
    params: {
      codes: currencyCodes.join(",")
    },
  });

  return res.results;
}
