import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: 16,
    "& > div:not(:last-child)": {
      marginRight: 8,
    },
  },
  block: {
    width: 32,
    height: 24,
    border: "1px solid " + theme.palette.text.primary + "26",
    borderRadius: 5,
    boxSizing: "border-box",
    margin: 4,
    cursor: "pointer",
  },
}));

export default useStyle;
