import { useState, useEffect } from "react";
import { bool, number, string, object, func, oneOf, arrayOf, node } from "prop-types";
import {
  CircularProgress,
  IconButton,
  Grid,
} from "@material-ui/core";
import { ClearButton, PopMenu, TextField, Dialog } from "@components";
import { Close } from "@material-ui/icons";
import { parseRegex } from "@utils";
import useStyle from "./AsyncPicker.style";
import classnames from "classnames";
import { AsyncPickerItem } from "@components/AsyncPicker/components/AsyncPickerItem/AsyncPickerItem";

export const AsyncPicker = ({
  type,
  isOpen,
  anchorEl,
  onClickAway,
  title,
  fetchFunc,
  idKey,
  labelKey,
  selected,
  onNewItemAdd,
  disableOptions,
  newItemText,
  inputLabel,
  inputName,
  removeOptions,
  noItemsText,
  limit,
  children,
  options: defaultOptions,
  width=360,
  maxLength,
  ...itemProps
}) => {
  const classes = useStyle();

  const [options, setOptions] = useState(defaultOptions);
  const [value, setValue] = useState("");
  const [isLoading, setLoading] = useState(!defaultOptions.length);

  useEffect(() => {
    if (defaultOptions) {
      setOptions(defaultOptions);
      setLoading(false);
    }
  }, [defaultOptions]);

  const onChange = (e) => {
    let v = parseRegex(e.target.value);
    if (maxLength) v = v.slice(0, maxLength);
    setValue(v);
  };

  const handleNewItem = () => {
    onNewItemAdd(value);
    setValue("");
  };

  useEffect(() => {
    if (isOpen && !options.length && fetchFunc) {
      setLoading(true);
      (async () => {
        const response = await fetchFunc();

        if (response) setOptions(response);
      })();
    }
    setLoading(false);
  }, [isOpen]);

  const filtered = options
    .filter((o) => (
      !removeOptions?.includes(o.id) &&
      (value ? o[labelKey].match(new RegExp(parseRegex(value), "i")) : true)
    ))
    .sort((a, b) => (a[labelKey] > b[labelKey] ? 1 : -1));

  const content = (
    <Grid container direction="column">
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        className="mb-3 px-6"
      >
        <h3>{title}</h3>
        <IconButton
          color="primary"
          aria-label="close modal"
          onClick={onClickAway}
        >
          <Close />
        </IconButton>
      </Grid>
      <TextField
        id={inputName}
        onChange={onChange}
        value={value}
        className="px-6"
        placeholder={inputLabel}
        InputProps={{
          endAdornment: (
            isLoading
              ? <CircularProgress color="primary" size={20} />
              : (
                value &&
                <IconButton
                  aria-label="clear input"
                  color="primary"
                  onClick={() => setValue("")}
                >
                  <Close />
                </IconButton>
              )
          ),
        }}
      />
      {children}
      <div
        role="listbox"
        className={classnames(classes.list, "w-full overflow-auto pb-6")}
      >
        {options.length > 0 ? (
          filtered.length ? (
            filtered.map((o) => (
              <AsyncPickerItem
                key={o[idKey]}
                idKey={idKey}
                labelKey={labelKey}
                inputValue={value}
                selectedItems={selected}
                maxSelected={limit && selected.length >= limit }
                data={o}
                disabled={disableOptions && disableOptions(o)}
                {...itemProps}
              />
            ))
          ) : (
            newItemText && value
            ? <ClearButton
              onClick={handleNewItem}
              name="add new item"
              className={classes.addButton}
            >
              {newItemText(value)}
            </ClearButton>
            : <p className="px-6">{noItemsText}</p>
          )
        ) : (
          <p className="px-6">{noItemsText}</p>
        )}
      </div>
    </Grid>
  );

  return type === "modal" ? (
    <Dialog
      open={isOpen}
      // rootClass={classes.dialog}
      containerClass="px-0 pb-0"
      onClose={onClickAway}
      width={width}
    >
      {content}
    </Dialog>
  ) : (
    <PopMenu
      show={isOpen}
      anchor={anchorEl}
      onClickAway={onClickAway}
      placement="bottom"
      className="pt-4 px-0 pb-0"
      style={{ width }}
    >
      {content}
    </PopMenu>
  );
};

AsyncPicker.propTypes = {
  inputName: string.isRequired,
  isOpen: bool,
  anchorEl: object,
  onClickAway: func.isRequired,
  fetchFunc: func,
  selected: arrayOf(object).isRequired,
  highlightedTextClass: string,
  title: string.isRequired,
  inputLabel: string.isRequired,
  ContentItem: func.isRequired,
  idKey: string,
  labelKey: string,
  newItemText: func,
  onNewItemAdd: func,
  noItemsText: string,
  onAdd: func,
  onRemove: func,
  type: oneOf(["popup", "modal"]),
  removeOptions: arrayOf(number),
  disableOptions: func,
  limit: number,
  children: node,
  options: arrayOf(object),
  maxLength: number,
  width: number,
};

AsyncPicker.defaultProps = {
  idKey: "id",
  labelKey: "name",
  type: "popup",
  options: [],
};
