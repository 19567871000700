import { useMemo } from "react";
import { useCheckProjectAccess, useProjectEditorStore } from "@hooks";
import { WT_STATUS } from "@utils";

export const useWorkTypePermitChecker = (workTypeId) => {
  const store = useProjectEditorStore();
  if (!store) return {};

  const { workTypesWithStatus, projectUsers } = store;

  const { userId, isOwner, isObserver, userGrantedPermits, hasUserGrantedPermits } =
    useCheckProjectAccess(projectUsers, workTypesWithStatus);

  const editionGrant = useMemo(
    () =>
      isOwner ||
      !isObserver ||
      (hasUserGrantedPermits
        ? !!userGrantedPermits.find((permit) => permit.id === workTypeId)
        : !workTypesWithStatus.find((permit) => permit.id === workTypeId)
            ?.estimatorUuid),
    [isOwner, userGrantedPermits, workTypesWithStatus]
  );

  const hasPermit = useMemo(
    () =>
      !!workTypesWithStatus.find(
        (permit) => permit.id === workTypeId && permit.estimatorUuid
      ),
    [workTypesWithStatus]
  );

  const isPending = useMemo(
    () =>
      workTypesWithStatus.find(
        (permit) => permit.id === workTypeId && permit.estimatorUuid
      )?.status === WT_STATUS.PENDING,
    [workTypesWithStatus]
  );

  const userSeesOnlyPermittedWorkTypes = useMemo(() => (
    hasPermit && store.projectUserWorkTypeVisibility[userId] === 0
  ), [hasPermit, store.projectUserWorkTypeVisibility]);

  return {
    isPending,
    hasPermit,
    editionGrant,
    hasUserGrantedPermits,
    userSeesOnlyPermittedWorkTypes,
  };
};
