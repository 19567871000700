import {withStyles} from "@material-ui/core/styles";
import {MaterialDesignContent} from "notistack";

export const StyledSnackbarComponent = MaterialDesignContent;
// withStyles((theme) => ({
//   "&.notistack-MuiContent-success": {
//     backgroundColor: theme.palette.success.main,
//   },
//   "&.notistack-MuiContent-error": {
//     backgroundColor: theme.palette.error.main,
//   },
//   "&.notistack-MuiContent-warning": {
//     backgroundColor: theme.palette.warning.main,
//   },
//   "&.notistack-MuiContent-info": {
//     backgroundColor: theme.palette.primary.main,
//   },
// }))(MaterialDesignContent);