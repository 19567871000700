import { createContext } from "react";
import { bool, node, object } from "prop-types";
import { action, makeObservable, observable } from "mobx";
import { notifyAboutClientCommentQuery } from "@query";

export const EditorCommentsStoreContext = createContext(null);
export const EditorCommentsStoreProvider = ({
  children,
  useComments,
  commenterData,
  isProposal
}) => (
  <EditorCommentsStoreContext.Provider
    value={new EditorCommentsStore(useComments, commenterData, isProposal)}
  >
    {children}
  </EditorCommentsStoreContext.Provider>
);

EditorCommentsStoreProvider.propTypes = {
  children: node.isRequired,
  useComments: bool,
  userData: object
};

export class EditorCommentsStore {
  constructor(useComments, commenterData, isProposal=false) {
    makeObservable(this);
    
    this.isProposal = isProposal;
    
    if(useComments)
      this.commentsVisible = useComments;
    
    if(commenterData)
      this.commenterData = commenterData;
  }
  // =============== PROPERTIES ===============
  
  isProposal = false;
  @observable useInternalComments = false;
  @observable allowedInternalComments = false;
  @observable useExternalComments = true;
  @observable comments = [];
  @observable fetchedVersions = [];
  @observable addedCommentThisSession = false;
  @observable commentsVisible = false;
  @observable commenterData = null;
  @observable addedCommentPath = null;
  @observable managedCommentPath = null;

  // =============== GETTERS ===============
  
  hasAnyPathComments(version, path) {
    return (
      (
        this.useExternalComments && this.comments.find((comment) =>
          Number(comment.version) === version
          && comment.place === path
          && comment.internal !== 1
        )
      ) || (
        this.useInternalComments && this.comments.find((comment) =>
          Number(comment.version) === version
          && comment.place === path
          && comment.internal === 1
        )
      )
    );
  }
  
  getPathComments(version, path, internal=false) {
    return this.comments.find((comment) =>
      Number(comment.version) === version
      && comment.place === path
      && (internal ? comment.internal === 1 : comment.internal !== 1)
    );
  }
  
  getChildComments(version, path) {
    return this.comments.filter((comment) =>
      Number(comment.version) === version
      && comment.place.indexOf(path) === 0
      && comment.place !== path
    )?.length;
  }
  
  // =============== SETTERS ===============
  
  @action setCommenterData(commenterData) {
    this.commenterData = commenterData;
  }
  @action setCommentsVisible(commentsVisible) {
    this.commentsVisible = commentsVisible;
  }
  
  @action setComments(comments, versionKey) {
    this.comments = [
      ...this.comments.filter(c => Number(c.version) !== versionKey),
      ...comments
    ]
    this.fetchedVersions.push(versionKey);
  }
  
  @action setUseInternalComments(useInternalComments, allowedInternalComments) {
    this.useInternalComments = useInternalComments;
    this.allowedInternalComments = allowedInternalComments;
  }
  @action setAllowInternalComments(allowedInternalComments) {
    this.allowedInternalComments = allowedInternalComments;
  }
  
  @action setUseExternalComments(useExternalComments) {
    this.useExternalComments = useExternalComments;
  }
  
  // =============== ACTIONS ===============
  
  @action addOrUpdateComment(commentObject) {
    const l = [...this.comments];
    const parentList = commentObject.parentId
      ? l.find((comment) => comment.id === commentObject.parentId)
      ?.children || l
      : l;
    const target = parentList
      .find((child) => child.id === commentObject.id);
    
    if (target) {
      target.body = commentObject.body;
      this.comments = l;
      return;
    }
    
    parentList.push(commentObject);
    this.comments = l;
  }
  
  @action removeComment(commentId, parentId) {
    if (!parentId) {
      this.comments = this.comments.filter(
        (comment) => comment.id !== commentId
      );
      return;
    }
    const l = [...this.comments];
    const parent = l.find((comment) => comment.id === parentId);
    if (parent)
      parent.children = parent.children?.filter(
        (comment) => comment.id !== commentId
      );
    this.comments = l;
  }
  
  @action removeCommentByPath(version, path) {
    this.comments = this.comments.filter((comment) => !(
      Number(comment.version) === version
        && comment.place.match(`^${path}`)
      )
    );
  }
  
  @action updateCommentPath(version, oldPath, path) {
    const l = [...this.comments];
    l.forEach((comment) => {
      if( Number(comment.version) === version && comment.place.match(`^${oldPath}`) )
        comment.place = comment.place.replace(oldPath, path)
    });
    this.comments = l;
  }
  
  @action async notifyUser(shareLink) {
    this.addedCommentThisSession = true;
    await notifyAboutClientCommentQuery(shareLink);
  }
}
