import { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router-dom";
import {
  useFonts,
  useEditorCommentStore,
  useEditorWebsocket,
  usePresetStore,
  useProjectEditorStore,
  useSharedProjectKey,
} from "@hooks";
import { StructureStoreProvider } from "@stores";
import { fetchProposalCommentsQuery } from "@query";
import { CONTENT_WIDTH } from "@utils";
import { isMobile } from "react-device-detect";
import ResizeObserver from "react-resize-observer";
import { SessionRecorder } from "@tools";
import { PolicyDialog } from "@dialogs";
import {
  AppContainer,
  EditorRoot,
  PdfUploadProgress,
  ProposalAppBar,
} from "@components";
import { usePresetStyles } from "@styles";

export const ProposalView = observer(() => {
  const shareKey = useSharedProjectKey();
  const { projectHash } = useParams();
  const editorStore = useProjectEditorStore();
  const presetStore = usePresetStore();
  const commentStore = useEditorCommentStore();
  const socket = useEditorWebsocket();
  const { search } = useLocation();
  const { loadFonts, unloadFonts } = useFonts();

  const { currentProjectStructure, pdfDocument } = editorStore;
  const { selectedPresetFont } = presetStore;

  const clientEmail = new URLSearchParams(search).get("c") || null;

  useEffect(() => {
    loadComments();
    
    if(selectedPresetFont)
      loadFonts([selectedPresetFont])
    
    return () => {
      unloadFonts();
    }
  }, [editorStore.currentVersionKey]);

  const sessionRecorder = useMemo(
    () =>
      new SessionRecorder({
        shareKey,
        clientEmail,
        type: "proposal", //"pdf",
        useCompression: true,
      }),
    []
  );

  const loadComments = async () => {
    if (
      !commentStore?.commentsVisible ||
      commentStore.fetchedVersions.includes(editorStore.currentVersionKey)
    )
      return;
    const c = await fetchProposalCommentsQuery(
      projectHash,
      editorStore.currentVersionKey
    );
    commentStore.setComments(c, editorStore.currentVersionKey);
    socket?.init(false, true);
  };

  const handleViewPortChange = () => {
    document.querySelector(
      'meta[name="viewport"]'
    ).content = `width=device-width, initial-scale=${Math.min(
      window.screen.width / CONTENT_WIDTH,
      1
    )}`;
  };

  const initSession = () => {
    sessionRecorder?.init();
  };
  
  usePresetStyles(presetStore?.selectedPresetData, presetStore?.selectedPresetFont);

  return (
    <StructureStoreProvider value={currentProjectStructure}>
      {isMobile && <ResizeObserver onResize={handleViewPortChange} />}
      <ProposalAppBar proposalShareKey={shareKey} />
      <AppContainer>
        <EditorRoot
          readOnly
          isProposal
          useClientActions
          useRecorderFriendlyPdf //={!disableRecorder}
          showPdfCover={!!pdfDocument}
          projectStructure={currentProjectStructure}
        />
        <PolicyDialog onDisclaimerClose={initSession} />
        <PdfUploadProgress />
      </AppContainer>
    </StructureStoreProvider>
  );
});
