import { useState, useMemo } from "react";
import { useEditorWebsocket, useStructureStore } from "@hooks";

export const useSection = (section, allowEdition) => {
  const structure = useStructureStore();
  const socket = useEditorWebsocket();

  const [alertVisible, showAlert] = useState(false);

  const removable = useMemo(
    () => structure.hasMultipleSections && allowEdition,
    [structure.hasMultipleSections, allowEdition]
  );

  const toggleAlert = () => {
    showAlert((state) => !state);
  };

  const handleNameChange = (value) => {
    section.setName(value);
  };

  const handleSectionRemoval = (moveModules = false) => async () => {
    const path = section.treePath.join("/");
    const newSection = structure.removeChild(section.id, moveModules);
    if(newSection)
      socket?.requestCommentMove(path, newSection.treePath.join("/"));
    else
      socket?.requestCommentPathRemove(path);
    showAlert(false);
  };

  const handleDescriptionChange = (v) => {
    section.setContent(v, false);
  };

  return {
    removable,
    alertVisible,
    toggleAlert,
    handleSectionRemoval,
    handleNameChange,
    handleDescriptionChange,
  };
};
