import { APIMethod, fetchData } from "@client";

export async function removeProjectAttachmentQuery(
  uuid,
  filename,
  attachmentUuid
) {
  return await fetchData("/project/attachment", APIMethod.DELETE, {
    data: { uuid, filename, attachmentUuid },
  });
}
