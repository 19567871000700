import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: (isMobile) => (isMobile ? 16 : 24),
    margin: "0 -12px",
    padding: "0 12px",
  },
  paper: {
    borderRadius: 10,
    height: "max-content",
    border: `1px solid ${theme.props.border}`,
    backgroundColor: theme.props.backgroundSecondary,
    marginBottom: 24,
  },
}));

export default useStyle;
