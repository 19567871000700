import { number } from "prop-types";
import { Breakdown, Import, Mappings, Preview, Sheets } from "./steps";

export const StepsFactory = ({ activeStep }) => {
  switch (activeStep) {
    case 0:
      return <Import />;
    case 1:
      return <Sheets />;
    case 2:
      return <Mappings />;
    case 3:
      return <Breakdown />;
    case 4:
      return <Preview />;
  }
};

StepsFactory.propTypes = {
  activeStep: number,
};
