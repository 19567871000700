import { useMemo, useState } from "react";
import { useCheckProjectAccess, useProjectEditorStore } from "@hooks";
import { useTranslation } from "react-i18next";
import defaultPreset from "@styles/presets/default";
import ReactDOMServer from "react-dom/server";
import { ExportStyles, ExportView } from "./ExportView";
import { usePresetStore, useStores } from "@hooks";
import { downloadFileFromUrlQuery, generateExportPdfQuery } from "@query";
import { DEFAULT_FONT_NAME, downloadFile, multiDocExport } from "@utils";
import { getProposalExportFileName } from "@utils/getProposalExportFileName";
import { useParams } from "react-router-dom";

export const usePdfExporter = (shareKey, attachCover = false, simpleExport=false) => {
  const editorStore = useProjectEditorStore();
  const presetStore = usePresetStore();
  const { stateStore } = useStores();
  const { projectUuid, project, company } = useParams();
  
  const { t } = useTranslation();
  
  const {
    companyLogo,
    pdfDocument,
    projectUsers,
  } = editorStore;
  
  const [isLoading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState("");
  
  const { hasSellerPrivileges } = useCheckProjectAccess(projectUsers);
  
  const closeAlert = () => setAlertText("");
  
  const fileName = useMemo(() => (
    projectUuid || (
      company && project ? `${company}-${project}` : "proposal"
    )
  ), [projectUuid, project, company]);
  
  const onUploadProgress = (progress) => {
    stateStore.setFilesUploadProgress(
      0,
      Math.min((progress.loaded / progress.total) * 100, 100)
    );
  };
  
  const onDownloadProgress = (progress) => {
    stateStore.setFilesUploadProgress(
      1,
      Math.min((progress.loaded / progress.total) * 100, 100)
    );
  };
  
  const handleSimpleDownload = async () => {
    if(!pdfDocument) return;
    const file = await multiDocExport(
      [pdfDocument.fileData],
      editorStore
        .usedPdfPages
        .sort((a, b) => (a[1] > b[1] ? 1 : -1))
        .map((p) => ({ doc: 0, page: p[0] }))
    );
    await downloadFile(getProposalExportFileName(fileName, "pdf"), file)
  }
  
  const exportProposal = async () => {
    setLoading(true);
    
    if(simpleExport) {
      handleSimpleDownload();
      setLoading(false);
      return;
    }
    
    const { projectName, proposalStep } = editorStore;
    const { selectedPresetData } = presetStore;
    const usePreset = !!(shareKey || proposalStep);
    const presetData = usePreset ? selectedPresetData : { ...defaultPreset };
    let logo = presetData?.logo || companyLogo;
    
    const html = ReactDOMServer.renderToStaticMarkup(
      <ExportView
        editorStore={editorStore}
        logo={logo}
        usePreset={usePreset}
        translate={t}
        hasSellerPrivileges={hasSellerPrivileges}
      />
    );
    
    const exportStr =
      `<!doctype html><head><meta charSet="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><meta http-equiv="X-UA-Compatible" content="ie=edge"><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=${
        presetData?.fontFamily?.replace(" ", "+") || DEFAULT_FONT_NAME
      }:wght@300;400;600;700&display=swap" rel="stylesheet"><style>${ExportStyles(
        presetData
      )}</style><title>${projectName}</title></head><body>${html}</body></html>`
        .replace(/(\n|\r|\t| {5}| {3})/g, "")
        .replace(/; /g, ";")
        .replace(/{ */g, "{")
        .replace(/ {/g, "{")
        .replace(/: /g, ":")
        .replace(/, /g, ",");
    
    try {
      stateStore.resetFilesUploadProgress(2);
      
      let url = await generateExportPdfQuery(
        onUploadProgress,
        shareKey || projectUuid,
        exportStr,
        `https://fonts.googleapis.com/css2?family=${
          presetData?.fontFamily?.replace(" ", "+") || DEFAULT_FONT_NAME
        }:wght@300;400;600;700&display=swap`,
        attachCover && pdfDocument
          ? editorStore.proposalTablePosition
          : 0,
        !!shareKey
      );
      
      let file = await downloadFileFromUrlQuery(url, onDownloadProgress);
      
      await downloadFile(getProposalExportFileName(fileName, "pdf"), file);
      
    } catch (e) {
      setAlertText(e.response?.data?.message || "Not allowed");
    }
    
    setLoading(false);
    stateStore.resetFilesUploadProgress();
  };
  
  return {
    isLoading,
    alertText,
    exportProposal,
    closeAlert,
  };
};
