import { node } from "prop-types";
import { RootStore, AppStateStore } from "./App";
import { createContext } from "react";

export const AppStoreContext = createContext(null);
export const AppStateStoreContext = createContext(null);

export const AppStoreProvider = (props) => {
  const { children } = props;

  return (
    <AppStoreContext.Provider value={new RootStore()}>
      {children}
    </AppStoreContext.Provider>
  );
};

export const AppStateStoreProvider = (props) => {
  const { children } = props;

  return (
    <AppStateStoreContext.Provider value={new AppStateStore()}>
      {children}
    </AppStateStoreContext.Provider>
  );
};

AppStoreProvider.propTypes = {
  children: node.isRequired,
};

AppStateStoreProvider.propTypes = {
  children: node.isRequired,
};
