export const flattenProjectUsers = (users) => {
  const flattened = [];
  if (users.author)
    flattened.push({ ...users.author, level: 3, nonRemovable: true });
  if (users.owner && users.owner.id !== users.author.id)
    flattened.push({ ...users.owner, level: 2 });
  return [
    ...flattened,
    ...users.observer.filter(
      (x) => ![users.owner.id, users.author.id].includes(x.id)
    ),
  ];
};
