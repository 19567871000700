import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  progress: {
    width: "12px !important",
    height: "12px !important",
  },
}));

export default useStyle;
