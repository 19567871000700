import { matchPath } from "react-router-dom";
import {
  activate, createWorkspace,
  inviteRegister,
  jiraAuth, login,
  loginWorkspace,
  loginWorkspaceOpenedProject,
  loginWorkspaceProject,
  register, resetPassword, retrievePassword
} from "@paths";

export const usePathChecker = () => {
  
  const current =
    location.pathname.lastIndexOf("/") === location.pathname.length - 1 &&
    location.pathname.length > 1
      ? location.pathname.slice(0, -1)
      : location.pathname;
  
  const hideNavigation = current !== login && current !== register;
  const hideContact = current === jiraAuth;
  
  let loginWorkspaceMatch = matchPath(loginWorkspace, location.pathname);
  let loginWorkspaceProjectMatch = matchPath(
    loginWorkspaceProject,
    location.pathname
  );
  let openProject = matchPath(loginWorkspaceOpenedProject, location.pathname);
  let jiraAuthMatch = matchPath(jiraAuth, location.pathname);
  let useRedirector =
    [
      loginWorkspaceMatch,
      loginWorkspaceProjectMatch,
      openProject, //jiraAuthMatch
    ].reduce((p, c) => p || !!c, false) &&
    [
      register,
      inviteRegister(".*"),
      activate,
      retrievePassword,
      resetPassword,
      createWorkspace,
    ].reduce((p, c) => {
      return p && !location.pathname.match(c);
    }, true);
  
  return {
    current,
    hideNavigation,
    hideContact,
    
    jiraAuthMatch,
    useRedirector,
    
    openProject,
    loginWorkspaceMatch,
    loginWorkspaceProjectMatch,
  }
}