import { APIMethod, fetchData } from "@client";

export async function getTemplatesQuery(page, limit, filters, company = false) {
  const params = { page, limit };

  if (filters)
    Object.keys(filters).forEach((k) => {
      if (filters[k]) params[k] = filters[k];
    });

  return await fetchData(
    `/template${company ? "/company" : ""}`,
    APIMethod.GET,
    { params }
  );
}
