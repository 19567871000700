import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  "@global": {
    ".canvasWrapper": {
      backgroundColor: "#fff",
    },
  },
  container: {
    padding: "8px 32px",
    width: "max-content",
    minWidth: "100%",
  },
  page: {
    position: "relative",
    boxSizing: "content-box",
    overflow: "hidden",
    width: "max-content",
    border: "1px solid transparent",
    borderRadius: 5,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15) !important",
    marginBottom: 8,
    "& canvas": {
      zIndex: 11,
      position: "relative",
    },
    "& .textLayer": {
      zIndex: 12
    },
    "& .annotationLayer": {
      zIndex: 13
    }
  },
  image: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    zIndex: 10
  }
  // heatmapContainer: {
  // 	zIndex: 2,
  // 	position: "absolute !important",
  // 	top: 0,
  // 	left: 0,
  // 	width: "100%",
  // 	height: "100%",
  // 	pointerEvents: "none",
  // },
}));

export default useStyle;
