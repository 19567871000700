import { CircularProgress, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useProjectCreatorStore, useFeatureGuardian } from "@hooks";
import { DEFAULT_WORK_TYPES, LOCKED_FEATURES, TEMPLATE_TYPE } from "@utils";
import { TemplateCard } from "@components";
import { arrayOf, bool, func, number, object } from "prop-types";
import { BlankTemplateCard } from "@components/Templates/StaticTemplateCards/BlankTemplateCard";
import { XlsTemplateCard } from "@components/Templates/StaticTemplateCards/XlsTemplateCard";
import useStyle from "./TemplatesList.style";
import { getWorkTypesQuery } from "@query";

export const TemplatesList = observer(({
  templates,
  defaultTemplateId,
  onSetDefault,
  onSelect,
  onRemove,
  // onEdit,
  loadingTemplates,
  showBlank = false,
  showXls = false,
  allowEdition = false,
  companyTemplates = false,
}) => {
  const creator = useProjectCreatorStore();
  const classes = useStyle();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ESTIMATION
  );
  
  const setWorkTypes = async (templateWorkTypes) => {
    
    let workTypes = creator.workTypes;
    if (!workTypes.length) {
      workTypes = await getWorkTypesQuery();
      creator.setWorkTypes(workTypes);
    }
    creator.setProjectWorkTypes((templateWorkTypes || DEFAULT_WORK_TYPES)
      .filter(wId => workTypes.find(wT => wT.id === wId)))
  }

  const selectTemplate = async (templateId, templateWorkTypes) => {
    const allowed = await checkAccess();
    if (!allowed) return;
    
    creator.setTemplate(
      templateId ? templates.find((x) => x.id === templateId) || undefined : undefined,
      companyTemplates
    );
    
    await setWorkTypes(templateWorkTypes);
    
    onSelect(templateId);
  };

  const selectBlank = async () => {
    creator.setTemplate(undefined);
    await setWorkTypes();
    onSelect(TEMPLATE_TYPE.BLANK);
  };

  const selectXls = () => {
    creator.setTemplate(undefined);
    onSelect(TEMPLATE_TYPE.XLS);
  };

  return (
    <Grid
      item
      container
      spacing={3}
      alignContent="flex-start"
      className="py-6"
    >
      <FeatureAlert />
      {showBlank && <BlankTemplateCard onSelect={selectBlank} />}
      {showXls && <XlsTemplateCard onSelect={selectXls} />}
      {templates.map(({ id, ...other }) => (
        <TemplateCard
          key={id}
          id={id}
          isDefault={defaultTemplateId === id}
          onSelect={selectTemplate}
          onRemove={onRemove}
          onSetDefault={onSetDefault}
          allowEdition={allowEdition}
          {...other}
        />
      ))}
      {loadingTemplates && (
        <CircularProgress
          aria-label="progress indicator"
          className={classes.progress}
        />
      )}
    </Grid>
  );
});

TemplatesList.propTypes = {
  templates: arrayOf(object).isRequired,
  onSetDefault: func,
  onSelect: func.isRequired,
  loadingTemplates: bool.isRequired,
  defaultTemplateId: number,
  onRemove: func,
  onEdit: func,
  showBlank: bool,
  showXls: bool,
  allowEdition: bool,
  allowRemoval: bool,
  companyTemplates: bool,
};
