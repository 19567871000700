import { useEffect, useMemo, useState } from "react";
import { bool, func, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { Dialog, Button, Tag, Accordion } from "@components";
import { Grid } from "@material-ui/core";
import { EstimateTitle } from "./components/EstimateTitle";
import { SettingContents } from "./components/SettingContents/SettingContents";

export const Estimate = observer(({
  open,
  workType,
  section,
  sectionWorkType,
  onClose,
}) => {
  
  const { t } = useTranslation();
  
  const structure = useStructureStore();
  
  const [localPercent, setPercent] = useState(1);
  const [isPercent, setIsPercent] = useState(false);
  const [usedWorkTypes, setUsedWorkTypes] = useState([]);
  
  const { id, percent, of, name, backgroundColor } = workType;
  
  useEffect(() => {
    const p = sectionWorkType ? sectionWorkType.percent : percent;
    setPercent(p || 1);
    setIsPercent(p > 0);
  }, [percent, sectionWorkType?.percent]);
  
  useEffect(() => {
    setUsedWorkTypes((sectionWorkType ? sectionWorkType?.of?.slice() : of) || []);
  }, [of, sectionWorkType?.of]);
  
  const availableWorkTypes = useMemo(() => {
    if (section) {
      const available = section.availableAutomationWorkTypes(id);
      return structure.workTypes.filter((structureWorkType) =>
        available.includes(structureWorkType.id)
      )?.map((workType) => workType.id);
    }
    return structure.availableAutomationWorkTypes(id)?.map((workType) => workType.id);
  }, [section?.usedWorkTypes, structure.workTypes]);
  
  const blacklistedWorkTypes = useMemo(() => {
    if(!section) return [];
    return structure.sections.reduce((t, s) => {
      s.blacklistedAutomationWorkTypes(id).forEach(wT => {
        const ex = t.find(tw => tw.id === wT);
        if(ex)
          ex.sections.push(s.name);
        else
          t.push({ id: wT, sections: [s.name] })
      })
      return t;
    }, [])
  }, [section?.usedWorkTypes, structure.sections]);
  
  const handleWorkTypeSelect = (id) => {
    setUsedWorkTypes((state) =>
      state.includes(id) ? state.filter((s) => s !== id) : [...state, id]
    );
  };
  
  const handleExpand = () => {
    setIsPercent((state) => !state);
  };
  
  const handlePercentChange = (value) => {
    setPercent(value);
  };
  
  const saveEstimationSettings = () => {
    onClose();
    structure.historyManager.startGroup();
    if (isPercent) {
      (sectionWorkType || workType).setAutomation(localPercent, usedWorkTypes ? [...usedWorkTypes] : []);
      if(section)
        section.resetPercentValues(sectionWorkType.id, true);
      
    } else {
      (sectionWorkType || workType).setAutomation(0, undefined);
      if(section)
        section.removeOverride(sectionWorkType.id)
    }
    
    structure.historyManager.stopGroup();
  };

  const actions = (
    <>
      <Button
        variant="outlined"
        className="w-max"
        onClick={onClose}
      >
        {t("common.cancel")}
      </Button>
      <Button
        variant="contained"
        className="w-max"
        onClick={saveEstimationSettings}
        disabled={isPercent && !usedWorkTypes.length}
      >
        {t("common.apply")}
      </Button>
    </>
  );

  return (
    <Dialog
      open={open}
      title={
        <Grid container alignItems="center">
          {t("views.editor.dialogs.estimate.title")}
          <Tag color={backgroundColor} text={name} className="ml-2" />
        </Grid>
      }
      onClose={onClose}
      actions={actions}
      width={600}
    >
      <Grid item container alignItems="flex-start">
        <Accordion
          hideExpandButton
          expanded={isPercent}
          title={<EstimateTitle expanded={isPercent} onExpand={handleExpand} />}
          variant="info"
          bordered={false}
          titleColored
        >
          <SettingContents
            percent={localPercent}
            usedWorkTypes={usedWorkTypes}
            availableWorkTypes={availableWorkTypes}
            blacklistedWorkTypes={blacklistedWorkTypes}
            onPercentChange={handlePercentChange}
            onWorkTypeSelect={handleWorkTypeSelect}
          />
        </Accordion>
      </Grid>
    </Dialog>
  );
});

Estimate.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  workType: object.isRequired,
  section: object,
  sectionWorkType: object,
};
