import { func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ControlledTextField } from "@components";
import { CircularProgress, Divider, FormControlLabel, Grid, Switch } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailRegex } from "./emailRegex";
import { useState } from "react";

const emailInvoicesFormSchema = (t) =>
  yup.object().shape({
    invoiceEmail: yup
      .string()
      .matches(emailRegex, t("errors.email.required"))
      .required(t("errors.email.required")),
  });

export const EmailInvoicesForm = ({
  email,
  sendInvoices: defaultSendInvoices=false,
  onSubmit,
  onSendInvoices
}) => {
  const { t } = useTranslation();
  
  const [sendInvoices, setSendInvoices] = useState(defaultSendInvoices);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting }
  } = useForm({
    resolver: yupResolver(emailInvoicesFormSchema(t)),
    defaultValues: {
      invoiceEmail: email || ""
    },
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };
  
  const handleSendInvoices = (e) => {
    setSendInvoices(e.currentTarget.checked);
    onSendInvoices && onSendInvoices(e.currentTarget.checked);
  }

  return (
    <form
      autoComplete="on"
      aria-label="email invoices form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <FormControlLabel
        label={t("forms.invoice_send")}
        className="mb-4 ml-0"
        control={<Switch
          color="primary"
          checked={sendInvoices}
          onChange={handleSendInvoices}
          name={name}
          className="mr-2"
        />}
      />
      <ControlledTextField
        name="invoiceEmail"
        control={control}
        label={t("forms.invoice_email")}
        autoComplete="email"
        type="email"
        fullWidth
        disabled={!sendInvoices}
      />
      
      <Divider className="mt-2 -mx-6 mb-6" />
      
      <Grid item container justifyContent="flex-end">
        <Button
          isSubmit
          name="submit"
          variant="contained"
          disabled={!sendInvoices}
          endIcon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {t("common.save")}
        </Button>
      </Grid>
    </form>
  );
};

EmailInvoicesForm.propTypes = {
  onSubmit: func.isRequired,
  onSendInvoices: func,
  email: string,
  sendInvoices: string,
};
