import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  forgot: {
    width: "100%",
    marginTop: 8,
    justifyContent: "center",
    height: 40,
  },
  bottomText: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  askText: {
    fontSize: 18,
    letterSpacing: 0.33,
  },
}));

export default useStyle;
