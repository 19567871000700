import { bool, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useProjectEditorStore, useRiskBufferAlert, useStructureStore } from "@hooks";
import { ValueCell, ResetValueButton, Alert } from "@components";
import {useMemo} from "react";

export const HourCell = observer(({
  element,
  allowEdition,
  displayCellContent,
  isWorkType,
  profitabilityMode,
}) => {
  const { t } = useTranslation();
  const { visibilityMode } = useProjectEditorStore();
  const structure = useStructureStore();
  
  const {
    useMinMax,
    modifier,
    riskBuffer,
    roundType,
    roundHours,
    usedRoundDecimals,
  } = structure.settings;
  
  const {
    apply,
    hideSummaryHours,
    hideMinValues,
    hideMaxValues
  } = structure.visibility;
  
  const {
    parsedHours,
    hasOnlyCost,
    inBreakdown,
    showHoursMax,
    hasCustomHours,
  } = element;
  
  const {
    riskAlertVisible,
    handleRiskAlertClose,
    handleRiskAlertOpen,
  } = useRiskBufferAlert()
  
  const handleHoursChange = (hour, isMax) => {
    structure.historyManager.startGroup();
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCustomHours({ [key]: parseFloat((hour * modifier).toPrecision(12)) });
    
    handleRiskAlertOpen();
    structure.historyManager.stopGroup();
  };

  const handleHoursReset = () => {
    element.resetPercentHours();
  };

  const displayMessage = useMemo(() => (
    allowEdition &&
    isWorkType &&
    inBreakdown
  ), [allowEdition, isWorkType, inBreakdown]);
  
  const messageTitle = useMemo(() => {
   if(!profitabilityMode && displayMessage)
      return t("views.editor.breakdown_hours_alert.title")
  }, [displayMessage]);
  
  const messageContent = useMemo(() => {
    if(!profitabilityMode && displayMessage)
      return t("views.editor.breakdown_hours_alert.content")
  }, [displayMessage]);
  
  const showDecimals = useMemo(() => (
    roundType !== 2 || !roundHours
  ), [roundType, roundHours]);
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryHours && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryHours]);
  
  return (
    <ValueCell
      widest
      value={
        hasOnlyCost
          ? undefined
          : useMinMax
          ? parsedHours.min
          : parsedHours.avg
      }
      valueMax={hasOnlyCost ? undefined : parsedHours.max}
      onValueChange={handleHoursChange}
      displayContent={((allowEdition && !profitabilityMode) || !hasOnlyCost) && displayCellContent}
      editable={allowEdition && !inBreakdown && !profitabilityMode}
      messageTitle={messageTitle}
      message={messageContent}
      showDecimals={showDecimals}
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={(allowEdition && useMinMax && !inBreakdown) || (showHoursMax && (!apply || !hideMaxValues))}
      warning={!inBreakdown && hasCustomHours}
      textPresetClass="preset-summaryText"
      useSeparator={profitabilityMode}
      decimals={usedRoundDecimals}
      className={className}
      inputClassName="input-hours"
    >
      {displayCellContent && allowEdition && !inBreakdown && hasCustomHours && (
        <ResetValueButton action={handleHoursReset}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
      {allowEdition && riskAlertVisible && (
        <Alert
          isOpen={riskAlertVisible}
          title={t("views.editor.dialogs.settings.risk_buffer")}
          acceptText={t("common.close")}
          onAccept={handleRiskAlertClose}
          useLockCheckbox
        >
          {t("views.editor.risk_alert", {percent: riskBuffer})}
        </Alert>
      )}
    </ValueCell>
  );
});

HourCell.propTypes = {
  element: object.isRequired,
  allowEdition: bool,
  displayCellContent: bool,
  isWorkType: bool,
  profitabilityMode: bool,
};
