import { useState } from "react";
import { bool, func, number, object, oneOfType, string } from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { EditorContainer } from "../EditorContainer";
import useStyle from "./SectionContainer.style";
import classnames from "classnames";

export const SectionContainer = ({
  section,
  isFirst=false,
  ...containerProps
}) => {
  const classes = useStyle();
  
  const [expanded, expandSection] = useState(isFirst);
  
  const handleExpand = () => expandSection(!expanded);
  
  return (
    <Grid item container className={ classes.container }>
      <Accordion
        square
        expanded={expanded}
        onChange={handleExpand}
        className={classnames(classes.accordion, expanded && "expanded")}
      >
        <AccordionSummary
          className={classnames(classes.title, expanded && "expanded")}
        >
          <Grid item container>
            <IconButton size="small" className="mr-2" onClick={handleExpand}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <h3>{section.name}</h3>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className={classnames("h-max", classes.content, expanded && "expanded")}
        >
          <EditorContainer
            section={section}
            {...containerProps}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

SectionContainer.propTypes = {
  section: object,
  isFirst: bool,
  fullSettings: bool,
  selectedWorkType: number,
  selectedResource: oneOfType([string, number]),
  setExpanded: func,
}