import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: (isMobile) => (isMobile ? 16 : 24),
    height: theme.props.contentHeight,
    width: "100%",
    margin: 0,
    padding: 0,
    alignContent: "flex-start",
    paddingBottom: (isMobile) => (isMobile ? 0 : 24),
  },
  paper: {
    width: "100%",
    borderRadius: 10,
    border: `1px solid ${theme.props.border}`,
    backgroundColor: theme.props.backgroundSecondary,
    overflow: "hidden",
    padding: "32px 24px",
    boxSizing: "border-box",
  },
  menuContainer: {
    marginRight: 24,
    width: 300,
  },
  fixedMenu: {
    position: "fixed",
    width: 230,
    paddingRight: 24,
  },
  menuButton: {
    borderRadius: 5,
    height: 40,
    "& > span": { textAlign: "left" },
    "&.selected": {
      backgroundColor: `${theme.palette.primary.main}25`,
    },
  },
}));

export default useStyle;
