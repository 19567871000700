import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import useStyle from "./Table.style";
import classnames from "classnames";

export const TableCell = (props) => {
  const classes = useStyle();
  const {
    className,
    children,
    width,
    flexible,
    sticky,
    shadow,
    big,
    style={},
    justifyContent="flex-start",
    noTextOverflow,
    ...rest
  } = props;

  return (
    <Grid
      item
      container
      role="rowitem"
      justifyContent={justifyContent}
      className={`${classes.cell} ${className} ${big ? classes.cellBig : ""} ${
        sticky >= 0 ? "sticky" : ""
      } ${shadow ? "shadow" : ""}`}
      style={
        width
          ? {
              width: !flexible ? width : undefined,
              maxWidth: !flexible ? width : undefined,
              minWidth: width,
              left: sticky >= 0 ? sticky : undefined,
              ...style,
            }
          : style
      }
      // style={width ? {flexBasis: width, flexShrink: 0, flexGrow: 1, ...style} : style}
      {...rest}
    >
      <Grid
        item container
        alignItems="center"
        className={classnames(noTextOverflow && classes.cellNoTextOverflow)}
      >
        {children}
      </Grid>
    </Grid>
  );
};

TableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flexible: PropTypes.bool,
  big: PropTypes.bool,
  style: PropTypes.object,
  sticky: PropTypes.number,
  shadow: PropTypes.bool,
  noTextOverflow: PropTypes.bool,
  justifyContent: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
};