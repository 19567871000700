import { useEffect, useState } from "react";
import { bool, func, string } from "prop-types";
import { applyCouponQuery, removeCouponQuery } from "@query";
import { useTranslation } from "react-i18next";
import { Button, Dialog, TextField } from "@components";
import { CircularProgress } from "@material-ui/core";

export const CouponDialog = ({
  open,
  onClose,
  coupon,
  setCoupon
}) => {
  const { t } = useTranslation();

  const [couponVal, setCouponVal] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleCouponChange = (e) => {
    setError(null);
    setCouponVal(e.target.value);
  };

  const applyCoupon = async () => {
    setLoading(true);
    setError(null);
    if (coupon) await removeCouponQuery();
    try {
      await applyCouponQuery(couponVal);
      setCoupon(couponVal);
      onClose();
    } catch (e) {
      setError(e.response?.data?.errors?.[0]?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setCouponVal(coupon || "");
      setError(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      width={400}
      title={t("views.subscription.dialogs.coupon")}
      actions={
        <>
          <Button variant="outlined" onClick={() => onClose()}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            icon={
              isLoading && (
                <CircularProgress
                  aria-label="progress indicator"
                  size={24}
                  color="inherit"
                />
              )
            }
            onClick={applyCoupon}
          >
            {t("views.subscription.actions.add_coupon")}
          </Button>
        </>
      }
    >
      {coupon && (
        <p className="mb-2">{t("views.subscription.dialogs.coupon_alert")}</p>
      )}
      <TextField
        id="feedback"
        value={couponVal}
        error={error && couponVal}
        helperText={couponVal ? error : undefined}
        onChange={handleCouponChange}
      />
    </Dialog>
  );
};

CouponDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  setCoupon: func.isRequired,
  coupon: string,
};
