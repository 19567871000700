import { omit, unescape } from "lodash";
import { mergeDeep } from "@utils";


const defaultTextBlock = (text) => ({ font: { size: 10, scheme: "minor" }, text });
const defaultEndLineBlock = () => ({text: "\n"});
const rdiMap = [
  { tag: "p", block: defaultTextBlock },
  { tag: "/p", block: defaultEndLineBlock, close: true },
  { tag: "blockquote", block: defaultTextBlock },
  { tag: "/blockquote", block: defaultEndLineBlock, close: true },
  { tag: "pre", block: defaultTextBlock },
  { tag: "/pre", block: defaultEndLineBlock, close: true },
  { tag: "strong", block: { font: { bold: true }} },
  { tag: "/strong", close: true, closeStyle: true },
  { tag: "em", block: { font: { italic: true }} },
  { tag: "/em", close: true, closeStyle: true },
  { tag: "s", block: { font: { strike: true }} },
  { tag: "/s", close: true, closeStyle: true},
  { tag: "u", block: { font: { underline: "single"}}},
  { tag: "/u", close: true, closeStyle: true},
  { tag: "ol", block: defaultEndLineBlock, closeStyle: true, initOrder: true},
  { tag: "/ol", close: true, initOrder: false },
  { tag: "ul", block: defaultEndLineBlock, useBullet: true },
  { tag: "/ul", close: true, useBullet: false  },
  { tag: "li", block: (text, order, bullet) => ({
      text: bullet
        ? unescape(`    • ${text}`)
        : order > 0 ? `    ${order}. ${text}` : `    ${text}`
  }), increaseOrder: true},
  { tag: "/li", block: defaultEndLineBlock, close: true },
  { tag: "h1", block: (text) => ({ font: { size: 20,scheme: "minor" }, text }) },
  { tag: "/h1", block: defaultEndLineBlock, close: true },
  { tag: "h2", block: (text ) => ({ font: { size: 16,scheme: "minor" }, text }) },
  { tag: "/h2", block: defaultEndLineBlock, close: true },
  { tag: "h3", block: (text) => ({ font: { size: 12,scheme: "minor" }, text }) },
  { tag: "/h3", block: defaultEndLineBlock, close: true },
];

export const mapToXlsRichDescriptions = (htmlString) => {
  let richText = [];
  
  if(!htmlString.match(/<\/?\w+ ?.*?>/g))
    return htmlString;
  
  const text = htmlString.replace(/<\/p>/g, " ").replace(/<\/?\w+ ?.*?>/g, "");
  // const hyperlink = htmlString.match(/<a .*?href="(.*?)".*?>/g)?.pop(-1)?.match(/<a .*?href="(.*?)".*?>/)?.[1];
  
  let lastElement, lastStyleBlock, olTracker=-1, useBullet = false, lastLineBlock;
  htmlString
    .split("<")
    .forEach((part) => {
      if(!part) return;
      const text = unescape(part.replace(/\/?\w+ ?.*?>/g, ""));
      const tag = part.split(">")[0].replace(/ .*/, "");
      const map = rdiMap
        .find(({tag: listTag}) => tag === listTag);
      
      if(map) {
        
        if(typeof map.block === "function") {
          lastLineBlock = lastLineBlock ? undefined : tag.replace("/","");
          lastStyleBlock = undefined;
        } else if(map.closeStyle && lastStyleBlock) {
          const styleMap = rdiMap.find(({tag: listTag}) => tag.slice(1) === listTag);
          if(styleMap.block)
            lastStyleBlock.font = omit(lastStyleBlock.font, Object.keys(styleMap.block.font));
        }
        
        if(typeof map.useBullet === "boolean")
          useBullet = map.useBullet;
        if(typeof map.initOrder === "boolean")
          olTracker = map.initOrder ? 0 : -1;
        if(map.increaseOrder && olTracker >= 0)
          olTracker++;
        
        if(typeof map.block === "function" || map.close) { // text block or self-closing block
          if(lastElement?.text)
            richText.push(lastElement);
          lastElement = undefined;
        }
        
        if(map.close && text && lastLineBlock) {
          const oldMap = rdiMap
            .find(({tag: listTag}) => lastLineBlock === listTag);
          if(!oldMap.block) return;
          const block = typeof oldMap.block === "function"
            ? oldMap.block(text, olTracker, useBullet)
            : oldMap.block;
          lastElement = mergeDeep(block, lastStyleBlock || {});
          return;
        }
        
        if(typeof map.block === "function") { // text block
          if(map.close) // self-closing text block
            richText.push(map.block());
          else
            lastElement = map.block(text, olTracker, useBullet);
        } else if(typeof map.block === "object") {
          if(lastElement) {
            if ( !lastElement?.text ) {
              lastElement = mergeDeep(lastElement, map.block, lastStyleBlock || {});
              lastElement.text = text;
            } else {
              richText.push(lastElement);
              lastElement = mergeDeep(defaultTextBlock(text, olTracker, useBullet), map.block, lastStyleBlock || {});
            }
          }
          lastStyleBlock = mergeDeep(lastStyleBlock || {}, map.block);
        }
        
      } else if(text && lastElement) {
        lastElement.text = (lastElement.text || "") + text;
      }
    });
  
  if(JSON.stringify(richText[richText.length-1]) === JSON.stringify(defaultEndLineBlock()))
    richText.pop(-1);
  
  return { text, richText }
}