import { useEffect } from "react";
import { arrayOf, bool, func, object } from "prop-types";
import { observer } from "mobx-react";
import { getWorkTypesQuery } from "@query";
import { Dialog } from "@components";
import { StepsFactory, ImporterActions, ImporterStepper } from "./components";
import { useProjectCreatorStore } from "@hooks";

export const XlsImporter = observer(({ modalOpened, workTypes, onClose }) => {
  const creator = useProjectCreatorStore();

  const { activeStep, importWorkTypes } = creator;

  const init = async () => {
    creator.resetXlsImportToDefaults();
    let wT = creator.workTypes;
    if (!wT.length) {
      wT = await getWorkTypesQuery();
      creator.setWorkTypes(wT);
    }
    if (workTypes)
      creator.setImportWorkTypes(
        workTypes.map((s) => wT.find((t) => s.id === t.id))
      );
  };
  
  const handleModalClose = (hardReset) => {
    onClose();
    creator.resetXlsImportToDefaults(hardReset);
  };
  
  const cancelImport = () => {
    handleModalClose(true);
  }

  const finishImporting = async () => {
    creator.setProjectWorkTypes(importWorkTypes.map(({id}) => id));
    handleModalClose(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Dialog
      open={modalOpened}
      onClose={cancelImport}
      useCloseButton={false}
      containerClass={activeStep > 1 ? "py-3" : null}
      actionsAlignment="spaceBetween"
      width={1200}
      minWidth={600}
      title={<ImporterStepper />}
      actions={
        <ImporterActions
          cancelImport={cancelImport}
          finishImporting={finishImporting}
        />
      }
    >
      <StepsFactory activeStep={activeStep} />
    </Dialog>
  );
});

XlsImporter.propTypes = {
  modalOpened: bool.isRequired,
  onClose: func.isRequired,
  workTypes: arrayOf(object),
};
