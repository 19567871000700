import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { HtmlParser } from "@utils";
import { Button, Dialog } from "@components";

export const PolicyDialog = (props) => {
  const { onDisclaimerClose } = props;

  const { t } = useTranslation();

  const [disclaimerVisible, setDisclaimerVisibility] = useState(true);

  const hideDisclaimer = () => {
    setDisclaimerVisibility(false);
    if (onDisclaimerClose) onDisclaimerClose();
  };

  return (
    <Dialog
      open={disclaimerVisible}
      containerClass="coloredLinks"
      actions={
        <Button variant="contained" onClick={hideDisclaimer}>
          {t("views.editor.dialogs.policy.accept")}
        </Button>
      }
    >
      <p>{HtmlParser(t("views.editor.dialogs.policy.text"))}</p>
    </Dialog>
  );
};

PolicyDialog.propTypes = {
  /** callback fired on accepting policy & cookies  */
  onDisclaimerClose: PropTypes.func,
};
