export default {
  headerColor: "#4098F4",
  backgroundColor: "#f5f6fa",
  paperColor: "#ffffff",
  paperTimelineColor: "#ffffff",
  // fontFamily: DEFAULT_FONT_NAME, //any font name loaded into system
  rowColor: "#ffffff",
  rowDescColor: "#f5f6fa",
  rowBorderColor: "#4098F4",
  rowSummaryColor: "#ffffff",
  rowTimelineColor: "#ffffff",
  rowActiveSummaryColor: "#ecf4fe",
  rowActiveTimelineColor: "#ecf4fe",
  rowSummaryBorderColor: "#4098F4",
  rowTimelineBorderColor: "#4098F4",
  activeColor1: "#ecf4fe",
  activeColor2: "#f5fafe",
  activeColor3: "#ffffff",
  priceColor: "#4098F40d",
  sectionColor: "#717381",
  sectionDivider: "#D9DAE1",
  paperDivider: "#E5E5E5",
  exportButtonColor: "#f5f6fa",
  versionActiveBackground: "#DBECFD",
  versionBackground: "#ffffff",
  timelineModeBackground: "#ffffff",
  timelineModeBackgroundActive: "#4098F4",
  
  tableBreakdownExpandButton: "#f5f6fa",
  tableSummaryExpandButton: "#f5f6fa",
  tableTimelineExpandButton: "#f5f6fa",
  
  fontColor: {
    name: "#3A3C4F",
    text: "#757784",
    titleText: "#3A3C4F",
    titleTextSection: "#ffffff",
    summaryText: "#757784",
    timelineText: "#757784",
    summaryTitleText: "#3A3C4F",
    timelineTitleText: "#3A3C4F",
    title: "#3A3C4F",

    section: "#ffffff",
    sectionRow: "#ffffff",
    sectionDesc: "#3A3C4F",
    breakdownHeader: "#3A3C4F",
    summaryHeader: "#3A3C4F",
    timelineHeader: "#3A3C4F",
    timelineSubHeader: "#3A3C4F",
    tableTimelineHeader: "#757784",
    tableTimelineBilling: "#757784",
    tableBreakdownHeader: "#757784",
    tableSummaryHeader: "#757784",
    tableFooter: "#757784",
    
    row: "#3A3C4F",
    rowDesc: "#3A3C4F",
    rowActive1: "#3A3C4F",
    rowActive2: "#3A3C4F",

    price: "#3A3C4F",
    totalTime: "#757784",
    totalTimeTitle: "#757784",
    totalHours: "#757784",
    totalHoursTitle: "#757784",
    totalCost: "#3A3C4F",
    totalCostTitle: "#757784",
    
    moreDesc: "#4098F4",
    moreDescSection: "#b4e0fd",

    trashIcon: "#EB5757",

    export: "#3A3C4F",

    version: "#4098F4",
    timelineMode: "#3A3C4F",
    timelineModeActive: "#ffffff",

    btGreenText: '#41BD56',
    btRedText: '#DE0909',
    
    tableBreakdownExpandButton: "#3A3C4F",
    tableSummaryExpandButton: "#3A3C4F",
    tableTimelineExpandButton: "#3A3C4F",
  },
  texts: {},
};
