// Material components
import { SvgIcon } from "@material-ui/core";

export function Header2(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 12">
      <path xmlns="http://www.w3.org/2000/svg" d="M0.648438 11.3112V0.689453H2.62889V5.0101H6.52126V0.689453H8.50171V11.3112H6.52126V6.99055H2.62889V11.3112H0.648438ZM11.5017 11.3112V6.99055C11.5017 6.44617 11.6946 5.97999 12.0804 5.59203C12.4662 5.20408 12.9381 5.0101 13.4963 5.0101H17.3745V2.68403H11.5017V0.689453H17.3745C17.9369 0.689453 18.4099 0.882336 18.7936 1.2681C19.1773 1.65389 19.3691 2.12586 19.3691 2.68403V5.0101C19.3691 5.55449 19.1762 6.02066 18.7905 6.40863C18.4047 6.79658 17.9327 6.99055 17.3745 6.99055H13.4963V9.31663H19.3691V11.3112H11.5017Z" fill="currentColor"/>
    </SvgIcon>
  );
}
