import { memo } from "react";
import { bool, number, func, string, oneOf } from "prop-types";
import { capitalize, Grid, IconButton } from "@material-ui/core";
import { ModuleArrow } from "@assets";
import { ExpandMore } from "@material-ui/icons";
import useStyle from "./ExpandCell.style";
import useCellStyle from "../commonStyles.style";
import classnames from "classnames";

export const ExpandCell = memo(
  ({
    expandable,
    expanded,
    onExpand,
    noArrow,
    tableDepth = 0,
    highlightCellText,
    textPresetClass,
    className,
    isHeader,
    size="large"
  }) => {
    const classes = useStyle();
    const cellClasses = useCellStyle();

    return (
      <Grid
        item
        container
        role="rowitem"
        className={classnames(
          "cell",
          `d${tableDepth}`,
          cellClasses.cell,
          classes.expandCell,
          isHeader && cellClasses.headCell,
          cellClasses[`size${capitalize(size)}`],
          highlightCellText && "highlightCellText",
          className
        )}
      >
        <Grid
          item container
          alignItems="center"
          justifyContent="flex-end"
          wrap="nowrap"
          className={classnames(isHeader && "h-full", !isHeader && cellClasses.cellInner)}
        >
          {expandable ? (
            <IconButton
              onClick={onExpand}
              className={classnames("noDrag expand", textPresetClass)}
              tabIndex={-1}
            >
              {
                <ExpandMore
                  className={classnames("transition", expanded ? "rotate-180" : "rotate-0")}
                />
              }
              {/*{expanded ? <ExpandLess/> : <ExpandMore/>}*/}
            </IconButton>
          ) : (
            !noArrow && (
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.arrow}
              >
                <ModuleArrow className={textPresetClass} />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
    );
  }
);

ExpandCell.propTypes = {
  expandable: bool,
  expanded: bool,
  noArrow: bool,
  onExpand: func,
  // common
  tableDepth: number,
  highlightCellText: bool,
  textPresetClass: string,
  className: string,
  isHeader: bool,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"])
};
