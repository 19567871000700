import { makeStyles } from "@material-ui/core/styles";

const replayerPadding = 16;

const useStyle = makeStyles((theme) => ({
  
  paper: {
    width: "100%",
    borderRadius: 5,
    boxShadow: "none",
    border: "1px solid " + theme.props.border,
    padding: replayerPadding,
    overflow: "hidden",
    position: "relative",
  },
  replayerAnchor: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    height: "calc(100% - 68px)",
    marginBottom: replayerPadding,
    "& > div": {
      position: "absolute",
      top: "50%",
    },
    "& iframe": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "-20px 0 0 -20px",
  },
  controlPanel: {
    height: 68,
    margin: `0 -${replayerPadding}px`,
    flexWrap: "nowrap",
    backgroundColor: theme.palette.text.primary,
    width: `calc(100% + ${2*replayerPadding}px)`,
  },
  slider: {
    padding: 0,
    height: 6,
    color: theme.palette.primary.main,
    "& .MuiSlider-rail": {
      height: 6,
      backgroundColor: theme.props.text.alt + "33",
    },
    "& .MuiSlider-track": {
      height: 6,
    },
    "& .MuiSlider-thumb": {
      background: "linear-gradient(180deg, #F5F6FA 30.21%, #FFFFFF 100%)",
      border: "2px solid #fff",
      boxSizing: "border-box",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
      height: 16,
      width: 16,
    },
  },
  controls: {
    height: "100%",
    color: theme.props.text.alt,
    position: "relative",
    "& > p": {
      fontSize: 14,
      fontWeight: "bold",
      position: "absolute",
      left: 24,
    },
  }
}));

export default useStyle;
