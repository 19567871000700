import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  name: {
    cursor: "pointer",
    display: "flex",
    "& > span": {
      marginTop: 1,
    },
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default useStyle;
