import { APIMethod, fetchData } from "@client";

export async function updateLibraryElementParentQuery(
  elementId,
  parent,
  order
) {
  await fetchData(`/v1/library/parent/${elementId}`, APIMethod.PATCH, {
    data: { parent, order },
  });
}
