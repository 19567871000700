import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: (isMobile) => (isMobile ? 16 : 24),
    height: theme.props.contentHeight,
    margin: "0 -16px",
    alignContent: "flex-start",
  },
}));

export default useStyle;
