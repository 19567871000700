import { bool } from "prop-types";
import { observer } from "mobx-react";
import { usePresetFieldNames, useProjectEditorStore, useStructureStore } from "@hooks";
import { TotalContainer } from "../components";
import { useTranslation } from "react-i18next";
import { ResetValueButton } from "@components";
import { useMemo } from "react";

export const TotalTime = observer(({
  allowEdition,
  displayMax,
  ...containerProps
}) => {
  
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const {t} = useTranslation();
  const { getFieldName } = usePresetFieldNames(containerProps.isProposal);
  
  const { settings, visibility, totalTime, showTotalMaxTime } = structure;
  const { useMinMax, isTotalTimeOverwritten } = settings;
  const { apply, hideTotalTime } = visibility;
  
  const handleTimeChange = (time, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    settings.setCustomTotalTime({ [key]: time });
  };
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideTotalTime && "transparent-2") || undefined
  ), [visibilityMode, apply, hideTotalTime])
  
  return (
    <TotalContainer
      totalName="Time"
      min={useMinMax ? totalTime.min : totalTime.avg}
      max={totalTime.max}
      onValueChange={handleTimeChange}
      editable={allowEdition}
      // displayMax={showTotalMaxTime || (!allowEdition && useMinMax)}
      displayMax={showTotalMaxTime && displayMax}
      symbolEnd={ (
        <span>
         { getFieldName("pt-totalTimeMonths") }
        </span>
      ) }
      showDecimals
      textSmaller
      allowEdition={allowEdition}
      className={className}
      visibilityHidden={hideTotalTime}
      setVisibility={visibility.setHideTotalTime}
      { ...containerProps }
    >
      {allowEdition && isTotalTimeOverwritten && (
        <ResetValueButton
          action={() => settings.resetTotalTimeValues()}
          className="text-4xl"
        >
          {t("common.restore_value")}
        </ResetValueButton>
      )}
    </TotalContainer>
  );
})

TotalTime.propTypes = {
  isProposal: bool,
  divider: bool,
  displayMax: bool,
}