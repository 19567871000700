import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  box: {
    padding: 24,
    height: "100%",
    minHeight: 260,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    position: "relative",
  },
  bordered: {
    [theme.breakpoints.down("sm")]: {
      borderBottom: `1px solid ${theme.props.border}`,
    },
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.props.border}`,
    },
  },
  paymentInfo: {
    marginTop: 4,
    color: theme.palette.text.primary + "80",
    justifyContent: "space-between",
    "& strong": {
      color: theme.palette.text.primary,
      fontSize: 20,
    },
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -20,
    marginTop: -20,
  },
  discountLine: {
    background: theme.palette.primary.main,
    height: 3,
    left: 0,
    transform: "rotate(-10deg)",
    width: "100%",
    position: "absolute",
    top: "50%"
  }
}));

export default useStyle;
