import { useEffect, useState } from "react";
import { bool, func } from "prop-types";
import { useEditorWebsocket, useProjectEditorStore, useS3Uploader, useStores } from "@hooks";
import { observer } from "mobx-react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Divider,
  Tooltip,
  Hidden,
} from "@material-ui/core";
import {
  ActionButton,
  Alert,
  Button,
  TextField,
} from "@components";
import { Share, PdfExport } from "@dialogs";
import {
  AspectRatioVertical,
  AspectRatioHorizontal,
  Trash,
} from "@assets";
import { Add, MenuOpenRounded, Remove } from "@material-ui/icons";
import { isBetween, SCALE_PRESETS } from "@utils";
import { useFeatureGuardian } from "@hooks";
import { LOCKED_FEATURES } from "@utils";
import useStyle from "./ToolBar.style";
import { useParams } from "react-router-dom";
import { removeProjectProposalQuery } from "@query";
import { DocumentRemoval } from "@components/PdfEditor/components/DocumentRemoval/DocumentRemoval";


let scrollTimeout;

export const ToolBar = observer(({
  proposalSharing,
  noShareActions,
  noThumbButton,
  isObserver,
  hasSharePrivileges,
  sidebarVisible,
  handleShowSidebar,
}) => {
  
  const { projectUuid } = useParams();
  const { stateStore } = useStores();
  const editorStore = useProjectEditorStore();
  const socket = useEditorWebsocket();
  const { t } = useTranslation();
  const classes = useStyle();
  
  const { uploadProposal } = useS3Uploader();
  
  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.PDF
  );
  
  const {
    isSharedVersion,
    pdfDocument,
    currentPage,
    numPages,
    scale,
    scaleValue,
    pdfPages
  } = editorStore;
  
  const [ uploadAlertVisible, showUploadAlert ] = useState(false);
  const [ localCurrentPage, setCurrentPage ] = useState(currentPage || 1);
  const [ removeAlertVisible, showRemoveAlert ] = useState(false);
  
  const handleScaleChange = (e) => {
    const s = editorStore.setScale(e.target.value);
    if(s)
      socket?.updateCoverScale(pdfDocument.id, s);
  };
  
  const handleScaleSet = (v) => () => {
    const s = editorStore.setScale(v);
    if(s)
      socket?.updateCoverScale(pdfDocument.id, s);
  };
  
  const handleScaleButton = (d) => () => {
    const s = editorStore.setScale((parseInt(scale * 10) + d) / 10, SCALE_PRESETS.CUSTOM);
    if(s)
      socket?.updateCoverScale(pdfDocument.id, s);
  };
  
  const handleWheelScale = (e) => {
    if (pdfDocument && (e.ctrlKey || e.metaKey)) {
      e.stopPropagation();
      e.preventDefault();
      clearTimeout(scrollTimeout);
      editorStore.setScale( Math.max(
        Math.min(editorStore.tmpScale + e.wheelDelta / 240, 100),
        0.1
      ), true, true);
      scrollTimeout = setTimeout(() => {
        if(!editorStore.scale) return;
        editorStore.tmpScale = editorStore.scale;
        editorStore.applyScale(editorStore.scale);
        socket?.updateCoverScale(pdfDocument.id, { scale: editorStore.scale });
      }, 250);
    }
  };
  
  const handleArrowKeys = (e) => {
    const el = window.document.activeElement;
    if (
      [37, 39].includes(e.keyCode) &&
      el.nodeName !== "INPUT" &&
      !el.className.match(/(input)|(select)/gi)
    ) {
      const newPage = editorStore.currentPage + (e.key === "ArrowLeft" ? -1 : 1);
      if (isBetween(newPage, 0, editorStore.numPages-1))
        editorStore.jumpToPage(newPage);
    }
  };
  
  useEffect(() => {
    window.addEventListener("wheel", handleWheelScale, { passive: false });
    window.addEventListener("keydown", handleArrowKeys, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleWheelScale, {
        passive: false,
      });
      window.removeEventListener("keydown", handleArrowKeys, {
        passive: false,
      });
    };
  }, []);
  
  useEffect(() => {
    setCurrentPage(currentPage+1);
  }, [currentPage]);
  
  const fileGetter = async (files) => {
    if(!files[0]) return;
    if (proposalSharing) {
      const allowed = await checkAccess();
      if (!allowed) return;
    }
    
    const file = files[0]
    let version = 1;
    
    stateStore.resetFilesUploadProgress(pdfDocument ? 2 : 1);
    if(pdfDocument?.file?.path) {
      await removeProjectProposalQuery(projectUuid, pdfDocument.id);
      version = Number(decodeURIComponent(pdfDocument.file.path).match(/\/(\d+)\//)?.[1] || 0) + 1;
      socket?.removeCover();
      stateStore.setFilesUploadProgress(1, 100);
    }
    const pdf = await uploadProposal(projectUuid, file, 0, version, [], proposalSharing ? 0 : -1, 1);
    stateStore.resetFilesUploadProgress();
    
    if(!pdf)
      return;
    
    socket?.addPdfCover(pdf);
    editorStore.setPdfDocument(pdf, true);
  }
  
  const handleCurrentPageChange = (e) => {
    const v = e.target.value.replace(/[^\d]/g, "");
    setCurrentPage(v);
  };
  
  const handlePageInputBlur = () => {
    if (
      !localCurrentPage ||
      !isBetween(parseInt(localCurrentPage), 1, numPages)
    )
      setCurrentPage(currentPage);
    else editorStore.jumpToPage(parseInt(localCurrentPage)-1);
  };
  
  const { getInputProps, open } = useDropzone({
    accept: [".pdf"],
    multiple: false,
    noClick: true,
    noKeyboard: true,
    maxSize: 10 * 1024 * 1024,
    onDropAccepted: fileGetter,
    onDropRejected: () => showUploadAlert(true),
  });

  return (
    <Paper
      elevation={1}
      className={classes.root}
    >
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className="h-full"
      >
        <Grid
          item
          container
          alignItems="center"
          wrap="nowrap"
          className={classes.filler}
        >
          {!noThumbButton && (
            <Hidden xsDown>
              <Tooltip
                title={t(
                  `views.pdf_viewer.tooltips.${
                    sidebarVisible ? "hide" : "show"
                  }`
                )}
              >
                <ActionButton
                  icon={<MenuOpenRounded />}
                  size="small"
                  color="secondary"
                  onClick={handleShowSidebar}
                />
              </Tooltip>
            </Hidden>
          )}
        </Grid>
        
        <Grid
          item
          container
          wrap="nowrap"
          justifyContent="center"
          alignItems="center"
        >
          {isObserver && !isSharedVersion && (
            <>
              <Button
                id="uploadPdf"
                variant="contained"
                onClick={open}
                size="small"
                className="mr-2"
              >
                {t(
                  `views.pdf_viewer.import.${
                    pdfDocument ? "replace" : "document"
                  }`
                )}
              </Button>
              <input {...getInputProps()} tabIndex="0" />
              <ActionButton
                icon={<Trash color="error" className="transparent-2" />}
                onClick={() => showRemoveAlert(true)}
                color="secondary"
                size="small"
                name={t("views.pdf_viewer.import.remove")}
                tooltip
                disabled={!pdfDocument}
                className="p-0"
              />
              <DocumentRemoval
                projectUuid={projectUuid}
                visible={removeAlertVisible}
                onClose={() => showRemoveAlert(false)}
              />
              <Divider orientation="vertical" className={classes.divider} />
              <Alert
                title={t("errors.file.upload_error", { mb: 10 })}
                isOpen={uploadAlertVisible}
                acceptText={t("common.close")}
                onAccept={() => showUploadAlert(false)}
              />
              {/*<PopMenu*/}
              {/*  anchor={importMenuAnchor}*/}
              {/*  show={importMenuVisible}*/}
              {/*  onClickAway={() => showImportMenu(false)}*/}
              {/*>*/}
              {/*  <MenuButton onClick={() => handleImport()}>*/}
              {/*    {t("views.pdf_viewer.import.replace")}*/}
              {/*  </MenuButton>*/}
              {/*  <MenuButton onClick={() => handleImport(true)}>*/}
              {/*    {t("views.pdf_viewer.import.append")}*/}
              {/*  </MenuButton>*/}
              {/*</PopMenu>*/}
            </>
          )}
          <TextField
            size="small"
            variant="outlined"
            className={classes.input}
            value={localCurrentPage}
            label=""
            fullWidth={false}
            onFocus={() => setCurrentPage("")}
            onChange={handleCurrentPageChange}
            onBlur={handlePageInputBlur}
          />
          <span
            className="text-md"
            style={{
              minWidth: numPages.toString().length + 2 + "ch",
            }}
          >
          {"/ " + numPages}
        </span>
          <IconButton
            onClick={handleScaleButton(-1)}
            disabled={!pdfDocument}
          >
            <Remove />
          </IconButton>
          <TextField
            select
            variant="outlined"
            className={classes.select}
            value={scaleValue}
            label=""
            min={1}
            max={pdfPages.length}
            onChange={handleScaleChange}
            disabled={!pdfDocument}
          >
            {Object.values(SCALE_PRESETS).includes(
              scaleValue
            ) && (
              <MenuItem value={scaleValue} className="hidden">
                {Math.round(scale * 100)}%
              </MenuItem>
            )}
            <MenuItem value={0.5}>50%</MenuItem>
            <MenuItem value={0.75}>75%</MenuItem>
            <MenuItem value={1}>100%</MenuItem>
            <MenuItem value={1.25}>125%</MenuItem>
            <MenuItem value={1.5}>150%</MenuItem>
            <MenuItem value={2}>200%</MenuItem>
            <MenuItem value={3}>300%</MenuItem>
            <MenuItem value={4}>400%</MenuItem>
          </TextField>
          <IconButton
            onClick={handleScaleButton(1)}
            disabled={!pdfDocument}
          >
            <Add />
          </IconButton>
          <Divider orientation="vertical" className={classes.divider} />
          <Tooltip
            title={t(
              `views.pdf_viewer.scale.${
                scaleValue === "page-width" ? "page_width" : "page_fit"
              }`
            )}
          >
          <span>
            <IconButton
              onClick={handleScaleSet(
                scaleValue === "page-fit" ? "page-width" : "page-fit"
              )}
              disabled={!pdfDocument}
            >
              {scaleValue === "page-width" ? (
                <AspectRatioHorizontal />
              ) : (
                <AspectRatioVertical />
              )}
            </IconButton>
          </span>
          </Tooltip>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
          className={classes.buttonContainer}
        >
          {!noShareActions && (
            <>
              <PdfExport
                attachCover
                size="medium"
                simpleExport={!proposalSharing}
              />
              {hasSharePrivileges && (
                <Share
                  pdfShare={!proposalSharing}
                  size="medium"
                  className="m-1"
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
      <FeatureAlert />
    </Paper>
  );
});

ToolBar.propTypes = {
  proposalSharing: bool,
  noThumbButton: bool,
  noFileAppends: bool,
  isObserver: bool,
  hasSharePrivileges: bool,
  sidebarVisible: bool,
  handleShowSidebar: func.isRequired,
};
