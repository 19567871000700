import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  frame: {
    border: "1px solid" + theme.palette.text.primary,
    borderRadius: 5,
    padding: 16,
    cursor: "pointer",
    flexShrink: 0,
    flexWrap: "nowrap",
  },
  templateContainer: {
    height: 92,
    width: 92,
    minWidth: 92,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    cursor: "pointer",
    position: "relative",
    "& > img": {
      height: "100%",
    },
    "& > svg": {
      height: "100%",
      width: "100%",
      position: "absolute",
      left: 0,
    },
  },
  templateDescription: {
    marginLeft: 24,
    justifyContent: "center",
    width: "calc(100% - 124px)",
  },
  templateTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 4,
  },
  templateSubtitle: {
    fontSize: 14,
    opacity: 0.75,
    width: "100%",
    // height: 18,
    // overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default useStyle;
