import { makeStyles } from "@material-ui/core/styles";
import { CELL_HEIGHTS } from "@utils";

const useStyle = makeStyles(( theme ) => ({
  
  container: {
    height: CELL_HEIGHTS.XXSMALL,
    paddingLeft: 8,
    paddingRight: 8,
    position: "absolute",
    transition: "transform 250ms ease",
    zIndex: 2,
  },
  containerExceeds: {
    paddingRight: 0,
  },
  block: {
    cursor: "pointer",
    height: 24,
    width: "100%",
    backgroundColor: theme.props.billingColor,
    color: theme.props.text.alt,
    paddingLeft: 8,
    borderRadius: 10,
    fontSize: 12,
    textTransform: "uppercase",
    overflow: "hidden",
    textAlign: "left",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    lineHeight: "24px",
  },
  exceeds: {
    background: `linear-gradient(to left, transparent, ${theme.palette.primary.main} 24px)`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default useStyle;