import { useState } from "react";
import { bool, number, oneOfType, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { Grid, IconButton } from "@material-ui/core";
import { TooltipIcon, Dialog } from "@components";
import { Close } from "@material-ui/icons";
import { Settings } from "@assets";
import { EditorContainer } from "./components/EditorContainer";
import { SectionContainer } from "./components/SectionContainer/SectionContainer";
import { NonSectionWTContainer } from "./components/SectionContainer/NonSectionWTContainer";
import classnames from "classnames";

export const TimelineSettings = observer(({
  small,
  className,
  ...containerProps
}) => {
  const { t } = useTranslation();
  const {
    settings,
    sections,
    workTypesHaveSummaryOverwrittenHours
  } = useStructureStore();
  const { useTimelineSections, isTimelineTableUsed } = settings;

  const [visible, show] = useState(false);

  const handlePopup = () => show(!visible);
  const handleClose = () => show(false);

  return (
    <>
      <TooltipIcon
        icon={<Settings />}
        onClick={handlePopup}
        style={{ right: small && 12 }}
        color="primary"
        className={classnames(className, small && "absolute text-sm")}
      >
        {t("views.editor.dialogs.timeline_settings.wta_change")}
      </TooltipIcon>

      <Dialog
        open={visible}
        onClose={handleClose}
        width={containerProps.fullSettings ? 920 : 620}
      >
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          className="pb-3"
        >
          <Grid item container alignItems="center" className="w-max">
            <h3>{t("views.editor.dialogs.timeline_settings.wta_change")}</h3>
            <TooltipIcon color="primary">
              {t("views.editor.dialogs.timeline_settings.wta_tooltip")}
            </TooltipIcon>
          </Grid>
          <IconButton
            color="primary"
            size="small"
            aria-label="close modal"
            onClick={handlePopup}
          >
            <Close />
          </IconButton>
        </Grid>
        {
          useTimelineSections && isTimelineTableUsed
            ? sections.map((section, i) => (
              <SectionContainer
                key={section.id}
                section={section}
                isFirst={i === 0}
                {...containerProps}
              />
            ))
            : <EditorContainer {...containerProps} />
        }
        {
          useTimelineSections && isTimelineTableUsed && workTypesHaveSummaryOverwrittenHours &&
          <NonSectionWTContainer {...containerProps} />
        }
      </Dialog>
    </>
  );
});

TimelineSettings.propTypes = {
  small: bool,
  fullSettings: bool,
  className: string,
  selectedWorkType: number,
  selectedResource: oneOfType([string, number]),
}