import { useState } from "react";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@components";
import { PasswordChangeForm } from "@forms";
import { resetPasswordQuery } from "@query";

export const Password = () => {
  const { t } = useTranslation();
  const { userStore } = useStores();

  const [alertText, setAlertText] = useState(null);

  const changePassword = async ({ password, currentPassword }) => {
    try {
      await resetPasswordQuery({
        code: currentPassword,
        email: userStore.data.email,
        password,
      });
      setAlertText("views.settings.password_changed");
      return { reset: true };
    } catch (e) {
      setAlertText("errors.password.current_not_valid");
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
    >
      <h2 className="mb-4">{t("views.settings.menu.passwd_full")}</h2>
      <PasswordChangeForm onSubmit={changePassword} />
      <Alert
        isOpen={Boolean(alertText)}
        title={t(alertText)}
        onAccept={() => setAlertText(null)}
        acceptText={t("common.close")}
      />
    </Grid>
  );
};
