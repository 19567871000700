import { registerLocale, getNames } from "i18n-iso-countries";
import countriesEN from "i18n-iso-countries/langs/en.json";

export const getCountries = (lang) => {
  registerLocale(countriesEN);
  const countriesObj = getNames(lang);
  return Object.keys(countriesObj).map((code) => ({
    code,
    name: countriesObj[code],
  }));
};
