import { SvgIcon } from "@material-ui/core";

export const CircleCheck = (props) => (
  <SvgIcon {...props} viewBox="0 0 9 10">
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.684822 5.07635C0.684822 2.96932 2.39291 1.26124 4.49994 1.26124H5.74721C5.93633 1.26124 6.08965 1.10792 6.08965 0.918797C6.08965 0.729671 5.93633 0.576355 5.74721 0.576355H4.49994C2.01466 0.576355 -6.10352e-05 2.59107 -6.10352e-05 5.07635C-6.10352e-05 7.56164 2.01466 9.57635 4.49994 9.57635C6.98522 9.57635 8.99994 7.56163 8.99994 5.07635V4.86848C8.99994 4.67935 8.84662 4.52604 8.6575 4.52604C8.46837 4.52604 8.31506 4.67935 8.31506 4.86848V5.07635C8.31506 7.18338 6.60697 8.89147 4.49994 8.89147C2.39291 8.89147 0.684822 7.18339 0.684822 5.07635ZM8.48496 2.82416C8.61869 2.69042 8.61869 2.4736 8.48496 2.33987C8.35123 2.20614 8.13441 2.20614 8.00067 2.33987L4.48541 5.85514L3.06349 4.61096C2.92116 4.48642 2.70482 4.50084 2.58028 4.64317C2.45574 4.7855 2.47016 5.00184 2.61249 5.12638L4.27551 6.58153C4.41119 6.70025 4.61568 6.69344 4.74316 6.56596L8.48496 2.82416Z"
      fill="currentColor"
    />
  </SvgIcon>
);