import { db } from "@client";
import axios from "axios";
import { home } from "@paths";

const logoutErrors = {
  400: ["wrong number of segments"],
  401: ["signature verification failed", "invalid token", "expired token"],
}
const homeRedirectErrors= {
  403: ["not authorized"]
}

export const fetchDataLogoutHandler = (error) => {
  const s = error.response?.data?.status,
    m = error.response?.data?.error?.[0]?.toLowerCase();
  
  if(s && m) {
    if(homeRedirectErrors[s]?.includes(m) && window.location.href !== window.location.origin+home)
      window.location.replace(window.location.origin+home);
    if( logoutErrors[s]?.includes(m) ) {
      delete axios.defaults.headers.common["Authorization"];
      db.user.clear().then(() => {
        if(window.location.origin !== window.location.href.replace(/\/$/, ""))
          window.location.replace(window.location.origin);
      });
    }
  }
  
  return Promise.reject(error);
}