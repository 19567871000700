import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    height: "100%",
    minHeight: 200,
    padding: (isMobile) => (isMobile ? 16 : 12),
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  title: {
    width: "100%",
    marginBottom: 14,
    boxSizing: "border-box",
  },
  container: {
    height: "calc(100% - 37px)",
  },
  droppable: {
    width: "100%",
    paddingBottom: 392
  },
  spinner: {
    position: "absolute",
    top: 64,
    left: "calc(50% - 20px)",
  },
  input: {
    // height: 36,
    overflow: "hidden",
    fontWeight: 600,
    marginLeft: 8,
    "& input, & p": {
      opacity: 0.75,
      textTransform: "uppercase",
    },
  },
  buttonContainer: {
    position: "relative",
    top: -392
  },
}));

export default useStyle;
