import { APIMethod, fetchData } from "@client";

export async function startRecordingSessionQuery(
  projectShareKey,
  customerEmail,
  compressed = false
) {
  const data = {
    uuid: projectShareKey,
    customer: customerEmail,
    type: "proposal",
    compressed: compressed ? 1 : 0,
  };

  const res = await fetchData("/session/save", APIMethod.POST, { data });

  return {
    sessionId: res.results.session,
    ...data,
  };
}
