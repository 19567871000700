import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  paper: {
    width: "100%",
    backgroundColor: theme.props.backgroundSecondary,
    borderRadius: 10,
    overflow: "hidden",
    boxShadow: "none !important",
    border: `1px solid ${theme.props.border}`,
    padding: "32px 24px",
    boxSizing: "border-box",
    marginBottom: 24
  },
  content: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStyle;
