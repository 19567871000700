import { useEffect, useState } from "react";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Alert, Link } from "@components";
import { PaymentDialog } from "@dialogs";
import { SubscriptionsTable } from "./components/SubscriptionTable/SubscriptionsTable";
import { Elements } from "@stripe/react-stripe-js";
import { cancelSubscriptionQuery, getCurrentSubscriptionQuery, refreshUserDataQuery } from "@query";
import { pushGTagEvent } from "@utils";
import { home, subscription as subscriptionPath } from "@paths";
import { Container } from "@material-ui/core";

export const Pricing = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const navigate = useNavigate();
  const { stripePromise, subscriptionInfo } = userStore;
  const { premium, premiumUsers, premiumProjects } = subscriptionInfo;

  const [modalIsOpened, showModal] = useState(false);
  const [cancelAlertVisible, showCancelAlert] = useState(false);
  const [changeAlertVisible, showChangeAlert] = useState(false);
  const [changeAlertInfo, setChangeAlertInfo] = useState([]);
  // const [showUpdateAlert, setUpdateAlert] = useState(false);
  const [chosenSubscription, setSub] = useState(null);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    (async () => {
      const s = await getCurrentSubscriptionQuery();
      if(s && !s.name)
        s.name = t("plan.default")
      setSubscription(s);
    })();
  }, []);

  const setSubscriptionData = (
    code,
    name,
    stripePriceId,
    price,
    quantity,
    estimates,
    duration
  ) => {
    if (
      subscription &&
      (subscription.usedEstimates > estimates ||
        subscription.usedUsers > quantity)
    ) {
      setChangeAlertInfo(
        [
          subscription.usedEstimates > estimates && "estimates",
          subscription.usedUsers > quantity && "users",
        ].filter((e) => e)
      );
      showChangeAlert(true);
      return;
    }

    if (code === "package_free") {
      if (subscription) showCancelAlert(true);
      else navigate(subscriptionPath);
      return;
    }

    setSub({ code, name, stripePriceId, price, quantity, duration });
    showModal(true);

    // if(subscription) setUpdateAlert(true);
  };

  const cancelSubscription = async () => {
    showCancelAlert(false);
    try {
      await cancelSubscriptionQuery();
      const userData = await refreshUserDataQuery();
      userStore.saveUserData(userData);
      navigate(subscriptionPath);
    } catch (e) {
      console.log(e); // @todo: proper error handling
    }
  };

  // const updateSubscription = async () => {
  //   setUpdateAlert(false);
  //   try {
  //     await updateSubscriptionQuery(chosenSubscription.stripe_price_id, chosenSubscription.users || chosenSubscription.projects);
  //   } catch(e) {
  //     console.log(e);
  //   }
  // };

  const handlePaymentModalClose = async (success = false) => {
    showModal(false);
    if (success) {
      pushGTagEvent("subscription_success");
      setSub(null);
      const userData = await refreshUserDataQuery();
      userStore.saveUserData(userData);
      navigate(subscriptionPath);
    }
  };

  const handleChangeAlertClose = () => {
    showChangeAlert(false);
    setChangeAlertInfo([]);
  };

  return (
    <Elements stripe={stripePromise}>
      <Container className="pt-8">
        <SubscriptionsTable
          currentSub={premium}
          currentUsers={premiumUsers}
          currentProjects={premiumProjects}
          currentDuration={subscription?.duration}
          onSubSelect={setSubscriptionData}
          freeSelect
        />
        {Boolean(subscription) && (
          <Alert
            isOpen={cancelAlertVisible}
            title={t("payments.subscription.cancel")}
            onCancel={() => showCancelAlert(false)}
            onAccept={cancelSubscription}
            acceptText={t("common.accept_full")}
            cancelText={t("common.cancel_full")}
          >
            {t("payments.subscription.cancel_info", {
              date: format(subscription.to, "dd MMM yyyy"),
            })}
          </Alert>
        )}
        {changeAlertVisible && (
          <Alert
            isOpen={changeAlertVisible}
            title={t("payments.subscription.upgrade_error")}
            onAccept={handleChangeAlertClose}
            acceptText={t("common.close")}
          >
            <p>
              {t("payments.subscription.upgrade_error_info.text")}
              {changeAlertInfo.map((e, i) => (
                <>
                  {i === 1 && ` ${t("common.and")}`}
                  <Link
                    key={e}
                    to={e === "users" ? subscriptionPath : home}
                    className="w-max"
                  >
                    {` ${t(`payments.subscription.upgrade_error_info.${e}`)}`}
                  </Link>
                </>
              ))}
              {"."}
            </p>
          </Alert>
        )}
        {/*{*/}
        {/*  chosenSubscription && subscription &&*/}
        {/*	<Alert*/}
        {/*	  isOpen={showUpdateAlert}*/}
        {/*	  title={t("payments.subscription.update", {name: chosenSubscription.name})}*/}
        {/*	  onCancel={() => setUpdateAlert(false)}*/}
        {/*	  onAccept={updateSubscription}*/}
        {/*	  acceptText={t("common.accept_full")}*/}
        {/*	  cancelText={t("common.cancel_full")}*/}
        {/*	>*/}
        {/*	  {t("payments.subscription.update_higher", {*/}
        {/*	    date: format(add(new Date(), {[chosenSubscription.duration+"s"]: 1}), "dd MMM yyyy"),*/}
        {/*	    name: chosenSubscription.name,*/}
        {/*	    price: chosenSubscription.price_usd,*/}
        {/*	  })}*/}
        {/*	</Alert>*/}
        {/*}*/}
        {Boolean(chosenSubscription) && (
          <PaymentDialog
            open={modalIsOpened}
            productInfo={chosenSubscription}
            onClose={handlePaymentModalClose}
            isUpdate={Boolean(subscription)}
            defaultPaymentMethod={subscription?.paymentMethod}
          />
        )}
      </Container>
    </Elements>
  );
});
