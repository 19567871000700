import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  getCompanyDetailsQuery, getCompanySettingsQuery,
  removeCompanyLogoQuery,
  setCompanyDetailsQuery,
  setCompanySettingsQuery,
  updateCompanyLogoQuery
} from "@query";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { toBase64, vatTypes } from "@utils";
import { DEFAULT_CURRENCY_KEY } from "project-structure";
import imageCompression from "browser-image-compression";
import { COMPANY_SETTINGS } from "@client";
import { CompanyDetailsForm } from "@forms";
import { Button, Alert, StaticImg } from "@components";
import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import { Trash } from "@assets";
import { Add } from "@material-ui/icons";
import useStyle from "./SettingsViews.style";
import classnames from "classnames";

export const Company = observer(() => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isLoading, setLoading] = useState(false);
  const [alertText, setAlertText] = useState();
  const [companyLogo, setCompanyLogo] = useState();
  const [fileErrorMessage, setFileErrorMessage] = useState();
  const [defaultValues, setDefaultValues] = useState();

  const onDropAccepted = async (files) => {
    if (!isLoading && files.length) {
      setLoading(true);
      const compressedFile = await imageCompression(files[0], {
        maxSizeMB: 0.75,
        maxWidthOrHeight: 2048,
        useWebWorker: true,
        fileType: "image/jpeg",
      });
      const b64Logo = await toBase64(compressedFile);
      await updateCompanyLogoQuery(b64Logo);
      setCompanyLogo(b64Logo);
      setLoading(false);
    }
  };

  const onDropRejected = (fileRejections) => {
    if (fileRejections?.[0]?.errors?.[0])
      setFileErrorMessage(fileRejections[0].errors[0].message);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDropRejected,
    // maxSize: 800*1024,
    accept: [".png", ".jpg", ".jpeg"],
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const currency = await getCompanySettingsQuery(COMPANY_SETTINGS.COMPANY_CURRENCY);
      const values = await getCompanyDetailsQuery();
      const { logo, ...rest } = values;
      setCompanyLogo(logo);
      setDefaultValues({ ...rest, currency: currency || DEFAULT_CURRENCY_KEY });
      setLoading(false);
    })();
  }, []);

  const removeLogo = async () => {
    setLoading(true);
    await removeCompanyLogoQuery();
    setCompanyLogo(undefined);
    setLoading(false);
  };

  const changeCompanyDetails = async ( { currency, ...data }) => {
    try {
      await setCompanyDetailsQuery(data);
      await setCompanySettingsQuery(COMPANY_SETTINGS.COMPANY_CURRENCY, currency);
    } catch (e) {
      if (vatTypes.find((v) => e.response?.data?.error?.[0]?.includes(v)))
        setAlertText(t("errors.stripe.invalid_vat"));
      else setAlertText(t("errors.network"));
    }
  };

  return (
    <Grid item container direction="column">
      <h2 className="mb-4">{t("views.settings.menu.company_full")}</h2>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        {...getRootProps({ className: "dropzone relative mb-8" })}
      >
        {companyLogo ? (
          <>
            <StaticImg
              src={companyLogo}
              {...getRootProps({ className: "dropzone" })}
              className={classnames(classes.avatar, classes.companyAvatar, isLoading && "transparent-2")}
            />
            {isLoading && (
              <CircularProgress
                color="primary"
                size={24}
                className={classes.avatarProgress}
              />
            )}
          </>
        ) : (
          <IconButton
            className={`${classes.addAvatar} ${classes.companyAvatar}`}
            onClick={open}
          >
            {isLoading ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              <Add color="primary" />
            )}
          </IconButton>
        )}
        <Button
          onClick={open}
          variant="contained"
          color="secondary"
          icon={<Add color="primary" />}
          className={classes.actionButton}
        >
          {t("views.settings.photo_upload")}
        </Button>
        <Button
          onClick={removeLogo}
          variant="contained"
          color="secondary"
          icon={<Trash color="error" />}
          disabled={!companyLogo}
          className={classes.actionButton}
        >
          {t("common.delete")}
        </Button>
      </Grid>

      <CompanyDetailsForm
        onSubmit={changeCompanyDetails}
        defaultValues={defaultValues}
        useCurrency
        useCancel
      />
      <input {...getInputProps()} id="logo" />

      <Alert
        isOpen={Boolean(alertText)}
        title={alertText}
        onAccept={() => setAlertText(undefined)}
        acceptText={t("common.close")}
      />
      <Alert
        isOpen={!!fileErrorMessage}
        title={fileErrorMessage}
        acceptText={t("common.close")}
        onAccept={() => setFileErrorMessage()}
      />
    </Grid>
  );
});
