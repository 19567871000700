import { useEffect, useMemo } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import ResizeObserver from "react-resize-observer";
import {
  useStructureStore,
  usePresetFieldNames,
  useTableOverflowChecker,
  useTableExpandHandler,
} from "@hooks";
import { TableContainer, Grid, Collapse } from "@material-ui/core";
import { TableTitle } from "@components";
import {
  Tables,
  hashCode,
  DEFAULT_PROFITABILITY_COLUMNS,
} from "project-structure";
import useStyle from "../commonStyles.style";
import classnames from "classnames";
import { calculateMaxSummaryTitleCellWidth } from "@utils";
import { TableHeader } from "./components/TableHeader/TableHeader";
import { BudgetTrackingSummaryRowGroup } from "./components/BudgetTrackingSummaryRowGroup/BudgetTrackingSummaryRowGroup";

export const BudgetTrackingSummary = observer(
  ({
    hasEditorPrivileges,
    hasSellerPrivileges,
    useClientActions,
    isProposal,
    readOnly,
    tableBreakdownNext,
    tableBreakdownPrev,
    useProfitability,
    jiraProject,
  }) => {
    // values & systems from dataStore
    const structure = useStructureStore();
    const { getFieldName } = usePresetFieldNames(isProposal);
    const classes = useStyle();

    const { settings, workTypesHaveResources, btWorkTypes } = structure;
    const {
      showSummary,
      useMinMax,
      currencyObj,
      visibleSummaryColumns,
      hasTeamMembersCol,
    } = settings;

    const allowEdition = useMemo(
      () => !readOnly && hasEditorPrivileges,
      [readOnly, hasEditorPrivileges]
    );

    const isSellerOrClient = useMemo(
      () => isProposal || hasSellerPrivileges,
      [isProposal, hasSellerPrivileges]
    );

    const blockExpansions = useMemo(
      () => (readOnly && !useClientActions) || useProfitability,
      [readOnly, useClientActions, useProfitability]
    );

    const useExpandCell = useMemo(
      () =>
        hasTeamMembersCol &&
        ((!readOnly && !useProfitability) || workTypesHaveResources),
      [hasTeamMembersCol, readOnly, workTypesHaveResources]
    );

    const columns = useMemo(
      () =>
        useProfitability
          ? DEFAULT_PROFITABILITY_COLUMNS
          : visibleSummaryColumns,
      [useProfitability, visibleSummaryColumns]
    );

    const jiraListColumns = ["cost", "hours", "rate"];

    const columnsJira = useMemo(() =>
      jiraProject
        ? columns.filter((column) => jiraListColumns.includes(column.name))
        : columns
    );

    const maxTitleCellWidth = useMemo(
      () =>
        calculateMaxSummaryTitleCellWidth(
          columns,
          useMinMax,
          hasSellerPrivileges,
          useClientActions
        ),
      [columns, useMinMax, hasSellerPrivileges, useClientActions]
    );

    const tableContainerIdentifier = useMemo(() => "summaryContainer", []);
    const tableIdentifier = useMemo(() => "summaryHeader", []);

    const { onResize, isTableContentOverflowing } = useTableOverflowChecker(
      tableContainerIdentifier,
      tableIdentifier
    );

    useEffect(() => {
      onResize();
    }, [useMinMax]);

    const { handleOpen, ...collapseProps } = useTableExpandHandler(
      true,
      showSummary,
      settings.setShowSummary
    );

    return (
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classnames(
          "preset-paper",
          classes.whiteContainer,
          tableBreakdownNext && classes.whiteContainerLast,
          tableBreakdownPrev && classes.whiteContainerFirst
        )}
      >
        <Grid item container justifyContent="flex-end">
          <TableTitle
            expandable={!blockExpansions}
            presetClass="summary"
            id={hashCode(Tables.SUMMARY)}
            title={getFieldName("pt-summary")}
            showContent={showSummary}
            onContentAction={handleOpen}
          ></TableTitle>
          <Collapse
            in={showSummary || useProfitability}
            timeout="auto"
            mountOnEnter
            unmountOnExit
            className={classes.collapse}
            data-id={hashCode("cont")}
            data-t="summary"
            data-p={hashCode(Tables.SUMMARY)}
            {...collapseProps}
          >
            <Grid
              container
              justifyContent="center"
              wrap="nowrap"
              direction="column"
            >
              <TableContainer
                className={classnames(
                  "pb-3",
                  classes.tableContainer,
                  isTableContentOverflowing && classes.overflows
                )}
                id={tableContainerIdentifier}
              >
                <Grid container role="rowgroup" direction="column">
                  <ResizeObserver onResize={onResize} />
                  <TableHeader
                    tableIdentifier={tableIdentifier}
                    isProposal={isProposal}
                    useClientActions={useClientActions}
                    showPrices={isSellerOrClient}
                    currencyCode={currencyObj.code}
                    allowEdition={allowEdition}
                    columns={columnsJira}
                    maxTitleCellWidth={maxTitleCellWidth}
                    useExpandCell={useExpandCell}
                    useProfitability={useProfitability}
                    isTableContentOverflowing={isTableContentOverflowing}
                    scrollTargetId={tableContainerIdentifier}
                  />
                  {btWorkTypes.map((workType) => (
                    <BudgetTrackingSummaryRowGroup
                      key={workType.id}
                      workType={workType}
                    />
                  ))}
                </Grid>
              </TableContainer>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    );
  }
);

BudgetTrackingSummary.propTypes = {
  hasEditorPrivileges: bool,
  hasSellerPrivileges: bool,
  isProposal: bool,
  useClientActions: bool,
  useProfitability: bool,
  tableBreakdownNext: bool,
  tableBreakdownPrev: bool,
  jiraProject: bool,
};
