import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { setSelectionColor, coordsToIdentifier } from "@utils";
import { useProjectCreatorStore } from "@hooks";
import { TextField } from "@components";
import { Grid, useTheme } from "@material-ui/core";
import { Spreadsheet } from "../../Spreadsheet/Spreadsheet";
import useStyle from "./Steps.style";

export const Mappings = observer(() => {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyle();
  const creator = useProjectCreatorStore();

  const {
    currentSheet,
    breakdownSelection
  } = creator;
  
  const spreadsheet = useRef(null);

  const [breakdownAreaVal, setBreakdownAreaVal] = useState(
    breakdownSelection?.length
      ? coordsToIdentifier(breakdownSelection[0], breakdownSelection[1]) +
          ":" +
          coordsToIdentifier(breakdownSelection[2], breakdownSelection[3])
      : ""
  );
  
  const setSpreadsheet = (s) => {
    spreadsheet.current = s;
  }

  // useEffect(() => {
  //   return () => {
  //     spreadsheet.current = null;
  //   };
  // }, []);

  const handleSelection = (instance, x1, y1, x2, y2) => {
    creator.setBreakdownSelection([x1, y1, x2, y2]);
    setBreakdownAreaVal(
      coordsToIdentifier(x1, y1) + ":" + coordsToIdentifier(x2, y2)
    );
  };

  const updateSelectionColors = () => {
    if (creator.previousBreakdownSelection.length) {
      let [x1, y1, x2, y2] = creator.previousBreakdownSelection;
      setSelectionColor(
        [...Array(y2 - y1 + 1).keys()].map((y) => y + y1),
        [...Array(x2 - x1 + 1).keys()].map((x) => x + x1),
        null,
        spreadsheet.current
      );
    }

    let [x3, y3, x4, y4] = creator.breakdownSelection;
    setSelectionColor(
      [...Array(y4 - y3 + 1).keys()].map((y) => y + y3),
      [...Array(x4 - x3 + 1).keys()].map((x) => x + x3),
      theme.props.selections.level2,
      spreadsheet.current
    );
  };

  const getSelectionColor = () => {
    const [x1, y1, x2, y2] = breakdownSelection;
    return setSelectionColor(
      [...Array(y2 - y1 + 1).keys()].map((y) => y + y1),
      [...Array(x2 - x1 + 1).keys()].map((x) => x + x1),
      theme.props.selections.level2
    );
  };

  const saveOldSelection = () => {
    creator.setPreviousBreakdownSelection([...breakdownSelection]);
  };

  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      className="overflow-hidden px-6"
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        className="w-full mb-4"
      >
        <p className="mr-4">{t("views.import.breakdown_area")}</p>
        <TextField
          className={classes.textField}
          value={breakdownAreaVal}
          disabled
          onChange={(e) => setBreakdownAreaVal(e.target.value)}
        />
      </Grid>
      {!!currentSheet && (
        <Spreadsheet
          spreadsheet={spreadsheet.current}
          title={currentSheet.title}
          data={currentSheet.data.slice()}
          colWidths={currentSheet.colWidths.slice()}
          columns={currentSheet.columns.slice()}
          mergeCells={currentSheet.mergeCells}
          minDimensions={currentSheet.minDimensions.slice()}
          onRangeSelect={handleSelection}
          onMouseUp={updateSelectionColors}
          onMouseDown={saveOldSelection}
          initialCellStyles={breakdownSelection?.length ? getSelectionColor(false, false) : {}}
          setSpreadsheet={setSpreadsheet}
        />
      )}
    </Grid>
  );
});
