import { isMobile } from "react-device-detect";
import { func } from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Button } from "@components";
import { FileAlt } from "@assets";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

export const BlankTemplateCard = ({ onSelect }) => {
  const { t } = useTranslation();
  const { blankBorder } = useBlankBorderStyle();

  return (
    <Grid
      item
      container
      xs={isMobile ? 12 : 6}
      md={isMobile ? 12 : 4}
      className="p-3"
      onClick={onSelect}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classnames(
          blankBorder,
          "p-4 rounded-2 border-dashed-2 cursor-pointer"
        )}
      >
        <FileAlt style={{ fontSize: 64 }} color="primary" />
        <p className="mb-4">{t("views.new_project.blank")}</p>
        <p className="mb-4 transparent-2">
          {t("views.new_project.blank_desc")}
        </p>
        <Button variant="contained" color="primary">
          {t("common.choose")}
        </Button>
      </Grid>
    </Grid>
  );
};

BlankTemplateCard.propTypes = {
  onSelect: func.isRequired,
};

const useBlankBorderStyle = makeStyles((theme) => ({
  blankBorder: {
    borderColor: theme.props.primary.lighter,
    backgroundColor: theme.props.primary.lightest,
  },
}));
