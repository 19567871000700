import { useEffect, useState } from "react";
import { loginRequest, MSAL_PROGRESS } from "@client";
import { authorizeMsSsoUserQuery } from "@query";
import { useMsal } from "@azure/msal-react";
import { useStores } from "./stores";

export const useMsalLogin = (isLoading, setLoading) => {
  
  const { accounts, instance, inProgress } = useMsal();
  const { userStore } = useStores();
  
  const [msalAlertVisible, showMsalAlert] = useState(false);
  
  useEffect(() => {
    if (
      inProgress === MSAL_PROGRESS.NONE &&
      accounts?.[0] &&
      !userStore.isLoggedIn &&
      !isLoading
    )
      handleMsalLogin();
  }, [isLoading, accounts, inProgress, userStore.isLoggedIn]);
  
  const handleMsalLogin = async () => {
    setLoading(true);
    try {
      const msRes = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const userData = await authorizeMsSsoUserQuery(msRes?.accessToken);
      userStore.saveUserData(userData);
      userStore.checkWorkspacesAndRedirect();
    } catch (e) {
      console.error(e);
      showMsalAlert(true);
      // instance?.getTokenCache()?.storage?.clear();
      accounts?.splice(0,1);
      userStore.clearUserData();
    }
    
    userStore.setLoginProcessStatus(true);
    setLoading(false);
  };
  
  const closeMsalAlert = () => {
    showMsalAlert(false);
  }
  
  return {
    msalAlertVisible,
    closeMsalAlert
  }
}