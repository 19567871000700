import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { HtmlParser } from "@utils";
import { login, terms } from "@paths";
import { AppContainer, AppToolBar, PageTitle } from "@components";
import { Container, Grid, Paper, CircularProgress } from "@material-ui/core";
import useStyle from "./Legal.style";
import { getPolicyArticleQuery, getTermsArticleQuery } from "@query";

export const Legal = observer(() => {
  const classes = useStyle(isMobile);
  const location = useLocation();

  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      const d =
        location.pathname === terms
          ? await getTermsArticleQuery()
          : await getPolicyArticleQuery();
      setData(d);
    })();
  }, []);

  return (
    <>
      <AppToolBar noActions iconLink={login} />
      <AppContainer>
        <PageTitle />
        <Container>
          <Paper className={classes.paper}>
            <Grid container justifyContent="center" wrap="nowrap">
              {!data ? (
                <CircularProgress />
              ) : (
                <p className={classes.content}>
                  {HtmlParser(
                    data.content
                      .replace(/(\\r\\n|\\n|\\r)/gm, "<br>")
                      .replace(/(\\t)/gm, "   ")
                  )}
                </p>
              )}
            </Grid>
          </Paper>
        </Container>
      </AppContainer>
    </>
  );
});
