import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  handle: {
    fontSize: 30,
    cursor: "grab",
    padding: 3,
    color: theme.palette.text.primary,
  },
  absolute: {
    position: "absolute",
    left: 3,
  },
  backgroundPrimary: {
    backgroundColor: theme.props.background,
  },
}));

export default useStyle;
