import { node, number, oneOfType, string } from "prop-types";
import { Avatar } from "@components";
import Grid from "@material-ui/core/Grid";

export const UserItem = ({ children, ...userData }) => (
  <Grid item container alignItems="center">
    <Avatar color={userData.avatarColor} {...userData} />
    <span className="text-md ml-3 font-initial">
      {children}
    </span>
  </Grid>
);

UserItem.propTypes = {
  id: number.isRequired,
  email: string.isRequired,
  children: oneOfType([string, node]).isRequired,
  avatar: string,
  avatarColor: string,
  borderColor: string,
  fullname: string,
};
