import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, DeleteObjectCommand } from "@aws-sdk/client-s3";

const clientWest = new S3Client({
  region: process.env.REACT_APP_S3_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  },
});
const clientCentral = new S3Client({
  region: process.env.REACT_APP_S3_REGION2,
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
  },
});

export const uploadFontToS3 = async (Key, Body, ContentType, onProgress) => (
  await uploadToS3(Key, process.env.REACT_APP_S3_FONT_BUCKET, Body, ContentType, onProgress, true)
)

export const uploadCompanyFileToS3 = async (Key, Body, ContentType, onProgress) => (
  await uploadToS3(Key, process.env.REACT_APP_S3_BUCKET, Body, ContentType, onProgress, false, true)
)

export const uploadToS3 = async (Key, Bucket, Body, ContentType, onProgress, useCentralRegion=false, useAcl=false) => {
  
  try {
    const action = new Upload({
      client: useCentralRegion ? clientCentral : clientWest,
      params: {
        Bucket,
        Key,
        Body,
        ContentType,
        ACL: useAcl ? "public-read" : undefined,
      },
    });
    
    if (onProgress) {
      action.on("httpUploadProgress", onProgress);
      // action.on("httpUp", onProgress);
    }

    return await action.done();
  } catch (e) {
    console.error(e);
  }
};

export const deleteFromS3 = async (Key) => {
  const input = {
    Bucket: process.env.REACT_APP_S3_BUCKET,
    Key
  };
  return await clientWest.send(new DeleteObjectCommand(input));
}