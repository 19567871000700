import { bool, number, object, arrayOf } from "prop-types";
import { parsePrice, roundFloat } from "project-structure";
import { usePresetFieldNames } from "@hooks";
import { chunk } from "lodash";

export const ExportTimelineBilling = ({
  editorStore,
  timelineBilling,
  roundPrice,
  currencyObj,
}) => {
  const { getFieldName } = usePresetFieldNames(true, editorStore);
  
  const chunkedTimelineBilling = chunk(timelineBilling || [], 4)
    .map(weeks => ({
      month: weeks.reduce((t, w) => t + w, 0),
      weeks,
    }));
  
  return (
    <tr className="timeline">
      <td
        className="timeline-name"
        style={{ textAlign: "left", paddingLeft: 16 }}
      >
        {getFieldName("pt-billing")}
      </td>
      {
        chunkedTimelineBilling.map(({ month }, i) => {
          const value = roundFloat(month, roundPrice ? 3 : 0, !roundPrice);
          return (
            <td key={`period${i}`}>
              {
                Boolean(value) && <>
                  {currencyObj.symbolStart || ""}
                  {
                    parsePrice(value)
                  }
                  {currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : ""}
                </>
              }
            </td>
          )
        })}
    </tr>
  );
};

ExportTimelineBilling.propTypes = {
  editorStore: object.isRequired,
  currencyObj: object.isRequired,
  timelineBilling: arrayOf(number).isRequired,
  roundPrice: bool,
};
