import { bool, number, string, object, func, arrayOf, shape } from "prop-types";
import { parsePrice, roundFloat } from "project-structure";
import classnames from "classnames";
import { Columns } from "project-structure";

export const ExportWorkType = ({
  translate,
  element,
  currencyObj,
  hasTeamMembersCol,
  visibleSummaryColumns,
  hasTimeCol,
  allowShowingPrices,
  useMinMax,
  visibility,
  depth = 1,
}) => {
  const {
    name,
    inBreakdown,
    parsedHours,
    parsedPrice,
    parsedRate,
    showPriceMax,
    showHoursMax,
    hasOnlyCost,
    time,
    teamMembers,
    totalTeamMembers,
    showTimeMax,
    isOpened,
    hasResources,
    resources,
    turnOff,
    roundHours,
    roundPrice,
    roundType,
    usedRoundDecimals,
  } = element;
  
  const {
    apply,
    hideSummaryTime,
    hideSummaryTeam,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryCost,
    hideMinValues,
    hideMaxValues,
  } = visibility;
  
  const showDecimals = roundType !== 2 || !roundHours;
  const opened = hasTeamMembersCol && hasResources && isOpened;

  return (
    <>
      <tr className={classnames("system", opened && "hover")}>
        <td
          className={classnames("summary-name", turnOff && "off")}
          style={{ textAlign: "left", paddingLeft: 16 * depth }}
        >
          {name || translate("common.unnamed")}
        </td>
        {visibleSummaryColumns.map(({ name }) => {
          switch (name) {
            case Columns.TEAM:
              if(apply && hideSummaryTeam) return <></>
              return (
                <td
                  key={name}
                  className={classnames("summary", turnOff && "off")}
                >
                  {!hasOnlyCost && (totalTeamMembers || teamMembers || 1)}
                </td>
              );
            case Columns.TIME:
              if(apply && hideSummaryTime) return <></>
              return (
                <td
                  key={name}
                  className={classnames("summary", turnOff && "off")}
                >
                  { !hasOnlyCost && (!apply || !hideMinValues) && (useMinMax ? time.min : time.avg) }
                  { showTimeMax && (!apply || !(hideMinValues || hideMaxValues)) && "  - " }
                  { !hasOnlyCost && (!apply || !hideMaxValues) && showTimeMax && time.max }
                </td>
              );
            case Columns.RATE:
              if(apply && hideSummaryRate) return <></>
              return (
                allowShowingPrices && (
                  <td
                    key={name}
                    className={classnames("summary", turnOff && "off")}
                  >
                    {!(!inBreakdown && hasOnlyCost) && (
                      <>
                        {currencyObj.symbolStart || ""}
                        {parsedRate}
                        {currencyObj.symbolEnd
                          ? " " + currencyObj.symbolEnd
                          : ""}
                      </>
                    )}
                  </td>
                )
              );
            case Columns.HOURS:
              if(apply && hideSummaryHours) return <></>
              return (
                <td
                  key={name}
                  className={classnames("summary", turnOff && "off")}
                >
                  {(inBreakdown || !hasOnlyCost) && (!apply || !hideMinValues) &&
                    roundFloat(
                      useMinMax ? parsedHours.min : parsedHours.avg,
                      showDecimals ? roundHours : 0,
                      showDecimals, usedRoundDecimals
                    )}
                  {!hasOnlyCost && showHoursMax && (!apply || !(hideMinValues || hideMaxValues)) && "  -  "}
                  {!hasOnlyCost && showHoursMax && (!apply || !hideMaxValues) && roundFloat(
                    parsedHours.max,
                    showDecimals ? roundHours : 0,
                    showDecimals, usedRoundDecimals
                  )}
                </td>
              );
            case Columns.COST:
              if(apply && hideSummaryCost) return <></>
              return (
                allowShowingPrices && (
                  <td key={name} /*className="cell-price"*/>
                    <p className={classnames("price", turnOff && "off")}>
                      {
                        (!apply || !hideMinValues) &&
                        <>
                          {currencyObj.symbolStart || ""}
                          {parsePrice(
                            roundFloat(
                              useMinMax ? parsedPrice.min : parsedPrice.avg,
                              roundPrice ? 3 : 0,
                              !roundPrice,
                            )
                          )}
                          {currencyObj.symbolEnd ? " " + currencyObj.symbolEnd : ""}
                        </>
                      }
                      { showPriceMax && (!apply || !(hideMinValues || hideMaxValues)) && "  -  "}
                      { showPriceMax && (!apply || !hideMaxValues) && (
                        <>
                          {currencyObj.symbolStart || ""}
                          {parsePrice(
                            roundFloat(
                              parsedPrice.max,
                              roundPrice ? 3 : 0,
                              !roundPrice
                            )
                          )}
                          {currencyObj.symbolEnd
                            ? " " + currencyObj.symbolEnd
                            : ""}
                        </>
                      )}
                    </p>
                  </td>
                )
              );
          }
        })}
      </tr>
      {isOpened &&
        hasResources &&
        resources.map((resource) => (
          <ExportWorkType
            translate={translate}
            key={resource.id}
            element={resource}
            currencyObj={currencyObj}
            allowShowingPrices={allowShowingPrices}
            hasTeamMembersCol={hasTeamMembersCol}
            hasTimeCol={hasTimeCol}
            useMinMax={useMinMax}
            depth={depth + 1}
            visibleSummaryColumns={visibleSummaryColumns}
            visibility={visibility}
          />
        ))}
    </>
  );
};

ExportWorkType.propTypes = {
  translate: func.isRequired,
  element: object.isRequired,
  currencyObj: object.isRequired,
  allowShowingPrices: bool,
  hasTeamMembersCol: bool,
  hasTimeCol: bool,
  depth: number,
  useMinMax: bool,
  visibleSummaryColumns: arrayOf(shape({ name: string })),
  visibility: shape({
    apply: bool,
    hideSummaryTime: bool,
    hideSummaryTeam: bool,
    hideSummaryHours: bool,
    hideSummaryRate: bool,
    hideSummaryCost: bool,
  }),
};
