// Material components
import { SvgIcon } from "@material-ui/core";

export default (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3.97411 10.4482L12 18.4741L20.0259 10.4482L18.8188 9.24118L12.8682 15.1918L12.8682 2L11.1318 2L11.1318 15.1918L5.18117 9.24118L3.97411 10.4482Z"
        fill="currentColor"
      />
      <rect x="2" y="20" width="20" height="2" fill="currentColor" />
    </SvgIcon>
  );
};
