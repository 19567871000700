import { SvgIcon } from "@material-ui/core";

export const DescriptionCell = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M0 12H6" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M10.5 16L10.5 8" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M14 16L14 8" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M18 12L24 12" stroke="currentColor" strokeWidth="1.5"/>
    </SvgIcon>
  );
};
