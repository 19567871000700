import { fetchData } from "@client";

export async function getCompanySettingsQuery(key = "") {
  const res = await fetchData(`/company/settings/${key}`);

  if (!res?.results?.[key]) return undefined;

  const safeJsonParse = (str) => {
    try {
      return [null, str === "undefined" ? undefined : JSON.parse(str)];
    } catch (err) {
      return [err];
    }
  };

  let [err, result] = safeJsonParse(res?.results?.[key]);
  if (err) {
    result = res?.results?.[key];
  }
  // this.savedSettings = {
  //   ...this.savedSettings,
  //   ...result
  // };
  return result;
}
