import { SvgIcon } from "@material-ui/core";

export const BarChartSide = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g clipPath="url(#clip0_5447_49430)">
      <path d="M15 8V4L4 4V8H15Z" fill="currentColor"/>
      <path d="M11 20V16H4V20H11Z" fill="currentColor"/>
      <path d="M20 14V10L4 10V14H20Z" fill="currentColor"/>
    </g>
  </SvgIcon>
);