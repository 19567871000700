import { Divider, Grid } from "@material-ui/core";
import useStyle from "./OrDivider.style";

export const OrDivider = () => {
  const classes = useStyle();

  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="center"
    >
      <Divider className={classes.root} />
      {"OR"}
      <Divider className={classes.root} />
    </Grid>
  );
};
