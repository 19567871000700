import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles(() => ({
  visibilityCell: {
    width: CELL_WIDTHS.VISIBILITY,
    minWidth: CELL_WIDTHS.VISIBILITY,
  },
}));

export default useStyle;
