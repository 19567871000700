import { makeStyles } from "@material-ui/core/styles";
import { PATTERN_SRC, REGISTER_BG_SRC } from "@assets";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    zIndex: 2,
  },
  background: {
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
  },
  pattern: {
    backgroundImage: `url(${PATTERN_SRC})`,
  },
  mockup: {
    backgroundImage: `url(${REGISTER_BG_SRC})`,
    filter: "blur(4px)",
  },
  backdropPattern: {
    background: "linear-gradient(to left, #ffffff00 0%, #f5f6fa 91%)",
  },
  backdropMockup: {
    background: "#00000088",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    backgroundColor: theme.palette.background.default,
    height: (isMobile) => (isMobile ? "100%" : "90%"),
    maxHeight: 667,
    width: 810,
    display: "flex",
    flexDirection: (isMobile) => (isMobile ? "column-reverse" : "row"),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: (isMobile) => (isMobile ? 0 : 10),
    overflow: "hidden",
  },
  modal: {
    width: "1000px",
    height: "750px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  part: {
    width: (isMobile) => (isMobile ? "100%" : "50%"),
    height: "100%",
    backgroundColor: theme.props.backgroundSecondary,
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto"
  },
  partNoNavigation: {
    paddingTop: (isMobile) => (isMobile ? 16 : 40),
  },
  logoContainer: {
    backgroundColor: theme.palette.primary.main,
    justifyContent: "center",
    color: theme.palette.text.alt,
    padding: (isMobile) => (isMobile ? 8 : "65px 45px"),
    height: (isMobile) => (isMobile ? "max-content" : ""),
  },
  text: {
    fontSize: 18,
    "& a": {
      cursor: "pointer",
      color: theme.palette.text.alt,
      textDecoration: "none",
      fontWeight: "bold",
    },
  },
  navigation: {
    paddingTop: (isMobile) => (isMobile ? 16 : 40),
    margin: (isMobile) => `0 ${isMobile ? 16 : 40}px 24px !important`,
    position: "sticky",
    top: 0,
    background: theme.props.backgroundSecondary,
    zIndex: 2
  },
  outletWrapper: {
    marginBottom: 40,
    padding: (isMobile) => `0 ${isMobile ? 16 : 40}px`,
    height: "max-content",
  }
}));

export default useStyle;
