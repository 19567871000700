import { forwardRef } from "react";
import { bool, string } from "prop-types";

export const StaticImg = forwardRef(({ src, srcSet, disableCache, ...rest }, ref) => {
  
  const fullSrc = disableCache && src ? `${src}?d=${new Date().getTime()}` : src;
  
  return (
    <img
      {...rest}
      ref={ref}
      alt={src.toString()}
      src={fullSrc}
      srcSet={srcSet}
      draggable={false}
    />
  );
});

StaticImg.propTypes = {
  alt: string,
  title: string,
  className: string,
  src: string,
  srcSet: string,
  disableCache: bool,
};

StaticImg.defaultProps = {
  ext: "png",
};
