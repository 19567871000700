import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  
  totalCost: {
    color: theme.palette.text.secondary,
    fontSize: 11,
    textTransform: "uppercase",
    height: 14,
  },
  totalCostDivider: {
    width: 16,
    height: 2,
    margin: "0 8px",
    backgroundColor: theme.palette.text.primary,
    opacity: 0.85,
  },
  divider: {
    marginLeft: 20,
    height: "60%",
  }
}));

export default useStyle;
