import { useState } from "react";
import { func } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { Alert } from "@components";
import { IconButton, Link } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";

export const HardRoundingAlert = observer(({
  showBreakdownSettingsDialog,
  
}) => {
  const {t} = useTranslation();
  const structure = useStructureStore();
  
  const [dialogVisible, showDialog] = useState();
  
  const { settings } = structure;
  
  const handleDialogOpen = () => showDialog(!dialogVisible);
  
  return <>
    <IconButton
      size="small"
      onClick={handleDialogOpen}
    >
      <ReportProblemOutlined className="color-warning" />
    </IconButton>
    <Alert
      title={t("common.warning")}
      isOpen={dialogVisible}
      onAccept={handleDialogOpen}
      acceptText={t("common.close")}
    >
      <p className="mb-4 text-sm">
        {t("views.editor.hard_rounding_alert.text", {
          roundLevel: t(`proposal_parts.module_levels.level_${typeof settings.roundLevel === "number" ? settings.roundLevel : 3}`),
          times: t(`times.${ settings?.timeModifier === 1 ? "hour" : "day" }`)
        })}
      </p>
      <p className="text-sm">
        {t("views.editor.hard_rounding_alert.text2")}
        <Link className="cursor-pointer" onClick={showBreakdownSettingsDialog}>
          {t("common.click_here")}</Link>
        {"."}
      </p>
    </Alert>
  </>
})

HardRoundingAlert.propTypes = {
  showBreakdownSettingsDialog: func
}