export const ROLE_ADMIN = 3;
export const ROLE_USER = 4;

export const TEAM_ROLE_ADMIN = 1;
export const TEAM_ROLE_TECHNICAL = 2;
export const TEAM_ROLE_BUSINESS = 4;
export const TEAM_ROLE_MODERATOR = 5;

export const DEMO_PROJECT_NAME =
  "Marketplace - web portal and mobile application";
export const DEMO_PROJECT_DESC =
  "<p>This is a demo quote for marketplace application. Use it to see how you can create a great-looking quote!</p><p>The quote includes:</p><ul><li>Design phase</li><li>Portal for customers</li><li>Mobile app for customers</li><li>Portal for vendors</li><li>Admin panel</li></ul><p>It is broken down into 2 different options with different components enabled and disabled.<br>It also includes automated calculations for PM and QA work types.</p>";
