import { memo } from "react";
import { bool, number, object, string } from "prop-types";
import { parsePrice, roundFloat } from "project-structure";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const BillingChartBarInnerTooltipContent = memo(({
  name,
  hours,
  value,
  currencyObj,
  roundPrice,
  timeModifier,
}) => {
  const {t} = useTranslation();
  
  return (
    <Grid item container direction="column" className="font-bold">
      <span>
        {name}{": "}
      </span>
      <span>
        {currencyObj.symbolStart || ""}
        {parsePrice(roundFloat(value, roundPrice ? 3 : 0, !roundPrice))}
        {currencyObj.symbolEnd || ""}
      </span>
      <span>
        {`${hours} ${ timeModifier === 1 ? t("times.h") : " "+t("times.day") }`}
      </span>
    </Grid>
  );
});

BillingChartBarInnerTooltipContent.propTypes = {
  currencyObj: object,
  roundPrice: bool,
  timeModifier: number,
  value: number.isRequired,
  hours: number.isRequired,
  name: string.isRequired,
};