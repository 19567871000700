import { Grid, LinearProgress } from "@material-ui/core";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { getCounterQuery } from "@query";
import { string } from "prop-types";
import { SUCCESS_UPLOAD, ERROR_UPLOAD } from "@utils";
import { useTranslation } from "react-i18next";

export const UploadSnackBar = observer(({ uuid }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [counter, setCounter] = useState(0);
  const [allIssues, setAllIsues] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCounterQuery(uuid);
        setCounter(response.percentExported);
        setAllIsues(response.allIssues);
        if (response.percentExported === 100) {
          closeSnackbar(uuid);
          enqueueSnackbar(t("views.bt.uploaded_successfully"), {
            variant: "success",
            key: SUCCESS_UPLOAD.BT_PROJECT,
            preventDuplicate: true,
          });
        }
      } catch {
        closeSnackbar(uuid);
        enqueueSnackbar(t("views.bt.something_went_wrong"), {
          variant: "error",
          key: ERROR_UPLOAD.BT_PROJECT,
          preventDuplicate: true,
        });
      }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  });

  return (
    <Grid container justifyContent="space-between" className="mx-3">
      <p className="mb-2" >{t("views.bt.upload_progress", { allIssues: allIssues, exported: counter })}</p>
      <LinearProgress value={counter} variant="determinate" color="secondary" />
    </Grid>
  );
});

UploadSnackBar.propTypes = {
  uuid: string.isRequired,
};
