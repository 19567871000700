import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 12,
    borderRadius: "15px 0",
    "&:not(:first-child)": {
      marginTop: 8,
    },
  },
  creationMode: {
    border: "1px dashed " + theme.palette.primary.main,
  },
  sentMessage: {
    border: "1px solid " + theme.props.border,
    "&:not(:hover)": {
      "& button": { visibility: "hidden" },
    },
  },
  ownMessage: {
    marginLeft: 24,
    width: "calc(100% - 24px)",
  },
  infoRowContent: {
    width: "max-content",
    "& > div": {
      width: "max-content",
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
  },
  opaque: {
    opacity: 0.5,
  },
  text: {
    fontSize: 12,
  },
}));

export default useStyle;
