import { useEffect, useState } from "react";
import { CircularProgress, Grid, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { getLatestPaymentsQuery } from "@query";
import { universalDateParser } from "@utils";
import { Button } from "@components";
import { PaymentHistoryDialog } from "../PaymentHistoryDialog";
import useStyle from "./SubscriptionSections.style";
import { EmailInvoicesDialog } from "@views/Utility/Subscription/components/EmailInvoicesDialog";

export const SectionPayments = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isLoading, setLoading] = useState(false);
  const [historyDialogOpened, openHistoryDialog] = useState(false);
  const [invoiceEmailDialogOpened, openInvoiceEmailDialog] = useState(false);
  const [latestPayments, setPayments] = useState([]);

  const loadData = async () => {
    setLoading(true);
    const h = await getLatestPaymentsQuery();
    setPayments(h);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Grid item container xs={12} md={4} className={classes.box}>
      <p className="font-bold text-xl">
        {t("views.subscription.titles.payments")}
      </p>

      {isLoading ? (
        <CircularProgress
          aria-label="progress indicator"
          size={40}
          color="primary"
          className={classes.spinner}
        />
      ) : (
        <>
          {latestPayments.length ? (
            latestPayments.map((m) => {
              const {
                id,
                detail,
                createdAt,
                amount,
                status,
                stripeInvoicePdf,
                stripeInvoiceUrl,
              } = m;

              const isPaid = status !== "new" && stripeInvoiceUrl;
              const isCanceled =
                status === "cancel" ||
                (status === "new" && !stripeInvoiceUrl);

              return (
                <Grid item container direction="column" key={id}>
                  {isCanceled ? (
                    <p>{detail?.[0]?.name || ""}</p>
                  ) : (
                    <Link
                      href={isPaid ? stripeInvoicePdf : stripeInvoiceUrl}
                      target="_blank"
                      className={!isPaid && "color-warning"}
                    >
                      {detail?.[0]?.name || ""}
                    </Link>
                  )}
                  <Grid item container className={classes.paymentInfo}>
                    <p>
                      {format(universalDateParser(createdAt), "dd.MM.yyyy")}
                    </p>
                    <p>
                      <strong>
                        {"$"}
                        {(amount / 100).toFixed(2)}
                      </strong>
                    </p>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <p className="transparent-2">
              {t("views.subscription.no_payments")}
            </p>
          )}
          <Grid item container justifyContent="space-between">
            <Button
              variant="outlined"
              size="small"
              disabled={isLoading || !latestPayments.length}
              onClick={() => openHistoryDialog(true)}
            >
              {t("views.subscription.actions.show_history")}
            </Button>
            <Button
              variant="outlined"
              size="small"
              disabled={isLoading}
              onClick={() => openInvoiceEmailDialog(true)}
            >
              {t("views.subscription.actions.email_invoices")}
            </Button>
          </Grid>
          <PaymentHistoryDialog
            open={historyDialogOpened}
            onClose={() => openHistoryDialog(false)}
          />
          <EmailInvoicesDialog
            open={invoiceEmailDialogOpened}
            onClose={() => openInvoiceEmailDialog(false)}
          />
        </>
      )}
    </Grid>
  );
};

SectionPayments.propTypes = {};
