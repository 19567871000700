import { APIMethod, fetchData } from "@client";

export async function updateCompanyPresetQuery(presetId, name, params, font) {
  await fetchData(`/preset/${presetId}`, APIMethod.PUT, {
    data: {
      name,
      params: JSON.stringify(params),
      fonts: font,
    },
  });
}
