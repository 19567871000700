import { bool, func, node, oneOf, oneOfType, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { TagLabel } from "@components";
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, IconButton } from "@material-ui/core";
import { ErrorRounded, ExpandLess, ExpandMore, WarningRounded } from "@material-ui/icons";
import useStyle from "./Accordion.style";
import classnames from "classnames";

export const Accordion = ({
  id,
  title,
  expanded,
  defaultExpanded,
  onExpand,
  onEntered,
  children,
  className,
  titleClassName,
  titleContentClassName,
  contentClassName,
  titleColored,
  colored,
  bordered,
  reverseTitleRow,
  variant="info",
  showVariantTag,
  noSummary,
  hideExpandButton,
  square,
}) => {
  const {t} = useTranslation();
  const classes = useStyle({ bordered, colored, reverseTitleRow, titleColored });
  
  return (
    <MuiAccordion
      key={id}
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      onChange={onExpand}
      square={square}
      TransitionProps={{
        onEntered,
        unmountOnExit: true,
        mountOnEnter: true,
      }}
      classes={{ root: classnames(classes.root, className) }}
    >
      {
        !noSummary &&
        <AccordionSummary
          aria-controls={`${name}-content`}
          id={id}
          classes={{
            root: classnames(titleClassName, classes.title, titleColored && classes.titleColored),
            content: classnames(titleContentClassName, classes.titleContent),
            expanded: classes[`variant-${variant}`],
          }}
        >
          {
            !hideExpandButton &&
            <IconButton onClick={onExpand}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
          {
            typeof title === "string"
              ? <p>{ title }</p>
              : title
          }
          {
          showVariantTag && variant !== "info" &&
            <TagLabel
              color={variant}
              icon={variant === "warning" ? <WarningRounded /> : <ErrorRounded />}
              className="ml-2"
            >
              {t(`common.${variant}`)}
            </TagLabel>
          }
        </AccordionSummary>
      }
      <AccordionDetails
        className={classnames(contentClassName, noSummary && "p-0 squared")}
        classes={{ root: classes.content }}
      >
        { children }
      </AccordionDetails>
    </MuiAccordion>
  );
};

Accordion.propTypes = {
  id: string,
  title: oneOfType([string, node]).isRequired,
  expanded: bool,
  defaultExpanded: bool,
  onExpand: func,
  onEntered: func,
  children: node.isRequired,
  className: string,
  titleClassName: string,
  titleContentClassName: string,
  contentClassName: string,
  titleColored: bool,
  colored: bool,
  bordered: bool,
  reverseTitleRow: bool,
  showVariantTag: bool,
  noSummary: bool,
  hideExpandButton: bool,
  square: bool,
  variant: oneOf(["info", "warning", "danger"])
};