import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function fetchCompanyJiraProjects(companyId) {
  return await fetchData(`api/jira-project-list`, APIMethod.GET, {
    baseURL: ACCESS_DOMAINS.btApi,
    params: {
      companyId
    }
  });
  
}