import { useEffect, useMemo, useState } from "react";
import { bool, number, oneOfType, object, string } from "prop-types";
import { useStores } from "@hooks";
import { getPaymentMethodsQuery } from "@query";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { universalDateParser } from "@utils";
import { Button } from "@components";
import { CircularProgress, Grid } from "@material-ui/core";
import { CreditCard } from "@material-ui/icons";
import { PaymentMethodsDialog } from "../PaymentMethodsDialog";
import useStyle from "./SubscriptionSections.style";
import classnames from "classnames";
import { roundFloat } from "project-structure";

export const SectionCards = ({
  isSubscriptionLoading,
  hasSubscription,
  subscriptionEnd,
  subscriptionPrice,
  defaultPaymentMethod,
  coupon,
  lastPaymentPeriod,
}) => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const classes = useStyle();

  const [isLoading, setLoading] = useState(false);
  const [methodsDialogOpened, openMethodsDialog] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [savedCards, setSavedCards] = useState([]);

  const currentCard = useMemo(
    () => savedCards.find((x) => x.id === paymentMethod),
    [paymentMethod, savedCards]
  );

  const { hasTrialLeft, subscriptionInfo, trialLeftDays } = userStore;

  const loadData = async () => {
    setLoading(true);
    const methods = await getPaymentMethodsQuery();
    setPaymentMethod(defaultPaymentMethod);
    setSavedCards(methods);
    setLoading(false);
    return methods;
  };

  const handleMethodSelection = (newMethodId = null) => {
    if (newMethodId) setPaymentMethod(newMethodId);
    openMethodsDialog(false);
  };

  useEffect(() => {
    loadData();
  }, []);
  
  useEffect(() => {
    setPaymentMethod(defaultPaymentMethod);
  }, [defaultPaymentMethod]);

  return (
    <Grid
      item
      container
      xs={12}
      md={4}
      className={`${classes.box} ${classes.bordered}`}
    >
      <p className="font-bold text-xl">
        {t("views.subscription.titles.details")}
      </p>
      {isSubscriptionLoading ? (
        <CircularProgress
          aria-label="progress indicator"
          size={40}
          color="primary"
          className={classes.spinner}
        />
      ) : (
        <>
          {hasSubscription ? (
            <>
              {hasTrialLeft && (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <p className="transparent-2">
                    {t("views.subscription.details.trial")}
                  </p>
                  <p>
                    <strong>
                      {t("views.subscription.tral_left", {
                        days: trialLeftDays,
                      })}
                    </strong>
                  </p>
                </Grid>
              )}
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <p className="transparent-2">
                  {t(
                    `views.subscription.details.${
                      lastPaymentPeriod ? "ends" : "next"
                    }`
                  )}
                </p>
                <p className={classnames(lastPaymentPeriod && "color-error")}>
                  {format(
                    universalDateParser(
                      hasTrialLeft ? subscriptionInfo.trialEnd : subscriptionEnd
                    ),
                    "dd MMMM yyyy"
                  )}
                </p>
              </Grid>
              {!lastPaymentPeriod && (
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <p className="transparent-2">
                    {t("views.subscription.details.amount")}
                  </p>
                  <p>
                    {"$"}
                    {roundFloat(subscriptionPrice || 0, 0, true)}
                  </p>
                </Grid>
              )}
            </>
          ) : (
            hasTrialLeft && (
              <>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <p className="transparent-2">
                    {t("views.subscription.details.using_trial")}
                  </p>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <p className="transparent-2">
                    {t("views.subscription.details.trial")}
                  </p>
                  <p>
                    <strong>
                      {t("views.subscription.tral_left", {
                        days: trialLeftDays,
                      })}
                    </strong>
                  </p>
                </Grid>
              </>
            )
          )}
          {coupon ? (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <p className="transparent-2">
                {t("views.subscription.details.coupon")}
              </p>
              <p>{coupon?.code || coupon}</p>
            </Grid>
          ) : null}
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <p className="transparent-2">
              {t("views.subscription.details.method")}
            </p>
            {currentCard ? (
              <Grid
                item
                container
                alignItems="center"
                className="w-max"
              >
                <CreditCard className="text-md mr-1" />
                <p className="mr-1">
                  {currentCard.brand.toUpperCase()}
                </p>
                <p>&#8226;&#8226;&#8226;&#8226; {currentCard.digits}</p>
              </Grid>
            ) : (
              <p>{t("views.subscription.details.no_card")}</p>
            )}
          </Grid>
        </>
      )}
      <Button
        variant="outlined"
        size="small"
        disabled={
          isSubscriptionLoading ||
          isLoading ||
          !hasSubscription
        }
        onClick={() => openMethodsDialog(true)}
      >
        {t(
          `views.subscription.actions.${
            hasSubscription || savedCards?.length ? "show" : "no"
          }_methods`
        )}
      </Button>
      <PaymentMethodsDialog
        open={methodsDialogOpened}
        onClose={handleMethodSelection}
        onCardSelect={setPaymentMethod}
        savedCards={savedCards}
        onReload={loadData}
        defaultCard={currentCard ? currentCard.id : null}
      />
    </Grid>
  );
};

SectionCards.propTypes = {
  isSubscriptionLoading: bool,
  hasSubscription: bool,
  subscriptionEnd: number,
  subscriptionPrice: number,
  defaultPaymentMethod: string,
  coupon: oneOfType([object, string]),
  lastPaymentPeriod: bool,
};
