import { APIMethod, fetchData } from "@client";

export async function changeProjectCommentQuery(uuid, id, comment, mentions) {
  const data = {
    id,
    uuid,
    comment,
    notify: mentions?.length ? JSON.stringify(mentions) : undefined
  };
  return await fetchData("/project/comment", APIMethod.PUT, { data });
}
