import { useEffect, useMemo, useState } from "react";
import { bool, func } from "prop-types";
import { observer } from "mobx-react";
import { MinAvgMaxKeys, checkBuildLock, RISK_BUFFER_LOCK_BUILD } from "project-structure";
import { useTranslation } from "react-i18next";
import { useStructureStore } from "@hooks";
import { Grid, Switch } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  ButtonSwitch,
  Button,
  Dialog,
  InfoLabel, Alert,
} from "@components";
import { Settings } from "@assets";
import {
  DifferentTotalCostAlert,
  HardRoundingAlert,
  RiskDisplay,
  SoftRoundingAlert
} from "./components";
import { Risk, Rounding } from "./settings";
import { LevelCheckbox } from "../VisibilityBreakdownSettings/components/LevelCheckbox";

export const ProposalSettings = observer(({
  noViewLevel,
  noMinMax,
  noRounding,
  noDescriptionMode,
  noRiskBuffer,
  externalVisible=false,
  noAlerts=false,
  externalOnShow
}) => {

  const structure = useStructureStore();
  const { t } = useTranslation();

  const [modifierAlertVisible, showModifierAlert] = useState(externalVisible);
  const [visible, show] = useState(externalVisible);
  
  const { settings } = structure;

  const {
    viewLevel,
    valueLevel,
    timeModifier,
    useMinMax,
    sectionFormat,
    descriptionMode,
    applyRiskBuffer,
    // mixedHoursCounting,
    roundType,
    roundLevel,
    dayModifier,
    showBreakdownParentValues,
  } = settings;

  useEffect(() => {
    show(externalVisible);
  }, [externalVisible])
  
  const showRiskBuffer = useMemo(() => Boolean(
    !structure.sOriginBuild || checkBuildLock(RISK_BUFFER_LOCK_BUILD, structure.sOriginBuild)
  ), [structure.sOriginBuild]);
  
  const handleShowDialog = () => {
    show(!visible);
    externalOnShow && externalOnShow(!visible)
  };

  const handleViewLevelChange = (level) => {
    structure.settings.setViewLevel(level);
  };

  const handleValueLevelChange = (level) => {
    structure.settings.setValueLevel(level === valueLevel && level === 0 ? -1 : level);
  };

  const handleDescriptionModeChange = (e, n) => {
    structure.settings.setDescriptionMode(n >= 0 ? n : 3);
  };

  const handleSectionFormatChange = (e, n) => {
    structure.settings.setSectionFormat(n || 3);
  };

  const handleShowBreakdownParentValues = () => {
    structure.settings.setShowBreakdownParentValues(!showBreakdownParentValues);
  };

  const handleTimeModifierChange = (modifier) => {
    if(timeModifier === modifier)
      return;
    
    if(modifier === 0 && (
      structure.settings.useMinMax
        ? (structure.totalHours[MinAvgMaxKeys.MIN] || structure.totalHours[MinAvgMaxKeys.MAX])
        : structure.totalHours[MinAvgMaxKeys.AVG]
    )) {
      showModifierAlert(true);
      return;
    }
    structure.settings.setModifier(modifier);
  }
  
  const setDayModifierChange = () => {
    structure.alterElementHours(true);
    structure.settings.setModifier(timeModifier ? 0 : 1);
    showModifierAlert(false);
  }
  
  const handleMinMaxChange = () => structure.settings.setMinMax(!useMinMax);
  
  return (
    <>
      {
        applyRiskBuffer && !noRiskBuffer && !externalOnShow && showRiskBuffer &&
        <RiskDisplay />
      }
      {/*((!noRounding && mixedHoursCounting) || !timeModifier) &&*/ !noAlerts && (
        <DifferentTotalCostAlert />
      )}
      {roundType === 1 && (
        <SoftRoundingAlert showBreakdownSettingsDialog={handleShowDialog} />
      )}
      {roundType === 2 && roundLevel < 3 && (
        <HardRoundingAlert showBreakdownSettingsDialog={handleShowDialog} />
      )}
      {
        !externalOnShow &&
        <Button
          id="breakdownSettings"
          onClick={handleShowDialog}
          aria-label={t("views.editor.dialogs.settings.title")}
          color="secondary"
          endIcon={<Settings color="primary" />}
          className="font-normal"
        >
          {t("views.editor.dialogs.settings.title")}
        </Button>
      }
      <Dialog
        open={visible}
        onClose={handleShowDialog}
        width={460}
        title={t("views.editor.dialogs.settings.title")}
      >
        <Grid item container direction="column">
          {!noViewLevel && (
            <Grid item container>
              <Grid item container xs={6}>
                <InfoLabel className="mt-0" label="views.editor.dialogs.settings.level" />
                {
                  [0,1,2,3].map((level) => (
                    <LevelCheckbox
                      key={`level${level}`}
                      onCheck={handleViewLevelChange}
                      level={level}
                      checked={viewLevel >= level}
                      // disabled={level > maxViewLevel}
                    />
                  ))
                }
              </Grid>
              {/*<Autocomplete*/}
              {/*  id="levelDisplaySelect"*/}
              {/*  value={viewLevel}*/}
              {/*  fullWidth*/}
              {/*  size="small"*/}
              {/*  onChange={handleViewLevelChange}*/}
              {/*  getOptionSelected={(o, v) => o === v}*/}
              {/*  getOptionLabel={(o) => t("proposal_parts.levels_estimates." + o)}*/}
              {/*  disableClearable*/}
              {/*  options={[1, 2, 3]}*/}
              {/*  renderInput={(params) => (*/}
              {/*    <TextField {...params} label="" />*/}
              {/*  )}*/}
              {/*/>*/}
              <Grid item container xs={6}>
                <InfoLabel className="mt-0" label="views.editor.dialogs.settings.values" />
                {
                  [0,1,2,3].map((level) => (
                    <LevelCheckbox
                      key={`level${level}`}
                      onCheck={handleValueLevelChange}
                      level={level}
                      checked={valueLevel >= level}
                      disabled={level > viewLevel}
                    />
                  ))
                }
              </Grid>
              {/*<Autocomplete*/}
              {/*  id="levelValuesSelect"*/}
              {/*  value={valueLevel}*/}
              {/*  fullWidth*/}
              {/*  size="small"*/}
              {/*  onChange={handleValueLevelChange}*/}
              {/*  getOptionSelected={(o, v) => o === v}*/}
              {/*  getOptionLabel={(o) => t("proposal_parts.levels_estimates." + o)}*/}
              {/*  getOptionDisabled={(o) =>*/}
              {/*    o > viewLevel ||*/}
              {/*    (o === 0 && sectionFormat !== 1) ||*/}
              {/*    (o === -1 && sectionFormat === 2)*/}
              {/*  }*/}
              {/*  disableClearable*/}
              {/*  options={[0, 1, 2, 3, -1]}*/}
              {/*  renderInput={(params) => (*/}
              {/*    <TextField {...params} label="" />*/}
              {/*  )}*/}
              {/*/>*/}
              <InfoLabel className="mt-6" label="views.editor.dialogs.settings.sections" />
              <Autocomplete
                id="sectionFormatSettingsSelect"
                value={sectionFormat}
                fullWidth
                size="small"
                onChange={handleSectionFormatChange}
                getOptionSelected={(o, v) => o === v}
                getOptionLabel={(o) =>
                  t("views.editor.dialogs.settings.section_formats." + o)
                }
                disableClearable
                options={[1, 2, 3]}
                renderInput={(params) => (
                  <TextField {...params} label="" />
                )}
              />
            </Grid>
          )}

          <>
            <InfoLabel className="mt-0" label="views.editor.estimate_in" />
            <ButtonSwitch
              value={timeModifier}
              setValue={handleTimeModifierChange}
              values={[
                { value: 1, label: t("times.hour") },
                { value: 0, label: t("times.day") },
              ]}
              fullWidth
              className="mb-4"
              size="small"
            />
          </>
          
          {!noDescriptionMode && (
            <>
              <InfoLabel
                className="mt-0"
                label="views.editor.dialogs.settings.description_mode"
                translate
              />
              <Autocomplete
                id="descriptionModeSelect"
                value={descriptionMode}
                fullWidth
                size="small"
                onChange={handleDescriptionModeChange}
                getOptionSelected={(o, v) => o === v}
                getOptionLabel={(o) => t("description_mode." + o)}
                disableClearable
                options={[3, 2, 1]}
                renderInput={(params) => (
                  <TextField {...params} label="" />
                )}
              />
            </>
          )}

          {!noMinMax && (
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <InfoLabel label="views.editor.min_max" className="mb-0" />
              <Switch
                size="medium"
                color="primary"
                checked={useMinMax}
                onChange={handleMinMaxChange}
                name={
                  useMinMax ? "Revert to fixed values" : "Allow min-max values"
                }
              />
            </Grid>
          )}
          
          <Grid item container wrap="nowrap" className="mb-4">
            <InfoLabel label="views.editor.dialogs.settings.parent_estimates" className="mb-0" />
            <Switch
              size="medium"
              color="primary"
              checked={showBreakdownParentValues || false}
              onChange={handleShowBreakdownParentValues}
              name={
                useMinMax ? "Revert to fixed values" : "Allow min-max values"
              }
            />
          </Grid>

          {
            !noRounding &&
            <Rounding />
          }
          {
            !noRiskBuffer && showRiskBuffer &&
            <Risk />
          }
        </Grid>
      </Dialog>
      <Alert
        isOpen={modifierAlertVisible}
        title={t("common.warning")}
        acceptText={t("common.yes")}
        onAccept={setDayModifierChange}
        onCancel={() => showModifierAlert(false)}
      >
        {t("views.editor.estimate_in_alert.text1", {hours: dayModifier})}
        <br />
        <br />
        {t("views.editor.estimate_in_alert.text2")}
      </Alert>
    </>
  );
});

ProposalSettings.propTypes = {
  noDescriptionMode: bool,
  noViewLevel: bool,
  noRounding: bool,
  noMinMax: bool,
  noRiskBuffer: bool,
  externalVisible: bool,
  noAlerts: bool,
  externalOnShow: func
};
