import { APIMethod, fetchData } from "@client";
import { LIBRARY_LEVELS } from "@utils";

export async function addLibraryElementQuery(
  level,
  name,
  content,
  order,
  parent = undefined
) {
  const res = await fetchData("/v1/library", APIMethod.POST, {
    data: {
      name,
      content,
      lvl: LIBRARY_LEVELS[level - 1],
      parent,
      order,
    },
  });
  return res.results?.id;
}
