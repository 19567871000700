import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { checkPrivilegesQuery, createWorkspaceQuery } from "@query";
import { useStores } from "@hooks";
import { Alert } from "@components";
import { home, login } from "@paths";
import { LOCKED_FEATURES, pushGTagEvent } from "@utils";
import { NewBoardForm } from "@forms";

export const CreateWorkspace = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore, stateStore } = useStores();

  const [alertText, setAlertText] = useState("");

  const createFirstBoard = async (data) => {
    pushGTagEvent("start_using_apropo");

    try {
      const res = await checkPrivilegesQuery(LOCKED_FEATURES.WORKSPACES.serverCode);
      if (!res.isAllowed) setAlertText(res.message || "Not allowed");
      else {
        const workspace = await createWorkspaceQuery(data);
        userStore.setAvailableWorkspaces([workspace]);
        pushGTagEvent("start_free_trial", {
          plan: "package_trial",
          value: "0",
          billing: "month",
        });
        stateStore.firstLogin = true;
        stateStore.setMemorizedAction(home);
        userStore.setLoginProcessStatus(true);
      }
    } catch (e) {
      setAlertText(e.message);
    }
  };

  useEffect(() => {
    if (!userStore.isLoggedIn)
      navigate(login, { replace: true });
  }, []);

  return (
    <>
      <NewBoardForm
        onSubmit={createFirstBoard}
        defaultName={userStore?.data?.company}
      />
      <Alert
        title={alertText}
        isOpen={!!alertText}
        onAccept={() => setAlertText("")}
        acceptText={t("common.close")}
      />
    </>
  );
});
