import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  root: {
    "& input, & .MuiSelect-root": {
      boxSizing: "border-box",
    },
  },
  noLabel: {
    "& fieldset": {
      top: 0,
      "& > legend": { display: "none" },
    },
  },
}));

export default useStyle;
