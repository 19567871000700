import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@components";
import { pushGTagEvent } from "@utils";
import { ActivationForm } from "@forms";
import { activateUserAccountQuery } from "@query";

export const Activate = () => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const { search } = useLocation();

  const email = new URLSearchParams(search).get("email") || null;

  const [alertText, setAlertText] = useState("");

  const activate = async (data) => {
    try {
      const userData = await activateUserAccountQuery(data);
      pushGTagEvent("verified_registration");
      userStore.saveUserData(userData);
      userStore.checkWorkspacesAndRedirect();
      userStore.setLoginProcessStatus(true);
    } catch (e) {
      if (e.response) setAlertText(e.response.data.error);
      else setAlertText(t("errors.network"));
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      wrap="nowrap"
      className="mt-3"
    >
      <Grid item container>
        <ActivationForm
          onSubmit={activate}
          defaultEmail={stateStore.activationEmail || email}
        />
      </Grid>
      <Alert
        isOpen={Boolean(alertText)}
        title={alertText}
        acceptText={t("common.close")}
        onAccept={() => setAlertText("")}
      />
    </Grid>
  );
};
