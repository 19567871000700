export const UnathorizedFrameWarning = () => {
  return (
    <div style={{ position: "absolute", top: 40, left: 40 }}>
      <h1 style={{ color: "red" }}>Warning!</h1>
      <h3>You are viewing this page in a unauthorized frame window!</h3>
      <p>
        This is not an official Apropo website. Apropo only allows connections
        within its own domain. Please leave this site.
      </p>
    </div>
  );
};
