import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  header: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  mobileDrawerButton: {
    marginRight: 3,
  },
  noDialogHeader: {
    margin: "0 -16px 16px",
    padding: 16,
    width: "calc(100% + 32px)",
    backgroundColor: theme.props.backgroundSecondary,
    borderBottom: "1px solid " + theme.props.border,
  },
  rowGrid: {
    width: "100%",
    height: 450,
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  details: {
    width: (isMobile) => (isMobile ? "100%" : "calc(100% - 184px)"),
    height: "100%",
    alignItems: "flex-start",
    backgroundColor: theme.props.background,
    borderRadius: 5,
  },
  detailsScrollable: {
    margin: 16,
  },
  actions: {
    width: 184,
    height: "100%",
    alignItems: "flex-start",
    alignContent: "flex-start",
    position: "relative",
    paddingLeft: 16,
    "& > p": {
      fontSize: 16,
    },
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  listGrid: {
    padding: "8px 0",
    margin: "8px 0",
  },
  addButton: {
    padding: 0,
  },
  memberItem: {
    display: "flex",
    alignItems: "center",
    "& > p": {
      fontSize: "16px",
      marginLeft: 14,
    },
  },
  userDisabled: { color: theme.palette.text.primary + "80" },
  userEnabled: { color: theme.palette.text.primary },
}));

export default useStyle;
