import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles(() => ({
  
  cell: {
    width: CELL_WIDTHS.VALUE.SMALL,
    minWidth: CELL_WIDTHS.VALUE.SMALL,
  },
  narrow: {
    width: CELL_WIDTHS.VALUE.TINY,
    minWidth: CELL_WIDTHS.VALUE.TINY
  },
}));

export default useStyle;