import { SvgIcon } from "@material-ui/core";

export const DescriptionRow = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M2 14H22" stroke="currentColor" strokeWidth="1.5"/>
      <path d="M2 10H12" stroke="currentColor" strokeWidth="1.5"/>
    </SvgIcon>
  );
};
