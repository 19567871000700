import { useEffect, useMemo, useRef, useState } from "react";
import { bool, func } from "prop-types";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import { useEditorWebsocket, useProjectEditorStore, useStructureStore } from "@hooks";
import ResizeObserver from "react-resize-observer";
import {
  ProposalAppBar,
  EditorTablesFactory,
  EditorUsers,
  Versioning,
  TableToolbar,
  TableSummary,
} from "@components";
import { CONTENT_WIDTH, TABLE_CONTAINER_ID, SNACKBAR_VERSION_LOCKED } from "@utils";
import { Tables } from "project-structure";
import useStyle from "./EditorContent.style";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export const EditorContent = observer(({
  hasEditorPrivileges,
  hasSellerPrivileges,
  readOnly,
  isProposal,
  useClientActions,
  onSectionReorder,
  showPdfCover,
  forceAllowVersionSelect,
}) => {
  const {t} = useTranslation();
  const classes = useStyle();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const socket = useEditorWebsocket();
  const editorStore = useProjectEditorStore();
  const structure = useStructureStore();
  
  const { locked, settings } = structure;
  const { visibleTables } = settings;

  const { widestPageSize, scale, profitabilityMode, visibilityMode } = editorStore;

  const containerWidth = (widestPageSize.width || 800) * scale;
  const transformScale = containerWidth / CONTENT_WIDTH;

  const [containerHeight, setContainerHeight] = useState(0);
  
  const tableRef = useRef(null);
  
  const isLocked = useMemo(() => (
    locked && !isProposal
  ), [locked, isProposal]);
  
  const handleStructureActionHistory = (e) => {
    
    if(!editorStore.useHistoryManager || !(e.ctrlKey || e.metaKey) || e.target.className.includes("ql-editor"))
      return;
    
    const isZKey = e.key.toUpperCase() === "Z";
    const isYKey = e.key.toUpperCase() === "Y";
    
    if(isYKey || (isZKey && e.shiftKey)) {
      e.preventDefault();
      if(!structure.historyManager?.redoLevels)
        return;
      socket?.requestStructurePatch(
        structure.historyManager?.history[structure.historyManager?.undoIdx].patches
      )
      structure.historyManager?.redo();
    }
    else if(isZKey) {
      e.preventDefault();
      if(!structure.historyManager?.undoLevels)
        return;
      socket?.requestStructurePatch(
        structure.historyManager?.history[structure.historyManager?.undoIdx - 1].inversePatches.slice().reverse()
      )
      structure.historyManager?.undo();
    }
  }

  useEffect(() => {
    if (tableRef.current?.children?.[0])
      setContainerHeight(
        tableRef.current?.children?.[0]?.offsetHeight * transformScale
      );
  }, [transformScale, tableRef.current?.children]);
  
  useEffect(() => {
    if(isLocked) {
      enqueueSnackbar(
        t("alerts.editor.version_locked"),
        {
          key: SNACKBAR_VERSION_LOCKED,
          variant: "info",
          persist: true,
        }
      );
    } else {
      closeSnackbar(SNACKBAR_VERSION_LOCKED);
    }
    
    return () => {
      closeSnackbar(SNACKBAR_VERSION_LOCKED);
    }
  }, [isLocked]);
  
  useEffect(() => {
    if(editorStore.useHistoryManager) {
      window.document.addEventListener("keydown", handleStructureActionHistory);
    }
    return () => {
      window.document.removeEventListener("keydown", handleStructureActionHistory);
    }
  }, [structure]);
  
  const breakdownTableIndex = useMemo(() => (
    visibleTables?.findIndex(t => t.name === Tables.BREAKDOWN)
  ), [visibleTables])
  
  const useProfitability = useMemo(() => (
    profitabilityMode && !readOnly && hasEditorPrivileges && hasSellerPrivileges && !isProposal
  ), [profitabilityMode, readOnly, hasEditorPrivileges, hasSellerPrivileges, isProposal]);
  
  const tableProps = {
    readOnly: readOnly || visibilityMode || isLocked,
    isProposal: isProposal,
    hasEditorPrivileges,
    hasSellerPrivileges,
    useClientActions: useClientActions && !visibilityMode && !isLocked,
    forceAllowExpansions: isLocked,
    showPdfCover
  };

  return (
    <Grid
      id={TABLE_CONTAINER_ID}
      item
      container
      direction="column"
      alignItems="center"
      className={classnames(
        "preset-page", 
        classes.scrollableContainer,
        showPdfCover && classes.pageFormat,
      )}
      style={showPdfCover ? {
        width: containerWidth,
        height: containerHeight,
      } : undefined}
      ref={tableRef}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        style={
          showPdfCover
            ? {
                transform: `scale(${transformScale})`,
                transformOrigin: transformScale > 1 ? "top" : "top left",
              }
            : undefined
        }
        className={showPdfCover ? classes.scalableContainer : undefined}
      >
        {showPdfCover && (
          <>
            <ResizeObserver
              onResize={(rect) => setContainerHeight(rect.height)}
            />
            <ProposalAppBar className={classes.proposalBar} />
          </>
        )}
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={classnames(
            "preset-paper",
            classes.titleContainer,
            breakdownTableIndex === 0 && classes.titleContainerLast
          )}
          id="titleBar"
        >
          <Grid item container justifyContent="flex-end">
            <TableToolbar isSharedVersion={readOnly} />
            {!readOnly && <EditorUsers />}
            <Versioning
              noEditing={readOnly || !hasEditorPrivileges || visibilityMode}
              useClientActions={!readOnly || useClientActions || forceAllowVersionSelect}
              hasEditorPrivileges={hasEditorPrivileges}
              isProposal={isProposal}
            />
          </Grid>
        </Grid>
        {
          useProfitability
            ? <TableSummary
              {...tableProps}
              tableIndex={0}
              useProfitability
            />
            : visibleTables.map(({ name }, index) => (
              <EditorTablesFactory
                key={name}
                index={index}
                tableName={name}
                onSectionReorder={onSectionReorder}
                profitabilityMode={profitabilityMode}
                breakdownTableIndex={breakdownTableIndex}
                presetEditionMode={editorStore.proposalStep === 1}
                {...tableProps}
              />
            ))
        }
      </Grid>
    </Grid>
  );
});

EditorContent.propTypes = {
  hasEditorPrivileges: bool,
  hasSellerPrivileges: bool,
  readOnly: bool,
  isProposal: bool,
  useClientActions: bool,
  onSectionReorder: func,
  showPdfCover: bool,
  // useRecorderFriendlyPdf: bool,
};
