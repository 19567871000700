import { useEffect, useState } from "react";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { useAutoLogin } from "@hooks";
import { isBetween } from "@utils";
import { AppContainer, AppToolBar, PageCircularProgress } from "@components";
import { WorkspaceInvite, Feedback, MaintenanceDialog } from "@dialogs";
import {
  login,
} from "@paths";
import { MobileExperienceAlert } from "./components/MobileExperienceAlert";
import { TrialAlert } from "./components/TrialAlert";

export const HomeLayout = observer(() => {
  const { userStore, stateStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileExperienceDialogVisible, showMobileExperienceDialog] =
    useState(isMobile);
  const [maintenance, setMaintenance] =
    useState(false);
  
  const autoLogin = useAutoLogin();

  // should check for autologin option
  useEffect(() => {
    handleAutoLogin();
    showMaintenanceDialog();
  }, []);
  
  const handleAutoLogin = async () => {
    try {
      if(!userStore.isLoggedIn)
        await autoLogin();
      userStore.userSessionStart();
    } catch(e) {
      userStore.clearUserData();
      stateStore.setMemorizedAction(location.pathname);
      navigate(login, { replace: true });
    }
  }
  
  const showMaintenanceDialog = () => {
    if(!process.env.REACT_APP_MAINTENANCE_START || !process.env.REACT_APP_MAINTENANCE_END)
      return;
    
    if(isBetween(new Date().getTime(), process.env.REACT_APP_MAINTENANCE_START, process.env.REACT_APP_MAINTENANCE_END)) {
      setMaintenance(true);
    }
  }

  const turnOffFirstLoginPopups = () => {
    stateStore.firstLogin = false;
    window.userGuiding?.previewGuide(68103);
  };

  const closeMobileExperienceDialog = () => {
    showMobileExperienceDialog(false);
  };

  if (!userStore.isLoggedIn) return <PageCircularProgress />;

  return (
    <>
      <AppToolBar />
      <AppContainer>
        <Outlet />
        {stateStore.firstLogin ? (
          <WorkspaceInvite
            onInvite={turnOffFirstLoginPopups}
            onClickAway={turnOffFirstLoginPopups}
            isOpen={stateStore.firstLogin}
          />
        ) : null}
        {stateStore.trialAlertStatus && <TrialAlert />}
        {maintenance && <MaintenanceDialog onClose={() => setMaintenance(false)} />}
        {isMobile && (
          <MobileExperienceAlert
            onClose={closeMobileExperienceDialog}
            isOpen={mobileExperienceDialogVisible}
          />
        )}
      </AppContainer>
      <Feedback />
    </>
  );
});
