import { useEffect, useMemo, useRef, useState } from "react";
import { arrayOf, bool, func, node, number, object, oneOfType, string } from "prop-types";
import { Button, PopMenu, TextField } from "@components";
import { CircularProgress, ClickAwayListener, Grid, IconButton, InputAdornment } from "@material-ui/core";
import { Link as MuiLink } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Trash } from "@assets";
import useStyle from "./InteractiveAutocomplete.style";
import classnames from "classnames";

export const InteractiveAutocomplete = ({
  value: defaultValue,
  onChange,
  inputLabel,
  inputPlaceholder,
  name,
  options,
  className,
  children,
  idKey,
  labelKey,
  inputProps,
  getOptionStyle,
  optionClassName,
  popupIcon,
  // onOptionEdit,
  onOptionRemove,
  pinnedOptionIds=[],
  readOnlyOptions=[],
  ...textFieldProps
}) => {
  const classes = useStyle();
  
  const [isOpen, setOpen] = useState(false);
  const [isRemoving, setRemoving] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [dirty, setDirty] = useState(false);
  
  const inputRef = useRef(null);
  
  useEffect(() => {
    setInputValue(defaultValue?.[labelKey] || "");
  }, [defaultValue, options]);
  
  const handleFocus = () => {
    setOpen(true);
    setDirty(false);
    inputRef.current?.select();
    inputRef.current?.setSelectionRange(0, 99999);
  }
  
  const handleButtonFocus = () => {
    if(!isOpen) {
      inputRef.current?.select();
      inputRef.current?.setSelectionRange(0, 99999);
    }
    setOpen(!isOpen);
    setDirty(false);
  }
  
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setDirty(e.target.value !== defaultValue?.[labelKey]);
  }
  
  const handleSelect = (o) => (e) => {
    if(e.target.name === "remove")
      return;
    onChange(o, o[idKey]);
    setOpen(false);
  }
  
  const onClickAway = () => {
    setOpen(false);
  }
  
  const handleOptionRemove = (o) => async (e) => {
    setRemoving(o[idKey]);
    e.stopPropagation();
    e.preventDefault();
    await onOptionRemove(o, o[idKey])
    setRemoving(false);
  }
  
  const filteredOptions = useMemo(() => (
    options
      .filter(o => (
        dirty
          ? o[labelKey].toLowerCase().includes(inputValue.toLowerCase())
          : true
      ))
      .sort((a,b) => {
        if(pinnedOptionIds.includes(a[idKey]))
          return -1;
        if(pinnedOptionIds.includes(b[idKey]))
          return 1;
        return a[labelKey].toLowerCase() > b[labelKey].toLowerCase() ? 1 : -1
      })
  ), [dirty, options, options.length, inputValue])
  
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Grid item container className={classes.root}>
        <TextField
          {...textFieldProps}
          name={name}
          value={inputValue}
          className={classnames(className, "mb-0")}
          label={inputLabel}
          size="small"
          placeholder={!defaultValue?.length ? inputPlaceholder : undefined}
          onFocus={handleFocus}
          onChange={handleInputChange}
          inputProps={{
            ref: inputRef,
            ...(inputProps || {})
          }}
          endAdornment={(
            <InputAdornment position="end">
              {
                popupIcon ||
                <IconButton className="p-0-5" onClick={handleButtonFocus}>
                  <ExpandMore
                    className={classnames("expand transition", isOpen ? "-rotate-180" : "rotate-0")}
                  />
                </IconButton>
              }
            </InputAdornment>
          )}
        />
        <PopMenu
          show={isOpen}
          anchor={inputRef.current}
          placement="bottom-start"
          className="p-0 overflow-auto"
          style={{ width: 260, maxHeight: 320, justifyContent: "flex-start" }}
        >
          {children}
          {
            filteredOptions.map((o) => {
              return (
                <Button
                  key={o[idKey]}
                  color="secondary"
                  variant="text"
                  className={classnames("w-full", optionClassName)}
                  style={getOptionStyle?.(o)}
                  onClick={handleSelect(o)}
                  component={MuiLink}
                  endIcon={(
                    Boolean(onOptionRemove) && !readOnlyOptions.includes(o[idKey]) &&
                    <IconButton
                      name="remove"
                      size="small"
                      onClick={handleOptionRemove(o)}
                    >
                      {
                        isRemoving === o[idKey]
                          ? <CircularProgress size={16} />
                          : <Trash className="color-error text-md" />
                      }
                    </IconButton>
                  )}
                >
                  {o[labelKey]}
                </Button>
              )
            })
          }
        </PopMenu>
      </Grid>
    </ClickAwayListener>
  );
};

InteractiveAutocomplete.propTypes = {
  value: oneOfType([string, number, arrayOf(oneOfType([string, number]))])
    .isRequired,
  options: arrayOf(object).isRequired,
  idKey: string.isRequired,
  labelKey: string.isRequired,
  getOptionStyle: func,
  optionClassName: string,
  name: string,
  onChange: func, // (id/idList, object/objectList, removedItemId) => void
  onInputChange: func,
  children: node,
  popupIcon: node,
  inputLabel: string,
  inputPlaceholder: string,
  error: bool,
  helperText: string,
  inputProps: object,
  InputProps: object,
  className: string,
  style: object,
  onOptionEdit: func,
  onOptionRemove: func,
  pinnedOptionIds: arrayOf(oneOfType([string, number])),
  readOnlyOptions: arrayOf(oneOfType([string, number])),
};