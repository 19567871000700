import { fetchData } from "@client";

export async function getProfessionalLevelsQuery() {
  try {
    const res = await fetchData("/team/professional-level");
    return res.results;
  } catch (e) {
    return [];
  }
}
