import { memo } from "react";
import { arrayOf, bool, number, object, shape, string } from "prop-types";
import { parsePrice, roundFloat } from "project-structure";
import { Grid } from "@material-ui/core";

export const BillingChartBarTooltipContent = memo(({
  values,
  currencyObj,
  roundPrice,
}) => (
  <Grid item container direction="column" className="font-bold">
    {
      values
        .filter(v => v.value)
        .map(({name, value}) => (
          <span key={name}>
            {name}{": "}
            {currencyObj.symbolStart || ""}
            {parsePrice(roundFloat(value, roundPrice ? 3 : 0, !roundPrice))}
            {currencyObj.symbolEnd || ""}
          </span>
        ))
    }
  </Grid>
));

BillingChartBarTooltipContent.propTypes = {
  currencyObj: object,
  roundPrice: bool,
  values: arrayOf(shape({
    value: number.isRequired,
    hours: number.isRequired,
    name: string.isRequired,
    color: string,
  })),
};