import { Button, Dialog } from "@components";
import { Grid } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import { func } from "prop-types";

export const MaintenanceDialog = ({ onClose }) => {
  
  return (
    <Dialog
      open
      useCloseButton={false}
      onClose={onClose}
      width={460}
      title={(
        <Grid item container justifyContent="center" alignItems="center">
          <WarningRounded className="color-warning text-2xl mr-2" />
          <h3>{ " Maintenance works scheduled " }</h3>
          <WarningRounded className="color-warning text-2xl ml-2" />
        </Grid>
      )}
      actions={(
        <Button variant="contained" onClick={onClose}>
          {"Close"}
        </Button>
      )}
    >
      <Grid item container direction="column">
        <span className="text-md">
            {"Our team will be conducting maintenance works today between "}
          <b>{"9:00pm"}</b>
          {" and "}
          <b>{"11:00pm GMT"}</b>
          </span>
        <span className="text-md mt-2">
          {"The service will not be available within that time."}
        </span>
      </Grid>
    </Dialog>
  )
}

MaintenanceDialog.propTypes = {
  onClose: func
}