import { useEffect, useMemo } from "react";
import { bool, func, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { getCurrencies } from "project-structure";
import { useStores } from "@hooks";
import { getCountries, vatFormats } from "@utils";
import { Button, ControlledTextField, Autocomplete, VatTextField } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const options = getCurrencies();

const companyDetailsFormSchema = (t, defaultValues) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("errors.company.name.required"))
      .max(64, t("errors.company.name.max"))
      .default(defaultValues?.name || ""),
    country: yup
      .string()
      .required(t("errors.company.country"))
      .default(defaultValues?.country || ""),
    city: yup
      .string()
      .nullable(true)
      .default(defaultValues?.city || ""),
    postcode: yup
      .string()
      .nullable(true)
      .default(defaultValues?.postcode || ""),
    street: yup
      .string()
      .nullable(true)
      .default(defaultValues?.street || ""),
    nip: yup
      .string()
      .nullable(true)
      .default(defaultValues?.nip || "")
      .matches(/^[^_]*$/g, t("errors.company.nip.regex")),
    phone: yup
      .string()
      .nullable(true)
      .default(defaultValues?.phone || ""),
    currency: yup
      .string()
      .max(64, t("errors.company.name.max"))
      .default(defaultValues?.currency || "USD"),
  });

export const CompanyDetailsForm = ({
  onSubmit,
  defaultValues,
  noDivider,
  useCancel,
  onCancel,
  useCurrency,
}) => {
  const { t } = useTranslation();
  const { stateStore } = useStores();

  const countries = useMemo(() => getCountries(stateStore.lang), []);

  const {
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    reset: resetForm,
    formState: { isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(companyDetailsFormSchema(t)),
    defaultValues: defaultValues || {
      name: "",
      postcode: "",
      street: "",
      phone: "",
      city: "",
      nip: "",
    },
  });
  

  useEffect(() => {
    if (defaultValues) resetForm(defaultValues);
  }, [defaultValues]);

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors, reset } = result;

      if (reset) {
        resetForm(defaultValues);
        return;
      }

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };
  
  const handleCancel = () => {
    if(!onCancel) {
      resetForm(defaultValues);
      return;
    }
    onCancel();
  }
  
  const country = watch('country');
  const nip = watch('nip');
  
  useEffect(() => {
    if(defaultValues && country !== defaultValues?.country && nip)
      setValue("nip", "");
  }, [country]);

  return (
    <form
      autoComplete="on"
      aria-label="companyDetails form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container spacing={1}>
        <Grid item container xs={12}>
          <ControlledTextField
            name="name"
            control={control}
            label={t("forms.company.name")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={6}>
          <ControlledTextField
            name="street"
            control={control}
            label={t("forms.company.street")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={6}>
          <ControlledTextField
            name="city"
            control={control}
            label={t("forms.company.city")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={6}>
          <ControlledTextField
            name="postcode"
            control={control}
            label={t("forms.company.postcode")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={6}>
          <Controller
            name="country"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                id="countrySelect"
                value={value}
                options={countries}
                fullWidth
                idKey="code"
                labelKey="name"
                inputLabel={t("forms.company.country")}
                onChange={onChange}
                name={name}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item container xs={6}>
          <ControlledTextField
            name="phone"
            control={control}
            label={t("forms.company.phone")}
            fullWidth
            disabled={!country}
          />
        </Grid>
        <Grid item container xs={6}>
          <VatTextField
            name="nip"
            control={control}
            label={t("forms.company.nip")}
            fullWidth
            disabled={!country}
            mask={vatFormats.find(v => v.code === country)?.format || ""}
          />
          {/*<ControlledTextField*/}
          {/*  disabled={!country}*/}
          {/*  // InputProps={{*/}
          {/*  //   inputRef: countryInputRef*/}
          {/*  // }}*/}
          {/*  name="nip"*/}
          {/*  control={control}*/}
          {/*  label={t("forms.company.nip")}*/}
          {/*  fullWidth*/}
          {/*/>*/}
        </Grid>
        {
          useCurrency &&
          <>
            <Divider className="w-full mb-4" />
            <Grid item container xs={6}>
              <Controller
                name="currency"
                control={control}
                render={({ field: { onChange, value, name } }) => (
                  <Autocomplete
                    id="currencySelect"
                    value={value}
                    options={options}
                    name={name}
                    idKey="code"
                    labelKey="name"
                    inputLabel={t("forms.currency")}
                    fullWidth
                    onChange={onChange}
                    disableClearable
                  />
                )}
              />
            </Grid>
          </>
        }
      </Grid>

      {!noDivider && <Divider className="mt-2 -mx-6 mb-6" />}

      <Grid item container justifyContent="flex-end">
        {useCancel && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            disabled={!isDirty && !onCancel}
            className="mr-2"
          >
            {t("common.cancel")}
          </Button>
        )}
        <Button
          isSubmit
          name="submit"
          variant="contained"
          disabled={!isDirty}
          icon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {!isSubmitting && t("common.save")}
        </Button>
      </Grid>
    </form>
  );
};

CompanyDetailsForm.propTypes = {
  onSubmit: func.isRequired,
  defaultValues: object,
  noDivider: bool,
  useCancel: bool,
  onCancel: func,
  useCurrency: bool,
};
