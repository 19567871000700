import { CELL_WIDTHS } from "@utils/settings";
import { useStructureStore } from "./stores";
import { useMemo } from "react";

export const useTimelineBarChartWidth = ({
  isDraggable,
  startWeek,
  endWeek,
  startMonth,
  endMonth,
  offsetLeft: oL,
  offsetRight: oR,
}) => {
  
  const structure = useStructureStore();
  
  const { settings, totalDevelopmentTime } = structure;
  const { expandedTimelineMonths } = settings;
  
  const exceeds = useMemo(() => (
    endMonth > totalDevelopmentTime
  ), [totalDevelopmentTime, endMonth]);
  
  const offsetLeft = useMemo(() => {
    if(isDraggable)
      return 0;
    const startMonthExpanded = structure.settings.isTimelineMonthExpanded(startMonth);
    
    return (
      startMonthExpanded
        ? 0
        : (
        typeof oL === "number" ? oL : startWeek%4
      ) / 4 * CELL_WIDTHS.VALUE.SMALL
    );
  }, [isDraggable, expandedTimelineMonths?.size, startMonth, startWeek]);
  
  const offsetRight = useMemo(() => {
    if(isDraggable)
      return 0;
    const endMonthExpanded = structure.settings.isTimelineMonthExpanded(endMonth);
    
    return (
      endMonthExpanded
        ? 0
        : (
        typeof oR === "number" ? oR : (3 - endWeek%4)
      ) / 4 * CELL_WIDTHS.VALUE.SMALL
    );
  }, [isDraggable, expandedTimelineMonths?.size, endMonth, endWeek])
  
  const width = useMemo(() => {
    if(isDraggable)
      return CELL_WIDTHS.VALUE.SMALL;
    const maxEndMonth = Math.min(totalDevelopmentTime-1, endMonth);
    
    const startMonthExpanded = structure.settings.isTimelineMonthExpanded(startMonth);
    const endMonthExpanded = structure.settings.isTimelineMonthExpanded(endMonth);
    const expandedMonths = structure.settings.expandedTimelineMonthsBetween(startMonth, maxEndMonth);
    const expandedWeeks = startMonthExpanded && startMonth === endMonth
      ? ( endWeek - startWeek + 1 )
      : (
        expandedMonths * 4 - (
          (startMonthExpanded ? startWeek%4 : 0) +
          (endMonthExpanded ? (3 - (endWeek%4)) : 0)
        )
      );
    
    const months = maxEndMonth - startMonth + 1 - expandedMonths;
    
    return months * CELL_WIDTHS.VALUE.SMALL + expandedWeeks * CELL_WIDTHS.VALUE.TINY
  }, [isDraggable, expandedTimelineMonths?.size, startMonth, endMonth, startWeek, endWeek, totalDevelopmentTime, offsetLeft]);
  
  return {
    exceeds,
    offsetLeft,
    offsetRight,
    width,
  }
}