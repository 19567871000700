import { useMemo } from "react";
import { bool, number, string } from "prop-types";
import { observer } from "mobx-react";
import { useTimelineBarChartWidth } from "@hooks";
import { getTimelineBarChartHeight } from "@utils";
import useStyle from "./TimelineChartBar.style";
import classnames from "classnames";

export const TimelineChartBar =  observer(({
  color,
  value,
  isPlaceholder,
  ...hookProps
}) => {
  
  // const {t} = useTranslation();
  const classes = useStyle();
  
  const { width, offsetLeft, offsetRight, exceeds } = useTimelineBarChartWidth(hookProps);
  const height = useMemo(() => getTimelineBarChartHeight(value), [value]);
  
  return (
    <div
      style={{
        width,
        paddingLeft: offsetLeft || undefined,
        paddingRight: offsetRight || undefined,
      }}
      className={classnames("tm-bar", classes.container, isPlaceholder && "semi-transparent", exceeds && !isPlaceholder && classes.containerExceeds)}
    >
      <div
        style={{backgroundColor: color, height }}
        className={classnames(classes.block, exceeds && !isPlaceholder && classes.exceeds)}
      />
    </div>
  );
});

TimelineChartBar.propTypes = {
  value: number.isRequired,
  color: string.isRequired,
  startWeek: number.isRequired,
  startMonth: number.isRequired,
  endWeek: number.isRequired,
  endMonth: number.isRequired,
  addPaddingLeft: bool,
  addPaddingRight: bool,
  isPlaceholder: bool,
};