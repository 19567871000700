import { setCompanySettingsQuery } from "@query";
import { PROJECT_COMPANY_SETTINGS } from "@client";
import { useState } from "react";
import { useProjectEditorStore } from "@hooks";
import { useParams } from "react-router-dom";

export const useBreakdownValueAlert = () => {
  const editorStore = useProjectEditorStore();
  const { projectUuid } = useParams();
  
  const [valueAlertVisible, showAlertVisible] = useState(false);
  
  const handleValueAlertClose = async (e, lock) => {
    showAlertVisible(false);
    if (lock) {
      editorStore.blockedValueAlert = true;
      await setCompanySettingsQuery(PROJECT_COMPANY_SETTINGS.VALUE_ALERT(projectUuid), true)
    }
  };
  
  const handleValueAlertOpen = () => {
    // if(!editorStore?.blockedValueAlert)
    setTimeout(
      () =>
        showAlertVisible(true),
      100
    );
  }
  
  return {
    valueAlertVisible,
    handleValueAlertClose,
    handleValueAlertOpen,
  }
}