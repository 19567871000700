import { useState } from "react";
import { func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dialog } from "@components";
import { Link } from "@material-ui/core";

export const DomainInfoDialog = ({ onClose, domainName }) => {
  const { t } = useTranslation();

  const [acknowledged, setAcknowledged] = useState(false);

  return (
    <Dialog
      open
      title={t("views.settings.projects.domain_dialog.title")}
      actions={
        <Button
          onClick={onClose}
          disabled={!acknowledged}
          variant="contained"
          color="primary"
        >
          {t("common.close")}
        </Button>
      }
    >
      <p className="mb-4">
        {t("views.settings.projects.domain_dialog.subtitle")}
      </p>
      <p>{t("views.settings.projects.domain_dialog.please")}</p>
      <ol>
        <li className="my-1">
          {t("views.settings.projects.domain_dialog.steps.1")}
        </li>
        <li className="my-1">
          {t("views.settings.projects.domain_dialog.steps.2")}
        </li>
        <li className="my-1">
          {t("views.settings.projects.domain_dialog.steps.3-1")}
          <strong>{` ${domainName} `}</strong>
          {t("views.settings.projects.domain_dialog.steps.3-2")}
        </li>
      </ol>
      <p className="mb-4">
        {t("views.settings.projects.domain_dialog.final1")}{" "}
        <Link href="mailto:support@apropo.io">{"support@apropo.io"}</Link>
        {" - "}
        {t("views.settings.projects.domain_dialog.final2")}
      </p>

      <Checkbox
        name="acknowledged"
        label={t("views.settings.projects.domain_dialog.checkbox")}
        checked={acknowledged}
        onChange={() => setAcknowledged(!acknowledged)}
      />
    </Dialog>
  );
};

DomainInfoDialog.propTypes = {
  domainName: string,
  onClose: func,
};
