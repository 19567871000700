import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  grid: {
    display: "grid",
    gridTemplateRows: "1fr",
  },
  flexbox: {
    display: "flex",
  },
  columnFlexbox: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyle;
