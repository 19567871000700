import { useEffect, useState } from "react";
import { string, shape } from "prop-types";
import { useTranslation } from "react-i18next";
import { TagList } from "@components";
import { Divider, Grid, Link, Paper } from "@material-ui/core";
import { format, formatDistance } from "date-fns";
import { universalDateParser } from "@utils";
import { getProjectDetailsQuery } from "@query";
import ReactCountryFlag from "react-country-flag";
import useStyle from "./ProjectDetails.style";

export const ProjectDetails = ({
  projectUuid,
  userInfo
}) => {
  
  const { t } = useTranslation();
  const classes = useStyle();

  const [projectDetails, setProjectDetails] = useState(null);

  useEffect(() => {
    (async () => {
      const details = await getProjectDetailsQuery(projectUuid);
      setProjectDetails(details);
    })();
  }, []);

  return (
    <Grid item container xs={3} className="h-full">
      <Paper className={classes.paper}>
        <Grid item container direction="column" alignItems="flex-start">
          {projectDetails && (
            <>
              <p className={classes.sectionTitle}>
                {t("views.session.info_pr")}
              </p>
              <p className="mb-2 font-bold">{projectDetails.name}</p>
              {Boolean(
                projectDetails.category && projectDetails.category.title
              ) && (
                <p className="mb-2 transparent-3">{projectDetails.category.title}</p>
              )}
              <p className={classes.text}>
                {t("common.created_on")}{" "}
                {format(
                  universalDateParser(projectDetails.createdAt),
                  "dd MMM yyyy"
                )}
              </p>
              {projectDetails.dueOn && (
                <p className={classes.text}>
                  {formatDistance(
                    universalDateParser(projectDetails.dueOn),
                    new Date()
                  )}
                </p>
              )}
              <Grid item container className="mt-4">
                <TagList tags={projectDetails.tags} />
              </Grid>
            </>
          )}
          <Divider className={classes.divider} />
          {userInfo && (
            <>
              <p className="transparent-3 font-bold text-xs mb-2 uppercase">
                {t("views.session.info_se")}
              </p>
              {userInfo.email ? (
                <Link
                  href={"mailto:" + userInfo.email}
                  className="mb-2"
                >
                  {userInfo.email}
                </Link>
              ) : (
                <p className={classes.text}>
                  {t("views.session.anonymous")}
                </p>
              )}
              <p className={classes.text}>
                {userInfo.ip}
              </p>
              <p className={classes.text}>
                {format(universalDateParser(userInfo.createdAt), "dd MMM yyyy")}
              </p>
              <Grid item container>
                {userInfo && userInfo.country ? (
                  <ReactCountryFlag
                    countryCode={userInfo.country.toUpperCase()}
                    svg
                    className={classes.flag}
                    style={{
                      height: "1.5em",
                      width: "auto",
                    }}
                  />
                ) : (
                  ""
                )}
                <p className={`${classes.text} ml-2`}>
                  {userInfo.city || ""}
                  {userInfo.city ? ", " : ""}
                  {userInfo.region || ""}
                </p>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

ProjectDetails.propTypes = {
  projectUuid: string,
  userInfo: shape({
    createdAt: string,
    city: string,
    region: string,
    country: string,
    email: string,
    ip: string.isRequired,
  }),
};
