import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  button: {
    textTransform: "none !important",
    justifyContent: "center !important",
    "& p": {
      fontWeight: 400,
    },
    "&:first-child": {
      borderTopRightRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
    },
    "&:last-child": {
      borderLeft: "none",
      borderTopLeftRadius: "0 !important",
      borderBottomLeftRadius: "0 !important",
    },
    "&:not(:first-child):not(:last-child)": {
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
    },
  },
  empty: {
    backgroundColor: theme.props.backgroundSecondary,
  },
  emptyLight: {
    color: theme.props.text.lighter,
  },
}));

export default useStyle;
