import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  title: {
    fontSize: "18px !important",
  },
  button: {
    justifyContent: "center !important",
  },
  confirmTitle: {
    marginBottom: 8,
  },
}));

export default useStyle;
