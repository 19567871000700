export const getTimelineChartSegments = (presentedTimeline, mta, modifier, usesTimelinePercent) => {
  const segments = [];
  let startWeek= -1;
  let override = false;
  for(let i= 0; i <= presentedTimeline?.weeks.length; i++) {
    const hasHours = presentedTimeline?.weeks[i]?.hours;
    const hasOverride = presentedTimeline?.weeks[i]?.isOverridden && hasHours;
    const isLast = i === presentedTimeline?.weeks.length;
    const breakSegment = !hasHours;
    
    if(startWeek < 0 && presentedTimeline?.weeks[i]?.hours)
      startWeek = i;
    else if (startWeek >= 0 && (breakSegment || isLast)) {
      const endWeek = i - 1;
      const startMonth = Math.floor(startWeek / 4);
      const endMonth = Math.floor(endWeek / 4);
      
      segments.push({
        startWeek,
        endWeek,
        startMonth,
        endMonth,
        offsetLeft: startWeek > startMonth*4 ? (startWeek - startMonth * 4)%4 : undefined,
        offsetRight: endWeek < (endMonth+1)*4 ? ((endMonth+1) * 4 - endWeek - 1)%4 : undefined,
        weight: usesTimelinePercent || override
          ? Math.round(
            (presentedTimeline?.totalHoursBetween(startWeek, endWeek) || 0)
            / (startWeek !== endWeek ? endWeek-startWeek : 1)
            / modifier
            * 4
          )
          : mta
      });
      startWeek = hasHours ? i : -1;
      override = false;
    }
    override = !override ? Boolean(hasOverride) : true;
  }
  
  return segments;
}