import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useMsalLogin, useStores, useAutoLogin } from "@hooks";
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { home, login, register, editor, jiraAuth } from "@paths";
import { Grid, Link, BottomNavigationAction, BottomNavigation, Paper, CircularProgress } from "@material-ui/core";
import { Alert } from "@components";
import { Contact } from "@dialogs";
import { ApropoLogo } from "@assets";
import { usePathChecker } from "@layouts/LandingLayout/usePathChecker";
import classnames from "classnames";
import useStyle from "./LandingLayout.style";

export const LandingLayout = observer(() => {
  const classes = useStyle(isMobile);
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);
  const [openContact, setContactModalState] = useState(false);
  
  const {
    current,
    hideNavigation,
    hideContact,
    jiraAuthMatch,
    useRedirector,
    openProject,
    loginWorkspaceMatch,
    loginWorkspaceProjectMatch,
  } = usePathChecker();
  
  const autoLogin = useAutoLogin();
  
  const {
    msalAlertVisible,
    closeMsalAlert
  } = useMsalLogin(isLoading, setLoading);

  useEffect(() => {
    console.log("autoLogin start");//, current, useRedirector, openProject);
    (async () => {
      try {
        if (jiraAuthMatch)
          stateStore.setMemorizedAction(jiraAuth + location.search);
        
        await autoLogin();
        
        if (useRedirector) {
          const { workspaceUuid } = loginWorkspaceMatch?.params || {};
          const projectId = loginWorkspaceProjectMatch
            ? loginWorkspaceProjectMatch.params.projectId
            : null;
          
          if (workspaceUuid) {
            if (projectId) {
              if (openProject && openProject.isExact)
                stateStore.setMemorizedAction(editor(projectId), workspaceUuid);
              else stateStore.setMemorizedAction(home, projectId);
            } else stateStore.setMemorizedAction(home);
          }
        }
      } catch(e) {
        console.log("autoLogin error: ",e);
        await userStore.clearUserData();
        if (jiraAuthMatch)
          navigate(login, { replace: true });
      }
      setLoading(false);
    })();
  }, []);
  
  useEffect(() => {
    if (stateStore.memorizedAction && userStore.isLoggedIn) {
      navigate(stateStore.memorizedAction, { replace: true });
      stateStore.setMemorizedAction();
    }
  }, [userStore.isLoggedIn, stateStore.memorizedAction]);
  

  return (
    <div className={classes.root}>
      <div
        className={classnames(classes.root, classes.background, {
          [classes.pattern]: jiraAuthMatch,
          [classes.mockup]: !jiraAuthMatch,
        })}
      />
      <Contact open={openContact} onClose={() => setContactModalState(false)} />
      <div
        className={classnames(classes.root, {
          [classes.backdropPattern]: jiraAuthMatch,
          [classes.backdropMockup]: !jiraAuthMatch,
        })}
      >
        <div className={classes.container}>
          <Paper elevation={3} className={classes.content}>
            <Grid
              item
              container
              direction="column"
              wrap="nowrap"
              alignItems="center"
              justifyContent={isLoading ? "center" : "flex-start"}
              className={classnames(classes.part, hideNavigation && classes.partNoNavigation)}
            >
              {!isLoading && !hideNavigation && (
                <BottomNavigation
                  value={current}
                  onChange={(e, path) => navigate(path)}
                  aria-label="landing page navigation"
                  role="navigation"
                  showLabels
                  className={classes.navigation}
                >
                  <BottomNavigationAction
                    aria-label="register page link"
                    label={t("routes.register")}
                    value={register}
                  />
                  <BottomNavigationAction
                    aria-label="login page link"
                    label={t("routes.login")}
                    value={login}
                  />
                </BottomNavigation>
              )}
              {isLoading ? (
                <CircularProgress aria-label="progress indicator" className="absolute -mt-5 top-50" />
              ) : (
                <Grid
                  item container
                  justifyContent="center"
                  className={classes.outletWrapper}
                >
                  <Outlet />
                </Grid>
              )}
            </Grid>
            <Grid
              item container
              direction="column"
              wrap="nowrap"
              alignItems="center"
              className={`${classes.part} ${classes.logoContainer}`}
            >
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className="h-full"
              >
                <ApropoLogo variant={isMobile ? "small" : "big"} />
              </Grid>
              {!isMobile && !hideContact && (
                <p className={classes.text}>
                  {t("forms.try_product_1")}{" "}
                  <Link onClick={() => setContactModalState(true)}>
                    {t("forms.try_product_here")}
                  </Link>
                  {", "}
                  {t("forms.try_product_2")}{" "}
                  <Link href="mailto:hello@apropo.io">{"hello@apropo.io"}</Link>
                </p>
              )}
            </Grid>
          </Paper>
        </div>
      </div>

      <Alert
        isOpen={msalAlertVisible}
        title={t("errors.msal.alert.title")}
        acceptText={t("common.close")}
        onAccept={closeMsalAlert}
      >
        {t("errors.msal.alert.content")}
      </Alert>
    </div>
  );
});
