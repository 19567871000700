import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  point: {
    margin: "4px 0",
    textAlign: "center",
    fontSize: 12,
  },
  text: {
    margin: "8px 0",
    textAlign: "center",
  },
  contactLink: {
    marginBottom: 24,
    "@media (min-width: 600px)": {
      position: "absolute",
      bottom: 0,
    },
  },
  offer: {
    marginTop: 16,
    marginBottom: 8,
    color: theme.props.text.alt2,
    textDecoration: "underline",
    textAlign: "center",
  },
}));

export default useStyle;
