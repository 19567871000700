export function parseSessionTime(time) {
  if(!time) return "00:00"
  
  const h = [
    Math.floor(time % 60), //s
    Math.floor((time / 60) % 60), //m
  ];
  
  if(time > 60*60)
    h.push(Math.floor(time / 60 / 60))
  
  
  return h
    .reverse()
    .map((t) => (
      t >= 10 ? t.toString() : "0" + t
    ))
    .join(":");
}