import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { PageTitle } from "@components";
import { NotificationsTable } from "./components/NotificationsTable/NotificationsTable";
import { Container } from "@material-ui/core";

export const Notifications = observer(() => {
  const { messageStore } = useStores();
  const { t } = useTranslation();

  const { newMessages } = messageStore;

  return (
    <>
      <PageTitle
        title={
          newMessages.length
            ? t("routes.notifications_new", { new: newMessages.length })
            : t("routes.notifications")
        }
        pinned
      />
      <Container>
        <NotificationsTable />
      </Container>
    </>
  );
});
