import { db } from "@client";

export async function updateUserToken(token) {
  const timestamp = new Date().getTime();
  const users = await db.user.toArray();
  
  if (!users?.[0])
    return;
  
  await db.user.update(users[0].id, {
    userId: users[0].id,
    token,
    timestamp,
    persist: users[0].persist,
  });
}