import { APIMethod, fetchData } from "@client";

export async function getCompanyLogoQuery() {
  try {
    const res = await fetchData(
      "/company/logo",
      APIMethod.GET
      // { noAuth: true }
    );

    if (res.results.logo.length > 8) return res.results.logo;
  } catch (e) {
    return "";
  }
}
