import { func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, ControlledTextField } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailRegex } from "./emailRegex";

const contactFormSchema = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, t("errors.email.required"))
      .required(t("errors.email.required")),
    name: yup
      .string()
      .required(t("errors.name.required"))
      .max(64, t("errors.name.max")),
    phone: yup.string().nullable(true),
    company: yup
      .string()
      .required(t("errors.company.name.required"))
      .max(64, t("errors.company.name.max")),
    message: yup.string().required("" /*t("errors.message.required")*/),
  });

export const ContactForm = ({ onSubmit, defaultMessage }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(contactFormSchema(t)),
    defaultValues: {
      message: defaultMessage || "",
      email: "",
      company: "",
      phone: "",
      name: "",
    },
  });

  const submitHandler = async (data) => {
    await onSubmit(data);
    resetForm();
  };

  return (
    <form
      autoComplete="on"
      aria-label="companyDetails form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container spacing={1}>
        <Grid item container xs={12}>
          <ControlledTextField
            name="name"
            control={control}
            label={t("forms.name")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12}>
          <ControlledTextField
            name="email"
            control={control}
            label={t("forms.email")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={6}>
          <ControlledTextField
            name="phone"
            control={control}
            label={t("forms.contact.phone")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={6}>
          <ControlledTextField
            name="company"
            control={control}
            label={t("forms.company.name")}
            fullWidth
          />
        </Grid>
        <Grid item container xs={12}>
          <ControlledTextField
            name="message"
            control={control}
            label={t("forms.contact.message")}
            multiline
            fullWidth
          />
        </Grid>
      </Grid>

      <Divider className="mt-2 -mx-6 mb-6" />

      <Grid item container justifyContent="flex-end">
        <Button
          isSubmit
          name="submit"
          variant="contained"
          endIcon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {t("common.send")}
        </Button>
      </Grid>
    </form>
  );
};

ContactForm.propTypes = {
  onSubmit: func.isRequired,
  defaultMessage: string,
};
