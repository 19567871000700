import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  progress: {
    position: "absolute",
    left: "50%",
    top: "50%",
    margin: "-20px 0 0 -20px",
  },
}));

export default useStyle;
