import { memo } from "react";
import { bool, number, string, oneOfType } from "prop-types";
import { ValueDivider } from "@components";
import { NumericFormat } from "react-number-format";

export const ValueCellReadOnlyContent = memo(({
  value,
  valueMax,
  displayMin=true,
  displayMax,
  symbolStart,
  symbolEnd,
  showDecimals,
  displayMinMaxSeparator = true,
  allowNegative=false,
  decimals=2,
}) => {
  const formatterProps = {
    allowNegative,
    // fixedDecimalScale: showDecimals,
    decimalScale: showDecimals ? decimals : 0,
    prefix: symbolStart,
    suffix: symbolEnd,
    thousandSeparator: " ",
    valueIsNumericString: !isNaN(Number(value)),
    displayType: "text",
    className: "w-full",
  };
  
  const isValueText = value !== undefined && isNaN(Number(value)) && value !== null;
  const isValueMaxText = value !== undefined && isNaN(Number(valueMax)) && value !== null;

  return (
    <>
      {
        displayMin && (
          isValueText
            ? <span
              className="w-full"
              style={{ textAlign: displayMax ? "right" : "center" }}
            >
              {symbolStart}
              {value}
              {symbolEnd}
            </span>
            : <NumericFormat
              value={typeof value === "undefined" ? "" : value}
              style={{ textAlign: displayMax ? "right" : "center" }}
              {...formatterProps}
            />
        )
      }
      { displayMin && displayMax && <ValueDivider display={displayMinMaxSeparator} /> }
      { displayMax && (
        isValueMaxText
          ? <span className="w-full" style={{ textAlign: displayMin ? "left" : "center" }}>
            {symbolStart}
            {valueMax}
            {symbolEnd}
          </span>
          : <NumericFormat
            value={typeof valueMax === "undefined" ? "" : valueMax}
            style={{ textAlign: displayMin ? "left" : "center" }}
            {...formatterProps}
          />
      )}
    </>
  )
});

ValueCellReadOnlyContent.propTypes = {
  value: oneOfType([number, string]),
  valueMax: oneOfType([number, string]),
  displayMin: bool,
  displayMax: bool,
  displayMinMaxSeparator: bool,
  symbolStart: string,
  symbolEnd: string,
  // parser: func,
  allowNegative: bool,
  showDecimals: bool,
  decimals: number,
};