import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  
  root: {
    borderRadius: 5,
    border: `1px solid ${theme.props.border}`,
    backgroundColor: theme.props.backgroundSecondary,
    marginTop: 12,
  },
  button: {
    color: theme.palette.text.secondary,
    "&.ql-active": {
      color: theme.palette.primary.main
    }
  },
  divider: {
    height: 24,
    marginLeft: 4,
    marginRight: 4
  }
}));

export default useStyle;