import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { Badge, IconButton } from "@material-ui/core";
import { Notifications, NotificationsNone } from "@material-ui/icons";
import { NotificationItem } from "@components/AppToolBar/components";
import { ClearButton, PopMenu } from "@components";
import { ArrowRight } from "@assets";
import { notifications } from "@paths";
import { universalDateParser } from "@utils";
import useStyle from "./MenuNotifications.style";

let timer;

export const MenuNotifications = observer(() => {
  const { messageStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyle();

  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, show] = useState(false);

  const { newMessages } = messageStore;

  const loadMessages = async () => {
    clearTimeout(timer);
    await messageStore.getNew();
    timer = setTimeout(loadMessages, 5 * 60 * 1000);
  };

  useEffect(() => {
    loadMessages();
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <IconButton
        className="p-1"
        ref={setAnchorEl}
        onClick={() => show(!visible)}
        aria-label="Show notifications"
      >
        {newMessages && newMessages.length ? (
          <Badge
            badgeContent={newMessages && newMessages.length}
            overlap="circular"
            aria-label="new messages badge"
            className={classes.badge}
            max={9}
          >
            <Notifications />
          </Badge>
        ) : (
          <NotificationsNone />
        )}
      </IconButton>
      <PopMenu
        show={visible}
        anchor={anchorEl}
        onClickAway={() => show(false)}
        style={{ width: 395, minHeight: 0 }}
      >
        {newMessages && newMessages.length ? (
          <div className={classes.messageContainer}>
            {newMessages.map((mes) => {
              const { createdAt, ...rest } = mes;
              const parsedDate = new Date(
                universalDateParser(createdAt).getTime() -
                  new Date().getTimezoneOffset() * 1000 * 60
              );

              return (
                <NotificationItem
                  key={mes.id}
                  id={mes.id}
                  date={parsedDate}
                  {...rest}
                />
              );
            })}
          </div>
        ) : (
          <p className={classes.noMessages}>{t("notifications.none")}</p>
        )}
        <ClearButton
          className={classes.seeAllButton}
          icon={<ArrowRight />}
          to={notifications}
          onClick={() => show(false)}
        >
          {t("notifications.older")}
        </ClearButton>
      </PopMenu>
    </>
  );
});
