import { useState } from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { MAX_WORKTYPE_LENGTH } from "project-structure";
import { /*usePresetFieldNames,*/ useStructureStore } from "@hooks";
import { TextClearInput } from "@components";
import classnames from "classnames";

export const TitleInput = ({ allowEdition, useExpandCell }) => {
  const {t} = useTranslation();
  const structure = useStructureStore();
  // const { getFieldName } = usePresetFieldNames(isProposal);
  
  const [value, setValue] = useState(structure.settings.fixedCostTitle || t("views.editor.summary_table.fixed_costs"))
  const [focused, setFocused] = useState(false)
  
  const handleTitleChange = (v) => {
    setValue(v);
  };
  
  const commitTitleChange = () => {
    structure.settings.setFixedCostTitle(value);
    setValue(value || t("views.editor.summary_table.fixed_costs"));
    setFocused(false);
  }
  
  const handleTitleFocus = () => setFocused(true);
  
  if(!allowEdition)
    return (
      <span className={ classnames(
        "preset-summaryTitleText",
        !useExpandCell && "ml-3"
      )}>
        {value}
      </span>
    )
  
  return (
    <TextClearInput
      value={value}
      focused={focused}
      onFocus={handleTitleFocus}
      onChange={handleTitleChange}
      onAccept={commitTitleChange}
      acceptOnClickAway
      confirmOnEnter
      placeholder={t("views.editor.summary_table.fixed_costs")}
      containerClass="name"
      className={classnames("w-max -m-0-5", !useExpandCell && "ml-2")}
      maxLength={MAX_WORKTYPE_LENGTH}
      preventClickAwayOnElement="#workTypeSelector"
    />
  )
}

TitleInput.propTypes = {
  allowEdition: bool,
  useExpandCell: bool,
};