import { func, string } from "prop-types";
import { Button, ControlledTextField } from "@components";
import { CircularProgress, Divider, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

const newBoardFormSchema = (t) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("errors.workspace.required"))
      .min(4, t("errors.workspace.min"))
      .max(64, t("errors.workspace.max")),
  });

export const NewBoardForm = ({ onSubmit, defaultName }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(newBoardFormSchema(t)),
    defaultValues: {
      name: defaultName || "",
    },
  });

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="new board form"
      onSubmit={handleSubmit(submitHandler)}
      className="mt-1"
    >
      <Grid item container direction="column" alignItems="center">
        <ControlledTextField
          name="name"
          control={control}
          label={t("views.workspace.name")}
          helperText={t("views.workspace.create_first")}
          fullWidth
        />

        <h1 className="mt-4 mb-8">
          {t("views.workspace.start_trial")}
        </h1>

        <p className="mb-1 text-center font-lighter">
          {t("views.workspace.desc_1")}
        </p>
        <p className="mt-4 text-center font-lighter">
          {t("views.workspace.desc_2")}
        </p>

        <Button
          isSubmit
          name="submit"
          variant="contained"
          fullWidth
          className="mt-6"
          icon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={24}
                color="inherit"
              />
            ) : undefined
          }
        >
          {!isSubmitting && t("views.workspace.start")}
        </Button>
      </Grid>

      <Divider className="mt-4 mb-6" />

      <Grid item container direction="column" alignItems="center">
        <p className="text-xs font-lighter my-2">
          {t("views.workspace.info_1")}
        </p>
        <p className="text-xs font-lighter my-2">
          {t("views.workspace.info_2")}
        </p>
        <p className="text-xs font-lighter my-2">
          {t("views.workspace.info_3")}
        </p>
        <p className="text-xs font-lighter my-2">
          {t("views.workspace.info_4")}
        </p>
      </Grid>
    </form>
  );
};

NewBoardForm.propTypes = {
  onSubmit: func.isRequired,
  defaultName: string,
};
