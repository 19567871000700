import { createContext } from "react";
import { instanceOf, node } from "prop-types";
import { action, makeObservable, observable } from "mobx";
import {
  fetchLibraryQuery,
  getCompanySettingsQuery,
  getDefaultRatesQuery,
  initLibraryQuery,
  setCompanySettingsQuery,
} from "@query";
import {
  StructureModel,
  DEFAULT_PROJECT_SETTINGS as D,
} from "project-structure";
import { COMPANY_SETTINGS } from "@client";
import { LIBRARY_LEVELS } from "@utils";

export class LibraryStore {
  constructor() {
    makeObservable(this);
  }

  @observable hasSuggestions = false;
  @observable pending = false;
  @action setPendingState = (state) => (this.pending = state);

  libraryWorkTypes = undefined;

  @action
  async initStructure(workTypes) {
    let root = await fetchLibraryQuery(this.pending);
    if (!root?.module?.length && !this.pending) {
      await initLibraryQuery();
      root = await fetchLibraryQuery(this.pending);
    }

    let libraryWorkTypes = (
      await getCompanySettingsQuery(COMPANY_SETTINGS.LIBRARY_WT)
    )?.filter((id) => id);

    let librarySettings =
      this.librarySettings || (await this.getLibrarySettings());

    if (!libraryWorkTypes?.length) {
      libraryWorkTypes = [2, 3, 5, 6];
      await this.setLibraryWorkTypes(libraryWorkTypes);
    }

    const defaultCurrency = await getCompanySettingsQuery(
      COMPANY_SETTINGS.CURRENCY_DEF
    );
    const defaultRates = await getDefaultRatesQuery();
    const defaultTimeEquiv = await getCompanySettingsQuery(
      COMPANY_SETTINGS.TIME_EQUIV
    );

    const existingLibraryWorkTypes = libraryWorkTypes.filter((id) =>
      workTypes.find((tag) => tag.id === id)
    );

    const mta =
      defaultTimeEquiv &&
      Number(defaultTimeEquiv.day) * Number(defaultTimeEquiv.month);
    const dayModifier = defaultTimeEquiv && Number(defaultTimeEquiv?.day);

    return StructureModel.create({
      sections: LIBRARY_LEVELS
        .filter((level) => !this.pending || root[level]?.length)
        .map((level, index) => ({
          id: `${level}Collection`, //root[0].id.toString(),
          name: level,
          startingLevel: index + 1,
          status: 1,
          children: root[level] || [],
          workTypes: existingLibraryWorkTypes.map((id) => ({ id })),
          libraryTask: true
          // {id:2},{id:3},{id:4},{id:6},{id:20},{id:21},{id:25}
        })),
      workTypes: existingLibraryWorkTypes.map((id, i) => {
        const workType = workTypes.find((tag) => tag.id === id);
        return {
          id: workType.id,
          name: workType.name,
          inBreakdown: true,
          timelineOrder: i,
          rate: (defaultRates?.[workType.id] || 0) / 60, // this will be set up in the company settings and should be retrieved here
        };
      }),
      visibility: {
        version: true,
      },
      settings: {
        ...D,
        currency: defaultCurrency || "USD",
        roundPrice: false,
        sectionFormat: 3,
        descriptionMode: 1,
        showPrices: librarySettings ? librarySettings.showPrices : false,
        timeModifier: librarySettings
          ? librarySettings?.timeModifier > 1
            ? 0
            : librarySettings?.timeModifier
          : 1,
        useMinMax: librarySettings ? librarySettings.useMinMax : false,
        dayModifier,
        mta: {min: mta, avg: mta, max: mta},
      },
    });
  }

  @action async setLibraryWorkTypes(value) {
    if (!value) return;
    if (value.join(",") !== this.libraryWorkTypes?.join(",")) {
      this.libraryWorkTypes = value;
      return await setCompanySettingsQuery(COMPANY_SETTINGS.LIBRARY_WT, value);
    }
  }

  @action async getLibrarySettings() {
    return (
      this.librarySettings ||
      (this.librarySettings = await getCompanySettingsQuery(
        COMPANY_SETTINGS.LIBRARY_SETTINGS
      ))
    );
  }

  @action async setLibrarySettings(settings) {
    return await setCompanySettingsQuery(
      COMPANY_SETTINGS.LIBRARY_SETTINGS,
      settings
    );
  }
}

export const LibraryStoreContext = createContext(null);
export const LibraryStoreProvider = ({ children, libraryStore }) => (
  <LibraryStoreContext.Provider value={libraryStore}>
    {children}
  </LibraryStoreContext.Provider>
);

LibraryStoreProvider.propTypes = {
  children: node.isRequired,
  libraryStore: instanceOf(LibraryStore),
};
