import { useMemo } from "react";
import { bool, number } from "prop-types";
import { observer } from "mobx-react";
import { parsePrice, roundFloat } from "project-structure";
import { useStructureStore } from "@hooks";
import {
  CollapsePriceInnerCell
} from "@components/ProjectEditor/Tables/cells/CollapseValueCell/components/CollapsePriceInnerCell";

export const BillingInnerCell = observer(({
  value: unparsedValue,
  ...cellProps
}) => {
  const structure = useStructureStore();
  const { roundPrice, currencyObj} = structure.settings;
  
  const value = useMemo(() => (
    parsePrice(roundFloat(
      unparsedValue,
      roundPrice ? 3 : 0,
      !roundPrice
    ))
  ), [unparsedValue, roundPrice, roundPrice])
  
  return (
    <CollapsePriceInnerCell
      displayContent
      value={value}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      {...cellProps}
    />
  )
})

BillingInnerCell.propTypes = {
  value: number,
  narrow: bool,
  useBorder: bool,
  useSeparator: bool,
}