import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  messageButton: {
    margin: 4,
    width: 120,
  },
}));

export default useStyle;
