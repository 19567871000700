export function textOverflows(containerWidth, text, fontSize) {
  if(!containerWidth) return false;
  
  const el = document.createElement("div")
  el.style.position = "absolute"
  el.style.left = "-99in"
  el.style.whiteSpace = "nowrap"
  el.style.fontFamily = "${DEFAULT_FONT_NAME}, sans-serif";
  el.style.fontSize = fontSize;
  el.innerHTML = text
  
  document.body.appendChild(el)
  const width = el.clientWidth
  document.body.removeChild(el)
  return width > containerWidth ;
}