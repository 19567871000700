import { useMemo } from "react";
import { object } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore, useBudgetTrackingStore } from "@hooks";
import { CELL_WIDTHS } from "@utils";
import { CollapseValueCell, PriceCell, ValueCell } from "@components";
import { makeStyles } from "@material-ui/core/styles";
import { roundFloat } from "project-structure";

export const CostCell = observer(({ workType }) => {
  const structure = useStructureStore();
  const classes = useStyle();
  const { cost } = useBudgetTrackingStore();

  const {
    currencyObj,
    roundPrice,
    useMinMax,
    usedDecimals,
    usedRounding,
  } = structure.settings;
  const {
    parsedPrice,
    parsedBTPrice,
    btPriceDiff,
    btPriceDiffPercent
  } = workType;

  const colorText = useMemo(() => {
    if (parsedPrice.avg !== 0) {
      return btPriceDiff.avg > 0 ? classes.rowAccepted : classes.rowDanger;
    }
    return null;
  }, [btPriceDiff.avg]);
  
  const value = useMemo(
    () =>
      roundFloat(
        useMinMax ? parsedPrice.max || 0 : parsedPrice.avg || 0,
        usedRounding,
        false,
        usedDecimals
      ),
    [parsedPrice, useMinMax, usedRounding, usedDecimals]
  );
  
  const btPriceDiffs = useMemo(
    () =>
      roundFloat(
        (useMinMax ? btPriceDiff.max || 0 : btPriceDiff.avg || 0),
        usedRounding,
        false,
        usedDecimals
      ),
    [useMinMax, roundPrice, useMinMax]
  );

  const btPriceDiffPercents = useMemo(
    () =>
      roundFloat(
        (useMinMax ? btPriceDiffPercent.max || 0 : btPriceDiffPercent.avg || 0),
        usedRounding,
        false,
        usedDecimals
      ),
    [useMinMax, roundPrice, useMinMax]
  );


  return (
    <CollapseValueCell
      childrenWidth={CELL_WIDTHS.VALUE.TINY}
      mainCellWidth={CELL_WIDTHS.VALUE.LARGE}
      isOpened={cost}
      cell={
        <ValueCell
          widest
          displayContent
          value={btPriceDiffPercents}
          symbolEnd="%"
          showDecimals={!roundPrice}
          max={10000000}
          className={classes.cell}
          textPresetClass="preset-summaryText"
        />
      }
    >
      <PriceCell
        mini
        displayContent
        value={value}
        symbolStart={currencyObj.symbolStart}
        symbolEnd={currencyObj.symbolEnd}
        showDecimals={!roundPrice}
        max={10000000}
      />
      <ValueCell
        mini
        displayContent
        value={parsedBTPrice || 0}
        symbolStart={parsedBTPrice ? currencyObj.symbolStart : undefined}
        symbolEnd={parsedBTPrice ? currencyObj.symbolEnd : undefined}
        showDecimals={!roundPrice}
        max={10000000}
        textPresetClass={colorText}
      />
      <ValueCell
        mini
        displayContent
        value={btPriceDiffs}
        symbolEnd={currencyObj.symbolEnd}
        showDecimals={!roundPrice}
        textPresetClass={colorText}
        max={10000000}
        showValueSign
      />
      <ValueCell
        mini
        displayContent
        value={btPriceDiffPercents}
        symbolEnd="%"
        showDecimals={!roundPrice}
        textPresetClass={colorText}
        max={10000000}
        showValueSign
      />
    </CollapseValueCell>
  );
});

CostCell.propTypes = {
  workType: object.isRequired,
};

const useStyle = makeStyles(() => ({
  cell: {
    zIndex: 2,
  },
  rowDanger: {
    color: "#41BD56",
  },
  rowAccepted: {
    color: "#DE0909",
  },
}));
