import { useEffect, useMemo, useState } from "react";
import { bool, func, shape, string, number } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  applyCouponQuery,
  createPaymentMethodQuery,
  getPaymentMethodsQuery,
  updateSubscriptionQuery,
  setFirstSubscriptionQuery, setCompanyDetailsQuery, getCompanyDetailsQuery,
} from "@query";
import { add, format } from "date-fns";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  Alert,
  Dialog,
  Button,
  TextField,
  StaticImg,
  Link,
  InfoLabel,
  Accordion,
} from "@components";
import {
  CircularProgress,
  Grid,
  useTheme,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import useStyle from "./PaymentDialog.style";
import { settingsPaymentHistory } from "@paths";
import { DEFAULT_FONT_NAME, vatTypes } from "@utils";
import { CompanyDetailsForm } from "@forms";
import { CARD_BRAND_SRC, Pencil } from "@assets";

export const PaymentDialog = ({
  open,
  onClose,
  productInfo,
  trial,
  title,
  isUpdate,
  defaultPaymentMethod = "new",
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();

  const onlyCreateCard = useMemo(() => !productInfo, []);

  const [companyDetails, setCompanyDetails] = useState({  });
  const [companyDataEdited, setCompanyDataEdited] = useState(true);
  const [alertText, setAlertText] = useState("");
  const [alertSubText, setAlertSubText] = useState(null);
  const [processingPayment, setPaymentStatus] = useState(false);
  const [paymentSuccessful, setPaymentSuccess] = useState(false);
  const [cardElFocused, isFocused] = useState(false);
  const [isCardLoading, setCardLoading] = useState(false);
  // const [cardData, setCard] = useState(null);
  const [savedCards, setSavedCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(defaultPaymentMethod);
  const [couponVal, setCouponVal] = useState("");
  const [couponError, setCouponError] = useState(null);
  
  let CARD_OPTIONS;
  CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
      base: {
        iconColor: cardElFocused
          ? theme.palette.primary.main
          : theme.palette.text.primary,
        fontFamily: `'${ DEFAULT_FONT_NAME }', sans-serif`,
        fontSize: "16px",
        fontWeight: 400,
        color: theme.palette.text.primary,
        "::placeholder": {
          color: theme.palette.text.secondary,
        },
      },
      invalid: {
        iconColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
      complete: {
        iconColor: cardElFocused
          ? theme.palette.primary.main
          : theme.palette.text.primary,
      },
    },
  };

  const companyDataAlreadyFilled = useMemo(
    () => companyDetails?.nip && companyDetails?.city && companyDetails?.name,
    [companyDetails]
  );

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        setCardLoading(true);
        const details = await getCompanyDetailsQuery();
        setCompanyDetails(details);
        setCompanyDataEdited(!(details.country && details.name));

        const methods = await getPaymentMethodsQuery();
        // if(defaultPaymentMethod) {
        //  setCard(methods.find(x => x.id === defaultPaymentMethod));
        //  setSelectedCard(currentMethod.id);
        // }
        setSavedCards(methods);
        setCardLoading(false);
      } catch (e) {
        throw e;
      }
    })();
  }, []);

  const handleCouponChange = (e) => {
    setCouponError(null);
    setCouponVal(e.target.value);
  };

  const changeCompanyDetails = async (data) => {
    try {
      await setCompanyDetailsQuery(data);
      setCompanyDetails(data);
      setCompanyDataEdited(false);
    } catch (e) {
      if (vatTypes.find((v) => e.response?.data?.error?.[0]?.includes(v)))
        setAlertText(t("errors.stripe.invalid_vat"));
      else setAlertText(t("errors.network"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    // if(!onlyCreateCard) {
    //   setAlertText(t("views.new_project.payment_success"));
    //   setPaymentSuccess(true);
    //   return;
    // }

    setPaymentStatus(true);

    const coupon = couponVal?.trim();
    setCouponVal(coupon);

    if (coupon?.length) {
      setCouponError(null);
      try {
        await applyCouponQuery(coupon);
      } catch (e) {
        setCouponError(e.response?.data?.errors?.[0]?.message);
        if (e.response?.data?.errors?.[0]?.message !== "Coupon used!") {
          setPaymentStatus(false);
          return;
        }
      }
    }

    const cardElement = elements.getElement(CardElement);

    let error, paymentMethod;
    if (selectedCard === "new" || onlyCreateCard) {
      const res = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      error = res.error;
      paymentMethod = res.paymentMethod ? res.paymentMethod.id : null;
    } else paymentMethod = savedCards.find((x) => x.id === selectedCard).id;

    if (!error) {
      if (onlyCreateCard) {
        await createPaymentMethodQuery(paymentMethod);
        setPaymentStatus(false);
        onClose(true);
        return;
      }

      try {
        let res;
        if (isUpdate)
          res = await updateSubscriptionQuery(
            paymentMethod,
            productInfo.stripePriceId,
            productInfo.quantity
          );
        else
          res = await setFirstSubscriptionQuery(
            paymentMethod,
            productInfo.stripePriceId,
            productInfo.quantity
          );

        const sub = res.subscription;

        if (!sub || !["active", "trialing"].includes(sub.status)) {
          let paymentIntent = sub.latestInvoice.paymentIntent;

          if (paymentIntent.status === "requires_action") {
            return stripe
              .confirmCardPayment(paymentIntent.clientSecret, {
                payment_method: paymentMethod.id,
              })
              .then((result) => {
                if (result.error) {
                  throw result;
                } else {
                  if (result.paymentIntent.status === "succeeded") {
                    setAlertText(t("views.subscription.plan.success"));
                    setPaymentSuccess(true);
                  }
                }
              })
              .catch((error) => {
                setAlertText(error.error.message);
                setPaymentStatus(false);
              });
          } else if (sub.status === "incomplete") {
            const invoiceUrl = sub.latestInvoice.hostedInvoiceUrl;
            setAlertText(t("views.subscription.plan.no_success.title"));
            setAlertSubText(
              <>
                {t("views.subscription.plan.no_success.content_start")}{" "}
                <Link href={invoiceUrl} target="_blank">
                  {t("views.subscription.plan.no_success.here")}
                </Link>{" "}
                {t("views.subscription.plan.no_success.content_end")}{" "}
                <Link to={settingsPaymentHistory}>
                  {t("views.subscription.plan.no_success.history")}
                </Link>
                {". "}
                {t("views.subscription.plan.no_success.expiration")}
              </>
            );
            setPaymentStatus(false);
          }
        } else {
          setAlertText(t("views.subscription.plan.success"));
          setPaymentSuccess(true);
        }
      } catch (e) {
        if (e.response.data && e.response.data.error) {
          if (typeof e.response.data.error === "string")
            setAlertText(e.response.data.error);
          else setAlertText(e.response.data.error?.[0]);
        }
        setPaymentStatus(false);
      }
    } else {
      console.error(error);
      if (
        error?.code &&
        ["validation_error", "card_error"].includes(error?.type)
      )
        setAlertText(t(`errors.stripe.${error.code}`));
      setPaymentStatus(false);
    }
  };

  const handleAlertClose = () => {
    setAlertText(null);
    if (paymentSuccessful) {
      setPaymentSuccess(false);
      onClose(true);
    }
  };

  const handlePaymentCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      title={
        title || t("payments.subscription.title", { name: productInfo.name })
      }
      className={classes.root}
      onClose={handlePaymentCancel}
      actions={
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePaymentCancel}
            size="large"
          >
            {t("common.cancel")}
          </Button>
          {!companyDataEdited && (
            <Button
              size="large"
              variant="contained"
              className="ml-2"
              icon={
                processingPayment ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
              disabled={!stripe}
              onClick={handleSubmit}
            >
              {onlyCreateCard ? t("common.save") : t("payments.subscribe")}
            </Button>
          )}
        </>
      }
    >
      {!onlyCreateCard && (
        <p className="mb-4">
          {t(`payments.subscription.payment_info${trial > 0 ? "_trial" : ""}`, {
            price: productInfo.price,
            date: format(
              add(
                new Date(),
                trial
                  ? { days: trial }
                  : {
                      [productInfo.duration
                        ? productInfo.duration + "s"
                        : "months"]: 1,
                    }
              ),
              "d MMM yyyy"
            ),
          })}
        </p>
      )}
      <Accordion
        square
        hideExpandButton
        expanded={companyDataEdited}
        className="mb-4"
        titleContentClassName="w-full justify-between"
        contentClassName="p-2 pt-4 square"
        title={!companyDataEdited ? (
          <>
            <div>
              <strong className={classes.companyInfo}>
                {companyDetails.name}
              </strong>
              {(companyDetails.postcode ||
                companyDetails.street ||
                companyDetails.city) && (
                <p className={classes.companyInfo}>
                  {[
                    companyDetails.street,
                    companyDetails.postcode,
                    companyDetails.city,
                  ]
                    .filter((d) => d)
                    .join(", ")}
                </p>
              )}
            </div>
            
            <IconButton
              size="medium"
              onClick={() => setCompanyDataEdited(true)}
            >
              <Pencil />
            </IconButton>
          </>
        ) : (
          <h3>{t("views.settings.menu.company_full")}</h3>
        )}
      >
        <CompanyDetailsForm
          onSubmit={changeCompanyDetails}
          defaultValues={companyDetails}
          noDivider
          useCancel={companyDataAlreadyFilled}
          onCancel={() => setCompanyDataEdited(false)}
        />
      </Accordion>
      <Accordion
        square
        hideExpandButton
        expanded={!companyDataEdited}
        titleContentClassName="w-full justify-between"
        contentClassName="p-2 pt-4 square"
        title={<h3>{t("payments.method")}</h3>}
      >
        <InfoLabel
          label={t(
            `payments.${
              !onlyCreateCard && savedCards.length
                ? "method_select"
                : "new_method"
            }`
          )}
        />
        {isCardLoading ? (
          <CircularProgress aria-label="progress indicator" size={20} />
        ) : (
          <>
            {Boolean(!onlyCreateCard && savedCards.length) && (
              <TextField
                id="savedCards"
                value={selectedCard}
                select
                onChange={(e) => setSelectedCard(e.target.value)}
              >
                {savedCards.map((c) => {
                  const { id, digits, brand } = c;
                  
                  const matchedBrand = CARD_BRAND_SRC.find(
                    (b) => b.name === brand.toLowerCase().replace(" ", "")
                  );
                  
                  return (
                    <MenuItem value={id} key={id}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        {Boolean(matchedBrand) && (
                          <StaticImg
                            src={matchedBrand.src}
                            style={{ height: 20 }}
                          />
                        )}
                        <Grid
                          item
                          container
                          justifyContent="space-evenly"
                          className="ml-4"
                          style={{ width: "45%" }}
                        >
                          <span>&#8226;&#8226;&#8226;&#8226;</span>
                          <span>&#8226;&#8226;&#8226;&#8226;</span>
                          <span>&#8226;&#8226;&#8226;&#8226;</span>
                          <span>{digits}</span>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
                <MenuItem value="new">{t("payments.new_method")}</MenuItem>
              </TextField>
            )}
            {(selectedCard === "new" || onlyCreateCard) && (
              <div
                className={`${classes.cardElementContainer} ${
                  cardElFocused ? classes.cardElementContainerFocused : ""
                }`}
              >
                <CardElement
                  options={CARD_OPTIONS}
                  onFocus={() => isFocused(true)}
                  onBlur={() => isFocused(false)}
                />
              </div>
            )}
          </>
        )}
        
        {!onlyCreateCard && (
          <>
            <InfoLabel label={t("views.subscription.actions.add_coupon")} />
            <TextField
              id="feedback"
              value={couponVal}
              error={couponVal && couponError}
              helperText={couponVal ? couponError : undefined}
              onChange={handleCouponChange}
              style={{ marginBottom: 0 }}
            />
          </>
        )}
      </Accordion>
      <Alert
        isOpen={!!alertText}
        title={alertText}
        onAccept={handleAlertClose}
        acceptText={t("common.close")}
      >
        {alertSubText}
      </Alert>
    </Dialog>
  );
};

PaymentDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  defaultPaymentMethod: string,
  productInfo: shape({
    code: string,
    name: string,
    stripePriceId: string,
    price: number,
    quantity: number,
    duration: string,
  }),
  trial: number,
  title: string,
  isUpdate: bool,
};

PaymentDialog.defaultProps = {
  trial: 0,
};
