import { memo } from "react";
import { arrayOf, bool, func, number, string } from "prop-types";
import { coordsToIdentifier } from "@utils";
import { TextField } from "@components";
import { MenuItem } from "@material-ui/core";
import useStyle from "../StepsFactory/steps/Steps.style";
import classnames from "classnames";

export const ColumnSelector = memo(({
  name,
  selectedColumn,
  availableColumns,
  takenColumns,
  useMinMax,
  onChange,
  className,
}) => {
  const classes = useStyle();
  
  return (
    <TextField
      value={selectedColumn}
      select
      id={`${name}_column_select`}
      name={
        useMinMax
          ? `${name} column min select`
          : `${name} column select`
      }
      className={classnames(classes.columnSelect, "mb-0", className)}
      onChange={onChange}
    >
      {availableColumns
        .filter((c) => c === selectedColumn || !takenColumns.includes(c))
        .map((c) => (
          <MenuItem value={c} key={"column" + c}>
            {coordsToIdentifier(c)}
          </MenuItem>
        ))}
    </TextField>
  )
})

ColumnSelector.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  selectedColumn: number,
  availableColumns: arrayOf(number),
  takenColumns: arrayOf(number),
  useMinMax: bool,
  className: string,
}