import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  tableContainer: {
    height: "max-content",
    width: "100%",
    minWidth: "100%",
    position: "relative",
    overflowX: "auto",
    overflowY: "hidden",
    paddingBottom: 8,
  },
  headRow: {
    zIndex: 10,
    width: "max-content",
    minWidth: "100%",
    position: "relative",
  },
  row: {
    width: "max-content",
    minWidth: "100%",
    position: "relative",
    margin: "2px 0",
    "&:hover": {
      borderColor: theme.palette.primary.main + "80",
      boxShadow: " 0px 1px 2px rgba(0, 0, 0, 0.15)",
    },
  },
  cell: {
    padding: "0 8px",
    height: 52,
    boxSizing: "content-box",
    alignContent: "center",
    backgroundColor: theme.props.backgroundSecondary,
    borderColor: theme.props.border,
    borderStyle: "solid",
    borderWidth: "1px 0",
    boxShadow: "none !important",
    "&:first-child": {
      paddingLeft: 16,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderLeftWidth: 1,
    },
    "&:last-child": {
      paddingRight: 16,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      borderRightWidth: 1,
    },
    "&.sticky": {
      alignSelf: "flex-start",
      zIndex: 1,
      fallbacks: [{ position: "sticky" }, { position: "-webkit-sticky" }],
    },
    "&.shadow": {
      borderRightWidth: 1,
      boxShadow: "5px 0px 5px -3px rgba(0,0,0,0.12)",
    },
  },
  cellBig: {
    height: 72,
  },
  cellNoTextOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block"
  },
  headCell: {
    lineHeight: "normal",
    height: 32,
    fontSize: "12px !important",
    fontWeight: 600,
    padding: "0 8px",
    margin: "0 !important",
    textTransform: "uppercase",
    border: "none",
    color: theme.palette.text.primary + "bf",
    alignItems: "center",
    backgroundColor: theme.props.background,
    "&:first-child": {
      paddingLeft: 17,
    },
    "&:last-child": {
      paddingRight: 17,
    },
  },
}));

export default useStyle;
