import { useEffect, useState } from "react";
import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { getSubscriptionPlansQuery } from "@query";
import { Grid } from "@material-ui/core";
import { SubscriptionPlan } from "../SubscriptionPlan/SubscriptionPlan";
import { CustomPlan } from "../CustomPlan/CustomPlan";
import { PricingAutocomplete } from "../PricingAutocomplete/PricingAutocomplete";
import { COMPANY_SIZE_TIERS, ESTIMATE_TIERS } from "@utils";
import { FeaturesList } from "../FeaturesList/FeaturesList";
import { PlanDurationSwitch } from "../PlanDurationSwitch/PlanDurationSwitch";
import useStyle from "./SubscriptionTable.style";
import { PageCircularProgress } from "@components";

export const SubscriptionsTable = ({ onSubSelect }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isLoading, setLoadedState] = useState(true);

  const [plans, setPlans] = useState([]);

  const [duration, setDuration] = useState("year");
  const [users, setUsers] = useState(COMPANY_SIZE_TIERS[0]);
  const [estimates, setEstimates] = useState(ESTIMATE_TIERS[0]);

  const handlePlanSelect = (...args) => {
    onSubSelect(...args, users.amount, estimates.amount, duration);
  };

  const changeDuration = (val) => {
    setDuration(val);
  };

  const changeUsers = (e, val) => {
    setUsers(val);
  };

  const changeEstimates = (e, val) => {
    setEstimates(val);
  };

  const loadPlans = async () => {
    const p = await getSubscriptionPlansQuery();
    setPlans(p);
    setLoadedState(false);
  };

  useEffect(() => {
    loadPlans();
  }, []);

  if (isLoading)
    return <PageCircularProgress />;

  return (
    <Grid item container justifyContent="center" className="mb-8">
      <h1 className="text-5xl text-center">
        {t("views.subscription.plan.choose")}
      </h1>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        className={classes.settingsGrid}
      >
        <PricingAutocomplete
          id="usersSelect"
          value={users}
          options={COMPANY_SIZE_TIERS}
          onChange={changeUsers}
          label={t("views.subscription.plan.company_size")}
        />
        <PricingAutocomplete
          id="estimatesSelect"
          value={estimates}
          options={ESTIMATE_TIERS}
          onChange={changeEstimates}
          label={t("views.subscription.plan.estimates_month")}
        />
      </Grid>
      <PlanDurationSwitch value={duration} onChange={changeDuration} />
      <Grid
        item container
        spacing={6}
        className="mx-0"
        justifyContent="center"
      >
        <SubscriptionPlan
          code="package_free"
          name={t("plans.package_free")}
          hashLink="features"
          duration={duration}
          userAmount={5}
          estimateAmount={3}
          basePrice={0}
          onSelect={handlePlanSelect}
        />
        {plans.map(({ code, name, best, prices }) => (
          <SubscriptionPlan
            key={code}
            code={code}
            name={name}
            hashLink="features"
            highlight={Boolean(best)}
            onSelect={handlePlanSelect}
            duration={duration}
            prices={prices}
            userAmount={users.amount}
            estimateAmount={estimates.amount}
          />
        ))}
        <CustomPlan />
      </Grid>

      <FeaturesList hashLink="features" />
    </Grid>
  );
};

SubscriptionsTable.propTypes = {
  onSubSelect: func.isRequired,
};
