import { useMemo } from "react";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { setCompanySettingsQuery } from "@query";
import { HtmlParser } from "@utils";
import { useNavigate } from "react-router-dom";
import { Button, Dialog } from "@components";
import { pricing } from "@paths";
import useStyle from "./TrialAlert.style";
import { COMPANY_SETTINGS } from "@client";

export const TrialAlert = () => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();
  const classes = useStyle();
  const navigate = useNavigate();

  const { nonPayingUser, hasTrialLeft, trialLeftDays } = userStore;
  const { trialAlertStatus } = stateStore;

  const status = useMemo(() => {
    if (nonPayingUser) return "end";
    if (hasTrialLeft && trialLeftDays === 7) return "7";
    if (hasTrialLeft && trialLeftDays === 3) return "3";
  }, [nonPayingUser, hasTrialLeft, trialLeftDays]);

  const title = useMemo(() => {
    switch (status) {
      case "end":
        return t("alerts.no_subscription.title_end");
      case "7":
      case "3":
        return t("alerts.no_subscription.title");
      default:
        stateStore.setTrialAlertStatus(false);
    }
  }, [status]);

  const text = useMemo(() => {
    switch (status) {
      case "end":
        return t("alerts.no_subscription.trial_end");
      case "7":
        return t("alerts.no_subscription.trial_7");
      case "3":
        return t("alerts.no_subscription.trial_3");
    }
  }, [status]);

  const isOpen = useMemo(() => trialAlertStatus, [trialAlertStatus]);

  const onClose = async () => {
    stateStore.setTrialAlertStatus(false);
    await setCompanySettingsQuery(COMPANY_SETTINGS.TRIAL_ALERT, status);
  };

  const onUpgradeClose = () => {
    onClose();
    navigate(pricing);
  };

  return isOpen && text ? (
    <Dialog
      title={title}
      open={isOpen}
      onClose={onClose}
      actionsAlignment="start"
      containerClass={classes.container}
      width={380}
      rootClass={classes.dialog}
      actions={
        <>
          <Button
            variant="contained"
            href={process.env.REACT_APP_DEMO_BOOK_LINK}
            target="_blank"
            onClick={onClose}
          >
            {t("alerts.no_subscription.action")}
          </Button>
          {status === "end" && (
            <Button variant="contained" onClick={onUpgradeClose}>
              {t("alerts.no_subscription.action2")}
            </Button>
          )}
        </>
      }
    >
      {text && HtmlParser(text)}
    </Dialog>
  ) : (
    <></>
  );
};
