import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  avatar: {
    border: `1px solid ${theme.palette.text.primary}`,
    minWidth: 96,
    height: 96,
  },
  avatarProgress: {
    position: "absolute",
    top: 36,
    left: 36,
  },
  addAvatar: {
    width: 96,
    height: 96,
    border: `1px dashed ${theme.palette.text.primary}`,
    borderRadius: "50%",
  },
  divider: {
    margin: "8px -24px 24px",
  },
  formDivider: {
    margin: "8px -24px",
    width: "calc(100% + 48px)",
  },
  companyAvatar: {
    borderRadius: 5,
  },
  label: {
    fontSize: 16,
    marginBottom: 8,
    "&:not(:first-child)": { marginTop: 8 },
    flexShrink: 0,
  },
  actionButton: {
    marginLeft: 8,
    flexShrink: 0,
    height: 48,
  },
}));

export default useStyle;
