import { APIMethod, fetchData } from "@client";

export async function addProjectAttachmentQuery(
  uuid,
  s3etag,
  filename,
  s3path,
  s3file
) {
  const res = await fetchData("/project/attachment", APIMethod.PUT, {
    removeEmptyKeys: true,
    data: { filename, s3path, s3file, s3etag, uuid },
  });
  return res.results.attachmentId;
}
