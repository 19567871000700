import LogRocket from "logrocket";
import Smartlook from "smartlook-client";

const BLACKLIST = [
  // domains
  // /.*@apropo.io/,
  /.*@geckodynamics.com/,
  /.*@tsh.io/,
  // individual emails
  "emilpio@gmail.com",
  "khlopkov.d@gmail.com",
  // grouped emails (developers/testers)
  /mateuszg.programista(\+.+)?@mirit.pl/,
  /kamil.bakalarz87(\+.+)?@gmail.com/,
  /grubkamichal(\+.+)?@gmail.com/,
  // /januszkoziol22(\+.+)?@gmail.com/,
  // /janusz.koziol(\+.+)?@3step.pl/,
];

const WHITELIST = [
  "michael@geckodynamics.com"
];

export const isLogBlacklisted = (email) => {
  if (!email) return true;
  return Boolean(
    BLACKLIST.find((element) =>
      typeof element === "string" ? element === email : email.match(element)
    ) && !WHITELIST.includes(email)
  );
};

export const runLogrocket = ({ id, email, firstname, lastname }) => {
  if (
    process.env.REACT_APP_USE_LOGROCKET !== "true" ||
    isLogBlacklisted(email)
  )
    return;

  LogRocket.init("apropo/apropo", {
    network: {
      requestSanitizer: (request) => {
        const url = request.url.toLowerCase();
        if (url.indexOf("auth") !== -1 || url.indexOf("/delete") !== -1)
          request.body = undefined;
        if (request.headers["Authorization"]) {
          request.headers["Authorization"] = "";
        }

        return request;
      },
      responseSanitizer: (response) => {
        if (response.body?.replace)
          response.body = response.body.replace(
            /"token":"\w+\.\w+\.\w+",?/,
            ""
          );

        return response;
      },
    },
  });

  LogRocket.identify(id, {
    name: firstname + (lastname ? " " + lastname : ""),
    email: email,
  });
};

export const runSmartlook = ({ email, firstname, lastname }) => {
  if (
    !process.env.REACT_APP_SMARTLOOK_KEY ||
    isLogBlacklisted(email)
  )
    return;
  console.log("starting smartlook")
  Smartlook.init(process.env.REACT_APP_SMARTLOOK_KEY, {
    region: "eu",
    relayProxyUrl: process.env.REACT_APP_SMARTLOOK_PROXY_URL || undefined,
  }, () => {
    console.log("smartlook initialized")
  });
  Smartlook.identify(email, {
    name: firstname + (lastname ? " " + lastname : ""),
    email: email,
  });
};
