import { fetchData } from "@client";

export async function getPaymentMethodsQuery() {
  try {
    let res = await fetchData("/company/payment/methods");
    return res.map(({ id, card }) => ({
      id,
      brand: card.brand,
      digits: card.last4,
      valid: `${card.expMonth}/${card.expYear}`,
    }));
  } catch (e) {
    return [];
  }
}
