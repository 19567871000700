import { fetchData } from "@client";

export async function getCompanyDetailsQuery() {
  const res = await fetchData("/company");
  return {
    name: res.results.name,
    city: res.results.city,
    postcode: res.results.postcode,
    street: res.results.street,
    phone: res.results.phone,
    nip: res.results.nip,
    country: res.results.country,
    logo: res.results.logo,
  };
}
