import { useMemo } from "react";
import { arrayOf, number } from "prop-types";
import { observer } from "mobx-react";
import { useStructureStore } from "@hooks";
import { CollapseValueCell } from "@components";
import { BillingInnerCell } from "./BillingInnerCell";


export const BillingCell = observer(({
  index,
  monthlyBilling,
  weeklyBilling,
}) => {
  const structure = useStructureStore();
  
  const isOpened = useMemo(() => (
    structure.settings.isTimelineMonthExpanded(index)
  ), [structure.settings.expandedTimelineMonths?.size])
  
  return (
    <CollapseValueCell
      isOpened={isOpened}
      narrowChildren
      useBorder
      visible
      isHeader
      isFirst={index === 0}
      cell={<BillingInnerCell
        value={monthlyBilling}
        isOpened={isOpened}
      />}
    >
      {
        [...new Array(4).keys()]
          .map((index) => (
            <BillingInnerCell
              key={"week"+index}
              value={weeklyBilling[index]}
              displayCellContent={Boolean(weeklyBilling[index])}
              narrow
              useBorder
            />
          ))
      }
    </CollapseValueCell>
  );
})

BillingCell.propTypes = {
  index: number,
  monthlyBilling: number,
  weeklyBilling: arrayOf(number),
}