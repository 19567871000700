import { APIMethod, fetchData } from "@client";

export async function removeUserFromWorkTypeQuery(
  projectUuid,
  workTypeId,
  userUuid
) {
  await fetchData("/estimate/project/user/assigne", APIMethod.DELETE, {
    data: {
      project: projectUuid,
      worktype: workTypeId,
      user: userUuid,
    },
  });
}
