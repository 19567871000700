// Material components
import { SvgIcon } from "@material-ui/core";

export default function User(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path d="M10.667 9.333c1.84 0 3.333 1.493 3.333 3.334V14c0 .368-.298.667-.667.667-.368 0-.666-.299-.666-.667v-1.333c0-1.105-.896-2-2-2H5.333c-1.104 0-2 .895-2 2V14c0 .368-.298.667-.666.667-.369 0-.667-.299-.667-.667v-1.333c0-1.841 1.492-3.334 3.333-3.334zM8 1.333c1.84 0 3.333 1.493 3.333 3.334C11.333 6.507 9.841 8 8 8 6.16 8 4.667 6.508 4.667 4.667c0-1.841 1.492-3.334 3.333-3.334zm0 1.334c-1.105 0-2 .895-2 2 0 1.104.895 2 2 2s2-.896 2-2c0-1.105-.895-2-2-2z" />
    </SvgIcon>
  );
}
