export function libraryChildMap(child, idKey) {
  const values = child.values?.length
    ? child.values.reduce(
        (values, workType) => ({
          ...values,
          ...workType,
        }),
        {}
      )
    : {};

  const newChildObj = {
    name: child.name,
    content: child.content,
    parent: child.parent,
    status: child.status,
    children: child.children?.length
      ? child.children.map((e) => libraryChildMap(e, idKey))
      : undefined,
    values,
    libraryTask: true,
  };

  newChildObj[idKey] = child.id;

  return newChildObj;
}
