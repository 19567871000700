import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles((theme) => ({
  priceCell: {
    width: CELL_WIDTHS.PRICE.SMALL,
    minWidth: CELL_WIDTHS.PRICE.SMALL,
  },
  cellMini: {
    width: CELL_WIDTHS.VALUE.MINI,
    minWidth: CELL_WIDTHS.VALUE.MINI,
  },
  priceCellBody: {
    color: theme.palette.text.primary,
  },
  priceContainer: {
    height: 24,
  },
  priceContainerBackground: {
    borderRadius: 3,
    backgroundColor: theme.palette.primary.main + "0d",
  },
  valueCellWidth: {
    width: CELL_WIDTHS.VALUE.SMALL,
    minWidth: CELL_WIDTHS.VALUE.SMALL,
  },
  cellMedium: {
    width: CELL_WIDTHS.PRICE.MEDIUM,
    minWidth: CELL_WIDTHS.PRICE.MEDIUM,
  },
  cellLarge: {
    width: CELL_WIDTHS.PRICE.LARGE,
    minWidth: CELL_WIDTHS.PRICE.LARGE,
  },
}));

export default useStyle;
