import { object } from "prop-types";
import { observer } from "mobx-react";
import { useProjectEditorStore, useStructureStore } from "@hooks";
import { PriceCell } from "@components";
import { useMemo } from "react";

export const CostCell = observer(({
  element
}) => {
  const structure = useStructureStore();
  const { visibilityMode } = useProjectEditorStore();
  const { usesTwoValues, settings, visibility } = structure;
  const { useMinMax, currencyObj, roundPrice } = settings;
  const { apply, hideSummaryCost, hideMinValues, hideMaxValues } = visibility;
  const { parsedPrice, showPriceMax } = element;
  
  const className = useMemo(() => (
    (visibilityMode && apply && hideSummaryCost && "transparent-2") || undefined
  ), [visibilityMode, apply, hideSummaryCost]);
  
  return (
    <PriceCell
      displayContent
      widest={usesTwoValues}
      value={useMinMax ? parsedPrice.min : parsedPrice.avg}
      valueMax={parsedPrice.max}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      displayMin={!useMinMax || !apply || !hideMinValues}
      displayMax={showPriceMax && (!apply || !hideMaxValues)}
      allowNegative
      className={className}
      inputClassName="input-cost"
    />
  );
});

CostCell.propTypes = {
  element: object.isRequired,
};
