import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    "& > img": {
      height: "100%",
    },
    border: "2px dashed " + theme.palette.primary.main + "4d",
    backgroundColor: theme.palette.primary.main + "1a",
  },
}));

export default useStyle;
