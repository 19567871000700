import { useMemo } from "react";
import { string, bool, func, shape } from "prop-types";
import { Button, Dialog } from "@components";
import { useTranslation } from "react-i18next";
import { SUBSCRIPTION_FEATURE_PREVIEWS } from "@utils";
import useStyle from "./FeatureAlert.style";

export const FeatureAlert = ({ visible, onClose, feature, altText }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyle();

  const hasVideo = useMemo(
    () => Boolean(SUBSCRIPTION_FEATURE_PREVIEWS[feature.code]),
    [feature.code]
  );

  return (
    <Dialog
      open={visible}
      useCloseButton={false}
      onClose={onClose}
      title={`${t(`feature_alerts.base${feature.plan ? "_named" : ""}`, {
        plan: t(`plans.${feature.plan}`),
      })} ${t(`feature_alerts.${feature.code}`)}`}
      actions={
        <Button variant="contained" color="primary" onClick={onClose}>
          {t("common.close")}
        </Button>
      }
    >
      {altText ||
        (i18n.exists(`feature_desc.${feature.code}`) && (
          <p>{t(`feature_desc.${feature.code}`)}</p>
        ))}
      {hasVideo && (
        <video autoPlay loop className={classes.video}>
          <source
            src={SUBSCRIPTION_FEATURE_PREVIEWS[feature.code]}
            type="video/mp4"
          />
        </video>
      )}
    </Dialog>
  );
};

FeatureAlert.propTypes = {
  visible: bool,
  feature: shape({
    code: string.isRequired,
    plan: string,
  }),
  onClose: func.isRequired,
  altText: string,
};
