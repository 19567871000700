import { APIMethod, fetchData } from "@client";

export async function updateLibraryElementHoursQuery(
  user,
  elementId,
  worktype,
  tmin,
  tavg,
  tmax
) {
  await fetchData(`/v1/library/${elementId}/estimate`, APIMethod.PUT, {
    data: {
      user,
      worktype,
      tmin: tmin || 0,
      tavg: tavg || 0,
      tmax: tmax || 0,
    },
  });
}
