import { useEffect, useState } from "react";
import { bool, func } from "prop-types";
import { useTranslation } from "react-i18next";
import { getPaymentHistoryQuery } from "@query";
import { universalDateParser } from "@utils";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { Dialog, Table, TableCell, TableRow } from "@components";
import { CircularProgress, IconButton, Link } from "@material-ui/core";
import { Receipt } from "@material-ui/icons";

export const PaymentHistoryDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [payments, setPayments] = useState([]);

  const loadPayments = async (resetList = false) => {
    if (resetList || (!isLoading && hasMore)) {
      setIsLoading(true);
      const res = await getPaymentHistoryQuery(
        resetList ? null : payments[payments.length - 1].id
      );
      if (res) {
        setPayments(resetList ? res.results : [...payments, ...res.results]);
        setHasMore(res.paginate.hasMore);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) loadPayments(true);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      width={800}
      title={t("views.subscription.dialogs.history")}
    >
      <Table
        id="paymentHistory"
        scrollEndHandler={hasMore ? loadPayments : null}
        headers={[
          { label: t("views.subscription.type"), width: 160, flexible: true },
          { label: t("views.subscription.date"), width: 100 },
          { label: t("views.subscription.price"), width: 120, flexible: true },
          { label: t("views.subscription.status"), width: 100 },
          { width: 34 },
        ]}
      >
        {payments
          .filter(p => p.detail?.length && p.stripeInvoicePdf)
          .map((m) => {
          const { id, detail, createdAt, amount, status, stripeInvoicePdf } =
            m;
          return (
            <TableRow key={id}>
              <TableCell width={160} flexible>
                {detail && detail[0] && <p>{detail[0].name}</p>}
              </TableCell>
              <TableCell width={100}>
                {format(universalDateParser(createdAt), "dd.MM.yyyy")}
              </TableCell>
              <TableCell width={120} flexible>
                <strong>
                  {"$"}
                  {(amount / 100).toFixed(2)}
                </strong>
              </TableCell>
              <TableCell width={100}>
                <span style={{ textTransform: "capitalize" }}>
                  {t(`views.subscription.payment_status.${status}`)}
                </span>
              </TableCell>
              <TableCell width={34}>
                {stripeInvoicePdf && (
                  <Link href={stripeInvoicePdf} target="_blank">
                    <IconButton color="primary">
                      <Receipt />
                    </IconButton>
                  </Link>
                )}
              </TableCell>
            </TableRow>
          );
        })}
        {isLoading && (
          <CircularProgress
            aria-label="progress indicator"
            className={classes.spinner}
          />
        )}
      </Table>
    </Dialog>
  );
};

const useStyle = makeStyles(() => ({
  spinner: {
    position: "relative",
    left: "calc(50% - 20px)",
    margin: 10,
  },
}));

PaymentHistoryDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
