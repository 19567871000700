import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  
  root: {
    borderWidth: ({ bordered }) => bordered ? 1 : 0,
  },
  title: {
    borderWidth: ({ bordered, titleColored }) => bordered || titleColored ? 0 : undefined,
    borderRadius: ({ bordered }) => bordered ? 0 : undefined,
    "&.Mui-expanded": {
      backgroundColor: ( { colored } ) => colored && theme.props.accordion.expandedTitle,
    },
    "& .hoverIcon": {
      "-webkit-transition": "opacity 0.4s ease",
      "-moz-transition": "opacity 0.4s ease",
      transition: "opacity 0.4s ease",
      opacity: 1,
    },
    "&:not(:hover) .hoverIcon": {
      zIndex: -1,
      opacity: "0 !important",
    },
  },
  titleContent: {
    alignItems: "center",
    justifyContent: ({ reverseTitleRow }) => reverseTitleRow ? "space-between" : "flex-start",
    flexDirection: ({ reverseTitleRow }) => reverseTitleRow ? "row-reverse" : "row",
  },
  titleColored: {
    backgroundColor: theme.props.background,
  },
  content: {
    padding: ({ bordered }) => bordered ? "16px" : "8px",
    backgroundColor: ({ colored }) => colored && theme.props.accordion.expandedContent,
  },
  "variant-info": {
    borderColor: theme.palette.primary.main + " !important"
  },
  "variant-warning": {
    borderColor: theme.palette.warning.main + " !important"
  },
  "variant-danger": {
    borderColor: theme.palette.error.main + " !important"
  },
}));

export default useStyle;