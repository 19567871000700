import { arrayOf, bool, func, number, object, oneOfType, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Grid, Switch } from "@material-ui/core";
import { Autocomplete, ClearButton, TextField } from "@components";
import { Search } from "@assets";
import useStyle from "./TemplatesFilter.style";
import { useEffect, useRef, useState } from "react";

export const TemplatesFilter = ({
  filters,
  categories,
  types,
  clearFilters,
  setFilter,
  switchCompanyTemplatesState,
  filtersLoading = false,
  areCompany = false,
  // dialogVersion=false,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  
  const inputRef = useRef();
  
  const { query, category, type } = filters;
  
  const [localName, setLocalName] = useState(query);
  
  useEffect(() => {
    setLocalName(query);
  }, [query])

  const setName = (e) => setLocalName(e.target.value);
  
  const checkKey = e => {
    if(e.keyCode === 13 ) {
      e.stopPropagation();
      handleNameChange();
      inputRef.current.blur();
    }
  };
  
  const handleCategorySelect = (e, n) => setFilter("category", n?.id);
  const handleTypeSelect = (e, n) => setFilter("type", n?.id);
  
  const handleNameChange = () => {
    if(query === localName) return;
    setFilter("query", localName);
  }

  return (
    // <ScrollBar className="h-max -mb-3">
    <Grid
      container
      spacing={isMobile ? 1 : 2}
      className={classes.filter}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        xs
        style={{ maxWidth: "max-content" }}
      >
        <p style={{ width: 80 }}>{t("views.new_project.custom_templates")}</p>
        <Switch
          color="primary"
          checked={areCompany}
          onChange={switchCompanyTemplatesState}
          name={t(
            `views.new_project.${
              areCompany ? "apropo_templates" : "custom_templates"
            }`
          )}
        />
      </Grid>
      <Grid item xs style={{ minWidth: 220 }}>
        <TextField
          size="small"
          id="filterName"
          value={localName}
          InputLabelProps={{
            style: { display: "flex", alignItems: "center" },
          }}
          InputProps={{
            inputProps: {
              ref: inputRef
            }
          }}
          label={
            <>
              <Search
                style={{ fontSize: 18, marginRight: 4, marginTop: 2 }}
              />
              {t("views.new_project.search")}
              {"..."}
            </>
          }
          onChange={setName}
          onBlur={handleNameChange}
          onKeyDown={checkKey}
          style={{ margin: 0 }}
        />
      </Grid>
      <Grid item xs style={{ minWidth: 180 }}>
        <Autocomplete
          id="templateCategorySelect"
          value={category}
          options={categories}
          onChange={handleCategorySelect}
          idKey="id"
          labelKey="name"
          fullWidth
          size="small"
          dataLoading={filtersLoading}
          inputLabel={t("views.new_project.project_category")}
          fieldClassName="m-0"
        />
      </Grid>
      <Grid item xs style={{ minWidth: 180 }}>
        <Autocomplete
          id="templateSubcategorySelect"
          value={type}
          options={types}
          onChange={handleTypeSelect}
          idKey="id"
          labelKey="name"
          fullWidth
          size="small"
          dataLoading={filtersLoading}
          disabled={!category}
          inputLabel={t("views.new_project.project_subtype")}
          fieldClassName="m-0"
        />
      </Grid>
      <Grid
        item
        style={{ paddingLeft: isMobile ? 8 : 0, marginTop: isMobile ? 8 : 0 }}
      >
        <ClearButton className={classes.clearFilters} onClick={clearFilters}>
          {t("common.clear_filters")}
        </ClearButton>
      </Grid>
    </Grid>
    // </ScrollBar>
  );
};

TemplatesFilter.propTypes = {
  filters: shape({
    query: string,
    category: oneOfType([string, number]),
    type: oneOfType([string, number]),
  }).isRequired,
  categories: arrayOf(object).isRequired,
  types: arrayOf(object).isRequired,
  switchCompanyTemplatesState: func.isRequired,
  clearFilters: func.isRequired,
  setFilter: func.isRequired,
  areCompany: bool,
  filtersLoading: bool,
  dialogVersion: bool,
};
