import { fetchData } from "@client";

export async function getLatestPaymentsQuery() {
  try {
    const res = await fetchData("/order?limit=4");
    return res.results
      ?.filter(p => p.detail?.length && p.stripeInvoicePdf)
      .slice(0,2)
      || [];
  } catch (e) {
    return [];
  }
}
