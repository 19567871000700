import { observer } from "mobx-react";
import { StructureStoreProvider } from "@stores";
import { useProjectEditorStore } from "@hooks";
import { BudgetTrackingContainer } from "../../../components/ProjectEditor";

export const BudgetTrackingView = observer(() => {
  const editorStore = useProjectEditorStore();

  const { currentProjectStructure } = editorStore;

  return (
    <StructureStoreProvider value={currentProjectStructure}>
      <BudgetTrackingContainer  />
    </StructureStoreProvider>
  );
});
