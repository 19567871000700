import { action, computed, makeObservable, observable } from "mobx";
import { createContext } from "react";
import { arrayOf, node, object } from "prop-types";
import { PROJECT_STATUS } from "@utils/settings";
import { areaAutoSearch, autoBreakdownSearch, cutSheetArea } from "@utils";
import { MAX_WORKTYPE_LENGTH } from "project-structure";

const DEFAULT_STEPS = [
  { id: 1, completed: false, locked: false },
  { id: 2, completed: false, locked: true },
  { id: 3, completed: false, locked: true },
  { id: 4, completed: false, locked: true },
  { id: 5, completed: true, locked: true },
];

export const ProjectCreatorStoreContext = createContext(null);
export const ProjectCreatorStoreProvider = ({ children, workTypes }) => (
  <ProjectCreatorStoreContext.Provider value={new ProjectCreatorStore(workTypes)}>
    {children}
  </ProjectCreatorStoreContext.Provider>
);

ProjectCreatorStoreProvider.propTypes = {
  children: node.isRequired,
  workTypes: arrayOf(object)
};

export class ProjectCreatorStore {
  constructor(workTypes) {
    makeObservable(this);

    if(workTypes)
      this.setWorkTypes(workTypes);
    
    this.setFiles = this.setFiles.bind(this);
  }

  @action
  clearProjectData() {
    this.projectWorkTypes = [];
    this.template = undefined;
  }

  @observable workTypes = [];
  @action
  setWorkTypes(workTypes) {
    this.workTypes = workTypes;
  }
  @action
  addWorkType(workType) {
    this.workTypes.push(workType);
  }
  @action
  removeWorkType(workType) {
    this.workTypes = this.workTypes.filter((w) => w.id !== workType);
  }

  status = PROJECT_STATUS.TODO;
  setStatus = (status) => {
    this.status = status;
  };

  @observable projectWorkTypes = [];
  @action
  setProjectWorkTypes(workTypes) {
    this.projectWorkTypes = workTypes;
  }
  
  @action clearXlsData() {
    this.xlsFile = undefined;
    this.xlsProjectStructure = undefined;
  }
  
  @observable template;
  @action
  setTemplate(template, company=false) {
    this.template = template
      ? {...template, company}
      : undefined;
    console.log(this.template);
    this.xlsProjectStructure = undefined;
  }
  
  @observable files = [];
  @action
  setFiles(files) {
    this.files = files;
  }

  // ======================= XLS IMPORT =======================

  @observable xlsData;
  @action setXlsData(data, file) {
    this.resetXlsImportToDefaults();
    this.xlsData = data;
    this.completeStep(0);
    this.unlockStep(1);
    this.setActiveStep(1);
    this.breakdownXlsData = new Array(data.length);
    this.setXlsFile(file);
  }
  @observable xlsFile;
  @action setXlsFile(xlsFile) {
    this.xlsFile = xlsFile;
  }

  @observable xlsProjectStructure;
  @computed get hasXlsFile() {
    return Boolean(this.xlsProjectStructure);
  }
  @action setXlsProjectStructure(structure) {
    this.xlsProjectStructure = structure;
  }

  @observable importModules = [];
  @action setImportModules = (value) => {
    this.importModules = value;
    this.checkBreakdownCompletion();
  };
  @observable importWorkTypes = [];
  @action setImportWorkTypes(importWorkTypes) {
    this.importWorkTypes = importWorkTypes;
  }
  @observable descColumn = "";
  @action setImportDescColumn(descColumn) {
    this.descColumn = descColumn;
  }
  @observable steps = [...DEFAULT_STEPS];
  @observable activeStep = 0;
  @action setActiveStep(s) {
    this.activeStep = s;
    /** @notice: imho, should be used only with "apropo import template" */
    if (s === 2) this.autoAreaCalculations();
    if (s === 3) this.parseBreakdownSheet();
  }
  @observable activeSheet = -1;
  @action setActiveSheet(index) {
    this.activeSheet = index;
    this.completeStep(1);
    this.unlockStep(2);
    this.completeStep(2, false);
    this.unlockStep(3, false);
    this.setBreakdownSelection(
      this.breakdownXlsData.length
        ? this.breakdownXlsData[index]?.selection || []
        : []
    );
  }

  @observable breakdownXlsData = [];
  @observable breakdownSelection = [];
  @action setBreakdownSelection(selection) {
    this.breakdownSelection = selection;
    this.completeStep(2, !!selection.length);
    this.unlockStep(3, !!selection.length);
  }
  @observable previousBreakdownSelection = [];
  @action setPreviousBreakdownSelection(previousBreakdownSelection) {
    this.previousBreakdownSelection = previousBreakdownSelection;
  }
  @observable titleColumnsLength = 1;
  @observable importTimeModifier = 1;
  @action setImportTimeModifier(importTimeModifier) {
    this.importTimeModifier = importTimeModifier;
  }
  @observable useMinMax = false;
  @action setMinMax(useMinMax) {
    this.useMinMax = useMinMax;
  }

  @computed get currentSheet() {
    return this.xlsData?.length ? this.xlsData[this.activeSheet] : undefined;
  }

  @computed get currentBreakdownData() {
    return this.breakdownXlsData[this.activeSheet];
  }

  @action completeStep(index, complete = true) {
    const currentSteps = [...this.steps];
    currentSteps[index].completed = complete;
    this.steps = currentSteps;
  }

  @action unlockStep(index, unlock = true) {
    const currentSteps = [...this.steps];
    currentSteps[index].locked = !unlock;
    this.steps = currentSteps;
  }

  @action autoAreaCalculations = () => {
    if (!this.currentBreakdownData?.selection?.length) {
      const { breakdownSelection, titleColumnsLength } = areaAutoSearch(
        this.xlsData[this.activeSheet]
      );
      const u = [...this.breakdownXlsData];
      u[this.activeSheet] = { selection: breakdownSelection };
      this.breakdownXlsData = u;
      this.setBreakdownSelection(breakdownSelection);
      this.titleColumnsLength = titleColumnsLength;
    }
  };

  @action parseBreakdownSheet() {
    const d = this.breakdownXlsData[this.activeSheet];
    if (!d.sheet || d.selection !== this.breakdownSelection) {
      this.breakdownXlsData[this.activeSheet].selection =
        this.breakdownSelection;
      const area = cutSheetArea(this.currentSheet, this.breakdownSelection);
      this.breakdownXlsData[this.activeSheet].sheet = area;

      const { modules/*, workTypes */} = autoBreakdownSearch(
        area,
        this.titleColumnsLength,
        this.workTypes,
        MAX_WORKTYPE_LENGTH
      );

      this.importModules = modules;
      // this.setImportWorkTypes(workTypes);
    }
  }

  @action checkBreakdownCompletion() {
    this.completeStep(3, !!this.importModules.length);
    this.unlockStep(4, !!this.importModules.length);
  }

  @action changeImportModules(row, col, type, clear = false) {
    const f = this.importModules.find((s) => s.x === col && s.y === row);
    if (f) {
      if (clear)
        this.setImportModules(
          this.importModules.filter((s) => s.x !== col || s.y !== row)
        );
      else f.type = type;
    } else if (!clear)
      this.setImportModules([
        ...this.importModules,
        {
          name: this.currentBreakdownData.sheet.data[row][col],
          x: col,
          y: row,
          type,
        },
      ]);

    this.checkBreakdownCompletion();
    this.titleColumnsLength =
      this.importModules.reduce((max, c) => (c.x > max ? c.x : max), 0) + 1;
  }

  @action updateImportWorkType(index, { id, name }) {
    const u = [...this.importWorkTypes];
    u[index] = { ...u[index], id, name };
    this.setImportWorkTypes(u);
  }

  @action resetStructureAndFile() {
    this.setXlsProjectStructure(null);
    this.setXlsFile(null);
  }

  @action resetXlsImportToDefaults(hardReset) {
    this.steps = [...DEFAULT_STEPS];
    this.xlsData = [];
    this.activeStep = 0;
    this.activeSheet = -1;
    this.breakdownXlsData = [];
    this.breakdownSelection = [];
    this.importModules = [];
    this.previousBreakdownSelection = [];
    this.titleColumnsLength = 1;
    this.setMinMax(false);
    this.importTimeModifier = 1;
    if (hardReset) {
      this.setImportWorkTypes([]);
      this.resetStructureAndFile();
    }
  }
}
