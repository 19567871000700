import { useEffect, useRef, useState } from "react";

export const useSearchableList = ({
  defaultValue,
  autoFocus,
  maxLength,

  onAutoFocus,
  onFocus,
  onChange,
  onFilterChange,
  getOptionDisabled,
}) => {
  const [searchValue, setSearchValue] = useState(defaultValue);
  const [valueIsUsed, setUsedValue] = useState(false);
  const [focused, setFocused] = useState(autoFocus);

  const anchor = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      setFocused(autoFocus);
      onAutoFocus && onAutoFocus();
    }
  }, [autoFocus]);

  useEffect(() => {
    setSearchValue(defaultValue?.replace(/<(.|\n)*?>/g, ""));
  }, [defaultValue]);

  useEffect(() => {
    if (focused !== undefined) onFocus && onFocus();
  }, [focused]);

  useEffect(() => {
    if (focused && searchValue.length >= 3)
      onFilterChange && onFilterChange(searchValue);

    if (
      focused &&
      searchValue.length &&
      searchValue !== defaultValue &&
      getOptionDisabled
    )
      setUsedValue(getOptionDisabled(searchValue));
  }, [searchValue]);

  const handleInputChange = (v) => {
    setSearchValue(v.slice(0, maxLength));
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleNameAccept = () => {
    if (focused) {
      setFocused(false);
      if (valueIsUsed) {
        setUsedValue(false);
        setSearchValue(defaultValue);
      } else if (searchValue && searchValue !== defaultValue) {
        onChange && onChange({ name: searchValue }, true);
      }
    }
  };

  const looseFocus = () => {
    setFocused(false);
  };

  const inputProps = {
    ref: anchor,
    focused,
    value: searchValue,
    error: valueIsUsed,
    onChange: handleInputChange,
    onFocus: handleFocus,
    onAccept: handleNameAccept,
    confirmOnEnter: true,
    acceptOnClickAway: true,
  };

  const containerProps = {
    show: focused,
    anchor: anchor.current,
  };

  return {
    inputProps,
    containerProps,
    looseFocus,
  };
};
