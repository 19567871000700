import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  
  root: {
    opacity: 0.5,
    width: 8,
    margin: "0 4px",
    backgroundColor: theme.palette.text.primary,
  },
}));

export default useStyle;