import { useEffect, useState } from "react";
import { func, object, string } from "prop-types";
import { useIMask } from "react-imask";
import { TextField } from "@components";
import { Controller } from "react-hook-form";
import { vatFormatReplacement } from "@utils";

export const MaskTextField = ({
  mask,
  value: outerValue,
  onChange,
  ...textFieldProps
}) => {
  
  const [focused, setFocused] = useState();
  
  const {
    ref,
    value,
    setValue,
  } = useIMask({
    mask,
    definitions: vatFormatReplacement,
    lazy: !focused,
    prepareChar: str => str.toUpperCase(),
  }, { onAccept: (v) => onChange && onChange(v) });
  
  useEffect(() => {
    if(typeof outerValue === "string")
      setValue(outerValue);
  }, [outerValue]);
  
  return (
    <TextField
      InputProps={{
        inputProps: { ref }
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      value={value}
      { ...textFieldProps }
    />
  )
}

MaskTextField.propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
  errors: object,
  helperText: string,
  mask: string,
}

export const VatTextField = ({
  mask,
  name,
  control,
  ...textFieldProps
}) => {
  
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        mask
          ? <MaskTextField
            mask={mask}
            value={value}
            onChange={onChange}
            name={name}
            error={!!error}
            helperText={error?.message}
            {...textFieldProps}
          />
          : <TextField
            value={value}
            onChange={onChange}
            name={name}
            error={!!error}
            helperText={error?.message}
            {...textFieldProps}
          />
      )}
    />
  );
};

VatTextField.propTypes = {
  name: string.isRequired,
  control: object.isRequired,
  errors: object,
  helperText: string,
  mask: string,
};
