import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { usePresetStore, useFonts } from "@hooks";
import { Button, InteractiveAutocomplete } from "@components";
import { CircularProgress } from "@material-ui/core";
import { CustomFontDialog } from "@dialogs";
import { getCompanyFontsQuery } from "@query";
import { DEFAULT_FONT_NAME } from "@utils";

export const FontPicker = observer(() => {
  const presetStore = usePresetStore();
  const { fonts, loadFonts, addFont, unloadFonts } = useFonts();
  
  const { selectedPresetFont, selectedPresetName, selectedPresetData } = presetStore;
  
  const { t } = useTranslation();
  const [dialogOpen, showDialog] = useState(false);
  const [isLoading, setLoadingState] = useState(true);
  
  useEffect(() => {
    (async () => {
      setLoadingState(true);
      const f = await getCompanyFontsQuery();
      loadFonts(f);
      presetStore.setDefaultPresetFontObj(f.find(ff => ff.name === DEFAULT_FONT_NAME))
      if(!selectedPresetFont) {
        presetStore.setSelectedPresetFont(
          f.find(ff => ff.name === (selectedPresetData?.fontFamily || DEFAULT_FONT_NAME)),
          true
        );
      }
      setLoadingState(false);
    })();
    
    return () => {
      unloadFonts(selectedPresetFont);
    }
  }, []);
  
  useEffect(() => {
    if(fonts.length && !selectedPresetFont && selectedPresetData.fontFamily) {
      presetStore.setSelectedPresetFont(
        fonts.find(ff => ff.name === selectedPresetData.fontFamily)
      );
    }
  }, [selectedPresetName])

  const handleFontFamilyChange = (fontObj) => {
    presetStore.setSelectedPresetFont(fontObj);
  };

  const getOptionStyle = (fontObj) => ({
    fontFamily: fontObj?.name ? `"${fontObj.name}"` : DEFAULT_FONT_NAME,
    minHeight: 36,
    maxHeight: 36
  });
  
  /** @notice: may be used in the future */
  // const handleFontRemove = (fontId) => async (e) => {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   await removeCustomFontQuery(fontId);
  //   removeFont(fontId);
  // }

  return <>
    <InteractiveAutocomplete
      label={t("views.editor.preset.font_picker")}
      value={selectedPresetFont}
      options={isLoading ? [] : fonts}
      labelKey="name"
      idKey="id"
      onChange={handleFontFamilyChange}
      getOptionStyle={getOptionStyle}
      optionClassName="font-normal pl-4 justify-left"
      popupIcon={(
        isLoading &&
        <CircularProgress aria-label="progress indicator" size={24} />
      )}
      className="mr-2"
    >
      <Button
        color="primary"
        variant="text"
        onClick={() => showDialog(true)}
        style={{ minHeight: 36, maxHeight: 36 }}
        className="w-full pl-4 justify-left"
      >
        {t("views.editor.preset.custom_font.import")}
      </Button>
    </InteractiveAutocomplete>
    <CustomFontDialog
      onClose={() => showDialog(false)}
      onFontAdd={addFont}
      open={dialogOpen}
      usedFontNames={fonts?.map(f => f.name.toLowerCase())}
    />
  </>
});
